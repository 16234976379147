const processFindReplaceTextApiData = ({ data, find, matchCase }) => {
  const items = {}
  const entries = []
  const findRegex = new RegExp(find, `g${matchCase ? '' : 'i'}`)

  data?.forEach(searchResult => {
    items[searchResult._id] = searchResult
    switch (searchResult.type) {
      case 'workflow':
        entries.push(...processWorkflow(searchResult, findRegex))
        break
      case 'step':
        entries.push(...processStep(searchResult, findRegex))
        break
      case 'component':
        entries.push(...processComponent(searchResult, findRegex))
        break
      case 'field':
        entries.push(...processField(searchResult, findRegex))
        break
    }
  })

  let i = 0

  return {
    entries: entries.map(entry => ({
      ...entry,
      id: i++,
      score: entry.score - entry.highlightIndex / 10000,
      entryType: 'text',
    })),
    items,
  }
}

function processWorkflow(workflow, find) {
  const results = []

  results.push(
    ...processEntry({
      string: workflow?.name || '',
      path: ['name'],
      entry: workflow,
      find,
      type: 'workflow',
    }),
  )

  results.push(
    ...processEntry({
      string: workflow?.content?.description || '',
      path: ['content', 'description'],
      entry: workflow,
      find,
      type: 'workflow',
    }),
  )

  let highlightIndex = 0

  return results.map(result => ({
    ...result,
    highlightIndex: highlightIndex++,
  }))
}

function processStep(step, find) {
  const results = []
  results.push(
    ...processEntry({
      string: step?.name || '',
      path: ['name'],
      entry: step,
      find,
      type: 'step',
    }),
  )
  let highlightIndex = 0

  return results.map(result => ({
    ...result,
    highlightIndex: highlightIndex++,
  }))
}

function processComponent(component, find) {
  const results = []
  const subComponents = component?.subComponents || []

  if (component?.configurations?.title) {
    results.push(
      ...processEntry({
        string: component?.configurations?.title || '',
        path: ['configurations', 'title'],
        entry: component,
        find,
        type: 'component',
      }),
    )
  }

  if (component?.configurations?.description) {
    results.push(
      ...processEntry({
        string: component?.configurations?.description || '',
        path: ['configurations', 'description'],
        entry: component,
        find,
        type: 'component',
      }),
    )
  }

  if (subComponents.length > 0) {
    subComponents.forEach((subComponent, index) => {
      if (subComponent?.configurations?.title) {
        results.push(
          ...processEntry({
            string: subComponent?.configurations?.title || '',
            path: ['subComponents', index, 'configurations', 'title'],
            entry: component,
            find,
            type: 'component',
            subType: 'accordion',
            subItemId: subComponent?._id,
          }),
        )
      }
    })
  }

  let highlightIndex = 0

  return results.map(result => ({
    ...result,
    highlightIndex: highlightIndex++,
  }))
}
function processField(field, find) {
  switch (field.fieldType) {
    case 'Button':
      return processButton(field, find)
    case 'RichText':
      return processText(field, find)
    case 'Table':
      return processTable(field, find)
  }

  return []
}

function processButton(field, find) {
  const results = []
  results.push(
    ...processEntry({
      string: field?.blocks?.[0]?.button?.label || '',
      path: ['blocks', 0, 'button', 'label'],
      entry: field,
      find,
      type: 'field',
      subType: 'button',
    }),
  )
  let highlightIndex = 0
  return results.map(result => ({
    ...result,
    highlightIndex: highlightIndex++,
  }))
}

function processText(field, find) {
  const results = []
  let highlightIndex = 0
  field.blocks.forEach((block, i) => {
    const items = processTipTapData(block, find, ['blocks', i])
    const processedItems = items.map(item => ({
      ...item,
      itemId: field._id,
      score: field.score,
      type: 'field',
      subType: 'text',
      breadcrumb: field.breadcrumb,
      highlightIndex: highlightIndex++,
    }))
    results.push(...processedItems)
  })
  return results.toReversed()
}

function processTable(field, find) {
  const results = []
  const { body, header } = field?.blocks[0]?.table || { body: [], header: [] }
  body.forEach((row, rowIndex) => {
    row.forEach((cell, cellIndex) => {
      const items = processTipTapData(cell, find, [
        'blocks',
        0,
        'table',
        'body',
        rowIndex,
        cellIndex,
      ])
      let highlightIndex = 0
      results.push(
        ...items.map(item => ({
          ...item,
          itemId: field._id,
          score: field.score,
          type: 'field',
          subType: 'table-cell',
          breadcrumb: field.breadcrumb,
          rowIndex,
          cellIndex,
          highlightIndex: highlightIndex++,
        })),
      )
    })
  })

  header.forEach((header, headerIndex) => {
    const items = processTipTapData(header, find, [
      'blocks',
      0,
      'table',
      'header',
      headerIndex,
    ])
    let highlightIndex = 0
    results.push(
      ...items.map(item => ({
        ...item,
        itemId: field._id,
        score: field.score,
        type: 'field',
        subType: 'table-header',
        breadcrumb: field.breadcrumb,
        headerIndex,
        highlightIndex: highlightIndex++,
      })),
    )
  })

  return results
}

function processTipTapData(block, find, path, fieldIndex) {
  const results = []
  if (block?.text) {
    const matches = [...(block?.text?.matchAll(find) || [])]
    let index = 0
    matches.forEach(match => {
      results.push({
        index,
        find,
        match,
        path: [...path, 'text'],
        replaced: false,
      })
      index = index + 1
    })
  }

  if (block?.content?.length > 0) {
    block.content.forEach((content, index) => {
      const items = processTipTapData(
        content,
        find,
        [...path, 'content', index],
        fieldIndex,
      )
      results.push(...items)
    })
  }
  return results
}

function processEntry({ string, path, find, type, entry, subType, subItemId }) {
  const results = []
  const nameMatches = [...(string?.matchAll(find) || [])]

  let index = 0
  nameMatches.forEach(match => {
    results.push({
      index,
      find,
      path,
      match,
      type,
      itemId: entry._id,
      score: entry.score,
      breadcrumb: entry.breadcrumb,
      replaced: false,
      subType,
      subItemId,
    })
    index = index + 1
  })
  return results
}

export default processFindReplaceTextApiData
