import React from 'react'
import PropTypes from 'prop-types'

function LightningIcon({ colour }, props) {
  return (
    <svg
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M30.1522 14.36C30.0322 14.12 29.8522 14 29.6122 14H22.5322L29.5522 2.9C29.6722 2.72 29.6722 2.48002 29.5522 2.30002C29.4322 2.12002 29.2522 2 29.0122 2H19.4122C19.1723 2 18.9923 2.12002 18.8722 2.30002L9.87224 20.3C9.75222 20.48 9.75222 20.72 9.87224 20.9C9.99227 21.08 10.2322 21.2 10.4122 21.2H16.5922L9.87224 37.22C9.75222 37.46 9.87224 37.76 10.1122 37.94C10.1722 38 10.2922 38 10.4122 38C10.5922 38 10.7722 37.94 10.8923 37.82L30.0923 15.02C30.2122 14.84 30.2722 14.6 30.1522 14.36ZM12.5122 33.98L18.0322 20.78C18.0922 20.6 18.0922 20.42 17.9722 20.24C17.8522 20.12 17.6722 20.0001 17.4922 20.0001H11.3722L19.7722 3.20002H27.9322L20.8522 14.3C20.7322 14.48 20.7322 14.72 20.8522 14.9C20.9723 15.08 21.1523 15.2 21.3922 15.2H28.3523L12.5122 33.98Z"
        fill={colour}
      />
    </svg>
  )
}

LightningIcon.defaultProps = {
  colour: '#000000',
}

LightningIcon.propTypes = {
  colour: PropTypes.string,
}

export default LightningIcon
