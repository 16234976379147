export const bindRouterLinks = (editor, navigate) => {
  const aTags = [...editor.view.dom.querySelectorAll('a')]
  aTags.forEach(element => {
    const href = element.attributes?.href?.value
    const target = element?.attributes?.target.value
    if (href?.indexOf('https://') === -1 && target !== '_blank') {
      element.addEventListener('click', evt => {
        evt.preventDefault()
        navigate(href)
      })
    }
  })
}
