import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { cloneDeep, set } from 'lodash'
import EditorFieldList from '../../editor-core/EditorFieldList'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import EditorLayoutSelector from '../../editor-core/EditorLayoutSelector'
import fixTransform from '../../../../utilities/workflows/fixTransform'
import getId from '../../../../utilities/editor/getId'
import { ReactComponent as ReorderIcon } from '../../../../assets/icons/reorder-icon.svg'
import { ReactComponent as DeleteIcon } from '../../../../assets/icons/delete-icon.svg'
import { ReactComponent as DuplicateIcon } from '../../../../assets/icons/duplicate-icon.svg'
import TipTapColorPicker from '../../base-fields/TipTap/TipTapColorPicker'

function EditorAccordionSubcomponent({
  subcomponent,
  index,
  dropPath,
  onChange = values => {},
  onDuplicateClick = subcomponent => {},
  onDeleteClick = subcomponent => {},
  disableLayouts,
}) {
  const dropLevel = 'subcomponents'
  const { layouts, configurations } = subcomponent
  const id = getId(subcomponent)
  const { title, layout: layoutConfig, titleColor } = configurations || {}
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    setActivatorNodeRef,
  } = useSortable({
    id,
    data: {
      dropPath,
      dropLevel,
    },
  })

  const style = {
    transform: CSS.Transform.toString(fixTransform(transform)),
    transition,
  }

  const inputStyle = {
    ...(titleColor ? { color: titleColor } : {}),
  }

  const isEmpty = title === '' && !layouts?.[0]?.fields?.length

  const [isOpen, setIsOpen] = useState(isEmpty)

  function handleTitleChange(evt) {
    const newValues = cloneDeep(subcomponent)
    newValues.configurations.title = evt.target.value
    onChange(newValues)
  }

  function handleColorChange(value) {
    const newValues = cloneDeep(subcomponent)
    newValues.configurations.titleColor = value
    onChange(newValues)
  }

  function handleFieldChange(layout, field) {
    const newValues = cloneDeep(subcomponent)
    const layoutIndex = newValues.layouts.findIndex(
      item => getId(item) === getId(layout),
    )
    const fieldIndex = newValues.layouts[layoutIndex].fields.findIndex(
      item => getId(item) === getId(field),
    )
    set(newValues, ['layouts', layoutIndex, 'fields', fieldIndex], field)
    onChange(newValues)
  }

  function handleFieldDelete(layout, field) {
    const newValues = cloneDeep(subcomponent)
    const layoutIndex = newValues.layouts.findIndex(
      item => getId(item) === getId(layout),
    )
    const fields = newValues.layouts[layoutIndex].fields.filter(
      item => getId(item) !== getId(field),
    )
    set(newValues, ['layouts', layoutIndex, 'fields'], fields)
    onChange(newValues)
  }

  function handleLayoutChange(value) {
    const currentLayoutCount = layoutConfig.split('-').length
    const newLayoutCount = value.split('-').length
    const newData = cloneDeep(subcomponent)
    newData.configurations.layout = value
    if (newLayoutCount > currentLayoutCount) {
      const id = `temp-${Date.now()}-${Math.random()}`
      newData.layouts.push({
        id,
        fields: [],
        subComponentLayoutType: 'accordionLayout',
        type: 'subComponentLayout',
      })
    } else if (newLayoutCount < currentLayoutCount) {
      newData.layouts = newData.layouts.slice(0, newLayoutCount)
    }
    onChange(newData)
  }

  return (
    <div
      className="accordion-subcomponent"
      ref={setNodeRef}
      style={style}
      {...attributes}
    >
      <div className="accordion-subcomponent__options">
        <div className="accordion-subcomponent__option">
          <div ref={setActivatorNodeRef} className="drag-handle" {...listeners}>
            <ReorderIcon />
          </div>
        </div>
        {!disableLayouts && (
          <div className="accordion-subcomponent__option">
            <EditorLayoutSelector
              label="Layout"
              value={layoutConfig}
              options={['100', '50-50']}
              onChange={handleLayoutChange}
            />
          </div>
        )}
        <div className="accordion-subcomponent__option">
          <TipTapColorPicker
            onChange={handleColorChange}
            tooltip="Font Color"
            value={titleColor}
          />
        </div>
      </div>

      <div className="flex row">
        <div className="flex-fill">
          <div className="accordion-subcomponent-title">
            <input
              type="text"
              value={title}
              style={inputStyle}
              className="accordion-subcomponent-title-input"
              onChange={handleTitleChange}
              placeholder="Enter Accordion Title"
            />

            <div className="flex row justify-center align-center">
              <button
                className="accordion-subcomponent-expand-button"
                onClick={() => setIsOpen(!isOpen)}
              >
                {isOpen ? '' : '+'}
              </button>

              <div data-tooltip="Duplicate Accordion">
                <button
                  className="accordion-subcomponent-duplicate-button"
                  onClick={() => onDuplicateClick(subcomponent)}
                >
                  <DuplicateIcon />
                </button>
              </div>
              <div data-tooltip="Delete Accordion">
                <button
                  className="accordion-subcomponent-delete-button"
                  onClick={() => onDeleteClick(subcomponent)}
                >
                  <DeleteIcon />
                </button>
              </div>
            </div>
          </div>

          {isOpen && (
            <div className="accordion-subcomponent-content">
              {layouts?.map((layout, i) => (
                <EditorFieldList
                  id={`${getId(subcomponent)}-${i}`}
                  key={`${getId(subcomponent)}-${i}`}
                  fields={layout.fields}
                  dropPath={[...dropPath, index, 'layouts', i, 'fields']}
                  onFieldChange={field => handleFieldChange(layout, field)}
                  onFieldDelete={field => handleFieldDelete(layout, field)}
                />
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

EditorAccordionSubcomponent.propTypes = {
  index: PropTypes.number,
  dropPath: PropTypes.array,
  subcomponent: PropTypes.object,
  title: PropTypes.string,
  editorData: PropTypes.shape({
    blocks: PropTypes.arrayOf(PropTypes.shape({})),
    time: PropTypes.number,
    version: PropTypes.string,
  }),
  disableLayouts: PropTypes.bool,
  onChange: PropTypes.func,
  onDeleteClick: PropTypes.func,
  onDuplicateClick: PropTypes.func,
}

export default EditorAccordionSubcomponent
