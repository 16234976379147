import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import Modal from '../../global-components/elements/Modal'
import ManageCategoryForm from '../../forms/ManageCategoryForm'
import { ReactComponent as CloseModalIcon } from '../../../assets/icons/close-icon.svg'
import { updateCategory } from '../../../redux/categories/updateCategory'
import {
  createCategory,
  selectCreateCategoryState,
} from '../../../redux/categories/createCategory'
import useAuthToken from '../../../hooks/useAuthToken'
import MaterialLoader from '../../global-components/elements/MaterialLoader'

function ManageCategoryModal({ category, isOpen, onRequestClose, onSubmit }) {
  const dispatch = useDispatch()
  const { token } = useAuthToken({})
  const { loading } = useSelector(selectCreateCategoryState)
  async function handleSubmit(values) {
    if (category) {
      const response = await dispatch(
        updateCategory(values, category._id, token),
      )
      onSubmit(response)
    } else {
      const response = await dispatch(createCategory(values, token))
      onSubmit(response)
    }
  }

  return (
    <Modal
      modalClassName="modal--delete-workflow modal--md"
      isOpen={isOpen}
      onRequestClose={onRequestClose}
    >
      <div className="modal__header">
        <h3>{category ? 'Edit' : 'Create'} Category</h3>
        <button className="modal__close" onClick={onRequestClose}>
          <CloseModalIcon />
        </button>
      </div>

      <div className="modal__body">
        <ManageCategoryForm
          category={category}
          onSubmit={handleSubmit}
          onCancel={onRequestClose}
        />
        {loading ? <MaterialLoader containerClasses="overlay-loader" /> : null}
      </div>
    </Modal>
  )
}

ManageCategoryModal.propTypes = {
  category: PropTypes.object,
  isOpen: PropTypes.bool,
  onRequestClose: PropTypes.func,
  onSubmit: PropTypes.func,
}

export default ManageCategoryModal
