import React from 'react'
import PropTypes from 'prop-types'

function MinusIcon({ colour }, props) {
  return (
    <svg
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M20 2C10.0748 2 2 10.0748 2 20C2 29.9252 10.0748 38 20 38C29.9252 38 38 29.9252 38 20C38 10.0748 29.9252 2 20 2ZM20 35.8906C11.2379 35.8906 4.10938 28.7621 4.10938 20C4.10938 11.2379 11.2379 4.10938 20 4.10938C28.7621 4.10938 35.8906 11.2379 35.8906 20C35.8906 28.7621 28.7621 35.8906 20 35.8906Z"
        fill={colour}
      />
      <path
        d="M27.9078 16.6859H12.0922C10.2648 16.6859 8.77814 18.1726 8.77814 20C8.77814 21.8273 10.2648 23.3141 12.0922 23.3141H27.9078C29.7352 23.3141 31.2219 21.8273 31.2219 20C31.2219 18.1726 29.7352 16.6859 27.9078 16.6859ZM27.9078 21.2047H12.0922C11.4279 21.2047 10.8875 20.6642 10.8875 20C10.8875 19.3357 11.4279 18.7953 12.0922 18.7953H27.9078C28.5721 18.7953 29.1125 19.3357 29.1125 20C29.1125 20.6642 28.5721 21.2047 27.9078 21.2047Z"
        fill={colour}
      />
    </svg>
  )
}

MinusIcon.defaultProps = {
  colour: '#000000',
}

MinusIcon.propTypes = {
  colour: PropTypes.string,
}

export default MinusIcon
