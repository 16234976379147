import { useSelector } from 'react-redux'
import { selectBrandLinkColour } from '../../../redux/user/userSelections'

const BrandingStyles = () => {
  const { hex } = useSelector(selectBrandLinkColour) || {}
  return (
    <style>
      {hex
        ? `
        .tiptap-editor a { 
          color: ${hex}; 
        }
        
        .editor-table-cell a {
          color: ${hex}; 
        }`
        : ''}
    </style>
  )
}

export default BrandingStyles
