// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.media-library-breadcrumbs {
  display: flex;
  padding: 24px 0 20px;
  height: 36px;
  border-bottom: 1px solid #f1f1f1;
  margin: 0 0 16px 0;
}
.media-library-breadcrumbs__crumb {
  display: flex;
  align-items: center;
  margin-right: 8px;
  font-size: 14px;
  color: #989fa9;
  font-weight: 500;
}
.media-library-breadcrumbs__crumb button {
  height: 20px;
  border: 0;
  border: 0;
  background: transparent;
  padding: 0;
  margin-right: 8px;
  display: flex;
  align-items: center;
  color: #475467;
  font-weight: 500;
  font-size: 14px;
}
.media-library-breadcrumbs__arrow {
  height: 10px;
}
.media-library-breadcrumbs__arrow path {
  fill: #d0d5dd;
}
.media-library-breadcrumbs__home svg {
  width: 20px;
  height: 20px;
}
.media-library-breadcrumbs__home path {
  stroke: #667085;
}`, "",{"version":3,"sources":["webpack://./src/styles/global-components/media-library/media-library-breadcrumbs.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,oBAAA;EACA,YAAA;EACA,gCAAA;EACA,kBAAA;AACF;AAAE;EACE,aAAA;EACA,mBAAA;EACA,iBAAA;EACA,eAAA;EACA,cAAA;EACA,gBAAA;AAEJ;AADI;EACE,YAAA;EACA,SAAA;EACA,SAAA;EACA,uBAAA;EACA,UAAA;EACA,iBAAA;EACA,aAAA;EACA,mBAAA;EACA,cAAA;EACA,gBAAA;EACA,eAAA;AAGN;AAAE;EACE,YAAA;AAEJ;AADI;EACE,aAAA;AAGN;AACI;EACE,WAAA;EACA,YAAA;AACN;AACI;EACE,eAAA;AACN","sourcesContent":[".media-library-breadcrumbs {\n  display: flex;\n  padding: 24px 0 20px;\n  height: 36px;\n  border-bottom: 1px solid #f1f1f1;\n  margin: 0 0 16px 0;\n  &__crumb {\n    display: flex;\n    align-items: center;\n    margin-right: 8px;\n    font-size: 14px;\n    color: #989fa9;\n    font-weight: 500;\n    button {\n      height: 20px;\n      border: 0;\n      border: 0;\n      background: transparent;\n      padding: 0;\n      margin-right: 8px;\n      display: flex;\n      align-items: center;\n      color: #475467;\n      font-weight: 500;\n      font-size: 14px;\n    }\n  }\n  &__arrow {\n    height: 10px;\n    path {\n      fill: #d0d5dd;\n    }\n  }\n  &__home {\n    svg {\n      width: 20px;\n      height: 20px;\n    }\n    path {\n      stroke: #667085;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
