import React from 'react'
import { get, isBoolean, isEmpty } from 'lodash'
import { dashboardCardRoutes } from '../../../configuration/dashboardCardRoutes'
import { Link } from 'react-router-dom'
import { selectUserIsStaff, selectUserState } from '../../../redux/user/user'
import { useSelector, useDispatch } from 'react-redux'
import PlatformDashboardSearch from './PlatformDashboardSearch'
import '../../../styles/global-components/platform-dashboard/platform-dashboard.scss'

function PlatformDashboard() {
  const userState = useSelector(selectUserState)
  const userAccessLevel = get(userState, 'user.accessLevel', 'User')

  return (
    <div className="platform-dashboard">
      <PlatformDashboardSearch />

      <div className="platform-dashboard__cards container">
        {!isEmpty(dashboardCardRoutes)
          ? dashboardCardRoutes.map((card, i) => {
              const dispatch = useDispatch()
              const accessPermissions = get(card, 'accessPermissions', [])
              const label = get(card, 'label', null)
              const description = get(card, 'description', null)
              const route = get(card, 'path', '/')
              const icon = get(card, 'icon', null)
              const type = get(card, 'type', 'link')
              const staffOnly = get(card, 'staffOnly', false)
              const stateValue = get(card, 'state', null)
              const currentState = stateValue ? useSelector(stateValue()) : null
              const eventValue = get(card, 'event', null)
              const isStaff = useSelector(selectUserIsStaff)

              function handleButtonEvent() {
                eventValue ? dispatch(eventValue(!currentState)) : null
              }

              return accessPermissions.includes(userAccessLevel) &&
                label &&
                route &&
                (!staffOnly || (staffOnly && isStaff)) &&
                type === 'link' ? (
                <Link
                  key={`platform-cta-${i}`}
                  className="platform-dashboard__card"
                  to={route}
                >
                  {icon ? icon : null}
                  <p className="label">{label}</p>
                  {description ? (
                    <p className="description">{description}</p>
                  ) : null}
                </Link>
              ) : accessPermissions.includes(userAccessLevel) &&
                type === 'button' &&
                eventValue &&
                isBoolean(currentState) ? (
                <button
                  key={`platform-cta-${i}`}
                  className="platform-dashboard__card"
                  onClick={() => handleButtonEvent()}
                >
                  {icon ? icon : null}
                  <p className="label">{label}</p>
                  {description ? (
                    <p className="description">{description}</p>
                  ) : null}
                </button>
              ) : null
            })
          : null}
      </div>
    </div>
  )
}

export default PlatformDashboard
