import React from 'react'
import PropTypes from 'prop-types'

function VoicemailIcon({ colour }, props) {
  return (
    <svg
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.60033 2C8.26873 2 7.99991 2.26872 7.99995 2.60026V10.1116L2.25923 14.0772C2.09691 14.1883 2 14.3738 2 14.5709L2.0006 37.4C2.00055 37.7313 2.26901 37.9998 2.60038 38H37.3996C37.7312 38.0002 38.0001 37.7315 38 37.4V14.5709C38 14.3736 37.9031 14.1892 37.7408 14.0772L32.0001 10.1114V2.60026C32.0001 2.26872 31.7313 2 31.3997 2H8.60033ZM9.2007 3.19985H30.7993V19.9313L20.0009 29.9716L9.2007 19.9032V3.19985ZM20.0041 5.58254C18.0195 5.58254 16.404 7.20078 16.404 9.18639V13.9924C16.404 15.978 18.0195 17.5967 20.0041 17.5967C21.9888 17.5967 23.6042 15.978 23.6042 13.9924V9.18639C23.6042 7.20078 21.9888 5.58254 20.0041 5.58254ZM20.0041 6.78239C21.3434 6.78239 22.404 7.84368 22.404 9.18639V13.9924C22.404 15.3351 21.3434 16.3964 20.0041 16.3964C18.6648 16.3964 17.6042 15.3351 17.6042 13.9924V9.18639C17.6042 7.84368 18.6648 6.78239 20.0041 6.78239ZM7.99995 11.57V18.7838L3.55538 14.6405L7.99995 11.57ZM32 11.57L36.4774 14.6632C34.9819 16.0265 33.4885 17.4309 32 18.8147V11.57ZM24.7999 13.9924C24.7999 16.6125 22.6202 18.7936 20.0053 18.7936C17.3898 18.7936 15.2067 16.6166 15.2067 13.9924C15.2114 13.6542 14.9357 13.3789 14.5973 13.3839C14.266 13.3839 14.0012 13.6613 14.0059 13.9924C14.0059 17.0756 16.3982 19.6544 19.4012 19.9628L19.4038 21.2026H17.6042C17.2666 21.2026 16.9881 21.4647 16.9881 21.8024C16.9881 22.14 17.2666 22.4113 17.6042 22.4022H22.404C22.7417 22.4022 23.0202 22.14 23.0202 21.8024C23.0202 21.4647 22.7417 21.1937 22.404 21.2026H20.6045C20.6045 21.2017 20.6045 21.201 20.6045 21.2026L20.6018 19.966C23.6083 19.6605 26.0002 17.0765 26.0002 13.9949C26.0086 13.6567 25.7361 13.3785 25.3978 13.3796C25.0613 13.3812 24.7916 13.6586 24.8 13.9949L24.7999 13.9924ZM3.20075 15.9505L15.4303 27.3513L3.20075 36.2185V15.9505ZM36.7992 15.9914V36.2178L24.5766 27.3556L36.7992 15.9914ZM16.3245 28.1849L19.591 31.23C19.8213 31.4454 20.1783 31.4454 20.409 31.2309L23.6808 28.1887L35.5569 36.8001H4.44327L16.3245 28.1849Z"
        fill={colour}
      />
    </svg>
  )
}

VoicemailIcon.defaultProps = {
  colour: '#000000',
}

VoicemailIcon.propTypes = {
  colour: PropTypes.string,
}

export default VoicemailIcon
