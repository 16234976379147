import axios from 'axios'
import { get, isEmpty } from 'lodash'

export const FETCH_DUPLICATE_WORKFLOW_BEGIN = 'FETCH_DUPLICATE_WORKFLOW_BEGIN'
export const FETCH_DUPLICATE_WORKFLOW_ERROR = 'FETCH_DUPLICATE_WORKFLOW_ERROR'
export const FETCH_DUPLICATE_WORKFLOW_SUCCESS =
  'FETCH_DUPLICATE_WORKFLOW_SUCCESS'

export const fetchSanitizedWorkflow =
  (slug, token) => async (dispatch, getState) => {
    const activeClient = getState().userSelectionsData.activeClient
    const activeOperatingLocation =
      getState().userSelectionsData.activeOperatingLocation
    const activeClientId = get(activeClient, 'id', null)

    dispatch({ type: FETCH_DUPLICATE_WORKFLOW_BEGIN })
    return axios({
      url: `${process.env.REACT_APP_PRODUCT_API}/api/v1/workflow/${slug}`,
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      data: {
        clientId: activeClientId,
        operatingLocation: activeOperatingLocation,
        sanitizeData: true,
      },
    })
      .then(response => {
        const workflow = get(response, 'data', [])

        if (!isEmpty(workflow)) {
          dispatch({
            type: FETCH_DUPLICATE_WORKFLOW_SUCCESS,
            payload: workflow,
          })
        } else {
          dispatch({
            type: FETCH_DUPLICATE_WORKFLOW_ERROR,
            payload:
              'There was an error retrieving this workflow - please wait a moment, then try again.',
          })
        }
      })
      .catch(error => {
        dispatch({
          type: FETCH_DUPLICATE_WORKFLOW_ERROR,
          payload: error.message,
        })
      })
  }
