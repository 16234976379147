import { Field, Form, Formik } from 'formik'
import TextField from '../../../forms/elements/TextField'
import React from 'react'
import * as Yup from 'yup'
import PropTypes from 'prop-types'

const FindReplaceTextForm = ({ onSubmit }) => {
  const validationSchema = Yup.object().shape({
    find: Yup.string().min(3).required('A search value is required.'),
    replace: Yup.string().required('A replace value is required.'),
  })

  const initialValues = {
    find: '',
    replace: '',
    matchCase: true,
  }

  return (
    <div className="find-replace-text-form">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ isValid, setFieldTouched, values, setFieldValue, errors }) => {
          return (
            <>
              <Form className="form">
                <div className="form__field">
                  <Field
                    component={TextField}
                    label="Find What:"
                    name="find"
                    placeholder=""
                    type="text"
                  />
                  <div className="form__sub-field">
                    <label className="checkbox">
                      <input
                        type="checkbox"
                        name="matchCase"
                        checked={values.matchCase}
                        onChange={e => {
                          setFieldValue('matchCase', e.target.checked)
                        }}
                      />
                      <span className="label__content">Match Case</span>
                    </label>
                  </div>
                </div>

                <div className="form__field">
                  <Field
                    component={TextField}
                    label="Replace With:"
                    name="replace"
                    placeholder=""
                    type="text"
                  />
                </div>

                <div>
                  <button
                    type="submit"
                    className="btn btn--dark-green form__button-offset"
                    disabled={!isValid}
                  >
                    Search
                  </button>
                </div>
              </Form>
            </>
          )
        }}
      </Formik>
    </div>
  )
}

FindReplaceTextForm.propTypes = {
  onSubmit: PropTypes.func,
}
export default FindReplaceTextForm
