import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import Modal from '../../../global-components/elements/Modal'
import MaterialLoader from '../../../global-components/elements/MaterialLoader'
import { ReactComponent as CloseModalIcon } from '../../../../assets/icons/close-icon.svg'
import {
  deleteStep,
  selectDeleteStepsState,
} from '../../../../redux/steps/deleteStep'
import useAuthToken from '../../../../hooks/useAuthToken'

function ManageWorkflowStepsDeleteModal({
  steps,
  isOpen,
  onRequestClose,
  onSubmit,
}) {
  const [deleteInputValue, setDeleteInputValue] = useState('')
  const confirmationString = `delete ${steps.length} steps`

  function handleClose() {
    setDeleteInputValue('')
    onRequestClose()
  }

  function cleanInput(value) {
    return value.replace(/[\u2018\u2019]/g, "'").replace(/[\u201C\u201D]/g, '"')
  }

  function handleSubmit() {
    setDeleteInputValue('')
    onSubmit(steps)
  }

  return (
    <Modal
      modalClassName="modal--delete-workflow modal--sm"
      isOpen={isOpen}
      onRequestClose={handleClose}
    >
      <div className="modal__header">
        <h3>Delete Steps</h3>

        <button className="modal__close" onClick={handleClose}>
          <CloseModalIcon />
        </button>
      </div>

      <div className="modal__body">
        <p className="warning warning--red">
          Once these steps are deleted, they will no longer be accessible or
          recoverable.
        </p>

        <div className="modal__body-group">
          <p>
            This action will delete the following steps and all of their related
            content. All versions of this workflow will no longer be available
            and this action cannot be undone.
          </p>
          <ul>
            {steps.map(step => (
              <li key={step._id}>{step.name}</li>
            ))}
          </ul>

          <p>
            Please type <strong>{confirmationString}</strong> to confirm:
          </p>

          <input
            type="text"
            value={deleteInputValue}
            onChange={e => setDeleteInputValue(cleanInput(e.target.value))}
          />
        </div>
      </div>

      <div className="modal__submit">
        <button className="btn btn--white" onClick={handleClose}>
          Cancel
        </button>

        <button
          className="btn btn--red"
          disabled={deleteInputValue !== confirmationString}
          onClick={handleSubmit}
        >
          I understand, delete these steps
        </button>
      </div>
    </Modal>
  )
}

ManageWorkflowStepsDeleteModal.propTypes = {
  steps: PropTypes.arrayOf(PropTypes.object),
  isOpen: PropTypes.bool,
  onRequestClose: PropTypes.func,
  onSubmit: PropTypes.func,
}

export default ManageWorkflowStepsDeleteModal
