import Modal from '../../../global-components/elements/Modal'
import React from 'react'
import { ReactComponent as CloseModalIcon } from '../../../../assets/icons/close-icon.svg'
import EditorLinkForm from './EditorLinkForm'
import PropTypes from 'prop-types'

const EditorLinkModal = ({ values, onSubmit, isOpen, onRequestClose }) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      modalClassName="modal--sm"
    >
      <div className="modal__header">
        <h3>Configure Link</h3>
        <button className="modal__close" onClick={() => onRequestClose()}>
          <CloseModalIcon />
        </button>
      </div>

      <div className="modal__body">
        <EditorLinkForm
          values={values}
          onSubmit={onSubmit}
          onCancel={onRequestClose}
          onDelete={() => onSubmit(null)}
        />
      </div>
    </Modal>
  )
}

EditorLinkModal.propTypes = {
  values: PropTypes.shape({}),
  isOpen: PropTypes.bool,
  onRequestClose: PropTypes.func,
  onSubmit: PropTypes.func,
}

export default EditorLinkModal
