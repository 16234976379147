import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { get, isEmpty } from 'lodash'
import renderConsumerField from '../../../../utilities/fields/renderConsumerFields'
import useEntryHighlight from '../../../../hooks/useEntryHighlight'
import highlightTextInHTML from '../../../../utilities/find-replace/highlightTextInHTML'

const Accordion = ({ subcomponent, onToggle, isOpen }) => {
  const headingRef = useRef()
  const { title, titleColor } = get(subcomponent, 'configurations', {})
  const layouts = get(subcomponent, 'layouts', [])
  const layoutWidth = get(subcomponent, 'configurations.layout', '100')
  const highlight = useEntryHighlight()
  const hasInnerText = !!headingRef?.current?.innerText

  useEffect(
    function highlightResultsOnSearch() {
      if (highlight?.subItemId === subcomponent?._id && hasInnerText) {
        highlightTextInHTML({ ref: headingRef, highlight })
      }
    },
    [highlight, hasInnerText],
  )

  const titleStyle = {
    ...(titleColor ? { color: titleColor } : {}),
  }
  return (
    <div className="accordions__accordion">
      <button
        className="accordions__accordion-heading flex"
        onClick={() => onToggle(subcomponent)}
        ref={headingRef}
      >
        {title ? (
          <h3 className="flex-fill" style={titleStyle}>
            {title}
          </h3>
        ) : null}

        <div className="accordions__accordion-toggle">{isOpen ? '-' : '+'}</div>
      </button>

      {isOpen && (
        <div
          className={`accordions__accordion-content${isOpen ? ' open' : ''}`}
        >
          {!isEmpty(layouts)
            ? layouts.map(layout => {
                const id = layout?._id || layout?.id
                const fields = layout?.fields || []
                return !isEmpty(fields) ? (
                  <div
                    key={id}
                    className={`content-area layout-${layoutWidth}`}
                  >
                    {fields.map(field => {
                      const fieldId = field?._id || field?.id
                      const fieldType = field?.fieldType
                      const fieldHighlight =
                        highlight?.itemId === fieldId ? highlight : null
                      const FieldComponent = renderConsumerField(fieldType)
                      return FieldComponent ? (
                        <FieldComponent
                          key={fieldId}
                          field={field}
                          highlight={fieldHighlight}
                        />
                      ) : null
                    })}
                  </div>
                ) : null
              })
            : null}
        </div>
      )}
    </div>
  )
}

Accordion.propTypes = {
  subcomponent: PropTypes.object,
  onToggle: PropTypes.func,
  isOpen: PropTypes.bool,
}

export default Accordion
