import EditorComponentOption from './EditorComponentOption'
import {
  EDITOR_COMPONENTS,
  selectComponent,
  selectField,
} from '../../../configuration/editor'
import { useDroppable } from '@dnd-kit/core'
import '../../../styles/editor-flow/editor-components/editor-component-options.scss'
import { useState } from 'react'
import MediaLibrary from '../../global-components/media-library/MediaLibrary'
import { isEmpty } from 'lodash'
import { ReactComponent as StorageBoxIcon } from '../../../assets/icons/storage-box-icon.svg'
import PropTypes from 'prop-types'

const EditorComponentOptionList = ({ selectedComponent }) => {
  const [mediaLibraryOpen, setMediaLibraryOpen] = useState(false)
  const dropLevel = !!selectedComponent ? 'fields' : 'components'
  const options = !!selectedComponent
    ? selectComponent(selectedComponent.componentType)?.fields?.map(field =>
        selectField(field),
      )
    : EDITOR_COMPONENTS

  const { setNodeRef } = useDroppable({
    id: 'editor-component-option-list',
    data: {},
  })

  return (
    <div className="editor-component-option-list" ref={setNodeRef}>
      <div className="editor-component-option-list-title">
        {!!selectedComponent ? 'Fields' : 'Components'}
      </div>

      {options?.map(component => (
        <EditorComponentOption
          {...component}
          id={component.type}
          key={component.type}
          dropLevel={dropLevel}
        />
      ))}
      {!!selectedComponent && isEmpty(options) ? (
        <div className="editor-component-option-list-empty">
          <p>
            There are no additional fields available for the selected component
          </p>
        </div>
      ) : null}

      <div className="flex flex-fill align-end">
        <button
          className="btn btn--white btn--full media"
          onClick={() => setMediaLibraryOpen(true)}
        >
          <StorageBoxIcon />
          Open Media Library
        </button>
      </div>

      <MediaLibrary
        isOpen={mediaLibraryOpen}
        onCancel={() => setMediaLibraryOpen(false)}
        mode="management"
      />
    </div>
  )
}

EditorComponentOptionList.propTypes = {
  selectedComponent: PropTypes.object,
}
export default EditorComponentOptionList
