import React from 'react'
import PropTypes from 'prop-types'
import * as Yup from 'yup'
import { Formik, Form, Field, FieldArray } from 'formik'
import { get, isEmpty, isEqual } from 'lodash'
import TextField from './elements/TextField'
import SubcategoriesField from './elements/SubcategoriesField'
import IconField from './elements/IconField'
import IconColourField from './elements/IconColourField'

const createCategorySchema = Yup.object().shape({
  category: Yup.string()
    .min(2, 'Error - minimum 2 characters')
    .required('Required'),
  subcategories: Yup.array(),
  icon: Yup.string(),
  iconColour: Yup.string(),
})

function ManageCategoryForm({ category, onSubmit, onCancel }) {
  const isEditCategory = !!category
  const managedCategorySubcategories = get(category, 'subcategories', [])
  const managedCategoryIcon = get(category, 'icon.id', null)
  const managedCategoryIconColour = get(category, 'icon.colour', null)

  const initialUserValues = {
    category: category ? category.category : '',
    subcategories: !isEmpty(managedCategorySubcategories)
      ? managedCategorySubcategories.map(subcategory => {
          return { label: subcategory, value: subcategory }
        })
      : [],
    icon: managedCategoryIcon ? managedCategoryIcon : '',
    iconColour: managedCategoryIconColour ? managedCategoryIconColour : '',
  }

  const submitLabel = isEditCategory ? 'Save Category' : 'Create Category'

  return (
    <Formik
      initialValues={initialUserValues}
      validationSchema={createCategorySchema}
      onSubmit={values => {
        onSubmit(values)
      }}
    >
      {({ values, isValid, initialValues, setFieldTouched, setFieldValue }) => {
        const initialValuesAltered = isEqual(values, initialValues)
        const submitButtonDisabled = isEditCategory
          ? !isValid || initialValuesAltered
          : !isValid
        const displaySubcategoriesWarning =
          isEditCategory &&
          !isEmpty(managedCategorySubcategories) &&
          isEmpty(values.subcategories)

        return (
          <Form className="form form--modal">
            <div className="form__field full">
              <Field
                component={TextField}
                label="Name"
                name="category"
                placeholder="Enter Category Name"
                required={true}
                type="text"
              />
            </div>

            {displaySubcategoriesWarning ? (
              <p className="warning warning--orange">
                <strong>Warning</strong> you are about to remove all
                subcategories from this category. Are you sure you wish to
                proceed?
              </p>
            ) : null}

            <div
              className={`form__field ${
                values?.iconColour ? 'three-quarter' : 'full'
              }`}
            >
              <Field
                component={IconField}
                label="Icon"
                name="icon"
                placeholder="Select or Search Category Icons"
                type="text"
                setFieldTouched={setFieldTouched}
              />
            </div>

            {values?.iconColour ? (
              <div className="form__field one-quarter">
                <Field
                  component={IconColourField}
                  label="Icon Colour"
                  name="iconColour"
                  placeholder="Select Colour"
                  type="text"
                  setFieldTouched={setFieldTouched}
                />
              </div>
            ) : null}

            <div className="form__field full">
              <SubcategoriesField
                value={values.subcategories}
                onChange={value => setFieldValue('subcategories', value)}
              />
            </div>

            <div className="form__submit">
              <>
                <button
                  className="btn btn--white"
                  type="button"
                  onClick={onCancel}
                >
                  Cancel
                </button>

                <button
                  className="btn btn--dark-purple"
                  disabled={submitButtonDisabled}
                  type="submit"
                >
                  {submitButtonDisabled
                    ? 'Please ensure all field values are valid'
                    : submitLabel}
                </button>
              </>
            </div>
          </Form>
        )
      }}
    </Formik>
  )
}

ManageCategoryForm.propTypes = {
  category: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  formCancelEvent: PropTypes.func.isRequired,
}

export default ManageCategoryForm
