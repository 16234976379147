import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import {
  selectActiveClient,
  selectActiveClientId,
  selectActiveOperatingLocation,
} from '../redux/user/userSelections'
import Header from '../components/global-components/structure/header/Header'
import WhatsNewCards from '../components/consumer-flow/whats-new/WhatsNewCards'
import { selectWorkflowsState } from '../redux/workflows/workflows'
import { get, orderBy } from 'lodash'
import dayjs from 'dayjs'
import TableListGridToggle from '../components/global-components/table/TableListGridToggle'
import BrowseWorkflowsEntriesTable from '../components/consumer-flow/browse-workflows/elements/BrowseWorkflowsEntriesTable'
import { mixpanelTrackUserLayoutChange } from '../utilities/analytics/mixpanelEvents'

function WhatsNewContainer() {
  const [dataView, setDataView] = useState(
    window.localStorage.getItem('WhatsNewWorkflowDataView') || 'grid',
  )
  const activeClient = useSelector(selectActiveClient)
  const activeOperatingLocation = useSelector(selectActiveOperatingLocation)
  const workflowsState = useSelector(selectWorkflowsState)
  const workflows = get(workflowsState, 'workflows', [])
  const workflowsByClient = get(
    workflows,
    `${activeOperatingLocation}-${activeClient?.id}`,
    [],
  )
  const clientWorkflowsByDateUpdated = orderBy(
    workflowsByClient,
    function (workflow) {
      const dateUpdated = get(workflow, 'updatedAt', null)

      return dateUpdated ? dayjs(dateUpdated) : null
    },
    ['desc'],
  )

  function handleDataViewChange(value) {
    setDataView(value)
    window.localStorage.setItem('WhatsNewWorkflowDataView', value)
    mixpanelTrackUserLayoutChange({
      clientId: activeClient.id,
      clientName: activeClient.name,
      operatingLocation: activeOperatingLocation,
      pageUrl: window.location.href,
      pathPath: '/whats-new',
      layoutType: value === 'table' ? 'List View' : 'Card View',
    })
  }

  //TODO fix table scrolling
  return (
    <div className="whats-new-container view-container ">
      <Header
        activeClient={activeClient}
        activeOperatingLocation={activeOperatingLocation}
        enableBreadCrumbs={true}
        title="Workflows"
      />

      <div
        className={`whats-new${
          dataView === 'table' ? ' table__scroll-container' : ''
        }`}
      >
        <div className="container table__scroll-container-header">
          <div className="whats-new__heading">
            <h3>Whats New in Workflows</h3>
            <TableListGridToggle
              value={dataView}
              onChange={handleDataViewChange}
            />
          </div>
        </div>

        {dataView === 'grid' ? (
          <div className="container">
            <WhatsNewCards workflows={clientWorkflowsByDateUpdated} />
          </div>
        ) : (
          <BrowseWorkflowsEntriesTable
            workflows={clientWorkflowsByDateUpdated}
            title="Recently Updated"
          />
        )}
      </div>
    </div>
  )
}

export default WhatsNewContainer
