import PropTypes from 'prop-types'

const SpacerField = ({ field }) => {
  const block = field?.blocks?.[0] || {}
  const { configurations } = block
  const height = configurations?.height ? `${configurations?.height}px` : '24px'
  const style = {
    height,
  }
  return <div className="spacer-field" style={style}></div>
}

SpacerField.propTypes = {
  field: PropTypes.object,
}

export default SpacerField
