import axios from 'axios'
import { get, isEmpty } from 'lodash'

export const DUPLICATE_COMPONENT_BEGIN = 'DUPLICATE_COMPONENT_BEGIN'
export const DUPLICATE_COMPONENT_ERROR = 'DUPLICATE_COMPONENT_ERROR'
export const DUPLICATE_COMPONENT_SUCCESS = 'DUPLICATE_COMPONENT_SUCCESS'

export const duplicateComponent =
  (existingContent, successEvent, token) => async (dispatch, getState) => {
    const activeClient = getState().userSelectionsData.activeClient
    const activeClientId = get(activeClient, 'id', null)
    const activeOperatingLocation =
      getState().userSelectionsData.activeOperatingLocation
    const workflows = getState().workflowsData.workflows
    const workflowsByClient = get(
      workflows,
      `${activeOperatingLocation}-${activeClientId}`,
      [],
    )
    const workflowId = get(existingContent, 'workflowId', null)
    const workflowData = workflowsByClient.find(
      clientWorkflow => clientWorkflow?._id === workflowId,
    )
    const workflowSlug = get(workflowData, 'slug', null)

    dispatch({ type: DUPLICATE_COMPONENT_BEGIN })
    return axios({
      url: `${process.env.REACT_APP_PRODUCT_API}/api/v1/workflow/component/duplicate/${activeClientId}`,
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      data: {
        content: existingContent,
        operatingLocation: activeOperatingLocation,
        workflowId: workflowId,
        workflowSlug: workflowSlug,
      },
    })
      .then(response => {
        const workflow = get(response, 'data', [])

        if (!isEmpty(workflow)) {
          dispatch({
            type: DUPLICATE_COMPONENT_SUCCESS,
            payload: workflow,
          })
          successEvent()
        } else {
          dispatch({
            type: DUPLICATE_COMPONENT_ERROR,
            payload:
              'There was an error duplicating this component - please wait a moment, then try again.',
          })
        }
      })
      .catch(error => {
        dispatch({
          type: DUPLICATE_COMPONENT_ERROR,
          payload: error.message,
        })
      })
  }
