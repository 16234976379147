import axios from 'axios'
import { get } from 'lodash'
import { fetchWorkflow } from '../workflows/workflow'
import {
  UPDATE_STEP_BEGIN,
  UPDATE_STEP_SUCCESS,
  UPDATE_STEP_ERROR,
} from './updateStep'

export const CREATE_STEP_BEGIN = 'CREATE_STEP_BEGIN'
export const CREATE_STEP_ERROR = 'CREATE_STEP_ERROR'
export const CREATE_STEP_SUCCESS = 'CREATE_STEP_SUCCESS'

export const createStep =
  (workflowData, stepData, successEvent, token) =>
  async (dispatch, getState) => {
    const activeClient = getState().userSelectionsData.activeClient
    const activeClientId = get(activeClient, 'id', null)
    const activeOperatingLocation =
      getState().userSelectionsData.activeOperatingLocation
    const workflowId = get(workflowData, '_id', null)
    const workflowSlug = get(workflowData, 'slug', null)
    const workflowClients = workflowData?.clientId?.filter(
      clientId => clientId !== activeClientId,
    )

    dispatch({ type: CREATE_STEP_BEGIN })

    return axios({
      url: `${process.env.REACT_APP_PRODUCT_API}/api/v1/workflow/step/create/${activeClientId}`,
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      data: {
        workflowId,
        workflowSlug,
        workflowClients,
        operatingLocation: activeOperatingLocation,
        step: stepData,
      },
    })
      .then(() => {
        dispatch({
          type: CREATE_STEP_SUCCESS,
        })
        dispatch(fetchWorkflow(workflowSlug, token))
        successEvent()
      })
      .catch(error => {
        const customError = get(error, 'response.data.error', 'Error')

        dispatch({
          type: CREATE_STEP_ERROR,
          payload: customError,
        })
      })
  }

const initialState = {
  error: null,
  loading: false,
}

export default function createStepReducer(state = initialState, action) {
  switch (action.type) {
    case UPDATE_STEP_BEGIN:
    case CREATE_STEP_BEGIN:
      return { ...state, loading: true }
    case UPDATE_STEP_SUCCESS:
    case CREATE_STEP_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
      }
    case UPDATE_STEP_ERROR:
    case CREATE_STEP_ERROR:
      return { ...state, loading: false, error: action.payload }
    default:
      return state
  }
}

// SELECTORS
export const selectCreateStepState = state => state.createStepData
