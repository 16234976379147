import PropTypes from 'prop-types'
import '../../../../styles/consumer-flow/base-fields/image-field.scss'
import Modal from '../../../global-components/elements/Modal'
import { ReactComponent as CloseIcon } from '../../../../assets/icons/close-icon.svg'

import { useState } from 'react'
import LinkWrapper from '../LinkWrapper'
import clsx from 'clsx'
const ImageField = ({ field, highlight }) => {
  const [isOpen, setIsOpen] = useState(false)
  const block = field?.blocks?.[0] || {}
  const { configurations, image } = block
  const alignment = configurations?.alignment || 'left'
  const width = configurations?.width ? configurations?.width | 'px' : 'auto'
  const link = configurations?.link
  const alignments = {
    left: 'flex-start',
    center: 'center',
    right: 'flex-end',
  }
  const style = {
    justifyContent: alignments[alignment],
  }

  const imageStyle = {
    width,
  }
  const { url, alt } = image || {}
  const linkClass =
    highlight?.itemId === field._id ? 'find-replace__outline' : null

  return (
    <div className="image-field" style={style}>
      {link?.type ? (
        <LinkWrapper link={link}>
          <img
            src={url}
            alt={alt}
            style={imageStyle}
            className={clsx(linkClass)}
          />
        </LinkWrapper>
      ) : (
        <>
          {url && (
            <button
              className="image-field-button"
              onClick={() => setIsOpen(true)}
            >
              <img
                src={url}
                alt={alt}
                style={imageStyle}
                className={clsx(linkClass)}
              />
            </button>
          )}
        </>
      )}

      <Modal
        isOpen={isOpen}
        onRequestClose={() => setIsOpen(false)}
        modalClassName="modal__lightbox"
      >
        {url && <img src={url} alt={alt} />}
        <button onClick={() => setIsOpen(false)} className="modal__close">
          <CloseIcon />
        </button>
      </Modal>
    </div>
  )
}

ImageField.propTypes = {
  field: PropTypes.object,
  highlight: PropTypes.object,
}

export default ImageField
