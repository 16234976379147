import React from 'react'
import PropTypes from 'prop-types'
import { get, isEmpty } from 'lodash'
import { Link } from 'react-router-dom'
import routes from '../../../../configuration/routes'
import convertStringToSlug from '../../../../utilities/slug/convertStringToSlug'
import getIconComponent from '../../../../utilities/icon/getIconComponent'
import validateUserManagementAccess from '../../../../utilities/permissions/validateUserManagementAccess'
import { ReactComponent as ArrowRightIcon } from '../../../../assets/icons/arrow-right-icon.svg'

function RelatedWorkflowsFilters({ activeCategory, filteredWorkflows }) {
  const isManager = validateUserManagementAccess()

  return !isEmpty(filteredWorkflows) ? (
    <div className="related-workflows__entries">
      {filteredWorkflows.map(workflow => {
        const workflowId = get(workflow, '_id', null)
        const workflowName = get(workflow, 'name', null)
        const workflowSlug = get(workflow, 'slug', null)
        const workflowStatus = get(workflow, 'status', 'draft')
        const defaultCategory = get(workflow, 'categories[0].category', null)
        const workflowIcon = get(workflow, 'icon', null)
        const workflowIconColour =
          get(workflowIcon, 'colour', null) || undefined
        const Icon = getIconComponent(workflowIcon)
        const workflowUrl = activeCategory
          ? `${routes.browseWorkflows}/${convertStringToSlug(
              activeCategory,
            )}/${workflowSlug}`
          : defaultCategory
          ? `${routes.browseWorkflows}/${convertStringToSlug(
              defaultCategory,
            )}/${workflowSlug}`
          : null

        const hasRequiredContent = workflowUrl && workflowName

        return hasRequiredContent ? (
          <div key={workflowId} className="related-workflows__entry">
            {isManager && workflowStatus ? (
              <div className={`status-flag lg ${workflowStatus}`}>
                {workflowStatus}
              </div>
            ) : null}

            {Icon ? (
              <div className="related-workflows__entry-icon">
                <Icon colour={workflowIconColour ? workflowIconColour : null} />
              </div>
            ) : null}

            <div className="related-workflows__entry-content">
              {workflowName ? (
                <p className="related-workflows__entry-name">{workflowName}</p>
              ) : null}
            </div>

            <Link className="btn btn--light-green" to={workflowUrl}>
              View <ArrowRightIcon />
            </Link>
          </div>
        ) : null
      })}
    </div>
  ) : null
}

RelatedWorkflowsFilters.propTypes = {
  activeCategory: PropTypes.string,
  filteredWorkflows: PropTypes.array,
}

export default RelatedWorkflowsFilters
