import React from 'react'

const routes = {
  dashboard: '/',
  browseWorkflows: '/browse',
  manageWorkflows: '/manage',
  viewMyWorkflows: '/view-workflows',
  viewMyWorkflowsCategories: '/view-workflows/categories',
  whatsNew: '/whats-new',
  findReplace: '/find-replace',
  manageContentLocks: '/manage-content-locks',
}
export default routes

export const routeData = [
  {
    accessPermissions: [
      'Administrator',
      'Edit',
      'Manager',
      'Super',
      'User',
      'View',
    ],
    label: 'Home',
    path: routes.dashboard,
    productLicenses: [],
  },
  {
    accessPermissions: [
      'Administrator',
      'Edit',
      'Manager',
      'Super',
      'User',
      'View',
    ],
    label: 'Browse Workflows',
    path: routes.browseWorkflows,
    productLicenses: ['workflows'],
  },
  {
    accessPermissions: ['Administrator', 'Edit', 'Manager', 'Super'],
    label: 'Create A New Workflow',
    path: routes.manageWorkflows,
    productLicenses: ['workflows'],
  },
  {
    accessPermissions: ['Administrator', 'Edit', 'Manager', 'Super'],
    label: 'View My Workflows',
    path: routes.viewMyWorkflows,
    productLicenses: ['workflows'],
  },
  {
    accessPermissions: ['Administrator', 'Edit', 'Manager', 'Super'],
    label: 'Find and Replace',
    path: routes.findReplace,
  },
  {
    accessPermissions: ['Administrator', 'Edit', 'Manager', 'Super'],
    label: 'Manage Content Locks',
    path: routes.manageContentLocks,
  },
  {
    accessPermissions: [
      'Administrator',
      'Edit',
      'Manager',
      'Super',
      'User',
      'View',
    ],
    label: 'Whats New',
    path: routes.whatsNew,
    productLicenses: ['workflows'],
  },
]
