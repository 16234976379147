import { useEffect, useRef } from 'react'

const Tooltip = () => {
  const ref = useRef(null)
  const contentRef = useRef(null)
  useEffect(() => {
    const onHover = evt => {
      const target = evt.target.closest('[data-tooltip]')
      if (ref.current && contentRef.current && target) {
        const bounds = target.getBoundingClientRect()
        ref.current.style.top = `${bounds.top}px`
        ref.current.style.left = `${bounds.left}px`
        ref.current.style.width = `${bounds.width}px`
        ref.current.style.height = `${bounds.height}px`
        ref.current.dataset.offset = target.dataset.tooltipOffset
        contentRef.current.innerText = target.dataset.tooltip
      } else {
        ref.current.style.top = '-1000px'
      }
    }
    if (ref.current && contentRef.current) {
      document.addEventListener('mousemove', onHover)
    }

    return () => document.removeEventListener('mousemove', onHover)
  }, [ref.current])
  return (
    <div ref={ref} className="global-tooltip-container">
      <div ref={contentRef} className="global-tooltip"></div>{' '}
    </div>
  )
}
export default Tooltip
