// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.table-list-grid-toggle button + button {
  margin-left: 4px;
}`, "",{"version":3,"sources":["webpack://./src/styles/global-components/table/table-list-grid-toggle.scss"],"names":[],"mappings":"AACE;EACE,gBAAA;AAAJ","sourcesContent":[".table-list-grid-toggle {\n  button + button {\n    margin-left: 4px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
