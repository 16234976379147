import React from 'react'
import PropTypes from 'prop-types'

function ImportIcon({ colour }, props) {
  return (
    <svg
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M35.84 4.88H32.24V2.72C32.24 2.52904 32.1641 2.34591 32.0291 2.21088C31.8941 2.07586 31.711 2 31.52 2C31.329 2 31.1459 2.07586 31.0109 2.21088C30.8759 2.34591 30.8 2.52904 30.8 2.72V4.88H9.2V2.72C9.2 2.52904 9.12414 2.34591 8.98912 2.21088C8.85409 2.07586 8.67096 2 8.48 2C8.28904 2 8.10591 2.07586 7.97088 2.21088C7.83586 2.34591 7.76 2.52904 7.76 2.72V4.88H4.16C3.58713 4.88 3.03773 5.10757 2.63265 5.51265C2.22757 5.91773 2 6.46713 2 7.04V11.36C2.00125 11.8056 2.14031 12.24 2.39812 12.6035C2.65592 12.967 3.01984 13.2419 3.44 13.3904V35.12C3.44 35.8838 3.74343 36.6164 4.28353 37.1565C4.82364 37.6966 5.55618 38 6.32 38H33.68C34.4438 38 35.1764 37.6966 35.7165 37.1565C36.2566 36.6164 36.56 35.8838 36.56 35.12V13.3904C36.9802 13.2419 37.3441 12.967 37.6019 12.6035C37.8597 12.24 37.9987 11.8056 38 11.36V7.04C38 6.46713 37.7724 5.91773 37.3674 5.51265C36.9623 5.10757 36.4129 4.88 35.84 4.88ZM35.12 35.12C35.12 35.5019 34.9683 35.8682 34.6982 36.1382C34.4282 36.4083 34.0619 36.56 33.68 36.56H6.32C5.93809 36.56 5.57182 36.4083 5.30177 36.1382C5.03171 35.8682 4.88 35.5019 4.88 35.12V13.52H35.12V35.12ZM36.56 11.36C36.56 11.551 36.4841 11.7341 36.3491 11.8691C36.2141 12.0041 36.031 12.08 35.84 12.08H4.16C3.96904 12.08 3.78591 12.0041 3.65088 11.8691C3.51586 11.7341 3.44 11.551 3.44 11.36V7.04C3.44 6.84904 3.51586 6.66591 3.65088 6.53088C3.78591 6.39586 3.96904 6.32 4.16 6.32H7.76V8.48C7.76 8.67096 7.83586 8.85409 7.97088 8.98912C8.10591 9.12414 8.28904 9.2 8.48 9.2C8.67096 9.2 8.85409 9.12414 8.98912 8.98912C9.12414 8.85409 9.2 8.67096 9.2 8.48V6.32H30.8V8.48C30.8 8.67096 30.8759 8.85409 31.0109 8.98912C31.1459 9.12414 31.329 9.2 31.52 9.2C31.711 9.2 31.8941 9.12414 32.0291 8.98912C32.1641 8.85409 32.24 8.67096 32.24 8.48V6.32H35.84C36.031 6.32 36.2141 6.39586 36.3491 6.53088C36.4841 6.66591 36.56 6.84904 36.56 7.04V11.36Z"
        fill={colour}
      />
      <path
        d="M16.4 20C16.8272 20 17.2448 19.8733 17.6 19.636C17.9552 19.3986 18.2321 19.0613 18.3956 18.6666C18.5591 18.2719 18.6018 17.8376 18.5185 17.4186C18.4351 16.9996 18.2294 16.6147 17.9273 16.3126C17.6253 16.0106 17.2404 15.8048 16.8214 15.7215C16.4024 15.6382 15.9681 15.6809 15.5734 15.8444C15.1787 16.0079 14.8414 16.2848 14.604 16.64C14.3667 16.9952 14.24 17.4128 14.24 17.84C14.24 18.4129 14.4676 18.9623 14.8726 19.3673C15.2777 19.7724 15.8271 20 16.4 20ZM16.4 17.12C16.5909 17.12 16.7741 17.1959 16.9091 17.3309C17.0441 17.4659 17.12 17.649 17.12 17.84C17.12 18.0309 17.0441 18.2141 16.9091 18.3491C16.7741 18.4841 16.5909 18.56 16.4 18.56C16.209 18.56 16.0259 18.4841 15.8909 18.3491C15.7558 18.2141 15.68 18.0309 15.68 17.84C15.68 17.649 15.7558 17.4659 15.8909 17.3309C16.0259 17.1959 16.209 17.12 16.4 17.12Z"
        fill={colour}
      />
      <path
        d="M23.5999 20C24.0271 20 24.4448 19.8733 24.8 19.636C25.1552 19.3986 25.432 19.0613 25.5955 18.6666C25.759 18.2719 25.8018 17.8376 25.7184 17.4186C25.6351 16.9996 25.4294 16.6147 25.1273 16.3126C24.8252 16.0106 24.4403 15.8048 24.0213 15.7215C23.6023 15.6382 23.168 15.6809 22.7733 15.8444C22.3787 16.0079 22.0413 16.2848 21.804 16.64C21.5666 16.9952 21.4399 17.4128 21.4399 17.84C21.4399 18.4129 21.6675 18.9623 22.0726 19.3673C22.4777 19.7724 23.0271 20 23.5999 20ZM23.5999 17.12C23.7909 17.12 23.974 17.1959 24.1091 17.3309C24.2441 17.4659 24.3199 17.649 24.3199 17.84C24.3199 18.0309 24.2441 18.2141 24.1091 18.3491C23.974 18.4841 23.7909 18.56 23.5999 18.56C23.409 18.56 23.2259 18.4841 23.0908 18.3491C22.9558 18.2141 22.8799 18.0309 22.8799 17.84C22.8799 17.649 22.9558 17.4659 23.0908 17.3309C23.2259 17.1959 23.409 17.12 23.5999 17.12Z"
        fill={colour}
      />
      <path
        d="M30.8 20C31.2272 20 31.6448 19.8733 32 19.636C32.3553 19.3986 32.6321 19.0613 32.7956 18.6666C32.9591 18.2719 33.0019 17.8376 32.9185 17.4186C32.8352 16.9996 32.6294 16.6147 32.3274 16.3126C32.0253 16.0106 31.6404 15.8048 31.2214 15.7215C30.8024 15.6382 30.3681 15.6809 29.9734 15.8444C29.5787 16.0079 29.2414 16.2848 29.004 16.64C28.7667 16.9952 28.64 17.4128 28.64 17.84C28.64 18.4129 28.8676 18.9623 29.2727 19.3673C29.6777 19.7724 30.2271 20 30.8 20ZM30.8 17.12C30.991 17.12 31.1741 17.1959 31.3091 17.3309C31.4442 17.4659 31.52 17.649 31.52 17.84C31.52 18.0309 31.4442 18.2141 31.3091 18.3491C31.1741 18.4841 30.991 18.56 30.8 18.56C30.6091 18.56 30.4259 18.4841 30.2909 18.3491C30.1559 18.2141 30.08 18.0309 30.08 17.84C30.08 17.649 30.1559 17.4659 30.2909 17.3309C30.4259 17.1959 30.6091 17.12 30.8 17.12Z"
        fill={colour}
      />
      <path
        d="M9.20004 26.48C9.62725 26.48 10.0449 26.3533 10.4001 26.116C10.7553 25.8786 11.0321 25.5413 11.1956 25.1466C11.3591 24.7519 11.4019 24.3176 11.3185 23.8986C11.2352 23.4796 11.0295 23.0947 10.7274 22.7927C10.4253 22.4906 10.0404 22.2849 9.62143 22.2015C9.20244 22.1182 8.76813 22.1609 8.37344 22.3244C7.97875 22.4879 7.64141 22.7648 7.40406 23.12C7.16672 23.4752 7.04004 23.8928 7.04004 24.32C7.04004 24.8929 7.26761 25.4423 7.67269 25.8474C8.07777 26.2524 8.62717 26.48 9.20004 26.48ZM9.20004 23.6C9.391 23.6 9.57413 23.6759 9.70916 23.8109C9.84418 23.9459 9.92004 24.129 9.92004 24.32C9.92004 24.511 9.84418 24.6941 9.70916 24.8291C9.57413 24.9641 9.391 25.04 9.20004 25.04C9.00908 25.04 8.82595 24.9641 8.69092 24.8291C8.5559 24.6941 8.48004 24.511 8.48004 24.32C8.48004 24.129 8.5559 23.9459 8.69092 23.8109C8.82595 23.6759 9.00908 23.6 9.20004 23.6Z"
        fill={colour}
      />
      <path
        d="M21.6488 31.52C21.512 32.6648 21.3968 32.96 21.8 33.2768C22.2032 33.5936 22.52 33.3632 23.6 32.8808C24.7664 33.3992 24.7664 33.4208 24.968 33.4208C25.0703 33.4215 25.1716 33.4005 25.2651 33.359C25.3586 33.3176 25.4422 33.2566 25.5103 33.1803C25.5784 33.104 25.6295 33.0141 25.6602 32.9165C25.6908 32.8189 25.7003 32.7159 25.688 32.6144L25.5512 31.52L26.336 30.7064C26.4268 30.6127 26.4905 30.4961 26.5202 30.369C26.5498 30.2419 26.5444 30.1092 26.5045 29.985C26.4645 29.8607 26.3916 29.7497 26.2935 29.6637C26.1953 29.5777 26.0756 29.52 25.9472 29.4968L24.7952 29.2736L24.2192 28.3016C24.1555 28.1942 24.0649 28.1051 23.9563 28.0433C23.8477 27.9815 23.725 27.949 23.6 27.949C23.4751 27.949 23.3523 27.9815 23.2437 28.0433C23.1352 28.1051 23.0446 28.1942 22.9808 28.3016L22.4048 29.2736C21.1736 29.5112 20.8568 29.4968 20.6984 29.9936C20.54 30.4904 20.828 30.6416 21.6488 31.52ZM22.9952 30.6272C23.0969 30.6081 23.1932 30.5674 23.2777 30.5078C23.3622 30.4481 23.4328 30.371 23.4848 30.2816L23.6 30.08C23.6506 30.2117 23.7318 30.3293 23.837 30.4233C23.9422 30.5172 24.0683 30.5846 24.2048 30.62C24.6368 30.7064 23.9888 30.62 24.0968 31.52C23.9513 31.4177 23.7778 31.3629 23.6 31.3629C23.4222 31.3629 23.2487 31.4177 23.1032 31.52C23.1386 31.3878 23.1407 31.2489 23.1093 31.1157C23.0779 30.9825 23.014 30.8592 22.9232 30.7568C22.8008 30.6128 22.7792 30.6488 22.9952 30.6056V30.6272Z"
        fill={colour}
      />
      <path
        d="M17.12 23.6H15.68C15.489 23.6 15.3059 23.6759 15.1708 23.8109C15.0358 23.9459 14.96 24.1291 14.96 24.32C14.96 24.511 15.0358 24.6941 15.1708 24.8291C15.3059 24.9641 15.489 25.04 15.68 25.04H17.12C17.3109 25.04 17.4941 24.9641 17.6291 24.8291C17.7641 24.6941 17.84 24.511 17.84 24.32C17.84 24.1291 17.7641 23.9459 17.6291 23.8109C17.4941 23.6759 17.3109 23.6 17.12 23.6Z"
        fill={colour}
      />
      <path
        d="M17.12 30.08H15.68C15.489 30.08 15.3059 30.1558 15.1708 30.2909C15.0358 30.4259 14.96 30.609 14.96 30.8C14.96 30.9909 15.0358 31.1741 15.1708 31.3091C15.3059 31.4441 15.489 31.52 15.68 31.52H17.12C17.3109 31.52 17.4941 31.4441 17.6291 31.3091C17.7641 31.1741 17.84 30.9909 17.84 30.8C17.84 30.609 17.7641 30.4259 17.6291 30.2909C17.4941 30.1558 17.3109 30.08 17.12 30.08Z"
        fill={colour}
      />
      <path
        d="M9.92001 30.08H8.48001C8.28905 30.08 8.10592 30.1558 7.97089 30.2909C7.83587 30.4259 7.76001 30.609 7.76001 30.8C7.76001 30.9909 7.83587 31.1741 7.97089 31.3091C8.10592 31.4441 8.28905 31.52 8.48001 31.52H9.92001C10.111 31.52 10.2941 31.4441 10.4291 31.3091C10.5642 31.1741 10.64 30.9909 10.64 30.8C10.64 30.609 10.5642 30.4259 10.4291 30.2909C10.2941 30.1558 10.111 30.08 9.92001 30.08Z"
        fill={colour}
      />
      <path
        d="M22.88 23.6C22.6891 23.6 22.5059 23.6759 22.3709 23.8109C22.2359 23.9459 22.16 24.1291 22.16 24.32C22.16 24.511 22.2359 24.6941 22.3709 24.8291C22.5059 24.9641 22.6891 25.04 22.88 25.04H24.32C24.511 25.04 24.6941 24.9641 24.8292 24.8291C24.9642 24.6941 25.04 24.511 25.04 24.32C25.04 24.1291 24.9642 23.9459 24.8292 23.8109C24.6941 23.6759 24.511 23.6 24.32 23.6H22.88Z"
        fill={colour}
      />
      <path
        d="M31.52 23.6H30.08C29.889 23.6 29.7059 23.6759 29.5709 23.8109C29.4358 23.9459 29.36 24.1291 29.36 24.32C29.36 24.511 29.4358 24.6941 29.5709 24.8291C29.7059 24.9641 29.889 25.04 30.08 25.04H31.52C31.7109 25.04 31.8941 24.9641 32.0291 24.8291C32.1641 24.6941 32.24 24.511 32.24 24.32C32.24 24.1291 32.1641 23.9459 32.0291 23.8109C31.8941 23.6759 31.7109 23.6 31.52 23.6Z"
        fill={colour}
      />
    </svg>
  )
}

ImportIcon.defaultProps = {
  colour: '#000000',
}

ImportIcon.propTypes = {
  colour: PropTypes.string,
}

export default ImportIcon
