import PropTypes from 'prop-types'
import Modal from '../../global-components/elements/Modal'
import React, { useEffect, useState } from 'react'
import { ReactComponent as CloseModalIcon } from '../../../assets/icons/close-icon.svg'
import axios from 'axios'
import useAuthToken from '../../../hooks/useAuthToken'

const ManageContentLocksDumpModal = ({ isOpen, onRequestClose }) => {
  const { token } = useAuthToken({})
  const [data, setData] = useState(null)
  useEffect(() => {
    if (!data && token) {
      fetchData()
    }
  }, [token])

  function fetchData() {
    return axios({
      url: `${process.env.REACT_APP_PRODUCT_API}/api/v1/sockets/dump`,
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      data: {},
    }).then(res => {
      setData(res?.data?.dump)
    })
  }

  return (
    <Modal
      modalClassName="modal--delete-workflow modal--lg"
      isOpen={isOpen}
      onRequestClose={onRequestClose}
    >
      <div className="modal__header">
        <h3 className="flex align-center">Socket Server Status</h3>
        <button className="modal__close" onClick={onRequestClose}>
          <CloseModalIcon />
        </button>
      </div>
      <div className="modal__body">
        <pre>{JSON.stringify(data, null, 2)}</pre>
      </div>
    </Modal>
  )
}

ManageContentLocksDumpModal.propTypes = {
  isOpen: PropTypes.bool,
  onRequestClose: PropTypes.func,
}

export default ManageContentLocksDumpModal
