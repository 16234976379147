import React from 'react'
import PropTypes from 'prop-types'

const FindReplaceMatchedText = ({ match, replace }) => {
  const find = match?.[0]
  const input = match?.input
  const index = match?.index
  const contextAmount = 15
  const startIndex = index - contextAmount
  const endIndex = index + find.length + contextAmount
  const startOfString = input.substring(startIndex, index)
  const endOfString = input.substring(index + find.length, endIndex)
  return (
    <span>
      {startOfString}
      <span className="find-replace__highlight">
        {!!replace ? replace : find}
      </span>
      {endOfString}
    </span>
  )
}

FindReplaceMatchedText.propTypes = {
  match: PropTypes.array,
  replace: PropTypes.string,
}

export default FindReplaceMatchedText
