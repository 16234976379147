import React from 'react'
import PropTypes from 'prop-types'

function ProtectionIcon({ colour }, props) {
  return (
    <svg
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M20.2914 2.07555C20.2023 2.02603 20.102 2.00005 20 2.00005C19.898 2.00005 19.7978 2.02603 19.7086 2.07555L8.90863 8.07555C8.81525 8.12742 8.73741 8.20328 8.68316 8.2953C8.6289 8.38732 8.6002 8.49215 8.60001 8.59897C8.60001 8.75587 8.60923 12.4876 9.97131 16.5801C10.7785 19.0054 11.8962 21.0484 13.2933 22.6523C15.06 24.6805 17.2723 26.0038 19.8688 26.5855C19.9554 26.6049 20.0452 26.6049 20.1318 26.5855C20.2481 26.5594 23.0138 25.9147 25.7966 23.3576C27.4246 21.8617 28.7216 20.0173 29.6523 17.8756C30.8062 15.2197 31.3942 12.0993 31.3998 8.60122C31.4 8.49404 31.3714 8.38877 31.3172 8.29636C31.2629 8.20394 31.1848 8.12774 31.0912 8.0757L20.2914 2.07555ZM28.5695 17.3561C27.7141 19.3394 26.5238 21.0466 25.0318 22.4303C22.7988 24.5011 20.5352 25.2295 19.991 25.3822C17.7149 24.8431 15.7701 23.6641 14.2085 21.8763C12.917 20.3975 11.8775 18.4975 11.1196 16.2299C10.0452 13.0164 9.84546 9.95684 9.80841 8.94839L20 3.28635L30.1974 8.95162C30.155 12.1232 29.6075 14.9491 28.5695 17.3561Z"
        fill={colour}
      />
      <path
        d="M19.7086 5.50748L12.0194 9.77925C11.9167 9.83628 11.8331 9.92218 11.7788 10.0263C11.7245 10.1304 11.7019 10.2482 11.7139 10.365C11.8501 11.6903 12.1601 13.7036 12.8637 15.7686C13.5498 17.7831 14.4711 19.4541 15.6021 20.7351C16.7687 22.0565 18.1221 22.9441 19.7393 23.4485C19.8688 23.489 20.0083 23.4843 20.1348 23.4352C21.5692 22.8632 22.8778 22.016 23.9868 20.9412C26.5718 18.4454 28.0368 14.8898 28.3403 10.3731C28.3479 10.2595 28.3231 10.146 28.2686 10.046C28.2142 9.94599 28.1324 9.86354 28.0328 9.80828L20.2914 5.50748C20.2022 5.45796 20.102 5.43198 20 5.43198C19.898 5.43198 19.7978 5.45796 19.7086 5.50748ZM27.1144 10.6707C26.778 14.7005 25.4462 17.8641 23.1533 20.078C22.2097 20.992 21.1056 21.7243 19.8964 22.2379C18.5823 21.7909 17.4701 21.038 16.5016 19.9411C15.4762 18.7796 14.6341 17.2456 13.9995 15.3819C13.4877 13.8396 13.1364 12.2487 12.951 10.6344L20 6.71828L27.1144 10.6707Z"
        fill={colour}
      />
      <path
        d="M18.6243 12.9758C18.5686 12.92 18.5024 12.8758 18.4296 12.8457C18.3568 12.8155 18.2788 12.8 18.2 12.8C18.1212 12.8 18.0432 12.8155 17.9704 12.8457C17.8976 12.8758 17.8315 12.92 17.7757 12.9758L15.9757 14.7758C15.92 14.8315 15.8758 14.8976 15.8457 14.9704C15.8155 15.0432 15.8 15.1212 15.8 15.2C15.8 15.2788 15.8155 15.3569 15.8457 15.4296C15.8758 15.5024 15.92 15.5686 15.9757 15.6243L18.9757 18.6243C19.0315 18.68 19.0976 18.7242 19.1704 18.7544C19.2432 18.7845 19.3212 18.8001 19.4 18.8001C19.4788 18.8001 19.5568 18.7845 19.6296 18.7544C19.7024 18.7242 19.7686 18.68 19.8243 18.6243L25.2243 13.2243C25.28 13.1686 25.3242 13.1024 25.3544 13.0296C25.3845 12.9569 25.4 12.8788 25.4 12.8C25.4 12.7212 25.3845 12.6432 25.3544 12.5704C25.3242 12.4976 25.28 12.4315 25.2243 12.3758L23.4243 10.5758C23.3686 10.52 23.3024 10.4758 23.2296 10.4457C23.1568 10.4155 23.0788 10.4 23 10.4C22.9212 10.4 22.8432 10.4155 22.7704 10.4457C22.6976 10.4758 22.6314 10.52 22.5757 10.5758L19.4 13.7516L18.6243 12.9758ZM23 11.8485L23.9515 12.8L19.4 17.3516L17.2485 15.2L18.2 14.2485L18.9757 15.0243C19.0315 15.08 19.0976 15.1242 19.1704 15.1544C19.2432 15.1845 19.3212 15.2001 19.4 15.2001C19.4788 15.2001 19.5568 15.1845 19.6296 15.1544C19.7024 15.1242 19.7686 15.08 19.8243 15.0243L23 11.8485Z"
        fill={colour}
      />
      <path
        d="M2.6 35.6H7.4C7.55913 35.6 7.71174 35.5368 7.82426 35.4243C7.93679 35.3118 8 35.1591 8 35V34.5766L21.0485 37.9806C21.1977 38.0194 21.3562 37.9994 21.491 37.9245L37.691 28.9245C37.7846 28.8726 37.8626 28.7966 37.917 28.7043C37.9713 28.6121 38 28.5071 38 28.4C38 27.9214 37.8767 27.4509 37.642 27.0339C37.4073 26.6168 37.0691 26.2672 36.6601 26.0189C36.251 25.7705 35.7848 25.6317 35.3065 25.6159C34.8282 25.6001 34.3539 25.7078 33.9293 25.9285L29.9205 28.0131C29.7505 27.6501 29.5096 27.3249 29.2119 27.0565C28.9142 26.7881 28.5659 26.582 28.1873 26.4503C27.8087 26.3186 27.4077 26.264 27.0077 26.2897C26.6077 26.3154 26.2169 26.4209 25.8583 26.6L19.9408 29.559L11.765 27.2231C11.7113 27.2078 11.6558 27.2 11.6 27.2H8V26.6C8 26.4409 7.93679 26.2883 7.82426 26.1758C7.71174 26.0632 7.55913 26 7.4 26H2.6C2.44087 26 2.28826 26.0632 2.17574 26.1758C2.06321 26.2883 2 26.4409 2 26.6V35C2 35.1591 2.06321 35.3118 2.17574 35.4243C2.28826 35.5368 2.44087 35.6 2.6 35.6ZM8 28.4H11.516L19.835 30.7769C19.9794 30.8182 20.1339 30.8038 20.2682 30.7366L26.3949 27.6733C26.6342 27.5506 26.8984 27.4842 27.1673 27.4793C27.4362 27.4744 27.7026 27.5311 27.9463 27.6451C28.353 27.8291 28.6767 28.158 28.8544 28.5676L22.1233 32.0675C21.982 32.1409 21.8757 32.2674 21.8278 32.4192C21.7798 32.5709 21.7941 32.7355 21.8675 32.8768C21.9409 33.018 22.0674 33.1243 22.2192 33.1722C22.3709 33.2202 22.5355 33.2059 22.6768 33.1325L34.4825 26.9932C34.699 26.8807 34.9386 26.8197 35.1825 26.8148C35.4265 26.81 35.6683 26.8615 35.8891 26.9653C36.1099 27.0691 36.3038 27.2225 36.4557 27.4134C36.6076 27.6044 36.7135 27.8278 36.765 28.0663L21.1185 36.7588L8 33.3364V28.4ZM3.2 27.2H6.8V34.4H3.2V27.2Z"
        fill={colour}
      />
    </svg>
  )
}

ProtectionIcon.defaultProps = {
  colour: '#000000',
}

ProtectionIcon.propTypes = {
  colour: PropTypes.string,
}

export default ProtectionIcon
