import React from 'react'
import PropTypes from 'prop-types'

import '../../../styles/global-components/elements/error-boundary.scss'

function ErrorBoundary({
  ctaEvent,
  ctaLabel,
  containerClasses,
  errorMessage,
  errorTitle,
  responseError,
}) {
  return (
    <div className={containerClasses || 'error-boundary section'}>
      {errorTitle && <h3>{errorTitle}</h3>}

      <p className="remove-margin">
        {errorMessage ||
          'There was an issue retrieving user - please wait a moment, then try again.'}
      </p>

      {responseError && (
        <p className="element-hidden">Error Message: {responseError}</p>
      )}

      {ctaEvent && ctaLabel ? (
        <button className="btn btn--white" onClick={() => ctaEvent()}>
          {ctaLabel}
        </button>
      ) : null}
    </div>
  )
}

ErrorBoundary.defaultProps = {
  containerClasses: null,
  ctaEvent: null,
  ctaLabel: null,
  errorMessage: null,
  errorTitle: null,
  responseError: null,
}
ErrorBoundary.propTypes = {
  containerClasses: PropTypes.string,
  ctaEvent: PropTypes.func,
  ctaLabel: PropTypes.string,
  errorMessage: PropTypes.string,
  errorTitle: PropTypes.string,
  responseError: PropTypes.string,
}

export default ErrorBoundary
