import React from 'react'
import PropTypes from 'prop-types'

function InsurancePolicyIcon({ colour }, props) {
  return (
    <svg
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M31.4214 33.3642L32.6213 37.5639C32.6571 37.6893 32.7328 37.7995 32.8369 37.878C32.941 37.9566 33.0678 37.999 33.1982 37.999C33.3285 37.999 33.4554 37.9566 33.5595 37.878C33.6635 37.7995 33.7392 37.6893 33.775 37.5639L34.975 33.3642C34.9902 33.3105 34.9978 33.255 34.9975 33.1992H34.9981V11.1037C35.3487 11.2281 35.6523 11.4579 35.8671 11.7617C36.082 12.0655 36.1975 12.4283 36.198 12.8004V18.8C36.198 18.8932 36.2197 18.985 36.2614 19.0683L36.8613 20.2683C36.8962 20.3394 36.9448 20.4029 37.0043 20.4551C37.0638 20.5073 37.1331 20.5472 37.2081 20.5726C37.2831 20.5979 37.3624 20.6081 37.4414 20.6027C37.5204 20.5972 37.5976 20.5762 37.6684 20.5407C37.7392 20.5053 37.8023 20.4562 37.8541 20.3963C37.9058 20.3364 37.9452 20.2668 37.97 20.1916C37.9947 20.1164 38.0043 20.037 37.9982 19.9581C37.9921 19.8791 37.9705 19.8022 37.9345 19.7316L37.3979 18.6583V12.8004C37.397 12.005 37.0807 11.2425 36.5183 10.6802C35.9559 10.1178 35.1934 9.80143 34.3981 9.80054H33.7981V9.20057C33.7981 9.04145 33.7349 8.88885 33.6224 8.77633C33.5099 8.66382 33.3573 8.60061 33.1982 8.60061C33.0391 8.60061 32.8865 8.66382 32.7739 8.77633C32.6614 8.88885 32.5982 9.04145 32.5982 9.20057V9.80054H31.9983C31.8391 9.80054 31.6865 9.86375 31.574 9.97626C31.4615 10.0888 31.3983 10.2414 31.3983 10.4005V33.1992H31.3993C31.3989 33.2549 31.4063 33.3105 31.4214 33.3642ZM33.1982 35.215L32.7937 33.7991H33.6027L33.1982 35.215ZM32.5982 11.0005H33.7981V12.2004H32.5982V11.0005ZM32.5982 13.4003H33.7981V32.5992H32.5982V13.4003Z"
        fill={colour}
      />
      <path
        d="M2.59997 35.599H4.99983V37.3989C4.99983 37.5581 5.06304 37.7107 5.17555 37.8232C5.28807 37.9357 5.44067 37.9989 5.59979 37.9989H29.5984C29.7575 37.9989 29.9101 37.9357 30.0226 37.8232C30.1351 37.7107 30.1984 37.5581 30.1984 37.3989V5.00082C30.1984 4.8417 30.1351 4.68909 30.0226 4.57658C29.9101 4.46406 29.7575 4.40085 29.5984 4.40085H27.1985V2.60096C27.1985 2.44184 27.1353 2.28923 27.0228 2.17672C26.9103 2.0642 26.7577 2.00099 26.5986 2.00099H2.59997C2.44084 2.00099 2.28824 2.0642 2.17573 2.17672C2.06321 2.28923 2 2.44184 2 2.60096V34.9991C2 35.1582 2.06321 35.3108 2.17573 35.4233C2.28824 35.5358 2.44084 35.599 2.59997 35.599ZM28.9984 5.60078V36.799H6.19976V5.60078H28.9984ZM3.19993 3.20092H25.9986V4.40085H5.59979C5.44067 4.40085 5.28807 4.46406 5.17555 4.57658C5.06304 4.68909 4.99983 4.8417 4.99983 5.00082V34.3991H3.19993V3.20092Z"
        fill={colour}
      />
      <path
        d="M11.292 15.1156C11.3859 15.1716 11.4934 15.2009 11.6028 15.2002C11.7122 15.1996 11.8193 15.169 11.9126 15.1119C12.047 15.0297 15.1992 13.0547 15.1992 9.20058C15.1992 9.09697 15.1724 8.99513 15.1214 8.90497C15.0703 8.81482 14.9968 8.73941 14.908 8.68611L11.9081 6.88621C11.8149 6.83027 11.7082 6.80071 11.5994 6.80071C11.4907 6.80071 11.384 6.83027 11.2908 6.88621L8.29094 8.68611C8.20256 8.73913 8.12934 8.81403 8.07832 8.90357C8.0273 8.99312 8.00021 9.0943 7.99965 9.19735C7.99875 9.35807 8.01593 13.1625 11.292 15.1156ZM11.5994 8.10039L13.9896 9.53446C13.8508 11.9017 12.2632 13.3592 11.5858 13.8773C9.68765 12.5467 9.29925 10.3507 9.21991 9.52808L11.5994 8.10039Z"
        fill={colour}
      />
      <path
        d="M16.9991 11.0005H25.3986C25.5578 11.0005 25.7104 10.9373 25.8229 10.8247C25.9354 10.7122 25.9986 10.5596 25.9986 10.4005C25.9986 10.2414 25.9354 10.0888 25.8229 9.97626C25.7104 9.86375 25.5578 9.80054 25.3986 9.80054H16.9991C16.84 9.80054 16.6874 9.86375 16.5749 9.97626C16.4624 10.0888 16.3992 10.2414 16.3992 10.4005C16.3992 10.5596 16.4624 10.7122 16.5749 10.8247C16.6874 10.9373 16.84 11.0005 16.9991 11.0005Z"
        fill={colour}
      />
      <path
        d="M16.9991 13.4003H25.3986C25.5578 13.4003 25.7104 13.3371 25.8229 13.2246C25.9354 13.1121 25.9986 12.9595 25.9986 12.8004C25.9986 12.6412 25.9354 12.4886 25.8229 12.3761C25.7104 12.2636 25.5578 12.2004 25.3986 12.2004H16.9991C16.84 12.2004 16.6874 12.2636 16.5749 12.3761C16.4624 12.4886 16.3992 12.6412 16.3992 12.8004C16.3992 12.9595 16.4624 13.1121 16.5749 13.2246C16.6874 13.3371 16.84 13.4003 16.9991 13.4003Z"
        fill={colour}
      />
      <path
        d="M7.99965 18.2001H27.1985C27.3577 18.2001 27.5103 18.1368 27.6228 18.0243C27.7353 17.9118 27.7985 17.7592 27.7985 17.6001C27.7985 17.441 27.7353 17.2884 27.6228 17.1758C27.5103 17.0633 27.3577 17.0001 27.1985 17.0001H7.99965C7.84053 17.0001 7.68793 17.0633 7.57541 17.1758C7.4629 17.2884 7.39969 17.441 7.39969 17.6001C7.39969 17.7592 7.4629 17.9118 7.57541 18.0243C7.68793 18.1368 7.84053 18.2001 7.99965 18.2001Z"
        fill={colour}
      />
      <path
        d="M7.99965 20.5999H27.1985C27.3577 20.5999 27.5103 20.5367 27.6228 20.4242C27.7353 20.3117 27.7985 20.1591 27.7985 19.9999C27.7985 19.8408 27.7353 19.6882 27.6228 19.5757C27.5103 19.4632 27.3577 19.4 27.1985 19.4H7.99965C7.84053 19.4 7.68793 19.4632 7.57541 19.5757C7.4629 19.6882 7.39969 19.8408 7.39969 19.9999C7.39969 20.1591 7.4629 20.3117 7.57541 20.4242C7.68793 20.5367 7.84053 20.5999 7.99965 20.5999Z"
        fill={colour}
      />
      <path
        d="M7.99965 22.9998H27.1985C27.3577 22.9998 27.5103 22.9366 27.6228 22.824C27.7353 22.7115 27.7985 22.5589 27.7985 22.3998C27.7985 22.2407 27.7353 22.0881 27.6228 21.9756C27.5103 21.8631 27.3577 21.7998 27.1985 21.7998H7.99965C7.84053 21.7998 7.68793 21.8631 7.57541 21.9756C7.4629 22.0881 7.39969 22.2407 7.39969 22.3998C7.39969 22.5589 7.4629 22.7115 7.57541 22.824C7.68793 22.9366 7.84053 22.9998 7.99965 22.9998Z"
        fill={colour}
      />
      <path
        d="M7.99965 25.3996H27.1985C27.3577 25.3996 27.5103 25.3364 27.6228 25.2239C27.7353 25.1114 27.7985 24.9588 27.7985 24.7997C27.7985 24.6406 27.7353 24.4879 27.6228 24.3754C27.5103 24.2629 27.3577 24.1997 27.1985 24.1997H7.99965C7.84053 24.1997 7.68793 24.2629 7.57541 24.3754C7.4629 24.4879 7.39969 24.6406 7.39969 24.7997C7.39969 24.9588 7.4629 25.1114 7.57541 25.2239C7.68793 25.3364 7.84053 25.3996 7.99965 25.3996Z"
        fill={colour}
      />
      <path
        d="M7.99965 27.7995H27.1985C27.3577 27.7995 27.5103 27.7363 27.6228 27.6238C27.7353 27.5113 27.7985 27.3586 27.7985 27.1995C27.7985 27.0404 27.7353 26.8878 27.6228 26.7753C27.5103 26.6628 27.3577 26.5996 27.1985 26.5996H7.99965C7.84053 26.5996 7.68793 26.6628 7.57541 26.7753C7.4629 26.8878 7.39969 27.0404 7.39969 27.1995C7.39969 27.3586 7.4629 27.5113 7.57541 27.6238C7.68793 27.7363 7.84053 27.7995 7.99965 27.7995Z"
        fill={colour}
      />
      <path
        d="M7.99965 30.1994H27.1985C27.3577 30.1994 27.5103 30.1361 27.6228 30.0236C27.7353 29.9111 27.7985 29.7585 27.7985 29.5994C27.7985 29.4403 27.7353 29.2877 27.6228 29.1751C27.5103 29.0626 27.3577 28.9994 27.1985 28.9994H7.99965C7.84053 28.9994 7.68793 29.0626 7.57541 29.1751C7.4629 29.2877 7.39969 29.4403 7.39969 29.5994C7.39969 29.7585 7.4629 29.9111 7.57541 30.0236C7.68793 30.1361 7.84053 30.1994 7.99965 30.1994Z"
        fill={colour}
      />
      <path
        d="M27.1985 33.1992H22.2313L21.768 31.8095C21.7357 31.7125 21.6791 31.6253 21.6036 31.5564C21.5281 31.4874 21.4362 31.4388 21.3367 31.4153C21.2372 31.3918 21.1333 31.3942 21.0349 31.4221C20.9366 31.4501 20.847 31.5027 20.7746 31.575L19.5633 32.7867L19.3356 32.3312C19.2976 32.2552 19.2438 32.188 19.1778 32.1343C19.1118 32.0806 19.0352 32.0415 18.953 32.0197C18.8708 31.9979 18.7848 31.9938 18.7009 32.0077C18.617 32.0216 18.537 32.0532 18.4662 32.1004L16.8175 33.1992H15.1992C15.0401 33.1992 14.8875 33.2624 14.775 33.3749C14.6625 33.4874 14.5993 33.64 14.5993 33.7991C14.5993 33.9583 14.6625 34.1109 14.775 34.2234C14.8875 34.3359 15.0401 34.3991 15.1992 34.3991H16.9991C17.1176 34.3991 17.2334 34.364 17.332 34.2983L18.5665 33.4754L18.8626 34.0675C18.9052 34.1525 18.9673 34.2262 19.0439 34.2825C19.1204 34.3388 19.2093 34.3761 19.3032 34.3913C19.3348 34.3965 19.3669 34.3991 19.399 34.3991C19.4778 34.3991 19.5558 34.3836 19.6286 34.3534C19.7014 34.3233 19.7675 34.2791 19.8232 34.2234L20.9364 33.1099L21.2294 33.9889C21.2693 34.1084 21.3457 34.2123 21.4479 34.2859C21.5501 34.3596 21.6729 34.3992 21.7989 34.3991H27.1985C27.3577 34.3991 27.5103 34.3359 27.6228 34.2234C27.7353 34.1109 27.7985 33.9583 27.7985 33.7991C27.7985 33.64 27.7353 33.4874 27.6228 33.3749C27.5103 33.2624 27.3577 33.1992 27.1985 33.1992Z"
        fill={colour}
      />
    </svg>
  )
}

InsurancePolicyIcon.defaultProps = {
  colour: '#000000',
}

InsurancePolicyIcon.propTypes = {
  colour: PropTypes.string,
}

export default InsurancePolicyIcon
