import React from 'react'
import PropTypes from 'prop-types'

function FilterIcon({ colour }, props) {
  return (
    <svg
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M28.4375 12.2655C28.8256 12.2655 29.1406 11.9505 29.1406 11.5624C29.1406 11.1743 28.8256 10.8593 28.4375 10.8593C28.0494 10.8593 27.7344 11.1743 27.7344 11.5624C27.7344 11.9505 28.0494 12.2655 28.4375 12.2655Z"
        fill={colour}
      />
      <path
        d="M37.2969 3.82803H2.70312C2.31476 3.82803 2 4.14279 2 4.53116V6.23074C2 8.00751 3.44553 9.45303 5.22229 9.45303H5.59967L16.4844 23.0591V35.4687C16.4844 35.9037 16.8755 36.2339 17.3034 36.1622L17.7868 36.0814C19.0431 35.8719 20.2318 35.339 21.225 34.5406C22.6897 33.3615 23.5156 31.6646 23.5156 31.2499V23.0591L34.4003 9.45303H34.4844C36.4229 9.45303 38 7.87595 38 5.93741V4.53116C38 4.14279 37.6852 3.82803 37.2969 3.82803ZM20.3433 33.445C19.627 34.0209 18.7852 34.4263 17.8906 34.6285V23.5155H22.1094V31.1107C21.7221 32.0201 21.1135 32.8251 20.3433 33.445ZM22.4744 22.1093H17.5253L7.40033 9.45303H32.5994L22.4744 22.1093ZM36.5938 5.93741C36.5938 7.10059 35.6476 8.04678 34.4844 8.04678H5.22229C4.22089 8.04678 3.40625 7.23215 3.40625 6.23074V5.23428H36.5938V5.93741Z"
        fill={colour}
      />
      <path
        d="M21.0209 20.5489C21.3235 20.7909 21.766 20.7431 22.0094 20.439L27.0958 14.0821C27.3383 13.7789 27.2894 13.3364 26.9862 13.0939C26.6829 12.8513 26.2405 12.9002 25.9977 13.2034L20.9113 19.5604C20.6688 19.8636 20.7179 20.3061 21.0209 20.5489Z"
        fill={colour}
      />
    </svg>
  )
}

FilterIcon.defaultProps = {
  colour: '#000000',
}

FilterIcon.propTypes = {
  colour: PropTypes.string,
}

export default FilterIcon
