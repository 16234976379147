import React from 'react'
import PropTypes from 'prop-types'

function WindIcon({ colour }, props) {
  return (
    <svg
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.4011 18.5629H2.46154C2.20662 18.5629 2 18.3562 2 18.1014C2 17.8465 2.20669 17.6398 2.46154 17.6398H12.4011C12.9246 17.6398 13.3506 17.214 13.3506 16.6907C13.3506 16.1672 12.9247 15.7414 12.4011 15.7414H12.0153C11.7604 15.7414 11.5538 15.5347 11.5538 15.2799C11.5538 15.0251 11.7605 14.8184 12.0153 14.8184H12.4011C13.4337 14.8184 14.2737 15.6583 14.2737 16.6907C14.2737 17.723 13.4337 18.5629 12.4011 18.5629Z"
        fill={colour}
      />
      <path
        d="M20.0938 23.7665H2.46154C2.20662 23.7665 2 23.5598 2 23.305C2 23.0501 2.20669 22.8434 2.46154 22.8434H20.0938C20.6174 22.8434 21.0434 22.4177 21.0434 21.8943C21.0434 21.3708 20.6174 20.945 20.0938 20.945H19.7081C19.4531 20.945 19.2465 20.7384 19.2465 20.4835C19.2465 20.2287 19.4532 20.022 19.7081 20.022H20.0938C21.1264 20.022 21.9664 20.8619 21.9664 21.8943C21.9664 22.9267 21.1264 23.7665 20.0938 23.7665Z"
        fill={colour}
      />
      <path
        d="M10.0932 31.7916H9.70745C9.45253 31.7916 9.24592 31.5849 9.24592 31.3301C9.24592 31.0752 9.45261 30.8685 9.70745 30.8685H10.0932C10.6168 30.8685 11.0428 30.4428 11.0428 29.9193C11.0428 29.3958 10.6168 28.9701 10.0932 28.9701H2.46154C2.20662 28.9701 2 28.7634 2 28.5085C2 28.2537 2.20669 28.047 2.46154 28.047H10.0932C11.1258 28.047 11.9658 28.8869 11.9658 29.9193C11.9658 30.9517 11.1258 31.7916 10.0932 31.7916Z"
        fill={colour}
      />
      <path
        d="M15.3959 21.1516H10.1527C9.89778 21.1516 9.69116 20.9449 9.69116 20.6901C9.69116 20.4352 9.89785 20.2285 10.1527 20.2285H15.3959C15.6508 20.2285 15.8575 20.4352 15.8575 20.6901C15.8575 20.9449 15.6508 21.1516 15.3959 21.1516Z"
        fill={colour}
      />
      <path
        d="M7.84476 21.1516H2.46154C2.20662 21.1516 2 20.9449 2 20.6901C2 20.4352 2.20669 20.2285 2.46154 20.2285H7.84476C8.09969 20.2285 8.3063 20.4352 8.3063 20.6901C8.3063 20.9449 8.09969 21.1516 7.84476 21.1516Z"
        fill={colour}
      />
      <path
        d="M13.0602 26.3815H2.46154C2.20662 26.3815 2 26.1748 2 25.9199C2 25.6651 2.20669 25.4584 2.46154 25.4584H13.0602C13.3151 25.4584 13.5218 25.6651 13.5218 25.9199C13.5218 26.1748 13.3151 26.3815 13.0602 26.3815Z"
        fill={colour}
      />
      <path
        d="M25.9835 20.0761C25.5663 20.0761 25.1439 20.0037 24.7325 19.855C24.6035 19.8085 24.5019 19.7068 24.4552 19.5778C23.9704 18.2362 24.2959 16.7761 25.3045 15.7673C26.3135 14.7583 27.774 14.4325 29.1165 14.9175C29.2455 14.9641 29.3471 15.0657 29.3937 15.1948C29.8785 16.5363 29.5531 17.9965 28.5444 19.0052C27.8447 19.705 26.9275 20.0761 25.9835 20.0761ZM25.2565 19.0537C26.1982 19.3125 27.1909 19.0534 27.8915 18.3525C28.5921 17.6521 28.8511 16.66 28.5924 15.7189C27.651 15.4603 26.6582 15.7193 25.9574 16.4201C25.2569 17.1205 24.9979 18.1126 25.2565 19.0537Z"
        fill={colour}
      />
      <path
        d="M34.3688 26.2497C33.9516 26.2497 33.5292 26.1773 33.1177 26.0286C32.9887 25.982 32.8871 25.8804 32.8405 25.7513C32.3556 24.4098 32.6811 22.9497 33.6898 21.941C34.6988 20.9317 36.1594 20.606 37.5016 21.091C37.6307 21.1376 37.7323 21.2392 37.779 21.3682C38.2638 22.7098 37.9383 24.17 36.9296 25.1787C36.2299 25.8786 35.3128 26.2497 34.3688 26.2497ZM33.6418 25.2273C34.5834 25.486 35.576 25.227 36.2768 24.5261C36.9773 23.8257 37.2363 22.8336 36.9776 21.8925C36.0361 21.634 35.0434 21.8929 34.3426 22.5937C33.6421 23.2941 33.383 24.2862 33.6418 25.2273Z"
        fill={colour}
      />
      <path
        d="M21.7976 12.2705H14.5076C13.5121 12.2705 12.7203 11.4129 12.7743 10.4286C12.8613 9.0942 13.866 8.00743 15.1258 7.82043C15.5835 6.51574 16.785 5.63074 18.1526 5.63074C19.5201 5.63074 20.7217 6.51574 21.1794 7.82043C22.4393 8.00758 23.4439 9.09458 23.5313 10.4334C23.6403 11.4147 22.7469 12.2705 21.7976 12.2705ZM18.1526 6.55374C17.1016 6.55374 16.1863 7.29774 15.9266 8.36304C15.8769 8.5672 15.6955 8.71196 15.4853 8.7152C14.5456 8.72966 13.7592 9.51081 13.6951 10.4934C13.688 10.9438 14.04 11.3474 14.5076 11.3474H21.7976C22.2734 11.3474 22.6079 10.9442 22.61 10.4934C22.5459 9.51081 21.7596 8.72966 20.8198 8.7152C20.6096 8.71196 20.4283 8.5672 20.3785 8.36304C20.119 7.29774 19.2036 6.55374 18.1526 6.55374Z"
        fill={colour}
      />
      <path
        d="M10.9726 10.586H6.59154C5.44039 10.586 4.39878 9.56208 4.50901 8.38493C4.61462 6.71663 5.84701 5.36747 7.38185 5.16578C7.91231 3.5154 9.37354 2.39001 11.0406 2.39001C12.8164 2.39001 14.3586 3.66701 14.791 5.49532C14.8497 5.7434 14.6962 5.99201 14.4481 6.0507C14.1999 6.10947 13.9513 5.95593 13.8927 5.70778C13.5593 4.29786 12.3865 3.31309 11.0407 3.31309C9.69485 3.31309 8.52185 4.29786 8.18846 5.70778C8.13985 5.9134 7.95785 6.0597 7.74662 6.06301C6.531 6.08224 5.51362 7.12716 5.43047 8.44178C5.38524 9.09739 5.93831 9.66293 6.59162 9.66293H10.9727C11.2276 9.66293 11.4342 9.86962 11.4342 10.1245C11.4342 10.3793 11.2275 10.586 10.9726 10.586Z"
        fill={colour}
      />
      <path
        d="M24.1547 37.6154C23.2185 37.6155 22.453 36.7366 22.2222 35.486C20.9914 35.9197 19.8212 35.7164 19.3516 34.9295C18.7797 33.9712 19.5344 32.8433 20.274 32.2318C19.5349 31.6205 18.7794 30.4934 19.3518 29.5342C19.8216 28.7473 20.9917 28.5438 22.2225 28.9774C22.4534 27.7268 23.219 26.8479 24.1553 26.8478C25.0916 26.8478 25.857 27.7266 26.0879 28.9772C27.3189 28.5435 28.4889 28.7471 28.9585 29.5337C29.5304 30.4921 28.7757 31.6198 28.036 32.2315C28.7753 32.8426 29.5307 33.9698 28.9583 34.929C28.4886 35.7158 27.3185 35.9195 26.0876 35.4858C25.8567 36.7365 25.091 37.6154 24.1547 37.6154ZM22.6143 34.3513C22.8608 34.3513 23.0683 34.553 23.0756 34.7989C23.1097 35.9315 23.6611 36.6923 24.1547 36.6922C24.6484 36.6922 25.2 35.9314 25.2342 34.7988C25.2443 34.4652 25.6121 34.2484 25.909 34.4034C26.9371 34.9395 27.9234 34.8613 28.1655 34.4558C28.5713 33.7761 27.4665 32.9062 26.9999 32.6277C26.7052 32.452 26.7053 32.0109 26.9998 31.835C27.4663 31.5566 28.5716 30.6864 28.1657 30.0066C27.9237 29.6012 26.9375 29.5232 25.9093 30.0594C25.6125 30.2142 25.2444 29.9979 25.2345 29.664C25.2004 28.5315 24.649 27.7707 24.1554 27.7707C23.6617 27.7708 23.11 28.5316 23.0759 29.6642C23.0658 29.9978 22.698 30.2145 22.4011 30.0595C21.3729 29.5233 20.3866 29.6015 20.1446 30.0071C19.7388 30.6869 20.8436 31.5569 21.3102 31.8353C21.6048 32.0112 21.6048 32.4522 21.3102 32.628C20.8437 32.9064 19.7385 33.7765 20.1444 34.4563C20.3863 34.8618 21.3724 34.94 22.4008 34.4035C22.4677 34.3688 22.541 34.3513 22.6143 34.3513Z"
        fill={colour}
      />
      <path
        d="M24.1548 33.8611C23.2655 33.8611 22.5167 33.1235 22.5168 32.2315C22.5168 31.3331 23.2518 30.602 24.1551 30.6019C25.0608 30.6019 25.7632 31.3472 25.7933 32.2315C25.7933 33.1301 25.0584 33.8611 24.155 33.8611C24.1549 33.8611 24.1548 33.8611 24.1548 33.8611ZM24.1552 31.5251C23.7608 31.5252 23.44 31.8421 23.44 32.2317C23.44 32.6208 23.7685 32.9381 24.1549 32.9381C24.5493 32.9381 24.8702 32.6212 24.8702 32.2315C24.8492 31.8522 24.5494 31.5251 24.1552 31.5251Z"
        fill={colour}
      />
      <path
        d="M33.4689 6.12926H25.3756C25.1207 6.12926 24.9141 5.92256 24.9141 5.66772C24.9141 5.41287 25.1208 5.20618 25.3756 5.20618H33.4688C33.9924 5.20618 34.4184 4.78041 34.4184 4.25695C34.4184 3.73349 33.9924 3.30772 33.4689 3.30772H33.0831C32.8282 3.30772 32.6216 3.10103 32.6216 2.84618C32.6216 2.59134 32.8283 2.38464 33.0831 2.38464H33.4689C34.5015 2.38464 35.3415 3.22457 35.3415 4.25695C35.3415 5.28933 34.5014 6.12926 33.4689 6.12926Z"
        fill={colour}
      />
      <path
        d="M29.8479 8.71788H25.3739C25.119 8.71788 24.9124 8.51118 24.9124 8.25634C24.9124 8.00149 25.119 7.7948 25.3739 7.7948H29.8479C30.1028 7.7948 30.3094 8.00149 30.3094 8.25634C30.3094 8.51118 30.1027 8.71788 29.8479 8.71788Z"
        fill={colour}
      />
      <path
        d="M15.685 26.5353C16.0243 26.5353 16.2993 26.2598 16.2993 25.9199C16.2993 25.5801 16.0243 25.3046 15.685 25.3046C15.3458 25.3046 15.0708 25.5801 15.0708 25.9199C15.0708 26.2598 15.3458 26.5353 15.685 26.5353Z"
        fill={colour}
      />
      <path
        d="M32.2339 8.87176C32.5731 8.87176 32.8481 8.59624 32.8481 8.25638C32.8481 7.91651 32.5731 7.64099 32.2339 7.64099C31.8946 7.64099 31.6196 7.91651 31.6196 8.25638C31.6196 8.59624 31.8946 8.87176 32.2339 8.87176Z"
        fill={colour}
      />
    </svg>
  )
}

WindIcon.defaultProps = {
  colour: '#000000',
}

WindIcon.propTypes = {
  colour: PropTypes.string,
}

export default WindIcon
