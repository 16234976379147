import Link from '@tiptap/extension-link'
import { Plugin, PluginKey, TextSelection } from '@tiptap/pm/state'
import { markPasteRule } from '@tiptap/core'
import { find } from 'linkifyjs'
const TipTapLink = Link.extend({
  addOptions() {
    return {
      ...this?.parent?.(),
      autolink: false,
      linkOnPaste: false,
    }
  },
  addAttributes() {
    return {
      ...this?.parent?.(),
      type: {
        default: null,
        rendered: false,
      },
      internalType: {
        default: null,
        rendered: false,
      },
      workflow: {
        default: null,
        rendered: false,
      },
      category: {
        default: null,
        rendered: false,
      },
      step: {
        default: null,
        rendered: false,
      },
      document: {
        default: null,
        rendered: false,
      },
      url: {
        default: null,
        rendered: false,
      },
      email: {
        default: null,
        rendered: false,
      },
      subject: {
        default: null,
        rendered: false,
      },
      body: {
        default: null,
        rendered: false,
      },
    }
  },
  addPasteRules() {
    return [
      markPasteRule({
        find: text =>
          find(text)
            .filter(link => {
              if (this.options.validate) {
                return this.options.validate(link.value)
              }

              return true
            })
            .filter(link => link.isLink)
            .map(link => ({
              text: link.value,
              index: link.start,
              data: link,
            })),
        type: this.type,
        getAttributes: (match, pasteEvent) => {
          const html = pasteEvent?.clipboardData?.getData('text/html')
          const hrefRegex = /href="([^"]*)"/
          const existingLink = html?.match(hrefRegex)
          if (existingLink) {
            return {
              href: existingLink[1],
            }
          }

          if (match?.data?.type === 'email') {
            return {
              href: 'temp',
              type: 'email',
              email: match?.data?.value,
            }
          }

          return {
            href: 'temp',
            type: 'external',
            url: match.data?.href,
          }
        },
      }),
    ]
  },
  addProseMirrorPlugins() {
    return [
      new Plugin({
        editor: this.editor,
        key: new PluginKey('emailLinker'),
        props: {
          handleKeyDown(view, event) {
            if (event.code === 'Space') {
              const selection = view.domSelectionRange()
              const focusNode = selection.focusNode
              const parts = focusNode.textContent.trim().split(' ')
              const search = find(parts.at(-1)).pop()
              if (search?.type === 'email') {
                const editor = this.spec.editor
                const headPos = view.state.selection.$head.pos
                editor
                  .chain()
                  .setTextSelection({
                    from: headPos - search.value.length,
                    to: headPos,
                  })
                  .setLink({ href: 'temp', type: 'email', email: search.value })
                  .setTextSelection(view.state.selection.$head.pos)
                  .run()
              }
            }
          },
        },
      }),
    ]
  },
})

export default TipTapLink
