// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.media-library-folders__new-button {
  margin-top: 24px;
}`, "",{"version":3,"sources":["webpack://./src/styles/global-components/media-library/media-library-folders.scss"],"names":[],"mappings":"AACE;EACE,gBAAA;AAAJ","sourcesContent":[".media-library-folders {\n  &__new-button {\n    margin-top: 24px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
