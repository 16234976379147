import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { get, isEmpty } from 'lodash'
import { selectWorkflowsState } from '../../../redux/workflows/workflows'
import {
  selectActiveClientId,
  selectActiveOperatingLocation,
  selectManageWorkflowModalVisibility,
  toggleManageWorkflowModal,
} from '../../../redux/user/userSelections'
import MaterialLoader from '../../global-components/elements/MaterialLoader'
import ViewMyWorkflowsTable from './elements/ViewMyWorkflowsTable'
import ErrorBoundary from '../../global-components/elements/ErrorBoundary'
import '../../../styles/consumer-flow/view-my-workflows/view-my-workflows-table.scss'

function ViewMyWorkflows() {
  const dispatch = useDispatch()
  const activeClientId = useSelector(selectActiveClientId)
  const activeOperatingLocation = useSelector(selectActiveOperatingLocation)
  const workflowsState = useSelector(selectWorkflowsState)
  const isManageWorkflowModalVisible = useSelector(
    selectManageWorkflowModalVisibility,
  )
  const workflows = get(workflowsState, 'workflows', [])
  const workflowsByClient = get(
    workflows,
    `${activeOperatingLocation}-${activeClientId}`,
    [],
  )

  const handleCreateWorkflow = () => {
    dispatch(toggleManageWorkflowModal(!isManageWorkflowModalVisible))
  }

  return workflowsState.loading ? (
    <MaterialLoader containerClasses="container-loader" />
  ) : !isEmpty(workflowsByClient) ? (
    <ViewMyWorkflowsTable />
  ) : (
    <ErrorBoundary
      ctaEvent={handleCreateWorkflow}
      ctaLabel="Add a New Workflow"
      errorTitle="No Workflows Found"
      errorMessage="There are currently no workflows registered under this client."
    />
  )
}

ViewMyWorkflows.propTypes = {}

export default ViewMyWorkflows
