import PropTypes from 'prop-types'
import Slider from 'react-slick'
import { useState } from 'react'
import Modal from '../../../global-components/elements/Modal'
import { ReactComponent as CloseIcon } from '../../../../assets/icons/close-icon.svg'
import { ReactComponent as NextIcon } from '../../../../assets/icons/chevron-right-icon.svg'
import { ReactComponent as PrevIcon } from '../../../../assets/icons/chevron-left-icon.svg'
import '../../../../styles/consumer-flow/base-fields/gallery-field.scss'

function GalleryArrow(props) {
  const { className, iconType, onClick } = props

  return (
    <div className={className} onClick={onClick}>
      <div className="arrow-wrapper">
        {iconType === 'next' ? <NextIcon /> : <PrevIcon />}
      </div>
    </div>
  )
}
const GalleryField = ({ field }) => {
  const [lightboxOpen, setLightboxOpen] = useState(false)
  const [selectedImage, setSelectedImage] = useState(null)
  const [currentSlide, setCurrentSlide] = useState(0)
  const settings = {
    adaptiveHeight: true,
    dots: true,
    infinite: true,
    nextArrow: <GalleryArrow iconType="next" />,
    prevArrow: <GalleryArrow iconType="prev" />,
    slidesToScroll: 1,
    slidesToShow: 1,
    speed: 500,
    beforeChange: (oldIndex, newIndex) => {
      setCurrentSlide(newIndex)
    },
  }

  const alignments = {
    left: 'flex-start',
    center: 'center',
    right: 'flex-end',
  }
  const configurations = field?.configurations || {}
  const alignment = configurations?.alignment || 'left'

  const style = {
    justifyContent: alignments[alignment],
  }

  function getImageStyle(block) {
    if (block.image?.height < 600 && block.image?.width < 600) {
      return { objectFit: 'none' }
    }
    return {}
  }

  return (
    <div className="gallery-field" style={style}>
      <div className="gallery-slider">
        <Slider {...settings}>
          {field?.blocks?.map(block => (
            <div className="gallery-slide-wrapper" key={block.id}>
              <button
                onClick={() => setLightboxOpen(true)}
                className="gallery-slide"
              >
                <img
                  src={block.image?.url}
                  style={getImageStyle(block)}
                  alt={block.image?.alt}
                />
                {block?.configurations?.title && (
                  <div className="gallery-slide-title">
                    {block.configurations.title}
                  </div>
                )}
              </button>
            </div>
          ))}
        </Slider>
      </div>

      {lightboxOpen && (
        <Modal
          isOpen={lightboxOpen}
          onRequestClose={() => setLightboxOpen(false)}
          modalClassName="modal gallery-lightbox"
        >
          <div className="gallery-slider">
            <Slider {...settings} initialSlide={currentSlide}>
              {field?.blocks?.map(block => (
                <div key={block.id} className="gallery-slide-wrapper">
                  <div className="gallery-slide">
                    <img
                      src={block.image?.url}
                      style={getImageStyle(block)}
                      alt={block.image?.alt}
                    />
                    {block?.configurations?.title && (
                      <div className="gallery-slide-title">
                        {block.configurations.title}
                      </div>
                    )}
                  </div>
                </div>
              ))}
            </Slider>
          </div>
          <button
            onClick={() => setLightboxOpen(false)}
            className="modal__close"
          >
            <CloseIcon />
          </button>
        </Modal>
      )}
    </div>
  )
}

GalleryField.propTypes = {
  field: PropTypes.object,
}

GalleryArrow.propTypes = {
  className: PropTypes.string,
  iconType: PropTypes.string,
  onClick: PropTypes.func,
}

export default GalleryField
