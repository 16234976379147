import React from 'react'
import PropTypes from 'prop-types'

function ExportIcon({ colour }, props) {
  return (
    <svg
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M20.0006 28.9614C18.1716 28.9614 16.6854 27.4752 16.6854 25.6485V12.9207C15.4979 13.3822 14.0959 13.1343 13.1387 12.1771C13.1305 12.1689 13.1224 12.1605 13.1144 12.1521C11.8442 10.8571 11.8485 8.77849 13.1384 7.48946L17.6569 2.97097C18.2819 2.34505 19.1142 2.00003 20 2.00003C20.8858 2.00003 21.7181 2.34505 22.3436 2.97147L26.8618 7.48967C28.1476 8.77449 28.1598 10.853 26.8855 12.152C26.8776 12.1605 26.8695 12.1689 26.8613 12.177C25.9038 13.1346 24.5014 13.3823 23.3135 12.9203V25.6484C23.3135 27.4752 21.8273 28.9614 20.0006 28.9614ZM18.1437 9.79493C18.5378 9.95812 18.7948 10.3427 18.7948 10.7693V25.6484C18.7948 26.312 19.3347 26.8519 19.9983 26.8519C20.6642 26.8519 21.2041 26.312 21.2041 25.6484V10.7682C21.2041 10.3416 21.4611 9.957 21.8552 9.7938C22.2493 9.63061 22.7029 9.72082 23.0046 10.0225L23.6677 10.6856C24.132 11.1499 24.8845 11.1548 25.3548 10.7002C25.36 10.6949 25.3652 10.6895 25.3706 10.6842C25.8414 10.2136 25.8418 9.4523 25.3706 8.98149L20.8516 4.46244C20.624 4.23463 20.3218 4.1094 20 4.1094C19.6782 4.1094 19.376 4.23463 19.1489 4.46195L14.6297 8.9812C14.1587 9.45188 14.1581 10.2131 14.6295 10.6842C14.6347 10.6894 14.64 10.6948 14.6451 10.7002C15.1155 11.1548 15.8679 11.1499 16.3324 10.6856L16.9943 10.0236C17.3181 9.69966 17.779 9.64383 18.1437 9.79493Z"
        fill={colour}
      />
      <path
        d="M32.4265 38H7.57345C4.4951 38 2 35.5099 2 32.4265V21.1297C2 18.0514 4.49011 15.5563 7.57345 15.5563H10.9625C12.7771 15.5563 14.2765 17.0227 14.2765 18.8703C14.2765 20.6804 12.8143 22.1844 10.9625 22.1844H8.62814V31.3719H31.3719V22.1844H29.0375C27.2274 22.1844 25.7235 20.7221 25.7235 18.8703C25.7235 17.0602 27.1857 15.5563 29.0375 15.5563H32.4266C35.5048 15.5563 38 18.0464 38 21.1297V32.4266C37.9999 35.5049 35.5098 38 32.4265 38ZM7.57345 17.6656C5.65983 17.6656 4.10937 19.2136 4.10937 21.1297V32.4266C4.10937 34.3399 5.65702 35.8906 7.57345 35.8906H32.4266C34.34 35.8906 35.8906 34.3429 35.8906 32.4265V21.1297C35.8906 19.2161 34.3426 17.6656 32.4265 17.6656H29.0374C28.3627 17.6656 27.8328 18.2146 27.8328 18.8703C27.8328 19.5437 28.3804 20.075 29.0374 20.075H32.4265C33.0089 20.075 33.4812 20.5472 33.4812 21.1297V32.4266C33.4812 33.0091 33.0089 33.4813 32.4265 33.4813H7.57345C6.99098 33.4813 6.51876 33.0091 6.51876 32.4266V21.1297C6.51876 20.5472 6.99098 20.075 7.57345 20.075H10.9625C11.6372 20.075 12.1672 19.5261 12.1672 18.8703C12.1672 18.197 11.6197 17.6656 10.9625 17.6656H7.57345Z"
        fill={colour}
      />
    </svg>
  )
}

ExportIcon.defaultProps = {
  colour: '#000000',
}

ExportIcon.propTypes = {
  colour: PropTypes.string,
}

export default ExportIcon
