import React from 'react'
import PropTypes from 'prop-types'

function InfoIcon({ colour }, props) {
  return (
    <svg
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M28.4375 12.2656C28.4375 11.8775 28.1225 11.5625 27.7344 11.5625C27.3463 11.5625 27.0312 11.8775 27.0312 12.2656C27.0312 12.6537 27.3463 12.9688 27.7344 12.9688C28.1225 12.9688 28.4375 12.6537 28.4375 12.2656Z"
        fill={colour}
      />
      <path
        d="M20 2C10.3018 2 2 10.2771 2 20C2 29.7039 10.284 38 20 38C29.7039 38 38 29.716 38 20C38 10.2936 29.713 2 20 2ZM20 36.5938C11.0052 36.5938 3.40625 28.9948 3.40625 20C3.40625 11.0052 11.0052 3.40625 20 3.40625C28.9948 3.40625 36.5938 11.0052 36.5938 20C36.5938 28.9948 28.9948 36.5938 20 36.5938Z"
        fill={colour}
      />
      <path
        d="M20 5.02344C11.863 5.02344 5.02344 11.8509 5.02344 20C5.02344 28.1288 11.841 34.9766 20 34.9766C28.137 34.9766 34.9766 28.1491 34.9766 20C34.9766 11.8509 28.1389 5.02344 20 5.02344ZM20 33.5703C12.6441 33.5703 6.42969 27.3559 6.42969 20C6.42969 12.6441 12.6441 6.42969 20 6.42969C27.3559 6.42969 33.5703 12.6441 33.5703 20C33.5703 27.3559 27.3559 33.5703 20 33.5703Z"
        fill={colour}
      />
      <path
        d="M29.0854 14.6414C31.5288 18.7855 30.8594 24.0559 27.4581 27.4581C27.1834 27.7325 27.1834 28.1777 27.4581 28.4523C27.7325 28.7267 28.1777 28.727 28.4523 28.4523C32.3074 24.5964 33.066 18.6237 30.2969 13.9273C30.0997 13.5925 29.6688 13.481 29.3343 13.6788C28.9995 13.8757 28.8882 14.3069 29.0854 14.6414Z"
        fill={colour}
      />
      <path
        d="M20 9.45312C18.4463 9.45312 17.1875 10.7116 17.1875 12.2656C17.1875 13.8163 18.4493 15.0781 20 15.0781C21.5537 15.0781 22.8125 13.8196 22.8125 12.2656C22.8125 10.7119 21.554 9.45312 20 9.45312ZM20 13.6719C19.2246 13.6719 18.5938 13.041 18.5938 12.2656C18.5938 11.4889 19.2233 10.8594 20 10.8594C20.7767 10.8594 21.4062 11.4889 21.4062 12.2656C21.4062 13.0424 20.7767 13.6719 20 13.6719Z"
        fill={colour}
      />
      <path
        d="M20.7031 16.4844H19.2969C18.1337 16.4844 17.1875 17.4306 17.1875 18.5938V28.4375C17.1875 29.6007 18.1337 30.5469 19.2969 30.5469H20.7031C21.8663 30.5469 22.8125 29.6007 22.8125 28.4375V18.5938C22.8125 17.4306 21.8663 16.4844 20.7031 16.4844ZM21.4062 28.4375C21.4062 28.8253 21.0909 29.1406 20.7031 29.1406H19.2969C18.9091 29.1406 18.5938 28.8253 18.5938 28.4375V18.5938C18.5938 18.2059 18.9091 17.8906 19.2969 17.8906H20.7031C21.0909 17.8906 21.4062 18.2059 21.4062 18.5938V28.4375Z"
        fill={colour}
      />
    </svg>
  )
}

InfoIcon.defaultProps = {
  colour: '#000000',
}

InfoIcon.propTypes = {
  colour: PropTypes.string,
}

export default InfoIcon
