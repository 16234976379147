import React from 'react'
import PropTypes from 'prop-types'

function HomeIcon({ colour }, props) {
  return (
    <svg
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M36.7623 13.7905H36.4187L33.1969 5.11643C32.9148 4.35698 32.181 3.84673 31.3709 3.84673H8.62898C7.81891 3.84673 7.08506 4.35698 6.80297 5.11643L3.58125 13.7905H3.23764C2.55519 13.7905 2 14.3457 2 15.0281V16.0225C2 16.705 2.55519 17.2601 3.23764 17.2601H3.42059V28.8188C3.42059 29.11 3.65663 29.3461 3.94793 29.3461C4.23924 29.3461 4.47528 29.11 4.47528 28.8188V17.2601H12.7553C13.2186 17.2601 13.6543 17.0797 13.982 16.752L20 10.7339L26.0181 16.752C26.3457 17.0797 26.7814 17.2601 27.2447 17.2601H35.5247V18.6582C35.5247 18.9494 35.7608 19.1855 36.0521 19.1855C36.3434 19.1855 36.5794 18.9494 36.5794 18.6582V17.2601H36.7624C37.4447 17.2601 38 16.7049 38 16.0224V15.0281C37.9999 14.3457 37.4447 13.7905 36.7623 13.7905ZM7.79163 5.4836C7.921 5.13534 8.25752 4.90141 8.62898 4.90141H31.3709C31.7423 4.90141 32.0789 5.13541 32.2082 5.4836L35.2936 13.7905H27.9633L21.2266 7.05388C20.899 6.72623 20.4634 6.54574 20 6.54574C19.5365 6.54574 19.101 6.72616 18.7733 7.05388L12.0366 13.7905H4.70625L7.79163 5.4836ZM36.9452 16.0225C36.9452 16.1234 36.8632 16.2055 36.7623 16.2055H27.2447C27.0631 16.2055 26.8922 16.1347 26.7638 16.0063L20.3728 9.61529C20.2739 9.51636 20.1398 9.46082 19.9999 9.46082C19.86 9.46082 19.726 9.51636 19.627 9.61529L13.2361 16.0063C13.1076 16.1347 12.9367 16.2055 12.755 16.2055H3.23764C3.13674 16.2055 3.05469 16.1234 3.05469 16.0225V15.0281C3.05469 14.9272 3.13674 14.8452 3.23764 14.8452H12.2551C12.3949 14.8452 12.529 14.7896 12.628 14.6907L19.5192 7.79962C19.6476 7.67116 19.8183 7.60042 20 7.60042C20.1817 7.60042 20.3525 7.67116 20.4809 7.79962L27.3721 14.6907C27.471 14.7896 27.6051 14.8452 27.745 14.8452H36.7624C36.8633 14.8452 36.9454 14.9272 36.9454 15.0281V16.0225H36.9452Z"
        fill={colour}
      />
      <path
        d="M33.7082 31.8917C33.9995 31.8917 34.2356 31.6556 34.2356 31.3643V21.9888C34.2356 21.6976 33.9995 21.4615 33.7082 21.4615H26.6055C26.3142 21.4615 26.0781 21.6976 26.0781 21.9888V31.3643C26.0781 31.6556 26.3142 31.8917 26.6055 31.8917H33.7082ZM33.1809 28.5641H30.6841V26.0675H33.1809V28.5641ZM29.6295 28.5641H27.1328V26.0675H29.6295V28.5641ZM33.1809 30.837H27.1328V29.6188H33.1809V30.837ZM33.1809 25.0127H30.6841V22.5161H33.1809V25.0127ZM29.6295 22.5161V25.0128H27.1328V22.5161H29.6295Z"
        fill={colour}
      />
      <path
        d="M13.3945 31.8917C13.6858 31.8917 13.9218 31.6556 13.9218 31.3643V21.9888C13.9218 21.6976 13.6858 21.4615 13.3945 21.4615H6.29175C6.00044 21.4615 5.7644 21.6976 5.7644 21.9888V31.3643C5.7644 31.6556 6.00044 31.8917 6.29175 31.8917H13.3945ZM12.8671 28.5641H10.3705V26.0675H12.8671V28.5641ZM9.31581 28.5641H6.81909V26.0675H9.31581V28.5641ZM12.8671 30.837H6.81909V29.6188H12.8671V30.837ZM12.8671 25.0127H10.3705V22.5161H12.8671V25.0127ZM9.31581 22.5161V25.0128H6.81909V22.5161H9.31581Z"
        fill={colour}
      />
      <path
        d="M21.7046 23.7343H18.2953C18.004 23.7343 17.7679 23.9704 17.7679 24.2616V26.5345C17.7679 26.8257 18.004 27.0618 18.2953 27.0618H21.7046C21.9959 27.0618 22.2319 26.8257 22.2319 26.5345V24.2616C22.2319 23.9704 21.9959 23.7343 21.7046 23.7343ZM21.1772 26.0071H18.8226V24.789H21.1772V26.0071Z"
        fill={colour}
      />
      <path
        d="M22.8002 17.0879C22.8002 15.5439 21.5441 14.2877 20 14.2877C18.456 14.2877 17.1998 15.5439 17.1998 17.0879C17.1998 18.6319 18.456 19.8881 20 19.8881C21.5441 19.8881 22.8002 18.6319 22.8002 17.0879ZM18.2545 17.0879C18.2545 16.1254 19.0376 15.3424 20 15.3424C20.9625 15.3424 21.7455 16.1254 21.7455 17.0879C21.7455 18.0503 20.9625 18.8335 20 18.8335C19.0376 18.8335 18.2545 18.0504 18.2545 17.0879Z"
        fill={colour}
      />
      <path
        d="M37.4726 32.6836H36.5794V21.125C36.5794 20.8338 36.3434 20.5977 36.0521 20.5977C35.7608 20.5977 35.5247 20.8338 35.5247 21.125V32.6836H24.5048V21.9888C24.5048 21.6976 24.2688 21.4614 23.9775 21.4614H16.0225C15.7312 21.4614 15.4951 21.6976 15.4951 21.9888V32.6836H4.47521V31.2856C4.47521 30.9943 4.23917 30.7582 3.94786 30.7582C3.65656 30.7582 3.42052 30.9943 3.42052 31.2856V32.6836H2.52734C2.23604 32.6836 2 32.9198 2 33.211V35.6259C2 35.9172 2.23604 36.1533 2.52734 36.1533H37.4726C37.7639 36.1533 37.9999 35.9172 37.9999 35.6259V33.211C37.9999 32.9198 37.7639 32.6836 37.4726 32.6836ZM16.5498 28.8375H17.301C17.5923 28.8375 17.8284 28.6014 17.8284 28.3101C17.8284 28.0189 17.5923 27.7828 17.301 27.7828H16.5498V22.5161H23.4502V32.6836H16.5498V28.8375ZM36.9452 35.0986H3.05469V33.7383H36.9452V35.0986Z"
        fill={colour}
      />
    </svg>
  )
}

HomeIcon.defaultProps = {
  colour: '#000000',
}

HomeIcon.propTypes = {
  colour: PropTypes.string,
}

export default HomeIcon
