import { cloneDeep, set } from 'lodash'
import axios from 'axios'

async function updateReplaceExternalLink({
  entry,
  items,
  replaceValue,
  replaceTarget,
  activeClientId,
  entries,
  token,
  operatingLocation,
}) {
  if (entry) {
    const item = cloneDeep(items[entry.itemId])
    set(item, [...entry.path, 'url'], replaceValue)
    set(item, [...entry.path, 'target'], replaceTarget)

    return axios({
      url: `${process.env.REACT_APP_PRODUCT_API}/api/v1/find-replace/update/external-link/${activeClientId}`,
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      data: {
        searchTerm: entry.find,
        replacementTerm: replaceValue,
        totalResults: entries.length,
        type: entry.type,
        item,
        operatingLocation,
      },
    }).then(res => {
      const newItems = cloneDeep(items)
      const newItem = res?.data?.item
      newItems[entry.itemId] = {
        ...newItem,
        breadcrumb: item.breadcrumb,
      }
      return {
        items: newItems,
        entries: entries.map(currentEntry => {
          if (currentEntry.id === entry.id) {
            return {
              ...currentEntry,
              replaced: true,
            }
          }
          return currentEntry
        }),
      }
    })
  }
}

export default updateReplaceExternalLink
