import axios from 'axios'
import { get, isEmpty, merge } from 'lodash'
import { UPDATE_WORKFLOWS_STEPS } from './workflows'

export const FETCH_WORKFLOW_BEGIN = 'FETCH_WORKFLOW_BEGIN'
export const FETCH_WORKFLOW_ERROR = 'FETCH_WORKFLOW_ERROR'
export const FETCH_WORKFLOW_SUCCESS = 'FETCH_WORKFLOW_SUCCESS'

export const fetchWorkflow = (slug, token) => async (dispatch, getState) => {
  const activeClient = getState().userSelectionsData.activeClient
  const activeOperatingLocation =
    getState().userSelectionsData.activeOperatingLocation
  const activeClientId = get(activeClient, 'id', null)

  dispatch({ type: FETCH_WORKFLOW_BEGIN })
  return axios({
    url: `${process.env.REACT_APP_PRODUCT_API}/api/v1/workflow/${slug}`,
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    data: {
      clientId: activeClientId,
      operatingLocation: activeOperatingLocation,
    },
  })
    .then(response => {
      const workflows = get(response, 'data', [])
      const workflowId = get(workflows, 'workflowId', null)
      const steps = workflows?.steps || []

      if (!isEmpty(workflows) && workflowId) {
        let keyedWorkflow = {}

        keyedWorkflow[`${activeOperatingLocation}-${activeClientId}-${slug}`] =
          workflows
        dispatch({
          type: FETCH_WORKFLOW_SUCCESS,
          payload: keyedWorkflow,
        })
        dispatch({
          type: UPDATE_WORKFLOWS_STEPS,
          payload: steps,
          workflowId,
          key: `${activeOperatingLocation}-${activeClientId}`,
        })
      } else {
        dispatch({
          type: FETCH_WORKFLOW_ERROR,
          payload:
            'There was an error retrieving this workflow - please wait a moment, then try again.',
        })
      }
    })
    .catch(error => {
      dispatch({
        type: FETCH_WORKFLOW_ERROR,
        payload: error.message,
      })
    })
}

// REDUCER
const initialState = {
  error: null,
  loading: false,
  workflow: null,
}

export default function workflowReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_WORKFLOW_BEGIN:
      return { ...state, loading: true }
    case FETCH_WORKFLOW_SUCCESS:
      return {
        ...state,
        loading: false,
        workflow: { ...state.workflow, ...action.payload },
      }
    case FETCH_WORKFLOW_ERROR:
      return { ...state, loading: false, error: action.payload }
    default:
      return state
  }
}

// SELECTORS
export const selectWorkflowState = state => state.workflowData
