import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { ReactComponent as DeleteIcon } from '../../../assets/icons/delete-icon.svg'

import { selectField } from '../../../configuration/editor'

import { useDndContext } from '@dnd-kit/core'
import fixTransform from '../../../utilities/workflows/fixTransform'
import getId from '../../../utilities/editor/getId'
import PropTypes from 'prop-types'
import clsx from 'clsx'

const EditorField = ({
  field,
  onChange,
  onDeleteClick,
  dropPath,
  index,
  zIndex,
}) => {
  const dropLevel = 'fields'
  const dndContext = useDndContext()
  const disableDropZones =
    dndContext?.active &&
    dndContext?.active?.data?.current?.dropLevel !== dropLevel
  const { fieldType } = field || {}
  const id = getId(field)
  const fieldConfig = selectField(field?.fieldType)
  const fieldProps = {
    field,
    dropPath: [...dropPath, index],
    onChange: handleChange,
    zIndex,
  }
  const Field = fieldConfig?.editorField
  const {
    attributes,
    listeners,
    isDragging,
    setNodeRef,
    transform,
    transition,
    setActivatorNodeRef,
  } = useSortable({
    id,
    data: {
      dropPath,
      dropLevel: 'fields',
    },
    disabled: disableDropZones,
  })
  const style = {
    transform: CSS.Transform.toString(fixTransform(transform)),
    transition,
  }

  function handleChange(editorData) {
    onChange({
      ...field,
      blocks: editorData.blocks,
      configurations: editorData?.configurations,
    })
  }

  return (
    <div
      className={clsx('editor-field-container', {
        'is-placeholder': !fieldConfig,
        'is-field': !!fieldConfig,
      })}
      id={id}
      ref={setNodeRef}
      style={style}
      {...attributes}
    >
      <div style={{ padding: '20px 0' }}>
        {fieldConfig ? (
          <div className="editor-field">
            <div className="editor-field-actions">
              <div
                className="editor-field-title"
                ref={setActivatorNodeRef}
                {...listeners}
              >
                {fieldType.replace(/([a-z])([A-Z])/g, '$1 $2')}
              </div>

              <div className="flex-fill"></div>

              <div>
                <button
                  className="btn btn--light-red"
                  onClick={() => onDeleteClick(field)}
                >
                  <DeleteIcon />
                </button>
              </div>
            </div>

            <Field {...fieldProps} />
          </div>
        ) : (
          <div
            id="editor-field-placeholder"
            className="editor-field-placeholder"
          >
            Drop Field Here
          </div>
        )}
      </div>
    </div>
  )
}

EditorField.propTypes = {
  field: PropTypes.object,
  onChange: PropTypes.func,
  onDeleteClick: PropTypes.func,
  dropPath: PropTypes.array,
  index: PropTypes.number,
  zIndex: PropTypes.number,
}
export default EditorField
