import React from 'react'
import { ReactComponent as WorkflowsIcon } from '../../assets/icons/workflows-icon.svg'
import { ReactComponent as ComparisonsIcon } from '../../assets/icons/comparisons-icon.svg'
import { ReactComponent as PagesIcon } from '../../assets/icons/pages-icon.svg'

function getResultTypeIcon(type) {
  switch (type) {
    case 'workflow':
      return <WorkflowsIcon />
    case 'Comparison':
      return <ComparisonsIcon />
    case 'Page':
      return <PagesIcon />
  }
}

export default getResultTypeIcon
