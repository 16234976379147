import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { get } from 'lodash'
import {
  selectActiveClientId,
  selectActiveOperatingLocation,
} from '../../../redux/user/userSelections'
import {
  fetchWorkflow,
  selectWorkflowState,
} from '../../../redux/workflows/workflow'
import MaterialLoader from '../../global-components/elements/MaterialLoader'
import BrowseWorkflowSteps from './BrowseWorkflowSteps'
import getWorkflowsDataById from '../../../utilities/workflows/getWorkflowsDataById'
import '../../../styles/consumer-flow/browse-workflow/browse-workflow.scss'
import BrowseWorkflowStep from './BrowseWorkflowStep'
import useAuthToken from '../../../hooks/useAuthToken'
import RelatedWorkflows from '../related-workflows/RelatedWorkflows'

function BrowseWorkflow() {
  const dispatch = useDispatch()
  const { workflow, step } = useParams()
  const { token } = useAuthToken({})
  const activeClientId = useSelector(selectActiveClientId)
  const activeOperatingLocation = useSelector(selectActiveOperatingLocation)
  const workflowState = useSelector(selectWorkflowState)
  const workflowOptions = get(workflowState, 'workflow', [])
  const workflowByClientSlug = get(
    workflowOptions,
    `[${activeOperatingLocation}-${activeClientId}-${workflow}]`,
    {},
  )
  const workflowId = get(workflowByClientSlug, 'workflowId', null)
  const activeWorkflow = getWorkflowsDataById(workflowId)

  const handleFetchWorkflowBySlug = async () => {
    await dispatch(fetchWorkflow(workflow, token))
  }

  useEffect(() => {
    if (token) {
      handleFetchWorkflowBySlug()
    }
  }, [token, workflow])

  return workflowState.loading ? (
    <MaterialLoader containerClasses="container-loader" />
  ) : (
    <div className="browse-workflow container">
      {workflow && !step ? (
        <>
          <BrowseWorkflowSteps
            activeWorkflow={activeWorkflow}
            activeWorkflowContent={workflowByClientSlug}
          />

          <RelatedWorkflows />
        </>
      ) : workflow && step ? (
        <BrowseWorkflowStep activeWorkflowContent={workflowByClientSlug} />
      ) : (
        <p>None found</p>
      )}
    </div>
  )
}

export default BrowseWorkflow
