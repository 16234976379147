import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'

import { EDITOR_TABLE_FIELD } from '../../../../configuration/editor'
import TipTapMenuBar from '../TipTap/TipTapMenuBar'
import EditorTableCell from './EditorTableCell'
import '../../../../styles/editor-flow/editor-fields/editor-table-field.scss'
import { cloneDeep } from 'lodash'
import EditorTableHeader from './EditorTableHeader'
import EditorTableContext from '../../../../contexes/EditorTableContext'

function EditorTableField({ field, onChange, readOnly }) {
  const block = field?.blocks?.[0] || {}
  const table = block?.table || {}

  const [editors, setEditors] = useState([])
  const [tableContext, setTableContext] = useState({
    cells: {},
    activeCells: [],
  })
  const [tableData, setTableData] = useState(table)
  const [dragRect, setDragRect] = useState({
    x1: 0,
    x2: 0,
    y1: 0,
    y2: 0,
    dragging: false,
  })
  const ref = useRef(null)
  const selectRectRef = useRef(null)
  const tableRef = useRef(null)
  const headerCells = tableData?.header || []
  const bodyRows = tableData?.body || []

  useEffect(() => {
    onChange({
      blocks: [
        {
          ...block,
          table: tableData,
        },
      ],
    })
  }, [tableData])

  useEffect(() => {
    const dragStart = evt => {
      const rect = tableRef.current.getBoundingClientRect()
      if (evt.button === 0) {
        const isMenuBarClick = !!evt.target.closest('.tiptap-menu-bar')

        if (!isMenuBarClick) {
          const x = evt.clientX
          const y = evt.clientY
          setTableContext({ ...tableContext, activeCells: [] })
          if (
            x > rect.left &&
            x < rect.right &&
            y > rect.top &&
            y < rect.bottom
          ) {
            setDragRect({
              x1: x,
              y1: y,
              x2: x,
              y2: y,
              dragging: true,
            })
          } else {
            setDragRect({
              dragging: false,
              x1: 0,
              x2: 0,
              y1: 0,
              y2: 0,
            })
          }
        }
      }
    }

    const dragMove = evt => {
      if (dragRect.dragging) {
        const tableRect = tableRef?.current?.getBoundingClientRect() || {
          x: 0,
          y: 0,
        }
        const newRect = {
          ...dragRect,
          x2: Math.max(evt.clientX, tableRect.x),
          y2: Math.max(evt.clientY, tableRect.y),
        }
        setDragRect(newRect)

        const activeCells = getCellsInArea({
          ...newRect,
          cells: tableContext.cells,
        })
        if (activeCells.length > 1) {
          setTableContext({ ...tableContext, activeCells })
        } else {
          setTableContext({ ...tableContext, activeCells: [] })
        }
      }
    }

    const dragEnd = () => {
      setDragRect({
        dragging: false,
        x1: 0,
        x2: 0,
        y1: 0,
        y2: 0,
      })
      if (dragRect.dragging && tableContext?.activeCells?.length > 1) {
        setEditors(
          tableContext?.activeCells?.map(
            activeCell => activeCell?.cell.editor,
          ) || [],
        )
      }
    }

    window.addEventListener('mousedown', dragStart)
    window.addEventListener('mouseup', dragEnd)
    window.addEventListener('mousemove', dragMove)

    return () => {
      window.removeEventListener('mousedown', dragStart)
      window.removeEventListener('mouseup', dragEnd)
      window.removeEventListener('mousemove', dragMove)
    }
  }, [dragRect, tableRef, tableContext])

  function handleHeaderChange(index, content) {
    setTableData(table => {
      const headerCells = tableData?.header || []
      const newHeader = cloneDeep(headerCells)
      newHeader[index] = {
        ...newHeader[index],
        content,
      }
      return {
        ...table,
        header: newHeader,
      }
    })
  }

  function handleCellChange(row, column, content) {
    setTableData(table => {
      const bodyRows = table?.body
      const newRows = cloneDeep(bodyRows)
      newRows[row][column] = {
        ...newRows[row][column],
        content,
      }
      return {
        ...table,
        body: newRows,
      }
    })
  }

  function handleAddRow(index) {
    const newRow = headerCells.map(() => emptyCell())
    setTableData({
      ...table,
      body: [...bodyRows.slice(0, index), newRow, ...bodyRows.slice(index)],
    })
  }

  function handleDeleteRow(index) {
    setTableData({
      ...table,
      body: bodyRows.filter((row, i) => i !== index),
    })
  }

  function handleAddColumn(index) {
    setTableData({
      ...table,
      header: [
        ...headerCells.slice(0, index),
        emptyCell(),
        ...headerCells.slice(index),
      ],
      body: bodyRows.map(row => [
        ...row.slice(0, index),
        emptyCell(),
        ...row.slice(index),
      ]),
    })
  }

  function handleDeleteColumn(index) {
    setTableData({
      ...table,
      header: headerCells.filter((header, i) => i !== index),
      body: bodyRows.map(row => row.filter((cell, i) => i !== index)),
    })
  }

  function handleMoveColumn(index, newIndex) {
    if (newIndex >= 0) {
      setTableData({
        ...table,
        header: arrayMove(headerCells, index, newIndex),
        body: bodyRows.map(row => arrayMove(row, index, newIndex)),
      })
    }
  }

  function handleMoveRow(index, newIndex) {
    if (newIndex >= 0) {
      setTableData({
        ...table,
        body: arrayMove(bodyRows, index, newIndex),
      })
    }
  }

  function arrayMove(arr, from, to) {
    const temp = [...arr]
    temp.splice(to, 0, temp.splice(from, 1)[0])
    return temp
  }

  function getCellsInArea({ x1, x2, y1, y2, cells }) {
    const startX = Math.min(x1, x2)
    const startY = Math.min(y1, y2)
    const endX = Math.max(x1, x2)
    const endY = Math.max(y1, y2)
    const cellLocations = Object.keys(cells).reduce((acc, cur) => {
      const id = cur
      const cell = cells[id]
      const element = cell.ref.current
      const rect = element.getBoundingClientRect()
      return [
        ...acc,
        {
          id,
          cell,
          rect,
        },
      ]
    }, [])
    return cellLocations.filter(cellLocation => {
      const { rect } = cellLocation
      const { left, right, top, bottom } = rect
      return startX < right && endX > left && startY < bottom && endY > top
    })
  }

  return (
    <div className="editor-table-field" ref={ref}>
      <div className="tiptap-editor">
        {!readOnly && <TipTapMenuBar editors={editors} />}
      </div>
      <EditorTableContext.Provider value={[tableContext, setTableContext]}>
        <div className="table scroll-x" ref={tableRef}>
          <div className="table__container">
            <table>
              <thead>
                <tr>
                  {headerCells.map((cell, i) => (
                    <th key={cell.id}>
                      <EditorTableHeader
                        onClick={editor => setEditors([editor])}
                        data={cell.content}
                        readOnly={readOnly}
                        placeholder="Enter column header"
                        id={`0-${i}`}
                        onChange={content => handleHeaderChange(i, content)}
                        onAddColumnClick={() => handleAddColumn(i + 1)}
                        onMoveLeftClick={() => handleMoveColumn(i, i - 1)}
                        onMoveRightClick={() => handleMoveColumn(i, i + 1)}
                        onDeleteColumnClick={() => handleDeleteColumn(i)}
                      />
                    </th>
                  ))}
                  <th className="table__add_column">
                    <div className="editor-table-header">
                      <div className="editor-table-header__actions"></div>
                      <button
                        className="table__add-button"
                        onClick={() => handleAddColumn(headerCells.length)}
                        data-tooltip="Add Column"
                        data-tooltip-left
                      >
                        +
                      </button>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {bodyRows.map((row, rowIndex) => (
                  <tr key={rowIndex}>
                    {row.map((cell, cellIndex) => (
                      <td key={cell.id}>
                        <EditorTableCell
                          onClick={editor => setEditors([editor])}
                          data={cell.content}
                          readOnly={readOnly}
                          index={cellIndex}
                          id={`${rowIndex + 1}-${cellIndex}`}
                          placeholder="Enter cell content"
                          onChange={content =>
                            handleCellChange(rowIndex, cellIndex, content)
                          }
                          onAddRowClick={() => handleAddRow(rowIndex + 1)}
                          onMoveUpClick={() =>
                            handleMoveRow(rowIndex, rowIndex - 1)
                          }
                          onMoveDownClick={() =>
                            handleMoveRow(rowIndex, rowIndex + 1)
                          }
                          onDeleteRowClick={() => handleDeleteRow(rowIndex)}
                        />
                      </td>
                    ))}
                    <td></td>
                  </tr>
                ))}
                <tr>
                  <td>
                    <button
                      className="table__add-button"
                      onClick={() => handleAddRow(bodyRows.length)}
                      data-tooltip="Add Row"
                      data-tooltip-right
                    >
                      +
                    </button>
                  </td>
                  {headerCells.map((cell, i) => (
                    <td key={i}></td>
                  ))}
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </EditorTableContext.Provider>
      <div
        className="editor-table-field__select-rect"
        ref={selectRectRef}
        style={{
          top: `${dragRect.y1 < dragRect.y2 ? dragRect.y1 : dragRect.y2}px`,
          left: `${dragRect.x1 < dragRect.x2 ? dragRect.x1 : dragRect.x2}px`,
          width: `${Math.abs(dragRect.x2 - dragRect.x1)}px`,
          height: `${Math.abs(dragRect.y2 - dragRect.y1)}px`,
          display: `${tableContext.activeCells.length > 1 ? 'block' : 'none'}`,
        }}
      ></div>
    </div>
  )
}

export const createTableField = id => {
  const rows = [1, 2, 3]
  const columns = [1, 2, 3]
  return {
    id: id,
    type: 'field',
    fieldType: EDITOR_TABLE_FIELD,
    blocks: [
      {
        table: {
          header: rows.map(() => emptyCell()),
          body: rows.map(() => columns.map(() => emptyCell())),
        },
      },
    ],
  }
}

function emptyCell() {
  return {
    content: [],
    id: randId(),
  }
}

function randId() {
  return Math.floor(Math.random() * 0xffffffff).toString()
}

EditorTableField.propTypes = {
  field: PropTypes.object,
  readOnly: PropTypes.bool,
  onChange: PropTypes.func,
  zIndex: PropTypes.number,
}

export default EditorTableField
