import { useDispatch, useSelector } from 'react-redux'
import { selectManagedContentId } from '../../../../../redux/user/userSelections'
import {
  selectSocketId,
  selectSocketResourceBlockedBy,
  selectSocketResourceOutOfDate,
  selectSocketSessionExpired,
} from '../../../../../redux/socket/socket'

import React, { useEffect } from 'react'
import ManageWorkflowStepModalBlocked from './ManageWorkflowStepModalBlocked'
import ManageWorkflowStepModalOutOfDate from './ManageWorkflowStepModalOutOfDate'
import useOnResourceBecomesUnblocked from '../../../../../hooks/useOnResourceBecomesUnblocked'
import ManageWorkflowStepManageModal from './ManageWorkflowStepManageModal'

const ManageWorkflowStepModalContainer = () => {
  const managedContentId = useSelector(selectManagedContentId)
  const resourceBlockedBy = useSelector(state =>
    selectSocketResourceBlockedBy({
      state,
      type: 'configureStep',
      id: managedContentId,
    }),
  )

  const isResourceOutOfDate = useSelector(state =>
    selectSocketResourceOutOfDate({
      state,
      type: 'configureStep',
      id: managedContentId,
    }),
  )

  useOnResourceBecomesUnblocked({
    name: 'configureStep',
    data: {
      stepId: managedContentId,
    },
    isBlocked: !!resourceBlockedBy,
  })
  const isSocketSessionExpired = useSelector(selectSocketSessionExpired)

  if (isSocketSessionExpired) {
    return null
  }

  if (resourceBlockedBy) {
    return <ManageWorkflowStepModalBlocked />
  }

  if (isResourceOutOfDate) {
    return <ManageWorkflowStepModalOutOfDate />
  }

  return <ManageWorkflowStepManageModal />
}

export default ManageWorkflowStepModalContainer
