import { ReactComponent as ArrowRight } from '../../../assets/icons/arrow-chevron-right-icon.svg'
import { ReactComponent as HomeIcon } from '../../../assets/icons/home-icon.svg'
import '../../../styles/global-components/media-library/media-library-breadcrumbs.scss'
import PropTypes from 'prop-types'
const MediaLibraryBreadcrumbs = ({ folder, onClick }) => {
  const showCrumbs = !!folder._id
  const path = folder?.path || []
  return (
    <div className="media-library-breadcrumbs">
      {showCrumbs ? (
        <>
          <div className="media-library-breadcrumbs__crumb">
            <button
              onClick={() => onClick(null)}
              data-tooltip="Home"
              className="media-library-breadcrumbs__home"
            >
              <HomeIcon height="15" width="15" />
            </button>
            <ArrowRight
              fill="#000"
              className="media-library-breadcrumbs__arrow"
            />
          </div>
          {path.map(crumb => (
            <div className="media-library-breadcrumbs__crumb" key={crumb._id}>
              <button onClick={() => onClick(crumb._id)}>{crumb.name}</button>
              <ArrowRight className="media-library-breadcrumbs__arrow" />
            </div>
          ))}
          <div className="media-library-breadcrumbs__crumb">{folder.name}</div>
        </>
      ) : (
        <div className="media-library-breadcrumbs__crumb">
          <HomeIcon
            height="15"
            width="15"
            className="media-library-breadcrumbs__home"
          />
        </div>
      )}
    </div>
  )
}

MediaLibraryBreadcrumbs.propTypes = {
  folder: PropTypes.shape({
    _id: PropTypes.string,
    path: PropTypes.array,
    name: PropTypes.string,
  }),
  onClick: PropTypes.func,
}

export default MediaLibraryBreadcrumbs
