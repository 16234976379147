import React from 'react'
import PropTypes from 'prop-types'

function StarIcon({ colour }, props) {
  return (
    <svg
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M36.9943 19.081C37.9066 18.1922 38.2286 16.8877 37.8348 15.6772C37.4418 14.4662 36.4146 13.6002 35.1541 13.417L27.3714 12.2862C26.9763 12.2285 26.635 11.9805 26.4583 11.6228L22.9778 4.56986C22.4142 3.42834 21.2731 2.71924 19.9999 2.71924C18.7267 2.71924 17.5857 3.42834 17.0221 4.56986C17.0221 4.56993 13.5415 11.6227 13.5415 11.6227C13.3649 11.9806 13.0235 12.2286 12.6291 12.2861L4.84591 13.4169C3.58534 13.6002 2.55815 14.4662 2.16531 15.6766C1.77135 16.8877 2.09331 18.1923 3.00534 19.0808L8.63722 24.5711C8.9229 24.8494 9.05326 25.2504 8.98597 25.6433L7.65622 33.3955C7.44135 34.6506 7.94753 35.895 8.97726 36.643C10.0072 37.3913 11.3473 37.4883 12.4749 36.896L19.436 33.2361C19.7891 33.0506 20.2107 33.0506 20.5635 33.2359L27.5251 36.896C28.6526 37.4883 29.9928 37.3913 31.0227 36.643C32.0524 35.8949 32.5586 34.6506 32.3438 33.3955L31.014 25.6436C30.9467 25.2504 31.077 24.8495 31.3628 24.5709L36.9943 19.081ZM28.9348 25.9996L30.2648 33.7521C30.3443 34.2165 30.1642 34.6594 29.783 34.9364C29.5714 35.0902 29.3262 35.1714 29.0738 35.1714C28.9632 35.1714 28.7513 35.1574 28.5061 35.0286L21.5451 31.3688C21.0699 31.1193 20.5359 30.9876 20.0001 30.9876C19.4639 30.9876 18.9296 31.1194 18.455 31.3688L11.4932 35.029C11.0151 35.2802 10.5365 35.1686 10.2172 34.9365C9.83584 34.6595 9.65577 34.2165 9.73536 33.7515L11.065 26C11.2495 24.9218 10.8922 23.8232 10.1094 23.0604L4.47782 17.5704C4.1399 17.2412 4.02543 16.7773 4.17126 16.3291C4.31702 15.8801 4.6825 15.5723 5.14938 15.5044L12.9323 14.3736C14.0149 14.2157 14.9493 13.5365 15.4331 12.5564L18.9135 5.50375C19.1222 5.08103 19.5284 4.82868 20 4.82868C20.4716 4.82868 20.8778 5.08103 21.0865 5.50375L24.5669 12.5563C25.0508 13.5365 25.9853 14.2157 27.067 14.3735C28.7924 14.6241 32.097 15.1039 34.8505 15.5044C35.3176 15.5723 35.6831 15.8803 35.8285 16.3284C35.9746 16.7775 35.86 17.2412 35.5224 17.5702L29.8904 23.0605C29.1076 23.8232 28.7504 24.9221 28.9348 25.9996Z"
        fill={colour}
      />
    </svg>
  )
}

StarIcon.defaultProps = {
  colour: '#000000',
}

StarIcon.propTypes = {
  colour: PropTypes.string,
}

export default StarIcon
