import axios from 'axios'
import { get } from 'lodash'
import { fetchWorkflow } from '../workflows/workflow'
import {
  UPDATE_WORKFLOW_BEGIN,
  UPDATE_WORKFLOW_ERROR,
  UPDATE_WORKFLOW_SUCCESS,
} from '../workflows/updateWorkflow'
import { getUserApiRequirements } from '../user/user'

export const deleteSteps =
  ({ stepIds, workflow, token }) =>
  async (dispatch, getState) => {
    const state = getState()
    const { userId, activeClientId, userEmail, activeOperatingLocation } =
      getUserApiRequirements(state)

    dispatch({ type: UPDATE_WORKFLOW_BEGIN })
    return axios({
      url: `${process.env.REACT_APP_PRODUCT_API}/api/v1/workflow/steps/delete/`,
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      data: {
        stepIds,
        authorId: userId,
        authorEmail: userEmail,
        clientId: activeClientId,
        operatingLocation: activeOperatingLocation,
        workflowId: workflow?._id,
      },
    })
      .then(() => {
        dispatch({
          type: UPDATE_WORKFLOW_SUCCESS,
        })
        dispatch(fetchWorkflow(workflow?.slug, token))
      })
      .catch(error => {
        dispatch({
          type: UPDATE_WORKFLOW_ERROR,
          payload: error.message,
        })
      })
  }
