import React from 'react'
import PropTypes from 'prop-types'
import { ReactComponent as MaterialLoaderSvg } from '../../../assets/images/material-loader.svg'

import '../../../styles/global-components/elements/material-loader.scss'

function MaterialLoader({ color, containerClasses }) {
  return (
    <div className={containerClasses || 'material-loader'}>
      <MaterialLoaderSvg style={color && { stroke: color }} />
    </div>
  )
}

MaterialLoader.defaultProps = {
  color: null,
  containerClasses: null,
}
MaterialLoader.propTypes = {
  color: PropTypes.string,
  containerClasses: PropTypes.string,
}
export default MaterialLoader
