import React from 'react'
import PropTypes from 'prop-types'

function SailboatIcon({ colour }, props) {
  return (
    <svg
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.8512 11.6624C11.8508 11.7866 11.8604 11.872 11.8638 11.8985L12.9843 11.8264C12.9844 11.7591 12.982 11.6929 12.9773 11.6278C12.983 11.471 13.0215 11.1976 13.2019 10.9853C13.3965 10.7563 13.7329 10.6323 14.2018 10.6169L14.1649 9.4923C13.3762 9.51822 12.7695 9.7689 12.3609 10.2375C12.1687 10.0473 11.9341 9.88859 11.6599 9.76574C11.0718 9.50221 10.5021 9.4923 10.4391 9.492L10.4345 10.6172C10.4382 10.6172 10.8287 10.6262 11.1998 10.7926C11.5994 10.9716 11.8094 11.2503 11.8512 11.6624Z"
        fill={colour}
      />
      <path
        d="M21.385 2.94501V27.1378L33.509 21.3199L21.385 2.94501ZM22.4865 6.69359L30.1762 18.348C29.9923 18.5384 29.4867 18.9941 28.8257 19.0319C28.27 19.0635 27.6968 18.7893 27.1239 18.2165C25.9465 17.0391 24.2852 16.7647 22.4865 17.4235V6.69359ZM22.4865 18.6378C23.4674 18.1985 25.0952 17.7792 26.3282 19.0121C27.0903 19.7742 27.8989 20.1598 28.734 20.1597C28.7858 20.1597 28.8379 20.1582 28.89 20.1553C29.7633 20.1053 30.4334 19.6368 30.8057 19.3021L31.8358 20.8632L22.4865 25.3497V18.6378Z"
        fill={colour}
      />
      <path
        d="M9.76334 35.0109C8.37878 35.383 7.37047 36.1166 7.32817 36.1477L7.99372 37.055C8.00261 37.0485 8.8977 36.4019 10.0853 36.0897C11.5872 35.6946 12.8547 36.0007 13.853 36.9989L14.6487 36.2032C13.3569 34.9114 11.6678 34.4991 9.76334 35.0109Z"
        fill={colour}
      />
      <path
        d="M32.2216 34.016L36.6898 28.2046H7.73254L9.77347 31.103C5.19963 31.2275 2.22564 32.5727 2 32.6743V33.8808C2 33.8808 10.0571 30.2761 19.5772 33.9667C22.4903 35.0963 25.3057 35.4997 27.865 35.4997C34.0852 35.4991 37.7129 33.5759 37.9822 33.4336L38 32.1975C37.9547 32.2213 35.6996 33.3919 32.2216 34.016ZM19.9837 32.9173C16.7605 31.6675 13.7815 31.1835 11.2122 31.1054C11.2043 31.1052 11.1966 31.1051 11.1888 31.1049C11.1967 31.1051 11.2043 31.1052 11.2122 31.1054L9.99677 29.3794H34.3046L30.5545 34.2567H30.5545C27.5369 34.5869 23.8521 34.4171 19.9837 32.9173ZM32.0967 34.0374C31.6325 34.1183 31.1474 34.1892 30.6429 34.2462C31.1473 34.1891 31.6324 34.1182 32.0967 34.0374Z"
        fill={colour}
      />
      <path
        d="M5.7433 19.0394C5.74286 19.1637 5.75248 19.249 5.75586 19.2756L6.87637 19.2035C6.87644 19.1361 6.87409 19.07 6.86939 19.0049C6.87512 18.848 6.9136 18.5746 7.09401 18.3624C7.2886 18.1333 7.62497 18.0094 8.09388 17.994L8.05695 16.8693C7.26833 16.8953 6.66159 17.1459 6.25304 17.6146C6.0608 17.4244 5.8262 17.2656 5.55195 17.1428C4.96386 16.8793 4.3942 16.8693 4.3312 16.869L4.32658 17.9943C4.33032 17.9943 4.72081 18.0033 5.09184 18.1696C5.49144 18.3487 5.70145 18.6274 5.7433 19.0394Z"
        fill={colour}
      />
      <path
        d="M32.7492 13.9758C32.7487 14.1001 32.7583 14.1854 32.7617 14.212L33.8822 14.1399C33.8823 14.0725 33.88 14.0064 33.8753 13.9413C33.881 13.7844 33.9195 13.511 34.0999 13.2988C34.2945 13.0697 34.6308 12.9458 35.0997 12.9304L35.0628 11.8057C34.2742 11.8317 33.6675 12.0823 33.2589 12.551C33.0667 12.3608 32.8321 12.202 32.5578 12.0792C31.9697 11.8157 31.4001 11.8057 31.3371 11.8055L31.3324 12.9307C31.3362 12.9307 31.7267 12.9397 32.0977 13.106C32.4973 13.2851 32.7073 13.5638 32.7492 13.9758Z"
        fill={colour}
      />
      <path
        d="M29.4621 31.0682H26.6719V32.2431H29.4621V31.0682Z"
        fill={colour}
      />
      <path
        d="M20.0633 27.1032V10.6285L12.7899 27.1032H20.0633ZM18.9619 26.0017H14.515L18.9619 15.9754V26.0017Z"
        fill={colour}
      />
    </svg>
  )
}

SailboatIcon.defaultProps = {
  colour: '#000000',
}

SailboatIcon.propTypes = {
  colour: PropTypes.string,
}

export default SailboatIcon
