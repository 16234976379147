import React, { useEffect, useRef, useState } from 'react'
import { get, isEmpty } from 'lodash'
import clsx from 'clsx'
import Accordion from '../../../consumer-flow/base-components/accordions/Accordion'
import PropTypes from 'prop-types'
import highlightTextInHTML from '../../../../utilities/find-replace/highlightTextInHTML'

const FindReplaceAccordionPreview = ({ item, highlight }) => {
  const ref = useRef()
  const accordions = get(item, 'subComponents', [])
  const configurations = item?.configurations || {}
  const {
    title,
    titleFormat,
    titleColor,
    description,
    bold = false,
  } = configurations
  const hasInnerText = !!ref?.current?.innerText
  const headingClass = !!titleFormat ? titleFormat : 'h2'

  useEffect(
    function highlightResultsOnSearch() {
      if (highlight && hasInnerText) {
        highlightTextInHTML({ ref, highlight })
      }
    },
    [highlight, hasInnerText],
  )

  return (
    <div className="accordions" ref={ref}>
      <div className="accordions__heading flex">
        <div className="flex-fill">
          {title && (
            <div
              className={clsx('accordions__title', headingClass)}
              style={{
                ...(titleColor && { color: titleColor }),
                ...(bold && { fontWeight: 'bold' }),
              }}
            >
              {title}
            </div>
          )}
          {description && (
            <p className="accordions__description">{description}</p>
          )}
        </div>
        <div className="flex align-end">
          <button className="btn-text btn-text--bright-purple">Open</button>
        </div>
      </div>

      {accordions.map(accordion => {
        const id = accordion?._id || accordion?.id

        return <Accordion key={id} subcomponent={accordion} />
      })}
    </div>
  )
}

FindReplaceAccordionPreview.propTypes = {
  item: PropTypes.object,
  highlight: PropTypes.object,
}

export default FindReplaceAccordionPreview
