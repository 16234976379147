import PropTypes from 'prop-types'
import { EDITOR_DIVIDER_FIELD } from '../../../../configuration/editor'
import '../../../../styles/editor-flow/editor-fields/editor-divider-field.scss'

function EditorDividerField({ field, onChange }) {
  return <div className="editor-divider-field"></div>
}

export const createDividerField = id => {
  return {
    id,
    type: 'field',
    fieldType: EDITOR_DIVIDER_FIELD,
    blocks: [],
  }
}

EditorDividerField.propTypes = {
  field: PropTypes.object,
  onChange: PropTypes.func,
}

export default EditorDividerField
