import React from 'react'
import PropTypes from 'prop-types'
import { get, isEmpty } from 'lodash'
import renderConsumerField from '../../../utilities/fields/renderConsumerFields'
import '../../../styles/consumer-flow/base-components/content.scss'
import useQueryParams from '../../../hooks/useQueryParams'
import useEntryHighlight from '../../../hooks/useEntryHighlight'

const Content = ({ component }) => {
  const layoutWidth = get(component, 'configurations.layout', '100')
  const cards = get(component, 'subComponents', [])
  const highlight = useEntryHighlight()
  return (
    <div className="content-component" style={{ display: 'flex' }}>
      {!isEmpty(cards)
        ? cards.map(card => {
            const cardId = get(card, '_id', null) || card?.id
            const cardFields = get(card, 'fields', [])

            return !isEmpty(cardFields) ? (
              <div
                key={cardId}
                className={`content-area layout-${layoutWidth}`}
              >
                {cardFields.map(field => {
                  const fieldId = get(field, '_id', null)
                  const fieldType = get(field, 'fieldType', null)
                  const fieldHighlight =
                    highlight?.itemId === fieldId ? highlight : null

                  const FieldComponent = renderConsumerField(fieldType)
                  return FieldComponent ? (
                    <FieldComponent
                      key={fieldId}
                      field={field}
                      highlight={fieldHighlight}
                    />
                  ) : null
                })}
              </div>
            ) : null
          })
        : null}
    </div>
  )
}

Content.propTypes = {
  component: PropTypes.object,
}

export default Content
