import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import { get, isEmpty } from 'lodash'
import { Link, matchPath, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import routes from '../../../../configuration/routes'
import {
  selectActiveClientId,
  selectActiveOperatingLocation,
} from '../../../../redux/user/userSelections'
import { selectWorkflowState } from '../../../../redux/workflows/workflow'
import { ReactComponent as HomeIcon } from '../../../../assets/icons/home-icon.svg'
import { ReactComponent as ArrowRight } from '../../../../assets/icons/arrow-chevron-right-icon.svg'
import '../../../../styles/consumer-flow/platform-navigation/platform-navigation.scss'

function PlatformNavigation({ isNavigationOpen, onToggleClick }) {
  const { pathname } = useLocation()
  const containerRef = useRef(null)

  const browseWorkflowPath =
    matchPath(
      { path: `/${routes.browseWorkflows}/:category/:workflow` },
      pathname,
    ) ||
    matchPath(
      { path: `/${routes.browseWorkflows}/:category/:workflow/:step` },
      pathname,
    )
  const activeCategorySlug = get(browseWorkflowPath, 'params.category', null)
  const activeWorkflowSlug = get(browseWorkflowPath, 'params.workflow', null)
  const activeWorkflowStepSlug = get(browseWorkflowPath, 'params.step', null)
  const activeClientId = useSelector(selectActiveClientId)
  const activeOperatingLocation = useSelector(selectActiveOperatingLocation)
  const workflowState = useSelector(selectWorkflowState)
  const workflowOptions = get(workflowState, 'workflow', [])
  const workflowByClientSlug = get(
    workflowOptions,
    `[${activeOperatingLocation}-${activeClientId}-${activeWorkflowSlug}]`,
    {},
  )
  const workflowSteps = get(workflowByClientSlug, 'steps', [])
  const displayedSteps = [...workflowSteps]

  function handleToggleClick() {
    onToggleClick()
  }

  return (
    <div
      className={`platform-navigation ${isNavigationOpen ? 'open' : ''}`}
      ref={containerRef}
    >
      <button
        className="platform-navigation__indicator"
        onClick={handleToggleClick}
      >
        <ArrowRight />
      </button>

      <div className="platform-navigation__content">
        <h4>Workflows Menu</h4>

        <Link className="platform-navigation__link home" to={routes.dashboard}>
          <HomeIcon />
          Workflows Dashboard
        </Link>

        {!isEmpty(displayedSteps)
          ? displayedSteps.map((step, i) => {
              const stepName = get(step, 'name', null)
              const stepSlug = get(step, 'slug', null)
              const nestedSteps = get(step, 'nestedSteps', [])

              return (
                <div
                  key={`platform-nav-link-${i}`}
                  className="platform-navigation__links"
                >
                  <Link
                    to={`${routes.browseWorkflows}/${activeCategorySlug}/${activeWorkflowSlug}/${stepSlug}`}
                    className={`platform-navigation__link step${
                      activeWorkflowStepSlug === stepSlug ? ' active' : ''
                    }`}
                  >
                    {stepName}
                  </Link>

                  {!isEmpty(nestedSteps) ? (
                    <div className="platform-navigation__nested-links">
                      {' '}
                      {nestedSteps.map((nestedStepId, j) => {
                        const nestedStepData = displayedSteps.find(
                          step => step?._id === nestedStepId,
                        )

                        displayedSteps.splice(
                          displayedSteps.findIndex(
                            step => step._id === nestedStepId,
                          ),
                          1,
                        )

                        const nestedStepName = get(nestedStepData, 'name', null)
                        const nestedStepSlug = get(nestedStepData, 'slug', null)

                        return nestedStepName && nestedStepSlug ? (
                          <Link
                            key={`platform-nav-sub-link-${j}`}
                            to={`${routes.browseWorkflows}/${activeCategorySlug}/${activeWorkflowSlug}/${nestedStepSlug}`}
                            className={`platform-navigation__link${
                              activeWorkflowStepSlug === nestedStepSlug
                                ? ' active'
                                : ''
                            }`}
                          >
                            {i + 1}.{j + 1} {nestedStepName}
                          </Link>
                        ) : null
                      })}
                    </div>
                  ) : null}
                </div>
              )
            })
          : null}
      </div>
    </div>
  )
}

PlatformNavigation.propTypes = {
  isNavigationOpen: PropTypes.bool,
  onToggleClick: PropTypes.func,
}

export default PlatformNavigation
