// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.view-my-workflows__table {
  display: flex;
  flex-wrap: wrap;
  margin: 20px 0 0 0;
  width: 100%;
}
.view-my-workflows__table .table__container {
  max-height: calc(100vh - 340px);
}
.view-my-workflows__table .icon {
  width: 5%;
}
.view-my-workflows__table .name {
  width: 20%;
}
.view-my-workflows__table .status {
  width: 8%;
}
.view-my-workflows__table .categories {
  width: 18%;
}
.view-my-workflows__table .subcategories {
  width: 18%;
}
.view-my-workflows__table .dateModified {
  width: 17%;
}
.view-my-workflows__table .edit,
.view-my-workflows__table .delete {
  text-align: right;
}
.view-my-workflows__table .edit button,
.view-my-workflows__table .delete button {
  display: inline-block;
}
.view-my-workflows__table .configure svg,
.view-my-workflows__table .duplicate svg,
.view-my-workflows__table .edit svg,
.view-my-workflows__table .delete svg {
  width: 18px;
  max-height: 18px;
}`, "",{"version":3,"sources":["webpack://./src/styles/consumer-flow/view-my-workflows/view-my-workflows-table.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,eAAA;EACA,kBAAA;EACA,WAAA;AADF;AAGE;EACE,+BAAA;AADJ;AAIE;EACE,SAAA;AAFJ;AAKE;EACE,UAAA;AAHJ;AAME;EACE,SAAA;AAJJ;AAOE;EACE,UAAA;AALJ;AAQE;EACE,UAAA;AANJ;AASE;EACE,UAAA;AAPJ;AAUE;;EAEE,iBAAA;AARJ;AAUI;;EACE,qBAAA;AAPN;AAeI;;;;EACE,WAAA;EACA,gBAAA;AAVN","sourcesContent":["@import '../../configuration/variables';\n\n.view-my-workflows__table {\n  display: flex;\n  flex-wrap: wrap;\n  margin: 20px 0 0 0;\n  width: 100%;\n\n  .table__container {\n    max-height: calc(100vh - 340px);\n  }\n\n  .icon {\n    width: 5%;\n  }\n\n  .name {\n    width: 20%;\n  }\n\n  .status {\n    width: 8%;\n  }\n\n  .categories {\n    width: 18%;\n  }\n\n  .subcategories {\n    width: 18%;\n  }\n\n  .dateModified {\n    width: 17%;\n  }\n\n  .edit,\n  .delete {\n    text-align: right;\n\n    button {\n      display: inline-block;\n    }\n  }\n\n  .configure,\n  .duplicate,\n  .edit,\n  .delete {\n    svg {\n      width: 18px;\n      max-height: 18px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
