import React from 'react'
import PropTypes from 'prop-types'

function WarningIcon({ colour }, props) {
  return (
    <svg
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M33.9207 18.8908C33.5404 18.8134 33.169 19.0592 33.0918 19.4398C31.8342 25.6313 26.3283 30.125 20 30.125C12.6336 30.125 6.64062 24.1321 6.64062 16.7656C6.64062 9.3992 12.6336 3.40625 20 3.40625C26.3189 3.40625 31.8232 7.89205 33.088 14.0725C33.1659 14.453 33.5371 14.6984 33.9178 14.6204C34.2982 14.5426 34.5435 14.171 34.4657 13.7906C33.7938 10.5071 31.9921 7.51735 29.3926 5.37205C26.7577 3.19756 23.422 2 20 2C11.8582 2 5.23438 8.62386 5.23438 16.7656C5.23438 23.9458 10.3864 29.9444 17.1875 31.2614V36.5938C17.1875 37.3692 17.8183 38 18.5938 38H21.4062C22.1817 38 22.8125 37.3692 22.8125 36.5938V31.2596C25.2206 30.7914 27.4943 29.7282 29.4005 28.1527C32.0016 26.003 33.802 23.0082 34.4699 19.7197C34.5472 19.3392 34.3014 18.968 33.9207 18.8908ZM21.4062 36.5938H18.5938V31.4639C19.0568 31.5078 19.5257 31.5312 20 31.5312C20.471 31.5312 20.9401 31.5078 21.4062 31.463V36.5938Z"
        fill={colour}
      />
      <path
        d="M20 4.8125C13.409 4.8125 8.04688 10.1747 8.04688 16.7656C8.04688 23.3566 13.409 28.7188 20 28.7188C26.591 28.7188 31.9531 23.3566 31.9531 16.7656C31.9531 10.1747 26.591 4.8125 20 4.8125ZM20 27.3125C14.1845 27.3125 9.45312 22.5812 9.45312 16.7656C9.45312 10.9501 14.1845 6.21875 20 6.21875C25.8155 6.21875 30.5469 10.9501 30.5469 16.7656C30.5469 22.5812 25.8155 27.3125 20 27.3125Z"
        fill={colour}
      />
      <path
        d="M20 21.2391C18.8369 21.2391 17.8906 22.1854 17.8906 23.3485C17.8906 24.5116 18.8369 25.4579 20 25.4579C21.1631 25.4579 22.1094 24.5116 22.1094 23.3485C22.1094 22.1854 21.1631 21.2391 20 21.2391ZM20 24.0516C19.6123 24.0516 19.2969 23.7362 19.2969 23.3485C19.2969 22.9608 19.6123 22.6454 20 22.6454C20.3877 22.6454 20.7031 22.9608 20.7031 23.3485C20.7031 23.7362 20.3877 24.0516 20 24.0516Z"
        fill={colour}
      />
      <path
        d="M20 8.07338C19.0435 8.07338 18.1554 8.50425 17.5634 9.25554C16.9713 10.0069 16.7601 10.9711 16.9839 11.9011L18.6328 18.7555C18.7877 19.3999 19.3372 19.8328 20 19.8328C20.6628 19.8328 21.2122 19.3998 21.3672 18.7555L23.0161 11.9011C23.2398 10.9711 23.0286 10.0069 22.4366 9.25554C21.8446 8.50433 20.9565 8.07338 20 8.07338ZM21.6489 11.5723L20 18.4266L18.3511 11.5723C18.2288 11.0638 18.3443 10.5367 18.6679 10.1259C18.9916 9.71525 19.4771 9.4797 20 9.4797C20.5228 9.4797 21.0084 9.71525 21.3321 10.1259C21.6557 10.5367 21.7712 11.0638 21.6489 11.5723Z"
        fill={colour}
      />
      <path
        d="M34.0625 17.4688C34.4508 17.4688 34.7656 17.154 34.7656 16.7656C34.7656 16.3773 34.4508 16.0625 34.0625 16.0625C33.6742 16.0625 33.3594 16.3773 33.3594 16.7656C33.3594 17.154 33.6742 17.4688 34.0625 17.4688Z"
        fill={colour}
      />
    </svg>
  )
}

WarningIcon.defaultProps = {
  colour: '#000000',
}

WarningIcon.propTypes = {
  colour: PropTypes.string,
}

export default WarningIcon
