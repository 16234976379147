import axios from 'axios'
import { get, isEmpty } from 'lodash'
import { fetchCategories } from './categories'
import {
  UPDATE_CATEGORY_BEGIN,
  UPDATE_CATEGORY_ERROR,
  UPDATE_CATEGORY_SUCCESS,
} from './updateCategory'

export const CREATE_CATEGORY_BEGIN = 'CREATE_CATEGORY_BEGIN'
export const CREATE_CATEGORY_ERROR = 'CREATE_CATEGORY_ERROR'
export const CREATE_CATEGORY_SUCCESS = 'CREATE_CATEGORY_SUCCESS'

export const createCategory =
  (categoryData, token) => async (dispatch, getState) => {
    const user = getState().userData.user
    const activeClient = getState().userSelectionsData.activeClient
    const activeClientId = get(activeClient, 'id', null)
    const activeOperatingLocation =
      getState().userSelectionsData.activeOperatingLocation
    const newSubcategories = get(categoryData, 'subcategories', [])
    const category = get(categoryData, 'category', null)
    const icon = get(categoryData, 'icon', null)
    const iconColour = get(categoryData, 'iconColour', null)

    const createCategoryPayload = {
      ...categoryData,
      category: category.trim(),
      icon: {
        id: icon,
        colour: iconColour,
      },
      subcategories: !isEmpty(newSubcategories)
        ? newSubcategories.map(newSubcategory => {
            const subcategory = get(newSubcategory, 'value', null)
            return subcategory.trim()
          })
        : [],
    }

    dispatch({ type: CREATE_CATEGORY_BEGIN })

    return axios({
      url: `${process.env.REACT_APP_PRODUCT_API}/api/v1/workflow/category/create/${activeClientId}`,
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      data: {
        category: createCategoryPayload,
        operatingLocation: activeOperatingLocation,
      },
    })
      .then(() => {
        dispatch({
          type: CREATE_CATEGORY_SUCCESS,
        })
        dispatch(fetchCategories(token))
      })
      .catch(error => {
        const customError = get(error, 'response.data.error', 'Error')

        dispatch({
          type: CREATE_CATEGORY_ERROR,
          payload: customError,
        })
      })
  }

const initialState = {
  error: null,
  loading: false,
}

export default function createCategoryReducer(state = initialState, action) {
  switch (action.type) {
    case UPDATE_CATEGORY_BEGIN:
    case CREATE_CATEGORY_BEGIN:
      return { ...state, loading: true }
    case UPDATE_CATEGORY_SUCCESS:
    case CREATE_CATEGORY_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
      }
    case UPDATE_CATEGORY_ERROR:
    case CREATE_CATEGORY_ERROR:
      return { ...state, loading: false, error: action.payload }
    default:
      return state
  }
}

// SELECTORS
export const selectCreateCategoryState = state => state.createCategoryData
