import { DELETE_CATEGORY_BEGIN } from './deleteCategory'
import { getUserApiRequirements } from '../user/user'
import axios from 'axios'

export const fetchWorkflowsUsingCategory =
  (categoryId, token) => async (dispatch, getState) => {
    const { activeClientId, activeOperatingLocation } = getUserApiRequirements(
      getState(),
    )
    dispatch({ type: DELETE_CATEGORY_BEGIN })

    return axios({
      url: `${process.env.REACT_APP_PRODUCT_API}/api/v1/workflows/categories/delete-check/${categoryId}`,
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      data: {
        clientId: activeClientId,
        operatingLocation: activeOperatingLocation,
      },
    }).then(res => {
      return res?.data || []
    })
  }
