// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.find-replace-text-form {
  margin-bottom: 8px;
}
.find-replace-text-form .form {
  display: flex;
}
.find-replace-text-form .form button[type=submit] {
  width: 150px;
  font-size: 16px;
}
.find-replace-text-form .form__field {
  margin-right: 24px;
}
.find-replace-text-form .form__field .field-error {
  position: absolute;
}`, "",{"version":3,"sources":["webpack://./src/styles/editor-flow/find-replace/find-replace-text.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;AACF;AAAE;EACE,aAAA;AAEJ;AAAI;EACE,YAAA;EACA,eAAA;AAEN;AAAI;EACE,kBAAA;AAEN;AADM;EACE,kBAAA;AAGR","sourcesContent":[".find-replace-text-form {\n  margin-bottom: 8px;\n  .form {\n    display: flex;\n\n    button[type='submit'] {\n      width: 150px;\n      font-size: 16px;\n    }\n    &__field {\n      margin-right: 24px;\n      .field-error {\n        position: absolute;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
