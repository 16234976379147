import axios from 'axios'
import processFindReplaceTextApiData from '../../utilities/find-replace/processFindReplaceTextApiData'
import processFindReplaceEmailApiData from '../../utilities/find-replace/processFindReplaceEmailApiData'

async function fetchFindEmail({
  activeClientId,
  token,
  userId,
  userEmail,
  find,
}) {
  return axios({
    url: `${process.env.REACT_APP_PRODUCT_API}/api/v1/find-replace/search/email/${activeClientId}`,
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    data: {
      authorId: userId,
      authorEmail: userEmail,
      find,
    },
  }).then(res => {
    return processFindReplaceEmailApiData({
      data: res?.data?.results || [],
      find,
    })
  })
}

export default fetchFindEmail
