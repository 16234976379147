import React from 'react'
import PropTypes from 'prop-types'

function TextField({
  label,
  type,
  required,
  field,
  tooltip,
  form: { touched, errors },
  ...props
}) {
  return (
    <>
      {label && (
        <label htmlFor={field.name}>
          {label}
          {required && <sup className="required">*</sup>}
          {tooltip && (
            <div
              className="field-tooltip"
              data-tooltip={tooltip}
              data-tooltip-long
            >
              ?
            </div>
          )}
        </label>
      )}

      <input
        id={field.name}
        className={touched[field.name] && errors[field.name] ? 'error' : ''}
        type={type}
        {...field}
        {...props}
      />
      {touched[field.name] && errors[field.name] && (
        <div className="field-error">{errors[field.name]}</div>
      )}
    </>
  )
}

TextField.defaultProps = {
  type: 'text',
}

TextField.propTypes = {
  errors: PropTypes.object,
  field: PropTypes.object,
  form: PropTypes.object,
  tooltip: PropTypes.string,
  label: PropTypes.string,
  required: PropTypes.bool,
  touched: PropTypes.object,
  type: PropTypes.string,
}

export default TextField
