import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { get, isEmpty } from 'lodash'
import Accordion from './Accordion'
import '../../../../styles/consumer-flow/base-components/accordions.scss'
import clsx from 'clsx'
import useEntryHighlight from '../../../../hooks/useEntryHighlight'
import highlightTextInHTML from '../../../../utilities/find-replace/highlightTextInHTML'
import getDefaultOpenAccordions from '../../../../utilities/find-replace/getDefaultOpenAccordions'

const Accordions = ({ component }) => {
  const ref = useRef()
  const headingRef = useRef()
  const highlight = useEntryHighlight()
  const [openAccordions, setOpenAccordions] = useState(
    getDefaultOpenAccordions({ highlight, component }),
  )
  const accordions = get(component, 'subComponents', [])
  const configurations = component?.configurations || {}
  const { title, titleColor, description, bold = false } = configurations
  const hasInnerText = !!headingRef?.current?.innerText

  useEffect(
    function highlightResultsOnSearch() {
      if (
        highlight?.itemId === component?._id &&
        !highlight?.subItemId &&
        hasInnerText
      ) {
        highlightTextInHTML({ ref: headingRef, highlight })
      }
    },
    [highlight, hasInnerText],
  )

  function handleAccordionToggle(accordion) {
    const id = accordion?._id || accordion?.id
    if (openAccordions.find(item => item === id)) {
      setOpenAccordions(openAccordions.filter(item => item !== id))
    } else {
      setOpenAccordions([...openAccordions, id])
    }
  }

  const allAccordionsToggled =
    accordions.length > 0 && accordions.length === openAccordions.length
  function toggleAllAccordions() {
    if (allAccordionsToggled) {
      setOpenAccordions([])
    } else {
      setOpenAccordions(
        accordions.map(accordion => {
          const id = accordion?._id || accordion?.id
          return id
        }),
      )
    }
  }

  const headingClass = configurations?.titleFormat || 'h2'

  return !isEmpty(accordions) ? (
    <div className="accordions" ref={ref}>
      <div className="accordions__heading flex" ref={headingRef}>
        <div className="flex-fill">
          {title && (
            <div
              className={clsx('accordions__title', headingClass)}
              style={{
                ...(titleColor && { color: titleColor }),
                ...(bold && { fontWeight: 'bold' }),
              }}
            >
              {title}
            </div>
          )}
          {description && (
            <p className="accordions__description">{description}</p>
          )}
        </div>
        <div className="flex align-end">
          <button
            className="btn-text btn-text--bright-purple"
            onClick={() => toggleAllAccordions()}
          >
            {allAccordionsToggled ? 'Close' : 'Open'} All
          </button>
        </div>
      </div>

      {accordions.map(accordion => {
        const id = accordion?._id || accordion?.id

        return (
          <Accordion
            key={id}
            subcomponent={accordion}
            isOpen={!!openAccordions.find(item => item === id)}
            onToggle={handleAccordionToggle}
          />
        )
      })}
    </div>
  ) : null
}

Accordions.propTypes = {
  component: PropTypes.object,
}

export default Accordions
