import PropTypes from 'prop-types'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import clsx from 'clsx'
import { useEffect, useState } from 'react'
import { selectComponent } from '../../../configuration/editor'
import fixTransform from '../../../utilities/workflows/fixTransform'
import getId from '../../../utilities/editor/getId'
import { ReactComponent as EditIcon } from '../../../assets/icons/pencil-writing-icon.svg'
import { ReactComponent as DeleteIcon } from '../../../assets/icons/delete-icon.svg'
import { ReactComponent as DuplicateIcon } from '../../../assets/icons/duplicate-icon.svg'
import { ReactComponent as ReorderIcon } from '../../../assets/icons/reorder-icon.svg'
import { ReactComponent as DragAndDropIcon } from '../../../assets/icons/drag-and-drop-component-icon.svg'
import {
  selectDuplicatedComponentVisibility,
  setDuplicatedContentData,
  toggleDuplicateComponentModal,
} from '../../../redux/user/userSelections'
import { useDispatch, useSelector } from 'react-redux'
import { get } from 'lodash'

const EditorComponent = ({
  component,
  index,
  onEditClick,
  onCancelClick,
  onChange,
  onSaveClick,
  onDeleteClick,
  selectedComponent,
  dropPath,
}) => {
  const dispatch = useDispatch()
  const isDuplicateComponentModalVisible = useSelector(
    selectDuplicatedComponentVisibility,
  )
  const dropLevel = 'components'

  const { componentType } = component
  const id = getId(component)
  const isSelected = getId(selectedComponent) === id
  const hideEdit = !!selectedComponent
  const componentConfig = selectComponent(componentType)
  const [originalValues, setOriginalValues] = useState(component)

  const componentProps = {
    component,
    dropPath: [...dropPath, index],
    onChange,
  }

  const Component = isSelected
    ? componentConfig?.editorComponent
    : componentConfig?.frontEndComponent

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
    setActivatorNodeRef,
  } = useSortable({
    id,
    data: {
      dropPath,
      dropLevel,
    },
    disabled: !!selectedComponent,
  })
  const style = {
    transform: CSS.Transform.toString(fixTransform(transform)),
    transition,
    ...(isDragging && {
      height: '100px',
      zIndex: 100,
    }),
  }

  const componentStyle = {
    ...(isDragging && {
      background: '#F5FBFF',
      border: '1px dashed #0BA5EC',
    }),
  }

  useEffect(() => {
    setOriginalValues(component)
  }, [isSelected])

  function handleCancel() {
    onCancelClick(originalValues)
  }

  function handleSave() {
    onSaveClick()
  }

  function handleDelete() {
    if (
      confirm(
        `Are you sure you want to delete this ${componentConfig.title} block?`,
      )
    ) {
      onDeleteClick(component)
    }
  }

  function handleEdit(evt) {
    onEditClick(component)
  }

  function handleDuplicate() {
    dispatch(
      setDuplicatedContentData({
        workflowId: get(component, 'workflowId', null),
        stepId: get(component, 'stepId', null),
        componentId: get(component, '_id', null),
        subComponentId: null,
      }),
    )
    dispatch(toggleDuplicateComponentModal(!isDuplicateComponentModalVisible))
  }

  return (
    <div
      className="editor-component-container"
      ref={setNodeRef}
      style={style}
      {...attributes}
    >
      {componentConfig ? (
        <div
          className={clsx('editor-component', { selected: isSelected })}
          style={componentStyle}
        >
          <div className="editor-component-header">
            <div
              className="editor-component-type"
              ref={setActivatorNodeRef}
              {...listeners}
            >
              {!isSelected ? <ReorderIcon /> : null}
              {componentConfig.title}
            </div>

            <EditorComponentActions
              isSelected={isSelected}
              hideEdit={hideEdit}
              onCancel={handleCancel}
              onSave={handleSave}
              onDelete={handleDelete}
              onEdit={handleEdit}
              onDuplicate={handleDuplicate}
            />
          </div>
          <Component {...componentProps} />
        </div>
      ) : (
        <div className="editor-component-placeholder">
          <DragAndDropIcon />
          Drop Component To Add
        </div>
      )}
    </div>
  )
}

const EditorComponentActions = ({
  isSelected,
  hideEdit,
  onCancel,
  onSave,
  onDelete,
  onEdit,
  onDuplicate,
}) => {
  return (
    <div className="editor-component-actions">
      {isSelected ? (
        <>
          <button className="btn btn--light-grey" onClick={onCancel}>
            Cancel
          </button>

          <button className="btn btn--bright-green" onClick={onSave}>
            Save
          </button>

          <button className="btn btn--light-red" onClick={onDelete}>
            <DeleteIcon />
          </button>
        </>
      ) : (
        <>
          {!hideEdit && (
            <div className="flex">
              <button className="btn btn--light-blue svg-left" onClick={onEdit}>
                <EditIcon />
                Edit
              </button>

              <button className="btn btn--white" onClick={onDuplicate}>
                <DuplicateIcon />
              </button>
            </div>
          )}
        </>
      )}
    </div>
  )
}

EditorComponent.propTypes = {
  component: PropTypes.object,
  index: PropTypes.number,
  onEditClick: PropTypes.func,
  onCancelClick: PropTypes.func,
  onChange: PropTypes.func,
  onSaveClick: PropTypes.func,
  onDeleteClick: PropTypes.func,
  selectedComponent: PropTypes.object,
  dropPath: PropTypes.array,
}

EditorComponentActions.propTypes = {
  isSelected: PropTypes.bool,
  hideEdit: PropTypes.bool,
  onCancel: PropTypes.func,
  onSave: PropTypes.func,
  onDelete: PropTypes.func,
  onEdit: PropTypes.func,
  onDuplicate: PropTypes.func,
}

export default EditorComponent
