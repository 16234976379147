import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { get, isEmpty } from 'lodash'
import useAuthToken from '../../../hooks/useAuthToken'
import { selectActiveClient } from '../../../redux/user/userSelections'
import { selectActiveOperatingLocation } from '../../../redux/user/userSelections'
import { setActiveOperatingLocation } from '../../../redux/user/userSelections'
import { mixpanelTrackOperatingLocation } from '../../../utilities/analytics/mixpanelEvents'
import { fetchWorkflows } from '../../../redux/workflows/workflows'
import { fetchCategories } from '../../../redux/categories/categories'
import { checkClientIndexes } from '../../../redux/global-search/checkClientIndexes'
import getOperatingLocationIcon from '../../../utilities/content/getOperatingLocationIcon'
import ClickAwayListener from '../elements/ClickAwayListener'
import OperatingLocationPlaceholder from '../../../assets/icons/operating-locations-placeholder-icon.svg'
import { ReactComponent as ArrowDownFullIcon } from '../../../assets/icons/arrow-down-full-icon.svg'
import '../../../styles/global-components/operating-locations/operating-locations-field.scss'

function OperatingLocationsField({ isSearchOpen }) {
  const { token } = useAuthToken({})
  const dispatch = useDispatch()
  const { type } = useParams()
  const navigate = useNavigate()
  const [isLocationsToggled, toggleLocations] = useState(false)
  const activeClient = useSelector(selectActiveClient)
  const activeClientId = get(activeClient, 'id', null)
  const activeOperatingLocation = useSelector(selectActiveOperatingLocation)
  const operatingLocations = get(activeClient, 'operatingLocations', [])

  const operatingOptions = []

  const handleOperatingLocationsEvent = async locationCode => {
    dispatch(setActiveOperatingLocation(locationCode))
    dispatch(checkClientIndexes(activeClientId, locationCode))
    mixpanelTrackOperatingLocation(locationCode, 'User Input')

    if (type) {
      navigate('.', { replace: true })
    }

    toggleLocations(!isLocationsToggled)
    await dispatch(fetchWorkflows(token))
    await dispatch(fetchCategories(token))
  }

  for (let i = 0; i < operatingLocations.length; i++) {
    const location = operatingLocations[i]
    const locationName = get(location, 'name', null)
    const locationCode = get(location, 'code', null)
    const locationIcon = get(location, 'icon.url', OperatingLocationPlaceholder)
    const isActiveLocation = locationCode === activeOperatingLocation

    if (locationName && locationCode) {
      operatingOptions.push(
        <button
          tabIndex={isSearchOpen ? 0 : -1}
          className={`toggle${isActiveLocation ? ' active' : ''}`}
          key={`operating-location${i}`}
          onClick={
            !isActiveLocation
              ? () => handleOperatingLocationsEvent(locationCode)
              : null
          }
        >
          <span
            className="icon"
            style={{
              backgroundImage: `url('${locationIcon}')`,
            }}
          >
            {locationName}
          </span>
          <span className="name">{locationCode}</span>
        </button>,
      )
    }
  }

  const activeLocationIcon = getOperatingLocationIcon(
    operatingLocations,
    activeOperatingLocation,
  )

  // Only display field when API returns a locations array greater than
  // 1 entry and an active location is preset in the reducer
  return (
    !isEmpty(operatingLocations) &&
    operatingOptions.length > 1 &&
    activeOperatingLocation && (
      <ClickAwayListener toggleEvent={() => toggleLocations(false)}>
        <div className="operating-locations-field">
          <button
            tabIndex={isSearchOpen ? 0 : -1}
            className={`operating-locations-field__toggle${
              isLocationsToggled ? ' toggled' : ''
            }`}
            onClick={() => toggleLocations(!isLocationsToggled)}
          >
            {activeLocationIcon && (
              <span
                className="icon"
                style={{
                  backgroundImage: `url('${activeLocationIcon}')`,
                }}
              >
                {activeOperatingLocation}
              </span>
            )}

            <span className="name">{activeOperatingLocation}</span>
            <ArrowDownFullIcon className="arrow" />
          </button>

          {isLocationsToggled && (
            <div
              className={`operating-locations-field__menu${
                isLocationsToggled ? ' toggled' : ''
              }`}
            >
              {operatingOptions}
            </div>
          )}
        </div>
      </ClickAwayListener>
    )
  )
}

OperatingLocationsField.propTypes = {
  isSearchOpen: PropTypes.bool,
}

export default OperatingLocationsField
