import React from 'react'
import PropTypes from 'prop-types'

function CancelIcon({ colour }, props) {
  return (
    <svg
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M20 2C10.0748 2 2 10.0748 2 20C2 29.9252 10.0748 38 20 38C29.9252 38 38 29.9252 38 20C38 10.0748 29.9252 2 20 2ZM20 35.8906C11.2379 35.8906 4.10938 28.7621 4.10938 20C4.10938 11.2379 11.2379 4.10938 20 4.10938C28.7621 4.10938 35.8906 11.2379 35.8906 20C35.8906 28.7621 28.7621 35.8906 20 35.8906Z"
        fill={colour}
      />
      <path
        d="M27.935 12.065C26.6429 10.7728 24.5404 10.7728 23.2483 12.0649L20 15.3132L16.7517 12.0649C15.4596 10.7728 13.3571 10.7728 12.0649 12.065C10.7727 13.3571 10.7727 15.4596 12.0649 16.7517L15.3132 20L12.0649 23.2482C10.7728 24.5404 10.7728 26.6429 12.065 27.9351C13.3572 29.2272 15.4596 29.2273 16.7518 27.9351L20 24.6868L23.2482 27.9351C24.5403 29.2272 26.6428 29.2272 27.9349 27.9351C29.2271 26.6429 29.2272 24.5404 27.935 23.2482L24.6868 20L27.935 16.7517C29.2272 15.4596 29.2272 13.3571 27.935 12.065ZM26.4435 15.2602L22.4494 19.2542C22.0376 19.6661 22.0376 20.3338 22.4494 20.7457L26.4435 24.7398C26.9132 25.2095 26.9132 25.9738 26.4434 26.4434C25.9737 26.9131 25.2094 26.9132 24.7397 26.4435L20.7457 22.4495C20.3338 22.0376 19.6661 22.0376 19.2542 22.4495L15.2601 26.4435C14.7904 26.9132 14.0262 26.9132 13.5565 26.4434C13.0868 25.9738 13.0867 25.2095 13.5564 24.7398L17.5504 20.7457C17.9623 20.3338 17.9623 19.6661 17.5504 19.2542L13.5564 15.2602C13.0867 14.7905 13.0867 14.0262 13.5565 13.5565C14.0262 13.0868 14.7904 13.0867 15.2601 13.5564L19.2542 17.5505C19.6661 17.9624 20.3338 17.9624 20.7457 17.5505L24.7397 13.5564C25.2094 13.0867 25.9737 13.0868 26.4434 13.5565C26.9132 14.0263 26.9132 14.7905 26.4435 15.2602Z"
        fill={colour}
      />
    </svg>
  )
}

CancelIcon.defaultProps = {
  colour: '#000000',
}

CancelIcon.propTypes = {
  colour: PropTypes.string,
}

export default CancelIcon
