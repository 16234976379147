import React, { useEffect, useMemo, useRef } from 'react'
import PropTypes from 'prop-types'
import { cloneDeep, get, isEmpty, set } from 'lodash'
import { useTable } from 'react-table'
import TipTapEditor from '../../../editor-flow/base-fields/TipTap/TipTapEditor'
import useEntryHighlight from '../../../../hooks/useEntryHighlight'
import openAccordions from '../../../../utilities/find-replace/openAccordions'

function TableField({ field }) {
  const ref = useRef()
  const highlight = useEntryHighlight()
  const mutableField = cloneDeep(field)
  if (highlight?.type === 'link') {
    set(mutableField, [...highlight.path, 'class'], 'find-replace__highlight')
  }

  const block = mutableField?.blocks?.[0] || {}
  const table = block?.table || {}
  const headers = table?.header
  const body = table?.body

  const columns = useMemo(
    () =>
      headers?.map((header, i) => ({
        Header: header.content,
        accessor: `col-${i}`,
      })),
    [],
  )
  const data = useMemo(
    () =>
      body?.map(row =>
        row.reduce((acc, cur, i) => {
          acc[`col-${i}`] = cur.content
          return acc
        }, {}),
      ),
    [],
  )

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns,
      data,
    })

  function handleOnCreated(type, rowIndex, cellIndex) {
    if (highlight && ref?.current?.innerText) {
      let cell
      let row
      if (
        type === 'cell' &&
        highlight.rowIndex === rowIndex &&
        highlight.cellIndex === cellIndex
      ) {
        row = [...ref.current.querySelectorAll('tbody tr')]?.[rowIndex]
      } else if (type === 'header' && highlight.headerIndex === cellIndex) {
        row = ref.current.querySelector('thead tr')
      }
      if (row) {
        cell = [...row.querySelectorAll('td')]?.[cellIndex]
      }

      if (cell) {
        let i = 0
        if (highlight.type === 'text') {
          cell.innerHTML = cell.innerHTML.replaceAll(highlight.find, find => {
            if (i++ === highlight.highlightIndex) {
              return `<span class="find-replace__highlight">${find}</span>`
            }
            return find
          })

          const highlightedText = [
            ...cell.querySelectorAll('.find-replace__highlight'),
          ]?.[0]
          if (highlightedText) {
            const accordion = highlightedText.closest('.tiptap-accordion')
            openAccordions({ accordion })
          }
        } else if (highlight?.type === 'link') {
          const highlightedLink = [
            ...cell.querySelectorAll('.find-replace__highlight'),
          ]?.[0]
          if (highlightedLink) {
            const accordion = highlightedLink.closest('.tiptap-accordion')
            openAccordions({ accordion })
          }
        }
      }
    }
  }

  return !isEmpty(columns) && !isEmpty(data) ? (
    <div className="table table-field" ref={ref}>
      <div className="table__container">
        <table {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup, i) => (
              <tr
                key={`consumer-table-thead-tr-${i}`}
                {...headerGroup.getHeaderGroupProps()}
              >
                {headerGroup?.headers?.map((column, i) => {
                  return (
                    <th
                      key={`consumer-table-thead-th-${i}`}
                      {...column.getHeaderProps()}
                      style={{ verticalAlign: 'middle' }}
                    >
                      <TipTapEditor
                        data={column.render('Header')}
                        readOnly
                        onCreated={() => handleOnCreated('header', 0, i)}
                      />
                    </th>
                  )
                })}
              </tr>
            ))}
          </thead>

          <tbody {...getTableBodyProps()}>
            {rows?.map((row, i) => {
              prepareRow(row)

              return (
                <tr key={`consumer-table-tbody-tr-${i}`} {...row.getRowProps()}>
                  {row.cells.map((cell, j) => {
                    return (
                      <td
                        className={cell.column.id}
                        key={`consumer-table-tbody-td-${j}`}
                        {...cell.getCellProps()}
                      >
                        <TipTapEditor
                          data={cell.value}
                          readOnly
                          onCreated={() => handleOnCreated('cell', i, j)}
                        />
                      </td>
                    )
                  })}
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    </div>
  ) : null
}

TableField.propTypes = {
  field: PropTypes.object,
}

export default TableField
