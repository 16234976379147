import AcceptPaymentIcon from '../components/global-components/icon-library/AcceptPaymentIcon'
import AccountingIcon from '../components/global-components/icon-library/AccountingIcon'
import AddIcon from '../components/global-components/icon-library/AddIcon'
import AddUserIcon from '../components/global-components/icon-library/AddUserIcon'
import AdviceIcon from '../components/global-components/icon-library/AdviceIcon'
import AgreementIcon from '../components/global-components/icon-library/AgreementIcon'
import AlertIcon from '../components/global-components/icon-library/AlertIcon'
import ApartmentCondoIcon from '../components/global-components/icon-library/ApartmentCondoIcon'
import AutoFireIcon from '../components/global-components/icon-library/AutoFireIcon'
import AutoInsuranceIcon from '../components/global-components/icon-library/AutoInsuranceIcon'
import BackIcon from '../components/global-components/icon-library/BackIcon'
import BasicHouseIcon from '../components/global-components/icon-library/BasicHouseIcon'
import BasicUserIcon from '../components/global-components/icon-library/BasicUserIcon'
import BoatIcon from '../components/global-components/icon-library/BoatIcon'
import BookmarkIcon from '../components/global-components/icon-library/BookmarkIcon'
import BrainIcon from '../components/global-components/icon-library/BrainIcon'
import BusinessInsuranceIcon from '../components/global-components/icon-library/BusinessInsuranceIcon'
import CalendarIcon from '../components/global-components/icon-library/CalendarIcon'
import CancelIcon from '../components/global-components/icon-library/CancelIcon'
import CandidatesIcon from '../components/global-components/icon-library/CandidatesIcon'
import CarAccidentIcon from '../components/global-components/icon-library/CarAccidentIcon'
import CarInsuranceIcon from '../components/global-components/icon-library/CarInsuranceIcon'
import CautionIcon from '../components/global-components/icon-library/CautionIcon'
import CertificateIcon from '../components/global-components/icon-library/CertificateIcon'
import ChecklistIcon from '../components/global-components/icon-library/ChecklistIcon'
import ChequeIcon from '../components/global-components/icon-library/ChequeIcon'
import ClaimIcon from '../components/global-components/icon-library/ClaimIcon'
import ClassicCarIcon from '../components/global-components/icon-library/ClassicCarIcon'
import ClientIcon from '../components/global-components/icon-library/ClientIcon'
import ClockIcon from '../components/global-components/icon-library/ClockIcon'
import CommercialBuildingIcon from '../components/global-components/icon-library/CommercialBuildingIcon'
import ComputerIcon from '../components/global-components/icon-library/ComputerIcon'
import ComputerListIcon from '../components/global-components/icon-library/ComputerListIcon'
import ComputerScreenIcon from '../components/global-components/icon-library/ComputerScreenIcon'
import ComputerSettingsIcon from '../components/global-components/icon-library/ComputerSettingsIcon'
import ConfirmCustomerIcon from '../components/global-components/icon-library/ConfirmCustomerIcon'
import ConstructionIcon from '../components/global-components/icon-library/ConstructionIcon'
import ContactIcon from '../components/global-components/icon-library/ContactIcon'
import ContactMethodIcon from '../components/global-components/icon-library/ContactMethodIcon'
import CourierVanIcon from '../components/global-components/icon-library/CourierVanIcon'
import CreditCardIcon from '../components/global-components/icon-library/CreditCardIcon'
import CrimeIcon from '../components/global-components/icon-library/CrimeIcon'
import DeadlineIcon from '../components/global-components/icon-library/DeadlineIcon'
import DeleteIcon from '../components/global-components/icon-library/DeleteIcon'
import DeliveryTruckIcon from '../components/global-components/icon-library/DeliveryTruckIcon'
import DiscountIcon from '../components/global-components/icon-library/DiscountIcon'
import DislikeIcon from '../components/global-components/icon-library/DislikeIcon'
import DocumentIcon from '../components/global-components/icon-library/DocumentIcon'
import DownloadIcon from '../components/global-components/icon-library/DownloadIcon'
import EarthquakeHouseIcon from '../components/global-components/icon-library/EarthquakeHouseIcon'
import EmailIcon from '../components/global-components/icon-library/EmailIcon'
import EnvelopeIcon from '../components/global-components/icon-library/EnvelopeIcon'
import EventIcon from '../components/global-components/icon-library/EventIcon'
import ExecutiveManagerIcon from '../components/global-components/icon-library/ExecutiveManagerIcon'
import ExportIcon from '../components/global-components/icon-library/ExportIcon'
import FamilyPolicyIcon from '../components/global-components/icon-library/FamilyPolicyIcon'
import FarmInsuranceIcon from '../components/global-components/icon-library/FarmInsuranceIcon'
import FavouriteIcon from '../components/global-components/icon-library/FavouriteIcon'
import FaxIcon from '../components/global-components/icon-library/FaxIcon'
import FemaleEmployeeIcon from '../components/global-components/icon-library/FemaleEmployeeIcon'
import FileCabinetIcon from '../components/global-components/icon-library/FileCabinetIcon'
import FilterIcon from '../components/global-components/icon-library/FilterIcon'
import FinancialIcon from '../components/global-components/icon-library/FinancialIcon'
import FireDamageIcon from '../components/global-components/icon-library/FireDamageIcon'
import FireExtinguisherIcon from '../components/global-components/icon-library/FireExtinguisherIcon'
import FireHydrantIcon from '../components/global-components/icon-library/FireHydrantIcon'
import FirePreventionIcon from '../components/global-components/icon-library/FirePreventionIcon'
import FirstAidIcon from '../components/global-components/icon-library/FirstAidIcon'
import FloodedHouseIcon from '../components/global-components/icon-library/FloodedHouseIcon'
import FloodWaveIcon from '../components/global-components/icon-library/FloodWaveIcon'
import FoldersIcon from '../components/global-components/icon-library/FoldersIcon'
import FreezingColdIcon from '../components/global-components/icon-library/FreezingColdIcon'
import GearIcon from '../components/global-components/icon-library/GearIcon'
import GearsIcon from '../components/global-components/icon-library/GearsIcon'
import GrowthIcon from '../components/global-components/icon-library/GrowthIcon'
import HandshakeIcon from '../components/global-components/icon-library/HandshakeIcon'
import HeadsetIcon from '../components/global-components/icon-library/HeadsetIcon'
import HealthInsuranceIcon from '../components/global-components/icon-library/HealthInsuranceIcon'
import HeartIcon from '../components/global-components/icon-library/HeartIcon'
import HeavyMachineryIcon from '../components/global-components/icon-library/HeavyMachineryIcon'
import HiredIcon from '../components/global-components/icon-library/HiredIcon'
import HomeIcon from '../components/global-components/icon-library/HomeIcon'
import HotTemperatureIcon from '../components/global-components/icon-library/HotTemperatureIcon'
import HourglassIcon from '../components/global-components/icon-library/HourglassIcon'
import HouseIcon from '../components/global-components/icon-library/HouseIcon'
import IdCardIcon from '../components/global-components/icon-library/IdCardIcon'
import ImportIcon from '../components/global-components/icon-library/ImportIcon'
import InfoIcon from '../components/global-components/icon-library/InfoIcon'
import InjuryIcon from '../components/global-components/icon-library/InjuryIcon'
import InsurancePolicyIcon from '../components/global-components/icon-library/InsurancePolicyIcon'
import InvestmentInsuranceIcon from '../components/global-components/icon-library/InvestmentInsuranceIcon'
import JewelIcon from '../components/global-components/icon-library/JewelIcon'
import LiabilitySlipIcon from '../components/global-components/icon-library/LiabilitySlipIcon'
import LightBulbIcon from '../components/global-components/icon-library/LightBulbIcon'
import LikeIcon from '../components/global-components/icon-library/LikeIcon'
import LinkIcon from '../components/global-components/icon-library/LinkIcon'
import LoanIcon from '../components/global-components/icon-library/LoanIcon'
import LocationIcon from '../components/global-components/icon-library/LocationIcon'
import MaleEmployeeIcon from '../components/global-components/icon-library/MaleEmployeeIcon'
import ManualSettingsIcon from '../components/global-components/icon-library/ManualSettingsIcon'
import MeetingIcon from '../components/global-components/icon-library/MeetingIcon'
import MegaphoneIcon from '../components/global-components/icon-library/MegaphoneIcon'
import MicrophoneIcon from '../components/global-components/icon-library/MicrophoneIcon'
import MinusIcon from '../components/global-components/icon-library/MinusIcon'
import ModernHouseIcon from '../components/global-components/icon-library/ModernHouseIcon'
import ModernHouseTallIcon from '../components/global-components/icon-library/ModernHouseTallIcon'
import MoneyBagIcon from '../components/global-components/icon-library/MoneyBagIcon'
import MoneyEnvelopeIcon from '../components/global-components/icon-library/MoneyEnvelopeIcon'
import MuteIcon from '../components/global-components/icon-library/MuteIcon'
import NaturalDisasterIcon from '../components/global-components/icon-library/NaturalDisasterIcon'
import OfficeBuildingIcon from '../components/global-components/icon-library/OfficeBuildingIcon'
import OfficeChairIcon from '../components/global-components/icon-library/OfficeChairIcon'
import OfficeEmployeeIcon from '../components/global-components/icon-library/OfficeEmployeeIcon'
import OfficeHoursIcon from '../components/global-components/icon-library/OfficeHoursIcon'
import OfficeSpaceIcon from '../components/global-components/icon-library/OfficeSpaceIcon'
import OnlineShoppingIcon from '../components/global-components/icon-library/OnlineShoppingIcon'
import OrgChartIcon from '../components/global-components/icon-library/OrgChartIcon'
import PackageIcon from '../components/global-components/icon-library/PackageIcon'
import PaperClipIcon from '../components/global-components/icon-library/PaperClipIcon'
import PaperIcon from '../components/global-components/icon-library/PaperIcon'
import PaperPlaneIcon from '../components/global-components/icon-library/PaperPlaneIcon'
import PaperShredderIcon from '../components/global-components/icon-library/PaperShredderIcon'
import PasswordIcon from '../components/global-components/icon-library/PasswordIcon'
import PaymentIcon from '../components/global-components/icon-library/PaymentIcon'
import PenIcon from '../components/global-components/icon-library/PenIcon'
import PetInsuranceIcon from '../components/global-components/icon-library/PetInsuranceIcon'
import PiggyBankIcon from '../components/global-components/icon-library/PiggyBankIcon'
import PinkCardIcon from '../components/global-components/icon-library/PinkCardIcon'
import PlanCoverageIcon from '../components/global-components/icon-library/PlanCoverageIcon'
import PlantIcon from '../components/global-components/icon-library/PlantIcon'
import PolicyAgreementIcon from '../components/global-components/icon-library/PolicyAgreementIcon'
import PollutionIcon from '../components/global-components/icon-library/PollutionIcon'
import PresentationIcon from '../components/global-components/icon-library/PresentationIcon'
import PrinterIcon from '../components/global-components/icon-library/PrinterIcon'
import ProblemSolvingIcon from '../components/global-components/icon-library/ProblemSolvingIcon'
import ProtectionIcon from '../components/global-components/icon-library/ProtectionIcon'
import PuzzlePieceIcon from '../components/global-components/icon-library/PuzzlePieceIcon'
import QuestionAvatarIcon from '../components/global-components/icon-library/QuestionAvatarIcon'
import ReceptionistIcon from '../components/global-components/icon-library/ReceptionistIcon'
import RefundIcon from '../components/global-components/icon-library/RefundIcon'
import RenewIcon from '../components/global-components/icon-library/RenewIcon'
import ReportIcon from '../components/global-components/icon-library/ReportIcon'
import RibbonIcon from '../components/global-components/icon-library/RibbonIcon'
import RiskManagementIcon from '../components/global-components/icon-library/RiskManagementIcon'
import RoadMapIcon from '../components/global-components/icon-library/RoadMapIcon'
import SafeBoxIcon from '../components/global-components/icon-library/SafeBoxIcon'
import SailboatIcon from '../components/global-components/icon-library/SailboatIcon'
import SavingsIcon from '../components/global-components/icon-library/SavingsIcon'
import ScalesIcon from '../components/global-components/icon-library/ScalesIcon'
import SearchFolderIcon from '../components/global-components/icon-library/SearchFolderIcon'
import SearchingOptionIcon from '../components/global-components/icon-library/SearchingOptionIcon'
import SecurePasswordIcon from '../components/global-components/icon-library/SecurePasswordIcon'
import ShopIcon from '../components/global-components/icon-library/ShopIcon'
import ShoppingBasketIcon from '../components/global-components/icon-library/ShoppingBasketIcon'
import ShoppingCartIcon from '../components/global-components/icon-library/ShoppingCartIcon'
import SignatureIcon from '../components/global-components/icon-library/SignatureIcon'
import SlideShowIcon from '../components/global-components/icon-library/SlideShowIcon'
import SnowCloudIcon from '../components/global-components/icon-library/SnowCloudIcon'
import SpeechBubbleIcon from '../components/global-components/icon-library/SpeechBubbleIcon'
import StampIcon from '../components/global-components/icon-library/StampIcon'
import StarIcon from '../components/global-components/icon-library/StarIcon'
import StationeryIcon from '../components/global-components/icon-library/StationeryIcon'
import StickyNotesIcon from '../components/global-components/icon-library/StickyNotesIcon'
import StopWatchIcon from '../components/global-components/icon-library/StopWatchIcon'
import StormCloudIcon from '../components/global-components/icon-library/StormCloudIcon'
import TelephoneIcon from '../components/global-components/icon-library/TelephoneIcon'
import ToggleOffIcon from '../components/global-components/icon-library/ToggleOffIcon'
import TrashIcon from '../components/global-components/icon-library/TrashIcon'
import UmbrellaIcon from '../components/global-components/icon-library/UmbrellaIcon'
import VirusIcon from '../components/global-components/icon-library/VirusIcon'
import VolumeOnIcon from '../components/global-components/icon-library/VolumeOnIcon'
import WalletIcon from '../components/global-components/icon-library/WalletIcon'
import WarningIcon from '../components/global-components/icon-library/WarningIcon'
import WifiIcon from '../components/global-components/icon-library/WifiIcon'
import WishlistIcon from '../components/global-components/icon-library/WishlistIcon'
import WorkingEmployeeIcon from '../components/global-components/icon-library/WorkingEmployeeIcon'
import WorkingIcon from '../components/global-components/icon-library/WorkingIcon'
import WorksStationIcon from '../components/global-components/icon-library/WorksStationIcon'
import LightningIcon from '../components/global-components/icon-library/LightningIcon'
import MoneyStackIcon from '../components/global-components/icon-library/MoneyStackIcon'
import MotorcycleIcon from '../components/global-components/icon-library/MotorcycleIcon'
import OnlineBankingIcon from '../components/global-components/icon-library/OnlineBankingIcon'
import PaymentCardIcon from '../components/global-components/icon-library/PaymentCardIcon'
import PriorityIcon from '../components/global-components/icon-library/PriorityIcon'
import QuestionIcon from '../components/global-components/icon-library/QuestionIcon'
import RenewalIcon from '../components/global-components/icon-library/RenewalIcon'
import RepairFixIcon from '../components/global-components/icon-library/RepairFixIcon'
import ScheduleIcon from '../components/global-components/icon-library/ScheduleIcon'
import SchoolIcon from '../components/global-components/icon-library/SchoolIcon'
import SearchMagnifyingGlassIcon from '../components/global-components/icon-library/SearchMagnifyingGlassIcon'
import SecuritySystemAlarmIcon from '../components/global-components/icon-library/SecuritySystemAlarmIcon'
import ShareIcon from '../components/global-components/icon-library/ShareIcon'
import SnowflakeIcon from '../components/global-components/icon-library/SnowflakeIcon'
import TeamIcon from '../components/global-components/icon-library/TeamIcon'
import VoicemailIcon from '../components/global-components/icon-library/VoicemailIcon'
import WindIcon from '../components/global-components/icon-library/WindIcon'
const supportedIcons = [
  {
    id: 'accept-payment-icon',
    component: AcceptPaymentIcon,
    label: 'Accept Payment',
    synonyms: ['Bill', 'Charge', 'Money'],
  },
  {
    id: 'accounting-icon',
    component: AccountingIcon,
    label: 'Accounting',
    synonyms: ['Money', 'Bookkeeping', 'Numbers', 'Calculator'],
  },
  {
    id: 'add-icon',
    component: AddIcon,
    label: 'Add',
    synonyms: ['New', 'Plus'],
  },
  {
    id: 'add-user-icon',
    component: AddUserIcon,
    label: 'Add User',
    synonyms: ['New User'],
  },
  {
    id: 'advice-icon',
    component: AdviceIcon,
    label: 'Advice',
    synonyms: ['Teach', 'Talk', 'Learn', 'Lesson'],
  },
  {
    id: 'agreement-icon',
    component: AgreementIcon,
    label: 'Agreement',
    synonyms: ['Thumbs Up', 'Paperwork', 'Sold'],
  },
  {
    id: 'alert-icon',
    component: AlertIcon,
    label: 'Alert',
    synonyms: ['Bell', 'Urgent', 'Notification', 'Alarm', 'Action'],
  },
  {
    id: 'apartment-condo-icon',
    component: ApartmentCondoIcon,
    label: 'Apartment/Condo',
    synonyms: ['Building', 'Home', 'Residence', 'Highrise'],
  },
  {
    id: 'auto-fire-icon',
    component: AutoFireIcon,
    label: 'Auto Fire',
    synonyms: ['Accident', 'Crash', 'Explosion', 'Claim', 'Car'],
  },
  {
    id: 'auto-insurance-icon',
    component: AutoInsuranceIcon,
    label: 'Auto Insurance',
    synonyms: ['Coverage', 'Car'],
  },
  {
    id: 'back-icon',
    component: BackIcon,
    label: 'Back',
    synonyms: ['Arrow', 'Return'],
  },
  {
    id: 'basic-house-icon',
    component: BasicHouseIcon,
    label: 'Basic House',
    synonyms: ['Home', 'Building', 'Residence'],
  },
  {
    id: 'basic-user-icon',
    component: BasicUserIcon,
    label: 'Basic User',
    synonyms: ['Person', 'Employee'],
  },
  { id: 'boat-icon', component: BoatIcon, label: 'Boat', synonyms: [] },
  {
    id: 'bookmark-icon',
    component: BookmarkIcon,
    label: 'Bookmark',
    synonyms: ['Save', 'Flag', 'Favourite', 'Favorite'],
  },
  {
    id: 'brain-icon',
    component: BrainIcon,
    label: 'Knowledge',
    synonyms: ['Brain', 'Teach', 'Lesson', 'Student', 'Think', 'Facts'],
  },
  {
    id: 'business-insurance-icon',
    component: BusinessInsuranceIcon,
    label: 'Business Insurance',
    synonyms: ['Commercial'],
  },
  {
    id: 'calendar-icon',
    component: CalendarIcon,
    label: 'Calendar',
    synonyms: ['Date', 'Schedule', 'Time'],
  },
  {
    id: 'cancel-icon',
    component: CancelIcon,
    label: 'Cancel',
    synonyms: ['Deny', 'Stop', 'Remove', 'End'],
  },
  {
    id: 'candidates-icon',
    component: CandidatesIcon,
    label: 'Employees',
    synonyms: ['People', 'Team', 'Coworkers', 'Workers', 'Group'],
  },
  {
    id: 'car-accident-icon',
    component: CarAccidentIcon,
    label: 'Car Accident',
    synonyms: ['Crash', 'Claim', 'Auto'],
  },
  {
    id: 'car-insurance-icon',
    component: CarInsuranceIcon,
    label: 'Car Insurance',
    synonyms: ['Auto'],
  },
  {
    id: 'caution-icon',
    component: CautionIcon,
    label: 'Caution',
    synonyms: ['Alert', 'Notification', 'Urgent'],
  },
  {
    id: 'certificate-icon',
    component: CertificateIcon,
    label: 'Certificate',
    synonyms: ['Paperwork', 'Confirmation'],
  },
  {
    id: 'checklist-icon',
    component: ChecklistIcon,
    label: 'Checklist',
    synonyms: ['Clipboard'],
  },
  {
    id: 'cheque-icon',
    component: ChequeIcon,
    label: 'Cheque',
    synonyms: ['Money', 'Pay', 'Bill', 'Charge'],
  },
  {
    id: 'claim-icon',
    component: ClaimIcon,
    label: 'Claim',
    synonyms: ['Paperwork', 'Policy', 'Sign', 'Signature'],
  },
  {
    id: 'classic-car-icon',
    component: ClassicCarIcon,
    label: 'Classic Car',
    synonyms: ['Antique', 'Vehicle', 'Auto'],
  },
  {
    id: 'client-icon',
    component: ClientIcon,
    label: 'Clients',
    synonyms: ['Customer', 'Insured'],
  },
  {
    id: 'clock-icon',
    component: ClockIcon,
    label: 'Clock',
    synonyms: ['Time', 'Schedule', 'Calendar'],
  },
  {
    id: 'commercial-building-icon',
    component: CommercialBuildingIcon,
    label: 'Commercial Building',
    synonyms: ['Business'],
  },
  {
    id: 'computer-icon',
    component: ComputerIcon,
    label: 'Computer',
    synonyms: ['Desktop', 'Laptop', 'Screen'],
  },
  {
    id: 'computer-list-icon',
    component: ComputerListIcon,
    label: 'Computer List',
    synonyms: ['Desktop', 'Laptop', 'Screen'],
  },
  {
    id: 'computer-screen-icon',
    component: ComputerScreenIcon,
    label: 'Computer Screen',
    synonyms: ['Desktop', 'Laptop'],
  },
  {
    id: 'computer-settings-icon',
    component: ComputerSettingsIcon,
    label: 'Computer Settings',
    synonyms: ['Desktop', 'Laptop', 'Screen', 'Change', 'Edit'],
  },
  {
    id: 'construction-icon',
    component: ConstructionIcon,
    label: 'Construction',
    synonyms: ['Build', 'Machinery'],
  },
  {
    id: 'contact-icon',
    component: ContactIcon,
    label: 'Contact',
    synonyms: ['Call', 'Email', 'Text', 'Mail'],
  },
  {
    id: 'contact-method-icon',
    component: ContactMethodIcon,
    label: 'Contact Method',
    synonyms: ['Call', 'Email', 'Text', 'Mail'],
  },
  {
    id: 'confirm-customer-icon',
    component: ConfirmCustomerIcon,
    label: 'Confirm Customer',
    synonyms: ['Client'],
  },
  {
    id: 'courier-van-icon',
    component: CourierVanIcon,
    label: 'Courier Van',
    synonyms: ['Vehicle', 'Auto'],
  },
  {
    id: 'credit-card-icon',
    component: CreditCardIcon,
    label: 'Credit Card',
    synonyms: ['Pay', 'Money', 'Bill', 'Charge'],
  },
  {
    id: 'crime-icon',
    component: CrimeIcon,
    label: 'Crime',
    synonyms: ['Burglar', 'Theft', 'Steal'],
  },
  {
    id: 'deadline-icon',
    component: DeadlineIcon,
    label: 'Deadline',
    synonyms: ['Clock', 'Time', 'Schedule', 'Due'],
  },
  {
    id: 'delete-icon',
    component: DeleteIcon,
    label: 'Delete',
    synonyms: ['Remove', 'Stop', 'End', 'Cancel'],
  },
  {
    id: 'delivery-truck-icon',
    component: DeliveryTruckIcon,
    label: 'Delivery Truck',
    synonyms: ['Vehicle', 'Courier', 'Shipment', 'Van'],
  },
  {
    id: 'discount-icon',
    component: DiscountIcon,
    label: 'Discount',
    synonyms: ['Coupon', 'Saving'],
  },
  {
    id: 'dislike-icon',
    component: DislikeIcon,
    label: 'Dislike',
    synonyms: ['Thumbs Down', 'No', 'Bad'],
  },
  {
    id: 'document-icon',
    component: DocumentIcon,
    label: 'Document',
    synonyms: ['Paperwork', 'Folder'],
  },
  {
    id: 'download-icon',
    component: DownloadIcon,
    label: 'Download',
    synonyms: ['Save'],
  },
  {
    id: 'earthquake-house-icon',
    component: EarthquakeHouseIcon,
    label: 'Earthquake (Property)',
    synonyms: ['Disaster', 'Natural disaster', 'Destruction'],
  },
  {
    id: 'email-icon',
    component: EmailIcon,
    label: 'Email',
    synonyms: ['Gmail', 'Outlook', 'Contact'],
  },
  {
    id: 'event-icon',
    component: EventIcon,
    label: 'Event',
    synonyms: ['Sign'],
  },
  {
    id: 'female-employee-icon',
    component: FemaleEmployeeIcon,
    label: 'Employee',
    synonyms: ['Worker', 'Coworker'],
  },
  {
    id: 'male-employee-icon',
    component: MaleEmployeeIcon,
    label: 'Employee',
    synonyms: ['Worker', 'Coworker'],
  },
  {
    id: 'envelope-icon',
    component: EnvelopeIcon,
    label: 'Envelope',
    synonyms: ['Mail', 'Paperwork', 'Folder', 'Send'],
  },
  {
    id: 'export-icon',
    component: ExportIcon,
    label: 'Export',
    synonyms: ['Upload', 'Send'],
  },
  {
    id: 'family-policy-icon',
    component: FamilyPolicyIcon,
    label: 'Family',
    synonyms: ['People', 'Group'],
  },
  {
    id: 'farm-insurance-icon',
    component: FarmInsuranceIcon,
    label: 'Farm Insurance',
    synonyms: ['Barn'],
  },
  {
    id: 'favourite-icon',
    component: FavouriteIcon,
    label: 'Favourite',
    synonyms: ['Star', 'Bookmark', 'Save'],
  },
  { id: 'fax-icon', component: FaxIcon, label: 'Fax', synonyms: [] },
  {
    id: 'file-cabinet-icon',
    component: FileCabinetIcon,
    label: 'File Cabinet',
    synonyms: ['Store', 'Save'],
  },
  { id: 'filter-icon', component: FilterIcon, label: 'Filter', synonyms: [] },
  {
    id: 'financial-icon',
    component: FinancialIcon,
    label: 'Finances',
    synonyms: ['Money', 'Acounting', 'Numbers', 'Invoice', 'Bill', 'Charge'],
  },
  {
    id: 'fire-extinguisher-icon',
    component: FireExtinguisherIcon,
    label: 'Fire Extinguisher',
    synonyms: [],
  },
  {
    id: 'fire-hydrant-icon',
    component: FireHydrantIcon,
    label: 'Fire Hydrant',
    synonyms: [],
  },
  {
    id: 'fire-prevention-icon',
    component: FirePreventionIcon,
    label: 'Fire Prevention',
    synonyms: ['Flames'],
  },
  {
    id: 'first-aid-icon',
    component: FirstAidIcon,
    label: 'First Aid',
    synonyms: ['Medical'],
  },
  {
    id: 'flooded-house-icon',
    component: FloodedHouseIcon,
    label: 'Flooded House',
    synonyms: ['Rain', 'Water', 'Storm', 'Natural disaster'],
  },
  {
    id: 'folders-icon',
    component: FoldersIcon,
    label: 'Folders',
    synonyms: ['File', 'Organize', 'Paperwork', 'Save'],
  },
  {
    id: 'freezing-cold-icon',
    component: FreezingColdIcon,
    label: 'Freezing Cold',
    synonyms: ['Temperature', 'Winter', 'Frozen'],
  },
  {
    id: 'gear-icon',
    component: GearIcon,
    label: 'Gear',
    synonyms: ['Settings', 'Change', 'Update'],
  },
  {
    id: 'gears-icon',
    component: GearsIcon,
    label: 'Gears',
    synonyms: ['Settings', 'Change', 'Update'],
  },
  {
    id: 'growth-icon',
    component: GrowthIcon,
    label: 'Growth',
    synonyms: ['Plants', 'Garden'],
  },
  {
    id: 'handshake-icon',
    component: HandshakeIcon,
    label: 'Handshake',
    synonyms: ['Meet', 'Interview'],
  },
  {
    id: 'headset-icon',
    component: HeadsetIcon,
    label: 'Headset',
    synonyms: ['Call', 'Phone', 'Meeting'],
  },
  {
    id: 'health-insurance-icon',
    component: HealthInsuranceIcon,
    label: 'Health Insurance',
    synonyms: ['Medical'],
  },
  {
    id: 'heart-icon',
    component: HeartIcon,
    label: 'Heart (Love)',
    synonyms: ['Love', 'Favourite', 'Like', 'Bookmark', 'Save'],
  },
  {
    id: 'heavy-machinery-icon',
    component: HeavyMachineryIcon,
    label: 'Heavy Machinery',
    synonyms: ['Construction', 'Build'],
  },
  {
    id: 'hired-icon',
    component: HiredIcon,
    label: 'Hired',
    synonyms: ['Interview', 'Success'],
  },
  {
    id: 'home-icon',
    component: HomeIcon,
    label: 'Home',
    synonyms: ['House', 'Residence', 'Property'],
  },
  {
    id: 'hot-temperature-icon',
    component: HotTemperatureIcon,
    label: 'Hot Temperature',
    synonyms: ['Warm', 'Summer'],
  },
  {
    id: 'hourglass-icon',
    component: HourglassIcon,
    label: 'Hourglass',
    synonyms: ['Time', 'Schedule'],
  },
  {
    id: 'flood-wave-icon',
    component: FloodWaveIcon,
    label: 'Flood (Wave)',
    synonyms: ['Wave', 'Water', 'Storm', 'Natural disaster'],
  },
  {
    id: 'fire-damage-icon',
    component: FireDamageIcon,
    label: 'House Fire',
    synonyms: ['Flames'],
  },
  {
    id: 'house-icon',
    component: HouseIcon,
    label: 'House',
    synonyms: ['Home', 'Residence', 'Property'],
  },
  {
    id: 'id-card-icon',
    component: IdCardIcon,
    label: 'ID Card',
    synonyms: ['ID Card', 'Identity', 'Card', 'Licence'],
  },
  {
    id: 'import-icon',
    component: ImportIcon,
    label: 'Import',
    synonyms: ['Save'],
  },
  {
    id: 'info-icon',
    component: InfoIcon,
    label: 'Info',
    synonyms: ['Directions', 'FAQ'],
  },
  {
    id: 'injury-icon',
    component: InjuryIcon,
    label: 'Injury',
    synonyms: ['Insurance', 'First Aid'],
  },
  {
    id: 'insurance-policy-icon',
    component: InsurancePolicyIcon,
    label: 'Insurance Policy',
    synonyms: ['Paperwork', 'Signature'],
  },
  {
    id: 'investment-insurance-icon',
    component: InvestmentInsuranceIcon,
    label: 'Home Insurance',
    synonyms: ['Property', 'House'],
  },
  {
    id: 'jewel-icon',
    component: JewelIcon,
    label: 'Jewel',
    synonyms: ['Diamond', 'Necklace', 'Braclet', 'Earrings'],
  },
  {
    id: 'liability-slip-icon',
    component: LiabilitySlipIcon,
    label: 'Liability Slip',
    synonyms: ['Paperwork', 'Card', 'Pink Card'],
  },
  {
    id: 'light-bulb-icon',
    component: LightBulbIcon,
    label: 'Light Bulb',
    synonyms: ['Idea', 'Thought'],
  },
  {
    id: 'lightning-icon',
    component: LightningIcon,
    label: 'Lightning',
    synonyms: ['Storm', 'Thunder', 'Weather'],
  },
  {
    id: 'like-icon',
    component: LikeIcon,
    label: 'Like',
    synonyms: ['Thumbs up'],
  },
  {
    id: 'link-icon',
    component: LinkIcon,
    label: 'Link',
    synonyms: ['Connect'],
  },
  {
    id: 'loan-icon',
    component: LoanIcon,
    label: 'Loan',
    synonyms: ['Money', 'Pay', 'Bill', 'Charge', 'Borrow'],
  },
  {
    id: 'location-icon',
    component: LocationIcon,
    label: 'Location',
    synonyms: ['Place'],
  },
  {
    id: 'executive-manager-icon',
    component: ExecutiveManagerIcon,
    label: 'Manager',
    synonyms: ['Boss'],
  },
  {
    id: 'manual-settings-icon',
    component: ManualSettingsIcon,
    label: 'Manual Settings',
    synonyms: ['Adjust', 'Change'],
  },
  {
    id: 'meeting-icon',
    component: MeetingIcon,
    label: 'Meeting',
    synonyms: ['Employees', 'Coworkers', 'Discussion', 'Conversation'],
  },
  {
    id: 'megaphone-icon',
    component: MegaphoneIcon,
    label: 'Megaphone (Announcement)',
    synonyms: ['Announcement', 'Speaker'],
  },
  {
    id: 'microphone-icon',
    component: MicrophoneIcon,
    label: 'Microphone',
    synonyms: ['Announcement', 'Speaker'],
  },
  {
    id: 'minus-icon',
    component: MinusIcon,
    label: 'Minus',
    synonyms: ['Deny', 'Stop', 'Cancel', 'Remove', 'Delete'],
  },
  {
    id: 'modern-house-icon',
    component: ModernHouseIcon,
    label: 'Modern House',
    synonyms: ['Home', 'Residence', 'Property'],
  },
  {
    id: 'modern-house-tall-icon',
    component: ModernHouseTallIcon,
    label: 'Modern House (Tall)',
    synonyms: ['Home', 'Residence', 'Property'],
  },
  {
    id: 'money-bag-icon',
    component: MoneyBagIcon,
    label: 'Money Bag',
    synonyms: ['Pay', 'Bill', 'Charge'],
  },
  {
    id: 'money-envelope-icon',
    component: MoneyEnvelopeIcon,
    label: 'Money Envelope',
    synonyms: ['Pay', 'Bill', 'Charge'],
  },
  {
    id: 'money-stack-icon',
    component: MoneyStackIcon,
    label: 'Money Stack',
    synonyms: ['Pay', 'Bill', 'Charge'],
  },
  {
    id: 'motorcycle-icon',
    component: MotorcycleIcon,
    label: 'Motorcycle',
    synonyms: ['Bike', 'Vehicle', 'Dirt Bike'],
  },
  {
    id: 'mute-icon',
    component: MuteIcon,
    label: 'Mute',
    synonyms: ['Quiet', 'Silence', 'Volume'],
  },
  {
    id: 'natural-disaster-icon',
    component: NaturalDisasterIcon,
    label: 'Natural Disaster',
    synonyms: ['Storm', 'Destruction'],
  },
  {
    id: 'office-building-icon',
    component: OfficeBuildingIcon,
    label: 'Office Building',
    synonyms: ['Commercial'],
  },
  {
    id: 'office-chair-icon',
    component: OfficeChairIcon,
    label: 'Office Chair',
    synonyms: ['Desk'],
  },
  {
    id: 'office-employee-icon',
    component: OfficeEmployeeIcon,
    label: 'Office Employee',
    synonyms: ['Worker', 'Coworker'],
  },
  {
    id: 'office-hours-icon',
    component: OfficeHoursIcon,
    label: 'Office Hours',
    synonyms: ['Office Hours', 'Desk', 'Schedule', 'Time'],
  },
  {
    id: 'office-space-icon',
    component: OfficeSpaceIcon,
    label: 'Office Space',
    synonyms: ['Building'],
  },
  {
    id: 'online-banking-icon',
    component: OnlineBankingIcon,
    label: 'Online Banking',
    synonyms: ['Money', 'Deposit'],
  },
  {
    id: 'online-shopping-icon',
    component: OnlineShoppingIcon,
    label: 'Online Shopping',
    synonyms: [],
  },
  {
    id: 'org-chart-icon',
    component: OrgChartIcon,
    label: 'Org Chart',
    synonyms: ['Org Chart', 'Organization'],
  },
  {
    id: 'package-icon',
    component: PackageIcon,
    label: 'Package',
    synonyms: ['Delivery', 'Box', 'Mail', 'Shipment', 'Send'],
  },
  {
    id: 'paper-icon',
    component: PaperIcon,
    label: 'Paper',
    synonyms: ['Documents'],
  },
  {
    id: 'paper-clip-icon',
    component: PaperClipIcon,
    label: 'Paper Clip (Attach)',
    synonyms: ['Attach', 'Connect', 'Link'],
  },
  {
    id: 'paper-plane-icon',
    component: PaperPlaneIcon,
    label: 'Paper Plane',
    synonyms: ['Send'],
  },
  {
    id: 'paper-shredder-icon',
    component: PaperShredderIcon,
    label: 'Paper Shredder',
    synonyms: ['Destroy'],
  },
  {
    id: 'password-icon',
    component: PasswordIcon,
    label: 'Password',
    synonyms: ['Login', 'Security', 'Username'],
  },
  {
    id: 'payment-icon',
    component: PaymentIcon,
    label: 'Payment',
    synonyms: ['Money', 'Bill', 'Invoice', 'Deposit'],
  },
  {
    id: 'payment-card-icon',
    component: PaymentCardIcon,
    label: 'Payment Card',
    synonyms: ['Debit', 'Money', 'Bill', 'Buy'],
  },
  {
    id: 'pen-icon',
    component: PenIcon,
    label: 'Pen',
    synonyms: ['Pencil', 'Write', 'Signature'],
  },
  {
    id: 'pet-insurance-icon',
    component: PetInsuranceIcon,
    label: 'Pet Insurance',
    synonyms: ['Animal'],
  },
  {
    id: 'piggy-bank-icon',
    component: PiggyBankIcon,
    label: 'Piggy Bank',
    synonyms: ['Savings'],
  },
  {
    id: 'pink-card-icon',
    component: PinkCardIcon,
    label: 'Pink Card',
    synonyms: ['Liability Slip'],
  },
  {
    id: 'plan-coverage-icon',
    component: PlanCoverageIcon,
    label: 'Plan Coverage',
    synonyms: ['Policy'],
  },
  {
    id: 'plant-icon',
    component: PlantIcon,
    label: 'Plant',
    synonyms: ['Growth', 'Garden'],
  },
  {
    id: 'policy-agreement-icon',
    component: PolicyAgreementIcon,
    label: 'Policy Agreement',
    synonyms: ['Confirm', 'Bind'],
  },
  {
    id: 'policy-card-icon',
    component: LiabilitySlipIcon,
    label: 'Policy Card',
    synonyms: ['Libaility Slip', 'Pink Card'],
  },
  {
    id: 'pollution-icon',
    component: PollutionIcon,
    label: 'Pollution',
    synonyms: ['Factory', 'Environment'],
  },
  {
    id: 'presentation-icon',
    component: PresentationIcon,
    label: 'Presentation',
    synonyms: ['Slideshow'],
  },
  {
    id: 'printer-icon',
    component: PrinterIcon,
    label: 'Printer',
    synonyms: [],
  },
  {
    id: 'priority-icon',
    component: PriorityIcon,
    label: 'Priority',
    synonyms: ['Important'],
  },
  {
    id: 'problem-solving-icon',
    component: ProblemSolvingIcon,
    label: 'Problem Solving',
    synonyms: ['Light bulb', 'Idea'],
  },
  {
    id: 'protection-icon',
    component: ProtectionIcon,
    label: 'Confirmation',
    synonyms: ['Protection', 'Approved', 'Coverage'],
  },
  {
    id: 'puzzle-piece-icon',
    component: PuzzlePieceIcon,
    label: 'Puzzle Piece',
    synonyms: ['Problem', 'Solve'],
  },
  {
    id: 'question-icon',
    component: QuestionIcon,
    label: 'Question',
    synonyms: ['Ask', 'FAQ'],
  },
  {
    id: 'question-avatar-icon',
    component: QuestionAvatarIcon,
    label: 'Question (User)',
    synonyms: ['Ask', 'FAQ'],
  },
  {
    id: 'receptionist-icon',
    component: ReceptionistIcon,
    label: 'Receptionist (Desk)',
    synonyms: ['Desk', 'Employee', 'Coworker'],
  },
  {
    id: 'refund-icon',
    component: RefundIcon,
    label: 'Refund',
    synonyms: ['Return'],
  },
  {
    id: 'renew-icon',
    component: RenewIcon,
    label: 'Renew',
    synonyms: ['Process', 'Load'],
  },
  {
    id: 'renewal-icon',
    component: RenewalIcon,
    label: 'Renewal',
    synonyms: ['Process', 'Load'],
  },
  {
    id: 'repair-icon',
    component: RepairFixIcon,
    label: 'Repair/Fix',
    synonyms: ['Build', 'Edit'],
  },
  { id: 'report-icon', component: ReportIcon, label: 'Report', synonyms: [] },
  {
    id: 'ribbon-icon',
    component: RibbonIcon,
    label: 'Ribbon',
    synonyms: ['Favourite', 'Bookmark', 'Save'],
  },
  {
    id: 'risk-management-icon',
    component: RiskManagementIcon,
    label: 'Risk Management',
    synonyms: ['Alert', 'Caution'],
  },
  {
    id: 'roadmap-icon',
    component: RoadMapIcon,
    label: 'Roadmap',
    synonyms: ['Plan', 'Organize', 'Assign'],
  },
  {
    id: 'safe-box-icon',
    component: SafeBoxIcon,
    label: 'Safe Box',
    synonyms: ['Secure'],
  },
  {
    id: 'sailboat-icon',
    component: SailboatIcon,
    label: 'Sailboat',
    synonyms: ['Boat'],
  },
  {
    id: 'savings-icon',
    component: SavingsIcon,
    label: 'Savings',
    synonyms: [],
  },
  {
    id: 'scales-icon',
    component: ScalesIcon,
    label: 'Scales',
    synonyms: ['Weigh', 'Decide', 'Decision'],
  },
  {
    id: 'schedule-icon',
    component: ScheduleIcon,
    label: 'Schedule',
    synonyms: ['Plan', 'Calendar', 'Date'],
  },
  {
    id: 'school-icon',
    component: SchoolIcon,
    label: 'School',
    synonyms: ['Learn', 'Education'],
  },
  {
    id: 'search-folder-icon',
    component: SearchFolderIcon,
    label: 'Search Folder',
    synonyms: ['Locate', 'Research', 'Find'],
  },
  {
    id: 'search-magnifying-glass-icon',
    component: SearchMagnifyingGlassIcon,
    label: 'Search (Magnifying Glass)',
    synonyms: ['Locate', 'Research', 'Find'],
  },
  {
    id: 'searching-option-icon',
    component: SearchingOptionIcon,
    label: 'Search Option',
    synonyms: ['Locate', 'Research', 'Find'],
  },
  {
    id: 'secure-password-icon',
    component: SecurePasswordIcon,
    label: 'Secure Password',
    synonyms: ['Security', 'Login'],
  },
  {
    id: 'security-system-alarm-icon',
    component: SecuritySystemAlarmIcon,
    label: 'Security System (Alarm)',
    synonyms: [],
  },
  {
    id: 'share-icon',
    component: ShareIcon,
    label: 'Share',
    synonyms: ['Send', 'Post', 'Arrow'],
  },
  {
    id: 'shop-icon',
    component: ShopIcon,
    label: 'Shop',
    synonyms: ['Store', 'Buy', 'Purchase'],
  },
  {
    id: 'shopping-basket-icon',
    component: ShoppingBasketIcon,
    label: 'Shopping Basket',
    synonyms: ['Store', 'Buy', 'Purchase'],
  },
  {
    id: 'shopping-cart-icon',
    component: ShoppingCartIcon,
    label: 'Shopping Cart',
    synonyms: ['Store', 'Buy', 'Purchase'],
  },
  {
    id: 'signature-icon',
    component: SignatureIcon,
    label: 'Signature',
    synonyms: [],
  },
  {
    id: 'slide-show-icon',
    component: SlideShowIcon,
    label: 'Slideshow',
    synonyms: ['Presentation'],
  },
  {
    id: 'snow-cloud-icon',
    component: SnowCloudIcon,
    label: 'Snow Cloud',
    synonyms: ['Winter', 'Storm', 'Cold', 'Weather'],
  },
  {
    id: 'snowflake-icon',
    component: SnowflakeIcon,
    label: 'Snowflake',
    synonyms: ['Winter', 'Storm', 'Cold', 'Weather'],
  },
  {
    id: 'speech-bubble-icon',
    component: SpeechBubbleIcon,
    label: 'Speech Bubble (Conversation)',
    synonyms: ['Conversation', 'Discusssion', 'Talk'],
  },
  {
    id: 'stamp-icon',
    component: StampIcon,
    label: 'Stamp',
    synonyms: ['Confirm'],
  },
  {
    id: 'star-icon',
    component: StarIcon,
    label: 'Star',
    synonyms: ['Favourite', 'Bookmark', 'Save'],
  },
  {
    id: 'stationery-icon',
    component: StationeryIcon,
    label: 'Stationery (Pens)',
    synonyms: ['Pen', 'Pencil'],
  },
  {
    id: 'sticky-notes-icon',
    component: StickyNotesIcon,
    label: 'Sticky Notes',
    synonyms: ['Note'],
  },
  {
    id: 'stopwatch-icon',
    component: StopWatchIcon,
    label: 'Stopwatch',
    synonyms: ['Time', 'Schedule'],
  },
  {
    id: 'storm-cloud-icon',
    component: StormCloudIcon,
    label: 'Storm Cloud',
    synonyms: ['Thunder', 'Lightning', 'Weather'],
  },
  {
    id: 'team-icon',
    component: TeamIcon,
    label: 'Team',
    synonyms: ['Collab', 'Coworkers', 'Employees'],
  },
  {
    id: 'telephone-icon',
    component: TelephoneIcon,
    label: 'Telephone',
    synonyms: ['Call', 'Phone'],
  },
  {
    id: 'toggle-off-icon',
    component: ToggleOffIcon,
    label: 'Button ',
    synonyms: ['Toggle', 'Switch'],
  },
  {
    id: 'trash-icon',
    component: TrashIcon,
    label: 'Trash',
    synonyms: ['Garbage', 'Discard', 'Remove', 'Delete'],
  },
  {
    id: 'umbrella-icon',
    component: UmbrellaIcon,
    label: 'Umbrella',
    synonyms: ['Rain', 'Flood', 'Water'],
  },
  {
    id: 'virus-icon',
    component: VirusIcon,
    label: 'Virus',
    synonyms: ['Hack', 'Sercurity'],
  },
  {
    id: 'voicemail-icon',
    component: VoicemailIcon,
    label: 'Voicemail',
    synonyms: ['Message', 'Call'],
  },
  {
    id: 'volume-on-icon',
    component: VolumeOnIcon,
    label: 'Volume On',
    synonyms: ['Sound', 'Loud'],
  },
  {
    id: 'wallet-icon',
    component: WalletIcon,
    label: 'Wallet',
    synonyms: ['Money'],
  },
  {
    id: 'warning-icon',
    component: WarningIcon,
    label: 'Warning',
    synonyms: ['Caution', 'Alert'],
  },
  {
    id: 'wifi-icon',
    component: WifiIcon,
    label: 'WiFi (Internet)',
    synonyms: ['Signal'],
  },
  {
    id: 'wind-icon',
    component: WindIcon,
    label: 'Wind',
    synonyms: ['Storm', 'Weather'],
  },
  {
    id: 'wishlist-icon',
    component: WishlistIcon,
    label: 'Wishlist',
    synonyms: ['List', 'Needs'],
  },
  {
    id: 'working-icon',
    component: WorkingIcon,
    label: 'Working',
    synonyms: [],
  },
  {
    id: 'working-employee-icon',
    component: WorkingEmployeeIcon,
    label: 'Working Employee',
    synonyms: ['Person'],
  },
  {
    id: 'workstation-icon',
    component: WorksStationIcon,
    label: 'Work Station',
    synonyms: ['Desk', 'Office'],
  },
]

export default supportedIcons
