import React from 'react'
import PropTypes from 'prop-types'

function IdCardIcon({ colour }, props) {
  return (
    <svg
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M33.9102 19.4042H18.8633C18.572 19.4042 18.3359 19.6403 18.3359 19.9316C18.3359 20.2228 18.572 20.4589 18.8633 20.4589H33.9102C34.2015 20.4589 34.4375 20.2228 34.4375 19.9316C34.4375 19.6403 34.2015 19.4042 33.9102 19.4042Z"
        fill={colour}
      />
      <path
        d="M33.9102 22.2558H18.8633C18.572 22.2558 18.3359 22.4919 18.3359 22.7831C18.3359 23.0744 18.572 23.3105 18.8633 23.3105H33.9102C34.2015 23.3105 34.4375 23.0744 34.4375 22.7831C34.4375 22.4919 34.2015 22.2558 33.9102 22.2558Z"
        fill={colour}
      />
      <path
        d="M34.4375 25.6346C34.4375 25.3434 34.2015 25.1073 33.9102 25.1073H18.8633C18.572 25.1073 18.3359 25.3434 18.3359 25.6346C18.3359 25.9259 18.572 26.162 18.8633 26.162H33.9102C34.2015 26.162 34.4375 25.9259 34.4375 25.6346Z"
        fill={colour}
      />
      <path
        d="M18.8633 27.959C18.572 27.959 18.3359 28.1951 18.3359 28.4863C18.3359 28.7775 18.572 29.0136 18.8633 29.0136H26.3867C26.678 29.0136 26.9141 28.7775 26.9141 28.4863C26.9141 28.1951 26.678 27.959 26.3867 27.959H18.8633Z"
        fill={colour}
      />
      <path
        d="M6.08984 26.4063C5.79854 26.4063 5.5625 26.6424 5.5625 26.9336V28.6753C5.5625 29.4355 6.18104 30.0541 6.94126 30.0541H14.5446C15.3049 30.0541 15.9234 29.4355 15.9234 28.6753V19.7425C15.9234 18.9822 15.3049 18.3636 14.5446 18.3636H6.94126C6.18104 18.3636 5.5625 18.9822 5.5625 19.7425V24.4682C5.5625 24.7595 5.79854 24.9956 6.08984 24.9956C6.38115 24.9956 6.61719 24.7595 6.61719 24.4682V19.7425C6.61719 19.5637 6.76252 19.4183 6.94126 19.4183H14.5446C14.7234 19.4183 14.8687 19.5637 14.8687 19.7425V28.6753C14.8687 28.854 14.7234 28.9994 14.5446 28.9994H6.94126C6.76252 28.9994 6.61719 28.854 6.61719 28.6753V26.9336C6.61719 26.6424 6.38115 26.4063 6.08984 26.4063Z"
        fill={colour}
      />
      <path
        d="M14.3047 14.0873C14.3047 15.1497 15.169 16.0141 16.2315 16.0141H23.7685C24.8309 16.0141 25.6952 15.1497 25.6952 14.0873C25.6952 13.0249 24.8309 12.1605 23.7685 12.1605H23.287V8.89685C23.287 7.08433 21.8124 5.60974 19.9999 5.60974C18.1874 5.60974 16.7128 7.08433 16.7128 8.89685V12.1605H16.2314C15.169 12.1605 14.3047 13.0249 14.3047 14.0873ZM17.7676 8.89685C17.7676 7.66589 18.769 6.66443 20 6.66443C21.231 6.66443 22.2324 7.66589 22.2324 8.89685V12.6761C22.2324 12.6794 22.2323 12.6826 22.2323 12.6857C22.2321 12.7418 22.1862 12.7875 22.1301 12.7875H17.8699C17.8139 12.7875 17.7682 12.7421 17.7677 12.6863C17.7677 12.683 17.7676 12.6796 17.7676 12.6762V8.89685ZM17.8699 13.8422H22.1301C22.5758 13.8422 22.9633 13.5873 23.1563 13.2152H23.7685C24.2494 13.2152 24.6406 13.6065 24.6406 14.0873C24.6406 14.5682 24.2494 14.9594 23.7685 14.9594H16.2315C15.7506 14.9594 15.3594 14.5682 15.3594 14.0873C15.3594 13.6065 15.7506 13.2152 16.2315 13.2152H16.8433C17.0362 13.5869 17.4239 13.8422 17.8699 13.8422Z"
        fill={colour}
      />
      <path
        d="M35.8521 10.1339H25.2314C24.9401 10.1339 24.704 10.37 24.704 10.6613C24.704 10.9525 24.9401 11.1886 25.2314 11.1886H35.8521C36.4549 11.1886 36.9453 11.679 36.9453 12.2817V32.2423C36.9453 32.8451 36.4549 33.3356 35.8521 33.3356H4.14791C3.54512 33.3356 3.05469 32.8451 3.05469 32.2423V12.2817C3.05469 11.679 3.54512 11.1886 4.14791 11.1886H14.7683C15.0596 11.1886 15.2957 10.9525 15.2957 10.6613C15.2957 10.37 15.0596 10.1339 14.7683 10.1339H4.14791C2.96356 10.1339 2 11.0975 2 12.2817V32.2423C2 33.4267 2.96356 34.3902 4.14791 34.3902H35.8521C37.0364 34.3902 38 33.4267 38 32.2423V12.2817C38 11.0975 37.0364 10.1339 35.8521 10.1339Z"
        fill={colour}
      />
    </svg>
  )
}

IdCardIcon.defaultProps = {
  colour: '#000000',
}

IdCardIcon.propTypes = {
  colour: PropTypes.string,
}

export default IdCardIcon
