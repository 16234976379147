import { Field, Form, Formik } from 'formik'
import TextField from '../../../forms/elements/TextField'
import React from 'react'
import * as Yup from 'yup'
import PropTypes from 'prop-types'
import { find } from 'linkifyjs'

const FindReplaceEmailForm = ({ onSubmit }) => {
  const findValidMessage = 'A valid email is required to search.'
  const replaceValidMessage = 'A valid replacement email is required.'
  const validationSchema = Yup.object().shape({
    find: Yup.string()
      .required(findValidMessage)
      .test('email', findValidMessage, emailTest),
    replace: Yup.string()
      .required(replaceValidMessage)
      .test('email', replaceValidMessage, emailTest),
  })

  const initialValues = {
    find: '',
    replace: '',
  }

  function emailTest(value) {
    const result = find(value.trim()).pop()
    return result?.type === 'email' && result?.isLink
  }

  return (
    <div className="find-replace-text-form">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ isValid, setFieldTouched, values, setFieldValue, errors }) => {
          return (
            <>
              <Form className="form">
                <div className="form__field">
                  <Field
                    component={TextField}
                    label="Find Email:"
                    name="find"
                    placeholder=""
                    type="text"
                  />
                </div>

                <div className="form__field">
                  <Field
                    component={TextField}
                    label="Replace Email:"
                    name="replace"
                    placeholder=""
                    type="text"
                  />
                </div>

                <div>
                  <button
                    type="submit"
                    className="btn btn--dark-green form__button-offset"
                    disabled={!isValid}
                  >
                    Search
                  </button>
                </div>
              </Form>
            </>
          )
        }}
      </Formik>
    </div>
  )
}

FindReplaceEmailForm.propTypes = {
  onSubmit: PropTypes.func,
}
export default FindReplaceEmailForm
