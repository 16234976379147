function openAccordions({ accordion }) {
  if (accordion) {
    const header = accordion?.children?.[0]
    if (header) {
      header.classList.add('open')
      const parentAccordion =
        accordion.parentElement.closest('.tiptap-accordion')
      if (parentAccordion) {
        openAccordions({ accordion: parentAccordion })
      }
    }
  }
}

export default openAccordions
