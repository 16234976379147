import React, { useState } from 'react'
import { EDITOR_IMAGE_FIELD } from '../../../../configuration/editor'
import { get } from 'lodash'
import MediaLibrary from '../../../global-components/media-library/MediaLibrary'
import { ReactComponent as AddImageIcon } from '../../../../assets/icons/add-media-icon.svg'
import { ReactComponent as DragIcon } from '../../../../assets/icons/suble-drag.svg'
import { ReactComponent as LinkIcon } from '../../../../assets/icons/link-icon.svg'
import '../../../../styles/editor-flow/editor-fields/editor-image-field.scss'
import EditorAlignmentButtons from '../elements/EditorAlignmentButtons'
import PropTypes from 'prop-types'
import EditorLinkModal from '../../shared-components/editor-link-modal/EditorLinkModal'

function EditorImageField({ field, onChange }) {
  const [linkModalOpen, setLinkModalOpen] = useState(false)
  const [editImageId, setEditImageId] = useState(null)
  const [mediaLibraryOpen, setMediaLibraryOpen] = useState(false)
  const block = get(field, 'blocks[0]', null)
  const image = get(block, 'image', null)
  const imageUrl = get(image, 'url', null)
  const configurations = get(block, 'configurations', {})
  const alignment = get(configurations, 'alignment', 'left')
  const alignments = {
    left: 'flex-start',
    center: 'center',
    right: 'flex-end',
  }

  const style = {
    justifyContent: alignments[alignment],
  }

  const imageStyle = {}

  if (configurations?.width) {
    imageStyle.width = configurations.width + 'px'
  }

  function handleSubmit(newImage) {
    onChange({
      ...field,
      blocks: [
        {
          image: newImage,
          type: 'image',
          configurations,
        },
      ],
    })
    setMediaLibraryOpen(false)
    setEditImageId(null)
  }

  function setConfigurations(configurations) {
    onChange({
      ...field,
      blocks: [
        {
          ...block,
          configurations,
        },
      ],
    })
  }

  function setAlignment(align) {
    setConfigurations({
      ...configurations,
      alignment: align,
    })
  }

  function handleLinkSubmit(values) {
    setConfigurations({
      ...configurations,
      link: values,
    })
    setLinkModalOpen(false)
  }

  const handler = mouseDownEvent => {
    const startSize = {
      width: image?.width,
      ...configurations,
    }

    const startPosition = { x: mouseDownEvent.pageX, y: mouseDownEvent.pageY }

    function onMouseMove(mouseMoveEvent) {
      onChange({
        ...field,
        blocks: [
          {
            ...block,
            configurations: {
              ...configurations,
              width: startSize.width - startPosition.x + mouseMoveEvent.pageX,
            },
          },
        ],
      })
    }
    function onMouseUp() {
      document.body.removeEventListener('mousemove', onMouseMove)
    }

    document.body.addEventListener('mousemove', onMouseMove)
    document.body.addEventListener('mouseup', onMouseUp, { once: true })
  }

  function handleChooseImageClick() {
    setMediaLibraryOpen(true)
    setEditImageId(null)
  }
  function handleEditImageClick() {
    setMediaLibraryOpen(true)
    setEditImageId(image?._id)
  }

  return (
    <div className="editor-image-field" style={style}>
      <div
        className={`editor-image-field-image${
          imageUrl ? ' populated' : ' placeholder'
        }`}
      >
        {imageUrl ? (
          <div className="editor-image-field-settings">
            <EditorAlignmentButtons setAlignment={setAlignment} />
            <div className="editor-image-field-link-settings">
              <label>Link</label>
              <button
                className="btn btn--white"
                onClick={() => setLinkModalOpen(true)}
              >
                <LinkIcon height="14" />
              </button>
            </div>
          </div>
        ) : null}

        {imageUrl && <img src={imageUrl} alt={image?.alt} style={imageStyle} />}

        <div
          className={`editor-image-field-options-overlay ${
            imageUrl ? ' populated' : ''
          }`}
        >
          <div className="flex col">
            <button
              className="btn btn--light-grey"
              onClick={handleChooseImageClick}
            >
              <AddImageIcon />
              {imageUrl
                ? 'Choose Image'
                : 'Choose Existing or Upload New Image'}
            </button>
            {imageUrl ? (
              <button
                className="btn btn--light-grey"
                onClick={handleEditImageClick}
              >
                <AddImageIcon />
                Edit Image
              </button>
            ) : null}
          </div>
          <button
            type="button"
            className="editor-image-field-resize-se"
            onMouseDown={handler}
          >
            <DragIcon />
          </button>
          <button
            type="button"
            className="editor-image-field-resize-ne"
            onMouseDown={handler}
          >
            <DragIcon />
          </button>
        </div>
      </div>

      <MediaLibrary
        isOpen={mediaLibraryOpen}
        folderId={image?.folderId}
        itemId={editImageId}
        onCancel={() => setMediaLibraryOpen(false)}
        onSubmit={handleSubmit}
        mode={editImageId ? 'edit' : 'select'}
        filter="image"
      />

      <EditorLinkModal
        isOpen={linkModalOpen}
        onRequestClose={() => setLinkModalOpen(false)}
        values={configurations.link}
        onSubmit={handleLinkSubmit}
      />
    </div>
  )
}

export const createImageField = id => {
  return {
    id,
    type: 'field',
    fieldType: EDITOR_IMAGE_FIELD,
    blocks: [],
  }
}

EditorImageField.propTypes = {
  field: PropTypes.object,
  onChange: PropTypes.func,
}

export default EditorImageField
