import PropTypes from 'prop-types'
import EditorAccordionSubcomponent from './EditorAccordionSubcomponent'
import { cloneDeep } from 'lodash'
import '../../../../styles/editor-flow/editor-components/editor-accordion-component.scss'
import { EDITOR_ACCORDIONS } from '../../../../configuration/editor'
import { SortableContext } from '@dnd-kit/sortable'
import getId from '../../../../utilities/editor/getId'
import React from 'react'
import TipTapColorPicker from '../../base-fields/TipTap/TipTapColorPicker'
import TipTapDropdown from '../../base-fields/TipTap/TipTapDropdown'
import clsx from 'clsx'
import { ReactComponent as BoldIcon } from '../../../../assets/icons/bold-editor-icon.svg'

const headingOptions = [1, 2, 3, 4, 5, 6].map(num => ({
  id: `h${num}`,
  name: `Heading ${num}`,
  icon: `h${num}`,
}))

function EditorAccordionsComponent({ component, onChange, dropPath }) {
  const { subComponents } = component
  const configurations = component?.configurations || {}
  const {
    title,
    titleColor,
    titleFormat = 'h2',
    description,
    bold = false,
  } = configurations

  function handleConfigChange(name, value) {
    onChange({
      ...component,
      configurations: {
        ...configurations,
        [name]: value,
      },
    })
  }

  function handleAddAccordion() {
    onChange({
      ...component,
      subComponents: [
        ...component.subComponents,
        { ...createEmptyAccordion() },
      ],
    })
  }

  function handleSubcomponentChange(subcomponent) {
    const newComponent = cloneDeep(component)
    const subcomponentIndex = newComponent.subComponents.findIndex(
      subcomp => getId(subcomp) === getId(subcomponent),
    )
    newComponent.subComponents[subcomponentIndex] = subcomponent
    onChange(newComponent)
  }

  function handleDeleteClick(subcomponent) {
    onChange({
      ...component,
      subComponents: component.subComponents.filter(
        item => getId(item) !== getId(subcomponent),
      ),
    })
  }

  function handleDuplicateAccordion(subcomponent) {
    const newSubcomponent = cloneDeep(subcomponent)
    delete newSubcomponent._id
    newSubcomponent.id = generateId()
    newSubcomponent.layouts.forEach(layout => {
      delete layout._id
      layout.id = generateId()
      layout.fields = layout.fields.map(field => ({
        blocks: field.blocks,
        id: generateId(),
        type: field.type,
        fieldType: field.fieldType,
      }))
    })

    const subcomponentIndex =
      subComponents.findIndex(search => getId(search) === getId(subcomponent)) +
      1
    onChange({
      ...component,
      subComponents: [
        ...subComponents.slice(0, subcomponentIndex),
        newSubcomponent,
        ...subComponents.slice(subcomponentIndex, subComponents.length),
      ],
    })
  }

  function getHeadingIcon() {
    const heading = headingOptions.find(search => search.id === titleFormat)
    return (
      <span className="editor-accordion-component__heading-icon">
        {heading.icon}
      </span>
    )
  }

  function generateId() {
    return `temp-${Date.now()}-${Math.random()}`
  }

  return (
    <div className="editor-accordion-component">
      <div className="accordion-component-title">
        <input
          type="text"
          value={title}
          className="accordion-component-title-input"
          onChange={evt => handleConfigChange('title', evt.target.value)}
          style={{ ...(titleColor && { color: titleColor }) }}
          placeholder="Enter Accordion Group Header"
        />
        <div className="accordion-component-title-actions">
          <button
            className={clsx('editor-accordion-component__title-option', {
              active: bold,
            })}
            onClick={() => handleConfigChange('bold', !bold)}
          >
            <BoldIcon />
          </button>
          <TipTapDropdown
            button={getHeadingIcon()}
            tooltip="Heading Style"
            direction="left"
          >
            {headingOptions.map(option => (
              <button
                key={option.id}
                onClick={() => {
                  handleConfigChange('titleFormat', option.id)
                }}
                className={clsx(option.id, {
                  'is-active': titleFormat === option.id,
                })}
              >
                {option.name}
              </button>
            ))}
          </TipTapDropdown>
          <TipTapColorPicker
            onChange={color => handleConfigChange('titleColor', color)}
            tooltip="Font Color"
            value={titleColor}
          />
        </div>
      </div>
      <div className="accordion-component-description">
        <textarea
          value={description}
          onChange={evt => handleConfigChange('description', evt.target.value)}
          placeholder="Enter accordion group description"
        ></textarea>
        <div className="accordion-component-description-actions"></div>
      </div>

      <SortableContext items={subComponents?.map(item => getId(item)) || []}>
        {subComponents?.map((subcomponent, i) => (
          <EditorAccordionSubcomponent
            subcomponent={subcomponent}
            index={i}
            key={getId(subcomponent)}
            onChange={handleSubcomponentChange}
            onDuplicateClick={handleDuplicateAccordion}
            onDeleteClick={handleDeleteClick}
            dropPath={[...dropPath, 'subComponents']}
          />
        ))}
      </SortableContext>

      <div className="accordion-component-add-button">
        <button className="btn btn--white" onClick={handleAddAccordion}>
          Add Another Accordion
        </button>
      </div>
    </div>
  )
}

export const createEmptyAccordion = () => {
  const id = `temp-${Date.now()}-sc`
  const layoutId = `temp-${Date.now()}-${Math.random()}`
  return {
    id,
    type: 'subComponent',
    subComponentType: 'accordion',
    configurations: {
      title: '',
      layout: '100',
    },
    layouts: [
      {
        id: layoutId,
        type: 'subComponentLayout',
        subComponentLayoutType: 'accordionLayout',
        fields: [],
      },
    ],
  }
}

export const createAccordionComponent = id => {
  return {
    id,
    componentType: EDITOR_ACCORDIONS,
    configurations: {},
    subComponents: [createEmptyAccordion()],
  }
}

EditorAccordionsComponent.propTypes = {
  component: PropTypes.object,
  onChange: PropTypes.func,
  dropPath: PropTypes.array,
}

export default EditorAccordionsComponent
