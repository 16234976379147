import '../../../../styles/editor-flow/find-replace/find-replace-text.scss'
import FindReplaceTextForm from './FindReplaceTextForm'
import {
  AUTO_CLOSE_WINDOW_HEIGHT,
  FindReplaceTabs,
} from '../../../../containers/FindReplaceContainer'
import TabNavigation from '../../../consumer-flow/structure/tab-navigation/TabNavigation'
import React, { useState } from 'react'
import useAuthToken from '../../../../hooks/useAuthToken'
import { getUserApiRequirements } from '../../../../redux/user/user'
import { useSelector } from 'react-redux'
import MaterialLoader from '../../../global-components/elements/MaterialLoader'
import { selectActiveOperatingLocation } from '../../../../redux/user/userSelections'
import FindReplaceTextTable from './FindReplaceTextTable'
import fetchFindText from '../../../../services/find-replace/fetchFindText'
import updateReplaceText from '../../../../services/find-replace/updateReplaceText'
import ErrorBoundary from '../../../global-components/elements/ErrorBoundary'

const FindReplaceTextContainer = () => {
  const { token } = useAuthToken({})
  const { activeClientId } = useSelector(getUserApiRequirements)
  const operatingLocation = useSelector(selectActiveOperatingLocation)
  const [replaceValue, setReplaceValue] = useState('')
  const [searchResultsState, setSearchResultsState] = useState({
    loading: false,
    error: false,
    data: null,
  })
  const [tabsAreOpen, setTabsAreOpen] = useState(true)

  const items = searchResultsState?.data?.items
  const entries = searchResultsState?.data?.entries

  async function handleFormSubmit(values) {
    setSearchResultsState({ ...searchResultsState, loading: true })
    setReplaceValue(values.replace)
    fetchFindText({
      activeClientId,
      token,
      find: values.find,
      matchCase: values.matchCase,
    })
      .then(data => {
        setSearchResultsState({
          ...searchResultsState,
          loading: false,
          data,
        })
        if (
          data?.entries?.length > 0 &&
          window.innerHeight < AUTO_CLOSE_WINDOW_HEIGHT
        ) {
          setTabsAreOpen(false)
        }
      })
      .catch(() => {
        setSearchResultsState({
          ...searchResultsState,
          loading: false,
          error: true,
        })
      })
  }

  function handleReplace(entry) {
    if (entry) {
      setSearchResultsState({
        ...searchResultsState,
        loading: true,
      })

      updateReplaceText({
        entry,
        items,
        entries,
        activeClientId,
        token,
        operatingLocation,
        replacementTerm: replaceValue,
      })
        .then(data => {
          setSearchResultsState({
            ...searchResultsState,
            loading: false,
            data,
          })
        })
        .catch(() => {
          setSearchResultsState({
            ...searchResultsState,
            loading: false,
          })
        })
    }
  }

  return (
    <div className="find-replace__container">
      <div className="find-replace__header container">
        <TabNavigation
          linkOptions={FindReplaceTabs}
          isOpen={tabsAreOpen}
          onSetOpen={setTabsAreOpen}
        >
          <FindReplaceTextForm onSubmit={handleFormSubmit} />
        </TabNavigation>
      </div>
      {entries?.length > 0 ? (
        <div className="find-replace__results">
          <FindReplaceTextTable
            items={items}
            entries={entries}
            replaceValue={replaceValue}
            onReplaceClick={handleReplace}
          />
        </div>
      ) : null}
      {entries?.length === 0 ? (
        <ErrorBoundary
          containerClasses="find-replace__error-boundary error-boundary section--md"
          errorTitle="No Results Found"
          errorMessage="Sorry, your search query did not yield any matching or relevant results. Please try different key words."
        />
      ) : null}
      {searchResultsState.loading && (
        <MaterialLoader containerClasses="overlay-loader" />
      )}
    </div>
  )
}

FindReplaceTextContainer.propTypes = {}

export default FindReplaceTextContainer
