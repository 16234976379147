import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import getOperatingSystem from '../../../../../utilities/browser/getOperatingSystem'
import {
  selectPowerSearchModalVisibility,
  togglePowerSearchModal,
} from '../../../../../redux/user/userSelections'
import { ReactComponent as PowerSearchInputIcon } from '../../../../../assets/icons/power-search-input-icon.svg'
import {
  selectCheckClientIndexes,
  selectCheckClientIndexLoading,
} from '../../../../../redux/global-search/checkClientIndexes'

function HeaderSearchToggle() {
  const dispatch = useDispatch()
  const userOs = getOperatingSystem()
  const isPowerSearchModalVisible = useSelector(
    selectPowerSearchModalVisibility,
  )
  const searchIndexesLoading = useSelector(selectCheckClientIndexLoading)
  const searchIndexesExist = useSelector(selectCheckClientIndexes)
  const globalSearchDisabled = !searchIndexesExist || searchIndexesLoading

  const handleTogglePowerSearch = () => {
    dispatch(togglePowerSearchModal(!isPowerSearchModalVisible))
  }

  return (
    <div className="header__search-toggle">
      <button
        className="header__search-toggle-input"
        aria-label="Open Search"
        disabled={globalSearchDisabled}
        onClick={() =>
          !globalSearchDisabled ? handleTogglePowerSearch() : null
        }
      >
        <div className="header__search-toggle-icon">
          <PowerSearchInputIcon />
        </div>

        <div className="header__search-toggle-placeholder">Global Search</div>

        <div className="header__search-toggle-hot-keys">
          {userOs === 'macos' || userOs === 'ios' ? (
            <span>&#8984;</span>
          ) : (
            <span>Win</span>
          )}
          <span>K</span>
        </div>
      </button>
    </div>
  )
}

HeaderSearchToggle.propTypes = {}

export default HeaderSearchToggle
