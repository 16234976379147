import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectActiveClient,
  selectActiveClientId,
  selectActiveOperatingLocation,
  selectManageWorkflowModalVisibility,
  toggleManageWorkflowModal,
} from '../redux/user/userSelections'
import { Outlet } from 'react-router-dom'
import { selectWorkflowsState } from '../redux/workflows/workflows'
import { get, isEmpty } from 'lodash'
import { selectCategoriesState } from '../redux/categories/categories'
import Header from '../components/global-components/structure/header/Header'
import ErrorBoundary from '../components/global-components/elements/ErrorBoundary'
import MaterialLoader from '../components/global-components/elements/MaterialLoader'
import routes from '../configuration/routes'
import TabNavigation from '../components/consumer-flow/structure/tab-navigation/TabNavigation'
import ManageCategoryModal from '../components/editor-flow/create-workflow/ManageCategoryModal'

function ViewMyWorkflowsContainer() {
  const dispatch = useDispatch()
  const activeClient = useSelector(selectActiveClient)
  const activeClientId = useSelector(selectActiveClientId)
  const activeOperatingLocation = useSelector(selectActiveOperatingLocation)
  const categoriesState = useSelector(selectCategoriesState)
  const workflowsState = useSelector(selectWorkflowsState)
  const isManageWorkflowModalVisible = useSelector(
    selectManageWorkflowModalVisibility,
  )

  const [categoryModalIsOpen, setCategoryModalIsOpen] = useState(null)
  const workflowsLoading = get(workflowsState, 'loading', false)
  const workflows = get(workflowsState, 'workflows', [])
  const workflowsByClient = get(
    workflows,
    `${activeOperatingLocation}-${activeClientId}`,
    [],
  )
  const categoriesByClient = get(
    categoriesState,
    `categories.${activeClientId}`,
    [],
  )
  const categoriesLoading = get(categoriesState, 'loading', false)
  const globalLoadingState = workflowsLoading || categoriesLoading
  const isCategoriesRoute = window.location.pathname.includes(
    routes.viewMyWorkflowsCategories,
  )

  const viewWorkflowsTabLinks = [
    {
      link: routes.viewMyWorkflows,
      label: `Workflows ${
        workflowsByClient?.length > 0
          ? '(' + workflowsByClient.length + ')'
          : ''
      }`,
    },
    {
      link: routes.viewMyWorkflowsCategories,
      label: `Categories ${
        categoriesByClient?.length > 0
          ? '(' + categoriesByClient.length + ')'
          : ''
      }`,
    },
  ]

  function handleCreateWorkflow() {
    dispatch(toggleManageWorkflowModal(!isManageWorkflowModalVisible))
  }

  function handleCreateCategory() {
    setCategoryModalIsOpen(true)
  }

  return (
    <div className="view-my-workflows view-container">
      <Header
        activeClient={activeClient}
        activeOperatingLocation={activeOperatingLocation}
        enableBreadCrumbs={true}
        title="Workflows"
      />

      <div className="container">
        <TabNavigation linkOptions={viewWorkflowsTabLinks} />

        {!globalLoadingState &&
        (!isEmpty(workflowsByClient) || !isEmpty(categoriesByClient)) ? (
          <Outlet />
        ) : globalLoadingState ? (
          <MaterialLoader containerClasses="container-loader" />
        ) : isCategoriesRoute ? (
          <ErrorBoundary
            ctaEvent={handleCreateCategory}
            ctaLabel="Add a New Category"
            errorTitle="No Categories Found"
            errorMessage="There are currently no categories registered under this client."
          />
        ) : (
          <ErrorBoundary
            ctaEvent={
              isEmpty(categoriesByClient)
                ? handleCreateCategory
                : handleCreateWorkflow
            }
            ctaLabel={
              isEmpty(categoriesByClient)
                ? 'Add a New Category'
                : 'Add a New Workflow'
            }
            errorTitle={
              isEmpty(categoriesByClient)
                ? 'No Workflows or Categories Found'
                : 'No Workflows Found'
            }
            errorMessage={
              isEmpty(categoriesByClient)
                ? 'There are currently no workflows or categories registered under this client - please add your category to proceed with creating your first workflow'
                : 'There are currently no workflows registered under this client - please add a new workflow.'
            }
          />
        )}
      </div>
      {categoryModalIsOpen ? (
        <ManageCategoryModal
          isOpen={categoryModalIsOpen}
          onRequestClose={() => setCategoryModalIsOpen(false)}
          onSubmit={() => setCategoryModalIsOpen(false)}
        />
      ) : null}
    </div>
  )
}

export default ViewMyWorkflowsContainer
