import React from 'react'
import { Link, useLocation, useParams } from 'react-router-dom'
import validateUserManagementAccess from '../../../../../utilities/permissions/validateUserManagementAccess'
import routes from '../../../../../configuration/routes'
import { ReactComponent as PencilIcon } from '../../../../../assets/icons/pencil-icon.svg'
import { useSelector } from 'react-redux'
import {
  selectActiveClientId,
  selectActiveOperatingLocation,
} from '../../../../../redux/user/userSelections'
import { selectWorkflowsState } from '../../../../../redux/workflows/workflows'
import { get } from 'lodash'

function HeaderEditorBanner() {
  const { pathname } = useLocation()
  const { workflow: workflowSlug, step } = useParams()
  const isManager = validateUserManagementAccess()
  const activeOperatingLocation = useSelector(selectActiveOperatingLocation)
  const activeClientId = useSelector(selectActiveClientId)
  const workflowsState = useSelector(selectWorkflowsState)
  const workflows = get(workflowsState, 'workflows', [])
  const workflowsByClient = get(
    workflows,
    `${activeOperatingLocation}-${activeClientId}`,
    [],
  )
  const workflow = workflowsByClient.find(
    search => search.slug === workflowSlug,
  )
  const ownsWorkflow = workflow?.clientId?.[0] === activeClientId

  const isConsumerFlow = pathname.includes(routes.browseWorkflows)
  const isBannerVisible =
    isManager && isConsumerFlow && workflowSlug && ownsWorkflow

  const baseWorkflowPath = `${routes.manageWorkflows}/${workflowSlug}`

  return isBannerVisible ? (
    <div className="header__editor-banner container">
      <Link to={baseWorkflowPath} className="btn btn--white">
        <PencilIcon />
        Edit Workflow
      </Link>

      {step ? (
        <Link to={`${baseWorkflowPath}/${step}`} className="btn btn--white">
          <PencilIcon />
          Edit Step
        </Link>
      ) : null}
    </div>
  ) : null
}

export default HeaderEditorBanner
