import { EditorContent, useEditor } from '@tiptap/react'
import Link from '@tiptap/extension-link'
import TextAlign from '@tiptap/extension-text-align'
import {
  TipTapAccordion,
  TipTapAccordionContent,
  TipTapAccordionHeader,
} from '../TipTap/TipTapAccordion/TipTapAccordion'
import { Underline } from '@tiptap/extension-underline'
import { Color } from '@tiptap/extension-color'
import TextStyle from '@tiptap/extension-text-style'
import ListItem from '@tiptap/extension-list-item'
import StarterKit from '@tiptap/starter-kit'
import Placeholder from '@tiptap/extension-placeholder'
import { isEmpty } from 'lodash'
import { ReactComponent as PlusIcon } from '../../../../assets/icons/plus-icon.svg'
import { ReactComponent as DeleteIcon } from '../../../../assets/icons/delete-icon.svg'
import { ReactComponent as ArrowDownIcon } from '../../../../assets/icons/arrow-down-icon.svg'
import { createPortal } from 'react-dom'
import { useContext, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import TipTapLink from '../TipTap/TipTapLink/TipTapLink'
import { bindRouterLinks } from '../../../../utilities/editor/bindRouterLinks'
import { useNavigate } from 'react-router-dom'
import EditorTableContext from '../../../../contexes/EditorTableContext'
import clsx from 'clsx'

const defaultContent = [
  {
    type: 'paragraph',
    attrs: {
      textAlign: 'left',
    },
  },
]
const EditorTableCell = ({
  readOnly,
  data,
  onChange,
  onClick,
  index,
  id,
  placeholder,
  onAddRowClick,
  onDeleteRowClick,
  onMoveUpClick,
  onMoveDownClick,
}) => {
  const navigate = useNavigate()
  const rowActionsRef = useRef()
  const cellRef = useRef()
  const [tableContext, setTableContext] = useContext(EditorTableContext)
  const isActive = !!tableContext?.activeCells.find(cell => cell.id === id)

  let content = {
    type: 'doc',
    content: !isEmpty(data) ? data : defaultContent,
  }
  const editor = useEditor({
    editable: !readOnly,
    extensions: [
      TipTapLink.configure({
        openOnClick: false,
      }),
      TextAlign.configure({
        types: ['heading', 'paragraph', 'image'],
      }),
      TipTapAccordion.configure({}),
      TipTapAccordionContent.configure({}),
      TipTapAccordionHeader.configure({}),
      Underline.configure({}),
      Color.configure({ types: [TextStyle.name, ListItem.name] }),
      TextStyle.configure({ types: [ListItem.name] }),
      StarterKit.configure({
        bulletList: {
          keepMarks: true,
          keepAttributes: false,
        },
        orderedList: {
          keepMarks: true,
          keepAttributes: false,
        },
      }),
      Placeholder.configure({
        emptyEditorClass: 'is-editor-empty',
        placeholder,
      }),
    ],
    editorProps: {
      transformPastedHTML(html) {
        return html.replace(/style=\"(.*?)\"/g, '')
      },
    },
    onUpdate: ({ editor }) => {
      onChange(editor.getJSON()?.content || [])
    },
    onFocus: ({ editor }) => {
      onClick(editor)
    },
    content,
  })

  useEffect(() => {
    if (editor && !editor.isEditable) {
      bindRouterLinks(editor, navigate)
    }
  }, [editor])

  useEffect(() => {
    if (cellRef.current && !tableContext.cells[id] && editor) {
      setTableContext({
        ...tableContext,
        cells: {
          ...tableContext.cells,
          [id]: {
            ref: cellRef,
            editor,
          },
        },
      })
    }
  }, [cellRef.current, tableContext.cells, editor])

  function handleMouseMove() {
    if (cellRef.current && rowActionsRef.current) {
      const rect = cellRef.current.getBoundingClientRect()
      rowActionsRef.current.style.left = `${rect.left}px`
      rowActionsRef.current.style.top = `${rect.top}px`
    }
  }

  function handleMouseEnter() {
    if (cellRef.current && rowActionsRef.current) {
      rowActionsRef.current.style.transform = 'translate(-100%, 0%) scale(1)'
    }
  }

  function handleMouseLeave() {
    if (cellRef.current && rowActionsRef.current) {
      rowActionsRef.current.style.transform = null
    }
  }

  return (
    <div
      className={clsx('editor-table-cell', { highlight: isActive })}
      onClick={() => onClick(editor)}
      onMouseMove={handleMouseMove}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      ref={cellRef}
    >
      <EditorContent editor={editor} />

      {index === 0 &&
        createPortal(
          <div className="editor-table-cell__actions" ref={rowActionsRef}>
            <button
              className="editor-table-cell__button"
              onClick={onMoveUpClick}
              data-tooltip="Move Row Up"
            >
              <ArrowDownIcon
                style={{ height: '14px', transform: 'rotate(180deg)' }}
              />
            </button>
            <button
              className="editor-table-cell__button"
              onClick={onMoveDownClick}
              data-tooltip="Move Row Down"
            >
              <ArrowDownIcon style={{ height: '14px' }} />
            </button>
            <div className="editor-table-cell__divider"></div>

            <button
              data-tooltip="Insert Row Below"
              onClick={onAddRowClick}
              className="editor-table-cell__button"
            >
              <PlusIcon style={{ width: '12px' }} />
            </button>
            <button
              data-tooltip="Delete Row"
              onClick={onDeleteRowClick}
              className="editor-table-cell__button"
            >
              <DeleteIcon height="30" width="30" />
            </button>
          </div>,
          document.body,
        )}
    </div>
  )
}

EditorTableCell.propTypes = {
  readOnly: PropTypes.bool,
  data: PropTypes.array,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  index: PropTypes.number,
  id: PropTypes.string,
  placeholder: PropTypes.string,
  onAddRowClick: PropTypes.func,
  onDeleteRowClick: PropTypes.func,
  onMoveUpClick: PropTypes.func,
  onMoveDownClick: PropTypes.func,
}
export default EditorTableCell
