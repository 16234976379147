import { Field, Form, Formik } from 'formik'
import TextField from '../../../../forms/elements/TextField'
import React from 'react'
import * as Yup from 'yup'
import PropTypes from 'prop-types'
import CustomSelect from '../../../../forms/elements/CustomSelect'

const targetOptions = [
  {
    label: 'Open the link in the same tab',
    value: '_self',
  },
  {
    label: 'Open the link in a new tab',
    value: '_blank',
  },
]

const FindReplaceExternalLinkForm = ({ onSubmit }) => {
  const validationSchema = Yup.object().shape({
    find: Yup.string().min(3).required('A search URL is required.'),
    replace: Yup.string().required('A replace URL is required.'),
  })

  const initialValues = {
    find: '',
    replace: '',
    replaceTarget: '_blank',
  }

  return (
    <div className="find-replace-external-link-form">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ isValid, setFieldTouched, values, setFieldValue, errors }) => {
          return (
            <>
              <Form className="form">
                <div className="form__field">
                  <Field
                    component={TextField}
                    label="URL:"
                    name="find"
                    placeholder=""
                    type="text"
                  />
                </div>
                <div className="flex find-replace-external-link-form__second-row">
                  <div className="form__field half">
                    <Field
                      component={TextField}
                      label="Replace URL With:"
                      name="replace"
                      placeholder=""
                      type="text"
                    />
                  </div>
                  <div className="form__field half">
                    <Field
                      component={CustomSelect}
                      label="Target"
                      name="replaceTarget"
                      menuPlacement="bottom"
                      options={targetOptions}
                      required={true}
                      setFieldTouched={setFieldTouched}
                      isClearable={false}
                    />
                  </div>
                  <div>
                    <button
                      type="submit"
                      className="btn btn--dark-green"
                      disabled={!isValid}
                    >
                      Search
                    </button>
                  </div>
                </div>
              </Form>
            </>
          )
        }}
      </Formik>
    </div>
  )
}

FindReplaceExternalLinkForm.propTypes = {
  onSubmit: PropTypes.func,
}
export default FindReplaceExternalLinkForm
