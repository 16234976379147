import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import * as Yup from 'yup'
import { Formik, Form, Field } from 'formik'
import { get, isBoolean } from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectActiveClientId,
  selectActiveOperatingLocation,
} from '../../redux/user/userSelections'
import {
  matchPath,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom'
import { selectWorkflowState } from '../../redux/workflows/workflow'
import { createStep, selectCreateStepState } from '../../redux/steps/createStep'
import { selectUserState } from '../../redux/user/user'
import { updateStep } from '../../redux/steps/updateStep'
import routes from '../../configuration/routes'
import useAuthToken from '../../hooks/useAuthToken'
import validateStepSlug from '../../utilities/slug/validateStepSlug'
import convertStringToSlug from '../../utilities/slug/convertStringToSlug'
import getWorkflowsDataById from '../../utilities/workflows/getWorkflowsDataById'
import MaterialLoader from '../global-components/elements/MaterialLoader'
import TextField from './elements/TextField'
import SlugField from './elements/SlugField'
import CustomSelect from './elements/CustomSelect'
import IconField from './elements/IconField'
import IconColourField from './elements/IconColourField'
import { emitSocketMessage } from '../../redux/middleware/socket/socket'

function ManageWorkflowStepForm({ formCancelEvent, managedStep }) {
  const [lastValidatedSlug, setLastValidatedSlug] = useState('')
  const [currentSlugValidity, setCurrentSlugValidity] = useState(null)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const { workflow, step } = useParams()
  const { token } = useAuthToken({})
  const createWorkflowStepState = useSelector(selectCreateStepState)
  const activeClientId = useSelector(selectActiveClientId)
  const activeOperatingLocation = useSelector(selectActiveOperatingLocation)
  const workflowState = useSelector(selectWorkflowState)
  const workflowOptions = get(workflowState, 'workflow', [])
  const createWorkflowStepLoading = get(
    createWorkflowStepState,
    'loading',
    false,
  )
  const workflowByClientSlug = get(
    workflowOptions,
    `[${activeOperatingLocation}-${activeClientId}-${workflow}]`,
    {},
  )
  const workflowId = get(workflowByClientSlug, 'workflowId', null)
  const managedWorkflow = getWorkflowsDataById(workflowId)

  // Handle Edit Logic for share model / form UI
  const managedStepName = get(managedStep, 'name', null)
  const managedStepSlug = get(managedStep, 'slug', null)
  const managedStepStatus = get(managedStep, 'status', null)
  const managedStepIcon = get(managedStep, 'icon.id', null)
  const managedStepIconColour = get(managedStep, 'icon.colour', null)
  const submitLabel = managedStep ? 'Configure Step' : 'Create Step'

  useEffect(() => {
    setLastValidatedSlug(managedStepSlug)
  }, [managedStepSlug])

  const initialStepValues = {
    name: managedStepName ? managedStepName : '',
    slug: managedStepSlug ? managedStepSlug : [],
    status: managedStepStatus ? managedStepStatus : 'draft',
    icon: managedStepIcon ? managedStepIcon : '',
    iconColour: managedStepIconColour ? managedStepIconColour : '',
  }

  const createWorkflowStepSchema = Yup.object().shape({
    name: Yup.string().required('Required'),
    slug: Yup.string()
      .required('Workflow slug required')
      .test(
        'validateSlug',
        'The selected URL path is already in use for this client - please enter a unique path value',
        async value => {
          // Re-fire event only when the slug field had changed OR slug remains invalid
          if (
            (managedStepSlug && lastValidatedSlug !== managedStepSlug) ||
            (value !== '' && value !== lastValidatedSlug) ||
            (isBoolean(currentSlugValidity) && currentSlugValidity === false)
          ) {
            setLastValidatedSlug(value)

            const slugExists = await validateStepSlug(
              activeClientId,
              workflowId,
              value,
              token,
            )
            setCurrentSlugValidity(slugExists)
            return slugExists
          }

          return true
        },
      ),
    status: Yup.string().required('Workflow status required'),
    icon: Yup.string(),
    iconColour: Yup.string(),
  })

  async function handleCreateWorkflowStep(values) {
    if (managedStep) {
      await dispatch(
        updateStep(
          managedWorkflow,
          managedStep,
          values,
          formCancelEvent,
          handleUpdateStepSlug,
          token,
        ),
      )
      dispatch(
        emitSocketMessage('configureStepComplete', {
          stepId: managedStep?._id,
          hasChanged: true,
        }),
      )
    } else {
      await dispatch(
        createStep(managedWorkflow, values, formCancelEvent, token),
      )
    }
  }

  // handle redirecting the manager to the new step slug when they
  // edit the existing step slug from within the step manager itself
  function handleUpdateStepSlug(updatedSlug) {
    if (
      matchPath(
        { path: `/${routes.manageWorkflows}/:workflow/:step` },
        pathname,
      ) &&
      updatedSlug !== step
    ) {
      navigate(`${routes.manageWorkflows}/${workflow}/${updatedSlug}`, {
        replace: true,
      })
    }
  }

  return (
    <Formik
      initialValues={initialStepValues}
      validationSchema={createWorkflowStepSchema}
      onSubmit={values => {
        handleCreateWorkflowStep(values)
      }}
    >
      {({ isValid, setFieldTouched, setFieldValue, values }) => {
        const submitButtonDisabled = !isValid

        return (
          <Form className="form form--modal">
            <div className="form__field full">
              <Field
                component={TextField}
                label="Name"
                name="name"
                placeholder="Enter Step Name"
                required={true}
                type="text"
                onKeyUp={e => {
                  !managedStep
                    ? setFieldValue('slug', convertStringToSlug(e.target.value))
                    : null
                }}
              />
            </div>

            <div className="form__field half">
              <Field
                component={SlugField}
                label="Path"
                name="slug"
                placeholder="Enter Step URL Path"
                required={true}
                type="text"
                onChange={e =>
                  setFieldValue(
                    'slug',
                    e.target.value
                      .replace(/\W+/g, '-')
                      .replace(/[^a-zA-Z0-9-]/g, '')
                      .toLowerCase(),
                  )
                }
              />
            </div>

            <div className="form__field half">
              <Field
                component={CustomSelect}
                label="Status"
                name="status"
                options={[
                  {
                    label: 'Draft',
                    value: 'draft',
                  },
                  {
                    label: 'Published',
                    value: 'published',
                  },
                ]}
                required={true}
                placeholder="Set Step Status"
                setFieldTouched={setFieldTouched}
                isClearable={false}
              />
            </div>

            <div
              className={`form__field ${
                values?.iconColour ? 'three-quarter' : 'full'
              }`}
            >
              <Field
                component={IconField}
                label="Icon"
                name="icon"
                placeholder="Select or Search Category Icons"
                type="text"
                setFieldTouched={setFieldTouched}
              />
            </div>

            {values?.iconColour ? (
              <div className="form__field one-quarter">
                <Field
                  component={IconColourField}
                  label="Icon Colour"
                  name="iconColour"
                  placeholder="Select Colour"
                  type="text"
                  setFieldTouched={setFieldTouched}
                />
              </div>
            ) : null}

            <div className="form__submit">
              {createWorkflowStepLoading ? (
                <MaterialLoader containerClasses="inline-loader" />
              ) : (
                <>
                  <button
                    className="btn btn--white"
                    type="button"
                    onClick={() => formCancelEvent()}
                  >
                    Cancel
                  </button>

                  <button
                    className="btn btn--dark-purple"
                    disabled={submitButtonDisabled}
                    type="submit"
                  >
                    {submitButtonDisabled
                      ? 'Please ensure all field values are valid'
                      : submitLabel}
                  </button>
                </>
              )}
            </div>
          </Form>
        )
      }}
    </Formik>
  )
}

ManageWorkflowStepForm.propTypes = {
  formCancelEvent: PropTypes.func.isRequired,
  managedStep: PropTypes.object,
}

export default ManageWorkflowStepForm
