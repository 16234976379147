import { get, includes } from 'lodash'
import routes from '../../configuration/routes'

export default function handleClientRouteEvents(location, navigate) {
  const path = get(location, 'pathname', null)

  if (includes(path, routes.browseWorkflows)) {
    navigate(routes.browseWorkflows, { replace: true })
  } else if (includes(path, routes.manageWorkflows)) {
    navigate(routes.manageWorkflows, { replace: true })
  }
}
