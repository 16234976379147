import PropTypes from 'prop-types'
import TableField from '../base-fields/table/TableField'

const Table = ({ component }) => {
  const subcomponent = component?.subComponents?.[0] || {}
  const { fields } = subcomponent || []
  const field = fields[0]

  return (
    <div className="image">
      <TableField field={field} />
    </div>
  )
}

Table.propTypes = {
  component: PropTypes.object,
}

export default Table
