import React from 'react'
import PropTypes from 'prop-types'
import ImageField from '../base-fields/image/ImageField'
import useEntryHighlight from '../../../hooks/useEntryHighlight'

const Image = ({ component }) => {
  const subcomponent = component?.subComponents?.[0] || {}
  const { fields } = subcomponent || []
  const field = fields[0]
  const highlight = useEntryHighlight()
  const fieldHighlight = highlight?.itemId === field?._id ? highlight : null
  return (
    <div className="image">
      <ImageField field={field} highlight={fieldHighlight} />
    </div>
  )
}

Image.propTypes = {
  component: PropTypes.object,
}
export default Image
