import React from 'react'
import PropTypes from 'prop-types'
import { get, isEmpty } from 'lodash'
import { useSelector } from 'react-redux'
import { selectActiveOperatingLocation } from '../../../../redux/user/userSelections'
import parseValidHtmlString from '../../../../utilities/content/parseValidHtmlString'
import getResultRouteDetails from '../../../../utilities/algolia/getResultRouteDetails'
import getResultTypeIcon from '../../../../utilities/algolia/getResultTypeIcon'
import getIconComponent from '../../../../utilities/icon/getIconComponent'

function PowerSearchResultsList({
  // activeResultsFilter,
  autocomplete,
  filteredItems,
  groupedItems,
  resultGroup,
  // setActiveResultsFilter,
  source,
}) {
  let searchFilterOptions = [
    {
      label: 'Show All',
      type: 'All',
    },
  ]

  if (!isEmpty(groupedItems)) {
    groupedItems.forEach(resultType => {
      const filterType = get(resultType, 'type', null)
      const filterItems = get(resultType, 'items', [])

      searchFilterOptions.push({
        label: `${filterType} (${filterItems.length})`,
        type: filterType,
      })
    })
  }

  return (
    <div
      className="power-search__results-list"
      key={`power-search__results-${resultGroup}`}
    >
      {/*{!isEmpty(searchFilterOptions) ? (*/}
      {/*  <div className="power-search__results-filters">*/}
      {/*    <p>Refine Results</p>*/}

      {/*    {searchFilterOptions.map(filter => {*/}
      {/*      const filterType = get(filter, 'type', null)*/}

      {/*      return filterType ? (*/}
      {/*        <button*/}
      {/*          key={`power-search-filter-${filterType}`}*/}
      {/*          className={`power-search__filter ${*/}
      {/*            filterType === activeResultsFilter ? ' active' : ''*/}
      {/*          }`}*/}
      {/*          onClick={() => setActiveResultsFilter(filterType)}*/}
      {/*        >*/}
      {/*          {filter?.label}*/}
      {/*        </button>*/}
      {/*      ) : null*/}
      {/*    })}*/}
      {/*  </div>*/}
      {/*) : null}*/}

      {filteredItems.map((groupItem, i) => {
        const title = get(groupItem, 'type', null)
        const items = get(groupItem, 'items', [])

        return title ? (
          <div
            key={`power-search-group-${i}`}
            className={`power-search__results-group col-${groupedItems.length}`}
          >
            <div className="power-search__results-group-title">
              {title} ({items.length})
            </div>

            {!isEmpty(items)
              ? items.map((item, i) => {
                  const type =
                    get(item, '__typename', null) || get(item, 'type', null)
                  const highlightedContent = get(item, '_highlightResult', [])
                  const title =
                    get(highlightedContent, 'title.value', null) ||
                    get(highlightedContent, 'name.value', null)
                  const icon = get(item, 'icon.url', null)
                  const fallbackIcon = getResultTypeIcon(type)
                  const routeDetails = getResultRouteDetails(item)
                  const route = get(routeDetails, 'route', null)
                  const category = get(routeDetails, 'category', null)
                  const subcategory = get(routeDetails, 'subcategory', null)

                  return (
                    <article
                      key={`power-search-result-${i}`}
                      className="power-search__result"
                      {...autocomplete.getItemProps({
                        item,
                        source,
                      })}
                      tabIndex={0}
                    >
                      {icon ? <img src={icon} /> : fallbackIcon}

                      <div className="power-search__result-content">
                        <p className="power-search__result-title">
                          {parseValidHtmlString(title)}
                        </p>

                        <div className="power-search__result-path">
                          <span className="route">{route}</span>
                          <span className="divider">&#8250;</span>
                          <span className="category">{category}</span>
                          {subcategory ? (
                            <>
                              <span className="divider">&#8250;</span>
                              <span className="subcategory">{subcategory}</span>
                            </>
                          ) : null}
                        </div>
                      </div>
                    </article>
                  )
                })
              : 'No results found'}
          </div>
        ) : null
      })}
    </div>
  )
}

PowerSearchResultsList.propTypes = {
  activeResultsFilter: PropTypes.string,
  autocomplete: PropTypes.object,
  filteredItems: PropTypes.array,
  groupedItems: PropTypes.array,
  resultGroup: PropTypes.number,
  setActiveResultsFilter: PropTypes.func,
  source: PropTypes.object,
}

export default PowerSearchResultsList
