import React, { useState } from 'react'
import { EDITOR_BUTTON_FIELD } from '../../../../configuration/editor'
import { ReactComponent as LinkIcon } from '../../../../assets/icons/link-icon.svg'
import '../../../../styles/editor-flow/editor-fields/editor-button-field.scss'
import EditorAlignmentButtons from '../elements/EditorAlignmentButtons'
import PropTypes from 'prop-types'
import getActiveBrandHexColours from '../../../../utilities/brand/getActiveBrandHexColours'
import useClickAway from '../../../../hooks/useClickAway'
import { ReactComponent as EditIcon } from '../../../../assets/icons/pencil-icon.svg'
import EditorLinkModal from '../../shared-components/editor-link-modal/EditorLinkModal'
import ButtonField from '../../../consumer-flow/base-fields/button/ButtonField'

function EditorButtonField({ field, onChange }) {
  const [linkModalOpen, setLinkModalOpen] = useState(false)
  const colors = getActiveBrandHexColours()
  const block = field?.blocks?.[0]
  const configurations = block?.configurations || { alignment: 'left' }
  const button = block?.button || {}
  const { alignment } = configurations
  const alignments = {
    left: 'flex-start',
    center: 'center',
    right: 'flex-end',
  }

  const containerStyle = {
    justifyContent: alignments[alignment],
  }

  const buttonStyle = {
    color: button?.color || getTextColour(colors[0]),
    background: button?.background || colors[0],
    width: `calc(${button.label.length}ch + 48px)`,
  }

  function setButton(button) {
    onChange({
      ...field,
      blocks: [
        {
          configurations,
          button,
        },
      ],
    })
  }

  function setConfigurations(configurations) {
    onChange({
      ...field,
      blocks: [
        {
          configurations,
          button,
        },
      ],
    })
  }

  function setAlignment(align) {
    setConfigurations({
      ...configurations,
      alignment: align,
    })
  }

  function handleButtonLabelChange(evt) {
    setButton({
      ...button,
      label: evt.target.value,
    })
  }

  function handleButtonColorChange(color) {
    setButton({
      ...button,
      background: color,
      color: getTextColour(color),
    })
  }

  function handleLinkSubmit(values) {
    setConfigurations({
      ...configurations,
      link: values,
    })
    setLinkModalOpen(false)
  }

  return (
    <div className="editor-button-field">
      <div className="editor-button-field-content">
        <div className="editor-button-field-settings">
          <EditorAlignmentButtons setAlignment={setAlignment} />
          <div className="editor-button-field-link-settings">
            <label>Link</label>
            <button
              className="btn btn--white"
              onClick={() => setLinkModalOpen(true)}
            >
              <LinkIcon height="14" />
            </button>
          </div>
          <div className="editor-button-field-style-settings">
            <label>Style</label>
            <ButtonColors
              value={button.background}
              onChange={handleButtonColorChange}
            />
          </div>
        </div>

        <div
          className="editor-button-field-button-container"
          style={containerStyle}
        >
          <input
            className="btn"
            style={buttonStyle}
            value={button.label}
            onChange={handleButtonLabelChange}
          />
        </div>
      </div>
      <EditorLinkModal
        isOpen={linkModalOpen}
        onRequestClose={() => setLinkModalOpen(false)}
        values={configurations.link}
        onSubmit={handleLinkSubmit}
      />
    </div>
  )
}

const ButtonColors = ({ value, onChange }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const ref = useClickAway(() => setDropdownOpen(false))
  const brandColours = getActiveBrandHexColours()

  function handlePickColour(color) {
    onChange(color)
    setDropdownOpen(false)
  }

  return (
    <div className="editor-button-field-color-picker">
      <button onClick={() => setDropdownOpen(true)} className="btn btn--white">
        <EditIcon height="14" />
      </button>

      {dropdownOpen && (
        <div ref={ref} className="editor-button-field-color-picker-menu">
          {brandColours.map(color => (
            <button
              key={`button-${color}`}
              onClick={() => handlePickColour(color)}
              className="btn"
              style={{ backgroundColor: color, color: getTextColour(color) }}
            >
              New Button
            </button>
          ))}
        </div>
      )}
    </div>
  )
}

ButtonColors.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
}

export const createButtonField = id => {
  return {
    id,
    type: 'field',
    fieldType: EDITOR_BUTTON_FIELD,
    blocks: [
      {
        button: {
          label: 'New Button',
        },
        configurations: {
          alignment: 'left',
        },
      },
    ],
  }
}

function getTextColour(colour) {
  const brightness = getBrightness(colour)
  return brightness < 128 ? '#fff' : '#000'
}

function getBrightness(colour) {
  const c = colour.substring(1) // strip #
  const rgb = parseInt(c, 16) // convert rrggbb to decimal
  const r = (rgb >> 16) & 0xff // extract red
  const g = (rgb >> 8) & 0xff // extract green
  const b = (rgb >> 0) & 0xff // extract blue
  return 0.2126 * r + 0.7152 * g + 0.0722 * b
}

EditorButtonField.propTypes = {
  field: PropTypes.object,
  onChange: PropTypes.func,
}

export default EditorButtonField
