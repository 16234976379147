// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.content-component {
  margin: 0 0 20px 0;
}`, "",{"version":3,"sources":["webpack://./src/styles/consumer-flow/base-components/content.scss"],"names":[],"mappings":"AAEA;EACE,kBAAA;AADF","sourcesContent":["@import '../../configuration/variables';\n\n.content-component {\n  margin: 0 0 20px 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
