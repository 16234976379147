import React from 'react'
import PropTypes from 'prop-types'

function LinkIcon({ colour }, props) {
  return (
    <svg
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M36.3701 10.0229L33.1749 6.82762C30.9983 4.65117 27.473 4.64751 25.2929 6.82762L19.9297 12.1908C16.8929 10.123 13.9176 11.8072 12.8611 12.8637L3.62981 22.0951C1.45673 24.2681 1.45673 27.804 3.62981 29.9771L6.82502 33.1723C9.00161 35.3488 12.5269 35.3525 14.7071 33.1723L20.0713 27.8082C21.2327 28.5959 24.4912 29.7838 27.1389 27.1363L36.3703 17.9049C38.5433 15.7318 38.5433 12.1959 36.3701 10.0229ZM25.5315 19.37C25.4329 18.4939 25.1273 17.6372 24.6149 16.8793L29.234 12.2602L30.9376 13.9638L25.5315 19.37ZM13.2155 31.6808C11.8626 33.0336 9.67162 33.0358 8.31649 31.6808L5.12128 28.4856C3.77064 27.1349 3.77064 24.9372 5.12128 23.5866L14.3526 14.3552C15.7032 13.0046 17.9009 13.0046 19.2516 14.3552L22.4468 17.5504C23.5366 18.6402 23.7471 20.2813 23.0782 21.584L17.5479 16.0589C17.1361 15.6471 16.4682 15.647 16.0563 16.0589L6.82502 25.2903C6.41306 25.7022 6.41355 26.3703 6.82502 26.7818L10.0203 29.9771C10.4321 30.389 11.0999 30.389 11.5118 29.9771L16.8022 24.6868L18.5058 26.3904L13.2155 31.6808ZM15.3851 23.1207L10.766 27.7398L9.06236 26.0361L14.4689 20.6296C14.5672 21.5058 14.8726 22.3627 15.3851 23.1207ZM34.8786 16.4133L25.6473 25.6446C24.2966 26.9953 22.0988 26.9951 20.7483 25.6446L17.5531 22.4494C16.4634 21.3597 16.2529 19.7186 16.9217 18.4158L22.452 23.941C22.8639 24.3529 23.5321 24.3525 23.9436 23.941L33.1749 14.7096C33.5868 14.2978 33.5865 13.6295 33.1749 13.218L29.9797 10.0228C29.5679 9.61094 28.9001 9.61094 28.4882 10.0228L23.1978 15.3131L21.4942 13.6095L26.7845 8.31916C28.1374 6.96628 30.3285 6.96438 31.6834 8.31916L34.8787 11.5144C36.2292 12.8651 36.2292 15.0627 34.8786 16.4133Z"
        fill={colour}
      />
    </svg>
  )
}

LinkIcon.defaultProps = {
  colour: '#000000',
}

LinkIcon.propTypes = {
  colour: PropTypes.string,
}

export default LinkIcon
