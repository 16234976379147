import React from 'react'
import PropTypes from 'prop-types'

function SpeechBubbleIcon({ colour }, props) {
  return (
    <svg
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M20 10.8594C19.6119 10.8594 19.2969 11.1744 19.2969 11.5625C19.2969 11.9506 19.6119 12.2656 20 12.2656C20.3881 12.2656 20.7031 11.9506 20.7031 11.5625C20.7031 11.1744 20.3881 10.8594 20 10.8594Z"
        fill={colour}
      />
      <path
        d="M8.32812 23.5156C8.71622 23.5156 9.03125 23.2006 9.03125 22.8125C9.03125 22.4244 8.71622 22.1094 8.32812 22.1094C7.94003 22.1094 7.625 22.4244 7.625 22.8125C7.625 23.2006 7.94003 23.5156 8.32812 23.5156Z"
        fill={colour}
      />
      <path
        d="M25.625 3.82812C19.295 3.82812 14.0995 8.19244 13.6977 13.6897C7.66922 13.9921 2 18.3391 2 24.2188C2 26.6758 2.97229 29.0566 4.74219 30.9429C5.09238 32.3865 4.66721 33.9167 3.61224 34.9716C3.41119 35.1727 3.35104 35.4751 3.45981 35.7379C3.56857 36.0005 3.82483 36.1719 4.10938 36.1719C6.11465 36.1719 8.04715 35.3855 9.48306 33.9999C10.8819 34.4767 12.707 34.7656 14.375 34.7656C20.7042 34.7656 25.8991 30.4024 26.302 24.9062C27.7742 24.8409 29.3054 24.5689 30.5169 24.1561C31.9529 25.5418 33.8853 26.3281 35.8906 26.3281C36.1752 26.3281 36.4314 26.1567 36.5402 25.8942C36.649 25.6313 36.5888 25.3289 36.3878 25.1279C35.3328 24.0729 34.9076 22.5428 35.2578 21.0992C37.0277 19.2128 38 16.8321 38 14.375C38 8.26578 31.8941 3.82812 25.625 3.82812ZM14.375 33.3594C12.7155 33.3594 10.8245 33.0336 9.55777 32.5296C9.28641 32.4214 8.97632 32.4926 8.77884 32.7082C7.92987 33.6362 6.82602 34.2833 5.62329 34.5808C6.28604 33.311 6.45523 31.8141 6.05203 30.3958C6.02017 30.284 5.96112 30.1819 5.8801 30.0986C4.28488 28.4556 3.40625 26.3674 3.40625 24.2188C3.40625 19.2639 8.4292 15.0781 14.375 15.0781C19.9896 15.0781 24.9219 18.9824 24.9219 24.2188C24.9219 29.259 20.1906 33.3594 14.375 33.3594ZM34.1199 20.2549C34.0389 20.3384 33.9798 20.4406 33.948 20.5521C33.5448 21.9704 33.714 23.4673 34.3767 24.737C33.174 24.4398 32.0701 23.7925 31.2212 22.8644C31.0237 22.6491 30.7136 22.5777 30.4422 22.6859C29.3444 23.1226 27.7783 23.425 26.2998 23.498C26.0944 20.8391 24.7681 18.34 22.5005 16.4844H31.6719C32.0602 16.4844 32.375 16.1696 32.375 15.7812C32.375 15.3929 32.0602 15.0781 31.6719 15.0781H20.3403C18.7443 14.2657 16.9521 13.7919 15.1075 13.6925C15.5115 8.97 20.0744 5.23438 25.625 5.23438C31.5708 5.23438 36.5938 9.42017 36.5938 14.375C36.5938 16.5237 35.7151 18.6119 34.1199 20.2549Z"
        fill={colour}
      />
      <path
        d="M20 22.1094H11.1406C10.7523 22.1094 10.4375 22.4241 10.4375 22.8125C10.4375 23.2009 10.7523 23.5156 11.1406 23.5156H20C20.3884 23.5156 20.7031 23.2009 20.7031 22.8125C20.7031 22.4241 20.3884 22.1094 20 22.1094Z"
        fill={colour}
      />
      <path
        d="M20 26.3281H8.32812C7.93976 26.3281 7.625 26.6429 7.625 27.0312C7.625 27.4196 7.93976 27.7344 8.32812 27.7344H20C20.3884 27.7344 20.7031 27.4196 20.7031 27.0312C20.7031 26.6429 20.3884 26.3281 20 26.3281Z"
        fill={colour}
      />
      <path
        d="M31.6719 10.8594H22.8125C22.4241 10.8594 22.1094 11.1741 22.1094 11.5625C22.1094 11.9509 22.4241 12.2656 22.8125 12.2656H31.6719C32.0602 12.2656 32.375 11.9509 32.375 11.5625C32.375 11.1741 32.0602 10.8594 31.6719 10.8594Z"
        fill={colour}
      />
    </svg>
  )
}

SpeechBubbleIcon.defaultProps = {
  colour: '#000000',
}

SpeechBubbleIcon.propTypes = {
  colour: PropTypes.string,
}

export default SpeechBubbleIcon
