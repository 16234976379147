import React from 'react'
import PropTypes from 'prop-types'

function CalendarIcon({ colour }, props) {
  return (
    <svg
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M19.145 33.2824H4.3445C3.6354 33.2824 3.05855 32.7055 3.05855 31.9964L3.05862 11.6077H9.62195C9.9143 11.6077 10.1513 11.3708 10.1513 11.0784C10.1513 10.786 9.9143 10.5491 9.62195 10.5491H3.05862V7.81956C3.05862 7.11053 3.63547 6.53369 4.3445 6.53369H5.73444V6.865C5.73444 8.05813 6.70517 9.02873 7.89823 9.02873C9.0913 9.02873 10.062 8.05806 10.062 6.865V6.53369H26.1115V6.865C26.1115 8.05813 27.0822 9.02873 28.2753 9.02873C29.4684 9.02873 30.4391 8.05806 30.4391 6.865V6.53369H31.8133C32.5224 6.53369 33.0992 7.11053 33.0992 7.81956V10.5491H12.092C11.7996 10.5491 11.5626 10.786 11.5626 11.0784C11.5626 11.3708 11.7996 11.6077 12.092 11.6077H33.0994V17.3121C33.0994 17.6045 33.3363 17.8414 33.6287 17.8414C33.921 17.8414 34.158 17.6045 34.158 17.3121C34.158 16.4146 34.158 8.79001 34.158 7.81963C34.158 6.52687 33.1063 5.4752 31.8136 5.4752H30.4393V5.14389C30.4393 3.95076 29.4686 2.98016 28.2755 2.98016C27.0824 2.98016 26.1117 3.95083 26.1117 5.14389V5.4752H10.0621V5.14389C10.0621 3.95076 9.09137 2.98016 7.8983 2.98016C6.70524 2.98016 5.73451 3.95083 5.73451 5.14389V5.4752H4.3445C3.05173 5.4752 2.00007 6.52687 2.00007 7.81963C2.00007 8.71977 2 31.2723 2 31.9964C2 33.2892 3.05173 34.3409 4.3445 34.3409H19.145C19.4374 34.3409 19.6743 34.104 19.6743 33.8116C19.6743 33.5193 19.4374 33.2824 19.145 33.2824ZM27.1702 5.14389C27.1702 4.53449 27.666 4.03872 28.2754 4.03872C28.8849 4.03872 29.3807 4.53449 29.3807 5.14389V6.86507C29.3807 7.47447 28.8849 7.97024 28.2754 7.97024C27.666 7.97024 27.1702 7.47447 27.1702 6.86507V5.14389ZM6.79306 5.14389C6.79306 4.53449 7.28884 4.03872 7.8983 4.03872C8.50777 4.03872 9.00355 4.53449 9.00355 5.14389V6.86507C9.00355 7.47447 8.50777 7.97024 7.8983 7.97024C7.28884 7.97024 6.79306 7.47447 6.79306 6.86507V5.14389Z"
        fill={colour}
      />
      <path
        d="M33.9373 19.3797C32.9464 18.6685 31.8478 18.1592 30.6878 17.8629C30.6878 17.1027 30.6878 16.3649 30.6878 15.6702C30.6878 14.986 30.1312 14.4294 29.4471 14.4294C22.654 14.4294 13.4774 14.4294 6.71102 14.4294C6.02688 14.4294 5.47021 14.986 5.47021 15.6702V28.8789C5.47021 29.5631 6.02688 30.1197 6.71102 30.1197H18.9553C19.4154 31.6408 20.2478 33.0336 21.4068 34.1869C23.2428 36.0137 25.6801 37.0199 28.2697 37.0199C33.635 37.0199 38 32.6549 38 27.2895C38 24.1625 36.4813 21.2056 33.9373 19.3797ZM25.856 15.4881H29.4471C29.5475 15.4881 29.6292 15.5698 29.6292 15.6702V17.6548C29.182 17.5925 28.7283 17.5592 28.2697 17.5592C27.4368 17.5592 26.6281 17.6645 25.856 17.8624V15.4881ZM21.0242 15.4881H24.7974V18.0874H21.0242V15.4881ZM21.0242 19.146H22.9503C22.2381 19.6128 21.5906 20.1703 21.0242 20.8022V19.146ZM16.1924 15.4881H19.9656V18.0874H16.1924V15.4881ZM16.1924 19.146H19.9656V21.7453H16.1924V19.146ZM10.302 29.0611H6.71102C6.61054 29.0611 6.52877 28.9794 6.52877 28.8789V26.4617H10.302V29.0611ZM10.302 25.4032H6.52877V22.8039H10.302V25.4032ZM10.302 21.7453H6.52877V19.146H10.302V21.7453ZM10.302 18.0874H6.52877V15.6702C6.52877 15.5698 6.61047 15.488 6.71102 15.488H10.3021V18.0874H10.302ZM15.1338 29.0611H11.3606V26.4618H15.1338V29.0611ZM15.1338 25.4032H11.3606V22.8039H15.1338V25.4032ZM15.1338 21.7453H11.3606V19.146H15.1338V21.7453ZM15.1338 18.0874H11.3606V15.4881H15.1338V18.0874ZM16.1924 29.0611V26.4618H18.5749C18.5518 26.7347 18.5393 27.0107 18.5393 27.2895C18.5393 27.8903 18.594 28.4826 18.6999 29.0611H16.1924ZM18.7235 25.4032H16.1924V22.8039H19.6369C19.2159 23.611 18.9048 24.484 18.7235 25.4032ZM28.2697 35.9613C25.9618 35.9613 23.7896 35.0647 22.1534 33.4365C20.5055 31.7968 19.598 29.6137 19.598 27.2895C19.598 22.5079 23.4881 18.6177 28.2697 18.6177C30.0952 18.6177 31.8416 19.1786 33.3201 20.2397C35.5877 21.8673 36.9415 24.5027 36.9415 27.2895C36.9415 32.0711 33.0513 35.9613 28.2697 35.9613Z"
        fill={colour}
      />
      <path
        d="M28.2697 19.6681C28.2697 19.6681 28.2697 19.6681 28.2697 19.6681C28.2697 19.6681 28.2697 19.6681 28.2697 19.6681C24.0674 19.6681 20.6483 23.0884 20.6483 27.2895C20.6483 31.492 24.0688 34.9108 28.2697 34.9109H28.2697C32.472 34.9109 35.8911 31.4905 35.8911 27.2895C35.8911 23.0873 32.4708 19.6681 28.2697 19.6681ZM33.2671 31.5384L33.0342 31.3055C32.8275 31.0988 32.4924 31.0987 32.2856 31.3054C32.0788 31.5121 32.0788 31.8472 32.2856 32.054L32.5186 32.287C31.4989 33.1553 30.2113 33.7177 28.7989 33.831V33.8296C28.7989 33.5372 28.562 33.3002 28.2696 33.3002C27.9772 33.3002 27.7403 33.5372 27.7403 33.8296V33.831C26.3279 33.7177 25.0404 33.1552 24.0207 32.287L24.2537 32.054C24.4604 31.8472 24.4604 31.5121 24.2536 31.3054C24.047 31.0988 23.7119 31.0987 23.5051 31.3055L23.2721 31.5384C22.4038 30.5188 21.8414 29.2313 21.7281 27.8188H21.7295C22.0219 27.8188 22.2589 27.5819 22.2589 27.2895C22.2589 26.9972 22.0219 26.7602 21.7295 26.7602H21.7281C21.8414 25.3478 22.4039 24.0603 23.2721 23.0406L23.5051 23.2735C23.7117 23.4802 24.0468 23.4804 24.2536 23.2736C24.4604 23.0669 24.4604 22.7318 24.2537 22.5251L24.0207 22.2921C25.0404 21.4238 26.3278 20.8613 27.7403 20.748V20.7495C27.7403 21.0418 27.9772 21.2788 28.2696 21.2788C28.562 21.2788 28.7989 21.0418 28.7989 20.7495V20.748C30.2113 20.8613 31.4989 21.4238 32.5186 22.2921L32.2856 22.5251C32.0788 22.7319 32.0788 23.067 32.2856 23.2737C32.4923 23.4803 32.8274 23.4804 33.0342 23.2736L33.2671 23.0407C34.1353 24.0603 34.6978 25.3478 34.8111 26.7603H34.8097C34.5174 26.7603 34.2804 26.9972 34.2804 27.2896C34.2804 27.5819 34.5174 27.8189 34.8097 27.8189H34.8111C34.6978 29.2312 34.1353 30.5187 33.2671 31.5384Z"
        fill={colour}
      />
      <path
        d="M28.7989 25.6999V23.2247C28.7989 22.9323 28.5619 22.6953 28.2696 22.6953C27.9772 22.6953 27.7403 22.9323 27.7403 23.2247V25.6999C27.075 25.9221 26.594 26.5504 26.594 27.2894C26.594 27.7549 26.7849 28.1766 27.0924 28.4805L26.5162 29.6496C26.387 29.9118 26.4948 30.2291 26.757 30.3583C27.0192 30.4875 27.3365 30.3799 27.4658 30.1175L28.0416 28.9491C28.1162 28.9593 28.1922 28.965 28.2695 28.965C29.1935 28.965 29.9452 28.2133 29.9452 27.2893C29.9453 26.5504 29.4642 25.9221 28.7989 25.6999ZM28.2697 27.9065C27.9294 27.9065 27.6527 27.6297 27.6527 27.2895C27.6527 26.9492 27.9294 26.6724 28.2697 26.6724C28.6099 26.6724 28.8867 26.9492 28.8867 27.2895C28.8867 27.6297 28.6099 27.9065 28.2697 27.9065Z"
        fill={colour}
      />
    </svg>
  )
}

CalendarIcon.defaultProps = {
  colour: '#000000',
}

CalendarIcon.propTypes = {
  colour: PropTypes.string,
}

export default CalendarIcon
