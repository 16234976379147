import React from 'react'
import PropTypes from 'prop-types'

function SlideShowIcon({ colour }, props) {
  return (
    <svg
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M32.7148 12.0748H24.0767C23.7854 12.0748 23.5493 12.3109 23.5493 12.6021C23.5493 12.8934 23.7854 13.1295 24.0767 13.1295H32.7148C33.0061 13.1295 33.2422 12.8934 33.2422 12.6021C33.2422 12.3109 33.0061 12.0748 32.7148 12.0748Z"
        fill={colour}
      />
      <path
        d="M32.7148 15.2894H24.0767C23.7854 15.2894 23.5493 15.5255 23.5493 15.8167C23.5493 16.108 23.7854 16.3441 24.0767 16.3441H32.7148C33.0061 16.3441 33.2422 16.108 33.2422 15.8167C33.2422 15.5255 33.0061 15.2894 32.7148 15.2894Z"
        fill={colour}
      />
      <path
        d="M33.2421 19.0313C33.2421 18.7401 33.0061 18.504 32.7148 18.504H24.0767C23.7854 18.504 23.5493 18.7401 23.5493 19.0313C23.5493 19.3226 23.7854 19.5587 24.0767 19.5587H32.7148C33.0061 19.5587 33.2421 19.3226 33.2421 19.0313Z"
        fill={colour}
      />
      <path
        d="M24.0767 21.7187C23.7854 21.7187 23.5493 21.9548 23.5493 22.246C23.5493 22.5373 23.7854 22.7734 24.0767 22.7734H28.3957C28.6871 22.7734 28.9231 22.5373 28.9231 22.246C28.9231 21.9548 28.6871 21.7187 28.3957 21.7187H24.0767Z"
        fill={colour}
      />
      <path
        d="M35.9844 28.5508V26.7029C35.9844 26.4117 35.7483 26.1755 35.457 26.1755C35.1657 26.1755 34.9297 26.4117 34.9297 26.7029V28.5208H5.07034V6.18254H7.27344C7.56468 6.18254 7.80079 5.94643 7.80079 5.6552C7.80079 5.36396 7.56468 5.12785 7.27344 5.12785H4.36651C3.79494 5.12785 3.3299 4.66287 3.3299 4.09123C3.3299 3.51959 3.79494 3.05469 4.36651 3.05469H35.6335C36.2051 3.05469 36.6701 3.51966 36.6701 4.0913C36.6701 4.66295 36.2051 5.12792 35.6335 5.12792H9.75639C9.46515 5.12792 9.22904 5.36403 9.22904 5.65527C9.22904 5.9465 9.46515 6.18261 9.75639 6.18261H34.9297V24.2376C34.9297 24.5288 35.1657 24.7649 35.457 24.7649C35.7483 24.7649 35.9844 24.5288 35.9844 24.2376V6.15259C36.971 5.9851 37.7248 5.1249 37.7248 4.0913C37.7248 2.93818 36.7866 2 35.6335 2H4.36651C3.21339 2 2.27521 2.93811 2.27521 4.0913C2.27521 5.12483 3.02903 5.9851 4.01565 6.15259V28.5508C3.02903 28.7183 2.27521 29.5785 2.27521 30.6121C2.27521 31.7653 3.21339 32.7034 4.36651 32.7034H19.4727V34.1678C18.6515 34.3985 18.0474 35.1535 18.0474 36.0475C18.0474 37.124 18.9233 38 20 38C21.0766 38 21.9527 37.124 21.9527 36.0474C21.9527 35.1534 21.3486 34.3984 20.5273 34.1677V32.7034H35.6335C36.7866 32.7034 37.7248 31.7652 37.7248 30.6121C37.7248 29.5785 36.971 28.7183 35.9844 28.5508ZM20.898 36.0474C20.898 36.5425 20.4951 36.9453 20 36.9453C19.5049 36.9453 19.102 36.5425 19.102 36.0474C19.102 35.5522 19.5049 35.1494 20 35.1494C20.4951 35.1495 20.898 35.5523 20.898 36.0474ZM35.6335 31.6487H4.36651C3.79494 31.6487 3.3299 31.1837 3.3299 30.6121C3.3299 30.0404 3.79494 29.5754 4.36651 29.5754H35.6335C36.2051 29.5754 36.6701 30.0404 36.6701 30.6121C36.6701 31.1837 36.2051 31.6487 35.6335 31.6487Z"
        fill={colour}
      />
      <path
        d="M6.75793 17.424C6.75793 21.4682 10.0481 24.7584 14.0922 24.7584C17.8938 24.7584 21.1247 21.8328 21.4064 17.9632C21.4064 17.9632 21.4064 17.9631 21.4064 17.963C21.7165 13.6819 18.3187 10.0897 14.0922 10.0897C10.0481 10.0897 6.75793 13.3799 6.75793 17.424ZM14.0923 23.7037C10.6297 23.7037 7.81269 20.8867 7.81269 17.424C7.81269 14.1389 10.3481 11.4349 13.565 11.1663V17.424C13.565 17.5512 13.6133 17.6767 13.6942 17.7699L17.7983 22.4937C16.7271 23.2773 15.4299 23.7037 14.0923 23.7037ZM18.5946 21.802L16.409 19.2865L15.3296 18.0441L20.2946 18.4104C20.0934 19.6934 19.5038 20.8697 18.5946 21.802ZM20.3704 17.3585L14.6196 16.9342V11.1663C17.815 11.4332 20.3368 14.1032 20.3704 17.3585Z"
        fill={colour}
      />
    </svg>
  )
}

SlideShowIcon.defaultProps = {
  colour: '#000000',
}

SlideShowIcon.propTypes = {
  colour: PropTypes.string,
}

export default SlideShowIcon
