import React from 'react'
import PropTypes from 'prop-types'

function AlertIcon({ colour }, props) {
  return (
    <svg
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M35.3749 18.1249C34.7539 18.1249 34.2499 17.6209 34.2499 16.9999C34.2499 12.6919 32.5731 8.64343 29.528 5.59692C29.0885 5.15747 29.0885 4.44501 29.528 4.00555C29.9674 3.5661 30.6799 3.5661 31.1196 4.00555C34.5891 7.47641 36.4999 12.092 36.4999 16.9999C36.4999 17.6209 35.9959 18.1249 35.3749 18.1249Z"
        fill={colour}
      />
      <path
        d="M4.625 18.1249C4.004 18.1249 3.5 17.6209 3.5 16.9999C3.5 12.092 5.41107 7.47641 8.88193 4.00693C9.32138 3.56747 10.0341 3.56747 10.4736 4.00693C10.913 4.44638 10.913 5.15912 10.4736 5.59857C7.42706 8.64343 5.75 12.6919 5.75 16.9999C5.75 17.6209 5.246 18.1249 4.625 18.1249Z"
        fill={colour}
      />
      <path
        d="M20.0001 38C16.8981 38 14.3751 35.477 14.3751 32.375C14.3751 31.754 14.8791 31.25 15.5001 31.25C16.1211 31.25 16.6251 31.754 16.6251 32.375C16.6251 34.2366 18.1385 35.75 20.0001 35.75C21.8615 35.75 23.3751 34.2366 23.3751 32.375C23.3751 31.754 23.8791 31.25 24.5001 31.25C25.1211 31.25 25.6251 31.754 25.6251 32.375C25.6251 35.477 23.1021 38 20.0001 38Z"
        fill={colour}
      />
      <path
        d="M32.3751 33.5H7.62506C6.17734 33.5 4.99988 32.3225 4.99988 30.8751C4.99988 30.1069 5.33441 29.3796 5.91806 28.88C8.19937 26.9524 9.49988 24.1506 9.49988 21.1821V16.9999C9.49988 11.2101 14.21 6.5 20.0001 6.5C25.7899 6.5 30.5 11.2101 30.5 16.9999V21.1821C30.5 24.1506 31.8005 26.9524 34.0669 28.8695C34.6654 29.3796 35 30.1069 35 30.8751C35 32.3225 33.8225 33.5 32.3751 33.5ZM20.0001 8.75C15.4503 8.75 11.7499 12.4505 11.7499 16.9999V21.1821C11.7499 24.8134 10.1585 28.2425 7.38501 30.587C7.33255 30.632 7.24988 30.7251 7.24988 30.8751C7.24988 31.0789 7.42099 31.25 7.62506 31.25H32.3751C32.5789 31.25 32.75 31.0789 32.75 30.8751C32.75 30.7251 32.6676 30.632 32.6179 30.59C29.8413 28.2425 28.25 24.8134 28.25 21.1821V16.9999C28.25 12.4505 24.5495 8.75 20.0001 8.75Z"
        fill={colour}
      />
      <path
        d="M20.0001 8.75C19.3791 8.75 18.8751 8.246 18.8751 7.625V3.125C18.8751 2.504 19.3791 2 20.0001 2C20.6211 2 21.1251 2.504 21.1251 3.125V7.625C21.1251 8.246 20.6211 8.75 20.0001 8.75Z"
        fill={colour}
      />
    </svg>
  )
}

AlertIcon.defaultProps = {
  colour: '#000000',
}

AlertIcon.propTypes = {
  colour: PropTypes.string,
}

export default AlertIcon
