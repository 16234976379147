import React from 'react'
import PropTypes from 'prop-types'

function AcceptPaymentIcon({ colour }, props) {
  return (
    <svg
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M20.418 21.6472C20.3514 21.6328 20.2831 21.6302 20.2156 21.6403C19.5395 21.5616 19.0294 20.9895 19.0282 20.309C19.0282 19.9829 18.7638 19.7185 18.4377 19.7185C18.1116 19.7185 17.8472 19.9829 17.8472 20.309C17.849 21.4454 18.609 22.4407 19.7047 22.7414V23.3319C19.7047 23.658 19.9691 23.9224 20.2952 23.9224C20.6213 23.9224 20.8857 23.658 20.8857 23.3319V22.7772C22.1503 22.5125 23.0098 21.3346 22.8769 20.0495C22.7437 18.7644 21.661 17.7879 20.369 17.7876C19.6285 17.7876 19.0282 17.1873 19.0282 16.4468C19.0282 15.7064 19.6285 15.1061 20.369 15.1061C21.1094 15.1061 21.7097 15.7064 21.7097 16.4468C21.7097 16.7729 21.9741 17.0373 22.3002 17.0373C22.6263 17.0373 22.8907 16.7729 22.8907 16.4468C22.889 15.2537 22.0531 14.2247 20.8857 13.9784V13.441C20.8857 13.1149 20.6213 12.8505 20.2952 12.8505C19.9691 12.8505 19.7047 13.1149 19.7047 13.441V14.0145C18.4862 14.3472 17.7019 15.5302 17.8697 16.7822C18.0375 18.0341 19.1058 18.9686 20.369 18.9683C21.0996 18.9683 21.6956 19.5533 21.7089 20.2837C21.7224 21.014 21.1481 21.6207 20.418 21.6472Z"
        fill={colour}
      />
      <path
        d="M11.8059 18.4234C11.8059 23.1526 15.6396 26.9862 20.3688 26.9862C25.098 26.9862 28.9317 23.1526 28.9317 18.4234C28.9317 13.6941 25.098 9.86047 20.3688 9.86047C15.6419 9.86566 11.8114 13.6964 11.8059 18.4234ZM20.3688 11.0415C24.4458 11.0415 27.7507 14.3463 27.7507 18.4234C27.7507 22.5004 24.4458 25.8052 20.3688 25.8052C16.2918 25.8052 12.9869 22.5004 12.9869 18.4234C12.9915 14.3484 16.2938 11.0461 20.3688 11.0415Z"
        fill={colour}
      />
      <path
        d="M31.8428 28.4421L27.217 30.6276C26.5784 29.4397 25.3533 28.6837 24.005 28.6456L19.7348 28.5283C19.0587 28.509 18.3952 28.3377 17.7941 28.0272L17.3596 27.802C15.1348 26.6423 12.483 26.6452 10.2605 27.8095L10.2876 26.8251C10.2966 26.499 10.0397 26.2274 9.71356 26.2185L5.03104 26.0896C4.70494 26.0806 4.43362 26.3375 4.42468 26.6637L4.14183 36.9392C4.13289 37.2653 4.38979 37.5369 4.7159 37.5459L9.39841 37.6748H9.41456C9.73461 37.6748 9.99641 37.4202 10.0051 37.1004L10.0186 36.6088L11.2351 35.9575C11.7126 35.7006 12.2708 35.6394 12.7924 35.7871L20.0566 37.8259C20.0693 37.8296 20.082 37.8325 20.095 37.8354C20.62 37.9452 21.1552 38.0003 21.6915 38C22.8275 38.0006 23.9502 37.7558 24.9828 37.2818C25.0081 37.2702 25.0326 37.2567 25.056 37.2414L35.5885 30.4287C35.8509 30.2589 35.9356 29.9143 35.782 29.6421C35 28.2547 33.2657 27.7262 31.8428 28.4421ZM5.33869 36.3816L5.58897 27.2864L9.09076 27.3828L8.84049 36.4782L5.33869 36.3816ZM24.4508 36.2271C23.168 36.806 21.7353 36.9658 20.3565 36.6841L13.1118 34.6505C12.2964 34.4201 11.4237 34.5155 10.6777 34.9166L10.0561 35.2496L10.2225 29.2024C12.1923 27.8922 14.7187 27.7576 16.8166 28.8507L17.2511 29.0758C18.0106 29.468 18.8482 29.6842 19.7025 29.709L23.973 29.8264C25.1725 29.861 26.1912 30.715 26.4345 31.8902L20.0736 31.7147C19.7475 31.7057 19.4762 31.9629 19.467 32.2887C19.458 32.6148 19.7152 32.8864 20.041 32.8954L27.0608 33.0886H27.0772C27.397 33.0883 27.6585 32.8337 27.6671 32.5142C27.6743 32.2576 27.6553 32.0007 27.61 31.7481L32.3546 29.5063C32.3592 29.5043 32.3638 29.502 32.3684 29.4997C33.0414 29.1592 33.855 29.2731 34.4086 29.7854L24.4508 36.2271Z"
        fill={colour}
      />
      <path
        d="M20.9593 8.20029V2.5905C20.9593 2.2644 20.6949 2 20.3688 2C20.0427 2 19.7783 2.2644 19.7783 2.5905V8.20029C19.7783 8.5264 20.0427 8.7908 20.3688 8.7908C20.6949 8.7908 20.9593 8.5264 20.9593 8.20029Z"
        fill={colour}
      />
      <path
        d="M25.388 8.20029V5.54302C25.388 5.21692 25.1236 4.95251 24.7975 4.95251C24.4714 4.95251 24.207 5.21692 24.207 5.54302V8.20029C24.207 8.52639 24.4714 8.79079 24.7975 8.79079C25.1236 8.79079 25.388 8.52639 25.388 8.20029Z"
        fill={colour}
      />
      <path
        d="M16.5306 8.20029V5.54302C16.5306 5.21692 16.2662 4.95251 15.9401 4.95251C15.614 4.95251 15.3496 5.21692 15.3496 5.54302V8.20029C15.3496 8.52639 15.614 8.79079 15.9401 8.79079C16.2662 8.79079 16.5306 8.52639 16.5306 8.20029Z"
        fill={colour}
      />
    </svg>
  )
}

AcceptPaymentIcon.defaultProps = {
  colour: '#000000',
}

AcceptPaymentIcon.propTypes = {
  colour: PropTypes.string,
}

export default AcceptPaymentIcon
