import React from 'react'
import PropTypes from 'prop-types'

function ToggleOffIcon({ colour }, props) {
  return (
    <svg
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.0921 14.4265C9.01892 14.4265 6.51868 16.9268 6.51868 20C6.51868 23.0732 9.01892 25.5735 12.0921 25.5735C15.1654 25.5735 17.6656 23.0732 17.6656 20C17.6656 16.9268 15.1654 14.4265 12.0921 14.4265ZM12.0921 23.4641C10.182 23.4641 8.62805 21.9101 8.62805 20C8.62805 18.0899 10.182 16.5359 12.0921 16.5359C14.0022 16.5359 15.5562 18.0899 15.5562 20C15.5562 21.9101 14.0022 23.4641 12.0921 23.4641Z"
        fill={colour}
      />
      <path
        d="M27.9078 9.90784H12.0922C6.52735 9.90784 2 14.4351 2 20C2 25.5649 6.52735 30.0922 12.0922 30.0922H27.9078C33.4726 30.0922 38 25.5649 38 20C38 14.4351 33.4726 9.90784 27.9078 9.90784ZM27.9078 27.9828H12.0922C7.69046 27.9828 4.10938 24.4018 4.10938 20C4.10938 15.5982 7.69046 12.0172 12.0922 12.0172H27.9078C32.3095 12.0172 35.8906 15.5982 35.8906 20C35.8906 24.4018 32.3095 27.9828 27.9078 27.9828Z"
        fill={colour}
      />
    </svg>
  )
}

ToggleOffIcon.defaultProps = {
  colour: '#000000',
}

ToggleOffIcon.propTypes = {
  colour: PropTypes.string,
}

export default ToggleOffIcon
