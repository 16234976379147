import React from 'react'
import { useSelector } from 'react-redux'
import {
  selectActiveClient,
  selectActiveOperatingLocation,
} from '../redux/user/userSelections'
import Header from '../components/global-components/structure/header/Header'
import WhatsNewCards from '../components/consumer-flow/whats-new/WhatsNewCards'
import { Outlet } from 'react-router-dom'
import TabNavigation from '../components/consumer-flow/structure/tab-navigation/TabNavigation'
import routes from '../configuration/routes'
import '../styles/editor-flow/find-replace/find-replace.scss'
function FindReplaceContainer() {
  const activeClient = useSelector(selectActiveClient)
  const activeOperatingLocation = useSelector(selectActiveOperatingLocation)

  return (
    <div className="find-replace view-container">
      <Header
        activeClient={activeClient}
        activeOperatingLocation={activeOperatingLocation}
        enableBreadCrumbs={true}
        title="Find and Replace"
      />

      <Outlet />
    </div>
  )
}

export const FindReplaceTabs = [
  {
    link: routes.findReplace,
    label: 'Replace Text',
  },
  {
    link: `${routes.findReplace}/external-link`,
    label: 'External Link',
  },
  {
    link: `${routes.findReplace}/internal-link`,
    label: 'Internal Link',
  },
]

export const AUTO_CLOSE_WINDOW_HEIGHT = 825

export default FindReplaceContainer
