import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { get, isEmpty } from 'lodash'
import BrowseWorkflowStepsCards from './elements/BrowseWorkflowStepsCards'
import useEntryHighlight from '../../../hooks/useEntryHighlight'
import highlightTextInHTML from '../../../utilities/find-replace/highlightTextInHTML'

function BrowseWorkflowSteps({ activeWorkflow, activeWorkflowContent }) {
  const ref = useRef()
  const highlight = useEntryHighlight()
  const workflowName = get(activeWorkflow, 'name', null)
  const workflowDescription = get(activeWorkflow, 'content.description', null)
  const workflowSteps = get(activeWorkflowContent, 'steps', [])
  const hasInnerText = !!ref?.current?.innerText

  useEffect(
    function highlightResultsOnSearch() {
      if (highlight?.itemType === 'workflow' && hasInnerText) {
        highlightTextInHTML({ ref, highlight })
      }
    },
    [highlight, hasInnerText],
  )

  return (
    <div className="browse-workflow content">
      <div className="browse-workflow__heading" ref={ref}>
        <h2>{workflowName}</h2>
        {workflowDescription ? <p>{workflowDescription}</p> : null}
      </div>

      {!isEmpty(workflowSteps) ? (
        <BrowseWorkflowStepsCards steps={workflowSteps} />
      ) : null}
    </div>
  )
}

BrowseWorkflowSteps.propTypes = {
  activeWorkflow: PropTypes.object,
  activeWorkflowContent: PropTypes.object,
}

export default BrowseWorkflowSteps
