import React from 'react'
import PropTypes from 'prop-types'

function StormCloudIcon({ colour }, props) {
  return (
    <svg
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M37.0255 21.5803C36.6639 21.439 36.2561 21.6176 36.1146 21.9793C35.0531 24.6955 32.4829 26.4505 29.5667 26.4505H8.50109C5.69183 26.4505 3.40625 24.1649 3.40625 21.3556C3.40625 18.5463 5.69183 16.2608 8.50109 16.2608C8.8355 16.2608 9.12364 16.0252 9.19016 15.6975C10.0329 11.5447 13.7257 8.53061 17.9708 8.53061C19.8329 8.53061 21.5934 9.08587 23.0989 10.1419L21.3916 13.0846C21.2654 13.3022 21.265 13.5705 21.3905 13.7884C21.5159 14.0063 21.7483 14.1406 21.9998 14.1406H24.2732V19.4748C24.2732 19.7928 24.4866 20.0711 24.7936 20.1538C24.8543 20.1701 24.9157 20.178 24.9764 20.178C25.2225 20.178 25.4565 20.0483 25.5845 19.8277L29.8918 12.4038C32.6728 12.5289 35.093 14.2519 36.1147 16.8662C36.2232 17.1438 36.4886 17.3136 36.7697 17.3135C36.8548 17.3135 36.9414 17.2979 37.0255 17.265C37.3872 17.1237 37.5658 16.7159 37.4244 16.3542C36.8135 14.791 35.7596 13.4564 34.3767 12.4946C33.2647 11.7213 31.9997 11.236 30.6698 11.0627L31.0454 10.4153C31.1716 10.1977 31.172 9.92941 31.0465 9.71151C30.9211 9.49361 30.6887 9.35931 30.4373 9.35931H28.1637V4.02506C28.1637 3.7071 27.9503 3.42874 27.6433 3.34605C27.3363 3.26371 27.012 3.39717 26.8524 3.67223L23.8061 8.92296C22.0949 7.75858 20.0521 7.12436 17.9708 7.12436C15.5654 7.12436 13.2214 7.96685 11.3705 9.49656C9.68748 10.8876 8.48225 12.7826 7.93677 14.8788C4.61527 15.1658 2 17.961 2 21.3556C2 24.9404 4.91642 27.8567 8.50109 27.8567H29.5667C31.2974 27.8567 32.9607 27.336 34.3767 26.3511C35.7596 25.3892 36.8135 24.0545 37.4245 22.4912C37.5658 22.1295 37.3872 21.7217 37.0255 21.5803ZM27.4605 10.7656H29.2164L25.6794 16.8618V13.4374C25.6794 13.0491 25.3646 12.7343 24.9763 12.7343H23.2205L26.7574 6.63808V10.0624C26.7574 10.4508 27.0722 10.7656 27.4605 10.7656Z"
        fill={colour}
      />
      <path
        d="M10.8594 29.2626C10.4711 29.2626 10.1562 29.5774 10.1562 29.9657V31.38C10.1562 31.7683 10.4711 32.0831 10.8594 32.0831C11.2477 32.0831 11.5625 31.7683 11.5625 31.38V29.9657C11.5625 29.5774 11.2477 29.2626 10.8594 29.2626Z"
        fill={colour}
      />
      <path
        d="M15.4297 29.2626C15.0414 29.2626 14.7266 29.5774 14.7266 29.9657V31.38C14.7266 31.7683 15.0414 32.0831 15.4297 32.0831C15.818 32.0831 16.1328 31.7683 16.1328 31.38V29.9657C16.1328 29.5774 15.818 29.2626 15.4297 29.2626Z"
        fill={colour}
      />
      <path
        d="M13.1445 33.6111C12.7562 33.6111 12.4414 33.9259 12.4414 34.3142V35.9749C12.4414 36.3633 12.7562 36.678 13.1445 36.678C13.5329 36.678 13.8477 36.3633 13.8477 35.9749V34.3142C13.8477 33.9259 13.5329 33.6111 13.1445 33.6111Z"
        fill={colour}
      />
      <path
        d="M17.7148 33.6111C17.3265 33.6111 17.0117 33.9259 17.0117 34.3142V35.9749C17.0117 36.3633 17.3265 36.678 17.7148 36.678C18.1032 36.678 18.418 36.3633 18.418 35.9749V34.3142C18.418 33.9259 18.1032 33.6111 17.7148 33.6111Z"
        fill={colour}
      />
      <path
        d="M20 29.2706C19.6117 29.2706 19.2969 29.5854 19.2969 29.9737V31.38C19.2969 31.7683 19.6117 32.0831 20 32.0831C20.3883 32.0831 20.7031 31.7683 20.7031 31.38V29.9737C20.7031 29.5854 20.3883 29.2706 20 29.2706Z"
        fill={colour}
      />
      <path
        d="M24.5703 29.2706C24.182 29.2706 23.8672 29.5854 23.8672 29.9737V31.38C23.8672 31.7683 24.182 32.0831 24.5703 32.0831C24.9586 32.0831 25.2734 31.7683 25.2734 31.38V29.9737C25.2734 29.5854 24.9586 29.2706 24.5703 29.2706Z"
        fill={colour}
      />
      <path
        d="M29.1406 29.2706C28.7524 29.2706 28.4375 29.5854 28.4375 29.9737V31.38C28.4375 31.7683 28.7524 32.0831 29.1406 32.0831C29.5289 32.0831 29.8438 31.7683 29.8438 31.38V29.9737C29.8438 29.5854 29.5289 29.2706 29.1406 29.2706Z"
        fill={colour}
      />
      <path
        d="M22.2852 33.8655C21.8969 33.8655 21.582 34.1803 21.582 34.5687V35.9749C21.582 36.3633 21.8969 36.678 22.2852 36.678C22.6734 36.678 22.9883 36.3633 22.9883 35.9749V34.5687C22.9883 34.1803 22.6734 33.8655 22.2852 33.8655Z"
        fill={colour}
      />
      <path
        d="M26.8555 33.8655C26.4672 33.8655 26.1523 34.1803 26.1523 34.5687V35.9749C26.1523 36.3633 26.4672 36.678 26.8555 36.678C27.2437 36.678 27.5586 36.3633 27.5586 35.9749V34.5687C27.5586 34.1803 27.2437 33.8655 26.8555 33.8655Z"
        fill={colour}
      />
      <path
        d="M37.2969 20.1256C37.6852 20.1256 38 19.8108 38 19.4224C38 19.0341 37.6852 18.7193 37.2969 18.7193C36.9085 18.7193 36.5938 19.0341 36.5938 19.4224C36.5938 19.8108 36.9085 20.1256 37.2969 20.1256Z"
        fill={colour}
      />
    </svg>
  )
}

StormCloudIcon.defaultProps = {
  colour: '#000000',
}

StormCloudIcon.propTypes = {
  colour: PropTypes.string,
}

export default StormCloudIcon
