import React from 'react'
import PropTypes from 'prop-types'

function BasicHouseIcon({ colour }, props) {
  return (
    <svg
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M37.691 19.2542L20.7457 2.30887C20.3339 1.89704 19.6661 1.89704 19.2542 2.30887L2.30888 19.2542C2.11116 19.452 2 19.7202 2 20V36.9453C2 37.5278 2.47222 38 3.05469 38H15.4812C16.0637 38 16.5359 37.5278 16.5359 36.9453V29.0375C16.5359 27.1274 18.0899 25.5734 20 25.5734C21.9101 25.5734 23.4641 27.1274 23.4641 29.0375V36.9453C23.4641 37.5278 23.9363 38 24.5188 38H36.9453C37.5278 38 38 37.5278 38 36.9453V20C38 19.7202 37.8888 19.452 37.691 19.2542ZM35.8906 35.8906H25.5735V29.0375C25.5735 25.9642 23.0732 23.464 20 23.464C16.9268 23.464 14.4265 25.9642 14.4265 29.0375V35.8906H4.10938V20.4368L20 4.54621L35.8906 20.4368V35.8906Z"
        fill={colour}
      />
    </svg>
  )
}

BasicHouseIcon.defaultProps = {
  colour: '#000000',
}

BasicHouseIcon.propTypes = {
  colour: PropTypes.string,
}

export default BasicHouseIcon
