import React from 'react'
import PropTypes from 'prop-types'

function SnowCloudIcon({ colour }, props) {
  return (
    <svg
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M29.5664 24.7864H8.50102C4.91635 24.7864 2 21.8702 2 18.2855C2 14.8909 4.61541 12.0957 7.93691 11.8087C8.68616 8.93611 10.6802 6.46764 13.3565 5.13515C13.7041 4.96211 14.1262 5.10358 14.2993 5.45121C14.4723 5.79883 14.3308 6.22092 13.9832 6.39403C11.5259 7.61747 9.73402 9.94776 9.19009 12.6274C9.12364 12.9552 8.83543 13.1907 8.50102 13.1907C5.69176 13.1907 3.40625 15.4762 3.40625 18.2855C3.40625 21.0948 5.69176 23.3803 8.50102 23.3803H29.5664C33.4413 23.3803 36.5938 20.2278 36.5938 16.3529C36.5938 12.478 33.4413 9.3255 29.5664 9.3255C28.5001 9.3255 27.4763 9.55809 26.5233 10.0167C26.1981 10.1731 25.8071 10.0581 25.6187 9.75018C24.2076 7.44464 21.8702 5.91182 19.2062 5.54458C18.8214 5.49157 18.5526 5.13677 18.6056 4.75209C18.6587 4.36741 19.0142 4.09861 19.3981 4.15155C22.2785 4.54854 24.8281 6.12185 26.4819 8.50101C27.4618 8.11464 28.4966 7.91925 29.5664 7.91925C34.2167 7.91925 38 11.7026 38 16.3528C38 21.0031 34.2167 24.7864 29.5664 24.7864Z"
        fill={colour}
      />
      <path
        d="M30.5469 30.4114C29.3838 30.4114 28.4375 29.4652 28.4375 28.3021C28.4375 27.139 29.3838 26.1927 30.5469 26.1927C31.71 26.1927 32.6562 27.139 32.6562 28.3021C32.6562 29.4652 31.71 30.4114 30.5469 30.4114ZM30.5469 27.5989C30.1592 27.5989 29.8438 27.9144 29.8438 28.3021C29.8438 28.6898 30.1592 29.0052 30.5469 29.0052C30.9346 29.0052 31.25 28.6898 31.25 28.3021C31.25 27.9144 30.9346 27.5989 30.5469 27.5989Z"
        fill={colour}
      />
      <path
        d="M23.5156 30.4114C22.3525 30.4114 21.4062 29.4652 21.4062 28.3021C21.4062 27.139 22.3525 26.1927 23.5156 26.1927C24.6787 26.1927 25.625 27.139 25.625 28.3021C25.625 29.4652 24.6787 30.4114 23.5156 30.4114ZM23.5156 27.5989C23.1279 27.5989 22.8125 27.9144 22.8125 28.3021C22.8125 28.6898 23.1279 29.0052 23.5156 29.0052C23.9033 29.0052 24.2188 28.6898 24.2188 28.3021C24.2188 27.9144 23.9033 27.5989 23.5156 27.5989Z"
        fill={colour}
      />
      <path
        d="M16.4844 30.4114C15.3213 30.4114 14.375 29.4652 14.375 28.3021C14.375 27.139 15.3213 26.1927 16.4844 26.1927C17.6475 26.1927 18.5938 27.139 18.5938 28.3021C18.5938 29.4652 17.6475 30.4114 16.4844 30.4114ZM16.4844 27.5989C16.0967 27.5989 15.7812 27.9144 15.7812 28.3021C15.7812 28.6898 16.0967 29.0052 16.4844 29.0052C16.8721 29.0052 17.1875 28.6898 17.1875 28.3021C17.1875 27.9144 16.8721 27.5989 16.4844 27.5989Z"
        fill={colour}
      />
      <path
        d="M9.45312 30.4114C8.29002 30.4114 7.34375 29.4652 7.34375 28.3021C7.34375 27.139 8.29002 26.1927 9.45312 26.1927C10.6162 26.1927 11.5625 27.139 11.5625 28.3021C11.5625 29.4652 10.6162 30.4114 9.45312 30.4114ZM9.45312 27.5989C9.06542 27.5989 8.75 27.9144 8.75 28.3021C8.75 28.6898 9.06542 29.0052 9.45312 29.0052C9.84083 29.0052 10.1562 28.6898 10.1562 28.3021C10.1562 27.9144 9.84083 27.5989 9.45312 27.5989Z"
        fill={colour}
      />
      <path
        d="M27.0312 35.8551C25.8681 35.8551 24.9219 34.9088 24.9219 33.7457C24.9219 32.5826 25.8681 31.6364 27.0312 31.6364C28.1944 31.6364 29.1406 32.5826 29.1406 33.7457C29.1406 34.9088 28.1944 35.8551 27.0312 35.8551ZM27.0312 33.0426C26.6435 33.0426 26.3281 33.358 26.3281 33.7457C26.3281 34.1334 26.6435 34.4489 27.0312 34.4489C27.419 34.4489 27.7344 34.1334 27.7344 33.7457C27.7344 33.358 27.419 33.0426 27.0312 33.0426Z"
        fill={colour}
      />
      <path
        d="M20 35.8551C18.8369 35.8551 17.8906 34.9088 17.8906 33.7457C17.8906 32.5826 18.8369 31.6364 20 31.6364C21.1631 31.6364 22.1094 32.5826 22.1094 33.7457C22.1094 34.9088 21.1631 35.8551 20 35.8551ZM20 33.0426C19.6123 33.0426 19.2969 33.358 19.2969 33.7457C19.2969 34.1334 19.6123 34.4489 20 34.4489C20.3877 34.4489 20.7031 34.1334 20.7031 33.7457C20.7031 33.358 20.3877 33.0426 20 33.0426Z"
        fill={colour}
      />
      <path
        d="M12.9688 35.8551C11.8056 35.8551 10.8594 34.9088 10.8594 33.7457C10.8594 32.5826 11.8056 31.6364 12.9688 31.6364C14.1319 31.6364 15.0781 32.5826 15.0781 33.7457C15.0781 34.9088 14.1319 35.8551 12.9688 35.8551ZM12.9688 33.0426C12.581 33.0426 12.2656 33.358 12.2656 33.7457C12.2656 34.1334 12.581 34.4489 12.9688 34.4489C13.3565 34.4489 13.6719 34.1334 13.6719 33.7457C13.6719 33.358 13.3565 33.0426 12.9688 33.0426Z"
        fill={colour}
      />
      <path
        d="M16.4844 5.57419C16.8727 5.57419 17.1875 5.25939 17.1875 4.87106C17.1875 4.48274 16.8727 4.16794 16.4844 4.16794C16.096 4.16794 15.7812 4.48274 15.7812 4.87106C15.7812 5.25939 16.096 5.57419 16.4844 5.57419Z"
        fill={colour}
      />
    </svg>
  )
}

SnowCloudIcon.defaultProps = {
  colour: '#000000',
}

SnowCloudIcon.propTypes = {
  colour: PropTypes.string,
}

export default SnowCloudIcon
