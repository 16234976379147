import useQueryParams from './useQueryParams'

const useEntryHighlight = () => {
  const { entry: entryJsonString } = useQueryParams()
  let entry
  try {
    entry = JSON.parse(entryJsonString)
  } catch (e) {}

  if (entry) {
    return {
      itemId: entry.itemId,
      index: entry.index,
      highlightIndex: entry.highlightIndex,
      cellIndex: entry.cellIndex,
      headerIndex: entry.headerIndex,
      rowIndex: entry.rowIndex,
      match: entry.match,
      find: new RegExp(entry.find, `g${entry.matchCase ? '' : 'i'}`),
      path: entry.path,
      type: entry.entryType === 'text' ? 'text' : 'link',
      itemType: entry.type,
      itemSubtype: entry.subType,
      subItemId: entry.subItemId,
    }
  }
}

export default useEntryHighlight
