import {
  selectSocketOutOfDateResources,
  SOCKET_SET_OUT_OF_DATE_RESOURCES,
} from '../../../socket/socket'
import { v4 as uuidv4 } from 'uuid'

const onResourceHasChanged = ({ state, dispatch, args }) => {
  const currentResources = selectSocketOutOfDateResources(state)
  dispatch({
    type: SOCKET_SET_OUT_OF_DATE_RESOURCES,
    payload: [
      ...currentResources,
      {
        ...args,
        uuid: uuidv4(),
        receivedAt: Date.now(),
        dismissed: false,
      },
    ],
  })
}

export default onResourceHasChanged
