import React from 'react'
import PropTypes from 'prop-types'

function HeadsetIcon({ colour }, props) {
  return (
    <svg
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M35.6 16.581H35C34.9961 15.7959 34.4838 15.1039 33.734 14.871C33.0405 7.8055 27.0994 2.41902 20 2.41902C12.9006 2.41902 6.95951 7.8055 6.266 14.871C5.51623 15.1039 5.00391 15.7959 5 16.581H4.4C3.07452 16.581 2 17.6555 2 18.981V23.781C2 25.1065 3.07452 26.181 4.4 26.181H5C5 27.1751 5.80589 27.981 6.8 27.981H7.4V30.381C7.4 33.3633 9.81766 35.781 12.8 35.781H14.684C14.9569 36.8378 15.9085 37.5774 17 37.581H23C24.3255 37.581 25.4 36.5065 25.4 35.181C25.4 33.8555 24.3255 32.781 23 32.781H17C15.9085 32.7845 14.9569 33.5241 14.684 34.581H12.8C10.4804 34.581 8.6 32.7006 8.6 30.381V27.981H9.2C10.1941 27.981 11 27.1751 11 26.181V16.581C10.9992 15.847 10.5529 15.187 9.872 14.913C10.4847 9.78408 14.8346 5.92277 20 5.92277C25.1654 5.92277 29.5154 9.78408 30.128 14.913C29.4471 15.187 29.0008 15.847 29 16.581V26.181C29 27.1751 29.8059 27.981 30.8 27.981H33.2C34.1941 27.981 35 27.1751 35 26.181H35.6C36.9255 26.181 38 25.1065 38 23.781V18.981C38 17.6555 36.9255 16.581 35.6 16.581ZM17 33.981H23C23.6627 33.981 24.2 34.5182 24.2 35.181C24.2 35.8437 23.6627 36.381 23 36.381H17C16.3373 36.381 15.8 35.8437 15.8 35.181C15.8 34.5182 16.3373 33.981 17 33.981ZM4.4 24.981C3.73726 24.981 3.2 24.4437 3.2 23.781V18.981C3.2 18.3182 3.73726 17.781 4.4 17.781H5V24.981H4.4ZM9.8 26.181C9.8 26.5123 9.53137 26.781 9.2 26.781H6.8C6.46863 26.781 6.2 26.5123 6.2 26.181V16.581C6.2 16.2496 6.46863 15.981 6.8 15.981H9.2C9.53137 15.981 9.8 16.2496 9.8 16.581V26.181ZM20 4.58097C14.1741 4.58847 9.29125 8.98743 8.678 14.781H7.478C8.19092 8.4036 13.5829 3.5808 20 3.5808C26.4171 3.5808 31.8091 8.4036 32.522 14.781H31.322C30.7088 8.98743 25.8259 4.58847 20 4.58097ZM33.8 26.181C33.8 26.5123 33.5314 26.781 33.2 26.781H30.8C30.4686 26.781 30.2 26.5123 30.2 26.181V16.581C30.2 16.2496 30.4686 15.981 30.8 15.981H33.2C33.5314 15.981 33.8 16.2496 33.8 16.581V26.181ZM36.8 23.781C36.8 24.4437 36.2627 24.981 35.6 24.981H35V17.781H35.6C36.2627 17.781 36.8 18.3182 36.8 18.981V23.781Z"
        fill={colour}
      />
    </svg>
  )
}

HeadsetIcon.defaultProps = {
  colour: '#000000',
}

HeadsetIcon.propTypes = {
  colour: PropTypes.string,
}

export default HeadsetIcon
