import { get } from 'lodash'
import routes from '../../configuration/routes'
import convertStringToSlug from '../slug/convertStringToSlug'

export default function getResultRouteDetails(item, operatingLocation) {
  const comparisonsRoot = 'https://comparisons.quickfactsinc.com'
  const workflowsRoot = 'https://workflows.quickfactsinc.com'
  const type = get(item, '__typename', null) || get(item, 'type', null)
  const slug = get(item, 'slug', null)
  const navigation = get(item, 'navigations[0].title', null)
  const carrier = get(item, 'carriers[0].name', null)
  const pageCategory = get(item, 'categories[0].title', null)
  const comparisonCategory = get(item, 'insuranceType[0].title', null)
  const workflowCategory = get(item, 'categories[0].category', null)

  return type === 'Comparison' && comparisonCategory && slug
    ? {
        root: comparisonsRoot,
        route: 'Comparisons',
        category: comparisonCategory,
        slug: slug,
        link: `${comparisonsRoot}/comparisons/${comparisonCategory.toLowerCase()}/${slug}?operatingLocation=${operatingLocation}`,
        external: true,
      }
    : type === 'Page' && navigation === 'Resources' && carrier && slug
    ? {
        root: comparisonsRoot,
        route: navigation,
        category: carrier,
        slug: slug,
        link: `${comparisonsRoot}/${convertStringToSlug(
          navigation,
        )}/${convertStringToSlug(
          carrier,
        )}/${slug}?operatingLocation=${operatingLocation}`,
        external: true,
      }
    : type === 'Page' && navigation !== 'Resources' && pageCategory && slug
    ? {
        root: comparisonsRoot,
        route: navigation,
        category: pageCategory,
        slug: slug,
        link: `${comparisonsRoot}/${convertStringToSlug(
          navigation,
        )}/${convertStringToSlug(
          pageCategory,
        )}/${slug}?operatingLocation=${operatingLocation}`,
        external: true,
      }
    : type === 'workflow' && workflowCategory && slug
    ? {
        root: workflowsRoot,
        route: 'Browse',
        category: workflowCategory,
        slug: slug,
        link: `${routes.browseWorkflows}/${convertStringToSlug(
          workflowCategory,
        )}/${slug}`,
        external: false,
      }
    : {
        root: null,
        route: null,
        category: null,
        slug: null,
        link: null,
        external: null,
      }
}
