import React from 'react'
import PropTypes from 'prop-types'

function PaperPlaneIcon({ colour }, props) {
  return (
    <svg
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M37.691 2.30896C37.4167 2.0346 37.0138 1.93307 36.6422 2.04451L4.37712 11.7241C2.95527 12.1506 2 13.4345 2 14.9194C2 16.3838 2.98332 17.6874 4.3914 18.0897L18.0172 21.9828L21.9102 35.6086C22.3126 37.0166 23.6162 38 25.0811 38C26.5655 38 27.8494 37.0447 28.276 35.6229L37.9555 3.35774C38.067 2.98614 37.9655 2.58332 37.691 2.30896ZM4.10937 14.9189C4.10937 14.3732 4.46051 13.9013 4.98322 13.7445L33.2415 5.26701L18.5634 19.9452L4.97084 16.0616C4.46368 15.9166 4.10937 15.447 4.10937 14.9189ZM26.2556 35.0168C26.0988 35.5395 25.6268 35.8906 25.0806 35.8906C24.553 35.8906 24.0834 35.5364 23.9385 35.0291L20.0549 21.4366L34.7331 6.75847L26.2556 35.0168Z"
        fill={colour}
      />
    </svg>
  )
}

PaperPlaneIcon.defaultProps = {
  colour: '#000000',
}

PaperPlaneIcon.propTypes = {
  colour: PropTypes.string,
}

export default PaperPlaneIcon
