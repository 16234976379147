import React from 'react'
import PropTypes from 'prop-types'

function MuteIcon({ colour }, props) {
  return (
    <svg
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M17.7408 33.4812C17.4663 33.4812 17.1966 33.3741 16.9948 33.1723L9.39589 25.5735H3.05469C2.47222 25.5735 2 25.1013 2 24.5188V15.4813C2 14.8988 2.47222 14.4266 3.05469 14.4266H9.39589L16.9948 6.82774C17.2963 6.5261 17.7499 6.43582 18.1442 6.59908C18.5383 6.76235 18.7953 7.14696 18.7953 7.57347V32.4265C18.7953 32.8531 18.5383 33.2377 18.1442 33.4009C18.0137 33.455 17.8766 33.4812 17.7408 33.4812ZM4.10937 23.4641H9.83274C10.1124 23.4641 10.3807 23.5752 10.5785 23.7731L16.6858 29.8803V10.1198L10.5785 16.227C10.3807 16.4248 10.1124 16.536 9.83274 16.536H4.10937V23.4641Z"
        fill={colour}
      />
      <path
        d="M32.3419 27.1364L29.8923 24.6869L27.4428 27.1364C26.1487 28.4305 24.0521 28.4327 22.756 27.1364C21.4638 25.8442 21.4638 23.7417 22.756 22.4496L25.2055 20L22.756 17.5505C21.4638 16.2583 21.4638 14.1558 22.756 12.8636C24.0498 11.5697 26.1464 11.5672 27.4428 12.8636L29.8923 15.3131L32.3419 12.8636C33.636 11.5694 35.7321 11.5674 37.0281 12.8636C38.3229 14.1576 38.325 16.2543 37.0279 17.5507L34.5791 20L37.0283 22.4496C38.3229 23.7434 38.325 25.8403 37.028 27.1367C35.7342 28.4304 33.6381 28.4327 32.3419 27.1364ZM30.6381 22.4496L33.8334 25.6448C34.3041 26.1154 35.0656 26.1158 35.5367 25.6448C36.0077 25.174 36.0082 24.4124 35.5369 23.9413L32.3419 20.7457C31.9301 20.3339 31.9301 19.6661 32.3419 19.2543L35.5367 16.059C36.0085 15.5873 36.0075 14.8257 35.5369 14.3554C35.0662 13.8847 34.3048 13.884 33.8335 14.3552L30.6382 17.5505C30.2264 17.9624 29.5585 17.9624 29.1467 17.5505L25.9514 14.3552C25.4808 13.8847 24.7187 13.8842 24.2476 14.3552C23.7779 14.8249 23.7779 15.5892 24.2476 16.0589L27.4429 19.2542C27.8548 19.6661 27.8548 20.3339 27.4429 20.7457L24.2476 23.941C23.7779 24.4108 23.7779 25.1751 24.2476 25.6448C24.7182 26.1153 25.4804 26.1158 25.9514 25.6448L29.1467 22.4495C29.5585 22.0376 30.2264 22.0377 30.6381 22.4496Z"
        fill={colour}
      />
    </svg>
  )
}

MuteIcon.defaultProps = {
  colour: '#000000',
}

MuteIcon.propTypes = {
  colour: PropTypes.string,
}

export default MuteIcon
