import PropTypes from 'prop-types'
import TipTapColorPicker from './TipTapColorPicker'
import TipTapDropdown from './TipTapDropdown'
import { ReactComponent as UndoIcon } from '../../../../assets/icons/undo-editor-icon.svg'
import { ReactComponent as RedoIcon } from '../../../../assets/icons/redo-editor-icon.svg'
import { ReactComponent as AlignLeftIcon } from '../../../../assets/icons/align-left-icon.svg'
import { ReactComponent as AlignCenterIcon } from '../../../../assets/icons/align-center-icon.svg'
import { ReactComponent as AlignRightIcon } from '../../../../assets/icons/align-right-icon.svg'
import { ReactComponent as BoldIcon } from '../../../../assets/icons/bold-editor-icon.svg'
import { ReactComponent as ItalicIcon } from '../../../../assets/icons/italic-editor-icon.svg'
import { ReactComponent as UnderlineIcon } from '../../../../assets/icons/underline-icon.svg'
import { ReactComponent as ClearStylesIcon } from '../../../../assets/icons/reset-styles-icon.svg'
import { ReactComponent as OrderedListIcon } from '../../../../assets/icons/ordered-list-icon.svg'
import { ReactComponent as UnorderedListIcon } from '../../../../assets/icons/unordered-list-icon.svg'
import { ReactComponent as IndentLeftIcon } from '../../../../assets/icons/indent-left-icon.svg'
import { ReactComponent as IndentRightIcon } from '../../../../assets/icons/indent-right-icon.svg'
import { ReactComponent as AccordionsIcon } from '../../../../assets/icons/accordion-tool-icon.svg'
import { ReactComponent as LinkIcon } from '../../../../assets/icons/link-icon.svg'

import clsx from 'clsx'
import { useState } from 'react'
import EditorLinkModal from '../../shared-components/editor-link-modal/EditorLinkModal'

const MenuBar = ({ editors = [] }) => {
  const [linkValues, setLinkValues] = useState(null)

  function processCan(can) {
    if (editors.length > 1) {
      let returnVal = true
      editors.forEach(editor => {
        returnVal = returnVal && can(editor?.can()?.chain()?.focus())
      })
      return returnVal
    } else {
      return can(editors?.[0]?.can()?.chain()?.focus())
    }
  }
  function isActive(params, options = {}) {
    return editors.length > 0
      ? editors.reduce((acc, cur) => {
          return acc && cur?.isActive(params, options)
        }, true)
      : false
  }

  function hasLink() {
    return editors.length > 0
      ? editors.reduce((acc, cur) => {
          return acc && cur?.getAttributes('link')?.type
        }, true)
      : false
  }

  function getAlignmentIcon() {
    if (isActive({ textAlign: 'center' })) {
      return <AlignCenterIcon />
    }
    if (isActive({ textAlign: 'right' })) {
      return <AlignRightIcon />
    }
    return <AlignLeftIcon />
  }

  function getFormatValue() {
    let value = 'Paragraph'
    for (let i = 1; i < 7; i++) {
      if (isActive('heading', { level: i })) {
        value = `Heading ${i}`
      }
    }

    return <div>{value}</div>
  }

  function handleColorChange(color) {
    processAction(editor => editor.setColor(color).run())
  }

  function handleLinkClick() {
    const values = editors?.length > 0 ? editors[0]?.getAttributes('link') : {}
    setLinkValues(values)
  }

  function processAction(action) {
    if (editors.length > 1) {
      editors.forEach(editor => action(editor.chain().selectAll(), editor))
    } else {
      action(editors?.[0]?.chain()?.focus(), editors[0])
    }
  }

  function handleLinkModalSubmit(values) {
    if (values === null) {
      processAction((chain, editor) => {
        if (editor.view.state.selection.empty) {
          chain.extendMarkRange('link').unsetLink().run()
        } else {
          chain.unsetMark('link').setMeta('preventAutolink', true).run()
        }
      })
    } else {
      processAction(editor =>
        editor
          .extendMarkRange('link')
          .setLink({ ...values, href: 'temp' })
          .run(),
      )
    }
    setLinkValues(null)
  }

  return (
    <div className="tiptap-menu-bar">
      <button
        onClick={() => processAction(editor => editor.undo().run())}
        disabled={!processCan(editor => editor?.undo()?.run())}
        data-tooltip="Undo"
      >
        <UndoIcon />
      </button>
      <button
        onClick={() => processAction(editor => editor.redo().run())}
        disabled={!processCan(editor => editor?.redo()?.run())}
        data-tooltip="Redo"
      >
        <RedoIcon />
      </button>
      <div className="tiptap-menu-bar__divider"></div>

      <button
        onClick={() => processAction(editor => editor.toggleBold().run())}
        disabled={!processCan(editor => editor?.toggleBold()?.run())}
        className={isActive('bold') ? 'is-active' : ''}
        data-tooltip="Bold"
      >
        <BoldIcon />
      </button>
      <button
        onClick={() => processAction(editor => editor.toggleItalic().run())}
        disabled={!processCan(editor => editor?.toggleItalic().run())}
        className={isActive('italic') ? 'is-active' : ''}
        data-tooltip="Italic"
      >
        <ItalicIcon />
      </button>
      <button
        onClick={() => processAction(editor => editor.toggleUnderline().run())}
        disabled={!processCan(editor => editor?.toggleUnderline().run())}
        className={isActive('underline') ? 'is-active' : ''}
        data-tooltip="Underline"
      >
        <UnderlineIcon height="15" />
      </button>
      <TipTapDropdown
        button={getFormatValue()}
        buttonClass="content-width"
        disabled={!processCan(editor => editor?.toggleHeading)}
        tooltip="Styles"
      >
        <button
          onClick={() => processAction(editor => editor.setParagraph().run())}
          className={clsx('paragraph-option', {
            'is-active': isActive('paragraph'),
          })}
        >
          Paragraph
        </button>
        {[1, 2, 3, 4, 5, 6].map(i => {
          const Header = `h${i}`

          return (
            <button
              key={`tip-tap-heading-${i}`}
              onClick={() =>
                processAction(editor =>
                  editor.toggleHeading({ level: i }).run(),
                )
              }
              className={clsx({
                'is-active': isActive('heading', { level: i }),
              })}
            >
              <Header>Heading {i}</Header>
            </button>
          )
        })}
      </TipTapDropdown>
      <TipTapColorPicker
        onChange={handleColorChange}
        tooltip="Font Color"
        value={editors?.[0]?.getAttributes('textStyle').color}
      />
      <TipTapDropdown button={getAlignmentIcon()} tooltip="Align">
        <button
          onClick={() =>
            processAction(editor => editor.setTextAlign('left').run())
          }
          className={isActive({ textAlign: 'left' }) ? 'is-active' : ''}
        >
          <AlignLeftIcon /> <span>Left</span>
        </button>
        <button
          onClick={() =>
            processAction(editor => editor.setTextAlign('center').run())
          }
          className={isActive({ textAlign: 'center' }) ? 'is-active' : ''}
        >
          <AlignCenterIcon /> <span>Center</span>
        </button>
        <button
          onClick={() =>
            processAction(editor => editor.setTextAlign('right').run())
          }
          className={isActive({ textAlign: 'right' }) ? 'is-active' : ''}
        >
          <AlignRightIcon /> <span>Right</span>
        </button>
      </TipTapDropdown>
      <div className="tiptap-menu-bar__divider"></div>

      <button
        onClick={handleLinkClick}
        className={hasLink() ? 'is-active' : ''}
        data-tooltip="Link"
        disabled={!processCan(editor => editor?.setLink)}
      >
        <LinkIcon width="16" />
      </button>

      <button
        onClick={() => processAction(editor => editor.toggleBulletList().run())}
        className={isActive('bulletList') ? 'is-active' : ''}
        data-tooltip="Bulleted List"
        disabled={!processCan(editor => editor?.toggleBulletList)}
      >
        <UnorderedListIcon />
      </button>
      <button
        onClick={() =>
          processAction(editor => editor.toggleOrderedList().run())
        }
        className={isActive('orderedList') ? 'is-active' : ''}
        data-tooltip="Numbered List"
        disabled={!processCan(editor => editor?.toggleOrderedList)}
      >
        <OrderedListIcon />
      </button>
      <button
        onClick={() => processAction(editor => editor.setAccordion().run())}
        data-tooltip="Accordion"
        disabled={!processCan(editor => editor?.setAccordion)}
      >
        <AccordionsIcon height="18" />
      </button>
      <div className="tiptap-menu-bar__divider"></div>
      <div className="tiptap-menu-bar__group">
        <button
          onClick={() =>
            processAction(editor => editor.liftListItem('listItem').run())
          }
          disabled={
            !processCan(editor => editor?.liftListItem('listItem').run())
          }
          className={clsx('tiptap-menu-bar__button')}
          data-tooltip="Decrease Indent"
        >
          <IndentRightIcon />
        </button>
        <button
          onClick={() =>
            processAction(editor => editor.sinkListItem('listItem').run())
          }
          disabled={
            !processCan(editor => editor?.sinkListItem('listItem').run())
          }
          className={clsx('tiptap-menu-bar__button')}
          data-tooltip="Increase Indent"
        >
          <IndentLeftIcon />
        </button>
      </div>
      <div className="tiptap-menu-bar__divider"></div>
      <button
        onClick={() => {
          processAction(editor => editor.unsetAllMarks().run())
          processAction(editor => editor.clearNodes().run())
        }}
        data-tooltip="Clear Styles"
      >
        <ClearStylesIcon />
      </button>
      {linkValues && (
        <EditorLinkModal
          values={linkValues}
          isOpen={true}
          onSubmit={handleLinkModalSubmit}
          onRequestClose={() => setLinkValues(null)}
        />
      )}
    </div>
  )
}

MenuBar.propTypes = {
  editors: PropTypes.arrayOf(PropTypes.object),
}

export default MenuBar
