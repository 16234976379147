import PropTypes from 'prop-types'
import { ReactComponent as ListIcon } from '../../../assets/icons/unordered-list-icon.svg'
import { ReactComponent as GridIcon } from '../../../assets/icons/grid-icon.svg'
import '../../../styles/global-components/table/table-list-grid-toggle.scss'
import clsx from 'clsx'
const TableListGridToggle = ({ value, onChange }) => {
  return (
    <div className="table-list-grid-toggle">
      <button
        className={clsx('btn btn--white btn--icon-text-left', {
          active: value === 'table',
        })}
        onClick={() => onChange('table')}
      >
        <ListIcon height="18" /> <span>List</span>
      </button>
      <button
        className={clsx('btn btn--white btn--icon-text-left', {
          active: value === 'grid',
        })}
        onClick={() => onChange('grid')}
      >
        <GridIcon height="18" /> <span>Grid</span>
      </button>
    </div>
  )
}

TableListGridToggle.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
}
export default TableListGridToggle
