import { cloneDeep, get, set } from 'lodash'
import axios from 'axios'

async function updateReplaceInternalLink({
  entry,
  items,
  replaceValue,
  token,
  activeClientId,
  operatingLocation,
  entries,
}) {
  if (entry) {
    const item = cloneDeep(items[entry.itemId])
    const currentLink = get(item, entry.path)
    set(item, [...entry.path], { ...currentLink, ...replaceValue })

    return axios({
      url: `${process.env.REACT_APP_PRODUCT_API}/api/v1/find-replace/update/internal-link/${activeClientId}`,
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      data: {
        find: entry.find,
        replace: replaceValue,
        replacementTerm: replaceValue,
        totalResults: entries.length,
        type: entry.type,
        item,
        operatingLocation,
      },
    }).then(res => {
      const newItems = cloneDeep(items)
      const newItem = res?.data?.item
      newItems[entry.itemId] = {
        ...newItem,
        breadcrumb: item.breadcrumb,
      }
      return {
        items: newItems,
        entries: entries.map(currentEntry => {
          if (currentEntry.id === entry.id) {
            return {
              ...currentEntry,
              replaced: true,
            }
          }
          return currentEntry
        }),
      }
    })
  }
}

export default updateReplaceInternalLink
