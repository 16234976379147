import Modal from '../../../global-components/elements/Modal'
import React from 'react'
import { ReactComponent as MessageIcon } from '../../../../assets/icons/message-alert-square-icon.svg'
import routes from '../../../../configuration/routes'
import { useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'
const WorkflowEditorStepBlockedModal = ({ resource, workflow, step }) => {
  const navigate = useNavigate()
  const stepIndex = workflow?.steps?.findIndex(item => item.slug === step?.slug)

  function handleGotoPreviousStep() {
    const previousStep = workflow?.steps?.[stepIndex - 1]
    navigate(`${routes.manageWorkflows}/${workflow?.slug}/${previousStep.slug}`)
  }
  function handleGotoNextStep() {
    const previousStep = workflow?.steps?.[stepIndex + 1]
    navigate(`${routes.manageWorkflows}/${workflow?.slug}/${previousStep.slug}`)
  }

  function handleReturnToWorkflow() {
    navigate(`${routes.manageWorkflows}/${workflow?.slug}`)
  }

  return (
    <Modal modalClassName="modal--delete-workflow modal--sm" isOpen={true}>
      <div className="modal__header">
        <h3 className="flex align-center">
          <MessageIcon />
          This step is already being edited
        </h3>
      </div>
      <div className="modal__body">
        <br />
        <p>
          <b>
            Oops! It seems {`${resource?.firstName} ${resource?.lastName}`} is
            already editing this section.
          </b>
        </p>
        <p>
          To avoid any accidental overlaps, this section is currently locked.
          You can choose to wait, or feel free to reach out to{' '}
          {resource?.firstName} and see when they might be done.
        </p>
        <p>
          Collaboration is great, but let&apos;s avoid stepping on each
          other&apos;s toes.
        </p>
      </div>
      <div className="modal__submit">
        <button
          onClick={() => handleReturnToWorkflow()}
          className="btn btn--white dashboard"
        >
          Return to Edit Workflow
        </button>
        {stepIndex > 0 ? (
          <button
            className="btn btn--dark-purple"
            onClick={handleGotoPreviousStep}
          >
            Previous Step
          </button>
        ) : null}
        {stepIndex < workflow?.steps?.length - 1 ? (
          <button className="btn btn--dark-purple" onClick={handleGotoNextStep}>
            Next Step
          </button>
        ) : null}
      </div>
    </Modal>
  )
}

WorkflowEditorStepBlockedModal.propTypes = {
  resource: PropTypes.object,
  workflow: PropTypes.object,
  step: PropTypes.object,
}

export default WorkflowEditorStepBlockedModal
