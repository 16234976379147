import React, { useState } from 'react'
import { EDITOR_ICON_FIELD } from '../../../../configuration/editor'
import { get } from 'lodash'
import PropTypes from 'prop-types'
import supportedIcons from '../../../../configuration/supportedIcons'
import EditorAlignmentButtons from '../elements/EditorAlignmentButtons'
import EditorLinkModal from '../../shared-components/editor-link-modal/EditorLinkModal'
import Modal from '../../../global-components/elements/Modal'
import clsx from 'clsx'
import IconColourField from '../../../forms/elements/IconColourField'
import { ReactComponent as AddImageIcon } from '../../../../assets/icons/add-media-icon.svg'
import { ReactComponent as DragIcon } from '../../../../assets/icons/suble-drag.svg'
import { ReactComponent as LinkIcon } from '../../../../assets/icons/link-icon.svg'
import { ReactComponent as CloseModalIcon } from '../../../../assets/icons/close-icon.svg'
import '../../../../styles/editor-flow/editor-fields/editor-icon-field.scss'

function EditorIconField({ field, onChange }) {
  const [linkModalOpen, setLinkModalOpen] = useState(false)
  const [selectModalIsOpen, setSelectModalIsOpen] = useState(false)
  const [selectedIcon, setSelectedIcon] = useState(false)
  const block = get(field, 'blocks[0]', null)
  const icon = block?.icon
  const { id, color } = icon || {}
  const iconConfig = id ? supportedIcons.find(option => option.id === id) : null
  const IconComponent = iconConfig?.component

  const configurations = get(block, 'configurations', {})
  const alignment = get(configurations, 'alignment', 'left')
  const alignments = {
    left: 'flex-start',
    center: 'center',
    right: 'flex-end',
  }

  const style = {
    justifyContent: alignments[alignment],
  }

  const iconStyle = {}

  if (icon?.width) {
    iconStyle.width = icon.width + 'px'
  }

  function setConfigurations(configurations) {
    onChange({
      ...field,
      blocks: [
        {
          ...block,
          configurations,
        },
      ],
    })
  }

  function setAlignment(align) {
    setConfigurations({
      ...configurations,
      alignment: align,
    })
  }

  function handleLinkSubmit(values) {
    setConfigurations({
      ...configurations,
      link: values,
    })
    setLinkModalOpen(false)
  }

  const handler = mouseDownEvent => {
    const startSize = {
      width: icon?.width || 250,
      ...configurations,
    }

    const startPosition = { x: mouseDownEvent.pageX, y: mouseDownEvent.pageY }

    function onMouseMove(mouseMoveEvent) {
      onChange({
        ...field,
        blocks: [
          {
            ...block,
            icon: {
              ...icon,
              width: startSize.width - startPosition.x + mouseMoveEvent.pageX,
            },
          },
        ],
      })
    }
    function onMouseUp() {
      document.body.removeEventListener('mousemove', onMouseMove)
    }

    document.body.addEventListener('mousemove', onMouseMove)
    document.body.addEventListener('mouseup', onMouseUp, { once: true })
  }

  function handleIconChange(value) {
    onChange({
      ...field,
      blocks: [
        {
          ...block,
          icon: {
            ...icon,
            id: value,
          },
        },
      ],
    })
    setSelectModalIsOpen(false)
  }

  function handleColorChange(color) {
    onChange({
      ...field,
      blocks: [
        {
          ...block,
          icon: {
            ...icon,
            color,
          },
        },
      ],
    })
  }

  return (
    <div className="editor-icon-field" style={style}>
      <div
        className="editor-icon-field__icon-container populated"
        style={style}
      >
        <div className="editor-icon-field__settings">
          <div className="editor-icon-field__setting">
            <label>Colour</label>
            <div className="editor-icon-field__color-picker">
              <IconColourField
                setFieldTouched={() => {}}
                placeholder=""
                form={{
                  touched: {},
                  errors: {},
                  setFieldValue: (name, val) => {
                    handleColorChange(val)
                  },
                }}
                field={{ value: color, name: 'color' }}
              />
            </div>
          </div>
          <div className="editor-icon-field__setting">
            <EditorAlignmentButtons setAlignment={setAlignment} />
          </div>
          <div className="editor-icon-field__setting">
            <label>Link</label>
            <button
              className="btn btn--white"
              onClick={() => setLinkModalOpen(true)}
            >
              <LinkIcon height="14" />
            </button>
          </div>
        </div>

        {IconComponent && (
          <div style={iconStyle}>
            <IconComponent colour={color} />
          </div>
        )}

        <div
          className={`editor-icon-field__options-overlay ${
            IconComponent ? ' populated' : ''
          }`}
        >
          <div className="flex col">
            <button
              className="btn btn--light-grey"
              onClick={() => {
                setSelectedIcon(null)
                setSelectModalIsOpen(true)
              }}
            >
              <AddImageIcon />
              {IconComponent ? 'Change Icon' : 'Choose An Icon'}
            </button>
          </div>
          <button
            type="button"
            className="editor-icon-field-resize-se"
            onMouseDown={handler}
          >
            <DragIcon />
          </button>
          <button
            type="button"
            className="editor-icon-field-resize-ne"
            onMouseDown={handler}
          >
            <DragIcon />
          </button>
        </div>
      </div>

      <Modal
        modalClassName="modal--md"
        isOpen={selectModalIsOpen}
        onRequestClose={() => setSelectModalIsOpen(false)}
      >
        <div className="modal__header">
          <h3>Select Icon</h3>
          <button
            className="modal__close"
            onClick={() => setSelectModalIsOpen(false)}
          >
            <CloseModalIcon />
          </button>
        </div>
        <div className="editor-icon-field__icon-list">
          {supportedIcons.map((icon, index) => {
            const value = get(icon, 'id', null)
            const Component = get(icon, 'component', null)
            const label = get(icon, 'label', null)

            return (
              <button
                key={`editor-icon-${index}`}
                onClick={() => setSelectedIcon(value)}
                onDoubleClick={() => handleIconChange(value)}
                className={clsx({ selected: selectedIcon === value })}
              >
                <Component />
                <span>{label}</span>
              </button>
            )
          })}
        </div>
        <div className="form__submit">
          <button
            className="btn btn--white"
            onClick={() => setSelectModalIsOpen(false)}
          >
            Cancel
          </button>
          <button
            disabled={!selectedIcon}
            className="btn btn--dark-purple"
            onClick={() => handleIconChange(selectedIcon)}
          >
            Select
          </button>
        </div>
      </Modal>

      <EditorLinkModal
        isOpen={linkModalOpen}
        onRequestClose={() => setLinkModalOpen(false)}
        values={configurations.link}
        onSubmit={handleLinkSubmit}
      />
    </div>
  )
}

export const createIconField = id => {
  return {
    id,
    type: 'field',
    fieldType: EDITOR_ICON_FIELD,
    blocks: [
      {
        icon: {
          id: '',
          color: '#1a1d1f',
          width: 250,
        },
        configurations: {},
      },
    ],
  }
}

EditorIconField.propTypes = {
  field: PropTypes.object,
  onChange: PropTypes.func,
}

export default EditorIconField
