import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import {
  deleteWorkflow,
  selectDeleteWorkflowsState,
} from '../../../../redux/workflows/deleteWorkflow'
import Modal from '../../../global-components/elements/Modal'
import MaterialLoader from '../../../global-components/elements/MaterialLoader'
import useAuthToken from '../../../../hooks/useAuthToken'
import { ReactComponent as CloseModalIcon } from '../../../../assets/icons/close-icon.svg'

function ViewMyWorkflowsDeleteModal({
  deletedWorkflow,
  isDeleteModalOpen,
  setDeletedWorkflow,
  toggleDeleteModal,
}) {
  const [deleteInputValue, setDeleteInputValue] = useState('')
  const { token } = useAuthToken({})
  const dispatch = useDispatch()
  const deletedWorkflowsState = useSelector(selectDeleteWorkflowsState)
  const deletedWorkflowsLoading = get(deletedWorkflowsState, 'loading', false)
  const workflowId = get(deletedWorkflow, '_id', null)
  const workflowSlug = get(deletedWorkflow, 'slug', null)
  const workflowName = get(deletedWorkflow, 'name', null)
  const submitDisabled = cleanName(deleteInputValue) !== cleanName(workflowName)

  const handleCloseDeleteModal = () => {
    toggleDeleteModal(!isDeleteModalOpen)

    setDeletedWorkflow({})
  }

  const handleDeleteWorkflowById = async () => {
    await dispatch(deleteWorkflow(workflowId, workflowSlug, token))
  }

  function cleanName(name) {
    return name.replace(/[\u2018\u2019]/g, "'").replace(/[\u201C\u201D]/g, '"')
  }

  return (
    <Modal
      modalClassName="modal--delete-workflow modal--sm"
      isOpen={isDeleteModalOpen}
      onRequestClose={() => handleCloseDeleteModal()}
    >
      <div className="modal__header">
        <h3>Delete Workflow</h3>

        <button
          className="modal__close"
          onClick={() => toggleDeleteModal(!isDeleteModalOpen)}
        >
          <CloseModalIcon />
        </button>
      </div>

      <div className="modal__body">
        <p className="warning warning--red">
          Once this workflow is deleted, it will no longer be accessible or
          recoverable.
        </p>

        <div className="modal__body-group">
          <p>
            This action will delete the {workflowName} workflow and all of
            it&apos;s related content. All versions of this workflow will no
            longer be available and this action cannot be undone.
          </p>

          <p>
            Please type <strong>{workflowName}</strong> to confirm:
          </p>

          <input
            type="text"
            value={deleteInputValue}
            onChange={e => setDeleteInputValue(e.target.value)}
          />
        </div>
      </div>

      <div className="modal__submit">
        {deletedWorkflowsLoading ? (
          <MaterialLoader containerClasses="inline-loader" />
        ) : (
          <>
            <button
              className="btn btn--white"
              onClick={() => handleCloseDeleteModal()}
            >
              Cancel
            </button>

            <button
              className="btn btn--red"
              disabled={submitDisabled}
              onClick={submitDisabled ? null : () => handleDeleteWorkflowById()}
            >
              I understand, delete this workflow
            </button>
          </>
        )}
      </div>
    </Modal>
  )
}

ViewMyWorkflowsDeleteModal.propTypes = {
  deletedWorkflow: PropTypes.object,
  isDeleteModalOpen: PropTypes.bool,
  setDeletedWorkflow: PropTypes.func.isRequired,
  toggleDeleteModal: PropTypes.func.isRequired,
}

export default ViewMyWorkflowsDeleteModal
