import Modal from '../../global-components/elements/Modal'
import React from 'react'
import { ReactComponent as MessageAlertIcon } from '../../../assets/icons/message-alert-square-icon.svg'

const SocketSessionExpiredModal = () => {
  function handleRefresh() {
    window.location.reload()
  }

  return (
    <Modal modalClassName="modal--delete-workflow modal--sm" isOpen={true}>
      <div className="modal__header">
        <h3 className="flex align-center">
          <MessageAlertIcon />
          Session Expired
        </h3>
      </div>
      <div className="modal__body">
        <p>
          <b>Oops! Your session has expired.</b>
        </p>
        <p>Just refresh the page to keep going.</p>
      </div>
      <div className="modal__submit">
        <button className="btn btn--dark-purple" onClick={handleRefresh}>
          Refresh
        </button>
      </div>
    </Modal>
  )
}

export default SocketSessionExpiredModal
