import React, { useState, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { get, isEmpty } from 'lodash'
import { selectWorkflowsState } from '../../../../redux/workflows/workflows'
import {
  selectActiveClient,
  selectActiveClientId,
  selectActiveOperatingLocation,
  selectDuplicatedWorkflowVisibility,
  selectManageWorkflowModalVisibility,
  setDuplicatedContentData,
  setManagedContentId,
  toggleDuplicateWorkflowModal,
  toggleManageWorkflowModal,
} from '../../../../redux/user/userSelections'
import { useGlobalFilter, useSortBy, useTable } from 'react-table'
import { Link } from 'react-router-dom'
import globalSearchFilter from '../../../../utilities/table/globalSearchFilter'
import MaterialLoader from '../../../global-components/elements/MaterialLoader'
import formatWorkflowsToTableData from '../../../../utilities/workflows/formatWorkflowsToTableData'
import ViewMyWorkflowsDeleteModal from './ViewMyWorkflowsDeleteModal'
import { ReactComponent as DeleteIcon } from '../../../../assets/icons/delete-icon.svg'
import { ReactComponent as EditIcon } from '../../../../assets/icons/pencil-icon.svg'
import { ReactComponent as FilterIconDefault } from '../../../../assets/icons/table-sort-arrows-default.svg'
import { ReactComponent as FilterIconAscending } from '../../../../assets/icons/table-sort-arrows-ascending.svg'
import { ReactComponent as FilterIconDescending } from '../../../../assets/icons/table-sort-arrows-descending.svg'
import { ReactComponent as AddIcon } from '../../../../assets/icons/plus-icon.svg'
import { ReactComponent as DuplicateIcon } from '../../../../assets/icons/duplicate-icon.svg'
import { ReactComponent as ConfigureIcon } from '../../../../assets/icons/configure-icon.svg'
import { ReactComponent as ArrowRightIcon } from '../../../../assets/icons/arrow-right-icon.svg'

import '../../../../styles/consumer-flow/view-my-workflows/view-my-workflows-table.scss'
import dayjs from 'dayjs'
import dateFormats from '../../../../configuration/supportedDateFormats'
import FilterDropdown from '../../../global-components/filters/FilterDropdown'
import Select from 'react-select'
import {
  selectCategories,
  selectCategoriesByClient,
  selectCategoriesForActiveClient,
} from '../../../../redux/categories/categories'
import ResourceChangedToasts from '../../../global-components/resource-changed-toasts/ResourceChangedToasts'

function ViewMyWorkflows() {
  const dispatch = useDispatch()
  const activeClient = useSelector(selectActiveClient)
  const activeClientId = useSelector(selectActiveClientId)
  const activeOperatingLocation = useSelector(selectActiveOperatingLocation)
  const workflowsState = useSelector(selectWorkflowsState)
  const isManageWorkflowModalVisible = useSelector(
    selectManageWorkflowModalVisibility,
  )
  const isDuplicateWorkflowModalVisible = useSelector(
    selectDuplicatedWorkflowVisibility,
  )
  const categories = useSelector(selectCategoriesForActiveClient)

  const [isDeleteModalOpen, toggleDeleteModal] = useState(false)
  const [deletedWorkflow, setDeletedWorkflow] = useState({})
  const [filters, setFilters] = useState({
    category: null,
    subcategory: null,
    lastModified: null,
  })
  const clientName = get(activeClient, 'name', null)
  const workflows = get(workflowsState, 'workflows', [])
  const workflowsByClient = get(
    workflows,
    `${activeOperatingLocation}-${activeClientId}`,
    [],
  )

  const selectFilterProps = {
    className: 'custom-select',
    classNamePrefix: 'custom-select',
    minMenuHeight: 150,
    maxMenuHeight: 150,
    isClearable: true,
  }

  const categoryOptions =
    categories?.map(category => ({
      label: category.category,
      value: category._id,
    })) || []

  const subCategoryOptions = categories
    .reduce((acc, cur) => {
      if (filters.category) {
        if (filters.category.value === cur._id) {
          return [...acc, ...cur.subcategories]
        }
        return acc
      }
      return [...acc, ...cur.subcategories]
    }, [])
    .map(subcategory => ({ value: subcategory, label: subcategory }))

  const lastModifiedOptions = [
    {
      value: '24hours',
      label: 'Last 24 hours',
      operation: [1, 'd'],
    },
    {
      value: '3days',
      label: 'Last 3 days',
      operation: [3, 'd'],
    },
    {
      value: '7days',
      label: 'Last 7 days',
      operation: [7, 'd'],
    },
    {
      value: '30days',
      label: 'Last 30 days',
      operation: [30, 'd'],
    },
  ]

  function handleToggleDeleteWorkflow(workflowId) {
    const workflowData = workflowsByClient.find(workflow => {
      const id = get(workflow, '_id', null)
      return id === workflowId
    })

    if (workflowData) {
      setDeletedWorkflow(workflowData)
      toggleDeleteModal(!isDeleteModalOpen)
    }
  }

  const handleToggleDuplicateWorkflow = async workflowId => {
    dispatch(
      setDuplicatedContentData({
        workflowId: workflowId,
        stepId: null,
        componentId: null,
        subComponentId: null,
      }),
    )
    dispatch(toggleDuplicateWorkflowModal(!isDuplicateWorkflowModalVisible))
  }

  function handleToggleEditWorkflow(workflowId) {
    dispatch(
      toggleManageWorkflowModal(!isManageWorkflowModalVisible, workflowId),
    )
    dispatch(setManagedContentId(workflowId))
  }

  function handleFilterChange(type, val) {
    setFilters({
      ...filters,
      [type]: val,
    })
  }

  function onCategoryFilterChange(val) {
    setFilters({
      ...filters,
      category: val,
      subcategory: null,
    })
  }

  function filterWorkflowData(workflowData) {
    return workflowData.filter(workflow => {
      let isValid = true
      if (filters.category) {
        isValid = workflow?.categories?.some(
          category => category.id === filters.category.value,
        )
      }
      if (filters.subcategory) {
        const subcategories = workflow?.categories?.reduce(
          (acc, cur) => [...acc, ...cur?.subcategories],
          [],
        )
        isValid =
          isValid &&
          subcategories.some(
            subcategory => subcategory === filters.subcategory.value,
          )
      }
      if (filters.lastModified) {
        const check = dayjs().subtract(...filters.lastModified.operation)
        isValid = isValid && dayjs(workflow.updatedAt).isAfter(check)
      }

      return isValid
    })
  }

  const columns = useMemo(
    () => [
      {
        Header: '',
        accessor: 'icon',
        disableSortBy: true,
        Cell: props => {
          const value = get(props, 'cell.value', null)

          return value
        },
      },
      {
        Header: 'Name',
        accessor: 'name',
      },
      {
        Header: 'Status',
        accessor: 'status',
        Cell: props => {
          const status = get(props, 'cell.value', null)

          return status ? (
            <span className={`status-icon status-icon--${status}`}>
              <span className="status-icon__indicator"></span>
              {status}
            </span>
          ) : null
        },
      },
      {
        Header: 'Categories',
        accessor: 'categories',
      },
      {
        Header: 'Subcategories',
        accessor: 'subcategories',
      },
      {
        Header: 'Last Modified',
        accessor: 'dateModified',
        sortType: 'datetime',
        Cell: props => {
          const date = get(props, 'row.original.originalDateModified', null)

          const formatted = date
            ? dayjs(date).format(dateFormats.dayMonthYearTime)
            : ''
          return <span>{formatted}</span>
        },
      },
      {
        Header: '',
        accessor: 'configure',
        disableSortBy: true,
        Cell: props => {
          const id = get(props, 'cell.value', null)
          const canEdit = get(props, 'row.original.canEdit')
          return id && canEdit ? (
            <button
              className="btn--transparent btn--no-border"
              onClick={() => handleToggleEditWorkflow(id)}
              data-tooltip="Configure Workflow"
            >
              <ConfigureIcon />
            </button>
          ) : null
        },
      },
      {
        Header: '',
        accessor: 'duplicate',
        disableSortBy: true,
        Cell: props => {
          const id = get(props, 'cell.value', null)

          return id ? (
            <button
              className="btn--transparent btn--no-border"
              onClick={() => handleToggleDuplicateWorkflow(id)}
              data-tooltip="Duplicate Workflow"
            >
              <DuplicateIcon />
            </button>
          ) : null
        },
      },
      {
        Header: '',
        accessor: 'edit',
        disableSortBy: true,
        Cell: props => {
          const path = get(props, 'cell.value', null)
          const canEdit = get(props, 'row.original.canEdit')
          return path && canEdit ? (
            <Link to={path} data-tooltip="Edit Workflow Content">
              <EditIcon />
            </Link>
          ) : null
        },
      },
      {
        Header: '',
        accessor: 'delete',
        disableSortBy: true,
        Cell: props => {
          const id = get(props, 'cell.value', null)
          const canEdit = get(props, 'row.original.canEdit')
          return id && canEdit ? (
            <button
              className="btn--transparent btn--no-border"
              onClick={() => handleToggleDeleteWorkflow(id)}
              data-tooltip="Delete Workflow"
            >
              <DeleteIcon />
            </button>
          ) : null
        },
      },
      {
        Header: '',
        accessor: 'view',
        disableSortBy: true,
        Cell: props => {
          const path = get(props, 'cell.value', null)

          return path ? (
            <Link
              className="btn btn--light-green btn--icon-text-right"
              to={path}
            >
              <span>View</span>
              <ArrowRightIcon />
            </Link>
          ) : null
        },
      },
    ],
    [],
  )

  const data = workflowsByClient
    ? useMemo(
        () =>
          formatWorkflowsToTableData(
            filterWorkflowData(workflowsByClient),
            activeClientId,
          ),
        [workflowsByClient, filters],
      )
    : []

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      globalSearchFilter,
      initialState: {
        sortBy: [
          {
            id: 'name',
          },
        ],
      },
    },
    useGlobalFilter,
    useSortBy,
  )

  return workflowsState.loading ? (
    <MaterialLoader containerClasses="container-loader" />
  ) : (
    <div className="view-my-workflows__table">
      <div className="table__filters">
        <div className="table__filters-row">
          <div className="table__filters-content">
            <p className="title">Workflows</p>
            <div className="table__filters-counts">
              <p>
                <span>{data?.length}</span> Workflows
              </p>
            </div>
            <div className="table__filters-content-filters">
              <div className="table__filters-content-filters-label">
                Filter By:
              </div>
              <div className="flex">
                <Select
                  {...selectFilterProps}
                  value={filters.category}
                  options={categoryOptions}
                  onChange={onCategoryFilterChange}
                  placeholder="Category"
                />

                <Select
                  value={filters.subcategory}
                  options={subCategoryOptions}
                  onChange={val => handleFilterChange('subcategory', val)}
                  placeholder="Subcategory"
                  {...selectFilterProps}
                />

                <Select
                  value={filters.lastModified}
                  options={lastModifiedOptions}
                  onChange={val => handleFilterChange('lastModified', val)}
                  placeholder="Last Modified"
                  {...selectFilterProps}
                />
              </div>
            </div>
          </div>

          <div className="table__filters-actions">
            <input
              type="text"
              className="filters-search"
              name="search-users"
              onChange={e => setGlobalFilter(e.currentTarget.value)}
              placeholder="Search My Workflows"
            />

            <button
              className="btn btn--bright-purple btn--compact"
              onClick={() =>
                dispatch(
                  toggleManageWorkflowModal(!isManageWorkflowModalVisible),
                )
              }
            >
              <AddIcon />
              Add Workflow
            </button>
          </div>
        </div>
      </div>

      {!isEmpty(workflowsByClient) ? (
        <>
          <div className="table scroll-x">
            <div className="table__container">
              <table {...getTableProps()}>
                <thead>
                  {headerGroups.map((headerGroup, i) => (
                    <tr
                      key={`my-workflows-thead-tr-${i}`}
                      {...headerGroup.getHeaderGroupProps()}
                    >
                      {headerGroup?.headers?.map((column, i) => {
                        const canSort = column.canSort
                        const isSorted = column.isSorted
                        const isSortedDesc = column.isSortedDesc

                        return (
                          <th
                            key={`users-thead-th-${i}`}
                            {...column.getHeaderProps()}
                            style={{ verticalAlign: 'middle' }}
                          >
                            <div {...column.getSortByToggleProps()}>
                              {column.render('Header')}
                              {canSort && !isSorted && !isSortedDesc ? (
                                <FilterIconDefault className="filter-icon" />
                              ) : canSort && isSorted && !isSortedDesc ? (
                                <FilterIconAscending className="descending-icon" />
                              ) : canSort && isSortedDesc && isSortedDesc ? (
                                <FilterIconDescending className="ascending-icon" />
                              ) : (
                                ''
                              )}
                            </div>
                          </th>
                        )
                      })}
                    </tr>
                  ))}
                </thead>

                <tbody {...getTableBodyProps()}>
                  {rows?.map((row, i) => {
                    prepareRow(row)

                    return (
                      <tr
                        key={`my-workflows-tbody-tr-${i}`}
                        {...row.getRowProps()}
                      >
                        {row.cells.map((cell, i) => {
                          return (
                            <td
                              className={cell.column.id}
                              key={`my-workflows-tbody-td-${i}`}
                              {...cell.getCellProps()}
                            >
                              {cell.render('Cell')}
                            </td>
                          )
                        })}
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
          </div>

          {deletedWorkflow && isDeleteModalOpen ? (
            <ViewMyWorkflowsDeleteModal
              deletedWorkflow={deletedWorkflow}
              isDeleteModalOpen={isDeleteModalOpen}
              setDeletedWorkflow={setDeletedWorkflow}
              toggleDeleteModal={toggleDeleteModal}
            />
          ) : null}
        </>
      ) : (
        <div className="table scroll-x">
          <div className="table__message">
            <p>There are currently no Workflows listed under this client</p>
          </div>
        </div>
      )}
      <ResourceChangedToasts
        types={['configureWorkflow']}
        isPaused={
          isManageWorkflowModalVisible ||
          isDuplicateWorkflowModalVisible ||
          isDeleteModalOpen
        }
      >
        {resource => (
          <>
            {resource?.firstName} is done editing the {resource?.name} workflow
          </>
        )}
      </ResourceChangedToasts>
      <ResourceChangedToasts
        types={['createWorkflow']}
        isPaused={
          isManageWorkflowModalVisible ||
          isDuplicateWorkflowModalVisible ||
          isDeleteModalOpen
        }
      >
        {resource => (
          <>
            {resource?.firstName} has created a new workflow - &quot;
            {resource?.name}&quot;
          </>
        )}
      </ResourceChangedToasts>
    </div>
  )
}

ViewMyWorkflows.propTypes = {}

export default ViewMyWorkflows
