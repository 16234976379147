import React from 'react'
import PropTypes from 'prop-types'

function FileCabinetIcon({ colour }, props) {
  return (
    <svg
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M18.4393 9.43501H21.5607C21.5867 9.70206 21.812 9.91075 22.0858 9.91075C22.3773 9.91075 22.6135 9.6745 22.6135 9.38305C22.6135 8.82976 22.1634 8.37962 21.6101 8.37962H18.3899C17.8366 8.37962 17.3865 8.82976 17.3865 9.38305C17.3865 9.6745 17.6227 9.91075 17.9142 9.91075C18.188 9.91075 18.4133 9.70206 18.4393 9.43501Z"
        fill={colour}
      />
      <path
        d="M21.6101 18.2032H18.3899C17.8366 18.2032 17.3865 18.6534 17.3865 19.2067C17.3865 19.4981 17.6227 19.7344 17.9142 19.7344C18.1881 19.7344 18.4133 19.5257 18.4393 19.2586H21.5607C21.5867 19.5257 21.812 19.7344 22.0858 19.7344C22.3773 19.7344 22.6135 19.4981 22.6135 19.2067C22.6135 18.6533 22.1634 18.2032 21.6101 18.2032Z"
        fill={colour}
      />
      <path
        d="M18.3899 28.2901C17.8366 28.2901 17.3865 28.7402 17.3865 29.2935C17.3865 29.5849 17.6227 29.8212 17.9142 29.8212C18.1881 29.8212 18.4133 29.6125 18.4393 29.3455H21.5607C21.5867 29.6125 21.812 29.8212 22.0858 29.8212C22.3773 29.8212 22.6135 29.5849 22.6135 29.2935C22.6135 28.7402 22.1634 28.2901 21.6101 28.2901H18.3899Z"
        fill={colour}
      />
      <path
        d="M32.0195 2H7.9805C6.73245 2 5.71707 3.01538 5.71707 4.26343V33.6741C5.71707 34.6679 6.36106 35.5135 7.25347 35.8171V37.1363C7.25347 37.6126 7.64089 38 8.11719 38H9.90903C10.3853 38 10.7728 37.6126 10.7728 37.1363V35.9376H29.2514V37.1363C29.2514 37.6126 29.6389 38 30.1152 38H31.907C32.3832 38 32.7707 37.6126 32.7707 37.1363V35.8089C33.6505 35.4984 34.2829 34.6589 34.2829 33.6741V4.26343C34.2829 3.01538 33.2675 2 32.0195 2ZM9.71736 36.9446H8.30879V35.9375H9.71736V36.9446ZM30.3068 36.9446V35.9375H31.7154V36.9446H30.3068ZM33.2275 33.6741C33.2275 34.3402 32.6856 34.8822 32.0194 34.8822H7.9805C7.31436 34.8822 6.77239 34.3402 6.77239 33.6741V4.26343C6.77239 3.59729 7.31436 3.05539 7.9805 3.05539H32.0194C32.6856 3.05539 33.2275 3.59729 33.2275 4.26343V33.6741Z"
        fill={colour}
      />
      <path
        d="M30.1953 4.62971H9.80469C9.06388 4.62971 8.46123 5.23236 8.46123 5.97318V7.67382C8.46123 7.96527 8.69748 8.20152 8.98892 8.20152C9.28037 8.20152 9.51662 7.96527 9.51662 7.67382V5.97318C9.51662 5.81434 9.64585 5.68511 9.80469 5.68511H30.1953C30.3541 5.68511 30.4834 5.81434 30.4834 5.97318V11.8415C30.4834 12.0002 30.3541 12.1295 30.1953 12.1295H9.80469C9.64585 12.1295 9.51662 12.0002 9.51662 11.8415V10.1408C9.51662 9.84936 9.28037 9.61311 8.98892 9.61311C8.69748 9.61311 8.46123 9.84936 8.46123 10.1408V11.8415C8.46123 12.5822 9.06388 13.1848 9.80469 13.1848H30.1953C30.9361 13.1848 31.5388 12.5822 31.5388 11.8415V5.97318C31.5388 5.23236 30.9361 4.62971 30.1953 4.62971Z"
        fill={colour}
      />
      <path
        d="M30.1953 24.7781H9.80469C9.06388 24.7781 8.46123 25.3807 8.46123 26.1215V31.9897C8.46123 32.7306 9.06388 33.3332 9.80469 33.3332H30.1953C30.9361 33.3332 31.5388 32.7306 31.5388 31.9897V30.0512C31.5388 29.7597 31.3025 29.5235 31.0111 29.5235C30.7196 29.5235 30.4834 29.7597 30.4834 30.0512V31.9897C30.4834 32.1486 30.3541 32.2778 30.1953 32.2778H9.80469C9.64585 32.2778 9.51662 32.1486 9.51662 31.9897V26.1215C9.51662 25.9627 9.64585 25.8335 9.80469 25.8335H30.1953C30.3541 25.8335 30.4834 25.9627 30.4834 26.1215V27.5842C30.4834 27.8756 30.7196 28.1119 31.0111 28.1119C31.3025 28.1119 31.5388 27.8756 31.5388 27.5842V26.1215C31.5388 25.3807 30.9361 24.7781 30.1953 24.7781Z"
        fill={colour}
      />
      <path
        d="M30.1953 14.704H9.80469C9.06388 14.704 8.46123 15.3066 8.46123 16.0474V21.9156C8.46123 22.6563 9.06388 23.259 9.80469 23.259H30.1953C30.9361 23.259 31.5388 22.6563 31.5388 21.9156V16.0474C31.5388 15.3066 30.9361 14.704 30.1953 14.704ZM30.4834 21.9156C30.4834 22.0743 30.3541 22.2036 30.1953 22.2036H9.80469C9.64585 22.2036 9.51662 22.0743 9.51662 21.9156V16.0474C9.51662 15.8886 9.64585 15.7594 9.80469 15.7594H30.1953C30.3541 15.7594 30.4834 15.8886 30.4834 16.0474V21.9156Z"
        fill={colour}
      />
    </svg>
  )
}

FileCabinetIcon.defaultProps = {
  colour: '#000000',
}

FileCabinetIcon.propTypes = {
  colour: PropTypes.string,
}

export default FileCabinetIcon
