import React from 'react'
import PropTypes from 'prop-types'

function SchoolIcon({ colour }, props) {
  return (
    <svg
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g mask="url(#mask0_101_232)">
        <path
          d="M20.001 3.16L20.001 10.5005"
          stroke={colour}
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M23.1799 34.5027L16.8219 34.5027L16.8219 27.6862C16.8219 27.481 16.9893 27.3137 17.1947 27.3137L22.8052 27.3137C23.0105 27.3137 23.1799 27.481 23.1799 27.6862L23.1799 34.5027Z"
          stroke={colour}
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M20.001 27.3134L20.001 34.5024"
          stroke={colour}
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M20.001 19.979L18.825 19.979"
          stroke={colour}
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M20.001 19.979L20.001 18.0283"
          stroke={colour}
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M33.9051 21.4833L29.0319 21.4833C28.8943 21.4833 28.7808 21.5969 28.7808 21.7344L28.7808 24.1613C28.7808 24.2988 28.8943 24.4124 29.0319 24.4124L33.9051 24.4124C34.0446 24.4124 34.1582 24.2988 34.1582 24.1613L34.1582 21.7344C34.1582 21.5969 34.0446 21.4833 33.9051 21.4833Z"
          stroke={colour}
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M31.4695 21.4833L31.4695 24.4124"
          stroke={colour}
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M33.9051 26.7557L29.0319 26.7557C28.8943 26.7557 28.7808 26.8693 28.7808 27.0088L28.7808 29.4336C28.7808 29.5731 28.8943 29.6868 29.0319 29.6868L33.9051 29.6868C34.0446 29.6868 34.1582 29.5731 34.1582 29.4336L34.1582 27.0088C34.1582 26.8693 34.0446 26.7557 33.9051 26.7557Z"
          stroke={colour}
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M31.4695 26.7557L31.4695 29.6868"
          stroke={colour}
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.09492 21.4833L10.9681 21.4833C11.1076 21.4833 11.2212 21.5969 11.2212 21.7344L11.2212 24.1613C11.2212 24.2988 11.1076 24.4124 10.9681 24.4124L6.09492 24.4124C5.95739 24.4124 5.84383 24.2988 5.84383 24.1613L5.84383 21.7344C5.84383 21.5969 5.95739 21.4833 6.09492 21.4833Z"
          stroke={colour}
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.53272 21.4833L8.53271 24.4124"
          stroke={colour}
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.09492 26.7557L10.9681 26.7557C11.1076 26.7557 11.2212 26.8693 11.2212 27.0088L11.2212 29.4336C11.2212 29.5731 11.1076 29.6868 10.9681 29.6868L6.09492 29.6868C5.95739 29.6868 5.84383 29.5731 5.84383 29.4336L5.84383 27.0088C5.84383 26.8693 5.95739 26.7557 6.09492 26.7557Z"
          stroke={colour}
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.53272 26.7557L8.53271 29.6868"
          stroke={colour}
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M20.001 23.3643C17.8623 23.3643 16.1183 21.6109 16.1183 19.4629C16.1183 17.3149 17.8623 15.5615 20.001 15.5615C22.1395 15.5615 23.8835 17.3149 23.8835 19.4629C23.8835 21.6109 22.1395 23.3643 20.001 23.3643Z"
          stroke={colour}
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M20.001 4.00284L15.2932 4.00284C15.06 4.00284 14.8687 4.19416 14.8687 4.42922L14.8687 7.46784C14.8687 7.70297 15.06 7.89429 15.2932 7.89429L20.001 7.89429L20.001 4.00284Z"
          stroke={colour}
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M26.5942 18.0283L37.2933 18.0283C37.3929 18.0283 37.4727 17.9466 37.4727 17.847L37.4727 15.9959C37.4727 15.8963 37.3929 15.8146 37.2933 15.8146L26.5942 15.8146L26.5942 18.0283Z"
          stroke={colour}
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13.4058 18.0283L2.70863 18.0283C2.609 18.0283 2.52729 17.9466 2.52729 17.847L2.52729 15.9959C2.52729 15.8963 2.609 15.8146 2.70863 15.8146L13.4058 15.8146L13.4058 18.0283Z"
          stroke={colour}
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M21.2845 13.1826L27.7681 13.1826C27.9016 13.1826 28.0112 13.075 28.0112 12.9395L28.0112 10.7438C28.0112 10.6103 27.9016 10.5007 27.7681 10.5007L12.2318 10.5007C12.0982 10.5007 11.9906 10.6103 11.9906 10.7438L11.9906 12.9395C11.9906 13.075 12.0982 13.1826 12.2318 13.1826L18.9366 13.1826"
          stroke={colour}
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M36.4243 28.0189L36.4243 36.521C36.4243 36.6964 36.2808 36.8398 36.1035 36.8398L26.5942 36.8398M36.4243 18.0284L36.4243 25.5841"
          stroke={colour}
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13.4058 18.0284L3.57769 18.0284L3.57769 36.521C3.57769 36.6963 3.7212 36.8398 3.89656 36.8398L13.4058 36.8398L13.4058 18.0284Z"
          stroke={colour}
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M24.1746 36.8398L24.1746 34.9609C24.1746 34.7099 23.9513 34.5026 23.6782 34.5026L16.3216 34.5026C16.0506 34.5026 15.8273 34.7099 15.8273 34.9609L15.8273 36.8398"
          stroke={colour}
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M26.5942 13.1826L26.5942 36.8398L13.4059 36.8398L13.4059 13.1826"
          stroke={colour}
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  )
}

SchoolIcon.defaultProps = {
  colour: '#000000',
}

SchoolIcon.propTypes = {
  colour: PropTypes.string,
}

export default SchoolIcon
