import React from 'react'
import PropTypes from 'prop-types'

function MicrophoneIcon({ colour }, props) {
  return (
    <svg
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M27.9078 12.1672C27.4828 12.1672 27.0768 12.2485 26.7031 12.3949V8.70311C26.7031 4.94406 23.6439 2 20 2C16.3605 2 13.2968 4.93984 13.2968 8.70311V12.3948C12.9231 12.2484 12.5171 12.1671 12.0922 12.1671C10.2648 12.1671 8.77814 13.6533 8.77814 15.4801V18.8703C8.77814 23.799 11.9949 28.1415 16.686 29.5941V31.3719H15.4813C13.654 31.3719 12.1673 32.8581 12.1673 34.6871C12.1673 36.5138 13.654 38 15.4813 38H24.5188C26.3462 38 27.8329 36.5138 27.8329 34.6848C27.8329 32.8581 26.3462 31.3719 24.5188 31.3719H23.3141V29.5919C26.8378 28.5037 31.2219 24.9287 31.2219 18.8703V15.4801C31.2219 13.6534 29.7352 12.1672 27.9078 12.1672ZM15.4062 8.70311C15.4062 6.12602 17.5045 4.10938 20 4.10938C22.4869 4.10938 24.5937 6.11723 24.5937 8.70311V18.8703C24.5937 21.4475 22.4954 23.4641 20 23.4641C17.513 23.4641 15.4062 21.4562 15.4062 18.8703V8.70311ZM29.1125 18.8703C29.1125 22.6875 26.5657 26.721 22.0251 27.755C21.5452 27.8644 21.2046 28.2912 21.2046 28.7833V32.4266C21.2046 33.0091 21.6768 33.4813 22.2593 33.4813H24.5187C25.1829 33.4813 25.7233 34.0212 25.7233 34.6871C25.7233 35.3507 25.1829 35.8906 24.5187 35.8906H15.4812C14.8169 35.8906 14.2765 35.3507 14.2765 34.6848C14.2765 34.0212 14.8169 33.4813 15.4812 33.4813H17.7405C18.323 33.4813 18.7952 33.0091 18.7952 32.4266V28.7856C18.7952 28.293 18.4542 27.866 17.9738 27.7571C14.3027 26.9244 10.8874 23.5817 10.8874 18.8703V15.4801C10.8874 14.8165 11.4279 14.2766 12.0921 14.2766C12.7564 14.2766 13.2968 14.817 13.2968 15.4813V18.8703C13.2968 22.6294 16.3559 25.5734 19.9999 25.5734C23.6393 25.5734 26.703 22.6336 26.703 18.8703V15.4813C26.703 14.817 27.2434 14.2766 27.9077 14.2766C28.5719 14.2766 29.1123 14.8165 29.1123 15.4801V18.8703H29.1125Z"
        fill={colour}
      />
    </svg>
  )
}

MicrophoneIcon.defaultProps = {
  colour: '#000000',
}

MicrophoneIcon.propTypes = {
  colour: PropTypes.string,
}

export default MicrophoneIcon
