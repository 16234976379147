import React from 'react'
import PropTypes from 'prop-types'

function HotTemperatureIcon({ colour }, props) {
  return (
    <svg
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M32.3493 17.2769H30.9431C30.5547 17.2769 30.2399 17.5917 30.2399 17.9801C30.2399 18.3684 30.5547 18.6832 30.9431 18.6832H32.3493C32.7376 18.6832 33.0524 18.3684 33.0524 17.9801C33.0524 17.5917 32.7376 17.2769 32.3493 17.2769Z"
        fill={colour}
      />
      <path
        d="M33.7556 14.1128H30.9431C30.5547 14.1128 30.2399 14.4276 30.2399 14.816C30.2399 15.2043 30.5547 15.5191 30.9431 15.5191H33.7556C34.1438 15.5191 34.4587 15.2043 34.4587 14.816C34.4587 14.4276 34.1439 14.1128 33.7556 14.1128Z"
        fill={colour}
      />
      <path
        d="M30.9431 12.355H32.3493C32.7376 12.355 33.0524 12.0402 33.0524 11.6519C33.0524 11.2635 32.7376 10.9487 32.3493 10.9487H30.9431C30.5547 10.9487 30.2399 11.2635 30.2399 11.6519C30.2399 12.0402 30.5548 12.355 30.9431 12.355Z"
        fill={colour}
      />
      <path
        d="M28.8394 22.7137C28.8358 22.7111 28.8342 22.7087 28.8337 22.7087L28.8336 22.7088V6.92195C28.8336 4.20798 26.6257 2 23.9117 2C21.1977 2 18.9897 4.20798 18.9897 6.92195L18.9902 22.7073C18.9902 22.7074 18.989 22.7099 18.9848 22.713C16.7982 24.2883 15.4858 26.8349 15.4741 29.5254C15.4666 31.2534 15.9845 32.9204 16.9717 34.3462C17.936 35.7389 19.2734 36.8014 20.8395 37.4185C21.201 37.5609 21.6091 37.3834 21.7515 37.0222C21.8938 36.6609 21.7164 36.2526 21.3552 36.1102C18.6662 35.0504 16.8679 32.4066 16.8804 29.5315C16.8902 27.2895 17.9841 25.1671 19.8068 23.8541C20.1757 23.5882 20.396 23.1606 20.396 22.71V6.92195C20.396 4.9834 21.9731 3.40627 23.9117 3.40627C25.8502 3.40627 27.4274 4.9834 27.4274 6.92195V22.7097C27.4274 23.1604 27.6479 23.5884 28.0174 23.8546C29.8494 25.1746 30.943 27.3086 30.943 29.5629C30.943 32.4809 29.1872 35.0526 26.47 36.1145C26.1083 36.2558 25.9298 36.6636 26.071 37.0253C26.1796 37.3029 26.445 37.4727 26.7261 37.4727C26.8112 37.4727 26.8978 37.4571 26.9819 37.4242C28.5458 36.813 29.8811 35.7586 30.8433 34.3749C31.8286 32.9583 32.3493 31.2942 32.3493 29.5627C32.3493 26.8578 31.0372 24.2973 28.8394 22.7137Z"
        fill={colour}
      />
      <path
        d="M23.9117 4.81255C22.7486 4.81255 21.8023 5.75882 21.8023 6.92195V24.3487C20.8679 24.7286 20.0368 25.3628 19.4247 26.1708C18.6802 27.1537 18.2866 28.3268 18.2866 29.5629C18.2866 32.6646 20.81 35.188 23.9117 35.188C27.0134 35.188 29.5368 32.6646 29.5368 29.5629C29.5368 28.3268 29.1432 27.1538 28.3986 26.1708C27.7866 25.3628 26.9554 24.7286 26.0211 24.3487V6.92195C26.0211 5.75882 25.0748 4.81255 23.9117 4.81255ZM23.9117 6.21882C24.2994 6.21882 24.6148 6.53424 24.6148 6.92195V9.54246H23.2086V6.92195C23.2086 6.53424 23.5241 6.21882 23.9117 6.21882ZM28.1305 29.5629C28.1305 31.8891 26.238 33.7817 23.9117 33.7817C21.5854 33.7817 19.6929 31.8891 19.6929 29.5629C19.6929 27.7106 20.932 26.048 22.7061 25.5199C23.0042 25.4311 23.2086 25.157 23.2086 24.8459V10.9487H24.6149V24.846C24.6149 25.1571 24.8192 25.4311 25.1174 25.5199C26.8914 26.0481 28.1305 27.7106 28.1305 29.5629Z"
        fill={colour}
      />
      <path
        d="M11.5625 11.8395C9.92125 11.8395 8.586 13.1747 8.586 14.816C8.586 16.4572 9.92125 17.7924 11.5625 17.7924C13.2038 17.7924 14.539 16.4572 14.539 14.816C14.539 13.1748 13.2038 11.8395 11.5625 11.8395ZM11.5625 16.3861C10.6967 16.3861 9.99227 15.6817 9.99227 14.816C9.99227 13.9501 10.6967 13.2457 11.5625 13.2457C12.4283 13.2457 13.1327 13.9501 13.1327 14.816C13.1327 15.6817 12.4283 16.3861 11.5625 16.3861Z"
        fill={colour}
      />
      <path
        d="M11.5624 10.4334C11.9508 10.4334 12.2656 10.1186 12.2656 9.73024V8.67555C12.2656 8.28722 11.9508 7.97243 11.5624 7.97243C11.1741 7.97243 10.8593 8.28722 10.8593 8.67555V9.73024C10.8593 10.1186 11.1741 10.4334 11.5624 10.4334Z"
        fill={colour}
      />
      <path
        d="M7.50873 11.663L6.59607 11.1364C6.25963 10.9423 5.82974 11.0577 5.63561 11.394C5.44154 11.7304 5.55686 12.1604 5.89323 12.3545L6.80589 12.8811C6.91656 12.945 7.03743 12.9754 7.15668 12.9754C7.39975 12.9754 7.63614 12.8492 7.76636 12.6236C7.96042 12.2871 7.84504 11.8572 7.50873 11.663Z"
        fill={colour}
      />
      <path
        d="M6.80497 16.7504L5.89302 17.277C5.55671 17.4712 5.44154 17.9012 5.63574 18.2375C5.76596 18.4631 6.00228 18.5891 6.24528 18.5891C6.3646 18.5891 6.48554 18.5587 6.59628 18.4948L7.50823 17.9681C7.84454 17.7739 7.95971 17.3439 7.76551 17.0076C7.57131 16.6714 7.14127 16.5562 6.80497 16.7504Z"
        fill={colour}
      />
      <path
        d="M11.5624 19.1987C11.1741 19.1987 10.8593 19.5135 10.8593 19.9018V20.9565C10.8593 21.3448 11.1741 21.6596 11.5624 21.6596C11.9508 21.6596 12.2656 21.3448 12.2656 20.9565V19.9018C12.2656 19.5135 11.9508 19.1987 11.5624 19.1987Z"
        fill={colour}
      />
      <path
        d="M16.5286 18.4948C16.6393 18.5588 16.7603 18.5892 16.8796 18.5892C17.1226 18.5892 17.3589 18.4631 17.4891 18.2376C17.6833 17.9013 17.5682 17.4713 17.2319 17.2771L16.3199 16.7504C15.9835 16.5562 15.5536 16.6714 15.3594 17.0077C15.1652 17.344 15.2803 17.774 15.6166 17.9682L16.5286 18.4948Z"
        fill={colour}
      />
      <path
        d="M15.9682 12.9753C16.0874 12.9753 16.2083 12.9449 16.319 12.8811L17.2317 12.3544C17.568 12.1604 17.6834 11.7303 17.4893 11.3939C17.2952 11.0576 16.8652 10.9423 16.5288 11.1363L15.6162 11.663C15.2799 11.857 15.1645 12.2871 15.3586 12.6234C15.4888 12.8491 15.7252 12.9753 15.9682 12.9753Z"
        fill={colour}
      />
      <path
        d="M23.9117 38C24.3001 38 24.6149 37.6852 24.6149 37.2969C24.6149 36.9085 24.3001 36.5938 23.9117 36.5938C23.5234 36.5938 23.2086 36.9085 23.2086 37.2969C23.2086 37.6852 23.5234 38 23.9117 38Z"
        fill={colour}
      />
    </svg>
  )
}

HotTemperatureIcon.defaultProps = {
  colour: '#000000',
}

HotTemperatureIcon.propTypes = {
  colour: PropTypes.string,
}

export default HotTemperatureIcon
