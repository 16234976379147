import { useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { ReactComponent as ChevronIcon } from '../../../../assets/icons/dropdown-chevron-icon.svg'
import clsx from 'clsx'
import PropTypes from 'prop-types'

const BrowseWorkflowStepSideNavigationLink = ({ step, index }) => {
  const {
    category: categoryPath,
    workflow: workflowPath,
    step: stepPath,
  } = useParams()
  const defaultOpenState = window.localStorage.getItem(
    `workflowSideNav${step._id}`,
  )
  const [isOpen, setIsOpen] = useState(
    defaultOpenState === 'closed' ? false : true,
  )
  const isChildActive = !!step?.nestedSteps?.find(
    child => child.slug === stepPath,
  )
  const basePath = `browse/${categoryPath}/${workflowPath}`

  function toggleIsOpen() {
    const newValue = !isOpen
    setIsOpen(newValue)
    window.localStorage.setItem(
      `workflowSideNav${step._id}`,
      newValue ? 'open' : 'closed',
    )
  }

  return (
    <div className="step-side-nav-link">
      <div
        className={clsx('step-side-nav-link__title', {
          open: isOpen,
          active: stepPath === step?.slug,
          'active-child': isChildActive,
        })}
      >
        <Link to={`/${basePath}/${step?.slug}`}>
          {index}. {step?.name}
        </Link>
        {step?.nestedSteps?.length > 0 ? (
          <button onClick={toggleIsOpen}>
            <ChevronIcon />
          </button>
        ) : null}
      </div>
      {isOpen ? (
        <div className="step-side-nav-link__children">
          {step?.nestedSteps?.map((nestedStep, nestedIndex) => (
            <div
              className={clsx('step-side-nav-link__child', {
                active: nestedStep?.slug === stepPath,
              })}
              key={step?._id}
            >
              {nestedStep?.slug === stepPath ? (
                <span>
                  {index}.{nestedIndex + 1} {nestedStep?.name}
                </span>
              ) : (
                <Link to={`/${basePath}/${nestedStep?.slug}`}>
                  {index}.{nestedIndex + 1} {nestedStep?.name}
                </Link>
              )}
            </div>
          ))}
        </div>
      ) : null}
    </div>
  )
}

BrowseWorkflowStepSideNavigationLink.propTypes = {
  step: PropTypes.object,
  index: PropTypes.number,
}

export default BrowseWorkflowStepSideNavigationLink
