import React from 'react'
import { useLocation } from 'react-router-dom'

function useQueryParams() {
  const { search } = useLocation()

  return React.useMemo(() => {
    const params = new URLSearchParams(search)
    const keys = [...params.keys()]
    return keys.reduce((acc, cur) => {
      acc[cur] = params.get(cur)
      return acc
    }, {})
  }, [search])
}

export default useQueryParams
