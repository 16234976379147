import React from 'react'
import PropTypes from 'prop-types'

function DownloadIcon({ colour }, props) {
  return (
    <svg
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M35.2659 14.1017C35.3781 13.5004 35.4349 12.8901 35.4357 12.2784C35.4288 6.59725 30.8179 1.99736 25.1367 2.00422C20.812 2.00942 16.9524 4.71907 15.4784 8.78486C12.8393 7.7343 9.84818 9.02204 8.79754 11.6612C8.7066 11.8896 8.63217 12.1242 8.57483 12.3633C4.36084 12.9935 1.45568 16.9205 2.08591 21.1345C2.65112 24.9139 5.89788 27.7099 9.71919 27.7083H16.1483C16.8585 27.7083 17.4341 27.1327 17.4341 26.4225C17.4341 25.7123 16.8585 25.1366 16.1483 25.1366H9.71919C6.8786 25.1366 4.57586 22.8339 4.57586 19.9933C4.57586 17.1527 6.8786 14.85 9.71919 14.85C10.4294 14.85 11.005 14.2743 11.005 13.5641C11.0074 12.1439 12.1606 10.9944 13.5809 10.9967C14.2526 10.9979 14.8972 11.2617 15.3768 11.7318C15.8826 12.2303 16.6967 12.2243 17.1952 11.7186C17.3823 11.5286 17.5058 11.2853 17.5485 11.0221C18.2465 6.82305 22.2162 3.98494 26.4152 4.68291C30.6142 5.38087 33.4523 9.3506 32.7544 13.5496C32.7128 13.8 32.6587 14.0481 32.5927 14.2931C32.4449 14.8299 32.6592 15.4007 33.1238 15.7075C35.4907 17.278 36.1363 20.47 34.5657 22.8369C33.6151 24.2695 32.0116 25.1324 30.2924 25.1365H26.4349C25.7247 25.1365 25.149 25.7122 25.149 26.4223C25.149 27.1325 25.7247 27.7082 26.4349 27.7082H30.2924C34.5532 27.7042 38.0041 24.2468 38 19.9859C37.9979 17.7181 36.998 15.566 35.2659 14.1017Z"
        fill={colour}
      />
      <path
        d="M28.6007 29.3709C28.1024 28.8897 27.3124 28.8897 26.8142 29.3709L22.5773 33.6052V14.8501C22.5773 14.1399 22.0017 13.5642 21.2915 13.5642C20.5813 13.5642 20.0056 14.1399 20.0056 14.8501V33.6051L15.7714 29.3708C15.2606 28.8775 14.4466 28.8917 13.9532 29.4024C13.472 29.9007 13.472 30.6907 13.9532 31.189L20.3823 37.6181C20.8838 38.1208 21.698 38.1219 22.2008 37.6203C22.2015 37.6196 22.2023 37.6188 22.2031 37.6181L28.6322 31.189C29.1256 30.6782 29.1115 29.8642 28.6007 29.3709Z"
        fill={colour}
      />
    </svg>
  )
}

DownloadIcon.defaultProps = {
  colour: '#000000',
}

DownloadIcon.propTypes = {
  colour: PropTypes.string,
}

export default DownloadIcon
