import React from 'react'
import PropTypes from 'prop-types'

function ComputerScreenIcon({ colour }, props) {
  return (
    <svg
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M36.2 5H3.8C2.8064 5.00113 2.00113 5.8064 2 6.8V28.4C2.00113 29.3936 2.8064 30.1989 3.8 30.2H17.6V31.4H15.2C13.5439 31.4019 12.2019 32.7439 12.2 34.4C12.2 34.7314 12.4687 35 12.8 35H27.2C27.5314 35 27.8 34.7314 27.8 34.4C27.7981 32.7439 26.4561 31.4019 24.8 31.4H22.4V30.2H36.2C37.1936 30.1989 37.9989 29.3936 38 28.4V6.8C37.9989 5.8064 37.1936 5.00113 36.2 5ZM24.8 32.6C25.5625 32.6009 26.2421 33.0814 26.4972 33.8H13.5028C13.7579 33.0814 14.4375 32.6009 15.2 32.6H24.8ZM18.8 31.4V30.2H21.2V31.4H18.8ZM36.8 28.4C36.7996 28.7312 36.5312 28.9996 36.2 29H3.8C3.4688 28.9996 3.20038 28.7312 3.2 28.4V26.6H36.8V28.4ZM36.8 25.4H3.2V6.8C3.20038 6.4688 3.4688 6.20038 3.8 6.2H36.2C36.5312 6.20038 36.7996 6.4688 36.8 6.8V25.4Z"
        fill={colour}
      />
      <path
        d="M33.8 17H26.6C26.2686 17 26 17.2686 26 17.6V20C26 20.3314 26.2686 20.6 26.6 20.6H28.157L27.803 24.14C27.7699 24.4697 28.0103 24.7638 28.34 24.797C28.4897 24.8121 28.6396 24.7703 28.76 24.68L30.6526 23.2606L31.5008 24.5328C31.686 24.8076 32.059 24.8802 32.3337 24.6949C32.6065 24.511 32.6803 24.1418 32.4992 23.8672L31.7962 22.8127L33.9177 22.3884C34.2427 22.3234 34.4533 22.0073 34.3884 21.6824C34.3594 21.5377 34.2781 21.4086 34.16 21.32L33.2 20.6H33.8C34.1314 20.6 34.4 20.3314 34.4 20V17.6C34.4 17.2686 34.1314 17 33.8 17ZM28.277 19.4H27.2V18.2H28.397L28.277 19.4ZM30.44 21.9196L29.133 22.8999L29.4912 19.318L32.3664 21.4744L30.6899 21.8101C30.5479 21.8386 30.44 21.9196 30.44 21.9196ZM33.2 19.4H31.6L30.0001 18.2H33.2V19.4Z"
        fill={colour}
      />
      <path
        d="M23 8.6H20C19.6687 8.6 19.4 8.86865 19.4 9.2C19.4 9.53135 19.6687 9.8 20 9.8H23C23.3314 9.8 23.6 9.53135 23.6 9.2C23.6 8.86865 23.3314 8.6 23 8.6Z"
        fill={colour}
      />
      <path
        d="M23 11H17C16.6687 11 16.4 11.2686 16.4 11.6C16.4 11.9314 16.6687 12.2 17 12.2H23C23.3314 12.2 23.6 11.9314 23.6 11.6C23.6 11.2686 23.3314 11 23 11Z"
        fill={colour}
      />
      <path
        d="M23 13.4H17C16.6687 13.4 16.4 13.6687 16.4 14C16.4 14.3314 16.6687 14.6 17 14.6H23C23.3314 14.6 23.6 14.3314 23.6 14C23.6 13.6687 23.3314 13.4 23 13.4Z"
        fill={colour}
      />
      <path
        d="M9.59933 19.37C9.72038 19.4096 9.85088 19.4096 9.97193 19.37C11.0292 18.9776 11.9964 18.3758 12.8156 17.6007C14.0728 16.4171 15.4976 14.3007 15.1452 10.9372C15.126 10.7542 15.0239 10.5901 14.8682 10.492L10.1057 7.49195C9.91028 7.36895 9.66158 7.36895 9.46613 7.49195L4.70363 10.492C4.54793 10.5901 4.44585 10.7542 4.42665 10.9372C4.3437 12.1084 4.4724 13.2849 4.8066 14.4104C5.5404 16.8708 7.19753 18.5859 9.59933 19.37ZM5.60415 11.3429L9.78563 8.70913L13.9773 11.3491C14.1357 13.5112 13.48 15.3091 12.0261 16.6956C11.3703 17.3161 10.6078 17.8131 9.77528 18.1625C7.85783 17.4763 6.57713 16.1119 5.96708 14.105C5.70195 13.2091 5.57948 12.277 5.60415 11.3429Z"
        fill={colour}
      />
      <path
        d="M9.20002 15.8C9.21105 15.8 9.22207 15.8 9.23317 15.799C9.40357 15.7896 9.5619 15.708 9.66855 15.5748L12.0685 12.5748C12.2756 12.316 12.2337 11.9384 11.9749 11.7314C11.716 11.5244 11.3385 11.5663 11.1315 11.8251L9.15022 14.3016L8.4243 13.5756C8.19 13.3413 7.81005 13.3413 7.57575 13.5756C7.34145 13.8099 7.34145 14.1898 7.57575 14.4241L8.77575 15.6241C8.88825 15.7366 9.04087 15.7999 9.20002 15.8Z"
        fill={colour}
      />
      <path
        d="M31.4 7.40001C30.9942 7.40001 30.5905 7.45896 30.2017 7.57514C27.9788 6.91229 25.6395 8.17701 24.9766 10.3999C24.3138 12.6227 25.5785 14.962 27.8014 15.6249C28.5844 15.8584 29.4186 15.8584 30.2017 15.6249C32.4245 16.2877 34.7638 15.023 35.4267 12.8002C36.0895 10.5773 34.8248 8.23799 32.602 7.57514C32.2119 7.45881 31.807 7.39986 31.4 7.40001ZM26 11.6C26.0019 10.1732 27.0068 8.94449 28.4049 8.65949C26.7984 10.2907 26.7984 12.9093 28.4049 14.5405C27.0068 14.2555 26.0019 13.0268 26 11.6ZM31.4 14.6C29.7432 14.6 28.4 13.2568 28.4 11.6C28.4 9.94319 29.7432 8.60001 31.4 8.60001C33.0568 8.60001 34.4 9.94319 34.4 11.6C34.3981 13.2561 33.0561 14.5981 31.4 14.6Z"
        fill={colour}
      />
      <path
        d="M32.6 10.4C32.6 10.0687 32.3313 9.8 32 9.8H31.4C30.7372 9.8 30.2 10.3372 30.2 11C30.2 11.6628 30.7372 12.2 31.4 12.2H30.8C30.4686 12.2 30.2 12.4687 30.2 12.8C30.2 13.1314 30.4686 13.4 30.8 13.4H31.4C32.0627 13.4 32.6 12.8628 32.6 12.2C32.6 11.5372 32.0627 11 31.4 11H32C32.3313 11 32.6 10.7314 32.6 10.4Z"
        fill={colour}
      />
      <path
        d="M17.6 9.8C17.9314 9.8 18.2 9.53137 18.2 9.2C18.2 8.86863 17.9314 8.6 17.6 8.6C17.2686 8.6 17 8.86863 17 9.2C17 9.53137 17.2686 9.8 17.6 9.8Z"
        fill={colour}
      />
      <path
        d="M23 15.8H16.4C16.0687 15.8 15.8 16.0687 15.8 16.4C15.8 16.7314 16.0687 17 16.4 17H23C23.3314 17 23.6 16.7314 23.6 16.4C23.6 16.0687 23.3314 15.8 23 15.8Z"
        fill={colour}
      />
      <path
        d="M23 18.2H15.2C14.8686 18.2 14.6 18.4686 14.6 18.8C14.6 19.1313 14.8686 19.4 15.2 19.4H23C23.3313 19.4 23.6 19.1313 23.6 18.8C23.6 18.4686 23.3313 18.2 23 18.2Z"
        fill={colour}
      />
      <path
        d="M23 20.6H6.19998C5.86863 20.6 5.59998 20.8686 5.59998 21.2C5.59998 21.5313 5.86863 21.8 6.19998 21.8H23C23.3313 21.8 23.6 21.5313 23.6 21.2C23.6 20.8686 23.3313 20.6 23 20.6Z"
        fill={colour}
      />
      <path
        d="M23 23H6.19998C5.86863 23 5.59998 23.2686 5.59998 23.6C5.59998 23.9314 5.86863 24.2 6.19998 24.2H23C23.3313 24.2 23.6 23.9314 23.6 23.6C23.6 23.2686 23.3313 23 23 23Z"
        fill={colour}
      />
    </svg>
  )
}

ComputerScreenIcon.defaultProps = {
  colour: '#000000',
}

ComputerScreenIcon.propTypes = {
  colour: PropTypes.string,
}

export default ComputerScreenIcon
