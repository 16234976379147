import React from 'react'
import PropTypes from 'prop-types'

function DiscountIcon({ colour }, props) {
  return (
    <svg
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M14.5016 16.5492C14.7765 16.2743 14.7765 15.8291 14.5016 15.555C14.2275 15.28 13.7823 15.28 13.5073 15.555C13.2324 15.8291 13.2324 16.2743 13.5073 16.5492C13.7823 16.8233 14.2275 16.8233 14.5016 16.5492Z"
        fill={colour}
      />
      <path
        d="M21.4626 17.5435C22.2849 16.7212 22.2849 15.3831 21.4626 14.5607C20.6383 13.7365 19.3035 13.7362 18.4792 14.5607C17.6547 15.385 17.6547 16.719 18.4792 17.5435C19.3016 18.3658 20.64 18.3661 21.4626 17.5435ZM19.4735 15.555C19.7484 15.2803 20.1934 15.2803 20.4683 15.555C20.7421 15.8291 20.7421 16.2749 20.4683 16.549C20.1939 16.8231 19.7476 16.8234 19.4735 16.549C19.1991 16.2749 19.198 15.8305 19.4735 15.555Z"
        fill={colour}
      />
      <path
        d="M18.4792 22.5147C17.6547 23.3392 17.6547 24.6738 18.4792 25.4983C19.3035 26.3226 20.638 26.3228 21.4626 25.4983C22.2852 24.6757 22.2852 23.3373 21.4626 22.5147C20.6383 21.6907 19.3035 21.6905 18.4792 22.5147ZM20.4683 24.5041C20.1934 24.7787 19.7481 24.7787 19.4735 24.5041C19.1991 24.2297 19.198 23.7847 19.4735 23.5093C19.7484 23.2343 20.1934 23.2346 20.4683 23.5093C20.7424 23.7834 20.7424 24.2297 20.4683 24.5041Z"
        fill={colour}
      />
      <path
        d="M14.3094 21.1615C14.3855 21.5427 14.7563 21.7891 15.1367 21.713L25.0801 19.7239C25.4607 19.6479 25.7076 19.2774 25.6316 18.8967C25.5555 18.5157 25.185 18.2691 24.8043 18.3452L14.8609 20.3342C14.4799 20.4103 14.233 20.7808 14.3094 21.1615Z"
        fill={colour}
      />
      <path
        d="M23.3754 5.97794C23.1889 5.97794 23.0101 6.05182 22.8783 6.18365L15.4964 13.5656C15.2217 13.8403 15.2217 14.2855 15.4964 14.5601C15.771 14.8348 16.2162 14.8348 16.4906 14.5601L23.3754 7.67532L32.3243 16.6245L16.5392 32.4095L7.59008 23.4607L12.5133 18.5374C12.788 18.2631 12.788 17.8178 12.5133 17.5432C12.2386 17.2688 11.7934 17.2688 11.5188 17.5432L6.09869 22.9635C5.82404 23.2379 5.82404 23.6831 6.09869 23.9578L16.0421 33.9012C16.3168 34.1759 16.7617 34.1759 17.0364 33.9012L33.816 17.1216C34.0906 16.8469 34.0906 16.4017 33.816 16.1271L23.8726 6.18365C23.7407 6.05182 23.5619 5.97794 23.3754 5.97794Z"
        fill={colour}
      />
      <path
        d="M36.7993 2.20636L33.7814 5.22402C32.0206 3.8944 29.589 3.87271 27.8431 5.16909L25.3711 2.62549C25.3687 2.62302 25.3665 2.62082 25.364 2.61835C24.5439 1.79822 23.2101 1.78943 22.3801 2.61945L2.61829 22.4664C1.79404 23.2907 1.79377 24.6255 2.61829 25.45L5.40715 28.2386C4.78423 30.0214 5.23357 32.0426 6.59559 33.4041C7.95734 34.7664 9.97854 35.2155 11.7613 34.5931L14.5502 37.3817C15.3745 38.2059 16.7098 38.2056 17.5324 37.3828L37.3816 17.6187C38.2061 16.7942 38.2061 15.4602 37.3816 14.636C37.3791 14.6335 37.3769 14.6313 37.3745 14.6288L34.8311 12.1575C36.1229 10.4233 36.1179 7.99064 34.7759 6.21855L37.7936 3.20089C38.0683 2.92624 38.0683 2.48102 37.7936 2.20636C37.5189 1.93171 37.074 1.93171 36.7993 2.20636ZM33.3694 11.7032C33.2365 11.8364 33.1623 12.0171 33.1634 12.2055C33.1648 12.3937 33.2417 12.5736 33.3766 12.7046L36.3901 15.6332C36.6628 15.909 36.6598 16.3517 36.3882 16.6231L16.5393 36.3871C16.2649 36.6615 15.8186 36.6615 15.5445 36.3871L12.426 33.269C12.2914 33.1341 12.1113 33.063 11.9289 33.063C11.8278 33.063 11.7259 33.0847 11.6303 33.1294C10.2782 33.7639 8.65442 33.4747 7.59013 32.4096C6.52501 31.3453 6.23579 29.7218 6.87025 28.3696C6.99604 28.1013 6.94056 27.7832 6.731 27.5737L3.61282 24.4555C3.33844 24.1811 3.33844 23.7351 3.61364 23.4596L23.3758 3.61261C23.6493 3.33932 24.0915 3.33767 24.3667 3.60958L27.2951 6.62312C27.5577 6.89338 28.0073 6.91975 28.2968 6.63054C29.5003 5.42644 31.385 5.2979 32.7734 6.23201L31.7421 7.26334C30.9579 6.88981 29.9895 7.02687 29.3413 7.67506C28.519 8.49765 28.519 9.83606 29.3413 10.6584C30.1656 11.4826 31.5004 11.4829 32.3249 10.6584C32.9879 9.99536 33.1041 9.0228 32.7374 8.25678L33.7685 7.22572C34.6955 8.5979 34.5886 10.4842 33.3694 11.7032ZM31.3304 8.66931C31.6048 8.9437 31.6059 9.38864 31.3304 9.66412C31.0557 9.93878 30.6105 9.93878 30.3358 9.66412C30.0615 9.38974 30.0615 8.94342 30.3356 8.66931C30.6099 8.39548 31.0563 8.39493 31.3304 8.66931Z"
        fill={colour}
      />
    </svg>
  )
}

DiscountIcon.defaultProps = {
  colour: '#000000',
}

DiscountIcon.propTypes = {
  colour: PropTypes.string,
}

export default DiscountIcon
