import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { get, isEmpty } from 'lodash'
import clsx from 'clsx'
import renderConsumerField from '../../../../utilities/fields/renderConsumerFields'
import Accordion from './Accordion'
import '../../../../styles/consumer-flow/base-components/accordions.scss'
import useEntryHighlight from '../../../../hooks/useEntryHighlight'
import highlightTextInHTML from '../../../../utilities/find-replace/highlightTextInHTML'

const AccordionsAndContent = ({ component }) => {
  const highlight = useEntryHighlight()
  const headingRef = useRef()
  const [openAccordions, setOpenAccordions] = useState(
    getDefaultOpenAccordions({ component, highlight }),
  )
  const accordions = get(component, 'subComponents', []).filter(
    subcomp => subcomp.subComponentType === 'accordion',
  )
  const content = component?.subComponents?.find(
    subcomp => subcomp.subComponentType === 'content',
  )

  const configurations = component?.configurations || {}
  const contentFields = content?.fields || []
  const { title, titleColor, description } = configurations
  const hasInnerText = !!headingRef?.current?.innerText

  useEffect(
    function highlightResultsOnSearch() {
      if (highlight?.itemId === component?._id && hasInnerText) {
        highlightTextInHTML({ ref: headingRef, highlight })
      }
    },
    [highlight, hasInnerText],
  )

  function handleAccordionToggle(accordion) {
    const id = accordion?._id || accordion?.id
    if (openAccordions.find(item => item === id)) {
      setOpenAccordions(openAccordions.filter(item => item !== id))
    } else {
      setOpenAccordions([...openAccordions, id])
    }
  }

  const allAccordionsToggled =
    accordions.length > 0 && accordions.length === openAccordions.length
  function toggleAllAccordions() {
    if (allAccordionsToggled) {
      setOpenAccordions([])
    } else {
      setOpenAccordions(
        accordions.map(accordion => {
          const id = accordion?._id || accordion?.id
          return id
        }),
      )
    }
  }

  function getDefaultOpenAccordions() {
    let openAccordions = []
    if (highlight) {
      const accordions = component?.subComponents || []
      accordions.forEach(accordion => {
        const layouts = accordion?.layouts || []
        const fields = layouts.reduce((acc, cur) => {
          acc.push(...(cur?.fields || []))
          return acc
        }, [])
        fields.forEach(field => {
          if (field._id === highlight?.itemId) {
            openAccordions.push(accordion._id)
          }
        })
      })
    }
    return openAccordions
  }

  const headingClass = configurations?.titleFormat || 'h2'

  return !isEmpty(accordions) ? (
    <div className="accordions accordions--inc-content">
      <div className="accordions__heading flex" ref={headingRef}>
        <div className="flex-fill">
          {title && (
            <h2
              className={clsx('accordions__title', headingClass)}
              style={{ ...(titleColor && { color: titleColor }) }}
            >
              {title}
            </h2>
          )}
          {description && (
            <p className="accordions__description">{description}</p>
          )}
        </div>
        <div className="flex align-end">
          <button
            className="btn-text btn-text--bright-purple"
            onClick={() => toggleAllAccordions()}
          >
            {allAccordionsToggled ? 'Close' : 'Open'} All
          </button>
        </div>
      </div>

      <div className="accordions__content flex">
        <div className="accordions__content-column flex-fill">
          {accordions.map(accordion => {
            const id = accordion?._id || accordion?.id

            return (
              <Accordion
                key={id}
                subcomponent={accordion}
                isOpen={!!openAccordions.find(item => item === id)}
                onToggle={handleAccordionToggle}
              />
            )
          })}
        </div>
        <div className="accordions__content-column flex-fill">
          <div>
            {contentFields.map(field => {
              const FieldComponent = renderConsumerField(field?.fieldType)
              const fieldHighlight =
                highlight?.itemId === field?._id ? highlight : null
              return FieldComponent ? (
                <FieldComponent
                  key={field?._id}
                  field={field}
                  highlight={fieldHighlight}
                />
              ) : null
            })}
          </div>
        </div>
      </div>
    </div>
  ) : null
}

AccordionsAndContent.propTypes = {
  component: PropTypes.object,
}

export default AccordionsAndContent
