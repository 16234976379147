import { SOCKET_SET_BLOCKED_RESOURCES } from '../../../socket/socket'

const onBlockedResources = ({ dispatch, args }) => {
  const { blockedResources } = args
  dispatch({
    type: SOCKET_SET_BLOCKED_RESOURCES,
    payload: blockedResources,
  })
}

export default onBlockedResources
