import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Outlet } from 'react-router-dom'
import { selectManageStepModalVisibility } from '../redux/user/userSelections'
import '../styles/editor-flow/manage-workflows/manage-workflows.scss'
import ManageWorkflowStepModalContainer from '../components/editor-flow/manage-workflow/elements/manage-step-modal/ManageWorkflowStepModalContainer'

function ManageWorkflowContainer() {
  const isManageStepModalVisible = useSelector(selectManageStepModalVisibility)

  return (
    <div className="manage-workflow-container view-container">
      <Outlet />
      {isManageStepModalVisible ? <ManageWorkflowStepModalContainer /> : null}
    </div>
  )
}

export default ManageWorkflowContainer
