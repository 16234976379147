import React from 'react'
import PropTypes from 'prop-types'

function HourglassIcon({ colour }, props) {
  return (
    <svg
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M20 24.7109C20.3881 24.7109 20.7031 24.3959 20.7031 24.0078C20.7031 23.6197 20.3881 23.3047 20 23.3047C19.6119 23.3047 19.2969 23.6197 19.2969 24.0078C19.2969 24.3959 19.6119 24.7109 20 24.7109Z"
        fill={colour}
      />
      <path
        d="M23.8449 19.3839C25.4764 18.3927 26.8269 16.9974 27.7509 15.3487C28.66 13.7263 29.1406 11.8904 29.1406 10.0395V6.0979C29.9591 5.80759 30.5469 5.02591 30.5469 4.10938V2.70312C30.5469 2.31476 30.2321 2 29.8438 2H10.1562C9.76788 2 9.45312 2.31476 9.45312 2.70312V4.10938C9.45312 5.02591 10.0409 5.80759 10.8594 6.0979V10.0395C10.8594 11.8904 11.34 13.7263 12.2491 15.3487C13.1731 16.9974 14.5239 18.393 16.1553 19.3839C16.3638 19.5106 16.4885 19.741 16.4885 20C16.4885 20.2593 16.3638 20.4894 16.1553 20.6163C14.5239 21.6073 13.1731 23.0026 12.2491 24.6513C11.34 26.2737 10.8594 28.1096 10.8594 29.9605V33.9021C10.0409 34.1924 9.45312 34.9741 9.45312 35.8906V37.2969C9.45312 37.6852 9.76788 38 10.1562 38H29.8438C30.2321 38 30.5469 37.6852 30.5469 37.2969V35.8906C30.5469 34.9741 29.9591 34.1924 29.1406 33.9021V29.9605C29.1406 28.1096 28.66 26.2737 27.7509 24.6513C26.8269 23.0026 25.4761 21.607 23.8447 20.6161C23.6362 20.4894 23.5115 20.259 23.5115 20C23.5115 19.7407 23.6362 19.5106 23.8449 19.3839ZM10.8594 3.40625H29.1406V4.10938C29.1406 4.49719 28.8253 4.8125 28.4375 4.8125H11.5625C11.1747 4.8125 10.8594 4.49719 10.8594 4.10938V3.40625ZM29.1406 36.5938H10.8594V35.8906C10.8594 35.5028 11.1747 35.1875 11.5625 35.1875H28.4375C28.8253 35.1875 29.1406 35.5028 29.1406 35.8906V36.5938ZM23.1149 21.818C26.0073 23.5752 27.7344 26.6193 27.7344 29.9605V33.7812H12.2656V29.9605C12.2656 26.6193 13.9927 23.5752 16.8851 21.818C17.5174 21.4343 17.8947 20.7545 17.8947 20C17.8947 19.2455 17.5174 18.5657 16.8851 18.182C13.9927 16.4248 12.2656 13.3807 12.2656 10.0395V6.21875H27.7344V10.0395C27.7344 13.3807 26.0073 16.4248 23.1149 18.182C22.4826 18.5657 22.1053 19.2455 22.1053 20C22.1053 20.7545 22.4826 21.4343 23.1149 21.818Z"
        fill={colour}
      />
      <path
        d="M20.5603 26.3605C20.4276 26.1837 20.2195 26.0793 19.9984 26.0793C19.9984 26.0793 19.9981 26.0793 19.9978 26.0793C19.777 26.0793 19.5688 26.1831 19.4361 26.3594C19.1711 26.7121 18.8409 27.0117 18.455 27.251C17.6497 27.736 16.9304 28.0392 16.2954 28.307C14.8875 28.9008 13.6719 29.4136 13.6719 31.3626V31.6719C13.6719 32.0602 13.9866 32.375 14.375 32.375H25.625C26.0134 32.375 26.3281 32.0602 26.3281 31.6719V31.3626C26.3281 29.4147 25.1095 28.8997 23.6985 28.3032C23.0583 28.0324 22.3327 27.7259 21.5172 27.235C21.1434 27.003 20.8215 26.7085 20.5603 26.3605ZM24.8941 30.9688H15.1056C15.2127 30.3189 15.6557 30.103 16.842 29.6029C17.4877 29.3304 18.2911 28.9918 19.1845 28.4534C19.1867 28.4518 19.1892 28.4504 19.1914 28.449C19.4831 28.2686 19.7525 28.0623 19.9973 27.8324C20.2359 28.0568 20.4974 28.2573 20.78 28.4326C20.7825 28.4339 20.785 28.4356 20.7874 28.437C21.6905 28.9808 22.5002 29.3233 23.1509 29.5985C24.3421 30.1019 24.7867 30.3189 24.8941 30.9688Z"
        fill={colour}
      />
      <path
        d="M26.3281 10.0436V8.74995C26.3281 8.49425 26.1894 8.25886 25.9659 8.13499C25.7423 8.01085 25.4693 8.01826 25.2523 8.15367C23.6758 9.13914 21.8597 9.65989 20 9.65989C18.1406 9.65989 16.3242 9.13914 14.7477 8.15367C14.5307 8.01826 14.2577 8.01112 14.0341 8.13499C13.8106 8.25886 13.6719 8.49425 13.6719 8.74995V10.0436C13.6719 11.2598 14.1415 12.5688 15.0298 13.8289C15.8906 15.0501 17.0845 16.1407 18.4828 16.9837C18.7668 17.1603 19.045 17.4107 19.2969 17.7134V21.1953C19.2969 21.5836 19.6116 21.8984 20 21.8984C20.3881 21.8984 20.7031 21.5836 20.7031 21.1953V17.7148C20.9597 17.4086 21.2472 17.1523 21.545 16.9677C24.4061 15.242 26.3281 12.46 26.3281 10.0436ZM20.8155 15.7653C20.8133 15.7669 20.8108 15.768 20.8086 15.7697C20.5282 15.943 20.2565 16.1575 20.0025 16.4041C19.7561 16.163 19.4944 15.9562 19.22 15.7861C19.2175 15.7845 19.215 15.7831 19.2126 15.7815C16.7395 14.292 15.0781 11.9862 15.0781 10.0436V9.94005C16.6082 10.6794 18.2881 11.0661 20 11.0661C21.7119 11.0661 23.3918 10.6794 24.9219 9.94005V10.0436C24.9219 11.9558 23.2333 14.3085 20.8155 15.7653Z"
        fill={colour}
      />
    </svg>
  )
}

HourglassIcon.defaultProps = {
  colour: '#000000',
}

HourglassIcon.propTypes = {
  colour: PropTypes.string,
}

export default HourglassIcon
