import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { FileUploader } from 'react-drag-drop-files'
import '../../../styles/global-components/media-library/media-library.scss'
import { useDispatch, useSelector } from 'react-redux'
import Modal from 'react-modal'
import MaterialLoader from '../elements/MaterialLoader'
import axios from 'axios'
import useAuthToken from '../../../hooks/useAuthToken'
import { selectActiveClientId } from '../../../redux/user/userSelections'
import {
  fetchMediaLibrary,
  mediaLibraryDeleteFiles,
  mediaLibraryMoveFiles,
  selectMediaLibraryFolderState,
  updateMediaLibraryImage,
} from '../../../redux/media-library/mediaLibrary'
import { ReactComponent as CloseModalIcon } from '../../../assets/icons/close-icon.svg'
import { ReactComponent as EditIcon } from '../../../assets/icons/edit-icon.svg'
import { ReactComponent as SearchIcon } from '../../../assets/icons/search-icon.svg'
import { ReactComponent as DeleteIcon } from '../../../assets/icons/delete-icon.svg'
import { ReactComponent as FolderIcon } from '../../../assets/icons/folder-icon.svg'
import { ReactComponent as ImageIcon } from '../../../assets/icons/image-icon-no-border.svg'

import { selectUserState } from '../../../redux/user/user'
import {
  editMediaLibraryFolder,
  fetchMediaLibraryTree,
  selectMediaLibraryTreeState,
} from '../../../redux/media-library/mediaLibraryTree'
import MediaLibraryContentGrid from './MediaLibraryContentGrid'
import {
  DndContext,
  DragOverlay,
  MouseSensor,
  pointerWithin,
  useSensor,
  useSensors,
} from '@dnd-kit/core'
import MediaLibraryFolders from './MediaLibraryFolders/MediaLibraryFolders'
import MediaLibraryBreadcrumbs from './MediaLibraryBreadcrumbs'
import useDebounce from '../../../hooks/useDebounce'
import MediaLibraryDeleteModal from './MediaLibraryDeleteModal'
import MediaLibraryEditPanel from './MediaLibraryEditPanel'
import Select from 'react-select'
import FilterDropdown from '../filters/FilterDropdown'

const FILE_TYPES = [
  'JPG',
  'JPEG',
  'PNG',
  'SVG',
  'CSV',
  'DOC',
  'DOCX',
  'ODT',
  'JSON',
  'ODP',
  'PPT',
  'PPTX',
  'ODS',
  'XLS',
  'XLSX',
  'PDF',
  'TXT',
]

const TYPE_FILTER_OPTIONS = [
  {
    label: 'Images',
    value: 'image',
  },
  {
    label: 'Documents',
    value: 'document',
  },
]

const MediaLibrary = ({
  isOpen,
  onCancel,
  onSubmit,
  mode = 'management',
  filter,
  folderId = null,
  itemId = null,
  onFolderChange = () => {},
}) => {
  const dispatch = useDispatch()
  const { token } = useAuthToken({})
  const mouseSensor = useSensor(MouseSensor, {
    // Require the mouse to move by 10 pixels before activating
    activationConstraint: {
      delay: 250,
    },
  })
  const sensors = useSensors(mouseSensor)
  const {
    data: folderTree,
    loading: folderTreeLoading,
    error: folderTreeError,
  } = useSelector(selectMediaLibraryTreeState)

  const uploadRef = useRef({})
  const uploadFileInputRef = useRef(null)
  const [uploadState, setUploadState] = useState({})
  const [showSearchInput, setShowSearchInput] = useState(false)
  const [search, setSearch] = useState('')
  const [sort, setSort] = useState(
    localStorage.getItem('mediaLibrarySort') || 'latest',
  )
  const [editLoading, setEditLoading] = useState(false)
  const [editError, setEditError] = useState(null)
  const [fileToEdit, setFileToEdit] = useState(null)
  const [pendingSubmit, setPendingSubmit] = useState(null)
  const [selectedItems, setSelectedItems] = useState([])
  const [itemsToDelete, setItemsToDelete] = useState([])
  const [currentFolderId, setCurrentFolderId] = useState(folderId)
  const [isDroppingFile, setIsDroppingFile] = useState(false)
  const [currentDragItem, setCurrentDragItem] = useState(null)
  const [typeFilter, setTypeFilter] = useState(filter)

  const activeClientId = useSelector(selectActiveClientId)
  const {
    data,
    loading: imagesLoading,
    error,
  } = useSelector(state =>
    selectMediaLibraryFolderState(state, currentFolderId),
  )

  const loading = folderTreeLoading || imagesLoading
  const uploadingFiles = Object.values(uploadState).filter(
    upload =>
      upload.status !== 'complete' && upload.folderId === currentFolderId,
  )
  const folder = folderTree?.[currentFolderId] || {}
  const filteredData = typeFilter
    ? data?.filter(item => item.type === typeFilter)
    : data
  const sortedData = sortItems(filteredData, sort)
  const items = [...(uploadingFiles || []), ...sortedData]

  useEffect(() => {
    if (token && isOpen && !data && !loading && !error) {
      dispatch(fetchMediaLibrary(token, currentFolderId)).then(res => {
        const item = res?.find(search => search?._id === itemId)
        if (item) {
          setFileToEdit(item)
        }
      })
    }
  }, [token, data, imagesLoading, error, isOpen])

  useEffect(() => {
    if (
      token &&
      isOpen &&
      !folderTree &&
      !folderTreeLoading &&
      !folderTreeError
    ) {
      dispatch(fetchMediaLibraryTree(token))
    }
  }, [token, isOpen, folderTree, folderTreeError, folderTreeLoading])

  useEffect(() => {
    const interval = setInterval(() => {
      setUploadState(uploadRef.current)
    }, 100)
    return () => clearInterval(interval)
  }, [])

  useEffect(() => {
    setShowSearchInput(false)
    setSearch('')
    setFileToEdit(null)
    setCurrentFolderId(folderId)
    setPendingSubmit(null)
  }, [isOpen])

  useEffect(() => {
    if (isOpen && itemId) {
      const item = data?.find(search => search?._id === itemId)
      if (item) {
        setFileToEdit(item)
      }
    }
  }, [isOpen, itemId])

  const debouncedRequest = useDebounce(() => {
    if (search === '') {
      setCurrentFolderId(null)
    } else {
      dispatch(fetchMediaLibrary(token, 'searchResults', search)).then(() => {
        setCurrentFolderId('searchResults')
      })
    }
  })

  function handleDropFiles(fileList) {
    const files = [...fileList]
    files.forEach(handleUpload)
  }

  function handleUpload(file) {
    const now = Date.now()
    const formData = new FormData()
    formData.append('file', file)
    if (currentFolderId) {
      formData.append('folderId', currentFolderId)
    }
    axios
      .post(
        `${process.env.REACT_APP_PRODUCT_API}/api/v1/media/create/${activeClientId}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          onUploadProgress: progressEvent => {
            const progress = Math.round(
              (100 * progressEvent.loaded) / progressEvent.total,
            )
            uploadRef.current = {
              ...uploadRef.current,
              [now]: {
                id: now,
                name: file?.name,
                status: 'uploading',
                folderId: currentFolderId,
                progress,
              },
            }
          },
        },
      )
      .then(res => {
        uploadRef.current = {
          ...uploadRef.current,
          [now]: {
            name: file?.name,
            progress: 100,
            id: res?.data?.id,
            folderId: currentFolderId,
            status: 'complete',
          },
        }
        dispatch(fetchMediaLibrary(token, currentFolderId))
      })
      .catch(err => {
        const errors = err?.response?.data?.errors
        if (errors) {
          uploadRef.current = {
            ...uploadRef.current,
            [now]: {
              id: now,
              name: file?.name,
              progress: 100,
              status: 'error',
              folderId: currentFolderId,
              errors,
            },
          }
        }
      })
  }

  function handleSaveImage(id, image) {
    setEditLoading(true)
    setEditError(null)
    dispatch(updateMediaLibraryImage({ token, id, image }))
      .then(() => {
        setEditLoading(false)
        setFileToEdit(null)
        dispatch(fetchMediaLibrary(token, currentFolderId)).then(res => {
          if (pendingSubmit) {
            onSubmit(pendingSubmit)
          }
          if (mode === 'edit') {
            const imageData = res.find(search => search._id === id)
            onSubmit(imageData)
          }
        })
      })
      .catch(err => {
        const message = err?.response?.data
        if (typeof message === 'string') {
          setEditError(message)
        } else {
          // unknown error just bail
          setFileToEdit(null)
          dispatch(fetchMediaLibrary(token, currentFolderId)).then(() => {
            if (pendingSubmit) {
              onSubmit(pendingSubmit)
            }
          })
        }
        setEditLoading(false)
      })
  }

  function handleDragOver(evt) {
    const items = [...evt.dataTransfer.items]
    if (items?.[0]?.kind === 'file') {
      setIsDroppingFile(true)
    }
  }

  function handleInputUpload(evt) {
    const files = [...(evt?.target?.files || [])]
    files.forEach(handleUpload)
  }

  function handleClickItem(evt, item) {
    const isSelected = !!selectedItems.find(search => search === item._id)
    const isMouseDown = evt.type === 'mousedown'
    const isClick = evt.type === 'click'
    const hasMod = evt.ctrlKey || evt.metaKey
    const hasShift = evt.shiftKey

    if (hasShift) {
      if (isMouseDown) {
        const lastSelectedItem = [...selectedItems].pop()
        const itemIndex = items.findIndex(search => item._id === search._id)
        const lastIndex = items.findIndex(
          search => search._id === lastSelectedItem,
        )
        if (itemIndex < lastIndex) {
          setSelectedItems([
            ...selectedItems,
            ...items
              .slice(itemIndex, lastIndex)
              .filter(
                item => !selectedItems.find(search => search === item._id),
              )
              .map(item => item._id),
          ])
        } else if (itemIndex > lastIndex) {
          setSelectedItems([
            ...selectedItems,
            ...items
              .slice(lastIndex, itemIndex + 1)
              .filter(
                item => !selectedItems.find(search => search === item._id),
              )
              .map(item => item._id),
          ])
        }
      }
    } else {
      if (isMouseDown) {
        if (hasMod) {
          const newItems = selectedItems.filter(search => search !== item._id)
          if (isSelected) {
            setSelectedItems(newItems)
          } else {
            setSelectedItems([...newItems, item._id])
          }
        } else if (!isSelected) {
          setSelectedItems([item._id])
        }
      }
      if (isClick && !hasMod) {
        setSelectedItems([item._id])
      }
    }
  }

  function handleDoubleClickItem(item) {
    handleSubmit(item)
  }

  function handleSelect() {
    const item = items.find(search => search._id === selectedItems[0])
    handleSubmit(item)
  }

  function handleSubmit(item) {
    if ((item?.type === 'image' && item?.alt) || item?.type !== 'image') {
      onSubmit(item)
    } else {
      setPendingSubmit(item)
      setFileToEdit(item)
    }
  }

  function handleEditClick() {
    const item = items.find(search => search._id === selectedItems[0])
    setFileToEdit(item)
    setPendingSubmit(null)
  }

  function handleShowSearch() {
    setShowSearchInput(true)
  }

  function handleCancelSearch() {
    setShowSearchInput(false)
    setSearch('')
    setCurrentFolderId(null)
  }

  function handleDragEnd(evt) {
    const { over } = evt
    if (over?.data?.current?.type === 'folder') {
      if (currentDragItem.type === 'folder') {
        const dragId = currentDragItem.folder._id
        const folderId = over?.id === 'homeFolder' ? null : over?.id
        const destinationFolder = folderTree[folderId]
        const isChildFolder = !!destinationFolder?.path?.find(
          search => search._id === dragId,
        )
        if (!isChildFolder && folderId !== dragId) {
          dispatch(
            editMediaLibraryFolder(token, dragId, {
              folderId,
            }),
          )
        }
      } else {
        const folderId = over?.id === 'homeFolder' ? null : over?.id
        dispatch(
          mediaLibraryMoveFiles({
            token,
            fromFolderId: currentFolderId,
            toFolderId: folderId,
            ids: selectedItems,
          }),
        ).then(() => setSelectedItems([]))
      }
    }
  }

  function handleDragStart(evt) {
    setCurrentDragItem(evt.active.data.current)
  }

  function handleSearch(evt) {
    setSearch(evt.target.value)
    debouncedRequest()
  }

  function handleChangeFolder(folderId) {
    setCurrentFolderId(folderId)
    setSearch('')
    setShowSearchInput(false)
    setSelectedItems([])
    onFolderChange(folderId)
  }

  function handleDeleteClick() {
    setItemsToDelete(
      selectedItems.map(item => {
        return {
          ...items.find(search => search._id === item),
        }
      }),
    )
  }

  function handleDeleteSubmit(items) {
    dispatch(
      mediaLibraryDeleteFiles({
        token,
        folderId: currentFolderId,
        ids: items.map(item => item._id),
      }),
    ).then(() => {
      setItemsToDelete([])
      setSelectedItems([])
    })
  }

  function handleRemoveUpload(item) {
    uploadRef.current = {
      ...uploadRef.current,
      [item.id]: {
        ...item,
        status: 'complete',
      },
    }
  }

  function handleSortChange(option) {
    const value = option.value
    setSort(value)
    localStorage.setItem('mediaLibrarySort', value)
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onCancel}
      className="modal modal--xl modal--overflow-hidden"
      overlayClassName="modal-overlay"
    >
      <div className="media-library">
        <div className="media-library__title">
          <div>Media Library</div>
          <div className="">
            <button className="modal__close" onClick={onCancel}>
              <CloseModalIcon />
            </button>
          </div>
        </div>

        <MediaLibraryBreadcrumbs folder={folder} onClick={handleChangeFolder} />
        <div className="media-library__header-filters">
          <FilterDropdown
            value={typeFilter}
            options={TYPE_FILTER_OPTIONS}
            onChange={setTypeFilter}
            placeholder="Asset Type"
            icon={<ImageIcon />}
          />
        </div>
        <div className="media-library__header-sort">
          <label>Order by:</label>
          <Select
            className="custom-select"
            classNamePrefix="custom-select"
            options={sortOptions}
            value={sortOptions.find(search => search.value === sort)}
            onChange={handleSortChange}
          />
        </div>
        <div className="media-library__header-actions">
          {selectedItems.length > 0 ? (
            <>
              <div className="media-library__header-selected">
                <button onClick={() => setSelectedItems([])}>
                  <CloseModalIcon />
                </button>
                <div>{selectedItems.length} Selected</div>
              </div>
              {mode !== 'management' && (
                <div className="media-library__header-action">
                  <button
                    className="btn btn--bright-purple"
                    disabled={selectedItems.length > 1}
                    onClick={handleSelect}
                  >
                    Select
                  </button>
                </div>
              )}
              <div className="media-library__header-action">
                <button
                  className="btn btn--secondary"
                  disabled={selectedItems.length > 1}
                  onClick={handleEditClick}
                >
                  <EditIcon height="20" width="20" /> Edit
                </button>
              </div>
              <div className="media-library__header-action">
                <button
                  className="btn btn--secondary-red"
                  onClick={handleDeleteClick}
                >
                  <DeleteIcon /> Delete
                </button>
              </div>
            </>
          ) : (
            <>
              <button
                className="btn btn--bright-purple"
                onClick={() => uploadFileInputRef.current.click()}
              >
                Upload File
              </button>
            </>
          )}
          <div className="flex-fill"></div>
          <div className="media-library__header-search-container">
            {showSearchInput ? (
              <div className="media-library__header-search">
                <input
                  type="text"
                  placeholder="Search..."
                  autoFocus
                  value={search}
                  onChange={handleSearch}
                />
                <button onClick={handleCancelSearch}>
                  <CloseModalIcon />
                </button>
              </div>
            ) : (
              <button
                onClick={handleShowSearch}
                className="media-library__header-search-button"
                type="button"
              >
                <SearchIcon />
              </button>
            )}
          </div>
        </div>
        <div className="media-library__body">
          <DndContext
            onDragEnd={handleDragEnd}
            onDragStart={handleDragStart}
            collisionDetection={pointerWithin}
            sensors={sensors}
          >
            <div className="media-library__folder-container">
              <MediaLibraryFolders
                folders={folderTree}
                onChange={handleChangeFolder}
                value={currentFolderId}
                isLoading={loading}
              />
            </div>
            <div
              className="media-library__content-container"
              onDragEnter={handleDragOver}
              onDragExit={() => setIsDroppingFile(false)}
            >
              <MediaLibraryContentGrid
                items={items}
                onItemClick={handleClickItem}
                onItemDoubleClick={handleDoubleClickItem}
                onRemoveUploadClick={handleRemoveUpload}
                selectedItems={selectedItems}
                isLoading={loading}
              />

              <div
                className={clsx('media-library__uploader', {
                  'dropping-file': isDroppingFile,
                })}
              >
                <FileUploader
                  disabled={true}
                  classes="file-uploader"
                  dropMessageStyle={{ display: 'none' }}
                  onDraggingStateChange={dragging => {
                    setIsDroppingFile(dragging)
                  }}
                  multiple={true}
                  name="media-library-upload"
                  handleChange={handleDropFiles}
                  types={FILE_TYPES}
                >
                  <div>
                    <h3>Drop Files</h3>
                  </div>
                </FileUploader>
              </div>
            </div>
            <DragOverlay
              dropAnimation={null}
              adjustScale={false}
              className="media-library__drag-container"
            >
              {currentDragItem?.type === 'folder' ? (
                <div className="media-library__drag-folder-overlay">
                  <FolderIcon />
                  <span>{currentDragItem.folder.name}</span>
                </div>
              ) : (
                <div className="media-library__drag-item-overlay">
                  {selectedItems.length === 1
                    ? '1 File'
                    : `${selectedItems.length} Files`}
                </div>
              )}
            </DragOverlay>
          </DndContext>
        </div>
        <input
          className="hidden"
          type="file"
          multiple
          onChange={handleInputUpload}
          ref={uploadFileInputRef}
        />
      </div>
      {loading && <MaterialLoader containerClasses="overlay-loader" />}
      {fileToEdit && (
        <MediaLibraryEditPanel
          item={fileToEdit}
          error={editError}
          onCancel={() => setFileToEdit(null)}
          onSubmit={handleSaveImage}
          isLoading={editLoading}
          onDelete={() => {
            setItemsToDelete([fileToEdit])
            setFileToEdit(null)
          }}
          showAltRequiredMessage={!!pendingSubmit}
        />
      )}
      {itemsToDelete.length > 0 && (
        <MediaLibraryDeleteModal
          isOpen={itemsToDelete.length > 0}
          items={itemsToDelete}
          isLoading={loading}
          onCancel={() => setItemsToDelete([])}
          onSubmit={handleDeleteSubmit}
        />
      )}
    </Modal>
  )
}

function sortItems(items, sort) {
  if (items?.length > 0) {
    const sortedItems = [...items]
    const sortFn = sortOptions.find(search => search.value === sort)?.sortFn
    if (sortFn) {
      sortedItems.sort(sortFn)
    }
    return sortedItems
  }
  return []
}

const sortOptions = [
  {
    value: 'latest',
    label: 'Latest Upload',
    sortFn: (a, b) => {
      const aTime = new Date(a.createdAt).getTime()
      const bTime = new Date(b.createdAt).getTime()
      if (aTime > bTime) {
        return -1
      }
      if (aTime < bTime) {
        return 1
      }
      return 0
    },
  },
  {
    value: 'oldest',
    label: 'Oldest Upload',
    sortFn: (a, b) => {
      const aTime = new Date(a.createdAt).getTime()
      const bTime = new Date(b.createdAt).getTime()
      if (aTime < bTime) {
        return -1
      }
      if (aTime > bTime) {
        return 1
      }
      return 0
    },
  },
  {
    value: 'alpha',
    label: 'Alphabetical (A-Z)',
    sortFn: (a, b) => {
      const aName = a.name.toLowerCase()
      const bName = b.name.toLowerCase()
      if (aName < bName) {
        return -1
      }
      if (aName > bName) {
        return 1
      }
      return 0
    },
  },
  {
    value: 'alphaReverse',
    label: 'Alphabetical (Z-A)',
    sortFn: (a, b) => {
      const aName = a.name.toLowerCase()
      const bName = b.name.toLowerCase()
      if (aName < bName) {
        return 1
      }
      if (aName > bName) {
        return -1
      }
      return 0
    },
  },
]

MediaLibrary.propTypes = {
  isOpen: PropTypes.bool,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  mode: PropTypes.string,
  filter: PropTypes.string,
  folderId: PropTypes.string,
  itemId: PropTypes.string,
  onFolderChange: PropTypes.func,
}

export default MediaLibrary
