// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.find-replace-external-link-form .form button[type=submit] {
  width: 150px;
  font-size: 16px;
}
.find-replace-external-link-form .form__field {
  margin-right: 24px;
}
.find-replace-external-link-form .form__field .field-error {
  position: absolute;
}
.find-replace-external-link-form__second-row {
  margin-top: 30px;
  margin-bottom: 8px;
  display: flex;
  align-items: flex-end;
}
.find-replace-external-link-form .custom-select {
  margin: 0;
}`, "",{"version":3,"sources":["webpack://./src/styles/editor-flow/find-replace/find-replace-external-link.scss"],"names":[],"mappings":"AAEI;EACE,YAAA;EACA,eAAA;AADN;AAGI;EACE,kBAAA;AADN;AAEM;EACE,kBAAA;AAAR;AAIE;EACE,gBAAA;EACA,kBAAA;EACA,aAAA;EACA,qBAAA;AAFJ;AAKE;EACE,SAAA;AAHJ","sourcesContent":[".find-replace-external-link-form {\n  .form {\n    button[type='submit'] {\n      width: 150px;\n      font-size: 16px;\n    }\n    &__field {\n      margin-right: 24px;\n      .field-error {\n        position: absolute;\n      }\n    }\n  }\n  &__second-row {\n    margin-top: 30px;\n    margin-bottom: 8px;\n    display: flex;\n    align-items: flex-end;\n  }\n\n  .custom-select {\n    margin: 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
