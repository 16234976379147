import React from 'react'
import routes from './routes'
import {
  selectManageWorkflowModalVisibility,
  selectMediaLibraryModalVisibility,
  toggleManageWorkflowModal,
  toggleMediaLibraryModal,
} from '../redux/user/userSelections'
import { ReactComponent as BrowseWorkflowsIcon } from '../assets/icons/browse-workflows-icon.svg'
import { ReactComponent as CreateWorkflowsIcon } from '../assets/icons/create-workflow-icon.svg'
import { ReactComponent as ViewWorkflowsIcon } from '../assets/icons/view-workflows-icon.svg'
import { ReactComponent as WhatsNewIcon } from '../assets/icons/whats-new-icon.svg'
import { ReactComponent as MediaLibraryIcon } from '../assets/icons/asset-library-icon.svg'
import { ReactComponent as FindReplaceIcon } from '../assets/icons/find-and-replace-icon.svg'
import { ReactComponent as ContentLockIcon } from '../assets/icons/content-lock-icon.svg'

export const dashboardCardRoutes = [
  {
    accessPermissions: [
      'Administrator',
      'Edit',
      'Manager',
      'Super',
      'User',
      'View',
    ],
    icon: <BrowseWorkflowsIcon />,
    label: 'Browse Workflows',
    description:
      'Explore and navigate workflows sorted by categories and subcategories.',
    path: routes.browseWorkflows,
    type: 'link',
  },
  {
    accessPermissions: ['Administrator', 'Manager', 'Super', 'Edit'],
    icon: <CreateWorkflowsIcon />,
    label: 'Create A New Workflow',
    description: 'Quickly add a new workflow under your organization.',
    path: null,
    type: 'button',
    state: () => selectManageWorkflowModalVisibility,
    event: toggleManageWorkflowModal,
  },
  {
    accessPermissions: ['Administrator', 'Manager', 'Super', 'Edit'],
    icon: <ViewWorkflowsIcon />,
    label: 'Manage Workflows',
    description:
      'Manage workflows and categories created by you or your organization.',
    path: routes.viewMyWorkflows,
    type: 'link',
  },
  {
    accessPermissions: [
      'Administrator',
      'Edit',
      'Manager',
      'Super',
      'User',
      'View',
    ],
    icon: <WhatsNewIcon />,
    label: 'Whats New',
    description:
      'View the latest updates to workflows within your organization.',
    path: routes.whatsNew,
    type: 'link',
  },
  {
    accessPermissions: ['Administrator', 'Manager', 'Super', 'Edit'],
    icon: <MediaLibraryIcon />,
    label: 'Browse Media Library',
    description:
      'Add or Manage media and documents created by you or your organization.',
    path: null,
    type: 'button',
    state: () => selectMediaLibraryModalVisibility,
    event: toggleMediaLibraryModal,
  },
  {
    accessPermissions: ['Administrator', 'Manager', 'Super', 'Edit'],
    icon: <FindReplaceIcon />,
    label: 'Find and Replace',
    description:
      'Search all workflows for specific words or links, and easily replace them.',
    path: routes.findReplace,
    type: 'link',
  },
  {
    accessPermissions: ['Administrator', 'Manager', 'Super', 'Edit'],
    icon: <ContentLockIcon />,
    label: 'Manage Content Locks',
    description:
      'Content locks keep your work safe and controls who can make changes.',
    path: routes.manageContentLocks,
    type: 'link',
  },
]
