import RichText from '../../../consumer-flow/base-fields/RichText'
import PropTypes from 'prop-types'

const FindReplaceTableHeaderPreview = ({ item, highlight, headerIndex }) => {
  const length = Math.max(headerIndex, 5)
  const rows = new Array(3).fill(1)
  const columns = new Array(length).fill(1)
  const width = `${100 * (1 / length)}%`
  return (
    <table className="find-replace__header-preview-table">
      <thead>
        <tr>
          {columns.map((empty, i) => {
            if (i === headerIndex) {
              return (
                <th width={width} key={i}>
                  <RichText field={item} highlight={highlight} />
                </th>
              )
            }
            return <th width={width} key={i}></th>
          })}
        </tr>
      </thead>
      <tbody>
        {rows.map((empty, i) => {
          return (
            <tr key={i}>
              {columns.map((empty, j) => (
                <td width={width} key={`${i}-${j}`}></td>
              ))}
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}

FindReplaceTableHeaderPreview.propTypes = {
  item: PropTypes.object,
  highlight: PropTypes.object,
  headerIndex: PropTypes.number,
}

export default FindReplaceTableHeaderPreview
