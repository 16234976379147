import PropTypes from 'prop-types'
import urlParser from 'js-video-url-parser'
import '../../../../styles/consumer-flow/base-fields/video-field.scss'

const VideoField = ({ field }) => {
  const block = field?.blocks?.[0] || {}
  const { configurations, video } = block
  const alignment = configurations?.alignment || 'left'
  const { url } = video || {}
  const videoData = urlParser.parse(url) || {}
  const { provider, id } = videoData
  const alignments = {
    left: 'flex-start',
    center: 'center',
    right: 'flex-end',
  }
  const width = 560
  const height = 315
  const style = {
    justifyContent: alignments[alignment],
  }
  return (
    <div className="video-field" style={style}>
      {provider === 'youtube' && (
        <div className="video-youtube">
          <iframe
            width={width}
            height={height}
            src={`https://www.youtube.com/embed/${id}`}
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></iframe>
        </div>
      )}
      {provider === 'vimeo' && (
        <div className="video-vimeo">
          <iframe
            src={`https://player.vimeo.com/video/${id}`}
            width={width}
            height={height}
            allow="autoplay; fullscreen; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
      )}
    </div>
  )
}

VideoField.propTypes = {
  field: PropTypes.object,
}

export default VideoField
