import { ReactComponent as FolderIcon } from '../../../../assets/icons/folder-icon.svg'
import '../../../../styles/global-components/media-library/media-library-folder-tree.scss'
import React, { createContext, useContext, useState } from 'react'
import clsx from 'clsx'
import { useDroppable } from '@dnd-kit/core'
import MediaLibraryFolderTreeItem from './MediaLibraryFolderTreeItem'
import PropTypes from 'prop-types'

export const TreeContext = createContext()
const MediaLibraryFolderTree = ({
  folders,
  value,
  onChange,
  onEditClick,
  onDeleteClick,
}) => {
  const [tree, setTree] = useState({
    openFolders: [],
  })

  const { setNodeRef, isOver } = useDroppable({
    id: 'homeFolder',
    data: {
      type: 'folder',
    },
  })

  const topLevelFolders = Object.values(folders || {})
    .filter(folder => folder.parent === null)
    .sort(alphaFolderSort)
  const maxDepth = tree.openFolders.reduce((acc, cur) => {
    const childId = folders[cur]?.children?.[0]?._id || null
    const childDepth = folders?.[childId]?.path?.length || 0
    if (childDepth > acc) {
      return childDepth
    }
    return acc
  }, 0)

  const width = 190 + maxDepth * 10

  const onClick = folder => {
    onChange(folder._id)
  }

  const onOpen = folder => {
    if (tree.openFolders.find(item => item === folder._id)) {
      setTree({
        ...tree,
        openFolders: tree.openFolders.filter(item => item !== folder._id),
      })
    } else {
      setTree({
        ...tree,
        openFolders: [...tree.openFolders, folder._id],
      })
    }
  }

  const treeCtx = {
    ...tree,
    folders,
    selectedFolder: value,
    onClick,
    onOpen,
  }

  return (
    <div className="media-library-folder-tree" style={{ width: `${width}px` }}>
      <TreeContext.Provider value={treeCtx}>
        <div className="media-library-folder-tree__item">
          <div
            className={clsx(
              'media-library-folder-tree__item-content home-folder',
              {
                'is-over': isOver,
              },
            )}
            ref={setNodeRef}
          >
            <button
              onClick={() => treeCtx.onClick({ _id: null })}
              className={clsx('media-library-folder-tree__item-name', {
                selected: value === null,
              })}
            >
              <FolderIcon />
              <span>Home</span>
            </button>
          </div>
        </div>
        <div className="media-library-folder-tree__home-spacer"></div>
        <div className="media-library-folder-tree__home-children">
          {topLevelFolders.map(folder => (
            <MediaLibraryFolderTreeItem
              folder={folder}
              key={folder._id}
              onEditClick={onEditClick}
              onDeleteClick={onDeleteClick}
            />
          ))}
        </div>
      </TreeContext.Provider>
    </div>
  )
}

export const alphaFolderSort = (a, b) => {
  if (a.name < b.name) {
    return -1
  }
  if (a.name > b.name) {
    return 1
  }
  return 0
}

MediaLibraryFolderTree.propTypes = {
  folders: PropTypes.shape({}),
  value: PropTypes.string,
  onChange: PropTypes.func,
  onEditClick: PropTypes.func,
  onDeleteClick: PropTypes.func,
}

export default MediaLibraryFolderTree
