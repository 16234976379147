import PropTypes from 'prop-types'
import EditorDividerField, {
  createDividerField,
} from '../../base-fields/divider-field/EditorDividerField'
import { EDITOR_DIVIDER } from '../../../../configuration/editor'

const EditorDividerComponent = ({ component, onChange, dropPath }) => {
  const subcomponent = component?.subComponents?.[0] || {}
  const { fields } = subcomponent || []
  const field = fields[0]
  function handleFieldChange(field) {
    onChange({
      ...component,
      subComponents: [
        {
          ...subcomponent,
          fields: [field],
        },
      ],
    })
  }

  return (
    <div>
      <div>
        <EditorDividerField field={field} onChange={handleFieldChange} />
      </div>
    </div>
  )
}

export const createEmptyDivider = () => {
  const id = `temp-${Date.now()}-sc`
  const fieldId = `temp-${Date.now()}-field`
  return {
    id,
    type: 'subComponent',
    subComponentType: 'image',
    configurations: {},
    fields: [createDividerField(fieldId)],
  }
}

export const createDividerComponent = id => {
  return {
    id,
    componentType: EDITOR_DIVIDER,
    configurations: {},
    subComponents: [createEmptyDivider()],
  }
}

EditorDividerComponent.propTypes = {
  component: PropTypes.object,
  onChange: PropTypes.func,
  dropPath: PropTypes.string,
}

export default EditorDividerComponent
