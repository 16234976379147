import React from 'react'
import PropTypes from 'prop-types'

function OnlineShoppingIcon({ colour }, props) {
  return (
    <svg
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M34.4844 18.5938C34.8725 18.5938 35.1875 18.2787 35.1875 17.8906C35.1875 17.5025 34.8725 17.1875 34.4844 17.1875C34.0963 17.1875 33.7812 17.5025 33.7812 17.8906C33.7812 18.2787 34.0963 18.5938 34.4844 18.5938Z"
        fill={colour}
      />
      <path
        d="M20 25.625C19.6119 25.625 19.2969 25.94 19.2969 26.3281C19.2969 26.7162 19.6119 27.0312 20 27.0312C20.3881 27.0312 20.7031 26.7162 20.7031 26.3281C20.7031 25.94 20.3881 25.625 20 25.625Z"
        fill={colour}
      />
      <path
        d="M12.9688 12.9688H13.7985L14.8653 18.3043C15.0619 19.2867 15.932 20 16.9343 20H23.0657C24.068 20 24.9381 19.2867 25.1347 18.304L26.2015 12.9688H27.0312C27.4196 12.9688 27.7344 12.654 27.7344 12.2656C27.7344 11.8773 27.4196 11.5625 27.0312 11.5625C26.4619 11.5625 24.2017 11.5625 23.3192 11.5625L22.0734 7.82468C21.9503 7.45609 21.5524 7.25696 21.1841 7.37973C20.8155 7.50278 20.6163 7.90076 20.7391 8.26907L21.8369 11.5625H18.1631L19.2609 8.26907C19.3837 7.90076 19.1845 7.50278 18.8159 7.37973C18.4476 7.25696 18.0494 7.45609 17.9266 7.82468L16.6808 11.5625C16.0652 11.5625 13.7771 11.5625 12.9688 11.5625C12.5804 11.5625 12.2656 11.8773 12.2656 12.2656C12.2656 12.654 12.5804 12.9688 12.9688 12.9688ZM24.7675 12.9688L23.7557 18.0282C23.6903 18.3559 23.4 18.5938 23.0657 18.5938H16.9345C16.6 18.5938 16.31 18.3559 16.2443 18.0285L15.2325 12.9688H24.7675Z"
        fill={colour}
      />
      <path
        d="M18.5938 17.1875C18.9821 17.1875 19.2969 16.8727 19.2969 16.4844V15.0781C19.2969 14.6898 18.9821 14.375 18.5938 14.375C18.2054 14.375 17.8906 14.6898 17.8906 15.0781V16.4844C17.8906 16.8727 18.2054 17.1875 18.5938 17.1875Z"
        fill={colour}
      />
      <path
        d="M21.4062 17.1875C21.7946 17.1875 22.1094 16.8727 22.1094 16.4844V15.0781C22.1094 14.6898 21.7946 14.375 21.4062 14.375C21.0179 14.375 20.7031 14.6898 20.7031 15.0781V16.4844C20.7031 16.8727 21.0179 17.1875 21.4062 17.1875Z"
        fill={colour}
      />
      <path
        d="M4.10938 29.8438H16.4844V32.6562H14.375C13.4362 32.6562 12.5535 33.0221 11.8893 33.6862C11.2252 34.3503 10.8594 35.2331 10.8594 36.1719C10.8594 36.5602 11.1741 36.875 11.5625 36.875H28.4375C28.8259 36.875 29.1406 36.5602 29.1406 36.1719C29.1406 34.2333 27.5635 32.6562 25.625 32.6562H23.5156V29.8438H35.8906C36.4539 29.8438 36.9835 29.6243 37.382 29.2258C37.7805 28.8272 38 28.2977 38 27.7344V5.23438C38 4.67105 37.7805 4.14151 37.382 3.74298C36.9835 3.34445 36.4539 3.125 35.8906 3.125H4.10938C3.54605 3.125 3.01651 3.34445 2.61798 3.74298C2.21945 4.14151 2 4.67105 2 5.23438V27.7344C2 28.2977 2.21945 28.8272 2.61798 29.2258C3.01651 29.6243 3.54605 29.8438 4.10938 29.8438ZM27.6138 35.4688H12.3854C12.4886 35.1757 12.6573 34.9071 12.8839 34.6805C13.2824 34.282 13.812 34.0625 14.375 34.0625H25.625C26.5418 34.0625 27.3238 34.6503 27.6138 35.4688ZM22.1094 32.6562H17.8906V29.8438H22.1094V32.6562ZM36.3878 28.2315C36.2548 28.3644 36.0782 28.4375 35.8906 28.4375H4.10938C3.92178 28.4375 3.74518 28.3644 3.61224 28.2315C3.47931 28.0986 3.40625 27.922 3.40625 27.7344V24.2188H36.5938V27.7344C36.5938 27.922 36.5207 28.0986 36.3878 28.2315ZM3.61224 4.73724C3.74518 4.60431 3.92178 4.53125 4.10938 4.53125H35.8906C36.0782 4.53125 36.2548 4.60431 36.3878 4.73724C36.5207 4.87018 36.5938 5.04678 36.5938 5.23438V22.8125H3.40625V5.23438C3.40625 5.04678 3.47931 4.87018 3.61224 4.73724Z"
        fill={colour}
      />
      <path
        d="M34.4844 15.7812C34.8727 15.7812 35.1875 15.4665 35.1875 15.0781V9.45312C35.1875 9.06476 34.8727 8.75 34.4844 8.75C34.096 8.75 33.7812 9.06476 33.7812 9.45312V15.0781C33.7812 15.4665 34.096 15.7812 34.4844 15.7812Z"
        fill={colour}
      />
    </svg>
  )
}

OnlineShoppingIcon.defaultProps = {
  colour: '#000000',
}

OnlineShoppingIcon.propTypes = {
  colour: PropTypes.string,
}

export default OnlineShoppingIcon
