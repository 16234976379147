import {
  FETCH_WORKFLOW_BEGIN,
  FETCH_WORKFLOW_ERROR,
  FETCH_WORKFLOW_SUCCESS,
} from '../workflows/workflow'
import axios from 'axios'
import { get } from 'lodash'

const orderComponents =
  ({ order, workflowId, workflowSlug, stepSlug, token }) =>
  (dispatch, getState) => {
    const user = getState().userData.user
    const activeClient = getState().userSelectionsData.activeClient
    const activeClientId = get(activeClient, 'id', null)
    const activeOperatingLocation =
      getState().userSelectionsData.activeOperatingLocation

    dispatch({ type: FETCH_WORKFLOW_BEGIN })

    return axios({
      url: `${process.env.REACT_APP_PRODUCT_API}/api/v1/workflow/step/components/order/${stepSlug}`,
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      data: {
        clientId: activeClientId,
        operatingLocation: activeOperatingLocation,
        workflowSlug: workflowSlug,
        workflowId,
        order,
      },
    })
      .then(res => {
        dispatch({
          type: FETCH_WORKFLOW_SUCCESS,
          payload: {
            [`${activeOperatingLocation}-${activeClientId}-${workflowSlug}`]:
              res?.data,
          },
        })
      })
      .catch(error => {
        const customError = get(error, 'response.data.error', 'Error')

        dispatch({
          type: FETCH_WORKFLOW_ERROR,
          payload: customError,
        })
      })
  }

export default orderComponents
