// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.manage-content-locks .table__container table td {
  line-height: 1;
}
.manage-content-locks .table__container table td button {
  display: flex;
  align-items: center;
  justify-content: center;
}
.manage-content-locks .table__filters-actions .btn--light-grey {
  margin-right: 16px;
}`, "",{"version":3,"sources":["webpack://./src/styles/editor-flow/manage-content-locks/manage-content-locks.scss"],"names":[],"mappings":"AAKM;EACE,cAAA;AAJR;AAKQ;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;AAHV;AASI;EACE,kBAAA;AAPN","sourcesContent":["@import '../../configuration/config';\n\n.manage-content-locks {\n  .table__container {\n    table {\n      td {\n        line-height: 1;\n        button {\n          display: flex;\n          align-items: center;\n          justify-content: center;\n        }\n      }\n    }\n  }\n  .table__filters-actions {\n    .btn--light-grey {\n      margin-right: 16px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
