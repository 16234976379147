import React from 'react'
import PropTypes from 'prop-types'

function OnlineBankingIcon({ colour }, props) {
  return (
    <svg
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g mask="url(#mask0_101_103)">
        <path
          d="M27.0869 30.561H33.254C34.4191 30.561 35.3634 29.6167 35.3634 28.4517V9.18604"
          stroke={colour}
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4.63672 9.18604V28.4517C4.63672 29.6167 5.58102 30.561 6.74609 30.561H10.3364"
          stroke={colour}
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M32.5508 20.2147V9.18604"
          stroke={colour}
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M27.2324 27.7487H32.2696C32.425 27.7487 32.5509 27.6228 32.5509 27.4674V22.6758"
          stroke={colour}
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.44922 9.18604V27.4673C7.44922 27.6227 7.57508 27.7485 7.73047 27.7485H12.7677"
          stroke={colour}
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.22341 3.9126H34.7766C36.2656 3.9126 37.4727 5.11965 37.4727 6.60866V8.69385C37.4727 8.96568 37.2523 9.18604 36.9805 9.18604H3.01953C2.7477 9.18604 2.52734 8.96568 2.52734 8.69385V6.60866C2.52734 5.11965 3.7344 3.9126 5.22341 3.9126Z"
          stroke={colour}
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M23.6714 9.18604V7.35791C23.6714 7.04727 23.4196 6.79541 23.1089 6.79541H16.8911C16.5804 6.79541 16.3286 7.04727 16.3286 7.35791V9.18604"
          stroke={colour}
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M26.6839 25.831C27.084 26.7332 27.3055 27.7316 27.3055 28.7821C27.3055 32.8166 24.0345 36.0875 20 36.0875C15.9655 36.0875 12.6946 32.8166 12.6946 28.7821C12.6946 24.7475 15.9655 21.4773 20 21.4773C21.6165 21.4773 23.11 22.0025 24.32 22.892"
          stroke={colour}
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M21.4814 32.0666C21.4814 32.0666 20.6383 32.8443 19.3387 32.4528C18.1455 32.0932 17.8492 30.4606 18.6601 29.8245C19.1257 29.4593 19.8262 29.1591 20.7704 28.8253C22.8836 28.0784 22.1024 25.0982 19.9528 25.0839C19.114 25.0782 18.7218 25.1315 17.9849 25.6136"
          stroke={colour}
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M19.9529 33.5688V23.9958"
          stroke={colour}
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M23.7922 25.906L24.4505 22.1446C24.4948 21.8914 24.8058 21.7932 24.9875 21.9748L25.4732 22.4606C25.5972 22.5846 25.7981 22.5846 25.922 22.4606L27.8906 20.4921C28.0145 20.3682 28.2154 20.3682 28.3393 20.4921L29.5735 21.7262C29.6973 21.8501 29.6973 22.0511 29.5735 22.175L27.6049 24.1436C27.481 24.2674 27.481 24.4684 27.6049 24.5923L28.0907 25.0781C28.2724 25.2598 28.1741 25.5707 27.921 25.615L24.1595 26.2733C23.9429 26.3112 23.7543 26.1226 23.7922 25.906Z"
          stroke={colour}
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M17.7852 13.0532H11.668C11.3961 13.0532 11.1758 13.2736 11.1758 13.5454V15.3032C11.1758 15.5751 11.3961 15.7954 11.668 15.7954H17.7852C18.057 15.7954 18.2773 15.5751 18.2773 15.3032V13.5454C18.2773 13.2736 18.057 13.0532 17.7852 13.0532Z"
          stroke={colour}
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M28.332 13.0532H22.2148C21.943 13.0532 21.7227 13.2736 21.7227 13.5454V15.3032C21.7227 15.5751 21.943 15.7954 22.2148 15.7954H28.332C28.6039 15.7954 28.8242 15.5751 28.8242 15.3032V13.5454C28.8242 13.2736 28.6039 13.0532 28.332 13.0532Z"
          stroke={colour}
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  )
}

OnlineBankingIcon.defaultProps = {
  colour: '#000000',
}

OnlineBankingIcon.propTypes = {
  colour: PropTypes.string,
}

export default OnlineBankingIcon
