// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tiptap-color-picker {
  position: relative;
}
.tiptap-color-picker__button {
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  background: transparent;
  transition: 0.2s ease-in-out;
  margin: 0.1em 0.25em;
  border-radius: 4px;
  position: relative;
}
.tiptap-color-picker__button:hover, .tiptap-color-picker__button.is-active {
  background: #e0e0e0;
}
.tiptap-color-picker__value {
  height: 4px;
  position: absolute;
  bottom: 4px;
  width: 14px;
}
.tiptap-color-picker__icon {
  height: 15px;
  margin-bottom: 2px;
}
.tiptap-color-picker__menu {
  position: absolute;
  top: 100%;
  left: 0;
  width: 370px;
  background: white;
  border-radius: 4px;
  z-index: 4;
  box-shadow: 0 4px 11px 0 rgba(37, 44, 97, 0.15), 0 1px 3px 0 rgba(93, 100, 148, 0.2);
}
.tiptap-color-picker__menu.left {
  left: auto;
  right: 0;
}
.tiptap-color-picker__color {
  border: 1px solid #333;
  border-radius: 4px;
  height: 30px;
  width: 30px;
  margin: 8px;
}`, "",{"version":3,"sources":["webpack://./src/styles/editor-flow/tip-tap/tiptap-color-picker.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;AACF;AAAE;EACE,WAAA;EACA,YAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,SAAA;EACA,uBAAA;EACA,4BAAA;EACA,oBAAA;EACA,kBAAA;EACA,kBAAA;AAEJ;AAAI;EAEE,mBAAA;AACN;AAGE;EACE,WAAA;EACA,kBAAA;EACA,WAAA;EACA,WAAA;AADJ;AAIE;EACE,YAAA;EACA,kBAAA;AAFJ;AAKE;EACE,kBAAA;EACA,SAAA;EACA,OAAA;EACA,YAAA;EACA,iBAAA;EACA,kBAAA;EACA,UAAA;EACA,oFAAA;AAHJ;AAMI;EACE,UAAA;EACA,QAAA;AAJN;AAOE;EACE,sBAAA;EACA,kBAAA;EACA,YAAA;EACA,WAAA;EACA,WAAA;AALJ","sourcesContent":[".tiptap-color-picker {\n  position: relative;\n  &__button {\n    width: 32px;\n    height: 32px;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    border: 0;\n    background: transparent;\n    transition: 0.2s ease-in-out;\n    margin: 0.1em 0.25em;\n    border-radius: 4px;\n    position: relative;\n\n    &:hover,\n    &.is-active {\n      background: #e0e0e0;\n    }\n  }\n\n  &__value {\n    height: 4px;\n    position: absolute;\n    bottom: 4px;\n    width: 14px;\n  }\n\n  &__icon {\n    height: 15px;\n    margin-bottom: 2px;\n  }\n\n  &__menu {\n    position: absolute;\n    top: 100%;\n    left: 0;\n    width: 370px;\n    background: white;\n    border-radius: 4px;\n    z-index: 4;\n    box-shadow: 0 4px 11px 0 rgba(37, 44, 97, 0.15),\n      0 1px 3px 0 rgba(93, 100, 148, 0.2);\n\n    &.left {\n      left: auto;\n      right: 0;\n    }\n  }\n  &__color {\n    border: 1px solid #333;\n    border-radius: 4px;\n    height: 30px;\n    width: 30px;\n    margin: 8px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
