import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectPowerSearchModalVisibility,
  togglePowerSearchModal,
} from '../../../redux/user/userSelections'
import {
  selectCheckClientIndexes,
  selectCheckClientIndexLoading,
} from '../../../redux/global-search/checkClientIndexes'
import MaterialLoader from '../elements/MaterialLoader'
import { ReactComponent as DashboardSearchIcon } from '../../../assets/icons/dashboard-search-icon.svg'

function PlatformDashboard() {
  const dispatch = useDispatch()
  const isPowerSearchModalVisible = useSelector(
    selectPowerSearchModalVisibility,
  )
  const searchIndexesLoading = useSelector(selectCheckClientIndexLoading)
  const searchIndexesExist = useSelector(selectCheckClientIndexes)
  const globalSearchDisabled = !searchIndexesExist || searchIndexesLoading

  const handleTogglePowerSearch = () => {
    dispatch(togglePowerSearchModal(!isPowerSearchModalVisible))
  }

  return (
    <div className="platform-dashboard__search section">
      <div className="platform-dashboard__search-container container">
        <div className="platform-dashboard__search-background">
          <div className="platform-dashboard__search-field">
            <button
              className="platform-dashboard__search-field-input"
              type="text"
              disabled={globalSearchDisabled}
              onClick={() =>
                !globalSearchDisabled ? handleTogglePowerSearch() : null
              }
            >
              <p>Search QuickFacts</p>
              <div className="platform-dashboard__search-field-submit">
                {globalSearchDisabled ? (
                  <MaterialLoader />
                ) : (
                  <DashboardSearchIcon />
                )}
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PlatformDashboard
