import React, { useState } from 'react'
import PropTypes from 'prop-types'
import useClickAway from '../../../hooks/useClickAway'
import clsx from 'clsx'
import { ReactComponent as CloseIcon } from '../../../assets/icons/close-icon.svg'
import { ReactComponent as DropdownChevronIcon } from '../../../assets/icons/dropdown-chevron-icon.svg'
import '../../../styles/global-components/filters/filter-dropdown.scss'

const FilterDropdown = ({ value, onChange, icon, placeholder, options }) => {
  const [menuOpen, setMenuOpen] = useState(false)
  const ref = useClickAway(() => setMenuOpen(false))
  const selectedOption = options?.find(option => option.value === value)
  const displayValue = selectedOption?.label || placeholder

  function handleClickOption(option) {
    onChange(option.value)
    setMenuOpen(false)
  }

  return (
    <div className={clsx('filter-dropdown', { active: value })}>
      <button
        className="filter-dropdown__button"
        onClick={() => setMenuOpen(true)}
      >
        <div className="filter-dropdown__icon">{icon}</div>
        <div className="filter-dropdown__value">{displayValue}</div>
        <div className="filter-dropdown__chevron">
          <DropdownChevronIcon />
        </div>
      </button>
      {value && (
        <button
          onClick={() => onChange(null)}
          className="filter-dropdown__clear-button"
        >
          <CloseIcon />
        </button>
      )}
      <div
        className={clsx('filter-dropdown__menu', { open: menuOpen })}
        ref={ref}
      >
        {options.map((option, i) => (
          <button
            key={`filter-dropdown-option-${i}`}
            className="filter-dropdown__menu-option"
            onClick={() => handleClickOption(option)}
          >
            {option.label}
          </button>
        ))}
      </div>
    </div>
  )
}

FilterDropdown.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  icon: PropTypes.element,
  placeholder: PropTypes.element,
  options: PropTypes.array,
}

export default FilterDropdown
