import React from 'react'
import PropTypes from 'prop-types'

function SecuritySystemAlarmIcon({ colour }, props) {
  return (
    <svg
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g mask="url(#mask0_101_341)">
        <path
          d="M2.86411 11.4246C2.92247 10.6737 2.98856 9.92276 3.06309 9.17183C3.25293 7.25792 4.78434 5.75323 6.70247 5.60839C15.5675 4.94112 24.4325 4.94112 33.2975 5.60839C35.2156 5.75323 36.747 7.25792 36.9368 9.17182C37.6512 16.3908 37.6512 23.6091 36.9368 30.8281C36.747 32.742 35.2156 34.2467 33.2975 34.3915C24.4325 35.0588 15.5675 35.0588 6.70247 34.3915C4.78434 34.2467 3.25294 32.742 3.06309 30.8281C2.49215 25.061 2.37754 19.2947 2.71926 13.5284"
          stroke={colour}
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M28.1507 32.2182C28.4615 32.1999 28.7722 32.1802 29.083 32.1598C30.3846 32.0733 31.4315 31.0594 31.5714 29.7628C32.02 25.606 32.02 21.4491 31.5714 17.2929C31.4315 15.9957 30.3846 14.9818 29.083 14.8953C23.0277 14.4931 16.9725 14.4931 10.9171 14.8953C9.6156 14.9818 8.56865 15.9957 8.42873 17.2929C7.98014 21.4491 7.98014 25.606 8.42873 29.7628C8.56865 31.0594 9.6156 32.0733 10.9171 32.1598C15.962 32.4952 21.0069 32.5507 26.0519 32.3271"
          stroke={colour}
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M24.1238 23.5276C24.1238 21.2502 22.2775 19.404 20.0002 19.404C17.7227 19.404 15.8765 21.2502 15.8765 23.5276C15.8765 25.805 17.7227 27.6512 20.0002 27.6512C22.2775 27.6512 24.1238 25.805 24.1238 23.5276Z"
          stroke={colour}
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M25.9653 23.5276L30.1802 23.5276"
          stroke={colour}
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M27.3475 22.0051L25.825 23.5277L27.3475 25.0503"
          stroke={colour}
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14.0345 23.5277L9.81958 23.5277"
          stroke={colour}
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.6526 22.0051L14.1751 23.5277L12.6526 25.0503"
          stroke={colour}
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.1657 10.6616L10.4985 8.30728L9.83128 10.6616L11.1657 10.6616ZM8.36646 12.2491C8.36646 12.2337 8.37419 12.2031 8.38185 12.1724L9.86193 7.34864C9.93091 7.11858 10.2148 7.01121 10.4985 7.01121C10.7898 7.01121 11.0736 7.11858 11.1427 7.34864L12.6228 12.1724C12.6304 12.2031 12.6381 12.2261 12.6381 12.2491C12.6381 12.4868 12.2777 12.6632 12.0093 12.6632C11.8406 12.6632 11.7102 12.6096 11.6718 12.4638L11.3804 11.4438L9.62421 11.4438L9.33283 12.4638C9.29444 12.6096 9.16408 12.6632 8.9954 12.6632C8.72688 12.6632 8.36646 12.4945 8.36646 12.2491Z"
          fill={colour}
        />
        <path
          d="M13.9877 12.6633C13.7653 12.6633 13.5505 12.556 13.5505 12.3489L13.5505 7.36409C13.5505 7.14935 13.7959 7.05731 14.0491 7.05731C14.2944 7.05731 14.5476 7.14935 14.5476 7.36409L14.5476 11.7891L16.4187 11.7891C16.6181 11.7891 16.7178 12.0038 16.7178 12.2262C16.7178 12.4409 16.6181 12.6633 16.4187 12.6633L13.9877 12.6633Z"
          fill={colour}
        />
        <path
          d="M20.0536 10.6616L19.3864 8.30728L18.7191 10.6616L20.0536 10.6616ZM17.2544 12.2491C17.2544 12.2337 17.2621 12.2031 17.2698 12.1724L18.7499 7.34864C18.8188 7.11858 19.1027 7.01121 19.3864 7.01121C19.6778 7.01121 19.9616 7.11858 20.0306 7.34864L21.5108 12.1724C21.5184 12.2031 21.526 12.2261 21.526 12.2491C21.526 12.4868 21.1656 12.6632 20.8972 12.6632C20.7285 12.6632 20.5981 12.6096 20.5598 12.4638L20.2683 11.4438L18.5121 11.4438L18.2208 12.4638C18.1824 12.6096 18.052 12.6632 17.8833 12.6632C17.6149 12.6632 17.2544 12.4945 17.2544 12.2491Z"
          fill={colour}
        />
        <path
          d="M23.4355 7.93147L23.4355 9.5649L24.279 9.5649C24.7776 9.5649 25.0997 9.3579 25.0997 8.75201C25.0997 8.1462 24.7776 7.93147 24.279 7.93147L23.4355 7.93147ZM22.4385 7.36398C22.4385 7.20289 22.5689 7.0572 22.7606 7.0572L24.279 7.0572C25.2837 7.0572 26.0966 7.44069 26.0966 8.65998C26.0966 9.5419 25.6672 10.0174 25.092 10.1938L26.0966 12.0573C26.1273 12.0957 26.135 12.1494 26.135 12.18C26.135 12.4332 25.8052 12.7015 25.5138 12.7015C25.3758 12.7015 25.2454 12.6172 25.1686 12.4638L24.0796 10.3472L23.4355 10.3472L23.4355 12.3488C23.4355 12.5559 23.1901 12.6632 22.937 12.6632C22.6916 12.6632 22.4385 12.5559 22.4385 12.3488L22.4385 7.36398Z"
          fill={colour}
        />
        <path
          d="M29.7393 10.6463C29.6549 10.7998 29.5245 10.8458 29.4018 10.8458C29.2791 10.8458 29.1564 10.7998 29.0721 10.6463L28.1901 8.98985L28.1901 12.3489C28.1901 12.556 27.937 12.6633 27.6916 12.6633C27.4385 12.6633 27.1931 12.556 27.1931 12.3489L27.1931 7.55576C27.1931 7.18767 27.4385 7.05731 27.6916 7.05731C28.0521 7.05731 28.2131 7.13395 28.4662 7.58642L29.4095 9.30429L30.3527 7.58642C30.6058 7.13395 30.7746 7.05731 31.135 7.05731C31.388 7.05731 31.6334 7.18767 31.6334 7.55576L31.6334 12.3489C31.6334 12.556 31.3804 12.6633 31.135 12.6633C30.8819 12.6633 30.6365 12.556 30.6365 12.3489L30.6365 9.05117L29.7393 10.6463Z"
          fill={colour}
        />
      </g>
    </svg>
  )
}

SecuritySystemAlarmIcon.defaultProps = {
  colour: '#000000',
}

SecuritySystemAlarmIcon.propTypes = {
  colour: PropTypes.string,
}

export default SecuritySystemAlarmIcon
