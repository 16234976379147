import { useDraggable } from '@dnd-kit/core'
import '../../../styles/editor-flow/editor-components/editor-component-options.scss'
import PropTypes from 'prop-types'

const EditorComponentOption = ({ icon, title, type, dropLevel }) => {
  const { attributes, listeners, setNodeRef, transform } = useDraggable({
    data: {
      dropLevel: dropLevel,
      type,
      action: 'create',
      dropPath: ['components'],
    },
    id: `editor-component-${type}`,
  })

  const style = transform
    ? {
        transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
      }
    : undefined

  return (
    <div
      className="btn btn--white"
      id={type}
      ref={setNodeRef}
      style={{ ...style }}
      {...listeners}
      {...attributes}
    >
      {icon ? icon : null}
      {title}
    </div>
  )
}

EditorComponentOption.propTypes = {
  icon: PropTypes.node,
  title: PropTypes.string,
  type: PropTypes.string,
  dropLevel: PropTypes.string,
}
export default EditorComponentOption
