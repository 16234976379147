import PropTypes from 'prop-types'
import { EDITOR_VIDEO } from '../../../../configuration/editor'
import EditorVideoField, {
  createVideoField,
} from '../../base-fields/video-field/EditorVideoField'

const EditorVideoComponent = ({ component, onChange }) => {
  const subcomponent = component?.subComponents?.[0] || {}
  const { fields } = subcomponent || []
  const field = fields[0]
  function handleFieldChange(field) {
    onChange({
      ...component,
      subComponents: [
        {
          ...subcomponent,
          fields: [field],
        },
      ],
    })
  }

  return (
    <div>
      <div>
        <EditorVideoField field={field} onChange={handleFieldChange} />
      </div>
    </div>
  )
}

EditorVideoComponent.propTypes = {}

export const createEmptyVideo = () => {
  const id = `temp-${Date.now()}-sc`
  const fieldId = `temp-${Date.now()}-field`
  return {
    id,
    type: 'subComponent',
    subComponentType: 'video',
    configurations: {},
    fields: [createVideoField(fieldId)],
  }
}

export const createVideoComponent = id => {
  return {
    id,
    componentType: EDITOR_VIDEO,
    configurations: {},
    subComponents: [createEmptyVideo()],
  }
}

EditorVideoComponent.propTypes = {
  component: PropTypes.object,
  onChange: PropTypes.func,
}

export default EditorVideoComponent
