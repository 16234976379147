import { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import highlightTextInHTML from '../../../../utilities/find-replace/highlightTextInHTML'

const FindReplaceWorkflowPreview = ({ item, highlight }) => {
  const ref = useRef()
  const hasInnerText = !!ref?.current?.innerText

  useEffect(
    function highlightResultsOnSearch() {
      if (highlight && hasInnerText) {
        highlightTextInHTML({ ref, highlight })
      }
    },
    [highlight, hasInnerText],
  )

  return (
    <div className="find-replace__workflow-preview" ref={ref}>
      <h3>{item?.name}</h3>
      <p>{item?.content?.description}</p>
    </div>
  )
}

FindReplaceWorkflowPreview.propTypes = {
  item: PropTypes.object,
  highlight: PropTypes.object,
}
export default FindReplaceWorkflowPreview
