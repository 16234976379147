import React from 'react'
import PropTypes from 'prop-types'
import BreadCrumbs from '../bread-crumbs/BreadCrumbs'
import SearchFilters from '../../filters/SearchFilters'
import HeaderEditorBanner from './elements/HeaderEditorBanner'
import '../../../../styles/global-components/header/header.scss'

function Header({ breadCrumbEvent, enableBreadCrumbs, title }) {
  return (
    <header className="header">
      <div className="header__container container">
        <div className="header__content">
          {title && <h1>{title}</h1>}
          {enableBreadCrumbs && (
            <BreadCrumbs breadCrumbEvent={breadCrumbEvent} />
          )}
        </div>

        <SearchFilters />
      </div>

      <HeaderEditorBanner />
    </header>
  )
}

Header.propTypes = {
  breadCrumbEvent: PropTypes.func,
  enableBreadCrumbs: PropTypes.bool,
  title: PropTypes.string.isRequired,
}

export default Header
