/**
 * The useSortable transform causes elements to stretch on the Y scale for some reason,
 * this resets it to 1 if its defined.
 * @param transform
 * @returns {(*&{scaleY: number})|null}
 */
const fixTransform = transform => {
  if (transform === null) {
    return null
  }
  return {
    ...transform,
    scaleY: 1,
    scaleX: 1,
  }
}

export default fixTransform
