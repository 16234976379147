import React from 'react'
import PropTypes from 'prop-types'

function PaperShredderIcon({ colour }, props) {
  return (
    <svg
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M30.0977 10.3512H9.90228C9.61294 10.3512 9.37845 10.5858 9.37845 10.875C9.37845 11.1643 9.61301 11.3989 9.90228 11.3989H30.0977C30.3871 11.3989 30.6215 11.1643 30.6215 10.875C30.6215 10.5858 30.3871 10.3512 30.0977 10.3512Z"
        fill={colour}
      />
      <path
        d="M30.0977 13.1323H9.90228C9.61294 13.1323 9.37845 13.3669 9.37845 13.6561C9.37845 13.9455 9.61301 14.18 9.90228 14.18H30.0977C30.3871 14.18 30.6215 13.9454 30.6215 13.6561C30.6215 13.3669 30.3871 13.1323 30.0977 13.1323Z"
        fill={colour}
      />
      <path
        d="M30.0977 6.78551H20C19.7107 6.78551 19.4762 7.02007 19.4762 7.30934C19.4762 7.59867 19.7107 7.83316 20 7.83316H30.0977C30.387 7.83316 30.6215 7.5986 30.6215 7.30934C30.6215 7.02007 30.387 6.78551 30.0977 6.78551Z"
        fill={colour}
      />
      <path
        d="M6.01351 16.0108C6.30285 16.0108 6.53734 15.7762 6.53734 15.4869V7.8332H10.1663C10.804 7.8332 11.3229 7.31436 11.3229 6.67663V3.04773H32.7766C33.1548 3.04773 33.4626 3.35548 33.4626 3.73377V15.4869C33.4626 15.7763 33.6971 16.0108 33.9864 16.0108C34.2758 16.0108 34.5103 15.7762 34.5103 15.4869V3.73377C34.5103 2.77773 33.7326 2 32.7766 2H11.3002C10.8411 2 10.3929 2.18921 10.0743 2.5078L5.99748 6.58459C5.68529 6.89677 5.48969 7.34185 5.48969 7.81048V15.4869C5.48969 15.7763 5.72418 16.0108 6.01351 16.0108ZM10.2752 3.78854V6.67663C10.2752 6.73667 10.2264 6.78554 10.1663 6.78554H7.27815L10.2752 3.78854Z"
        fill={colour}
      />
      <path
        d="M36.0018 17.4229H3.99828C2.96223 17.4229 2.11932 18.2658 2.11932 19.3018V24.713V36.1559C2.11932 37.1727 2.94662 38 3.96348 38H36.0365C37.0534 38 37.8807 37.1727 37.8807 36.1558V32.3177C37.8807 32.0283 37.6462 31.7938 37.3569 31.7938C37.0675 31.7938 36.833 32.0284 36.833 32.3177V36.1558C36.833 36.595 36.4758 36.9522 36.0365 36.9522H3.96348C3.52431 36.9522 3.16705 36.595 3.16705 36.1558V25.2368H5.48968V27.8577C5.48968 28.4019 5.93244 28.8447 6.47666 28.8447H8.04906C8.59328 28.8447 9.03603 28.4019 9.03603 27.8577V25.2368H10.5845V33.19C10.5845 33.7342 11.0273 34.1769 11.5715 34.1769H13.144C13.6882 34.1769 14.1309 33.7342 14.1309 33.19V25.2368H15.6794V30.1239C15.6794 30.6681 16.1222 31.1109 16.6664 31.1109H18.2389C18.7831 31.1109 19.2259 30.6681 19.2259 30.1239V25.2368H20.7744V31.5903C20.7744 32.1345 21.2171 32.5773 21.7613 32.5773H23.3337C23.8779 32.5773 24.3206 32.1345 24.3206 31.5903V25.2368H25.8692V27.8577C25.8692 28.4019 26.312 28.8447 26.8561 28.8447H28.4285C28.9727 28.8447 29.4155 28.4019 29.4155 27.8577V25.2368H30.964V30.4947C30.964 31.0389 31.4067 31.4817 31.9509 31.4817H33.5233C34.0675 31.4817 34.5103 31.0389 34.5103 30.4947V25.2368H36.8329V29.8688C36.8329 30.1581 37.0674 30.3926 37.3567 30.3926C37.6461 30.3926 37.8805 30.158 37.8805 29.8688V24.7129V19.3018C37.8807 18.2658 37.0378 17.4229 36.0018 17.4229ZM3.16705 19.3018C3.16705 18.8434 3.53992 18.4705 3.99828 18.4705H36.0018C36.4601 18.4705 36.833 18.8434 36.833 19.3018V24.1891H3.16705V19.3018ZM7.98831 27.7969H6.53734V25.2368H7.98831V27.7969ZM13.0832 33.1292H11.6323V25.2368H13.0832V33.1292ZM18.1781 30.0632H16.7271V25.2368H18.1781V30.0632ZM23.2729 31.5295H21.8219V25.2368H23.2729V31.5295ZM28.3678 27.7969H26.9168V25.2368H28.3678V27.7969ZM33.4626 30.4339H32.0116V25.2368H33.4626V30.4339Z"
        fill={colour}
      />
      <path
        d="M11.1597 22.1312V20.7527C11.1597 20.2552 10.755 19.8504 10.2574 19.8504H5.38386C4.88633 19.8504 4.48154 20.2552 4.48154 20.7527V22.1312C4.48154 22.6287 4.88633 23.0335 5.38386 23.0335H10.2574C10.755 23.0336 11.1597 22.6288 11.1597 22.1312ZM10.1121 21.9858H5.52919V20.8981H10.112L10.1121 21.9858Z"
        fill={colour}
      />
    </svg>
  )
}

PaperShredderIcon.defaultProps = {
  colour: '#000000',
}

PaperShredderIcon.propTypes = {
  colour: PropTypes.string,
}

export default PaperShredderIcon
