import React from 'react'
import PropTypes from 'prop-types'

function ShareIcon({ colour }, props) {
  return (
    <svg
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M3.2 35.5994C3.1085 35.5994 3.0161 35.5889 2.9246 35.5679C2.3831 35.4389 2 34.9559 2 34.3994C2 23.495 3.3794 14.426 20 14.0135V5.59938C20 5.12238 20.2823 4.69128 20.7185 4.50018C21.1532 4.31028 21.6629 4.39248 22.0145 4.71828L37.6145 19.1183C37.8605 19.3442 38 19.6643 38 19.9994C38 20.3345 37.8605 20.6546 37.6145 20.8808L22.0145 35.2808C21.6641 35.6066 21.1544 35.6909 20.7185 35.4989C20.2823 35.3075 20 34.8764 20 34.3994V26.0135C8.5988 26.2865 6.4952 30.4925 4.2734 34.9361C4.0673 35.3498 3.6464 35.5994 3.2 35.5994ZM21.2 23.5994C21.8633 23.5994 22.4 24.1361 22.4 24.7994V31.6583L35.0306 19.9994L22.4 8.34048V15.1994C22.4 15.8627 21.8633 16.3994 21.2 16.3994C7.9436 16.3994 5.0891 21.5264 4.5278 29.7155C7.0133 26.3768 11.2496 23.5994 21.2 23.5994Z"
        fill={colour}
      />
    </svg>
  )
}

ShareIcon.defaultProps = {
  colour: '#000000',
}

ShareIcon.propTypes = {
  colour: PropTypes.string,
}

export default ShareIcon
