import { useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectActiveClientId,
  selectActiveOperatingLocation,
} from '../../../redux/user/userSelections'
import {
  fetchWorkflow,
  selectWorkflowState,
} from '../../../redux/workflows/workflow'
import Editor from '../editor-core/Editor'
import React, { useEffect, useRef, useState } from 'react'
import useAuthToken from '../../../hooks/useAuthToken'
import MaterialLoader from '../../global-components/elements/MaterialLoader'
import createComponent from '../../../redux/components/createComponent'
import updateComponent from '../../../redux/components/updateComponent'
import orderComponents from '../../../redux/components/orderComponents'
import deleteComponent from '../../../redux/components/deleteComponent'
import { selectCreateWorkflowsState } from '../../../redux/workflows/createWorkflow'
import { selectWorkflowsState } from '../../../redux/workflows/workflows'
import { get } from 'lodash'
import {
  selectSocketId,
  selectSocketResourceBlockedBy,
  selectSocketResourceOutOfDate,
  selectSocketSessionExpired,
} from '../../../redux/socket/socket'
import useOnResourceBecomesUnblocked from '../../../hooks/useOnResourceBecomesUnblocked'
import { emitSocketMessage } from '../../../redux/middleware/socket/socket'
import WorkflowEditorStepBlockedModal from './elements/WorkflowEditorStepBlockedModal'
import WorkflowEditorStepOutOfDateModal from './elements/WorkflowEditorStepOutOfDateModal'

function ManageWorkflowStep() {
  const dispatch = useDispatch()
  const { token } = useAuthToken({})
  const { workflow: slug, step: stepSlug } = useParams()
  const hasChanged = useRef(false)
  const workflowState = useSelector(selectWorkflowState)
  const isLoading = workflowState?.loading
  const activeClientId = useSelector(selectActiveClientId)
  const activeOperatingLocation = useSelector(selectActiveOperatingLocation)
  const workflowsState = useSelector(selectWorkflowsState)
  const isSocketSessionExpired = useSelector(selectSocketSessionExpired)
  const workflows = get(workflowsState, 'workflows', [])
  const workflowsByClient = get(
    workflows,
    `${activeOperatingLocation}-${activeClientId}`,
    [],
  )
  const workflowKey = `${activeOperatingLocation}-${activeClientId}-${slug}`
  const workflow = workflowState.workflow?.[workflowKey]
  const workflowId = get(workflow, 'workflowId', null)
  const workflowData = workflowsByClient.find(
    clientWorkflow => clientWorkflow?._id === workflowId,
  )
  const workflowSlug = get(workflowData, 'slug', null)
  const error = workflowState?.error
  const step = workflow?.steps?.find(item => item.slug === stepSlug)
  const stepId = step?._id
  const socketId = useSelector(selectSocketId)

  const resourceBlockedBy = useSelector(state =>
    selectSocketResourceBlockedBy({
      state,
      type: 'editStep',
      id: stepId,
    }),
  )

  const resourceOutOfDate = useSelector(state =>
    selectSocketResourceOutOfDate({
      state,
      type: 'editStep',
      id: stepId,
    }),
  )

  useOnResourceBecomesUnblocked({
    name: 'editStep',
    data: {
      stepId,
    },
    isBlocked: !!resourceBlockedBy || !!resourceOutOfDate,
  })

  useEffect(() => {
    if (token && !isLoading && !workflow && !error) {
      dispatch(fetchWorkflow(slug, token))
    }
  }, [token, isLoading, workflow, error])

  useEffect(() => {
    hasChanged.current = false
  }, [stepId])

  useEffect(() => {
    if (stepId && !resourceOutOfDate && socketId) {
      dispatch(
        emitSocketMessage('editStep', {
          stepId,
        }),
      )
    }
    return () => {
      if (stepId) {
        dispatch(
          emitSocketMessage('editStepComplete', {
            stepId,
            hasChanged: hasChanged.current,
          }),
        )
      }
    }
  }, [stepId, resourceOutOfDate, socketId])

  async function handleSaveClick(component) {
    const isNew = component._id === undefined
    hasChanged.current = true
    try {
      if (isNew) {
        await dispatch(
          createComponent({
            token,
            component,
            stepId,
            workflowId: workflowId,
            workflowSlug: workflowSlug,
            slug: stepSlug,
          }),
        )
      } else {
        await dispatch(
          updateComponent({
            token,
            component,
            stepId,
            workflowId: workflowId,
            workflowSlug: workflowSlug,
            slug: stepSlug,
          }),
        )
      }
    } catch (err) {
      console.log(err)
    }
  }

  async function handleOrderChange(order) {
    await dispatch(
      orderComponents({
        order,
        token,
        workflowId: workflow.workflowId,
        workflowSlug: workflowSlug,
        stepSlug,
      }),
    )
    hasChanged.current = true
  }

  async function handleDeleteClick(component) {
    await dispatch(
      deleteComponent({
        token,
        component,
        stepId,
        workflowId: workflow.workflowId,
        workflowSlug: workflowSlug,
        slug: stepSlug,
      }),
    )
    hasChanged.current = true
  }

  return (
    <div className="manage-workflow-step">
      {(isLoading || !step) && (
        <MaterialLoader containerClasses="overlay-loader" />
      )}
      {step ? (
        <Editor
          data={step}
          onSaveClick={handleSaveClick}
          onOrderChange={handleOrderChange}
          onDeleteClick={handleDeleteClick}
          managedWorkflow={workflow}
          workflowByClientSlugSteps={workflow?.steps}
        />
      ) : null}

      {!isSocketSessionExpired && resourceBlockedBy ? (
        <WorkflowEditorStepBlockedModal
          resource={resourceBlockedBy}
          workflow={workflowData}
          step={step}
        />
      ) : null}

      {!isSocketSessionExpired && !resourceBlockedBy && resourceOutOfDate ? (
        <WorkflowEditorStepOutOfDateModal workflow={workflowData} step={step} />
      ) : null}
    </div>
  )
}

export default ManageWorkflowStep
