import PropTypes from 'prop-types'
import EditorComponent from './EditorComponent'
import { useDroppable } from '@dnd-kit/core'
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable'
import getId from '../../../utilities/editor/getId'
import { ReactComponent as PlusIcon } from '../../../assets/icons/plus-icon.svg'
import { isEmpty } from 'lodash'

const EditorComponentList = ({
  components,
  selectedComponent,
  onEditClick,
  onCancelClick,
  onSaveClick,
  onDeleteClick,
  onChange,
}) => {
  const dropLevel = 'components'
  const { setNodeRef } = useDroppable({
    id: 'editor-component-list',
    data: {
      dropPath: [dropLevel],
      dropLevel,
    },
  })

  const { setNodeRef: setBottomNodeRef } = useDroppable({
    id: 'editor-component-list-bottom',
    data: {
      dropPath: [dropLevel],
      dropLevel,
    },
  })

  return (
    <div
      ref={setNodeRef}
      id="editor-component-list"
      className="editor-component-list"
    >
      <SortableContext
        items={components?.map(item => getId(item)) || []}
        strategy={verticalListSortingStrategy}
      >
        {components?.length > 0 &&
          components?.map((component, index) => (
            <EditorComponent
              component={component}
              key={getId(component)}
              dropPath={['components']}
              index={index}
              selectedComponent={selectedComponent}
              onEditClick={onEditClick}
              onCancelClick={onCancelClick}
              onSaveClick={onSaveClick}
              onDeleteClick={onDeleteClick}
              onChange={onChange}
            />
          ))}
      </SortableContext>

      <div
        ref={setBottomNodeRef}
        id="editor-component-list-bottom"
        className="editor-component-list-bottom"
        style={{ minHeight: '500px' }}
      >
        {isEmpty(components) ? (
          <div className="editor-component-list-action">
            <PlusIcon />
            Drag & Drop Your First Component Here
          </div>
        ) : null}
      </div>
    </div>
  )
}

EditorComponentList.propTypes = {
  components: PropTypes.array,
  selectedComponent: PropTypes.object,
  onEditClick: PropTypes.func,
  onCancelClick: PropTypes.func,
  onSaveClick: PropTypes.func,
  onDeleteClick: PropTypes.func,
  onChange: PropTypes.func,
  totalOptions: PropTypes.number,
}

export default EditorComponentList
