import PropTypes from 'prop-types'

const EditorLayoutSelector = ({ label, value, options, onChange }) => {
  function handleChange(evt) {
    onChange(evt.target.value)
  }

  return (
    <div className="editor-layout-selector">
      {label ? <label>{label}</label> : null}

      <select onChange={handleChange} value={value}>
        {options.map(option => (
          <option value={option} key={option}>
            {option}
          </option>
        ))}
      </select>
    </div>
  )
}

EditorLayoutSelector.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  options: PropTypes.array,
  onChange: PropTypes.func,
}

export default EditorLayoutSelector
