import React from 'react'
import { get, isEmpty } from 'lodash'
import { useSelector } from 'react-redux'
import { selectActiveClient } from '../../redux/user/userSelections'
import defaultBrandColours from '../../configuration/defaultBrandColours'

const generateHexColoursFieldOptions = () => {
  const activeClient = useSelector(selectActiveClient)
  const clientBrandColours = get(activeClient, 'brandColours', [])

  let brandColours = []

  if (!isEmpty(clientBrandColours)) {
    brandColours = clientBrandColours.map(colour => {
      return {
        label: colour?.hex,
        value: colour?.hex,
      }
    })
  }

  let formattedDefaultColours = defaultBrandColours.map(defaultColour => {
    return {
      label: defaultColour?.hex,
      value: defaultColour?.hex,
    }
  })

  return [
    {
      label: 'Brand Colours',
      options: brandColours,
    },
    {
      label: 'Default Colours',
      options: formattedDefaultColours,
    },
  ]
}

export default generateHexColoursFieldOptions
