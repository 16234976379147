import React from 'react'
import PropTypes from 'prop-types'

function TelephoneIcon({ colour }, props) {
  return (
    <svg
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M18.3656 29.4446C18.0913 29.7187 18.0913 30.1639 18.3656 30.4386C18.6406 30.713 19.0855 30.713 19.3596 30.4386C19.6346 30.1639 19.6346 29.7187 19.3596 29.4446C19.0855 29.1697 18.6406 29.1697 18.3656 29.4446Z"
        fill={colour}
      />
      <path
        d="M36.3961 28.2235L31.0579 23.6843C31.0332 23.6634 31.0071 23.6442 30.9799 23.6269C29.5794 22.7353 27.7812 22.9328 26.607 24.1067L23.6965 27.017C23.4949 27.2188 23.1941 27.2784 22.9305 27.1697C19.6439 25.8101 16.944 23.7634 14.9058 21.0868C13.3515 19.0458 12.7912 17.424 12.7173 17.1955C12.7085 17.1466 12.6945 17.098 12.6747 17.0505C12.5657 16.7868 12.6253 16.4866 12.8274 16.285L15.7377 13.3739C16.9119 12.1997 17.1091 10.4015 16.2176 9.00105C16.2104 8.98978 16.203 8.9788 16.1953 8.96781L12.3402 3.6106C11.1273 1.72892 8.48537 1.44465 6.89977 3.02998L6.31695 3.6128C6.31667 3.61335 6.31612 3.6139 6.31558 3.61418L4.23477 5.69499C1.7535 8.17652 1.34756 11.9053 3.06115 16.4789C4.52892 20.3966 7.46722 24.6843 11.3347 28.5518C15.2936 32.5107 19.6972 35.5034 23.7341 36.9785C27.7298 38.4384 31.9802 38.6183 34.9885 35.6096L36.9509 33.6475C38.5294 32.0688 38.2539 29.4441 36.3961 28.2235ZM30.1867 24.7889L35.5202 29.3244C35.5447 29.3452 35.5708 29.3645 35.598 29.3818C36.7373 30.1071 36.9097 31.7002 35.9564 32.6532L35.8108 32.7991L26.7139 25.9881L27.6013 25.101C28.2948 24.4072 29.3517 24.2817 30.1867 24.7889ZM11.1655 4.38322C11.1726 4.39448 11.18 4.40574 11.1877 4.41645L15.0412 9.77174C15.5647 10.6097 15.4436 11.6795 14.7435 12.3796L13.8835 13.2398L7.72897 4.18931L7.89404 4.02424C8.85122 3.06706 10.4429 3.24806 11.1655 4.38322ZM24.2164 35.6577C20.3679 34.2515 16.1461 31.3747 12.3289 27.5575C8.60018 23.829 5.77642 19.7193 4.37759 15.9856C3.32757 13.183 2.62307 9.2952 5.22903 6.68952L6.71685 5.20142L12.8714 14.252L11.8335 15.2904C11.2429 15.8799 11.0573 16.7522 11.352 17.5289C11.5739 18.4397 12.5951 20.3301 13.6253 21.7232C15.1414 23.7733 17.8932 26.6075 22.393 28.4691C23.1837 28.7962 24.0854 28.6166 24.6908 28.0115L25.7095 26.9925L34.8064 33.8035L33.9942 34.6154C31.2007 37.4092 27.1352 36.7242 24.2164 35.6577Z"
        fill={colour}
      />
      <path
        d="M17.0462 27.7659C16.3035 27.1147 15.019 25.9946 14.3425 25.2987C12.6835 23.5938 10.943 21.5273 9.90812 20.0343C9.68702 19.7151 9.24894 19.6355 8.92979 19.8569C8.61064 20.078 8.53126 20.516 8.75236 20.8352C10.0575 22.7182 11.9752 24.882 13.3345 26.2789C14.0486 27.0139 15.3442 28.1438 16.119 28.8231C16.4104 29.0785 16.8545 29.0505 17.1113 28.758C17.3673 28.4663 17.3382 28.0219 17.0462 27.7659Z"
        fill={colour}
      />
    </svg>
  )
}

TelephoneIcon.defaultProps = {
  colour: '#000000',
}

TelephoneIcon.propTypes = {
  colour: PropTypes.string,
}

export default TelephoneIcon
