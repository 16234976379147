import React from 'react'
import PropTypes from 'prop-types'

function FinancialIcon({ colour }, props) {
  return (
    <svg
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M26.3231 27.969H15.0419C14.7512 27.969 14.5155 28.2046 14.5155 28.4954C14.5155 28.7861 14.7512 29.0217 15.0419 29.0217H26.3231C26.6138 29.0217 26.8495 28.7861 26.8495 28.4954C26.8495 28.2046 26.6138 27.969 26.3231 27.969Z"
        fill={colour}
      />
      <path
        d="M26.3231 30.8961H15.0419C14.7512 30.8961 14.5155 31.1317 14.5155 31.4224C14.5155 31.7132 14.7512 31.9488 15.0419 31.9488H26.3231C26.6138 31.9488 26.8495 31.7132 26.8495 31.4224C26.8495 31.1317 26.6138 30.8961 26.3231 30.8961Z"
        fill={colour}
      />
      <path
        d="M20.6367 23.0615C20.9273 23.0615 21.163 22.8259 21.163 22.5352V21.8192C22.4628 21.584 23.2218 20.5214 23.3887 19.5292C23.6005 18.2697 22.9412 17.1745 21.7094 16.739C21.5129 16.6696 21.3317 16.6031 21.1631 16.5387V13.7009C21.6473 13.7999 21.9369 14.0473 21.9605 14.0681C22.1744 14.2622 22.505 14.2478 22.7011 14.0351C22.8982 13.8215 22.8847 13.4884 22.6711 13.2914C22.6383 13.2611 22.0784 12.7574 21.1631 12.6342V12.0295C21.1631 11.7388 20.9274 11.5032 20.6367 11.5032C20.3461 11.5032 20.1104 11.7388 20.1104 12.0295V12.6746C20.0127 12.6948 19.9129 12.7197 19.811 12.7505C18.0953 13.2675 17.6653 15.5899 18.8931 16.5529C19.1991 16.7928 19.5818 17.0125 20.1104 17.2457V20.8058C19.6235 20.7776 19.3041 20.672 18.7652 20.3195C18.5219 20.1604 18.1956 20.2285 18.0365 20.4718C17.8774 20.7151 17.9455 21.0413 18.1888 21.2005C18.9365 21.6897 19.4427 21.8271 20.1103 21.8592V22.5352C20.1102 22.8259 20.3459 23.0615 20.6367 23.0615ZM19.5427 15.7245C18.9922 15.2928 19.2107 14.0347 20.1102 13.7599V16.08C19.8793 15.9598 19.6939 15.8432 19.5427 15.7245ZM21.3584 17.7316C22.4663 18.1232 22.3979 19.0728 22.3505 19.3546C22.2553 19.9207 21.8515 20.5237 21.163 20.7357V17.6615C21.2269 17.6847 21.2916 17.7079 21.3584 17.7316Z"
        fill={colour}
      />
      <path
        d="M29.566 18.2224C29.6034 17.9341 29.4001 17.67 29.1117 17.6326C28.8235 17.5948 28.5594 17.7985 28.522 18.0869C28.0098 22.0303 24.6198 25.0039 20.6365 25.0039C16.6515 25.0039 13.2614 22.0287 12.7507 18.0833C12.7134 17.7949 12.4494 17.5909 12.1611 17.6288C11.8728 17.6661 11.6694 17.9301 11.7067 18.2184C12.2851 22.6869 16.1241 26.0567 20.6366 26.0567C25.147 26.0567 28.9858 22.6887 29.566 18.2224Z"
        fill={colour}
      />
      <path
        d="M28.4872 15.7778C28.5288 16.0364 28.7523 16.2205 29.0062 16.2205C29.034 16.2205 29.0622 16.2183 29.0905 16.2138C29.3775 16.1676 29.5727 15.8974 29.5265 15.6104C28.8346 11.3118 25.1062 8.04709 20.6366 8.04709C16.1797 8.04709 12.4541 11.2955 11.7507 15.5846C11.7037 15.8715 11.8982 16.1423 12.185 16.1892C12.4718 16.2363 12.7426 16.0418 12.7897 15.755C13.4099 11.9722 16.6976 9.09981 20.6366 9.09981C24.5801 9.09981 27.8759 11.9802 28.4872 15.7778Z"
        fill={colour}
      />
      <path
        d="M33.892 4.63806H32.8488V4.19797C32.8488 2.98599 31.8628 2 30.6508 2C29.8724 2 7.61508 2 6.89635 2C5.6843 2 4.6983 2.98606 4.6983 4.19797V35.802C4.6983 37.014 5.68437 38 6.89635 38H30.6508C31.8628 38 32.8488 37.0139 32.8488 35.802V34.9768C32.8488 34.686 32.6131 34.4504 32.3225 34.4504C32.0318 34.4504 31.7961 34.686 31.7961 34.9768V35.802C31.7961 36.4334 31.2823 36.9473 30.6508 36.9473H9.36643V3.05272H30.6507C31.2822 3.05272 31.796 3.5665 31.796 4.19797V32.4606C31.796 32.7514 32.0317 32.987 32.3224 32.987C32.6131 32.987 32.8487 32.7514 32.8487 32.4606V24.5692H33.892C34.6693 24.5692 35.3017 23.9368 35.3017 23.1595C35.3017 21.1392 35.3017 8.04471 35.3017 6.04783C35.3017 5.27045 34.6693 4.63806 33.892 4.63806ZM6.89635 36.9474C6.26487 36.9474 5.75102 36.4335 5.75102 35.802V4.19797C5.75102 3.5665 6.2648 3.05272 6.89635 3.05272H8.31371V36.9474H6.89635ZM32.8487 11.9837H34.249V17.2237H32.8487V11.9837ZM33.892 5.69085C34.0888 5.69085 34.2491 5.85102 34.2491 6.04783V10.9309H32.8488V5.69085H33.892ZM33.892 23.5165H32.8488V18.2765H34.2491V23.1595C34.249 23.3563 34.0888 23.5165 33.892 23.5165Z"
        fill={colour}
      />
    </svg>
  )
}

FinancialIcon.defaultProps = {
  colour: '#000000',
}

FinancialIcon.propTypes = {
  colour: PropTypes.string,
}

export default FinancialIcon
