import React from 'react'
import PropTypes from 'prop-types'
import { Navigate } from 'react-router-dom'

function PermissionProtectedRoute({
  children,
  userRouteAccess,
  redirectPath = '/',
}) {
  if (!userRouteAccess) {
    return <Navigate to={redirectPath} replace />
  }

  return children
}

PermissionProtectedRoute.propTypes = {
  children: PropTypes.node.isRequired,
  redirectPath: PropTypes.string,
  userRouteAccess: PropTypes.bool,
}

export default PermissionProtectedRoute
