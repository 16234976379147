import React from 'react'
import { get } from 'lodash'
import { useSelector } from 'react-redux'
import {
  selectActiveClientId,
  selectActiveOperatingLocation,
} from '../../redux/user/userSelections'
import { selectWorkflows } from '../../redux/workflows/workflows'

export default function getWorkflowsDataById(workflowId) {
  const activeClientId = useSelector(selectActiveClientId)
  const activeOperatingLocation = useSelector(selectActiveOperatingLocation)
  const workflows = useSelector(selectWorkflows)
  const workflowsByClient = get(
    workflows,
    `${activeOperatingLocation}-${activeClientId}`,
    [],
  )

  const activeWorkflow = workflowsByClient.find(workflow => {
    const activeWorkflowId = get(workflow, '_id', null)

    return activeWorkflowId === workflowId
  })

  return activeWorkflow
}
