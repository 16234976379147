import EditorCardsComponent, {
  createCardComponent,
  createEmptyCard,
} from '../components/editor-flow/base-components/cards-component/EditorCardsComponent'
import EditorAccordionsComponent, {
  createAccordionComponent,
  createEmptyAccordion,
} from '../components/editor-flow/base-components/accordions-component/EditorAccordionsComponent'
import EditorAccordionSubcomponent from '../components/editor-flow/base-components/accordions-component/EditorAccordionSubcomponent'
import EditorTextField, {
  createContentField,
} from '../components/editor-flow/base-fields/text-field/EditorTextField'
import EditorTableField, {
  createTableField,
} from '../components/editor-flow/base-fields/table-field/EditorTableField'
import EditorCardSubcomponent from '../components/editor-flow/base-components/cards-component/EditorCardSubcomponent'
import Cards from '../components/consumer-flow/base-components/Cards'
import Accordions from '../components/consumer-flow/base-components/accordions/Accordions'
import EditorContentComponent, {
  createContentComponent,
  createEmptyContent,
} from '../components/editor-flow/base-components/content-component/EditorContentComponent'
import EditorContentSubcomponent from '../components/editor-flow/base-components/content-component/EditorContentSubcomponent'
import Content from '../components/consumer-flow/base-components/Content'
import EditorImageField, {
  createImageField,
} from '../components/editor-flow/base-fields/image-field/EditorImageField'
import EditorGalleryField, {
  createGalleryField,
} from '../components/editor-flow/base-fields/gallery-field/EditorGalleryField'
import RichText from '../components/consumer-flow/base-fields/RichText'
import GalleryField from '../components/consumer-flow/base-fields/gallery/GalleryField'
import EditorImageComponent, {
  createImageComponent,
} from '../components/editor-flow/base-components/image-component/EditorImageComponent'
import Image from '../components/consumer-flow/base-components/Image'
import ImageField from '../components/consumer-flow/base-fields/image/ImageField'
import EditorVideoComponent, {
  createVideoComponent,
} from '../components/editor-flow/base-components/video-component/EditorVideoComponent'
import Video from '../components/consumer-flow/base-components/Video'
import Table from '../components/consumer-flow/base-components/Table'
import EditorVideoField, {
  createVideoField,
} from '../components/editor-flow/base-fields/video-field/EditorVideoField'
import VideoField from '../components/consumer-flow/base-fields/video/VideoField'
import EditorGalleryComponent, {
  createGalleryComponent,
} from '../components/editor-flow/base-components/gallery-component/EditorGalleryComponent'
import Gallery from '../components/consumer-flow/base-components/Gallery'
import EditorTableComponent, {
  createTableComponent,
} from '../components/editor-flow/base-components/table-component/EditorTableComponent'
import TableField from '../components/consumer-flow/base-fields/table/TableField'
import EditorDividerComponent, {
  createDividerComponent,
} from '../components/editor-flow/base-components/divider-component/EditorDividerComponent'
import Divider from '../components/consumer-flow/base-components/Divider'
import EditorDividerField, {
  createDividerField,
} from '../components/editor-flow/base-fields/divider-field/EditorDividerField'
import DividerField from '../components/consumer-flow/base-fields/divider/DividerField'
import { ReactComponent as ContentComponentIcon } from '../assets/icons/content-component-icon.svg'
import { ReactComponent as CardsComponentIcon } from '../assets/icons/cards-component-icon.svg'
import { ReactComponent as AccordionsComponentIcon } from '../assets/icons/accordions-component-icon.svg'
import { ReactComponent as ImageComponentIcon } from '../assets/icons/image-component-icon.svg'
import { ReactComponent as VideoComponentIcon } from '../assets/icons/video-component-icon.svg'
import { ReactComponent as GalleryComponentIcon } from '../assets/icons/media-component-icon.svg'
import { ReactComponent as TableComponentIcon } from '../assets/icons/table-component-icon.svg'
import { ReactComponent as DividerComponentIcon } from '../assets/icons/divider-component-icon.svg'
import { ReactComponent as SpacerComponentIcon } from '../assets/icons/spacer-component-icon.svg'
import EditorAccordionsAndContentComponent, {
  createAccordionAndContentComponent,
} from '../components/editor-flow/base-components/accordions-and-content-component/EditorAccordionsAndContentComponent'
import AccordionsAndContent from '../components/consumer-flow/base-components/accordions/AccordionsAndContent'
import ButtonField from '../components/consumer-flow/base-fields/button/ButtonField'
import EditorButtonField, {
  createButtonField,
} from '../components/editor-flow/base-fields/button-field/EditorButtonField'
import EditorIconField, {
  createIconField,
} from '../components/editor-flow/base-fields/icon-field/EditorIconField'
import IconField from '../components/consumer-flow/base-fields/icon/IconField'
import EditorSpacerField, {
  createSpacerField,
} from '../components/editor-flow/base-fields/spacer-field/EditorSpacerField'
import SpacerField from '../components/consumer-flow/base-fields/spacer/SpacerField'

export const EDITOR_ACCORDIONS = 'accordions'
export const EDITOR_ACCORDIONS_AND_CONTENT = 'accordionsAndContent'
export const EDITOR_CARDS = 'cards'
export const EDITOR_CONTENT = 'content'
export const EDITOR_IMAGE = 'image'
export const EDITOR_VIDEO = 'video'
export const EDITOR_GALLERY = 'gallery'
export const EDITOR_TABLE = 'table'
export const EDITOR_DIVIDER = 'divider'
export const EDITOR_FILE_UPLOAD = 'fileUpload'

export const EDITOR_CONTENT_FIELD = 'RichText'
export const EDITOR_TABLE_FIELD = 'Table'
export const EDITOR_IMAGE_FIELD = 'Image'
export const EDITOR_GALLERY_FIELD = 'GalleryField'
export const EDITOR_VIDEO_FIELD = 'Video'
export const EDITOR_DIVIDER_FIELD = 'Divider'
export const EDITOR_BUTTON_FIELD = 'Button'
export const EDITOR_ICON_FIELD = 'Icon'
export const EDITOR_SPACER_FIELD = 'Spacer'

export const EDITOR_COMPONENTS = [
  {
    type: EDITOR_CONTENT,
    title: 'Content',
    icon: <ContentComponentIcon />,
    editorComponent: EditorContentComponent,
    editorSubComponent: EditorContentSubcomponent,
    frontEndComponent: Content,
    createComponent: createContentComponent,
    createSubComponent: createEmptyContent,
    fields: [
      EDITOR_CONTENT_FIELD,
      EDITOR_BUTTON_FIELD,
      EDITOR_TABLE_FIELD,
      EDITOR_IMAGE_FIELD,
      EDITOR_ICON_FIELD,
      EDITOR_VIDEO_FIELD,
      EDITOR_GALLERY_FIELD,
      EDITOR_DIVIDER_FIELD,
      EDITOR_SPACER_FIELD,
    ],
  },
  {
    type: EDITOR_CARDS,
    title: 'Cards',
    icon: <CardsComponentIcon />,
    editorComponent: EditorCardsComponent,
    editorSubComponent: EditorCardSubcomponent,
    frontEndComponent: Cards,
    createComponent: createCardComponent,
    createSubComponent: createEmptyCard,
    fields: [
      EDITOR_CONTENT_FIELD,
      EDITOR_ICON_FIELD,
      EDITOR_BUTTON_FIELD,
      EDITOR_IMAGE_FIELD,
      EDITOR_SPACER_FIELD,
    ],
  },
  {
    type: EDITOR_ACCORDIONS,
    title: 'Accordions',
    icon: <AccordionsComponentIcon />,
    editorComponent: EditorAccordionsComponent,
    editorSubComponent: EditorAccordionSubcomponent,
    frontEndComponent: Accordions,
    createComponent: createAccordionComponent,
    createSubComponent: createEmptyAccordion,
    fields: [
      EDITOR_CONTENT_FIELD,
      EDITOR_BUTTON_FIELD,
      EDITOR_TABLE_FIELD,
      EDITOR_IMAGE_FIELD,
      EDITOR_ICON_FIELD,
      EDITOR_VIDEO_FIELD,
      EDITOR_GALLERY_FIELD,
      EDITOR_DIVIDER_FIELD,
      EDITOR_SPACER_FIELD,
    ],
  },
  {
    type: EDITOR_ACCORDIONS_AND_CONTENT,
    title: 'Accordions + Content',
    icon: <AccordionsComponentIcon />,
    editorComponent: EditorAccordionsAndContentComponent,
    frontEndComponent: AccordionsAndContent,
    createComponent: createAccordionAndContentComponent,
    fields: [
      EDITOR_CONTENT_FIELD,
      EDITOR_BUTTON_FIELD,
      EDITOR_TABLE_FIELD,
      EDITOR_IMAGE_FIELD,
      EDITOR_ICON_FIELD,
      EDITOR_VIDEO_FIELD,
      EDITOR_GALLERY_FIELD,
      EDITOR_DIVIDER_FIELD,
      EDITOR_SPACER_FIELD,
    ],
  },
  {
    type: EDITOR_IMAGE,
    title: 'Image',
    icon: <ImageComponentIcon />,
    editorComponent: EditorImageComponent,
    frontEndComponent: Image,
    createComponent: createImageComponent,
    fields: [],
  },
  {
    type: EDITOR_VIDEO,
    title: 'Video',
    icon: <VideoComponentIcon />,
    editorComponent: EditorVideoComponent,
    frontEndComponent: Video,
    createComponent: createVideoComponent,
    fields: [],
  },
  {
    type: EDITOR_GALLERY,
    title: 'Gallery',
    icon: <GalleryComponentIcon />,
    editorComponent: EditorGalleryComponent,
    frontEndComponent: Gallery,
    createComponent: createGalleryComponent,
    fields: [],
  },
  {
    type: EDITOR_TABLE,
    title: 'Table',
    icon: <TableComponentIcon />,
    editorComponent: EditorTableComponent,
    frontEndComponent: Table,
    createComponent: createTableComponent,
    fields: [],
  },
  {
    type: EDITOR_DIVIDER,
    title: 'Divider',
    icon: <DividerComponentIcon />,
    editorComponent: EditorDividerComponent,
    frontEndComponent: Divider,
    createComponent: createDividerComponent,
  },
]

export const EDITOR_FIELDS = [
  {
    type: EDITOR_CONTENT_FIELD,
    title: 'Text',
    icon: <ContentComponentIcon />,
    createField: createContentField,
    editorField: EditorTextField,
    consumerField: RichText,
  },
  {
    type: EDITOR_BUTTON_FIELD,
    title: 'Button',
    icon: <ContentComponentIcon />,
    createField: createButtonField,
    editorField: EditorButtonField,
    consumerField: ButtonField,
  },
  {
    type: EDITOR_TABLE_FIELD,
    title: 'Table',
    icon: <TableComponentIcon />,
    createField: createTableField,
    editorField: EditorTableField,
    consumerField: TableField,
  },
  {
    type: EDITOR_IMAGE_FIELD,
    title: 'Image',
    icon: <ImageComponentIcon />,
    createField: createImageField,
    editorField: EditorImageField,
    consumerField: ImageField,
  },
  {
    type: EDITOR_GALLERY_FIELD,
    title: 'Gallery',
    icon: <GalleryComponentIcon />,
    createField: createGalleryField,
    editorField: EditorGalleryField,
    consumerField: GalleryField,
  },
  {
    type: EDITOR_VIDEO_FIELD,
    title: 'Video',
    icon: <VideoComponentIcon />,
    createField: createVideoField,
    editorField: EditorVideoField,
    consumerField: VideoField,
  },
  {
    type: EDITOR_DIVIDER_FIELD,
    title: 'Divider',
    icon: <DividerComponentIcon />,
    createField: createDividerField,
    editorField: EditorDividerField,
    consumerField: DividerField,
  },
  {
    type: EDITOR_ICON_FIELD,
    title: 'Icon',
    icon: <ImageComponentIcon />,
    createField: createIconField,
    editorField: EditorIconField,
    consumerField: IconField,
  },
  {
    type: EDITOR_SPACER_FIELD,
    title: 'Spacer',
    icon: <SpacerComponentIcon />,
    createField: createSpacerField,
    editorField: EditorSpacerField,
    consumerField: SpacerField,
  },
]

export const selectComponent = type =>
  EDITOR_COMPONENTS.find(component => component.type === type)
export const selectField = type =>
  EDITOR_FIELDS.find(field => field.type === type)
