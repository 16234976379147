import React from 'react'
import { get } from 'lodash'
import { EDITOR_VIDEO_FIELD } from '../../../../configuration/editor'
import EditorAlignmentButtons from '../elements/EditorAlignmentButtons'
import '../../../../styles/editor-flow/editor-fields/editor-video-field.scss'
import PropTypes from 'prop-types'

function EditorVideoField({ field, onChange }) {
  const block = get(field, 'blocks[0]', null)
  const video = get(block, 'video', null)
  const videoUrl = get(video, 'url', null)
  const configurations = get(block, 'configurations', null)
  const alignment = get(configurations, 'alignment', 'left')

  const alignments = {
    left: 'flex-start',
    center: 'center',
    right: 'flex-end',
  }

  const style = {
    justifyContent: alignments[alignment],
  }

  function handleUrlChange(evt) {
    onChange({
      ...field,
      blocks: [
        {
          video: {
            ...video,
            url: evt.target.value,
          },
          configurations,
        },
      ],
    })
  }

  function setAlignment(align) {
    onChange({
      ...field,
      blocks: [
        {
          video,
          configurations: {
            ...configurations,
            alignment: align,
          },
        },
      ],
    })
  }

  return (
    <div className="editor-video-field" style={style}>
      <div
        className={`editor-video-field-options-overlay ${
          videoUrl ? ' populated' : ''
        }`}
      >
        {videoUrl ? (
          <div className="editor-video-field-alignments">
            <EditorAlignmentButtons setAlignment={setAlignment} />
          </div>
        ) : null}

        <div className="editor-video-field-input form__field full">
          <label>Video URL (Youtube / Vimeo)</label>
          <input value={videoUrl} onChange={handleUrlChange} type="text" />
        </div>
      </div>
    </div>
  )
}

export const createVideoField = id => {
  return {
    id,
    type: 'field',
    fieldType: EDITOR_VIDEO_FIELD,
    blocks: [
      {
        configurations: { alignment: 'left' },
        video: {
          url: '',
        },
      },
    ],
  }
}

EditorVideoField.propTypes = {
  field: PropTypes.object,
  onChange: PropTypes.func,
}

export default EditorVideoField
