import { useAuth0 } from '@auth0/auth0-react'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { SET_AUTH_TOKEN } from '../redux/auth/auth'

const useAuthToken = ({ scope = 'read:users read:roles' }) => {
  const dispatch = useDispatch()
  const { getAccessTokenSilently, getAccessTokenWithPopup } = useAuth0()
  const [state, setState] = useState({
    isLoading: false,
    token: null,
  })

  useEffect(() => {
    getAuthToken()
  }, [])

  async function getAuthToken() {
    let token
    setState({
      ...state,
      isLoading: true,
    })

    const authOptions = {
      authorizationParams: {
        audience: process.env.REACT_APP_AUTH_AUDIENCE,
        scope,
      },
    }

    try {
      token = await getAccessTokenSilently(authOptions)
      if (token) {
        dispatch({
          type: SET_AUTH_TOKEN,
          payload: token,
        })
      }
    } catch (e) {
      token = await getAccessTokenWithPopup(authOptions)
    }

    setState({
      token,
      isLoading: false,
    })
  }
  return state
}

export default useAuthToken
