import React from 'react'
import { Outlet } from 'react-router-dom'
import { useSelector } from 'react-redux'
import {
  selectActiveClient,
  selectActiveOperatingLocation,
} from '../redux/user/userSelections'
import { selectUserProducts } from '../redux/user/user'
import ValidateWorkflowClient from '../utilities/permissions/validateWorkflowClient'
import Header from '../components/global-components/structure/header/Header'
import UserLicenseError from '../components/global-components/platform-dashboard/UserLicenseError'

function PlatformDashboardContainer() {
  const activeClient = useSelector(selectActiveClient)
  const activeOperatingLocation = useSelector(selectActiveOperatingLocation)
  const userProducts = useSelector(selectUserProducts)
  const isWorkflowClient = ValidateWorkflowClient(userProducts)

  return (
    <div className="platform-dashboard-container view-container">
      <Header
        activeClient={activeClient}
        activeOperatingLocation={activeOperatingLocation}
        enableBreadCrumbs={true}
        title="Workflows"
      />

      {isWorkflowClient ? <Outlet /> : <UserLicenseError />}
    </div>
  )
}

export default PlatformDashboardContainer
