import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { get, isEmpty } from 'lodash'
import { useParams } from 'react-router-dom'
import { selectComponent } from '../../../configuration/editor'
import getIconComponent from '../../../utilities/icon/getIconComponent'
import BrowseWorkflowStepNavigation from './elements/BrowseWorkflowStepNavigation'
import '../../../styles/consumer-flow/browse-workflow/browse-workflow-step.scss'
import useEntryHighlight from '../../../hooks/useEntryHighlight'
import highlightTextInHTML from '../../../utilities/find-replace/highlightTextInHTML'
import BrowseWorkflowStepSideNavigation from './elements/BrowseWorkflowStepSideNavigation'
import ResourceChangedToasts from '../../global-components/resource-changed-toasts/ResourceChangedToasts'

function BrowseWorkflowStep({ activeWorkflowContent }) {
  const ref = useRef()
  const headerRef = useRef()
  const { step } = useParams()
  const highlight = useEntryHighlight()
  const workflowSteps = get(activeWorkflowContent, 'steps', [])
  const activeStepContent = workflowSteps.find(
    activeStep => activeStep?.slug === step,
  )
  const name = get(activeStepContent, 'name', null)
  const icon = get(activeStepContent, 'icon', null)
  const iconColour = get(icon, 'colour', null) || undefined
  const Icon = getIconComponent(icon)
  const activeStepComponents = get(activeStepContent, 'components', [])
  const hasInnerText = !!headerRef?.current?.innerText

  const displayedSteps = [...workflowSteps]
  const orderedSteps = []

  displayedSteps.map((step, i) => {
    const stepId = get(step, '_id', null)
    const stepSlug = get(step, 'slug', null)
    const nestedSteps = get(step, 'nestedSteps', [])

    orderedSteps.push({
      id: stepId,
      slug: stepSlug,
      index: Number(i) + 1,
    })

    if (!isEmpty(nestedSteps)) {
      nestedSteps.forEach((nestedStep, j) => {
        const nestedStepData = displayedSteps.find(
          step => step?._id === nestedStep,
        )
        const nestedStepId = get(nestedStepData, '_id', null)
        const nestedStepSlug = get(nestedStepData, 'slug', null)

        displayedSteps.splice(
          displayedSteps.findIndex(step => step._id === nestedStep),
          1,
        )

        orderedSteps.push({
          id: nestedStepId,
          slug: nestedStepSlug,
          index: `${Number(i) + 1}.${Number(j) + 1}`,
        })
      })
    }
  })

  useEffect(
    function highlightResultsOnSearch() {
      if (highlight?.itemType === 'step' && hasInnerText) {
        highlightTextInHTML({ ref: headerRef, highlight })
      }
    },
    [highlight, hasInnerText],
  )

  useEffect(() => {
    const container = ref.current
    const pollForScrollable = setInterval(() => {
      if (container && !container.classList.contains('scrolled')) {
        const highlight = [
          ...container.querySelectorAll('.find-replace__highlight'),
        ]?.[0]
        const outline = [
          ...container.querySelectorAll('.find-replace__outline'),
        ]?.[0]
        if (!!highlight) {
          container.classList.add('scrolled')
          highlight.scrollIntoView({
            behavior: 'instant',
            block: 'center',
          })
          clearInterval(pollForScrollable)
        }
        if (!!outline) {
          container.classList.add('scrolled')
          outline.scrollIntoView({
            behavior: 'instant',
            block: 'center',
          })
          clearInterval(pollForScrollable)
        }
      }
    }, 250)
    return () => clearInterval(pollForScrollable)
  }, [])

  return (
    <div className="browse-workflow__step" ref={ref}>
      <div className="browse-workflow__step-heading" ref={headerRef}>
        {Icon ? (
          <div className="browse-workflow__step-icon">
            <Icon colour={iconColour} />
          </div>
        ) : null}

        <h2>{name}</h2>
      </div>

      <div className="browse-workflow__step-content">
        {!isEmpty(activeStepComponents) ? (
          activeStepComponents.map(component => {
            const componentId = get(component, '_id', null)
            const componentType = get(component, 'componentType', null)
            const componentConfig = selectComponent(componentType)
            const StepComponent = get(
              componentConfig,
              'frontEndComponent',
              null,
            )

            return StepComponent ? (
              <StepComponent key={componentId} component={component} />
            ) : null
          })
        ) : (
          <p>
            There is currently no published content for this step - please check
            back later.
          </p>
        )}
      </div>
      {/* <BrowseWorkflowStepSideNavigation steps={workflowSteps} /> */}
      <BrowseWorkflowStepNavigation workflowSteps={workflowSteps} />
      <ResourceChangedToasts
        types={['editStep']}
        resourceId={activeStepContent?._id}
        isPaused={true}
      >
        {resource => (
          <>
            Changes have been made to the workflow - &quot;{resource.name}&quot;
          </>
        )}
      </ResourceChangedToasts>
    </div>
  )
}

BrowseWorkflowStep.propTypes = {
  activeWorkflowContent: PropTypes.object,
}

export default BrowseWorkflowStep
