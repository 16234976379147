import React from 'react'
import PropTypes from 'prop-types'
import { useNavigate, useParams } from 'react-router-dom'
import { get, isEmpty } from 'lodash'
import routes from '../../../../configuration/routes'
import getIconComponent from '../../../../utilities/icon/getIconComponent'
import validateUserManagementAccess from '../../../../utilities/permissions/validateUserManagementAccess'
import '../../../../styles/consumer-flow/browse-workflow/browse-workflow-steps-cards.scss'

function BrowseWorkflowStepsCards({ steps }) {
  const isManager = validateUserManagementAccess()
  const navigate = useNavigate()
  const { category, workflow } = useParams()
  const displayedSteps = [...steps]

  return !isEmpty(displayedSteps) ? (
    <div className="browse-workflow__steps-cards">
      {displayedSteps.map((step, i) => {
        const stepName = get(step, 'name', null)
        const stepsStatus = get(step, 'status', 'draft')
        const icon = get(step, 'icon', null)
        const iconColour = get(icon, 'colour', null) || undefined
        const Icon = getIconComponent(icon)
        const slug = get(step, 'slug', null)
        const nestedSteps = get(step, 'nestedSteps', [])

        return (
          <>
            <div
              key={`workflows-step-${stepName}-${i}`}
              onClick={() =>
                navigate(
                  `${routes.browseWorkflows}/${category}/${workflow}/${slug}`,
                )
              }
              className="browse-workflow__steps-card"
            >
              <div className="browse-workflow__steps-card-icon">
                {Icon ? <Icon colour={iconColour} /> : null}
              </div>

              {isManager ? (
                <div className={`status-flag lg ${stepsStatus}`}>
                  {stepsStatus}
                </div>
              ) : null}

              {/*<div className="browse-workflow__steps-card-step">*/}
              {/*  Step {Number(i) + 1}*/}
              {/*</div>*/}

              <p className="browse-workflow__steps-card-name">{stepName}</p>
            </div>

            {!isEmpty(nestedSteps)
              ? nestedSteps.map((nestedStepId, j) => {
                  const nestedStepData = displayedSteps.find(
                    step => step?._id === nestedStepId,
                  )

                  displayedSteps.splice(
                    displayedSteps.findIndex(step => step._id === nestedStepId),
                    1,
                  )

                  const nestedStepName = get(nestedStepData, 'name', null)
                  const nestedStepIcon = get(nestedStepData, 'icon', null)
                  const nestedStepIconColour =
                    get(nestedStepIcon, 'colour', null) || undefined
                  const NestedIcon = getIconComponent(nestedStepIcon)
                  const nestedStepSlug = get(nestedStepData, 'slug', null)
                  const nestedStepStatus = get(nestedStepData, 'status', null)

                  return nestedStepName && nestedStepSlug ? (
                    <div
                      key={`workflows-nested-step-${i}-${j}`}
                      onClick={() =>
                        navigate(
                          `${routes.browseWorkflows}/${category}/${workflow}/${nestedStepSlug}`,
                        )
                      }
                      className="browse-workflow__steps-card"
                    >
                      <div className="browse-workflow__steps-card-icon">
                        {NestedIcon ? (
                          <NestedIcon colour={nestedStepIconColour} />
                        ) : null}
                      </div>

                      {isManager ? (
                        <div className={`status-flag lg ${nestedStepStatus}`}>
                          {nestedStepStatus}
                        </div>
                      ) : null}

                      {/*<div className="browse-workflow__steps-card-step">*/}
                      {/*  Step {Number(i) + 1}.{Number(j) + 1}*/}
                      {/*</div>*/}

                      <p className="browse-workflow__steps-card-name">
                        {nestedStepName}
                      </p>
                    </div>
                  ) : null
                })
              : null}
          </>
        )
      })}
    </div>
  ) : null
}

BrowseWorkflowStepsCards.propTypes = {
  steps: PropTypes.array,
}

export default BrowseWorkflowStepsCards
