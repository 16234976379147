import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { Formik, Form, Field, FieldArray } from 'formik'
import {
  matchPath,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom'
import * as Yup from 'yup'
import { get, isEmpty, isBoolean, isEqual } from 'lodash'
import { selectUserState } from '../../redux/user/user'
import {
  selectActiveClient,
  selectActiveClientId,
  selectActiveOperatingLocation,
  selectManageCategoryModalVisibility,
  selectManagedContentId,
  toggleManageCategoryModal,
} from '../../redux/user/userSelections'
import { supportedUserAccessPermissions } from '../../configuration/supportedUserAccessPermissions'
import {
  createWorkflow,
  selectCreateWorkflowsState,
} from '../../redux/workflows/createWorkflow'
import {
  selectCategories,
  selectCategoriesLoading,
} from '../../redux/categories/categories'
import { updateWorkflow } from '../../redux/workflows/updateWorkflow'
import { selectWorkflows } from '../../redux/workflows/workflows'
import routes from '../../configuration/routes'
import useAuthToken from '../../hooks/useAuthToken'
import constructInitialWorkflowFormState from '../../utilities/forms/constructInitialWorkflowFormState'
import convertStringToSlug from '../../utilities/slug/convertStringToSlug'
import validateWorkflowSlug from '../../utilities/slug/validateWorkflowSlug'
import MaterialLoader from '../global-components/elements/MaterialLoader'
import TextField from './elements/TextField'
import CustomSelect from './elements/CustomSelect'
import AccordionFieldGroup from './elements/AccordionFieldGroup'
import CategoriesField from './elements/CategoriesField'
import FormStepper from './elements/FormStepper'
import SlugField from './elements/SlugField'
import IconField from './elements/IconField'
import IconColourField from './elements/IconColourField'
import { emitSocketMessage } from '../../redux/middleware/socket/socket'

function ManageWorkflowForm({ formCancelEvent }) {
  const [activeStep, setActiveStep] = useState(0)
  const [lastValidatedSlug, setLastValidatedSlug] = useState('')
  const [currentSlugValidity, setCurrentSlugValidity] = useState(null)
  const { token } = useAuthToken({})
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const { workflow } = useParams()
  const dispatch = useDispatch()
  const isManageCategoryModalVisible = useSelector(
    selectManageCategoryModalVisibility,
  )
  const managedContentId = useSelector(selectManagedContentId)
  const createWorkflowState = useSelector(selectCreateWorkflowsState)
  const userState = useSelector(selectUserState)
  const activeClient = useSelector(selectActiveClient)
  const activeClientId = useSelector(selectActiveClientId)
  const activeOperatingLocation = useSelector(selectActiveOperatingLocation)
  const categoriesLoading = useSelector(selectCategoriesLoading)
  const categoriesData = useSelector(selectCategories)
  const workflows = useSelector(selectWorkflows)
  const user = get(userState, 'user', {})
  const userAccessLevel = get(user, 'accessLevel', 'user')
  const userClients = get(user, 'clients', [])
  const createWorkflowLoading = get(createWorkflowState, 'loading', false)
  const createWorkflowError = get(createWorkflowState, 'error', false)
  let workflowsByClient = get(
    workflows,
    `${activeOperatingLocation}-${activeClientId}`,
    [],
  )

  // Handle Edit Logic for share model / form UI
  const managedWorkflowData = workflowsByClient.find(
    workflow => workflow?._id === managedContentId,
  )
  const isEditWorkflow = managedContentId && !isEmpty(managedWorkflowData)

  const managedWorkflowId = get(managedWorkflowData, '_id', null)
  const managedWorkflowSlug = get(managedWorkflowData, 'slug', null)
  const submitLabel = isEditWorkflow ? 'Save Configuration' : 'Create Workflow'

  // remove the option to select the currently managed workflow from the
  // related workflows options when editing an existing workflow vs. create
  if (isEditWorkflow) {
    workflowsByClient = workflowsByClient.filter(
      workflow => workflow?._id !== managedContentId,
    )
  }

  useEffect(() => {
    setLastValidatedSlug(managedWorkflowSlug)
  }, [managedWorkflowSlug])

  const createWorkflowSchema = Yup.object().shape({
    name: Yup.string().required('Workflow name required'),
    slug: Yup.string()
      .required('Workflow slug required')
      .test(
        'validateSlug',
        'The selected URL path is already in use for this client - please enter a unique path value',
        async value => {
          // Re-fire event only when the slug field had changed OR slug remains invalid
          if (
            (managedWorkflowSlug &&
              lastValidatedSlug !== managedWorkflowSlug) ||
            (value !== '' && value !== lastValidatedSlug) ||
            (isBoolean(currentSlugValidity) && currentSlugValidity === false)
          ) {
            setLastValidatedSlug(value)

            const slugExists = await validateWorkflowSlug(
              activeClientId,
              value,
              token,
            )
            setCurrentSlugValidity(slugExists)
            return slugExists
          }

          return true
        },
      ),
    status: Yup.string().required('Workflow status required'),
    categories: Yup.array()
      .min(1, 'At least 1 category is required per-workflow')
      .of(
        Yup.object().required('At least 1 category is required per-workflow'),
      ),
    operatingLocations: Yup.array()
      .min(1, 'At least 1 client is required per-workflow')
      .of(Yup.string().required('At least 1 client is required per-workflow')),
    userAccessPermissions: Yup.array()
      .min(1, 'At least 1 user access type is required per-workflow')
      .of(
        Yup.string().required(
          'At least 1 user access type is required per-workflow',
        ),
      ),
    icon: Yup.string(),
    iconColour: Yup.string(),
    description: Yup.string(),
    relatedWorkflows: Yup.array(),
  })

  async function handleCreateWorkflow(values) {
    const slug = get(values, 'slug', null)
    values.clientId = [activeClientId, ...values.clientId]
    const handleCreateWorkflowSuccess = () => {
      formCancelEvent()
      if (!isEditWorkflow) {
        navigate(`${routes.manageWorkflows}/${slug}`)
      }
    }

    const externalClientCategories =
      managedWorkflowData?.categories?.filter(
        category => !category?.clientId?.includes(activeClientId),
      ) || []

    if (isEditWorkflow) {
      await dispatch(
        updateWorkflow(
          managedWorkflowId,
          values,
          handleCreateWorkflowSuccess,
          handleUpdateStepSlug,
          token,
          externalClientCategories,
        ),
      )

      if (
        managedWorkflowData?.status === 'draft' &&
        values?.status === 'published'
      ) {
        dispatch(
          emitSocketMessage('publishWorkflow', {
            workflowId: managedContentId,
          }),
        )
      }
    } else {
      dispatch(createWorkflow(values, handleCreateWorkflowSuccess, token))
    }
  }

  // handle redirecting the manager to the new workflow slug when they
  // edit the existing workflow slug from within the workflow manager itself
  function handleUpdateStepSlug(updatedSlug) {
    if (
      matchPath({ path: `/${routes.manageWorkflows}/:workflow/` }, pathname) &&
      updatedSlug !== workflow
    ) {
      navigate(`${routes.manageWorkflows}/${updatedSlug}`, {
        replace: true,
      })
    }
  }

  const userAccessOptions = supportedUserAccessPermissions(userAccessLevel)
  const clientOptions = userClients
    .filter(client => client?.id !== activeClientId)
    .map(client => {
      const clientId = get(client, 'id', null)
      const clientName = get(client, 'name', null)

      return clientId && clientName
        ? {
            label: clientName,
            value: clientId,
          }
        : null
    })

  const activeClientOperatingLocations = get(
    activeClient,
    'operatingLocations',
    [],
  )
  const operatingLocationOptions = activeClientOperatingLocations.map(
    location => {
      const locationName = get(location, 'name', null)
      const locationCode = get(location, 'code', null)

      return locationCode && locationName
        ? {
            label: locationName,
            value: locationCode,
          }
        : null
    },
  )

  const initialFormValues = constructInitialWorkflowFormState(
    activeClientId,
    operatingLocationOptions,
    userAccessOptions,
    managedWorkflowData,
  )

  const relatedWorkflowsOptions = workflowsByClient.map(clientWorkflow => {
    const workflowId = get(clientWorkflow, '_id', null)
    const workflowName = get(clientWorkflow, 'name', null)
    const workflowStatus = get(clientWorkflow, 'status', 'draft')

    return workflowId && workflowName
      ? {
          label: workflowName,
          value: workflowId,
          status: workflowStatus,
        }
      : null
  })

  const customSelectRenderFilter = (option, searchText) => {
    if (
      option.data.label.toLowerCase().includes(searchText.toLowerCase()) ||
      option.data.value.toLowerCase().includes(searchText.toLowerCase())
    ) {
      return true
    } else {
      return false
    }
  }

  return (
    <Formik
      initialValues={initialFormValues}
      validationSchema={createWorkflowSchema}
      onSubmit={values => handleCreateWorkflow(values)}
    >
      {({ isValid, values, setFieldValue, setFieldTouched, initialValues }) => {
        const initialValuesAltered = !isEqual(values, initialValues)
        const submitButtonDisabled = isEditWorkflow
          ? !isValid || !initialValuesAltered
          : !isValid
        const canNext =
          activeStep !== 1 ||
          (activeStep === 1 && values?.categories?.length > 0)

        const formSteps = [
          {
            title: 'Add Details',
            content: (
              <>
                <div className="form__field half">
                  <Field
                    component={TextField}
                    label="Name"
                    name="name"
                    placeholder="Enter Workflow Name"
                    required={true}
                    type="text"
                    onKeyUp={e => {
                      !isEditWorkflow
                        ? setFieldValue(
                            'slug',
                            convertStringToSlug(e.target.value),
                          )
                        : null
                    }}
                  />
                </div>

                <div className="form__field half">
                  <Field
                    component={SlugField}
                    label="Path"
                    name="slug"
                    placeholder="Enter Workflow URL Path"
                    required={true}
                    type="text"
                    onChange={e =>
                      setFieldValue(
                        'slug',
                        e.target.value
                          .replace(/\W+/g, '-')
                          .replace(/[^a-zA-Z0-9-]/g, '')
                          .toLowerCase(),
                      )
                    }
                  />
                </div>

                <div className="form__field half">
                  <Field
                    component={CustomSelect}
                    label="Status"
                    name="status"
                    options={[
                      {
                        label: 'Draft',
                        value: 'draft',
                      },
                      {
                        label: 'Published',
                        value: 'published',
                      },
                    ]}
                    required={true}
                    placeholder="Set Workflow Status"
                    setFieldTouched={setFieldTouched}
                    isClearable={false}
                    defaultMenuIsOpen={true}
                  />
                </div>

                <div className="form__field half">
                  <Field
                    component={CustomSelect}
                    filterOption={customSelectRenderFilter}
                    label="Shared Clients"
                    name="clientId"
                    options={clientOptions}
                    isFixed={true}
                    isMulti={true}
                    required={true}
                    placeholder="Select Workflow Client(s)"
                    setFieldTouched={setFieldTouched}
                  />
                </div>

                <div className="form__field full">
                  <Field
                    component={TextField}
                    label="Description"
                    name="description"
                    placeholder="Enter Workflow Description"
                    required={false}
                    type="text"
                  />
                </div>

                <div
                  className={`form__field ${
                    values?.iconColour ? 'three-quarter' : 'full'
                  }`}
                >
                  <Field
                    component={IconField}
                    label="Icon"
                    name="icon"
                    placeholder="Select or Search Category Icons"
                    type="text"
                    setFieldTouched={setFieldTouched}
                  />
                </div>

                {values?.iconColour ? (
                  <div className="form__field one-quarter">
                    <Field
                      component={IconColourField}
                      label="Icon Colour"
                      name="iconColour"
                      placeholder="Select Colour"
                      type="text"
                      setFieldTouched={setFieldTouched}
                    />
                  </div>
                ) : null}

                <div className="form__field full">
                  <Field
                    component={CustomSelect}
                    label="Related Workflows"
                    filterOption={customSelectRenderFilter}
                    name="relatedWorkflows"
                    options={relatedWorkflowsOptions}
                    required={false}
                    placeholder="Select Related Workflows"
                    setFieldTouched={setFieldTouched}
                    isClearable={true}
                    isFixed={true}
                    isMulti={true}
                    getOptionLabel={option => {
                      const label = get(option, 'label', null)
                      const status = get(option, 'status', null)

                      return (
                        <div className="custom-select__option-content">
                          <span className="custom-select__option-content-label">
                            {label}
                          </span>
                          <span className={`status-flag ${status}`}>
                            {status}
                          </span>
                        </div>
                      )
                    }}
                  />
                </div>
              </>
            ),
          },
          {
            title: 'Select Categories',
            content: (
              <>
                {categoriesLoading ? (
                  <MaterialLoader containerClasses="inline-loader" />
                ) : (
                  <div className="form__field full">
                    <FieldArray
                      name="categories"
                      render={arrayHelpers => (
                        <CategoriesField arrayHelpers={arrayHelpers} />
                      )}
                    />
                  </div>
                )}
              </>
            ),
          },
          {
            title: 'Update Permissions',
            content: (
              <>
                <div className="form__field full">
                  <AccordionFieldGroup
                    currentValues={values?.operatingLocations?.length}
                    label="Operating Locations"
                    totalOptions={operatingLocationOptions.length}
                  >
                    <Field
                      component={CustomSelect}
                      label={null}
                      name="operatingLocations"
                      options={operatingLocationOptions}
                      isMulti={true}
                      required={true}
                      placeholder="Select Workflow Operating Location(s)"
                      setFieldTouched={setFieldTouched}
                    />
                  </AccordionFieldGroup>
                </div>

                <div className="form__field full">
                  <AccordionFieldGroup
                    currentValues={values?.userAccessPermissions?.length}
                    label="Access Permissions"
                    totalOptions={userAccessOptions.length}
                  >
                    <Field
                      component={CustomSelect}
                      label={null}
                      name="userAccessPermissions"
                      options={userAccessOptions}
                      isMulti={true}
                      required={true}
                      placeholder="Set Which Roles Can View This Workflow"
                      setFieldTouched={setFieldTouched}
                    />
                  </AccordionFieldGroup>
                </div>
              </>
            ),
          },
        ]

        return (
          <>
            {createWorkflowError ? (
              <p className="form__api-error">{createWorkflowError}</p>
            ) : null}

            <FormStepper
              activeStep={activeStep}
              steps={formSteps}
              setActiveStep={setActiveStep}
            />
            <Form className="form form--modal">
              {formSteps.map((step, i) => {
                return i === activeStep ? (
                  <div key={`form-step-${i}`} className="form__step">
                    {step?.content}
                  </div>
                ) : null
              })}

              <div className="form__submit">
                {createWorkflowLoading ? (
                  <MaterialLoader containerClasses="inline-loader" />
                ) : formSteps.length === activeStep + 1 ? (
                  <>
                    <button
                      className="btn btn--white"
                      type="button"
                      onClick={() => formCancelEvent()}
                    >
                      Cancel
                    </button>

                    <div
                      className="btn btn--white"
                      type="button"
                      onClick={() => setActiveStep(activeStep - 1)}
                    >
                      Previous
                    </div>
                  </>
                ) : (
                  <>
                    <button
                      className="btn btn--white"
                      type="button"
                      onClick={() => formCancelEvent()}
                    >
                      Cancel
                    </button>

                    {activeStep > 0 ? (
                      <button
                        className="btn btn--white"
                        type="button"
                        onClick={() => setActiveStep(activeStep - 1)}
                      >
                        Previous
                      </button>
                    ) : null}

                    <button
                      className="btn btn--dark-purple"
                      type="button"
                      onClick={() => setActiveStep(activeStep + 1)}
                      disabled={!canNext}
                    >
                      {canNext ? 'Next' : 'Select at least one Category'}
                    </button>
                  </>
                )}

                {formSteps.length === activeStep + 1 ? (
                  <>
                    {!initialValuesAltered && isEditWorkflow ? (
                      <button
                        className="btn btn--dark-purple"
                        type="button"
                        onClick={() => formCancelEvent()}
                      >
                        Continue
                      </button>
                    ) : (
                      <button
                        className="btn btn--dark-purple"
                        type="submit"
                        disabled={submitButtonDisabled}
                      >
                        {submitButtonDisabled
                          ? 'Please ensure all field values are valid'
                          : submitLabel}
                      </button>
                    )}
                  </>
                ) : null}
              </div>
            </Form>
          </>
        )
      }}
    </Formik>
  )
}

ManageWorkflowForm.propTypes = {
  formCancelEvent: PropTypes.func,
}

export default ManageWorkflowForm
