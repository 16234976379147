import React from 'react'
import PropTypes from 'prop-types'

function RoadMapIcon({ colour }, props) {
  return (
    <svg
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M24.32 14.96H15.68C15.1071 14.96 14.5577 15.1876 14.1526 15.5926C13.7476 15.9977 13.52 16.5471 13.52 17.12V19.28H9.2C7.29044 19.28 5.45909 20.0386 4.10883 21.3888C2.75857 22.7391 2 24.5704 2 26.48C2 30.1376 4.7864 33.68 9.6248 33.68L9.0488 34.2488C8.94726 34.3495 8.87801 34.4782 8.84988 34.6184C8.82175 34.7586 8.83601 34.904 8.89085 35.0361C8.94569 35.1682 9.03862 35.2809 9.1578 35.36C9.27697 35.439 9.41699 35.4808 9.56 35.48C9.9632 35.48 9.9488 35.3864 11.8712 33.4712C11.9711 33.3699 12.0388 33.2414 12.0657 33.1017C12.0926 32.962 12.0775 32.8175 12.0224 32.6864C11.9504 32.4992 12.0224 32.6 10.0712 30.6488C10.0041 30.5817 9.92437 30.5284 9.83666 30.4921C9.74895 30.4557 9.65494 30.437 9.56 30.437C9.46506 30.437 9.37105 30.4557 9.28334 30.4921C9.19563 30.5284 9.11593 30.5817 9.0488 30.6488C8.98167 30.7159 8.92842 30.7956 8.89208 30.8833C8.85575 30.971 8.83705 31.0651 8.83705 31.16C8.83705 31.2549 8.85575 31.3489 8.89208 31.4367C8.92842 31.5244 8.98167 31.6041 9.0488 31.6712L9.6248 32.24C5.6648 32.24 3.44 29.432 3.44 26.48C3.44 24.9523 4.04685 23.4873 5.12706 22.4071C6.20727 21.3268 7.67235 20.72 9.2 20.72H13.52V22.88C13.52 23.4529 13.7476 24.0023 14.1526 24.4073C14.5577 24.8124 15.1071 25.04 15.68 25.04H24.32C24.8929 25.04 25.4423 24.8124 25.8473 24.4073C26.2524 24.0023 26.48 23.4529 26.48 22.88V17.12C26.48 16.5471 26.2524 15.9977 25.8473 15.5926C25.4423 15.1876 24.8929 14.96 24.32 14.96ZM25.04 22.88C25.04 23.0709 24.9641 23.2541 24.8291 23.3891C24.6941 23.5241 24.511 23.6 24.32 23.6H15.68C15.489 23.6 15.3059 23.5241 15.1709 23.3891C15.0359 23.2541 14.96 23.0709 14.96 22.88V17.12C14.96 16.929 15.0359 16.7459 15.1709 16.6109C15.3059 16.4758 15.489 16.4 15.68 16.4H24.32C24.511 16.4 24.6941 16.4758 24.8291 16.6109C24.9641 16.7459 25.04 16.929 25.04 17.12V22.88Z"
        fill={colour}
      />
      <path
        d="M24.32 27.92H15.68C15.1072 27.92 14.5577 28.1476 14.1527 28.5527C13.7476 28.9577 13.52 29.5071 13.52 30.08V35.84C13.52 36.4129 13.7476 36.9623 14.1527 37.3674C14.5577 37.7724 15.1072 38 15.68 38H24.32C24.8929 38 25.4423 37.7724 25.8474 37.3674C26.2524 36.9623 26.48 36.4129 26.48 35.84V30.08C26.48 29.5071 26.2524 28.9577 25.8474 28.5527C25.4423 28.1476 24.8929 27.92 24.32 27.92ZM25.04 35.84C25.04 36.031 24.9642 36.2141 24.8291 36.3491C24.6941 36.4842 24.511 36.56 24.32 36.56H15.68C15.4891 36.56 15.3059 36.4842 15.1709 36.3491C15.0359 36.2141 14.96 36.031 14.96 35.84V30.08C14.96 29.8891 15.0359 29.7059 15.1709 29.5709C15.3059 29.4359 15.4891 29.36 15.68 29.36H24.32C24.511 29.36 24.6941 29.4359 24.8291 29.5709C24.9642 29.7059 25.04 29.8891 25.04 30.08V35.84Z"
        fill={colour}
      />
      <path
        d="M22.88 4.88H20C19.8091 4.88 19.6259 4.95586 19.4909 5.09089C19.3559 5.22591 19.28 5.40905 19.28 5.6C19.28 5.79096 19.3559 5.9741 19.4909 6.10912C19.6259 6.24415 19.8091 6.32 20 6.32H22.88C23.071 6.32 23.2541 6.24415 23.3891 6.10912C23.5242 5.9741 23.6 5.79096 23.6 5.6C23.6 5.40905 23.5242 5.22591 23.3891 5.09089C23.2541 4.95586 23.071 4.88 22.88 4.88Z"
        fill={colour}
      />
      <path
        d="M17.12 6.32C17.311 6.32 17.4941 6.24415 17.6291 6.10912C17.7642 5.9741 17.84 5.79096 17.84 5.6C17.84 5.40905 17.7642 5.22591 17.6291 5.09089C17.4941 4.95586 17.311 4.88 17.12 4.88C16.9291 4.88 16.7459 4.95586 16.6109 5.09089C16.4759 5.22591 16.4 5.40905 16.4 5.6C16.4 5.79096 16.4759 5.9741 16.6109 6.10912C16.7459 6.24415 16.9291 6.32 17.12 6.32Z"
        fill={colour}
      />
      <path
        d="M22.88 17.84H20C19.8091 17.84 19.6259 17.9159 19.4909 18.0509C19.3559 18.1859 19.28 18.369 19.28 18.56C19.28 18.751 19.3559 18.9341 19.4909 19.0691C19.6259 19.2041 19.8091 19.28 20 19.28H22.88C23.071 19.28 23.2541 19.2041 23.3891 19.0691C23.5242 18.9341 23.6 18.751 23.6 18.56C23.6 18.369 23.5242 18.1859 23.3891 18.0509C23.2541 17.9159 23.071 17.84 22.88 17.84Z"
        fill={colour}
      />
      <path
        d="M17.12 19.28C17.311 19.28 17.4941 19.2041 17.6291 19.0691C17.7642 18.9341 17.84 18.751 17.84 18.56C17.84 18.369 17.7642 18.1859 17.6291 18.0509C17.4941 17.9159 17.311 17.84 17.12 17.84C16.9291 17.84 16.7459 17.9159 16.6109 18.0509C16.4759 18.1859 16.4 18.369 16.4 18.56C16.4 18.751 16.4759 18.9341 16.6109 19.0691C16.7459 19.2041 16.9291 19.28 17.12 19.28Z"
        fill={colour}
      />
      <path
        d="M22.0088 30.8L19.28 33.5648L17.9912 32.24C17.924 32.1729 17.8443 32.1196 17.7566 32.0833C17.6689 32.047 17.5749 32.0283 17.48 32.0283C17.385 32.0283 17.291 32.047 17.2033 32.0833C17.1156 32.1196 17.0359 32.1729 16.9688 32.24C16.9016 32.3071 16.8484 32.3868 16.812 32.4745C16.7757 32.5623 16.757 32.6563 16.757 32.7512C16.757 32.8461 16.7757 32.9401 16.812 33.0279C16.8484 33.1156 16.9016 33.1953 16.9688 33.2624C18.9128 35.2064 18.884 35.2712 19.28 35.2712C19.676 35.2712 19.568 35.2712 23.0312 31.8224C23.0983 31.7553 23.1515 31.6756 23.1879 31.5879C23.2242 31.5001 23.2429 31.4061 23.2429 31.3112C23.2429 31.2163 23.2242 31.1223 23.1879 31.0345C23.1515 30.9468 23.0983 30.8671 23.0312 30.8C22.964 30.7329 22.8843 30.6796 22.7966 30.6433C22.7089 30.607 22.6149 30.5883 22.52 30.5883C22.425 30.5883 22.331 30.607 22.2433 30.6433C22.1556 30.6796 22.0759 30.7329 22.0088 30.8Z"
        fill={colour}
      />
      <path
        d="M38 13.52C38 11.6104 37.2414 9.77909 35.8912 8.42883C34.5409 7.07857 32.7096 6.32 30.8 6.32H26.48V4.16C26.48 3.58713 26.2524 3.03773 25.8474 2.63265C25.4423 2.22757 24.8929 2 24.32 2H15.68C15.1072 2 14.5577 2.22757 14.1527 2.63265C13.7476 3.03773 13.52 3.58713 13.52 4.16V9.92C13.52 10.4929 13.7476 11.0423 14.1527 11.4474C14.5577 11.8524 15.1072 12.08 15.68 12.08H24.32C24.8929 12.08 25.4423 11.8524 25.8474 11.4474C26.2524 11.0423 26.48 10.4929 26.48 9.92V7.76H30.8C32.3277 7.76 33.7927 8.36685 34.873 9.44706C35.9532 10.5273 36.56 11.9924 36.56 13.52C36.56 16.4 34.4 19.28 30.3752 19.28L30.9512 18.7112C31.0184 18.6441 31.0716 18.5644 31.1079 18.4767C31.1443 18.3889 31.163 18.2949 31.163 18.2C31.163 18.1051 31.1443 18.0111 31.1079 17.9233C31.0716 17.8356 31.0184 17.7559 30.9512 17.6888C30.8841 17.6217 30.8044 17.5684 30.7167 17.5321C30.629 17.4958 30.535 17.4771 30.44 17.4771C30.3451 17.4771 30.2511 17.4958 30.1634 17.5321C30.0756 17.5684 29.996 17.6217 29.9288 17.6888C27.9776 19.64 28.0496 19.5392 27.9776 19.7264C27.9225 19.8575 27.9074 20.002 27.9343 20.1417C27.9612 20.2814 28.0289 20.41 28.1288 20.5112C30.08 22.4552 30.08 22.52 30.44 22.52C30.583 22.5208 30.723 22.4791 30.8422 22.4C30.9614 22.3209 31.0543 22.2082 31.1092 22.0761C31.164 21.944 31.1783 21.7986 31.1501 21.6584C31.122 21.5182 31.0528 21.3895 30.9512 21.2888L30.3752 20.72C35.228 20.72 38 17.1704 38 13.52ZM25.04 9.92C25.04 10.111 24.9642 10.2941 24.8291 10.4291C24.6941 10.5641 24.511 10.64 24.32 10.64H15.68C15.4891 10.64 15.3059 10.5641 15.1709 10.4291C15.0359 10.2941 14.96 10.111 14.96 9.92V4.16C14.96 3.96904 15.0359 3.78591 15.1709 3.65088C15.3059 3.51586 15.4891 3.44 15.68 3.44H24.32C24.511 3.44 24.6941 3.51586 24.8291 3.65088C24.9642 3.78591 25.04 3.96904 25.04 4.16V9.92Z"
        fill={colour}
      />
      <path
        d="M2.72 7.76001H9.6248L9.0488 8.32881C8.94726 8.42951 8.87801 8.55818 8.84988 8.6984C8.82175 8.83861 8.83601 8.98403 8.89085 9.11611C8.94569 9.24819 9.03862 9.36095 9.1578 9.44C9.27697 9.51906 9.41699 9.56084 9.56 9.56001C9.9632 9.56001 9.9488 9.46641 11.8712 7.55121C11.9711 7.44996 12.0388 7.32138 12.0657 7.18171C12.0926 7.04204 12.0775 6.89752 12.0224 6.76641C11.9504 6.57921 12.0224 6.68001 10.0712 4.72881C10.0041 4.66167 9.92437 4.60842 9.83666 4.57209C9.74895 4.53576 9.65494 4.51706 9.56 4.51706C9.36826 4.51706 9.18438 4.59323 9.0488 4.72881C8.98167 4.79594 8.92842 4.87563 8.89209 4.96335C8.85575 5.05106 8.83705 5.14507 8.83705 5.24001C8.83705 5.43174 8.91322 5.61563 9.0488 5.75121L9.6248 6.32001H2.72C2.52904 6.32001 2.34591 6.39586 2.21088 6.53089C2.07586 6.66591 2 6.84905 2 7.04001C2 7.23096 2.07586 7.4141 2.21088 7.54912C2.34591 7.68415 2.52904 7.76001 2.72 7.76001Z"
        fill={colour}
      />
      <path
        d="M22.88 7.76001H17.12C16.9291 7.76001 16.7459 7.83587 16.6109 7.97089C16.4759 8.10592 16.4 8.28905 16.4 8.48001C16.4 8.67097 16.4759 8.8541 16.6109 8.98913C16.7459 9.12415 16.9291 9.20001 17.12 9.20001H22.88C23.071 9.20001 23.2541 9.12415 23.3891 8.98913C23.5242 8.8541 23.6 8.67097 23.6 8.48001C23.6 8.28905 23.5242 8.10592 23.3891 7.97089C23.2541 7.83587 23.071 7.76001 22.88 7.76001Z"
        fill={colour}
      />
      <path
        d="M22.88 20.72H17.12C16.9291 20.72 16.7459 20.7959 16.6109 20.9309C16.4759 21.0659 16.4 21.249 16.4 21.44C16.4 21.631 16.4759 21.8141 16.6109 21.9491C16.7459 22.0841 16.9291 22.16 17.12 22.16H22.88C23.071 22.16 23.2541 22.0841 23.3891 21.9491C23.5242 21.8141 23.6 21.631 23.6 21.44C23.6 21.249 23.5242 21.0659 23.3891 20.9309C23.2541 20.7959 23.071 20.72 22.88 20.72Z"
        fill={colour}
      />
    </svg>
  )
}

RoadMapIcon.defaultProps = {
  colour: '#000000',
}

RoadMapIcon.propTypes = {
  colour: PropTypes.string,
}

export default RoadMapIcon
