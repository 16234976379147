import PropTypes from 'prop-types'
import React from 'react'
import LinkWrapper from '../LinkWrapper'
import supportedIcons from '../../../../configuration/supportedIcons'
import '../../../../styles/consumer-flow/base-fields/icon-field.scss'

const IconField = ({ field }) => {
  const block = field?.blocks?.[0] || {}
  const { configurations, icon } = block
  const alignment = configurations?.alignment || 'left'
  const link = configurations?.link
  const { id, color } = icon || {}
  const iconConfig = id ? supportedIcons.find(option => option.id === id) : null
  const IconComponent = iconConfig?.component

  const alignments = {
    left: 'flex-start',
    center: 'center',
    right: 'flex-end',
  }

  const style = {
    justifyContent: alignments[alignment],
  }

  const iconStyle = {}
  if (icon?.width) {
    iconStyle.width = icon.width + 'px'
  }

  return (
    <div className="icon-field" style={style}>
      {link?.type ? (
        <LinkWrapper link={link}>
          {IconComponent && (
            <div style={iconStyle}>
              <IconComponent colour={color} />
            </div>
          )}
        </LinkWrapper>
      ) : (
        <>
          {IconComponent && (
            <div style={iconStyle}>
              <IconComponent colour={color} />
            </div>
          )}
        </>
      )}
    </div>
  )
}

IconField.propTypes = {
  field: PropTypes.object,
}

export default IconField
