import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import Modal from '../../../global-components/elements/Modal'
import MaterialLoader from '../../../global-components/elements/MaterialLoader'
import { ReactComponent as CloseModalIcon } from '../../../../assets/icons/close-icon.svg'
import {
  deleteStep,
  selectDeleteStepsState,
} from '../../../../redux/steps/deleteStep'
import useAuthToken from '../../../../hooks/useAuthToken'

function ManageWorkflowStepsDeleteModal({
  deletedStep,
  isDeleteModalOpen,
  managedWorkflow,
  setDeletedStep,
  toggleDeleteModal,
}) {
  const [deleteInputValue, setDeleteInputValue] = useState('')
  const { token } = useAuthToken({})
  const dispatch = useDispatch()
  const deletedStepsState = useSelector(selectDeleteStepsState)
  const deletedStepsLoading = get(deletedStepsState, 'loading', false)
  const stepId = get(deletedStep, '_id', null)
  const stepName = get(deletedStep, 'name', null)
  const submitDisabled = cleanName(deleteInputValue) !== cleanName(stepName)
  const workflowId = get(managedWorkflow, '_id', null)
  const workflowSlug = get(managedWorkflow, 'slug', null)

  const handleCloseDeleteModal = () => {
    toggleDeleteModal(!isDeleteModalOpen)

    setDeletedStep({})
  }

  const handleDeleteWorkflowStepById = async () => {
    await dispatch(
      deleteStep(
        stepId,
        workflowId,
        workflowSlug,
        handleCloseDeleteModal,
        token,
      ),
    )
  }

  function cleanName(name) {
    return name.replace(/[\u2018\u2019]/g, "'").replace(/[\u201C\u201D]/g, '"')
  }

  return (
    <Modal
      modalClassName="modal--delete-workflow modal--sm"
      isOpen={isDeleteModalOpen}
      onRequestClose={() => handleCloseDeleteModal()}
    >
      <div className="modal__header">
        <h3>Delete Step</h3>

        <button
          className="modal__close"
          onClick={() => toggleDeleteModal(!isDeleteModalOpen)}
        >
          <CloseModalIcon />
        </button>
      </div>

      <div className="modal__body">
        <p className="warning warning--red">
          Once this step is deleted, it will no longer be accessible or
          recoverable.
        </p>

        <div className="modal__body-group">
          <p>
            This action will delete the {stepName} step and all of it&apos;s
            related content. All versions of this workflow will no longer be
            available and this action cannot be undone.
          </p>

          <p>
            Please type <strong>{stepName}</strong> to confirm:
          </p>

          <input
            type="text"
            value={deleteInputValue}
            onChange={e => setDeleteInputValue(e.target.value)}
          />
        </div>
      </div>

      <div className="modal__submit">
        {deletedStepsLoading ? (
          <MaterialLoader containerClasses="inline-loader" />
        ) : (
          <>
            <button
              className="btn btn--white"
              onClick={() => handleCloseDeleteModal()}
            >
              Cancel
            </button>

            <button
              className="btn btn--red"
              disabled={submitDisabled}
              onClick={
                submitDisabled ? null : () => handleDeleteWorkflowStepById()
              }
            >
              I understand, delete this step
            </button>
          </>
        )}
      </div>
    </Modal>
  )
}

ManageWorkflowStepsDeleteModal.propTypes = {
  deletedStep: PropTypes.object,
  isDeleteModalOpen: PropTypes.bool,
  managedWorkflow: PropTypes.object.isRequired,
  setDeletedStep: PropTypes.func,
  toggleDeleteModal: PropTypes.func.isRequired,
}

export default ManageWorkflowStepsDeleteModal
