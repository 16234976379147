import React from 'react'
import PropTypes from 'prop-types'

function JewelIcon({ colour }, props) {
  return (
    <svg
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M13.1761 35.1997L19.28 37.8537C19.5069 37.9503 19.751 38 19.9976 38C20.2443 38 20.4883 37.9503 20.7153 37.8537L26.8221 35.1991C29.0742 34.2211 30.9914 32.6063 32.3379 30.5532C33.6845 28.5002 34.4018 26.0984 34.4015 23.6431V6.55336C34.4016 6.11401 34.241 5.68979 33.9499 5.36064C33.6589 5.03149 33.2576 4.82013 32.8215 4.76638C30.2683 4.46035 23.9772 3.58426 20.7009 2.14712C20.4802 2.0501 20.2417 2 20.0006 2C19.7595 2 19.5211 2.0501 19.3004 2.14712C16.0228 3.58426 9.73179 4.46035 7.17793 4.76638C6.74199 4.82027 6.34078 5.03169 6.04989 5.36082C5.75899 5.68996 5.59848 6.1141 5.59857 6.55336V23.6431C5.59804 26.0983 6.31496 28.5001 7.6612 30.5532C9.00744 32.6064 10.9243 34.2214 13.1761 35.1997ZM6.79869 6.55336C6.79926 6.40782 6.85271 6.26744 6.94908 6.15838C7.04545 6.04931 7.17818 5.97899 7.32255 5.9605C9.92861 5.64427 16.3589 4.74898 19.7834 3.24703C19.8522 3.21821 19.926 3.20337 20.0006 3.20337C20.0752 3.20337 20.1491 3.21821 20.2179 3.24703C23.6382 4.74718 30.0714 5.64367 32.6769 5.9593C32.8216 5.97768 32.9546 6.04812 33.0511 6.15745C33.1477 6.26678 33.2011 6.40752 33.2014 6.55336V23.6431C33.2019 25.8644 32.5534 28.0375 31.3354 29.8952C30.1174 31.7528 28.3831 33.214 26.3457 34.0992L20.2401 36.7544C20.1648 36.787 20.0838 36.8037 20.0018 36.8037C19.9199 36.8037 19.8388 36.787 19.7636 36.7544L13.6544 34.0992C11.617 33.214 9.8827 31.7528 8.6647 29.8952C7.4467 28.0375 6.79812 25.8644 6.79869 23.6431V6.55336Z"
        fill={colour}
      />
      <path
        d="M14.1339 32.9993L19.5218 35.3395C19.6732 35.4031 19.8358 35.4359 20.0001 35.4359C20.1643 35.4359 20.3269 35.4031 20.4783 35.3395L25.8662 32.9993C27.6892 32.2071 29.2409 30.8995 30.3309 29.2373C31.4208 27.5752 32.0014 25.6308 32.0013 23.6432V8.13639C32.0004 7.84668 31.8951 7.56699 31.7048 7.34857C31.5145 7.13015 31.2518 6.98761 30.965 6.94707C27.7972 6.51322 23.3952 5.75835 20.4153 4.62183C20.1477 4.51834 19.8512 4.51834 19.5836 4.62183C16.6079 5.75715 12.2029 6.51202 9.03454 6.94647C8.74759 6.98692 8.48486 7.12953 8.2946 7.34812C8.10434 7.56671 7.99933 7.8466 7.99884 8.13639V23.6432C7.99875 25.6308 8.57933 27.5752 9.66925 29.2373C10.7592 30.8995 12.3109 32.2071 14.1339 32.9993ZM9.19896 8.13699C12.4297 7.69414 16.9289 6.92066 19.9887 5.74394C23.0742 6.92066 27.5722 7.69414 30.8011 8.13639V23.6432C30.8013 25.397 30.289 27.1126 29.3274 28.5793C28.3657 30.046 26.9965 31.1997 25.388 31.8988L20.0001 34.2408L14.6121 31.9006C13.0034 31.2014 11.634 30.0474 10.6723 28.5803C9.71056 27.1133 9.19848 25.3973 9.19896 23.6432V8.13699Z"
        fill={colour}
      />
      <path
        d="M20 30.203C20.2077 30.2043 20.4121 30.1516 20.5933 30.0503C20.7745 29.9489 20.9264 29.8022 21.0339 29.6245L29.4264 15.8502C29.5525 15.6456 29.6133 15.4074 29.6008 15.1674C29.5882 14.9274 29.5029 14.6969 29.3562 14.5066L26.1344 10.2762C26.0208 10.1286 25.8749 10.009 25.7078 9.92657C25.5408 9.84418 25.357 9.8012 25.1707 9.80095H14.8293C14.6429 9.80123 14.459 9.84427 14.2918 9.92677C14.1247 10.0093 13.9786 10.129 13.865 10.2768L10.6439 14.5066C10.4971 14.6969 10.4118 14.9274 10.3993 15.1674C10.3867 15.4074 10.4476 15.6456 10.5737 15.8502L18.9655 29.6234C19.073 29.8013 19.2248 29.9483 19.4062 30.0499C19.5875 30.1515 19.7922 30.2043 20 30.203ZM17.1713 15.8016H22.8287L20 26.9477L17.1713 15.8016ZM24.0672 15.8016H28.0504L21.2349 26.9627L24.0672 15.8016ZM20 11.4013L22.4003 14.6014H17.5998L20 11.4013ZM16.5797 13.9636L15.472 11.0011H18.7999L16.5797 13.9636ZM21.2001 11.0011H24.5257L23.4204 13.9636L21.2001 11.0011ZM18.7759 27.0071L11.9448 15.8016H15.9328L18.7759 27.0071ZM27.9208 14.6014H24.4645L25.601 11.5567L27.9208 14.6014ZM14.4015 11.5621L15.5356 14.6014H12.0792L14.4015 11.5621Z"
        fill={colour}
      />
    </svg>
  )
}

JewelIcon.defaultProps = {
  colour: '#000000',
}

JewelIcon.propTypes = {
  colour: PropTypes.string,
}

export default JewelIcon
