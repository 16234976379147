import 'react-app-polyfill/ie11' //  eslint-disable-line
import 'react-app-polyfill/stable' // eslint-disable-line
import 'core-js' //  eslint-disable-line
import 'airbnb-browser-shims' //  eslint-disable-line
import React from 'react'
import mixpanel from 'mixpanel-browser'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { Auth0Provider } from '@auth0/auth0-react'
import { mixpanelTrackSignIn } from './utilities/analytics/mixpanelEvents'
import { createBrowserHistory } from 'history'
import configureStore from './redux/store'
import AuthProtectedRoute from './hooks/AuthProtectedRoute'
import App from './containers/configuration/App'

import './styles/base/base.scss'
import './styles/base/buttons.scss'
import './styles/base/forms.scss'
import './styles/base/layout.scss'
import './styles/base/modal.scss'
import './styles/base/print.scss'
import './styles/base/svg.scss'
import './styles/base/table.scss'
import './styles/base/typography.scss'
import '@quickfacts-inc/qf-brand-navigation/build/index.css'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

const store = configureStore()
const container = document.getElementById('workflows')
const root = createRoot(container)

mixpanel.init(process.env.REACT_APP_MIXPANEL_API_KEY, {
  api_host: process.env.REACT_APP_MIXPANEL_PROXY_DOMAIN,
})

export const history = createBrowserHistory()
const onRedirectCallback = async appState => {
  mixpanelTrackSignIn()
  history.replace({
    pathname: appState?.returnTo || window.location.pathname,
    search: '',
  })
}

const renderApp = () =>
  root.render(
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH0_DOMAIN}
      clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
      authorizationParams={{
        redirect_uri: window.location.origin,
      }}
      onRedirectCallback={() => onRedirectCallback()}
    >
      <Provider store={store}>
        <BrowserRouter>
          <AuthProtectedRoute component={App} />
        </BrowserRouter>
      </Provider>
    </Auth0Provider>,
  )

if (process.env.NODE_ENV !== 'production' && module.hot) {
  module.hot.accept('./containers/configuration/App', renderApp)
}

renderApp()
