export default function convertStringToSlug(string) {
  return string
    ?.toString()
    ?.trim()
    ?.toLowerCase()
    ?.replace(/\s+/g, '-')
    ?.replace(/[^\w\-]+/g, '')
    ?.replace(/\-\-+/g, '-')
    ?.replace(/^-+/, '')
    ?.replace(/-+$/, '')
}
