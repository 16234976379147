import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'
import { useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectManageStepModalVisibility,
  setManagedContentId,
  toggleManageStepModal,
} from '../../../../redux/user/userSelections'
import getWorkflowsDataById from '../../../../utilities/workflows/getWorkflowsDataById'
import convertStringToSlug from '../../../../utilities/slug/convertStringToSlug'
import Modal from '../../../global-components/elements/Modal'
import routes from '../../../../configuration/routes'
import { ReactComponent as PreviousIcon } from '../../../../assets/icons/double-chevron-left.svg'
import { ReactComponent as NextIcon } from '../../../../assets/icons/double-chevron-right.svg'
import { ReactComponent as DashboardIcon } from '../../../../assets/icons/home-icon.svg'
import { ReactComponent as EditIcon } from '../../../../assets/icons/pencil-icon.svg'
import { ReactComponent as GearIcon } from '../../../../assets/icons/gear-icon.svg'
import { ReactComponent as PreviewIcon } from '../../../../assets/icons/preview-icon.svg'
import { ReactComponent as CloseModalIcon } from '../../../../assets/icons/close-icon.svg'
import { emitSocketMessage } from '../../../../redux/middleware/socket/socket'

function ManageWorkflowStepNavigation({
  editWorkflowEvent,
  managedWorkflow,
  workflowSteps,
  onNavigate,
  unsavedChanges,
}) {
  const dispatch = useDispatch()
  const isManageStepModalVisible = useSelector(selectManageStepModalVisibility)
  const { workflow, step } = useParams()
  const navigate = useNavigate()
  const [navigatePath, setNavigatePath] = useState('')
  const activeStepIndex = workflowSteps.findIndex(
    activeStep => activeStep?.slug === step,
  )
  const basePath = `${routes.manageWorkflows}/${workflow}`
  const previousStepContent = workflowSteps[activeStepIndex - 1]
  const previousStepSlug = get(previousStepContent, 'slug', null)
  const nextStepContent = workflowSteps[activeStepIndex + 1]
  const nextStepSlug = get(nextStepContent, 'slug', null)
  const workflowStep = get(workflowSteps, activeStepIndex, null)
  const stepId = get(workflowStep, '_id', null)
  const stepSlug = get(workflowStep, 'slug', null)
  const stepTitle = get(workflowStep, 'name', null)
  const workflowId =
    get(managedWorkflow, '_id', null) ||
    get(managedWorkflow, 'workflowId', null)
  const workflowName = get(managedWorkflow, 'name', null)
  const workflowData = getWorkflowsDataById(workflowId)
  const workflowBaseCategory = get(workflowData, 'categories[0].category', null)
  const previewPath = workflowBaseCategory
    ? `${routes.browseWorkflows}/${convertStringToSlug(
        workflowBaseCategory,
      )}/${workflow}`
    : null
  const [currentState, setCurrentState] = useState({
    numberOfSteps: workflowSteps.length,
    nextStepSlug,
  })

  /**
   * Detects if a new step has been created if we're at the end of the step list
   */
  useEffect(() => {
    if (
      currentState.numberOfSteps !== workflowSteps.length &&
      currentState.nextStepSlug === null
    ) {
      handleNavigation(`${basePath}/${nextStepSlug}`)
    }
    setCurrentState({
      numberOfSteps: workflowSteps.length,
      nextStepSlug,
    })
  }, [workflowSteps.length, nextStepSlug])

  function handleNavigation(path) {
    if (unsavedChanges) {
      return setNavigatePath(path)
    }
    navigateTo(path)
  }

  function navigateTo(path) {
    if (onNavigate) {
      onNavigate(path)
    }
    navigate(path)
    setNavigatePath('')
  }

  function handleToggleEditStep() {
    if (!unsavedChanges) {
      dispatch(toggleManageStepModal(!isManageStepModalVisible))
      dispatch(setManagedContentId(stepId))
    }
  }

  return (
    <div className="manage-workflow__step-navigation">
      {activeStepIndex >= 0 && workflowSteps.length > 0 ? (
        <>
          <div className="flex">
            <button
              onClick={() => handleNavigation(`${basePath}`)}
              className="btn btn--white dashboard"
            >
              <EditIcon />
              Return to Edit Workflow
            </button>

            {previousStepSlug ? (
              <button
                disabled={!previousStepSlug}
                onClick={() =>
                  handleNavigation(`${basePath}/${previousStepSlug}`)
                }
                className="btn btn--white previous"
              >
                <PreviousIcon />
                Prev
              </button>
            ) : null}
          </div>

          <button
            className="manage-workflow__step-navigation-title"
            onClick={() => handleToggleEditStep()}
            {...(unsavedChanges
              ? {
                  'data-tooltip':
                    'Finish editing your component before editing the step configuration',
                }
              : {})}
          >
            {stepTitle}
            <GearIcon />
          </button>

          <div className="flex">
            {!nextStepSlug ? (
              <button
                onClick={() =>
                  dispatch(toggleManageStepModal(!isManageStepModalVisible))
                }
                className="btn btn--white next"
              >
                Create Step
                <NextIcon />
              </button>
            ) : (
              <button
                disabled={!nextStepSlug}
                onClick={() => handleNavigation(`${basePath}/${nextStepSlug}`)}
                className="btn btn--white next"
              >
                Next
                <NextIcon />
              </button>
            )}

            <button
              onClick={() => handleNavigation(`${previewPath}/${stepSlug}`)}
              className="btn btn--white"
            >
              <PreviewIcon />
              Preview Step
            </button>
          </div>
        </>
      ) : (
        <>
          <button
            onClick={() => handleNavigation(routes.viewMyWorkflows)}
            className="btn btn--white home"
          >
            <DashboardIcon />
            Manage Workflows
          </button>
          <button
            onClick={() => editWorkflowEvent()}
            className="manage-workflow__step-navigation-title inc-button"
          >
            <span className="label">{workflowName}</span>
            <GearIcon />
          </button>

          <button
            onClick={() => handleNavigation(previewPath)}
            className="btn btn--white home"
          >
            <PreviewIcon />
            Preview Workflow
          </button>
        </>
      )}

      <Modal
        modalClassName="modal--sm"
        isOpen={!!navigatePath}
        onRequestClose={() => setNavigatePath('')}
      >
        <div className="modal__header">
          <h3>Unsaved Changes</h3>
          <button className="modal__close" onClick={() => setNavigatePath('')}>
            <CloseModalIcon />
          </button>
        </div>
        <div className="modal__body">
          <p>
            Are you sure you want to leave this page?
            <br />
            Changes you made will not be saved.
          </p>
        </div>

        <div className="modal__submit">
          <button
            className="btn btn--white"
            onClick={() => setNavigatePath('')}
          >
            Cancel
          </button>

          <button
            className="btn btn--red"
            onClick={() => navigateTo(navigatePath)}
          >
            Leave Page
          </button>
        </div>
      </Modal>
    </div>
  )

  /*

   */
}

ManageWorkflowStepNavigation.propTypes = {
  editWorkflowEvent: PropTypes.func,
  managedWorkflow: PropTypes.object,
  workflowSteps: PropTypes.array,
  onNavigate: PropTypes.func,
  unsavedChanges: PropTypes.bool,
}

export default ManageWorkflowStepNavigation
