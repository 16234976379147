import React from 'react'
import PropTypes from 'prop-types'

function OfficeBuildingIcon({ colour }, props) {
  return (
    <svg
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M36.2422 33.2758H35.7959V24.5676C35.7959 24.2763 35.5599 24.0402 35.2686 24.0402C34.9773 24.0402 34.7412 24.2763 34.7412 24.5676V33.2759H23.5542V18.9406H34.3103C34.5479 18.9406 34.7413 19.134 34.7413 19.3715V22.0831C34.7413 22.3744 34.9773 22.6105 35.2686 22.6105C35.5599 22.6105 35.796 22.3744 35.796 22.0831V19.3715C35.796 18.5523 35.1295 17.8859 34.3103 17.8859H23.5542V8.30273C23.5542 7.53541 22.9572 6.88734 22.1935 6.82272V5.76487C22.1935 4.99453 21.5919 4.34393 20.8239 4.28374L7.16642 3.2133C6.30404 3.14517 5.5647 3.8291 5.5647 4.69443V5.53896C4.78262 5.60576 4.20409 6.25994 4.20409 7.01904V33.2758H3.75781C2.78855 33.2758 2 34.0643 2 35.0336C2 36.0028 2.78855 36.7914 3.75781 36.7914H36.2422C37.2114 36.7914 38 36.0028 38 35.0336C38 34.0643 37.2114 33.2758 36.2422 33.2758ZM6.61939 4.69451C6.61939 4.44194 6.83434 4.24507 7.08409 4.26483L20.7416 5.33519C20.9643 5.3527 21.1389 5.54142 21.1389 5.76487V6.73961L6.61932 5.60168L6.61939 4.69451ZM5.25877 7.01911C5.25877 6.76451 5.47653 6.56918 5.7234 6.58943L22.1021 7.87305C22.325 7.89056 22.4995 8.07928 22.4995 8.30273V33.2758H21.0259V25.7257C21.0259 25.2457 20.6355 24.8553 20.1555 24.8553H15.2562C14.7762 24.8553 14.3858 25.2457 14.3858 25.7257V29.1466C14.3858 29.4378 14.6219 29.6739 14.9131 29.6739C15.2043 29.6739 15.4404 29.4378 15.4404 29.1466V25.91H19.9712V33.2758H15.4404V31.612C15.4404 31.3207 15.2043 31.0846 14.9131 31.0846C14.6219 31.0846 14.3858 31.3207 14.3858 31.612V33.2758H5.25877V7.01911ZM36.2422 35.7368H3.75781C3.37011 35.7368 3.05469 35.4213 3.05469 35.0336C3.05469 34.6459 3.37011 34.3305 3.75781 34.3305H36.2422C36.6299 34.3305 36.9453 34.6459 36.9453 35.0336C36.9453 35.4213 36.6299 35.7368 36.2422 35.7368Z"
        fill={colour}
      />
      <path
        d="M7.33711 13.457H9.47166C9.97348 13.457 10.3817 13.0488 10.3817 12.547V10.9047C10.3817 10.403 9.97348 9.99474 9.47166 9.99474H7.33711C6.83536 9.99474 6.42712 10.403 6.42712 10.9047V12.547C6.42712 13.0488 6.83536 13.457 7.33711 13.457ZM7.48181 11.0494H9.32709V12.4023H7.48181V11.0494Z"
        fill={colour}
      />
      <path
        d="M12.6593 13.457H14.7938C15.2955 13.457 15.7038 13.0488 15.7038 12.547V10.9047C15.7038 10.403 15.2955 9.99474 14.7938 9.99474H12.6593C12.1575 9.99474 11.7493 10.403 11.7493 10.9047V12.547C11.7492 13.0488 12.1574 13.457 12.6593 13.457ZM12.8039 11.0494H14.6491V12.4023H12.8039V11.0494Z"
        fill={colour}
      />
      <path
        d="M17.9815 13.457H20.1161C20.6179 13.457 21.026 13.0488 21.026 12.547V10.9047C21.026 10.403 20.6178 9.99474 20.1161 9.99474H17.9815C17.4798 9.99474 17.0715 10.403 17.0715 10.9047V12.547C17.0715 13.0488 17.4797 13.457 17.9815 13.457ZM18.1261 11.0494H19.9714V12.4023H18.1261V11.0494Z"
        fill={colour}
      />
      <path
        d="M7.33711 18.1986H9.47166C9.97348 18.1986 10.3817 17.7904 10.3817 17.2886V15.6463C10.3817 15.1445 9.97348 14.7363 9.47166 14.7363H7.33711C6.83536 14.7363 6.42712 15.1445 6.42712 15.6463V17.2886C6.42712 17.7904 6.83536 18.1986 7.33711 18.1986ZM7.48181 15.791H9.32709V17.1439H7.48181V15.791Z"
        fill={colour}
      />
      <path
        d="M12.6593 18.1986H14.7938C15.2955 18.1986 15.7038 17.7904 15.7038 17.2886V15.6463C15.7038 15.1445 15.2955 14.7363 14.7938 14.7363H12.6593C12.1575 14.7363 11.7493 15.1445 11.7493 15.6463V17.2886C11.7492 17.7904 12.1574 18.1986 12.6593 18.1986ZM12.8039 15.791H14.6491V17.1439H12.8039V15.791Z"
        fill={colour}
      />
      <path
        d="M17.9815 18.1986H20.1161C20.6179 18.1986 21.026 17.7904 21.026 17.2886V15.6463C21.026 15.1445 20.6178 14.7363 20.1161 14.7363H17.9815C17.4798 14.7363 17.0715 15.1445 17.0715 15.6463V17.2886C17.0715 17.7904 17.4797 18.1986 17.9815 18.1986ZM18.1261 15.791H19.9714V17.1439H18.1261V15.791Z"
        fill={colour}
      />
      <path
        d="M7.33711 22.9403H9.47166C9.97348 22.9403 10.3817 22.532 10.3817 22.0303V20.388C10.3817 19.8862 9.97348 19.478 9.47166 19.478H7.33711C6.83536 19.478 6.42712 19.8862 6.42712 20.388V22.0303C6.42712 22.532 6.83536 22.9403 7.33711 22.9403ZM7.48181 20.5326H9.32709V21.8856H7.48181V20.5326Z"
        fill={colour}
      />
      <path
        d="M12.1638 24.8553H7.43962C6.88134 24.8553 6.42712 25.3095 6.42712 25.8678V27.3051C6.42712 27.8634 6.88134 28.3176 7.43962 28.3176H12.1638C12.7221 28.3176 13.1763 27.8634 13.1763 27.3051V25.8678C13.1763 25.3095 12.7221 24.8553 12.1638 24.8553ZM12.1216 27.2629H7.48181V25.9099H12.1216V27.2629Z"
        fill={colour}
      />
      <path
        d="M12.6593 22.9403H14.7938C15.2955 22.9403 15.7038 22.532 15.7038 22.0303V20.388C15.7038 19.8862 15.2955 19.478 14.7938 19.478H12.6593C12.1575 19.478 11.7493 19.8862 11.7493 20.388V22.0303C11.7492 22.532 12.1574 22.9403 12.6593 22.9403ZM12.8039 20.5326H14.6491V21.8856H12.8039V20.5326Z"
        fill={colour}
      />
      <path
        d="M17.9815 22.9403H20.1161C20.6179 22.9403 21.026 22.532 21.026 22.0303V20.388C21.026 19.8862 20.6178 19.478 20.1161 19.478H17.9815C17.4798 19.478 17.0715 19.8862 17.0715 20.388V22.0303C17.0715 22.532 17.4797 22.9403 17.9815 22.9403ZM18.1261 20.5326H19.9714V21.8856H18.1261V20.5326Z"
        fill={colour}
      />
      <path
        d="M28.759 22.4819C28.759 21.947 28.3239 21.5118 27.7889 21.5118H25.6354C25.1005 21.5118 24.6653 21.9469 24.6653 22.4819V24.19C24.6653 24.7249 25.1004 25.1601 25.6354 25.1601H27.7889C28.3238 25.1601 28.759 24.7249 28.759 24.19V22.4819ZM27.7043 24.1054H25.72V22.5665H27.7043V24.1054Z"
        fill={colour}
      />
      <path
        d="M27.7889 26.3786H25.6354C25.1005 26.3786 24.6653 26.8137 24.6653 27.3487V29.0568C24.6653 29.5916 25.1004 30.0269 25.6354 30.0269H27.7889C28.3238 30.0269 28.759 29.5917 28.759 29.0568V27.3487C28.759 26.8137 28.3239 26.3786 27.7889 26.3786ZM27.7043 28.9722H25.72V27.4333H27.7043V28.9722Z"
        fill={colour}
      />
      <path
        d="M33.6641 22.4819C33.6641 21.947 33.2289 21.5118 32.694 21.5118H30.5405C30.0057 21.5118 29.5704 21.9469 29.5704 22.4819V24.19C29.5704 24.7249 30.0056 25.1601 30.5405 25.1601H32.694C33.2289 25.1601 33.6641 24.7249 33.6641 24.19V22.4819ZM32.6094 24.1054H30.6251V22.5665H32.6094V24.1054Z"
        fill={colour}
      />
      <path
        d="M32.694 26.3786H30.5405C30.0057 26.3786 29.5704 26.8137 29.5704 27.3487V29.0568C29.5704 29.5916 30.0056 30.0269 30.5405 30.0269H32.694C33.2289 30.0269 33.6641 29.5917 33.6641 29.0568V27.3487C33.6641 26.8137 33.2289 26.3786 32.694 26.3786ZM32.6094 28.9722H30.6251V27.4333H32.6094V28.9722Z"
        fill={colour}
      />
    </svg>
  )
}

OfficeBuildingIcon.defaultProps = {
  colour: '#000000',
}

OfficeBuildingIcon.propTypes = {
  colour: PropTypes.string,
}

export default OfficeBuildingIcon
