import React, { useState } from 'react'
import MediaLibraryFolderTree from './MediaLibraryFolderTree'
import { ReactComponent as AddFolderIcon } from '../../../../assets/icons/add-folder-icon.svg'
import { ReactComponent as CloseModalIcon } from '../../../../assets/icons/close-icon.svg'
import MediaLibraryCreateFolderModal from './MediaLibraryCreateFolderModal'
import { useDispatch } from 'react-redux'
import useAuthToken from '../../../../hooks/useAuthToken'
import {
  createMediaLibraryFolder,
  deleteMediaLibraryFolder,
  editMediaLibraryFolder,
} from '../../../../redux/media-library/mediaLibraryTree'
import '../../../../styles/global-components/media-library/media-library-folders.scss'
import Modal from 'react-modal'
import PropTypes from 'prop-types'

const MediaLibraryFolders = ({ folders, value, onChange, isLoading }) => {
  const dispatch = useDispatch()
  const { token } = useAuthToken({})
  const [folderToEdit, setFolderToEdit] = useState(null)
  const [deleteError, setDeleteError] = useState(false)
  function openNewFolderModal() {
    setFolderToEdit({})
  }

  function handleCreateFolderSubmit(values) {
    if (values?._id) {
      dispatch(
        editMediaLibraryFolder(token, values._id, { name: values.name }),
      ).then(() => {
        setFolderToEdit(null)
      })
    } else {
      dispatch(createMediaLibraryFolder(token, values.name, value))
        .then(() => {
          setFolderToEdit(null)
        })
        .catch(err => {
          console.log(err)
        })
    }
  }

  function handleDelete(folder) {
    dispatch(deleteMediaLibraryFolder(token, folder._id))
      .then(() => {
        if (value === folder._id) {
          onChange(null)
        }
      })
      .catch(() => {
        setDeleteError(true)
      })
  }

  return (
    <div className="media-library-folders">
      <MediaLibraryFolderTree
        folders={folders}
        onChange={onChange}
        value={value}
        onEditClick={setFolderToEdit}
        onDeleteClick={handleDelete}
      />
      <div className="media-library-folders__new-button">
        <button className="btn btn--secondary" onClick={openNewFolderModal}>
          <AddFolderIcon width="20" stroke="#333" /> New Folder
        </button>
      </div>

      <MediaLibraryCreateFolderModal
        values={folderToEdit}
        isOpen={folderToEdit !== null}
        onCancel={() => setFolderToEdit(null)}
        onSubmit={handleCreateFolderSubmit}
        isLoading={isLoading}
      />

      <Modal
        isOpen={deleteError}
        onRequestClose={() => setDeleteError(false)}
        className="modal modal--sm modal--overflow-hidden"
        overlayClassName="modal-overlay"
      >
        <div className="modal__header">
          <h3 className="body-font">Unable to Delete Folder</h3>

          <button
            className="modal__close"
            onClick={() => setDeleteError(false)}
          >
            <CloseModalIcon />
          </button>
        </div>

        <div className="modal__body">
          <p>
            Unable to delete folders containing images or other folders. Please
            ensure that any files or folders have been removed from the folder
            you&apos;re attempting to delete.
          </p>
        </div>
        <div className="form__submit">
          <button
            className="btn btn--white"
            type="button"
            onClick={() => setDeleteError(false)}
          >
            Close
          </button>
        </div>
      </Modal>
    </div>
  )
}

MediaLibraryFolders.propTypes = {
  folders: PropTypes.shape({}),
  value: PropTypes.string,
  onChange: PropTypes.func,
  isLoading: PropTypes.bool,
}

export default MediaLibraryFolders
