import PropTypes from 'prop-types'
import { rectSwappingStrategy, SortableContext } from '@dnd-kit/sortable'
import { cloneDeep } from 'lodash'
import { EDITOR_CARDS } from '../../../../configuration/editor'
import EditorCardSubcomponent from './EditorCardSubcomponent'
import EditorLayoutSelector from '../../editor-core/EditorLayoutSelector'
import getId from '../../../../utilities/editor/getId'
import '../../../../styles/editor-flow/editor-components/editor-cards-component.scss'

function EditorCardsComponent({ component, onChange, dropPath }) {
  const { subComponents, configurations } = component
  const { layout: layoutConfig } = configurations
  const layoutOptions = [
    '100',
    '50-50',
    '70-30',
    '30-70',
    '33-33-33',
    '25-25-25-25',
  ]
  function handleAddCard() {
    onChange({
      ...component,
      subComponents: [...component.subComponents, { ...createEmptyCard() }],
    })
  }

  function handleSubcomponentChange(subcomponent) {
    const newComponent = cloneDeep(component)
    const subcomponentIndex = newComponent.subComponents.findIndex(
      subcomp => getId(subcomp) === getId(subcomponent),
    )
    newComponent.subComponents[subcomponentIndex] = subcomponent
    onChange(newComponent)
  }

  function handleDeleteClick(subcomponent) {
    onChange({
      ...component,
      subComponents: component.subComponents.filter(
        item => getId(item) !== getId(subcomponent),
      ),
    })
  }

  function handleLayoutChange(value) {
    const newData = cloneDeep(component)
    newData.configurations.layout = value
    onChange(newData)
  }

  return (
    <div className="editor-cards-component">
      <div className="cards-subcomponent-container">
        <SortableContext
          items={subComponents.map(item => getId(item)) || []}
          strategy={rectSwappingStrategy}
        >
          {subComponents?.map((subcomponent, i) => (
            <EditorCardSubcomponent
              subcomponent={subcomponent}
              index={i}
              key={getId(subcomponent)}
              onChange={handleSubcomponentChange}
              onDeleteClick={handleDeleteClick}
              dropPath={[...dropPath, 'subComponents']}
              layout={`layout-${layoutConfig}`}
            />
          ))}
        </SortableContext>
      </div>

      <EditorLayoutSelector
        label="Layout"
        value={layoutConfig}
        options={layoutOptions}
        onChange={handleLayoutChange}
      />

      <div className="cards-component-add-button">
        <button className="btn btn--white" onClick={handleAddCard}>
          Add Another Card
        </button>
      </div>
    </div>
  )
}

export const createEmptyCard = () => {
  const id = `temp-${Date.now()}-${Math.random()}`
  return {
    id,
    type: 'subComponent',
    subComponentType: 'card',
    fields: [],
  }
}

export const createCardComponent = id => {
  return {
    id,
    componentType: EDITOR_CARDS,
    configurations: {
      layout: '50-50',
    },
    subComponents: [createEmptyCard(), createEmptyCard()],
  }
}

EditorCardsComponent.propTypes = {
  component: PropTypes.object,
  onChange: PropTypes.func,
  dropPath: PropTypes.array,
}
export default EditorCardsComponent
