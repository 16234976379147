import { get, isEmpty } from 'lodash'

export default function constructInitialWorkflowFormState(
  activeClientId,
  operatingLocationOptions,
  userAccessOptions,
  workflow,
) {
  const name = get(workflow, 'name', null)
  const slug = get(workflow, 'slug', null)
  const status = get(workflow, 'status', null)
  const clients = get(workflow, 'clientId', null)
  const icon = get(workflow, 'icon.id', null)
  const iconColour = get(workflow, 'icon.colour', null)
  const description = get(workflow, 'content.description', null)
  const relatedWorkflows = get(workflow, 'relatedWorkflows', [])
  const categories = get(workflow, 'categories', [])
  const operatingLocations = get(workflow, 'operatingLocations', [])
  const userAccessPermissions = get(workflow, 'userAccessPermissions', [])
  const filteredClients =
    clients?.filter(clientId => clientId !== activeClientId) || []

  return {
    name: name ? name : '',
    slug: slug ? slug : '',
    status: status ? status : 'draft',
    icon: icon ? icon : '',
    iconColour: iconColour ? iconColour : '',
    description: description ? description : '',
    clientId: filteredClients,
    categories: !isEmpty(categories)
      ? categories
          .filter(category => category?.clientId?.includes(activeClientId))
          .map(category => {
            const categoryId = get(category, 'id', null)
            const subcategories = get(category, 'subcategories', [])

            return {
              category: categoryId,
              subcategories: subcategories,
            }
          })
      : [],
    operatingLocations: !isEmpty(operatingLocations)
      ? operatingLocations
      : !isEmpty(operatingLocationOptions)
      ? operatingLocationOptions.map(
          operatingLocation => operatingLocation?.value,
        )
      : [],
    userAccessPermissions: !isEmpty(userAccessPermissions)
      ? userAccessPermissions
      : !isEmpty(userAccessOptions)
      ? userAccessOptions.map(userAccessOption => userAccessOption?.value)
      : [],
    relatedWorkflows: !isEmpty(relatedWorkflows)
      ? relatedWorkflows.map(relatedworkflow => {
          const relatedWorkflowId = get(relatedworkflow, '_id', null)
          return relatedWorkflowId
        })
      : [],
  }
}
