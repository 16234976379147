import algoliasearch from 'algoliasearch'
import { get } from 'lodash'
export const CHECK_CLIENT_INDEX_BEGIN = 'CHECK_CLIENT_INDEX_BEGIN'
export const CHECK_CLIENT_INDEX_ERROR = 'CHECK_CLIENT_INDEX_ERROR'
export const CHECK_CLIENT_INDEX_SUCCESS = 'CHECK_CLIENT_INDEX_SUCCESS'

const searchClient = algoliasearch(
  process.env.REACT_APP_ALGOLIA_APP_ID,
  process.env.REACT_APP_ALGOLIA_API_KEY,
)
export const checkClientIndexes =
  (activeClientId, activeOperatingLocation) => async dispatch => {
    const baseAlgoliaIndexKey = `${activeClientId}_${activeOperatingLocation.toLowerCase()}`
    const comparisonsIndex = searchClient.initIndex(
      `${baseAlgoliaIndexKey}_comparisons`,
    )
    const pagesIndex = searchClient.initIndex(`${baseAlgoliaIndexKey}_pages`)
    const workflowsIndex = searchClient.initIndex(
      `${baseAlgoliaIndexKey}_workflows`,
    )

    dispatch({ type: CHECK_CLIENT_INDEX_BEGIN })
    return comparisonsIndex.exists().then(async comparisonsIndexExists => {
      const pagesIndexExists = await pagesIndex.exists()
      const workflowsIndexExists = await workflowsIndex.exists()

      dispatch({
        type: CHECK_CLIENT_INDEX_SUCCESS,
        payload: {
          comparisonsIndexExists,
          pagesIndexExists,
          workflowsIndexExists,
        },
      })
    })
  }

// REDUCER
const initialState = {
  error: null,
  loading: false,
  indexes: null,
}

export default function checkClientIndexesReducer(
  state = initialState,
  action,
) {
  switch (action.type) {
    case CHECK_CLIENT_INDEX_BEGIN:
      return { ...state, loading: true }
    case CHECK_CLIENT_INDEX_SUCCESS:
      return { ...state, loading: false, indexes: action.payload }
    case CHECK_CLIENT_INDEX_ERROR:
      return { ...state, loading: false, error: action.payload, indexes: null }
    default:
      return state
  }
}

export const selectCheckClientIndexes = state =>
  get(state, 'searchIndexData.indexes', [])
export const selectCheckClientIndexLoading = state =>
  state.searchIndexData.loading
