import React from 'react'
import ViewMyWorkflows from '../../consumer-flow/view-my-workflows/ViewMyWorkflows'

function ManageWorkflows() {
  return (
    <div className="manage-workflows">
      <div className="container">
        <ViewMyWorkflows />
      </div>
    </div>
  )
}

export default ManageWorkflows
