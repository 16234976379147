import React, { useState } from 'react'
import { ReactComponent as LinkExternalIcon } from '../../../assets/icons/link-external-icon.svg'
import PropTypes from 'prop-types'
import FindReplacePreview from './FindReplacePreview'
import { startCase } from 'lodash'

const FindReplaceBreadcrumb = ({ item, entry }) => {
  const { breadcrumb } = item
  const { workflow, step, component } = breadcrumb || {}
  const [previewCoords, setPreviewCoords] = useState({
    bottom: '-1000px',
    left: '-1000px',
  })
  const path = [
    workflow?.category,
    workflow?.name,
    step?.name,
    startCase(component?.type),
  ].filter(item => !!item)

  const slug = `${item?.breadcrumb?.slug}?entry=${getFindQueryParam()}`

  function handleMouseMove(evt) {
    const containerElement = evt.target?.closest(
      '.find-replace__preview-container',
    )
    const buttonElement = containerElement?.querySelector('.btn.btn--white')
    const previewElement = containerElement?.querySelector(
      '.find-replace__breadcrumb-preview',
    )
    const buttonRect = buttonElement?.getBoundingClientRect() || {
      right: '-1000px',
      top: '-1000px',
    }
    const previewHeight = previewElement?.clientHeight || 0

    setPreviewCoords({
      top: `${Math.max(buttonRect?.top - previewHeight, 100)}px`,
      left: `${buttonRect?.right}px`,
    })
  }

  function getFindQueryParam() {
    const obj = {
      entryType: entry.entryType,
      find: entry.find?.source,
      matchCase: !entry.find?.flags?.includes('i'),
      highlightIndex: entry.highlightIndex,
      itemId: entry.itemId,
      link: entry.link,
      match: entry.match,
      type: entry.type,
      path: entry.path,
      cellIndex: entry.cellIndex,
      rowIndex: entry.rowIndex,
      headerIndex: entry.headerIndex,
      subType: entry.subType,
      subItemId: entry.subItemId,
    }
    return encodeURI(JSON.stringify(obj))
  }

  return (
    <div className="find-replace__breadcrumb-cell">
      <div
        className="find-replace__preview-container"
        onMouseMove={handleMouseMove}
      >
        <a
          className="btn btn--white"
          href={slug}
          target="_blank"
          rel="noreferrer"
        >
          <LinkExternalIcon />
        </a>
        <div
          className="find-replace__breadcrumb-preview"
          style={{ ...previewCoords }}
        >
          <FindReplacePreview item={item} entry={entry} />{' '}
        </div>
      </div>
      <div className="find-replace__breadcrumb">
        <div className="find-replace__breadcrumb-header">{workflow?.name}</div>
        <div className="find-replace__breadcrumb-path">{path.join(' / ')}</div>
      </div>
    </div>
  )
}

FindReplaceBreadcrumb.propTypes = {
  item: PropTypes.object,
  entry: PropTypes.object,
}

export default FindReplaceBreadcrumb
