import mixpanel from 'mixpanel-browser'
import { get, isEmpty } from 'lodash'
import { setActiveClient, setActiveOperatingLocation } from './userSelections'
import { fetchUser } from '@quickfacts-inc/qf-consumer-sdk'
import { fetchWorkflows } from '../workflows/workflows'
import { fetchCategories } from '../categories/categories'
import {
  mixpanelTrackClient,
  mixpanelTrackOperatingLocation,
} from '../../utilities/analytics/mixpanelEvents'
import { checkClientIndexes } from '../global-search/checkClientIndexes'

export const FETCH_USER_BEGIN = 'FETCH_USER_BEGIN'
export const FETCH_USER_ERROR = 'FETCH_USER_ERROR'
export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS'
export const SET_USER_IS_ADMIN = 'SET_USER_IS_ADMIN'

export const fetchUserProfile = token => async dispatch => {
  const queryString = new URLSearchParams(window.location.search)
  dispatch({ type: FETCH_USER_BEGIN })

  return fetchUser(token)
    .then(response => {
      const user = get(response, 'data.teamMember', [])
      const isAdmin = get(response, 'data.admin', false)
      const userId = get(user, '_id', null)
      const userDefaultClientId = get(user, 'defaultClient', null)
      const clients = get(user, 'clients', [])
      const fallbackClient = get(clients, '[0]', null)
      const defaultClient = userDefaultClientId
        ? clients.find(client => client?.id === userDefaultClientId)
        : fallbackClient

      const defaultClientOperatingLocations = get(
        defaultClient,
        'operatingLocations',
        [],
      )
      const trackedUserClients = clients.map(client => {
        return {
          client_id: client.id,
          client_name: client.name,
        }
      })
      const queriedOperatingLocation = queryString.get('operatingLocation')
      const clientHasQueriedOperatingLocation = queriedOperatingLocation
        ? defaultClientOperatingLocations.some(
            location =>
              location?.code === queriedOperatingLocation.toUpperCase(),
          )
        : false

      const fallbackOperatingLocation = get(
        defaultClient,
        'operatingLocations[0].code',
        'NS',
      )
      const userDefaultOperatingLocation = get(
        user,
        'defaultOperatingLocation',
        null,
      )
      const defaultClientHasUserDefaultOperatingLocation =
        userDefaultOperatingLocation
          ? defaultClientOperatingLocations.some(
              operatingLocation =>
                operatingLocation?.code === userDefaultOperatingLocation,
            )
          : null
      const clientDefaultFallbackOperatingLocation = get(
        defaultClient,
        'operatingLocations[0].code',
        null,
      )
      const clientDefaultOperatingLocation = get(
        defaultClient,
        'defaultOperatingLocation.code',
        clientDefaultFallbackOperatingLocation,
      )

      const defaultOperatingLocation =
        queriedOperatingLocation && clientHasQueriedOperatingLocation // Location query string is valid for client
          ? queriedOperatingLocation.toUpperCase()
          : userDefaultOperatingLocation &&
            defaultClientHasUserDefaultOperatingLocation // User default location
          ? userDefaultOperatingLocation
          : clientDefaultOperatingLocation // Client default location
          ? clientDefaultOperatingLocation
          : fallbackOperatingLocation // Fallback location

      if (!isEmpty(user)) {
        dispatch({
          type: FETCH_USER_SUCCESS,
          payload: user,
        })

        if (userId) {
          // Identify user profile has logged in
          mixpanel.identify(userId)

          // Set super properties to be shown on all track calls
          mixpanel.register({
            client_id: get(defaultClient, 'id', null),
            client_name: get(defaultClient, 'name', null),
            operating_location: defaultOperatingLocation,
            platform: 'Workflows',
          })

          // Update user group with their applicable clients
          mixpanel.set_group('clients', trackedUserClients)
          mixpanelTrackClient(
            get(defaultClient, 'id', null),
            get(defaultClient, 'name', null),
            'Default',
          )
          mixpanelTrackOperatingLocation(defaultOperatingLocation, 'Default')

          // Set client based on previously select value or  by
          // initial default client based on user profile options
          if (defaultClient) {
            dispatch(setActiveClient(defaultClient))
          }

          // Set initial default operating location based on user profile options
          if (defaultOperatingLocation) {
            dispatch(setActiveOperatingLocation(defaultOperatingLocation))
          }

          if (defaultClient && defaultOperatingLocation) {
            const clientId = get(defaultClient, 'id', null)

            dispatch(checkClientIndexes(clientId, defaultOperatingLocation))
            dispatch(fetchWorkflows(token))
            dispatch(fetchCategories(token))
          }

          dispatch({
            type: SET_USER_IS_ADMIN,
            payload: isAdmin,
          })
        } else {
          dispatch({
            type: FETCH_USER_ERROR,
            payload:
              'There was an error retrieving user - please wait a moment, then try again.',
          })
        }
      }
    })
    .catch(error =>
      dispatch({ type: FETCH_USER_ERROR, payload: error.message }),
    )
}

// REDUCER
const initialState = {
  error: null,
  loading: false,
  admin: null,
  user: null,
}

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_USER_BEGIN:
      return { ...state, loading: true }
    case FETCH_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        user: action.payload,
        favourites: action.payload.favourites,
      }
    case SET_USER_IS_ADMIN:
      return {
        ...state,
        admin: action.payload,
      }
    case FETCH_USER_ERROR:
      return { ...state, loading: false, error: action.payload }
    default:
      return state
  }
}

// SELECTORS
export const selectUserState = state => state.userData
export const selectUserClients = state =>
  get(state, 'userData.user.clients', [])
export const selectUserProducts = state =>
  get(state, 'userData.user.products', [])
export const selectUserEmail = state => get(state, 'userData.user.email')
export const selectUserIsStaff = state =>
  get(state, 'userData.user.staff', false)

// HELPERS

export const getUserApiRequirements = state => {
  const user = state.userData.user
  const activeClient = state?.userSelectionsData.activeClient
  const activeOperatingLocation =
    state?.userSelectionsData?.activeOperatingLocation
  const userId = user?._id
  const userEmail = user?.email
  const activeClientId = activeClient?.id

  return {
    userId,
    userEmail,
    activeClientId,
    activeOperatingLocation,
  }
}
