import { SOCKET_SET_SESSION_EXPIRED } from '../../../socket/socket'

const onSessionExpired = ({ dispatch }) => {
  dispatch({
    type: SOCKET_SET_SESSION_EXPIRED,
    payload: true,
  })
}

export default onSessionExpired
