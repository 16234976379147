import PropTypes from 'prop-types'
import EditorGalleryField, {
  createGalleryField,
} from '../../base-fields/gallery-field/EditorGalleryField'
import { EDITOR_GALLERY } from '../../../../configuration/editor'

const EditorGalleryComponent = ({ component, onChange, dropPath }) => {
  const subcomponent = component?.subComponents?.[0] || {}
  const { fields } = subcomponent || []
  const field = fields[0]

  function handleFieldChange(field) {
    onChange({
      ...component,
      subComponents: [
        {
          ...subcomponent,
          fields: [field],
        },
      ],
    })
  }

  return (
    <div>
      <div>
        <EditorGalleryField
          field={field}
          onChange={handleFieldChange}
          dropPath={[...dropPath, 'subComponents', 0, 'fields', 0]}
        />
      </div>
    </div>
  )
}

export const createEmptyGallery = () => {
  const id = `temp-${Date.now()}-sc`
  const fieldId = `temp-${Date.now()}-field`
  return {
    id,
    type: 'subComponent',
    subComponentType: 'image',
    configurations: {},
    fields: [createGalleryField(fieldId)],
  }
}
export const createGalleryComponent = id => {
  return {
    id,
    componentType: EDITOR_GALLERY,
    configurations: {},
    subComponents: [createEmptyGallery()],
  }
}

EditorGalleryComponent.propTypes = {
  component: PropTypes.object,
  onChange: PropTypes.func,
  dropPath: PropTypes.array,
}

export default EditorGalleryComponent
