import axios from 'axios'
import { cloneDeep, get, isEmpty, merge } from 'lodash'

export const FETCH_WORKFLOWS_BEGIN = 'FETCH_WORKFLOWS_BEGIN'
export const FETCH_WORKFLOWS_ERROR = 'FETCH_WORKFLOWS_ERROR'
export const FETCH_WORKFLOWS_SUCCESS = 'FETCH_WORKFLOWS_SUCCESS'

export const UPDATE_WORKFLOWS_STEPS = 'UPDATE_WORKFLOWS_STEPS'

export const fetchWorkflows = token => async (dispatch, getState) => {
  const activeClient = getState().userSelectionsData.activeClient
  const activeOperatingLocation =
    getState().userSelectionsData.activeOperatingLocation
  const activeClientId = get(activeClient, 'id', null)

  dispatch({ type: FETCH_WORKFLOWS_BEGIN })
  return axios({
    url: `${process.env.REACT_APP_PRODUCT_API}/api/v1/workflows/${activeClientId}`,
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    data: {
      operatingLocation: activeOperatingLocation,
    },
  })
    .then(response => {
      const workflows = get(response, 'data', [])
      if (!isEmpty(workflows)) {
        let keyedWorkflows = {}
        keyedWorkflows[`${activeOperatingLocation}-${activeClientId}`] =
          workflows
        dispatch({
          type: FETCH_WORKFLOWS_SUCCESS,
          payload: keyedWorkflows,
        })
      } else {
        dispatch({
          type: FETCH_WORKFLOWS_ERROR,
          payload:
            'There was an error retrieving workflows - please wait a moment, then try again.',
        })
      }
    })
    .catch(error => {
      dispatch({
        type: FETCH_WORKFLOWS_ERROR,
        payload: error.message,
      })
    })
}

// REDUCER
const initialState = {
  error: null,
  loading: false,
  workflows: null,
}

export default function workflowsReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_WORKFLOWS_BEGIN:
      return { ...state, loading: true }
    case FETCH_WORKFLOWS_SUCCESS:
      return {
        ...state,
        loading: false,
        workflows: action.payload,
      }
    case FETCH_WORKFLOWS_ERROR:
      return { ...state, loading: false, error: action.payload }
    case UPDATE_WORKFLOWS_STEPS:
      const newState = cloneDeep(state)
      const workflowIndex = newState.workflows?.[action.key]?.findIndex(
        workflow => workflow._id === action.workflowId,
      )
      if (workflowIndex > -1) {
        const workflow = newState.workflows[action.key][workflowIndex]
        newState.workflows[action.key][workflowIndex] = {
          ...workflow,
          steps: action.payload.map(step => ({
            ...step,
            components: [],
          })),
        }
        return newState
      }
    default:
      return state
  }
}

// SELECTORS
export const selectWorkflowsState = state => state.workflowsData
export const selectWorkflows = state =>
  get(state, 'workflowsData.workflows', [])
