// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.image-field {
  display: flex;
  width: 100%;
}
.image-field img {
  max-width: 100%;
}
.image-field .image-field-button {
  border: 0;
  padding: 0;
  background: 0;
}`, "",{"version":3,"sources":["webpack://./src/styles/consumer-flow/base-fields/image-field.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,WAAA;AACF;AAAE;EACE,eAAA;AAEJ;AAAE;EACE,SAAA;EACA,UAAA;EACA,aAAA;AAEJ","sourcesContent":[".image-field {\n  display: flex;\n  width: 100%;\n  img {\n    max-width: 100%;\n  }\n  .image-field-button {\n    border: 0;\n    padding: 0;\n    background: 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
