import React from 'react'
import PropTypes from 'prop-types'

function PresentationIcon({ colour }, props) {
  return (
    <svg
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M27.6266 18.3288C27.9169 18.3639 28.1787 18.157 28.2137 17.8689L28.5897 14.7741C28.6079 14.6243 28.561 14.4738 28.4609 14.3608C28.3608 14.2478 28.2171 14.1831 28.0661 14.1831H24.8505C24.5593 14.1831 24.3232 14.4192 24.3232 14.7105C24.3232 15.0017 24.5593 15.2378 24.8505 15.2378H26.524L19.6675 20.5477C19.6408 20.5683 19.6034 20.5669 19.5784 20.5445L16.135 17.4508C15.7305 17.0874 15.1251 17.0653 14.6952 17.3982L6.6194 23.6524C6.38913 23.8308 6.34701 24.162 6.52539 24.3923C6.70251 24.6209 7.03333 24.6659 7.26522 24.4863L15.341 18.2321C15.3676 18.2115 15.4051 18.2128 15.4302 18.2353L18.8736 21.329C19.278 21.6925 19.8835 21.7146 20.3134 21.3816L27.3904 15.9009L27.1668 17.7417C27.1315 18.0308 27.3375 18.2936 27.6266 18.3288Z"
        fill={colour}
      />
      <path
        d="M26.1095 32.6219H3.7195C3.35296 32.6219 3.05469 32.3237 3.05469 31.9571C3.05469 31.5905 3.35289 31.2923 3.7195 31.2923H25.0315C25.3228 31.2923 25.5589 31.0562 25.5589 30.7649C25.5589 30.4737 25.3228 30.2376 25.0315 30.2376H4.48528V9.76228H33.0551V17.9141C33.0551 18.2053 33.2912 18.4414 33.5824 18.4414C33.8736 18.4414 34.1097 18.2053 34.1097 17.9141V9.73753C34.9207 9.59964 35.5403 8.89244 35.5403 8.04285C35.5403 7.09468 34.7689 6.32335 33.8208 6.32335H3.7195C2.77133 6.32328 2 7.09468 2 8.04278C2 8.89237 2.6196 9.59964 3.43051 9.73746V30.2624C2.6196 30.4003 2 31.1075 2 31.9571C2 32.9053 2.77133 33.6766 3.7195 33.6766H26.1095C26.4007 33.6766 26.6368 33.4405 26.6368 33.1493C26.6368 32.858 26.4007 32.6219 26.1095 32.6219ZM3.05469 8.04278C3.05469 7.67624 3.35289 7.37797 3.7195 7.37797H33.8208C34.1874 7.37797 34.4856 7.67617 34.4856 8.04278C34.4856 8.40932 34.1874 8.70752 33.8208 8.70752H3.7195C3.35289 8.70752 3.05469 8.40932 3.05469 8.04278Z"
        fill={colour}
      />
      <path
        d="M37.5098 21.3832C37.1135 21.1711 36.6384 21.2665 36.3546 21.6151L33.7024 24.8736C33.4259 22.7324 32.9824 20.653 32.7149 19.4956C32.6288 19.1228 32.2933 18.8625 31.8989 18.8625C31.5046 18.8625 31.1691 19.1228 31.083 19.4956C30.7974 20.7312 30.3279 22.9435 30.057 25.1818L27.4186 21.8314C27.1461 21.4855 26.6824 21.3845 26.2908 21.5861C25.8994 21.7875 25.712 22.2232 25.835 22.6457C26.1886 23.8599 26.7118 25.5498 27.3033 27.1158H26.8395C26.497 27.1158 26.1824 27.288 25.9977 27.5765C25.8131 27.8649 25.7885 28.2228 25.9319 28.5338L28.0355 33.0957C28.1982 33.4486 28.5544 33.6766 28.9431 33.6766H34.8544C35.2429 33.6766 35.5992 33.4486 35.762 33.0957L37.8655 28.5338C38.0089 28.2228 37.9843 27.865 37.7997 27.5765C37.615 27.2881 37.3004 27.1159 36.9579 27.1159H36.3499C36.9939 25.4877 37.5684 23.7317 37.9572 22.4723C38.0901 22.0431 37.906 21.5952 37.5098 21.3832ZM31.8989 20.6835C32.1998 22.0857 32.5794 24.0871 32.7719 26.0169L31.8775 27.1158H31.5802L30.9925 26.3695C31.1716 24.3355 31.5776 22.1808 31.8989 20.6835ZM27.0668 23.0893L30.2377 27.1158H28.4332C27.9093 25.7746 27.4299 24.2927 27.0668 23.0893ZM34.8193 32.6219H28.9787L26.9261 28.1705H36.872L34.8193 32.6219ZM35.2136 27.1158H33.2374L36.7495 22.8008C36.3456 24.0726 35.8035 25.6718 35.2136 27.1158Z"
        fill={colour}
      />
    </svg>
  )
}

PresentationIcon.defaultProps = {
  colour: '#000000',
}

PresentationIcon.propTypes = {
  colour: PropTypes.string,
}

export default PresentationIcon
