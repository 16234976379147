import React from 'react'
import PropTypes from 'prop-types'
import { isEmpty, get } from 'lodash'
import Select from 'react-select'
import generateHexColoursFieldOptions from '../../../utilities/brand/generateHexColoursFieldOptions'

function IconColourField({
  field,
  form: { touched, errors, setFieldValue },
  label,
  placeholder = 'Select an Icon',
  required,
  setFieldTouched,
}) {
  const brandColours = generateHexColoursFieldOptions()

  function onChange(option) {
    const value = get(option, 'value', '')

    setFieldValue(field.name, value)
  }

  const getValue = () => {
    const brandColourOptions = get(brandColours, '[0].options', [])
    const defaultColourOptions = get(brandColours, '[1].options', [])
    const combinedColourOptions = [
      ...brandColourOptions,
      ...defaultColourOptions,
    ]

    return !isEmpty(field?.value)
      ? combinedColourOptions.find(option => {
          return option.value === field.value
        })
      : ''
  }

  return (
    <>
      {label && (
        <label htmlFor={field.name}>
          {label}
          {required && <sup className="required">*</sup>}
        </label>
      )}

      <Select
        className={`custom-select custom-select__menu-colours${
          touched[field.name] && errors[field.name] ? ' error' : ''
        }`}
        classNamePrefix="custom-select"
        id={field.name}
        value={getValue()}
        isClearable={false}
        isMulti={false}
        maxMenuHeight={200}
        menuPlacement="top"
        minMenuHeight={200}
        isSearchable={false}
        name={field.name}
        onBlur={() => setFieldTouched(field.name, true)}
        onChange={onChange}
        options={brandColours}
        getOptionLabel={option => {
          const value = get(option, 'value', null)
          const label = get(option, 'label', null)

          return (
            <div
              className="custom-select__option-content"
              style={{ backgroundColor: value }}
            >
              <span className="custom-select__option-content-label">
                {label}
              </span>
            </div>
          )
        }}
        placeholder={placeholder}
        required={required}
      />

      {touched[field.name] && errors[field.name] && (
        <div className="field-error">{errors[field.name]}</div>
      )}
    </>
  )
}

IconColourField.propTypes = {
  errors: PropTypes.object,
  field: PropTypes.object,
  form: PropTypes.object,
  isFixed: PropTypes.bool,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  setFieldTouched: PropTypes.func.isRequired,
  touched: PropTypes.object,
}

export default IconColourField
