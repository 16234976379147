import axios from 'axios'
import processFindReplaceExternalLinkApiData from '../../utilities/find-replace/processFindReplaceExternalLinkApiData'

async function fetchFindExternalLink({ find, token, activeClientId }) {
  return axios({
    url: `${process.env.REACT_APP_PRODUCT_API}/api/v1/find-replace/search/external-link/${activeClientId}`,
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    data: {
      find,
    },
  }).then(res => {
    return processFindReplaceExternalLinkApiData({
      find,
      data: res?.data?.results || [],
    })
  })
}

export default fetchFindExternalLink
