import React from 'react'

export const supportedUserAccessPermissions = userAccessLevel => {
  switch (userAccessLevel) {
    case 'Super':
    case 'Administrator':
      return [
        {
          label: 'View',
          value: 'View',
          visible: true,
        },
        {
          label: 'User',
          value: 'User',
          visible: true,
        },
        {
          label: 'Edit',
          value: 'Edit',
          visible: true,
        },
        {
          label: 'Manager',
          value: 'Manager',
          visible: true,
        },
        {
          label: 'Administrator',
          value: 'Administrator',
          visible: true,
        },
        {
          label: 'Super',
          value: 'Super',
          visible: true,
        },
      ]
    case 'Manager':
    case 'Edit':
      return [
        {
          label: 'View',
          value: 'View',
          visible: true,
        },
        {
          label: 'User',
          value: 'User',
          visible: true,
        },
        {
          label: 'Edit',
          value: 'Edit',
          visible: true,
        },
        {
          label: 'Manager',
          value: 'Manager',
          visible: true,
        },
        {
          label: 'Administrator',
          value: 'Administrator',
          visible: false,
        },
        {
          label: 'Super',
          value: 'Super',
          visible: false,
        },
      ]
    default:
      return []
  }
}
