import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import useClickAway from '../../../../hooks/useClickAway'
import getActiveBrandHexColours from '../../../../utilities/brand/getActiveBrandHexColours'
import '../../../../styles/editor-flow/tip-tap/tiptap-color-picker.scss'
import { ReactComponent as TextColorIcon } from '../../../../assets/icons/text-color-icon.svg'
import clsx from 'clsx'

function TipTapColorPicker({ value, onChange, tooltip }) {
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const [dropdownSide, setDropdownSide] = useState('right')
  const ref = useClickAway(() => setDropdownOpen(false))
  const containerRef = useRef()
  const brandColours = getActiveBrandHexColours()

  function handlePickColour(color) {
    onChange(color)
    setDropdownOpen(false)
  }

  useEffect(() => {
    if (containerRef.current) {
      if (
        window.innerWidth - containerRef.current.getBoundingClientRect().x <
        400
      ) {
        setDropdownSide('left')
      } else {
        setDropdownSide('right')
      }
    }
  }, [dropdownOpen])

  return (
    <span className="tiptap-color-picker" ref={containerRef}>
      <button
        onClick={() => setDropdownOpen(true)}
        data-tooltip={tooltip}
        className="tiptap-color-picker__button"
      >
        <TextColorIcon className="tiptap-color-picker__icon" />
        <div
          className="tiptap-color-picker__value"
          style={{ backgroundColor: value || '#344054' }}
        ></div>
      </button>

      {dropdownOpen && (
        <div
          ref={ref}
          className={clsx('tiptap-color-picker__menu', dropdownSide)}
        >
          {brandColours.map(color => (
            <button
              key={`tip-tap-color-${color}`}
              onClick={() => handlePickColour(color)}
              className="tiptap-color-picker__color"
              style={{ backgroundColor: color }}
            ></button>
          ))}
        </div>
      )}
    </span>
  )
}

TipTapColorPicker.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  tooltip: PropTypes.string,
}

export default TipTapColorPicker
