import React from 'react'
import PropTypes from 'prop-types'

function QuestionAvatarIcon({ colour }, props) {
  return (
    <svg
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M35.32 30.1474C34.7742 27.5548 32.7326 25.5986 30.1197 25.1631L24.0642 24.1534V23.2789C28.1397 21.656 31.0319 17.6792 31.0319 13.0323C31.0319 6.94884 26.0831 2 19.9997 2C13.9162 2 8.96741 6.94884 8.96741 13.0323C8.96741 17.6792 11.8596 21.656 15.9351 23.2789V24.1534L9.87902 25.1631C7.26612 25.5986 5.22515 27.5548 4.67876 30.1474L3.02625 38H36.9737L35.32 30.1474ZM26.2015 25.6868L24.3702 31.1815L20.8491 28.1006L23.6827 25.267L26.2015 25.6868ZM19.9997 27.308L17.0964 24.4048V23.6639C18.0231 23.9176 18.9934 24.0645 19.9997 24.0645C21.0059 24.0645 21.9762 23.9176 22.9029 23.6639V24.4048L19.9997 27.308ZM10.1287 13.0323C10.1287 7.58929 14.5567 3.16129 19.9997 3.16129C25.4426 3.16129 29.8706 7.58929 29.8706 13.0323C29.8706 18.4752 25.4426 22.9032 19.9997 22.9032C14.5567 22.9032 10.1287 18.4752 10.1287 13.0323ZM16.3166 25.267L19.1502 28.1006L15.6291 31.1815L13.7978 25.6868L16.3166 25.267ZM17.2393 36.8387H9.03534L9.54457 32.2574L8.39083 32.1291L7.86708 36.8387H4.45696L5.81566 30.3866C6.26276 28.2655 7.9327 26.6646 10.0706 26.3087L12.6382 25.8808L15.0799 33.2062L16.8439 31.6628L18.2008 33.4733L17.2393 36.8387ZM18.4464 36.8387L19.4759 33.237L17.7195 30.8958L19.9735 28.9239L19.9997 28.9501L20.0258 28.9239L22.2793 30.8958L20.5228 33.237L21.5523 36.8387H18.4464ZM31.6091 32.1297L30.4553 32.258L30.964 36.8387H22.7601L21.7985 33.4727L23.1555 31.6623L24.9195 33.2056L27.3611 25.8802L29.9287 26.3081C32.0672 26.6646 33.7372 28.2649 34.1837 30.3866L35.5424 36.8387H32.1322L31.6091 32.1297Z"
        fill={colour}
      />
      <path
        d="M19.7094 5.48387C17.9483 5.48387 16.5159 6.9169 16.5159 8.67742V8.96774H17.6772V8.67742C17.6772 7.55677 18.5888 6.64516 19.7094 6.64516C20.8301 6.64516 21.7417 7.55677 21.7417 8.67742V9.04671C21.7417 9.58961 21.5303 10.0994 21.1465 10.4844L19.4191 12.2112V15.3548H20.5804V12.692L21.9681 11.3048C22.5714 10.701 22.903 9.8991 22.903 9.04613V8.67742C22.903 6.9169 21.4705 5.48387 19.7094 5.48387Z"
        fill={colour}
      />
      <path
        d="M19.9996 17.0968C19.0392 17.0968 18.2577 17.8783 18.2577 18.8387C18.2577 19.7991 19.0392 20.5806 19.9996 20.5806C20.96 20.5806 21.7416 19.7991 21.7416 18.8387C21.7416 17.8783 20.96 17.0968 19.9996 17.0968ZM19.9996 19.4194C19.6797 19.4194 19.419 19.1592 19.419 18.8387C19.419 18.5182 19.6797 18.2581 19.9996 18.2581C20.3196 18.2581 20.5803 18.5182 20.5803 18.8387C20.5803 19.1592 20.3196 19.4194 19.9996 19.4194Z"
        fill={colour}
      />
    </svg>
  )
}

QuestionAvatarIcon.defaultProps = {
  colour: '#000000',
}

QuestionAvatarIcon.propTypes = {
  colour: PropTypes.string,
}

export default QuestionAvatarIcon
