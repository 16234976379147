import React, { useEffect, useState, forwardRef } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { get, isEmpty } from 'lodash'
import {
  selectActiveClient,
  selectActiveClientId,
  selectActiveOperatingLocation,
  selectDuplicatedStepVisibility,
  selectManageStepModalVisibility,
  selectManageWorkflowModalVisibility,
  setDuplicatedContentData,
  setManagedContentId,
  toggleDuplicateStepModal,
  toggleManageStepModal,
  toggleManageWorkflowModal,
} from '../../../redux/user/userSelections'
import {
  fetchWorkflow,
  selectWorkflowState,
} from '../../../redux/workflows/workflow'
import { SimpleTreeItemWrapper, SortableTree } from 'dnd-kit-sortable-tree'
import routes from '../../../configuration/routes'
import MaterialLoader from '../../global-components/elements/MaterialLoader'
import formatStepsToNestedSteps from '../../../utilities/steps/formatStepsToNestedSteps'
import ManageWorkflowStepNavigationHeader from './elements/ManageWorkflowStepNavigationHeader'

import getWorkflowsDataById from '../../../utilities/workflows/getWorkflowsDataById'
import ManageWorkflowsStepsCard from './elements/ManageWorkflowsStepsCard'
import ManageWorkflowStepDeleteModal from './elements/ManageWorkflowStepDeleteModal'
import useAuthToken from '../../../hooks/useAuthToken'
import orderSteps from '../../../redux/steps/orderSteps'
import { emitSocketMessage } from '../../../redux/middleware/socket/socket'
import {
  selectSocketId,
  selectSocketResourceBlockedBy,
  selectSocketSessionExpired,
} from '../../../redux/socket/socket'
import ManageWorkflowStepBlockedModal from './elements/ManageWorkflowStepBlockedModal'
import useOnResourceBecomesUnblocked from '../../../hooks/useOnResourceBecomesUnblocked'
import { ReactComponent as CloseIcon } from '../../../assets/icons/close-icon.svg'
import { ReactComponent as DeleteIcon } from '../../../assets/icons/delete-icon.svg'
import { ReactComponent as EyeIcon } from '../../../assets/icons/eye-icon.svg'
import { ReactComponent as EyeBlockedIcon } from '../../../assets/icons/eye-blocked-icon.svg'
import { ReactComponent as PlusSquareIcon } from '../../../assets/icons/plus-square-icon.svg'

import ManageWorkflowStepsPublishModal from './elements/ManageWorkflowStepsPublishModal'
import { updateSteps } from '../../../redux/steps/updateSteps'
import ManageWorkflowStepsDeleteModal from './elements/ManageWorkflowStepsDeleteModal'
import { deleteSteps } from '../../../redux/steps/deleteSteps'
import { deleteStep } from '../../../redux/steps/deleteStep'

function ManageWorkflow() {
  const dispatch = useDispatch()
  const { workflow } = useParams()
  const { token } = useAuthToken({})
  const navigate = useNavigate()
  const [publishModalState, setPublishModalState] = useState({
    type: '',
    steps: [],
  })
  const [stepsToDelete, setStepsToDelete] = useState([])
  const [selectedSteps, setSelectedSteps] = useState([])
  const isManageWorkflowModalVisible = useSelector(
    selectManageWorkflowModalVisibility,
  )
  const isManageStepModalVisible = useSelector(selectManageStepModalVisibility)
  const isDuplicateStepModalVisible = useSelector(
    selectDuplicatedStepVisibility,
  )

  const activeClient = useSelector(selectActiveClient)
  const activeClientId = useSelector(selectActiveClientId)
  const activeOperatingLocation = useSelector(selectActiveOperatingLocation)
  const workflowState = useSelector(selectWorkflowState)
  const isSocketSessionExpired = useSelector(selectSocketSessionExpired)
  const socketId = useSelector(selectSocketId)
  const workflowOptions = get(workflowState, 'workflow', [])
  const workflowByClientSlug = get(
    workflowOptions,
    `[${activeOperatingLocation}-${activeClientId}-${workflow}]`,
    {},
  )
  const workflowByClientSlugSteps = get(workflowByClientSlug, 'steps', [])
  const workflowId = get(workflowByClientSlug, 'workflowId', null)
  const managedWorkflow = getWorkflowsDataById(workflowId)
  const managedWorkflowName = get(activeClient, 'name', null)
  const managedWorkflowSlug = get(managedWorkflow, 'slug', null)
  const noResultsState =
    !workflowByClientSlug || isEmpty(workflowByClientSlugSteps)
  const formattedStepsByClient = formatStepsToNestedSteps(
    workflowByClientSlugSteps,
  )
  const resourceBlockedBy = useSelector(state =>
    selectSocketResourceBlockedBy({
      state,
      type: 'configureSteps',
      id: workflowId,
    }),
  )

  useOnResourceBecomesUnblocked({
    name: 'configureSteps',
    data: {
      workflowId,
    },
    isBlocked: !!resourceBlockedBy,
  })

  useEffect(() => {
    if (token) {
      handleFetchWorkflowBySlug()
    }
  }, [token])

  useEffect(() => {
    if (workflowId && socketId) {
      dispatch(
        emitSocketMessage('configureSteps', {
          workflowId,
        }),
      )
    }
    return () => {
      if (workflowId) {
        dispatch(
          emitSocketMessage('configureStepsComplete', {
            workflowId,
          }),
        )
      }
    }
  }, [workflowId, socketId])

  async function handleFetchWorkflowBySlug() {
    await dispatch(fetchWorkflow(workflow, token))
  }

  function handleSetItem(orderedSteps) {
    let newStepOrder = []

    orderedSteps.forEach(orderedStep => {
      const orderedStepId = get(orderedStep, 'id', null)
      const nestedSteps = get(orderedStep, 'children', [])

      newStepOrder.push({
        id: orderedStepId,
        nestedSteps: nestedSteps.map(nestedStep => nestedStep?.id),
      })

      if (!isEmpty(nestedSteps)) {
        nestedSteps.forEach(nestedStep => {
          newStepOrder.push({
            id: nestedStep?.id,
            nestedSteps: [],
          })
        })
      }
    })

    dispatch(
      orderSteps({
        order: newStepOrder,
        slug: workflow,
        workflowId,
        workflowSlug: managedWorkflowSlug,
        token,
      }),
    )
  }

  function handleToggleDeleteStep(step) {
    if (step) {
      setStepsToDelete([step])
    }
  }

  function handleToggleEditWorkflow() {
    dispatch(toggleManageWorkflowModal(!isManageWorkflowModalVisible))
    dispatch(setManagedContentId(workflowId))
  }

  function handleToggleConfigureStep(step) {
    dispatch(toggleManageStepModal(!isManageStepModalVisible))
    dispatch(setManagedContentId(step?._id))
  }

  function handleEditClick(step) {
    navigate(`${routes.manageWorkflows}/${workflow}/${step?.slug}`)
  }

  function closePublishModal() {
    setPublishModalState({ steps: [], type: '' })
  }

  function handleStepClick(evt, step) {
    if (selectedSteps?.includes(step._id)) {
      setSelectedSteps(selectedSteps.filter(item => item !== step._id))
    } else {
      setSelectedSteps([...selectedSteps, step?._id])
    }
  }

  function handleToggleDuplicateStep(workflowId, stepId) {
    dispatch(
      setDuplicatedContentData({
        workflowId: workflowId,
        stepId: stepId,
        componentId: null,
        subComponentId: null,
      }),
    )
    dispatch(toggleDuplicateStepModal(!isDuplicateStepModalVisible))
  }

  function handlePublishSteps() {
    const steps = getSelectedSteps()

    setPublishModalState({
      steps,
      type: 'publish',
    })
  }

  function handleUnpublishSteps() {
    const steps = getSelectedSteps()

    setPublishModalState({
      steps,
      type: 'unpublish',
    })
  }

  function onDeleteStepClick() {
    setStepsToDelete(getSelectedSteps())
  }

  function handlePublishModalSubmit(steps) {
    const status = publishModalState.type === 'publish' ? 'published' : 'draft'
    dispatch(
      updateSteps({
        token,
        workflow: managedWorkflow,
        values: {
          status,
        },
        stepIds: steps?.map(step => step._id),
      }),
    )
    closePublishModal()
    setSelectedSteps([])
  }

  function getSelectedSteps() {
    return workflowByClientSlugSteps.filter(step =>
      selectedSteps.includes(step?._id),
    )
  }

  function handleDeleteStepsSubmit(steps) {
    setStepsToDelete([])
    dispatch(
      deleteSteps({
        token,
        stepIds: steps.map(step => step._id),
        workflow: managedWorkflow,
      }),
    )
    setSelectedSteps([])
  }

  function handleGotoFirstStep() {
    const stepSlug = formattedStepsByClient?.[0]?.data?.slug
    navigate(`${routes.manageWorkflows}/${workflow}/${stepSlug}`)
  }

  function handleSelectAllClick() {
    const allStepIds = workflowByClientSlugSteps.map(step => step._id)
    setSelectedSteps(
      selectedSteps.length === allStepIds.length ? [] : allStepIds,
    )
  }

  async function handleDeleteWorkflowStep(stepId, workflowId, workflowSlug) {
    await dispatch(
      deleteStep(
        stepId,
        workflowId,
        workflowSlug,
        () => setStepsToDelete([]),
        token,
      ),
    )
    setSelectedSteps([])
  }

  return workflowState.loading || !formattedStepsByClient ? (
    <MaterialLoader containerClasses="inline-loader" />
  ) : (
    <div className="manage-workflow">
      <div className="manage-workflow__main">
        <ManageWorkflowStepNavigationHeader
          editWorkflowEvent={handleToggleEditWorkflow}
          managedWorkflow={managedWorkflow}
          workflowSteps={workflowByClientSlugSteps}
        />
        <div className="manage-workflow__container">
          {workflow && workflowByClientSlug && workflowByClientSlugSteps ? (
            <div
              className={`manage-workflow__content${
                noResultsState ? ' error' : ''
              }`}
            >
              {!isEmpty(formattedStepsByClient) ? (
                <table className="manage-workflow__table">
                  {selectedSteps.length > 0 ? (
                    <div className="manage-workflow__actions">
                      <div className="flex align-center">
                        <button
                          className="manage-workflow__actions-clear"
                          data-tooltip="Clear Selection"
                          data-tooltip-offset="top"
                          onClick={() => setSelectedSteps([])}
                        >
                          <CloseIcon />
                        </button>
                        <div className="manage-workflow__actions-selected-title">
                          {selectedSteps.length} Selected:
                        </div>

                        <button
                          className="btn btn--white btn--icon-text-left"
                          onClick={handlePublishSteps}
                        >
                          <EyeIcon />
                          <span>
                            Publish Step{selectedSteps.length > 1 ? 's' : ''}
                          </span>
                        </button>
                        <button
                          className="btn btn--white btn--icon-text-left"
                          onClick={handleUnpublishSteps}
                        >
                          <EyeBlockedIcon />
                          <span>
                            Unpublish Step{selectedSteps.length > 1 ? 's' : ''}
                          </span>
                        </button>

                        <button
                          className="btn btn--secondary-red btn--icon-text-left"
                          onClick={onDeleteStepClick}
                        >
                          <DeleteIcon />
                          <span>
                            Delete Step{selectedSteps.length > 1 ? 's' : ''}
                          </span>
                        </button>
                      </div>
                    </div>
                  ) : (
                    <thead>
                      <tr className="manage-workflow__table-header">
                        <th className="manage-workflow__table-header-cell reorder"></th>
                        <th className="manage-workflow__table-header-cell select">
                          <div className="manage-workflow__steps-card-checkbox">
                            <label>
                              <input
                                type="checkbox"
                                onClick={handleSelectAllClick}
                                checked={
                                  selectedSteps.length ===
                                  workflowByClientSlugSteps.length
                                }
                              />
                            </label>
                          </div>
                        </th>
                        <th className="manage-workflow__table-header-cell details">
                          Name
                        </th>
                        <th className="manage-workflow__table-header-cell stage">
                          Stage
                        </th>
                        <th className="manage-workflow__table-header-cell actions">
                          Actions
                        </th>
                      </tr>
                    </thead>
                  )}
                  <tbody>
                    <SortableTree
                      items={formattedStepsByClient}
                      onItemsChanged={handleSetItem} // eslint-disable-next-line
                      TreeItemComponent={forwardRef((props, ref) => (
                        <SimpleTreeItemWrapper
                          {...props}
                          ref={ref}
                          showDragHandle={false}
                          manualDrag={true}
                          indentationWidth={35}
                        >
                          <ManageWorkflowsStepsCard
                            key={`manage-steps-card-${props?.item?.index}`}
                            onDeleteClick={handleToggleDeleteStep}
                            onDuplicateClick={handleToggleDuplicateStep}
                            onConfigureClick={handleToggleConfigureStep}
                            onEditClick={handleEditClick}
                            onClick={handleStepClick}
                            index={props?.item?.index}
                            parentIndex={props?.parent?.index}
                            stepData={props?.item?.data}
                            handleProps={props.handleProps}
                            isSelected={selectedSteps.includes(
                              props?.item?.data?._id,
                            )}
                          />
                        </SimpleTreeItemWrapper>
                      ))}
                    />
                  </tbody>
                </table>
              ) : (
                <div className="manage-workflow__error">
                  <h3>Add Your First Step</h3>

                  <p>
                    The are no steps currently assigned to this workflow - click
                    the button below and follow the form instructions to create
                    your first workflow step
                  </p>

                  <button
                    onClick={() =>
                      dispatch(toggleManageStepModal(!isManageStepModalVisible))
                    }
                    className="btn btn--white"
                  >
                    Add First Step
                  </button>
                </div>
              )}
              {formattedStepsByClient?.length > 0 ? (
                <div
                  className="manage-workflow__sticky__button__container"
                  onClick={() =>
                    dispatch(toggleManageStepModal(!isManageStepModalVisible))
                  }
                >
                  <PlusSquareIcon />
                  <span>Add New Step</span>
                </div>
              ) : null}
            </div>
          ) : (
            <div className="manage-workflow__error">
              <h3>Workflow Not Found</h3>
              <p>
                The current URL path does not match any workflows under{' '}
                {managedWorkflowName}
              </p>

              <Link className="btn btn--white" to={routes.viewMyWorkflows}>
                View {managedWorkflow} Workflows
              </Link>
            </div>
          )}
        </div>
        {stepsToDelete.length === 1 ? (
          <ManageWorkflowStepDeleteModal
            managedWorkflow={managedWorkflow}
            deletedStep={stepsToDelete[0]}
            isOpen={stepsToDelete.length === 1}
            onRequestClose={() => setStepsToDelete([])}
            onDelete={handleDeleteWorkflowStep}
          />
        ) : null}
        {stepsToDelete.length > 1 ? (
          <ManageWorkflowStepsDeleteModal
            steps={stepsToDelete}
            isOpen={stepsToDelete.length > 1}
            onRequestClose={() => setStepsToDelete([])}
            onSubmit={handleDeleteStepsSubmit}
          />
        ) : null}
        {publishModalState.type ? (
          <ManageWorkflowStepsPublishModal
            isOpen={!!publishModalState.type}
            onRequestClose={closePublishModal}
            steps={publishModalState.steps}
            onSubmit={handlePublishModalSubmit}
            type={publishModalState.type}
          />
        ) : null}
        {!isSocketSessionExpired && resourceBlockedBy ? (
          <ManageWorkflowStepBlockedModal
            resource={resourceBlockedBy}
            onStepsClick={handleGotoFirstStep}
          />
        ) : null}
      </div>
    </div>
  )
}

ManageWorkflow.propTypes = {
  handleProps: PropTypes.func,
  item: PropTypes.object,
  parent: PropTypes.object,
}

export default ManageWorkflow
