import React from 'react'
import ReactModal from 'react-modal'
import PropTypes from 'prop-types'

ReactModal.setAppElement('#workflows')

function Modal({
  children,
  closeOnOverlayClick,
  isOpen,
  modalClassName,
  onRequestClose,
  overlayClassName,
}) {
  return (
    <ReactModal
      bodyOpenClassName="modal-open"
      className={`modal ${modalClassName}`}
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      overlayClassName={`modal-overlay  ${overlayClassName}`}
      shouldCloseOnOverlayClick={!onRequestClose || closeOnOverlayClick}
    >
      {children}
    </ReactModal>
  )
}

Modal.defaultProps = {
  modalClassName: 'modal--lg',
  overlayClassName: '',
  closeOnOverlayClick: true,
  onRequestClose: null,
}

Modal.propTypes = {
  closeOnOverlayClick: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  isOpen: PropTypes.bool.isRequired,
  modalClassName: PropTypes.string,
  onRequestClose: PropTypes.func,
  overlayClassName: PropTypes.string,
}

export default Modal
