import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import useClickAway from '../../../../hooks/useClickAway'
import clsx from 'clsx'
import '../../../../styles/editor-flow/tip-tap/tiptap-dropdown.scss'

function TipTapDropdown({
  button,
  buttonClass,
  children,
  tooltip,
  disabled,
  direction,
}) {
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const ref = useClickAway(() => setDropdownOpen(false))

  return (
    <div className="tiptap-dropdown">
      <button
        onClick={() => setDropdownOpen(true)}
        className={clsx('tiptap-dropdown__button', buttonClass)}
        data-tooltip={tooltip}
        disabled={disabled}
      >
        {button}
      </button>

      {dropdownOpen && (
        <div
          ref={ref}
          className={`tiptap-dropdown__menu ${direction}`}
          onClick={() => setDropdownOpen(false)}
        >
          {children}
        </div>
      )}
    </div>
  )
}

TipTapDropdown.propTypes = {
  button: PropTypes.node,
  buttonClass: PropTypes.string,
  children: PropTypes.node,
  tooltip: PropTypes.string,
  disabled: PropTypes.bool,
  direction: PropTypes.string,
}

export default TipTapDropdown
