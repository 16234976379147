import React, { useEffect, useState, forwardRef } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { get, isEmpty } from 'lodash'
import {
  selectActiveClient,
  selectActiveClientId,
  selectActiveOperatingLocation,
  selectDuplicatedStepVisibility,
  selectManageStepModalVisibility,
  selectManageWorkflowModalVisibility,
  setDuplicatedContentData,
  setManagedContentId,
  toggleDuplicateStepModal,
  toggleManageStepModal,
  toggleManageWorkflowModal,
} from '../../../redux/user/userSelections'
import {
  fetchWorkflow,
  selectWorkflowState,
} from '../../../redux/workflows/workflow'
import { SimpleTreeItemWrapper, SortableTree } from 'dnd-kit-sortable-tree'
import routes from '../../../configuration/routes'
import MaterialLoader from '../../global-components/elements/MaterialLoader'
import formatStepsToNestedSteps from '../../../utilities/steps/formatStepsToNestedSteps'
import ManageWorkflowNavigation from './elements/ManageWorkflowStepNavigation'
import getWorkflowsDataById from '../../../utilities/workflows/getWorkflowsDataById'
import ManageWorkflowsStepsCard from './elements/ManageWorkflowsStepsCard'
import ManageWorkflowStepsDeleteModal from './elements/ManageWorkflowStepsDeleteModal'
import useAuthToken from '../../../hooks/useAuthToken'
import orderSteps from '../../../redux/steps/orderSteps'
import { ReactComponent as ReorderIcon } from '../../../assets/icons/reorder-icon.svg'
import { emitSocketMessage } from '../../../redux/middleware/socket/socket'
import {
  selectSocketId,
  selectSocketResourceBlockedBy,
  selectSocketSessionExpired,
} from '../../../redux/socket/socket'
import ManageWorkflowStepBlockedModal from './elements/ManageWorkflowStepBlockedModal'
import useOnResourceBecomesUnblocked from '../../../hooks/useOnResourceBecomesUnblocked'

function ManageWorkflow() {
  const dispatch = useDispatch()
  const { workflow } = useParams()
  const { token } = useAuthToken({})
  const navigate = useNavigate()
  const [isDeleteModalOpen, toggleDeleteModal] = useState(false)
  const [deletedStep, setDeletedStep] = useState({})
  const isManageWorkflowModalVisible = useSelector(
    selectManageWorkflowModalVisibility,
  )
  const isManageStepModalVisible = useSelector(selectManageStepModalVisibility)
  const isDuplicateStepModalVisible = useSelector(
    selectDuplicatedStepVisibility,
  )

  const activeClient = useSelector(selectActiveClient)
  const activeClientId = useSelector(selectActiveClientId)
  const activeOperatingLocation = useSelector(selectActiveOperatingLocation)
  const workflowState = useSelector(selectWorkflowState)
  const isSocketSessionExpired = useSelector(selectSocketSessionExpired)
  const socketId = useSelector(selectSocketId)
  const workflowOptions = get(workflowState, 'workflow', [])
  const workflowByClientSlug = get(
    workflowOptions,
    `[${activeOperatingLocation}-${activeClientId}-${workflow}]`,
    {},
  )
  const workflowByClientSlugSteps = get(workflowByClientSlug, 'steps', [])
  const workflowId = get(workflowByClientSlug, 'workflowId', null)
  const managedWorkflow = getWorkflowsDataById(workflowId)
  const managedWorkflowName = get(activeClient, 'name', null)
  const managedWorkflowSlug = get(managedWorkflow, 'slug', null)
  const noResultsState =
    !workflowByClientSlug || isEmpty(workflowByClientSlugSteps)
  const formattedStepsByClient = formatStepsToNestedSteps(
    workflowByClientSlugSteps,
  )
  const resourceBlockedBy = useSelector(state =>
    selectSocketResourceBlockedBy({
      state,
      type: 'configureSteps',
      id: workflowId,
    }),
  )

  useOnResourceBecomesUnblocked({
    name: 'configureSteps',
    data: {
      workflowId,
    },
    isBlocked: !!resourceBlockedBy,
  })

  useEffect(() => {
    if (token) {
      handleFetchWorkflowBySlug()
    }
  }, [token])

  useEffect(() => {
    if (workflowId && socketId) {
      dispatch(
        emitSocketMessage('configureSteps', {
          workflowId,
        }),
      )
    }
    return () => {
      if (workflowId) {
        dispatch(
          emitSocketMessage('configureStepsComplete', {
            workflowId,
          }),
        )
      }
    }
  }, [workflowId, socketId])

  async function handleFetchWorkflowBySlug() {
    await dispatch(fetchWorkflow(workflow, token))
  }

  function handleSetItem(orderedSteps) {
    let newStepOrder = []

    orderedSteps.forEach(orderedStep => {
      const orderedStepId = get(orderedStep, 'id', null)
      const nestedSteps = get(orderedStep, 'children', [])

      newStepOrder.push({
        id: orderedStepId,
        nestedSteps: nestedSteps.map(nestedStep => nestedStep?.id),
      })

      if (!isEmpty(nestedSteps)) {
        nestedSteps.forEach(nestedStep => {
          newStepOrder.push({
            id: nestedStep?.id,
            nestedSteps: [],
          })
        })
      }
    })

    dispatch(
      orderSteps({
        order: newStepOrder,
        slug: workflow,
        workflowId,
        workflowSlug: managedWorkflowSlug,
        token,
      }),
    )
  }

  function handleToggleDeleteStep(stepData) {
    if (stepData) {
      setDeletedStep(stepData)
      toggleDeleteModal(!isDeleteModalOpen)
    }
  }

  function handleToggleEditWorkflow() {
    dispatch(toggleManageWorkflowModal(!isManageWorkflowModalVisible))
    dispatch(setManagedContentId(workflowId))
  }

  function handleToggleEditStep(stepId) {
    dispatch(toggleManageStepModal(!isManageStepModalVisible))
    dispatch(setManagedContentId(stepId))
  }

  function handleToggleDuplicateStep(workflowId, stepId) {
    dispatch(
      setDuplicatedContentData({
        workflowId: workflowId,
        stepId: stepId,
        componentId: null,
        subComponentId: null,
      }),
    )
    dispatch(toggleDuplicateStepModal(!isDuplicateStepModalVisible))
  }

  function handleGotoFirstStep() {
    const stepSlug = formattedStepsByClient?.[0]?.data?.slug
    navigate(`${routes.manageWorkflows}/${workflow}/${stepSlug}`)
  }

  return workflowState.loading || !formattedStepsByClient ? (
    <MaterialLoader containerClasses="inline-loader" />
  ) : (
    <div className="manage-workflow">
      <div className="manage-workflow__sidebar">
        <button
          onClick={() => handleToggleEditWorkflow()}
          className="btn btn--white"
        >
          Configure Workflow
        </button>

        <button
          onClick={() =>
            dispatch(toggleManageStepModal(!isManageStepModalVisible))
          }
          className="btn btn--white"
        >
          {!isEmpty(workflowByClientSlugSteps)
            ? 'Add New Step'
            : 'Add First Step'}
        </button>
      </div>

      <div className="manage-workflow__main">
        <ManageWorkflowNavigation
          editWorkflowEvent={handleToggleEditWorkflow}
          managedWorkflow={managedWorkflow}
          workflowSteps={workflowByClientSlugSteps}
        />

        {workflow && workflowByClientSlug && workflowByClientSlugSteps ? (
          <div
            className={`manage-workflow__content${
              noResultsState ? ' error' : ''
            }`}
          >
            {!isEmpty(formattedStepsByClient) ? (
              <SortableTree
                items={formattedStepsByClient}
                onItemsChanged={handleSetItem} // eslint-disable-next-line
                TreeItemComponent={forwardRef((props, ref) => (
                  <SimpleTreeItemWrapper
                    {...props}
                    ref={ref}
                    showDragHandle={false}
                    manualDrag={true}
                    indentationWidth={35}
                  >
                    <ReorderIcon
                      className="manage-workflow__steps-card-reorder-icon"
                      {...props.handleProps}
                    />

                    <ManageWorkflowsStepsCard
                      key={`manage-steps-card-${props?.item?.index}`}
                      handleDeleteEvent={handleToggleDeleteStep}
                      handleDuplicateEvent={handleToggleDuplicateStep}
                      handleEditEvent={handleToggleEditStep}
                      index={props?.item?.index}
                      parentIndex={props?.parent?.index}
                      stepData={props?.item?.data}
                    />
                  </SimpleTreeItemWrapper>
                ))}
              />
            ) : (
              <div className="manage-workflow__error">
                <h3>Add Your First Step</h3>

                <p>
                  The are no steps currently assigned to this workflow - click
                  the button below and follow the form instructions to create
                  your first workflow step
                </p>

                <button
                  onClick={() =>
                    dispatch(toggleManageStepModal(!isManageStepModalVisible))
                  }
                  className="btn btn--white"
                >
                  Add First Step
                </button>
              </div>
            )}
          </div>
        ) : (
          <div className="manage-workflow__error">
            <h3>Workflow Not Found</h3>
            <p>
              The current URL path does not match any workflows under{' '}
              {managedWorkflowName}
            </p>

            <Link className="btn btn--white" to={routes.viewMyWorkflows}>
              View {managedWorkflow} Workflows
            </Link>
          </div>
        )}

        {isDeleteModalOpen ? (
          <ManageWorkflowStepsDeleteModal
            deletedStep={deletedStep}
            isDeleteModalOpen={isDeleteModalOpen}
            managedWorkflow={managedWorkflow}
            setDeletedStep={setDeletedStep}
            toggleDeleteModal={toggleDeleteModal}
          />
        ) : null}
        {!isSocketSessionExpired && resourceBlockedBy ? (
          <ManageWorkflowStepBlockedModal
            resource={resourceBlockedBy}
            onStepsClick={handleGotoFirstStep}
          />
        ) : null}
      </div>
    </div>
  )
}

ManageWorkflow.propTypes = {
  handleProps: PropTypes.func,
  item: PropTypes.object,
  parent: PropTypes.object,
}

export default ManageWorkflow
