import axios from 'axios'
import { get, isEmpty } from 'lodash'
import { fetchWorkflows } from './workflows'
import { fetchWorkflow } from './workflow'
import { emitSocketMessage } from '../middleware/socket/socket'

export const UPDATE_WORKFLOW_BEGIN = 'UPDATE_WORKFLOW_BEGIN'
export const UPDATE_WORKFLOW_ERROR = 'UPDATE_WORKFLOW_ERROR'
export const UPDATE_WORKFLOW_SUCCESS = 'UPDATE_WORKFLOW_SUCCESS'

export const updateWorkflow =
  (
    workflowId,
    workflowData,
    successEvent,
    redirectEvent,
    token,
    externalClientCategories,
  ) =>
  async (dispatch, getState) => {
    const activeClient = getState().userSelectionsData.activeClient
    const activeClientId = get(activeClient, 'id', null)
    const activeOperatingLocation =
      getState().userSelectionsData.activeOperatingLocation
    const categories = getState().categoriesData.categories
    const clientCategories = get(categories, activeClientId, [])
    const selectedCategories = get(workflowData, 'categories', [])
    const workflowName = get(workflowData, 'name', null)
    const workflowSlug = get(workflowData, 'slug', null)
    const icon = get(workflowData, 'icon', null)
    const iconColour = get(workflowData, 'iconColour', null)
    const workflowDescription = get(workflowData, 'description', null)

    const updatePayload = {
      ...workflowData,
      name: workflowName.trim(),
      content: workflowDescription ? { description: workflowDescription } : {},
      icon: {
        id: icon,
        colour: iconColour,
      },
      categories: [
        ...externalClientCategories,
        ...(selectedCategories?.map(selectedCategory => {
          const category = get(selectedCategory, 'category', null)
          const subcategories = get(selectedCategory, 'subcategories', [])
          const clientCategoryData = clientCategories.find(
            clientCategory => clientCategory?._id === category,
          )

          return !isEmpty(clientCategoryData)
            ? {
                ...clientCategoryData,
                id: category,
                subcategories: subcategories,
              }
            : null
        }) || []),
      ],
    }

    dispatch({ type: UPDATE_WORKFLOW_BEGIN })

    return axios({
      url: `${process.env.REACT_APP_PRODUCT_API}/api/v1/workflow/update/${workflowId}`,
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      data: {
        clientId: activeClientId,
        operatingLocation: activeOperatingLocation,
        workflow: updatePayload,
      },
    })
      .then(() => {
        dispatch({
          type: UPDATE_WORKFLOW_SUCCESS,
        })
        dispatch(fetchWorkflows(token))
        dispatch(fetchWorkflow(workflowSlug, token))
        dispatch(
          emitSocketMessage('configureWorkflowComplete', {
            workflowId,
            hasChanged: true,
          }),
        )
        successEvent()
        redirectEvent(workflowSlug)
      })
      .catch(error => {
        const customError = get(error, 'response.data.error', 'Error')

        dispatch({
          type: UPDATE_WORKFLOW_ERROR,
          payload: customError,
        })
      })
  }
