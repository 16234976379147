import axios from 'axios'
import { get, isEmpty } from 'lodash'
import { fetchCategories } from './categories'
import { fetchWorkflows } from '../workflows/workflows'

export const UPDATE_CATEGORY_BEGIN = 'UPDATE_CATEGORY_BEGIN'
export const UPDATE_CATEGORY_ERROR = 'UPDATE_CATEGORY_ERROR'
export const UPDATE_CATEGORY_SUCCESS = 'UPDATE_CATEGORY_SUCCESS'

export const updateCategory =
  (categoryData, categoryId, token) => async (dispatch, getState) => {
    const activeClient = getState().userSelectionsData.activeClient
    const activeClientId = get(activeClient, 'id', null)
    const activeOperatingLocation =
      getState().userSelectionsData.activeOperatingLocation
    const subcategories = get(categoryData, 'subcategories', [])
    const category = get(categoryData, 'category', null)
    const icon = get(categoryData, 'icon', null)
    const iconColour = get(categoryData, 'iconColour', null)

    const createCategoryPayload = {
      ...categoryData,
      category: category.trim(),
      icon: {
        id: icon,
        colour: iconColour,
      },
      subcategories: subcategories?.map(subcategory => subcategory?.label),
    }

    const subcategoryRenames = subcategories?.reduce((acc, cur) => {
      const { label, value, isNew } = cur
      if (!isNew && label !== value) {
        acc[value] = label
      }
      return acc
    }, {})

    dispatch({ type: UPDATE_CATEGORY_BEGIN })

    return axios({
      url: `${process.env.REACT_APP_PRODUCT_API}/api/v1/workflow/category/update/${categoryId}`,
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      data: {
        subcategoryRenames,
        category: createCategoryPayload,
        clientId: activeClientId,
        operatingLocation: activeOperatingLocation,
      },
    })
      .then(() => {
        dispatch({
          type: UPDATE_CATEGORY_SUCCESS,
        })
        dispatch(fetchCategories(token))
        dispatch(fetchWorkflows(token))
      })
      .catch(error => {
        const customError = get(error, 'response.data.error', 'Error')

        dispatch({
          type: UPDATE_CATEGORY_ERROR,
          payload: customError,
        })
      })
  }
