import PropTypes from 'prop-types'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { cloneDeep, set } from 'lodash'
import clsx from 'clsx'
import EditorFieldList from '../../editor-core/EditorFieldList'
import fixTransform from '../../../../utilities/workflows/fixTransform'
import getId from '../../../../utilities/editor/getId'
import { ReactComponent as ReorderIcon } from '../../../../assets/icons/reorder-icon.svg'

const EditorContentSubcomponent = ({
  subcomponent,
  index,
  dropPath,
  layout,
  onChange = values => {},
  onDeleteClick = subcomponent => {},
}) => {
  const dropLevel = 'subcomponents'

  const { fields } = subcomponent
  const id = getId(subcomponent)
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    setActivatorNodeRef,
  } = useSortable({
    id,
    data: {
      dropPath,
      dropLevel,
    },
  })

  const style = {
    transform: CSS.Transform.toString(fixTransform(transform)),
    transition,
  }

  function handleFieldChange(field) {
    const newValues = cloneDeep(subcomponent)
    const fieldIndex = newValues.fields.findIndex(
      item => getId(item) === getId(field),
    )
    set(newValues, ['fields', fieldIndex], field)
    onChange(newValues)
  }

  function handleFieldDelete(field) {
    onChange({
      ...subcomponent,
      fields: fields.filter(item => getId(item) !== getId(field)),
    })
  }

  return (
    <div
      className={clsx('card-subcomponent', layout)}
      ref={setNodeRef}
      style={style}
      {...attributes}
    >
      <div>
        <div ref={setActivatorNodeRef} className="drag-handle" {...listeners}>
          <ReorderIcon />
        </div>

        <EditorFieldList
          id={`${getId(subcomponent)}-fields`}
          key={`${getId(subcomponent)}-fields`}
          fields={fields}
          dropPath={[...dropPath, index, 'fields']}
          onFieldChange={handleFieldChange}
          onFieldDelete={handleFieldDelete}
        />
      </div>
    </div>
  )
}

EditorContentSubcomponent.propTypes = {
  subcomponent: PropTypes.object,
  index: PropTypes.number,
  dropPath: PropTypes.array,
  layout: PropTypes.string,
  onChange: PropTypes.func,
  onDeleteClick: PropTypes.func,
}

export default EditorContentSubcomponent
