import React from 'react'
import PropTypes from 'prop-types'

function StickyNotesIcon({ colour }, props) {
  return (
    <svg
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M33.4535 17.358H12.1327C11.8402 17.358 11.6031 17.5951 11.6031 17.8875C11.6031 18.18 11.8402 18.417 12.1327 18.417H33.4535C33.7459 18.417 33.983 18.18 33.983 17.8875C33.9829 17.595 33.7459 17.358 33.4535 17.358Z"
        fill={colour}
      />
      <path
        d="M33.4535 20.95H12.1327C11.8402 20.95 11.6031 21.1871 11.6031 21.4796C11.6031 21.772 11.8402 22.0091 12.1327 22.0091H33.4535C33.7459 22.0091 33.983 21.772 33.983 21.4796C33.983 21.1871 33.7459 20.95 33.4535 20.95Z"
        fill={colour}
      />
      <path
        d="M33.9829 25.0716C33.9829 24.7792 33.7458 24.5421 33.4534 24.5421H12.1327C11.8402 24.5421 11.6031 24.7792 11.6031 25.0716C11.6031 25.3641 11.8402 25.6012 12.1327 25.6012H33.4535C33.7459 25.6011 33.9829 25.3641 33.9829 25.0716Z"
        fill={colour}
      />
      <path
        d="M12.1327 28.1342C11.8402 28.1342 11.6031 28.3712 11.6031 28.6637C11.6031 28.9561 11.8402 29.1932 12.1327 29.1932H22.793C23.0855 29.1932 23.3226 28.9561 23.3226 28.6637C23.3226 28.3712 23.0855 28.1342 22.793 28.1342H12.1327Z"
        fill={colour}
      />
      <path
        d="M37.4705 12.2613C37.7629 12.2613 38 12.0242 38 11.7318V9.76723C38 8.77962 37.1965 7.97623 36.2089 7.97623H35.3323L35.4429 7.07932C35.5651 6.08862 34.8558 5.19066 33.8637 5.08006L7.21618 2.11019C6.24622 2.00191 5.35965 2.70223 5.24026 3.67099L2.79556 23.4952C2.75977 23.7854 2.96607 24.0497 3.25625 24.0855C3.27826 24.0882 3.30012 24.0895 3.32171 24.0895C3.58524 24.0895 3.81347 23.893 3.84659 23.6248L6.29129 3.80065C6.34009 3.40465 6.7022 3.11855 7.0989 3.16263L33.7464 6.13256C34.1518 6.17778 34.4418 6.54474 34.3918 6.94974L34.2653 7.9763H9.3771C8.38949 7.9763 7.58609 8.77976 7.58609 9.7673V31.2147L3.71026 30.7828C3.30476 30.7376 3.01486 30.3706 3.06472 29.9656L3.54369 26.0818C3.57947 25.7915 3.37318 25.5273 3.083 25.4914C2.79268 25.456 2.52851 25.6619 2.49265 25.9521L2.01368 29.836C1.89155 30.8265 2.60065 31.7246 3.5929 31.8352L7.58609 32.2803V36.1098C7.58609 37.0974 8.38956 37.9008 9.3771 37.9008H30.3039C30.7845 37.9008 31.2463 37.7007 31.5703 37.3762L37.4754 31.4711C37.8044 31.1422 38 30.6797 38 30.2046V14.2071C38 13.9147 37.7629 13.6776 37.4705 13.6776C37.178 13.6776 36.941 13.9147 36.941 14.2071V30.1977H31.9466C31.0369 30.1977 30.2968 30.9378 30.2968 31.8475V36.8419H9.3771C8.97343 36.8419 8.64507 36.5135 8.64507 36.1098C8.64507 34.9549 8.64507 10.9302 8.64507 9.76723C8.64507 9.36357 8.97343 9.03521 9.3771 9.03521H36.2089C36.6126 9.03521 36.941 9.36357 36.941 9.76723V11.7318C36.941 12.0243 37.1781 12.2613 37.4705 12.2613ZM31.3559 31.8476C31.3559 31.5218 31.6209 31.2568 31.9467 31.2568H36.1922L31.3559 36.0931V31.8476Z"
        fill={colour}
      />
      <path
        d="M24.9699 12.9691C24.9699 11.7688 23.9934 10.7923 22.7931 10.7923C21.5928 10.7923 20.6163 11.7688 20.6163 12.9691C20.6163 14.1694 21.5928 15.1458 22.7931 15.1458C23.9934 15.1459 24.9699 14.1694 24.9699 12.9691ZM21.6752 12.9691C21.6752 12.3527 22.1767 11.8513 22.7931 11.8513C23.4094 11.8513 23.9108 12.3527 23.9108 12.9691C23.9108 13.5855 23.4094 14.0869 22.7931 14.0869C22.1767 14.0869 21.6752 13.5855 21.6752 12.9691Z"
        fill={colour}
      />
    </svg>
  )
}

StickyNotesIcon.defaultProps = {
  colour: '#000000',
}

StickyNotesIcon.propTypes = {
  colour: PropTypes.string,
}

export default StickyNotesIcon
