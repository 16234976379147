import axios from 'axios'
import { get, isEmpty } from 'lodash'
import { fetchWorkflows } from './workflows'
import {
  UPDATE_WORKFLOW_BEGIN,
  UPDATE_WORKFLOW_SUCCESS,
  UPDATE_WORKFLOW_ERROR,
} from './updateWorkflow'
import { emitSocketMessage } from '../middleware/socket/socket'

export const CREATE_WORKFLOW_BEGIN = 'CREATE_WORKFLOW_BEGIN'
export const CREATE_WORKFLOW_ERROR = 'CREATE_WORKFLOW_ERROR'
export const CREATE_WORKFLOW_SUCCESS = 'CREATE_WORKFLOW_SUCCESS'

export const createWorkflow =
  (workflowData, successEvent, token) => async (dispatch, getState) => {
    const activeClient = getState().userSelectionsData.activeClient
    const activeClientId = get(activeClient, 'id', null)
    const activeOperatingLocation =
      getState().userSelectionsData.activeOperatingLocation
    const categories = getState().categoriesData.categories
    const clientCategories = get(categories, activeClientId, [])
    const selectedCategories = get(workflowData, 'categories', [])
    const workflowName = get(workflowData, 'name', null)
    const icon = get(workflowData, 'icon', null)
    const iconColour = get(workflowData, 'iconColour', null)

    const createPayload = {
      ...workflowData,
      name: workflowName.trim(),
      icon: {
        id: icon,
        colour: iconColour,
      },
      categories: !isEmpty(selectedCategories)
        ? selectedCategories.map(selectedCategory => {
            const category = get(selectedCategory, 'category', null)
            const subcategories = get(selectedCategory, 'subcategories', [])
            const clientCategoryData = clientCategories.find(
              clientCategory => clientCategory?._id === category,
            )

            return !isEmpty(clientCategoryData)
              ? {
                  ...clientCategoryData,
                  id: category,
                  subcategories: subcategories,
                }
              : null
          })
        : [],
    }

    dispatch({ type: CREATE_WORKFLOW_BEGIN })

    return axios({
      url: `${process.env.REACT_APP_PRODUCT_API}/api/v1/workflow/create/${activeClientId}`,
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      data: {
        operatingLocation: activeOperatingLocation,
        workflow: createPayload,
      },
    })
      .then(res => {
        dispatch({
          type: CREATE_WORKFLOW_SUCCESS,
        })
        dispatch(
          emitSocketMessage('createWorkflow', {
            workflowId: res?.data?._id,
          }),
        )
        dispatch(fetchWorkflows(token))
        successEvent()
      })
      .catch(error => {
        const customError = get(error, 'response.data.error', 'Error')

        dispatch({
          type: CREATE_WORKFLOW_ERROR,
          payload: customError,
        })
      })
  }

// REDUCER
const initialState = {
  error: null,
  loading: false,
}

export default function createWorkflowsReducer(state = initialState, action) {
  switch (action.type) {
    case UPDATE_WORKFLOW_BEGIN:
    case CREATE_WORKFLOW_BEGIN:
      return { ...state, loading: true }
    case UPDATE_WORKFLOW_SUCCESS:
    case CREATE_WORKFLOW_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
      }
    case UPDATE_WORKFLOW_ERROR:
    case CREATE_WORKFLOW_ERROR:
      return { ...state, loading: false, error: action.payload }
    default:
      return state
  }
}

// SELECTORS
export const selectCreateWorkflowsState = state => state.createWorkflowsData
