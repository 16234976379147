function getDefaultOpenAccordions({ component, highlight }) {
  let openAccordions = []
  if (highlight) {
    const accordions = component?.subComponents || []
    accordions.forEach(accordion => {
      const layouts = accordion?.layouts || []
      const fields = layouts.reduce((acc, cur) => {
        acc.push(...(cur?.fields || []))
        return acc
      }, [])
      fields.forEach(field => {
        if (field._id === highlight?.itemId) {
          openAccordions.push(accordion._id)
        }
      })
    })
  }
  return openAccordions
}

export default getDefaultOpenAccordions
