import React from 'react'
import PropTypes from 'prop-types'

function AddUserIcon({ colour }, props) {
  return (
    <svg
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M27.8447 20.0639C27.1529 19.7351 26.4471 19.4518 25.7288 19.2145C28.055 17.4685 29.5625 14.6882 29.5625 11.5625C29.5625 6.28977 25.2728 2 20 2C14.7272 2 10.4375 6.28977 10.4375 11.5625C10.4375 14.6917 11.9483 17.4747 14.2789 19.2203C12.1436 19.9233 10.1401 21.0171 8.38646 22.4595C5.17093 25.1045 2.9352 28.7937 2.09124 32.8477C1.82672 34.118 2.14362 35.4227 2.96051 36.4272C3.77339 37.4267 4.97876 38 6.26745 38H23.5859C24.3626 38 24.9922 37.3704 24.9922 36.5938C24.9922 35.8171 24.3626 35.1875 23.5859 35.1875H6.26745C5.66712 35.1875 5.30494 34.8524 5.14252 34.6527C4.86204 34.3078 4.75348 33.8589 4.84468 33.4209C6.30401 26.4111 12.487 21.2905 19.6272 21.1178C19.7509 21.1226 19.8751 21.125 20 21.125C20.126 21.125 20.2516 21.1225 20.3764 21.1177C22.5646 21.169 24.669 21.6684 26.6373 22.6041C27.3388 22.9374 28.1777 22.6392 28.5111 21.9377C28.8445 21.2362 28.5462 20.3973 27.8447 20.0639ZM20.343 18.3039C20.2288 18.3018 20.1145 18.3008 20 18.3008C19.8865 18.3008 19.773 18.3019 19.6597 18.304C16.0952 18.1262 13.25 15.1704 13.25 11.5625C13.25 7.84051 16.278 4.8125 20 4.8125C23.722 4.8125 26.75 7.84051 26.75 11.5625C26.75 15.1695 23.9062 18.1247 20.343 18.3039Z"
        fill={colour}
      />
      <path
        d="M36.5938 29.9141H32.7266V26.0469C32.7266 25.2702 32.097 24.6406 31.3203 24.6406C30.5436 24.6406 29.9141 25.2702 29.9141 26.0469V29.9141H26.0469C25.2702 29.9141 24.6406 30.5436 24.6406 31.3203C24.6406 32.097 25.2702 32.7266 26.0469 32.7266H29.9141V36.5938C29.9141 37.3704 30.5436 38 31.3203 38C32.097 38 32.7266 37.3704 32.7266 36.5938V32.7266H36.5938C37.3704 32.7266 38 32.097 38 31.3203C38 30.5436 37.3704 29.9141 36.5938 29.9141Z"
        fill={colour}
      />
    </svg>
  )
}

AddUserIcon.defaultProps = {
  colour: '#000000',
}

AddUserIcon.propTypes = {
  colour: PropTypes.string,
}

export default AddUserIcon
