import Modal from '../../../global-components/elements/Modal'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { ReactComponent as CloseModalIcon } from '../../../../assets/icons/close-icon.svg'
import MaterialLoader from '../../../global-components/elements/MaterialLoader'
import { Field, Form, Formik } from 'formik'
import TextField from '../../../forms/elements/TextField'
import CustomSelect from '../../../forms/elements/CustomSelect'

const ViewMyCategoriesAssignModal = ({
  isOpen = false,
  onRequestClose = () => {},
  workflows = [],
  categories = [],
  onSubmit = values => {},
  isLoading = false,
}) => {
  const initialValues = {
    category: null,
  }

  const categoryOptions =
    categories?.map(category => ({
      label: category.category,
      value: category._id,
    })) || []

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      modalClassName="modal--delete-category modal--sm"
    >
      <div className="modal__header">
        <h3>Delete Category</h3>

        <button className="modal__close" onClick={onRequestClose}>
          <CloseModalIcon />
        </button>
      </div>

      <Formik initialValues={initialValues} onSubmit={onSubmit}>
        {({ values, setFieldTouched }) => (
          <Form className="form">
            <div className="modal__body">
              <p className="warning warning--red">
                This category is current in use by {workflows?.length}{' '}
                {workflows?.length > 1 ? 'workflows' : 'workflow'}. Please
                choose a new category to replace the category being deleted.
              </p>
              <div>
                <div className="form__field">
                  <Field
                    component={CustomSelect}
                    label="Category"
                    name="category"
                    menuPlacement="top"
                    options={categoryOptions}
                    required={true}
                    isClearable={false}
                    setFieldTouched={setFieldTouched}
                  />
                </div>
              </div>
            </div>

            <div className="modal__submit">
              {isLoading ? (
                <MaterialLoader containerClasses="inline-loader" />
              ) : (
                <>
                  <button
                    className="btn btn--white"
                    onClick={onRequestClose}
                    type="button"
                  >
                    Cancel
                  </button>

                  <button
                    className="btn btn--red"
                    disabled={!values?.category}
                    type="submit"
                  >
                    Delete Category
                  </button>
                </>
              )}
            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  )
}

ViewMyCategoriesAssignModal.propTypes = {
  isOpen: PropTypes.bool,
  onRequestClose: PropTypes.func,
  workflows: PropTypes.arrayOf(PropTypes.object),
  onSubmit: PropTypes.func,
  categories: PropTypes.arrayOf(PropTypes.object),
  isLoading: PropTypes.bool,
}

export default ViewMyCategoriesAssignModal
