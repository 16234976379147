import { combineReducers } from 'redux'
import authReducer from './auth/auth'
import categoriesReducer from './categories/categories'
import checkClientIndexesReducer from './global-search/checkClientIndexes'
import createCategoryReducer from './categories/createCategory'
import createStepReducer from './steps/createStep'
import createWorkflowsReducer from './workflows/createWorkflow'
import deleteCategoryReducer from './categories/deleteCategory'
import deleteStepsReducer from './steps/deleteStep'
import deleteWorkflowsReducer from './workflows/deleteWorkflow'
import duplicateWorkflowReducer from './duplicate/duplicateContentReducers'
import mediaLibraryReducer from './media-library/mediaLibrary'
import mediaLibraryTreeReducer from './media-library/mediaLibraryTree'
import socketReducer from './socket/socket'
import userReducer from './user/user'
import userSelectionsReducer from './user/userSelections'
import workflowReducer from './workflows/workflow'
import workflowsReducer from './workflows/workflows'

const rootReducer = combineReducers({
  authData: authReducer,
  categoriesData: categoriesReducer,
  createCategoryData: createCategoryReducer,
  createStepData: createStepReducer,
  createWorkflowsData: createWorkflowsReducer,
  deleteCategoryData: deleteCategoryReducer,
  deleteStepsData: deleteStepsReducer,
  deleteWorkflowsData: deleteWorkflowsReducer,
  duplicateContentData: duplicateWorkflowReducer,
  mediaLibraryData: mediaLibraryReducer,
  mediaLibraryTreeData: mediaLibraryTreeReducer,
  searchIndexData: checkClientIndexesReducer,
  socketData: socketReducer,
  userData: userReducer,
  userSelectionsData: userSelectionsReducer,
  workflowData: workflowReducer,
  workflowsData: workflowsReducer,
})

export default rootReducer
