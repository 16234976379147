import React from 'react'
import { EDITOR_FIELDS } from '../../configuration/editor'
function renderConsumerField(fieldType) {
  const field = EDITOR_FIELDS.find(
    item => item.type === fieldType,
  )?.consumerField

  let ConsumerField = null

  if (field) {
    ConsumerField = field
  }

  return ConsumerField
}

export default renderConsumerField
