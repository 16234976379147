import { useDispatch, useSelector } from 'react-redux'
import {
  selectManageWorkflowModalVisibility,
  toggleManageWorkflowModal,
} from '../../../../redux/user/userSelections'
import Modal from '../../../global-components/elements/Modal'
import React from 'react'
import { ReactComponent as CloseModalIcon } from '../../../../assets/icons/close-icon.svg'
import { ReactComponent as SmileAlertIcon } from '../../../../assets/icons/message-smile-square-icon.svg'

const ManageWorkflowModalOutOfDate = () => {
  const dispatch = useDispatch()
  const isManageWorkflowModalVisible = useSelector(
    selectManageWorkflowModalVisibility,
  )

  function handleToggleCreateModal() {
    dispatch(toggleManageWorkflowModal(!isManageWorkflowModalVisible))
  }

  function handleRefresh() {
    window.location.reload()
  }

  return (
    <Modal
      modalClassName="modal--delete-workflow modal--sm"
      isOpen={true}
      onRequestClose={() => handleToggleCreateModal()}
    >
      <div className="modal__header">
        <h3 className="flex align-center">
          <SmileAlertIcon />
          Good News!
        </h3>
        <button
          className="modal__close"
          onClick={() => handleToggleCreateModal()}
        >
          <CloseModalIcon />
        </button>
      </div>
      <div className="modal__body">
        <br />
        <p>
          This workflow has recently been changed, please refresh your page to
          get a fresh copy.
        </p>
      </div>
      <div className="modal__submit">
        <button className="btn" onClick={() => handleToggleCreateModal()}>
          Close
        </button>
        <button className="btn btn--dark-purple" onClick={handleRefresh}>
          Refresh to Update
        </button>
      </div>
    </Modal>
  )
}

export default ManageWorkflowModalOutOfDate
