import axios from 'axios'
import { cloneDeep, get, isEmpty, merge } from 'lodash'
import { getUserApiRequirements } from '../user/user'
import { SET_ACTIVE_CLIENT } from '../user/userSelections'

export const FETCH_MEDIA_LIBRARY_BEGIN = 'FETCH_MEDIA_LIBRARY_BEGIN'
export const FETCH_MEDIA_LIBRARY_ERROR = 'FETCH_MEDIA_LIBRARY_ERROR'
export const FETCH_MEDIA_LIBRARY_SUCCESS = 'FETCH_MEDIA_LIBRARY_SUCCESS'
export const RESET_MEDIA_LIBRARY_FOLDER_DATA = 'RESET_MEDIA_LIBRARY_FOLDER_DATA'

export const SET_MEDIA_LIBRARY_IMAGE_DATA = 'SET_MEDIA_LIBRARY_IMAGE_DATA'

export const fetchMediaLibrary =
  (token, folderId, search) => async (dispatch, getState) => {
    const { activeClientId } = getUserApiRequirements(getState())

    dispatch({ type: FETCH_MEDIA_LIBRARY_BEGIN, folderId })

    return axios
      .post(
        `${process.env.REACT_APP_PRODUCT_API}/api/v1/media/${activeClientId}`,
        {
          folderId,
          search,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )
      .then(response => {
        const data = reduceImages(response?.data?.data)
        dispatch({
          type: FETCH_MEDIA_LIBRARY_SUCCESS,
          folderId,
          payload: data,
        })
        return data
      })
      .catch(error => {
        dispatch({
          type: FETCH_MEDIA_LIBRARY_ERROR,
          payload: error.message,
          folderId,
        })
      })
  }

export const mediaLibraryMoveFiles =
  ({ token, fromFolderId, toFolderId, ids }) =>
  async (dispatch, getState) => {
    const { activeClientId } = getUserApiRequirements(getState())
    dispatch({ type: FETCH_MEDIA_LIBRARY_BEGIN, folderId: fromFolderId })

    return axios
      .post(
        `${process.env.REACT_APP_PRODUCT_API}/api/v1/media/move/${activeClientId}`,
        {
          folderId: toFolderId,
          ids,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )
      .then(() => {
        dispatch({
          type: RESET_MEDIA_LIBRARY_FOLDER_DATA,
          folderId: toFolderId,
        })
        return dispatch(fetchMediaLibrary(token, fromFolderId))
      })
      .catch(error => {
        dispatch({
          type: FETCH_MEDIA_LIBRARY_ERROR,
          payload: error.message,
          folderId: fromFolderId,
        })
      })
  }

export const mediaLibraryDeleteFiles =
  ({ token, folderId, ids }) =>
  async (dispatch, getState) => {
    const { activeClientId } = getUserApiRequirements(getState())
    dispatch({ type: FETCH_MEDIA_LIBRARY_BEGIN, folderId: folderId })

    return axios
      .post(
        `${process.env.REACT_APP_PRODUCT_API}/api/v1/media/delete/${activeClientId}`,
        {
          ids,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )
      .then(() => {
        return dispatch(fetchMediaLibrary(token, folderId))
      })
      .catch(error => {
        dispatch({
          type: FETCH_MEDIA_LIBRARY_ERROR,
          payload: error.message,
          folderId,
        })
      })
  }

export const updateMediaLibraryImage =
  ({ token, id, image }) =>
  async (dispatch, getState) => {
    const { activeClientId } = getUserApiRequirements(getState())

    const formData = new FormData()
    formData.append('file', image.file)
    formData.append('name', image.name)
    formData.append('alt', image.alt)
    formData.append('caption', image.caption)
    formData.append('id', id)
    formData.append('clientId', activeClientId)

    return axios
      .post(
        `${process.env.REACT_APP_PRODUCT_API}/api/v1/media/update/${activeClientId}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )
      .then(res => {
        const data = res?.data
        if (data) {
          dispatch({
            type: SET_MEDIA_LIBRARY_IMAGE_DATA,
            payload: {
              _id: data._id,
              name: data.name,
              alt: data.alt,
              caption: data.caption,
            },
          })
        }
      })
  }

function reduceImages(data) {
  return data.length > 0
    ? data.map(image => ({
        ...image,
        thumbnail: image.thumbnail
          ? image.thumbnail
          : {
              type: 'thumbnail',
              url: image.url,
            },
      }))
    : []
}

// REDUCER
const initialState = {}
const initialFolderState = {
  loading: false,
  data: null,
  error: null,
}

export default function mediaLibraryReducer(state = initialState, action) {
  const folderId = action.folderId
  const folder = state?.[folderId] || {}
  switch (action.type) {
    case FETCH_MEDIA_LIBRARY_BEGIN:
      return {
        ...state,
        [folderId]: { ...initialFolderState, ...folder, loading: true },
      }
    case FETCH_MEDIA_LIBRARY_SUCCESS:
      return {
        ...state,
        [folderId]: { ...folder, loading: false, data: action.payload },
      }

    case FETCH_MEDIA_LIBRARY_ERROR:
      return {
        ...state,
        [folderId]: { ...folder, loading: false, error: action.payload },
      }
    case RESET_MEDIA_LIBRARY_FOLDER_DATA:
      return {
        ...state,
        [folderId]: { ...initialFolderState },
      }
    case SET_MEDIA_LIBRARY_IMAGE_DATA:
      return state
      return {
        ...state,
        data: state.data.map(image => {
          if (image._id === action.payload._id) {
            return {
              ...image,
              name: action.payload.name,
              alt: action.payload.alt,
              caption: action.payload.caption,
            }
          }
          return image
        }),
      }
    case SET_ACTIVE_CLIENT:
      return initialState
    default:
      return state
  }
}

// SELECTORS
export const selectMediaLibraryState = state => state.mediaLibraryData
export const selectMediaLibraryFolderState = (state, folderId) =>
  state.mediaLibraryData?.[folderId] || initialFolderState

export const selectMediaLibraryLoading = state =>
  get(state, 'mediaLibraryData.loading', false)
export const selectMediaLibrary = state =>
  get(state, 'mediaLibraryData.data', [])
