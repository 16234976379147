import React, { useState, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { get, isEmpty } from 'lodash'
import { selectWorkflowsState } from '../../../redux/workflows/workflows'
import {
  selectActiveClient,
  selectActiveClientId,
} from '../../../redux/user/userSelections'
import { useGlobalFilter, useSortBy, useTable } from 'react-table'
import { Link } from 'react-router-dom'
import { selectCategories } from '../../../redux/categories/categories'
import formatWorkflowsCategoriesToTableData from '../../../utilities/categories/formatWorkflowsCategoriesToTableData'
import globalSearchFilter from '../../../utilities/table/globalSearchFilter'
import MaterialLoader from '../../global-components/elements/MaterialLoader'
import ViewMyCategoriesDeleteModal from './elements/ViewMyCategoriesDeleteModal'
import { ReactComponent as DeleteIcon } from '../../../assets/icons/delete-icon.svg'
import { ReactComponent as EditIcon } from '../../../assets/icons/pencil-icon.svg'
import { ReactComponent as FilterIconDefault } from '../../../assets/icons/table-sort-arrows-default.svg'
import { ReactComponent as FilterIconAscending } from '../../../assets/icons/table-sort-arrows-ascending.svg'
import { ReactComponent as FilterIconDescending } from '../../../assets/icons/table-sort-arrows-descending.svg'
import { ReactComponent as AddIcon } from '../../../assets/icons/plus-icon.svg'
import '../../../styles/consumer-flow/view-my-workflows/view-my-categories-table.scss'
import ManageCategoryModal from '../../editor-flow/create-workflow/ManageCategoryModal'
import {
  DELETE_CATEGORY_BEGIN,
  DELETE_CATEGORY_SET_LOADING,
  DELETE_CATEGORY_SUCCESS,
  deleteCategory,
  selectDeleteCategoryState,
} from '../../../redux/categories/deleteCategory'
import useAuthToken from '../../../hooks/useAuthToken'
import { fetchWorkflowsUsingCategory } from '../../../redux/categories/fetchWorkflowsUsingCategory'
import ViewMyCategoriesAssignModal from './elements/ViewMyCategoriesAssignModal'

function ViewMyCategoriesTable() {
  const { token } = useAuthToken({})
  const dispatch = useDispatch()
  const [categoryToEdit, setCategoryToEdit] = useState(null)
  const [categoryModalIsOpen, setCategoryModalIsOpen] = useState(null)
  const activeClient = useSelector(selectActiveClient)
  const activeClientId = useSelector(selectActiveClientId)
  const workflowsState = useSelector(selectWorkflowsState)
  const categoriesData = useSelector(selectCategories)
  const deletedCategoryState = useSelector(selectDeleteCategoryState)

  const [isDeleteModalOpen, toggleDeleteModal] = useState(false)
  const [deletedCategory, setDeletedCategory] = useState({})
  const [isAssignModalOpen, setIsAssignModalOpen] = useState(false)
  const [deletedCategoryWorkflows, setDeletedCategoryWorkflows] = useState([])
  const clientName = get(activeClient, 'name', null)
  const categoriesByClient = get(categoriesData, activeClientId, [])

  function handleToggleDeleteCategory(categoryId) {
    const categoryData = categoriesByClient.find(category => {
      const id = get(category, '_id', null)
      return id === categoryId
    })

    if (categoryData) {
      setDeletedCategory(categoryData)
      toggleDeleteModal(!isDeleteModalOpen)
    }
  }

  function handleEditClick(row) {
    const category = categoriesByClient.find(search => search?._id === row.edit)
    setCategoryToEdit(category)
    setCategoryModalIsOpen(true)
  }

  const columns = useMemo(
    () => [
      {
        Header: '',
        accessor: 'icon',
        disableSortBy: true,
        Cell: props => {
          const value = get(props, 'cell.value', null)

          return value
        },
      },
      {
        Header: 'Category',
        accessor: 'category',
      },
      {
        Header: 'Subcategories',
        accessor: 'subcategories',
      },
      {
        Header: 'Last Modified',
        accessor: 'dateModified',
      },
      {
        Header: '',
        accessor: 'edit',
        disableSortBy: true,
        Cell: props => {
          const categoryId = get(props, 'cell.value', null)
          const editedCategory = get(props, 'row.original', {})

          return categoryId ? (
            <button
              className="btn--transparent btn--no-border"
              onClick={() => handleEditClick(editedCategory)}
              data-tooltip="Edit Category"
            >
              <EditIcon />
            </button>
          ) : null
        },
      },
      {
        Header: '',
        accessor: 'delete',
        disableSortBy: true,
        Cell: props => {
          const id = get(props, 'cell.value', null)

          return id ? (
            <button
              className="btn--transparent btn--no-border"
              onClick={() => handleToggleDeleteCategory(id)}
              data-tooltip="Delete Category"
            >
              <DeleteIcon />
            </button>
          ) : null
        },
      },

      {
        Header: '',
        accessor: 'view',
        disableSortBy: true,
        Cell: props => {
          const path = get(props, 'cell.value', null)

          return path ? (
            <Link className="btn btn--dark-purple" to={path}>
              Edit Category
            </Link>
          ) : null
        },
      },
    ],
    [],
  )

  const data = useMemo(
    () => formatWorkflowsCategoriesToTableData(categoriesByClient),
    [categoriesByClient],
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      globalSearchFilter,
      initialState: {
        sortBy: [
          {
            id: 'category',
          },
        ],
      },
    },
    useGlobalFilter,
    useSortBy,
  )

  function handleCategoryModalClose() {
    setCategoryToEdit(null)
    setCategoryModalIsOpen(null)
  }

  async function handleDeleteCategoryClick() {
    const categoryId = deletedCategory?._id
    const workflows = await dispatch(
      fetchWorkflowsUsingCategory(categoryId, token),
    )
    if (workflows?.length > 0) {
      setDeletedCategoryWorkflows(workflows)
      setIsAssignModalOpen(true)
      toggleDeleteModal(false)
      dispatch({
        type: DELETE_CATEGORY_SET_LOADING,
        payload: false,
      })
    } else {
      dispatch(
        deleteCategory({
          categoryId,
          token,
        }),
      )
    }
  }

  function handleCloseAssignModal() {
    setIsAssignModalOpen(false)
    setDeletedCategoryWorkflows([])
  }

  function handleAssignModalSubmit(values) {
    const categoryId = deletedCategory?._id
    dispatch(
      deleteCategory({
        categoryId,
        token,
        replacementCategoryId: values.category,
      }),
    )
  }

  return workflowsState.loading ? (
    <MaterialLoader containerClasses="container-loader" />
  ) : (
    <div className="view-my-categories__table">
      <div className="table__filters">
        <div className="table__filters-row">
          <div className="table__filters-content">
            <p className="title">
              {clientName ? `${clientName} Categories` : 'View All Categories'}
            </p>

            <div className="table__filters-counts">
              <p>
                <span>{data.length}</span> Categories
              </p>
            </div>
          </div>

          <div className="table__filters-actions">
            <input
              type="text"
              className="filters-search"
              name="search-users"
              onChange={e => setGlobalFilter(e.currentTarget.value)}
              placeholder="Search Categories"
            />

            <button
              className="btn btn--bright-purple"
              onClick={() => setCategoryModalIsOpen(true)}
            >
              <AddIcon />
              Add Category
            </button>
          </div>
        </div>
      </div>

      {!isEmpty(categoriesByClient) ? (
        <>
          <div className="table scroll-x">
            <div className="table__container">
              <table {...getTableProps()}>
                <thead>
                  {headerGroups.map((headerGroup, i) => (
                    <tr
                      key={`my-workflows-thead-tr-${i}`}
                      {...headerGroup.getHeaderGroupProps()}
                    >
                      {headerGroup?.headers?.map((column, i) => {
                        const canSort = column.canSort
                        const isSorted = column.isSorted
                        const isSortedDesc = column.isSortedDesc

                        return (
                          <th
                            key={`users-thead-th-${i}`}
                            {...column.getHeaderProps()}
                            style={{ verticalAlign: 'middle' }}
                          >
                            <div {...column.getSortByToggleProps()}>
                              {column.render('Header')}
                              {canSort && !isSorted && !isSortedDesc ? (
                                <FilterIconDefault className="filter-icon" />
                              ) : canSort && isSorted && !isSortedDesc ? (
                                <FilterIconAscending className="descending-icon" />
                              ) : canSort && isSortedDesc && isSortedDesc ? (
                                <FilterIconDescending className="ascending-icon" />
                              ) : (
                                ''
                              )}
                            </div>
                          </th>
                        )
                      })}
                    </tr>
                  ))}
                </thead>

                <tbody {...getTableBodyProps()}>
                  {rows?.map((row, i) => {
                    prepareRow(row)

                    return (
                      <tr
                        key={`my-workflows-tbody-tr-${i}`}
                        {...row.getRowProps()}
                      >
                        {row.cells.map((cell, i) => {
                          return (
                            <td
                              className={cell.column.id}
                              key={`my-workflows-tbody-td-${i}`}
                              {...cell.getCellProps()}
                            >
                              {cell.render('Cell')}
                            </td>
                          )
                        })}
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
          </div>

          {deletedCategory && isDeleteModalOpen ? (
            <ViewMyCategoriesDeleteModal
              deletedCategory={deletedCategory}
              isDeleteModalOpen={isDeleteModalOpen}
              setDeletedCategory={setDeletedCategory}
              toggleDeleteModal={toggleDeleteModal}
              onDeleteClick={handleDeleteCategoryClick}
            />
          ) : null}

          {isAssignModalOpen ? (
            <ViewMyCategoriesAssignModal
              isOpen={isAssignModalOpen}
              onRequestClose={handleCloseAssignModal}
              workflows={deletedCategoryWorkflows}
              categories={categoriesByClient.filter(
                category => category._id !== deletedCategory._id,
              )}
              onSubmit={handleAssignModalSubmit}
              isLoading={deletedCategoryState?.loading}
            />
          ) : null}
        </>
      ) : null}

      {categoryModalIsOpen ? (
        <ManageCategoryModal
          isOpen={categoryModalIsOpen}
          category={categoryToEdit}
          onRequestClose={handleCategoryModalClose}
          onSubmit={handleCategoryModalClose}
        />
      ) : null}
    </div>
  )
}

ViewMyCategoriesTable.propTypes = {}

export default ViewMyCategoriesTable
