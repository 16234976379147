import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { get, sortBy } from 'lodash'
import useAuthToken from '../../../hooks/useAuthToken'
import { useLocation, useNavigate } from 'react-router-dom'
import { mixpanelTrackClient } from '../../../utilities/analytics/mixpanelEvents'
import { setActiveClient } from '../../../redux/user/userSelections'
import { selectUserClients, selectUserIsStaff } from '../../../redux/user/user'
import { selectActiveClient } from '../../../redux/user/userSelections'
import {
  fetchWorkflows,
  selectWorkflowsState,
} from '../../../redux/workflows/workflows'
import { fetchCategories } from '../../../redux/categories/categories'
import { updateStaffDefaultClient } from '../../../redux/admin/updateStaffDefaultClient'
import ClickAwayListener from '../elements/ClickAwayListener'
import handleClientRouteEvents from '../../../utilities/routes/handleClientRouteEvents'
import MaterialLoader from '../elements/MaterialLoader'
import ClientPlaceholderIcon from '../../../assets/icons/client-placeholder-icon.svg'
import '../../../styles/global-components/clients-field/clients-field.scss'

function ClientsField() {
  const { token } = useAuthToken({})
  const [isClientsToggled, toggleClients] = useState(false)
  const dispatch = useDispatch()
  const location = useLocation()
  const navigate = useNavigate()
  const userClients = useSelector(selectUserClients)
  const activeClient = useSelector(selectActiveClient)
  const workflowsState = useSelector(selectWorkflowsState)
  const isFetchingClientContent = workflowsState.loading
  const activeClientId = get(activeClient, 'id', null)
  const activeClientLogo = get(activeClient, 'logo.url', ClientPlaceholderIcon)
  const activeClientName = get(activeClient, 'name', null)
  const clientOptions = []

  const handleUpdateClient = async client => {
    const clientId = get(client, 'id', null)
    const clientName = get(client, 'name', null)

    toggleClients(!isClientsToggled)
    mixpanelTrackClient(clientId, clientName, 'User Input')

    updateStaffDefaultClient(clientId, token)
    await dispatch(setActiveClient(client))
    await handleClientRouteEvents(location, navigate)
    await dispatch(fetchWorkflows(token))
    await dispatch(fetchCategories(token))
  }

  // Sorted on FE to maintain API order + default client logic
  const sortedClients = sortBy(userClients, ['name'])

  for (let i = 0; i < sortedClients.length; i++) {
    const client = sortedClients[i]
    const clientId = get(client, 'id', null)
    const clientName = get(client, 'name', null)
    const clientLogo = get(client, 'logo.url', ClientPlaceholderIcon)
    const isActiveClient = clientId === activeClientId

    if (clientId && clientName) {
      clientOptions.push(
        <button
          className={`toggle${isActiveClient ? ' active' : ''}`}
          key={clientId}
          onClick={!isActiveClient ? () => handleUpdateClient(client) : null}
        >
          <img src={clientLogo} alt={clientName} />
          <span className="name">{clientName}</span>
        </button>,
      )
    }
  }

  return clientOptions ? (
    <ClickAwayListener toggleEvent={() => toggleClients(false)}>
      <div className="clients-field">
        <button
          className={`clients-field__toggle${
            isClientsToggled ? ' toggled' : ''
          }`}
          onClick={() => toggleClients(!isClientsToggled)}
          disabled={isFetchingClientContent}
        >
          {isFetchingClientContent ? (
            <MaterialLoader />
          ) : (
            <>
              <img
                className="icon"
                src={activeClientLogo}
                alt={activeClientName}
              />
            </>
          )}
        </button>

        {isClientsToggled && (
          <div
            className={`clients-field__menu${
              isClientsToggled ? ' toggled' : ''
            }${userClients.length > 4 ? ' scroll' : ''}`}
          >
            {clientOptions}
          </div>
        )}
      </div>
    </ClickAwayListener>
  ) : null
}

export default ClientsField
