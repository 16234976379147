import React from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'
import '../../../styles/global-components/forms/form-stepper.scss'
function FormStepper({ steps, activeStep, setActiveStep }) {
  return (
    <div className="form-stepper">
      {steps.map((step, i) => {
        const title = get(step, 'title', null)
        const isActiveStep = i === activeStep

        return (
          <div
            className={`form-stepper__step${isActiveStep ? ' active' : ''}`}
            key={`form-step-${i}`}
            onClick={!isActiveStep ? () => setActiveStep(i) : null}
            style={{ width: `${100 / steps.length}%` }}
            tabIndex={0}
            type="button"
          >
            <span
              className={`form-stepper__step-number${
                isActiveStep ? ' active' : ''
              }`}
            >
              {Number(i) + 1}
            </span>{' '}
            {title}
          </div>
        )
      })}
    </div>
  )
}

FormStepper.propTypes = {
  activeStep: PropTypes.number,
  setActiveStep: PropTypes.func,
  steps: PropTypes.array,
}
export default FormStepper
