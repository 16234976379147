import { get } from 'lodash'
import { checkClientIndexes } from '../global-search/checkClientIndexes'

export const SET_ACTIVE_CLIENT = 'SET_ACTIVE_CLIENT'
export const SET_ACTIVE_OPERATING_LOCATION = 'SET_ACTIVE_OPERATING_LOCATION'
export const SET_MANAGED_CONTENT_ID = 'SET_MANAGED_CONTENT_ID'
export const SET_DUPLICATED_CONTENT_DATA = 'SET_DUPLICATED_CONTENT_DATA'
export const TOGGLE_LIVE_CHAT = 'TOGGLE_LIVE_CHAT'
export const TOGGLE_DUPLICATE_COMPONENT_MODAL =
  'TOGGLE_DUPLICATE_COMPONENT_MODAL'
export const TOGGLE_DUPLICATE_STEP_MODAL = 'TOGGLE_DUPLICATE_STEP_MODAL'
export const TOGGLE_DUPLICATE_WORKFLOW_MODAL = 'TOGGLE_DUPLICATE_WORKFLOW_MODAL'
export const TOGGLE_MANAGE_CATEGORY_MODAL = 'TOGGLE_MANAGE_CATEGORY_MODAL'
export const TOGGLE_MANAGE_STEP_MODAL = 'TOGGLE_MANAGE_STEP_MODAL'
export const TOGGLE_MANAGE_WORKFLOW_MODAL = 'TOGGLE_MANAGE_WORKFLOW_MODAL'
export const TOGGLE_MEDIA_LIBRARY_MODAL = 'TOGGLE_MEDIA_LIBRARY_MODAL'

export const TOGGLE_POWER_SEARCH_MODAL = 'TOGGLE_POWER_SEARCH_MODAL'

export function setActiveClient(activeClient) {
  return (dispatch, getState) => {
    const activeClientId = get(activeClient, 'id', null)
    const currentOperatingLocation = get(
      getState(),
      'userSelectionsData.activeOperatingLocation',
      null,
    )

    dispatch({ type: SET_ACTIVE_CLIENT, payload: activeClient })

    // If the currently selected operating location is not found
    // when toggling a new client, dispatch the defaultOperatingLocation
    // for the newly selected client as the new current operating location
    if (currentOperatingLocation) {
      const activeOperatingLocations = get(
        activeClient,
        'operatingLocations',
        [],
      )
      const fallbackOperatingLocation = get(
        activeOperatingLocations,
        '[0].code',
        'NS',
      )

      const defaultOperatingLocation = get(
        activeClient,
        'defaultOperatingLocation.code',
        fallbackOperatingLocation,
      )

      const isAvailableLocation = activeOperatingLocations.some(
        location => location.code === currentOperatingLocation,
      )

      if (!isAvailableLocation) {
        dispatch(setActiveOperatingLocation(defaultOperatingLocation))
        dispatch(checkClientIndexes(activeClientId, defaultOperatingLocation))
      } else {
        dispatch(setActiveOperatingLocation(currentOperatingLocation))
        dispatch(checkClientIndexes(activeClientId, currentOperatingLocation))
      }
    }
  }
}

export const setActiveOperatingLocation =
  activeOperatingLocation => dispatch => {
    dispatch({
      type: SET_ACTIVE_OPERATING_LOCATION,
      payload: activeOperatingLocation,
    })
  }

export const toggleLiveChat = visibility => dispatch => {
  dispatch({
    type: TOGGLE_LIVE_CHAT,
    payload: visibility,
  })
}

export const toggleDuplicateComponentModal = visibility => dispatch => {
  dispatch({
    type: TOGGLE_DUPLICATE_COMPONENT_MODAL,
    payload: visibility,
  })

  if (!visibility) {
    dispatch({
      type: SET_DUPLICATED_CONTENT_DATA,
      payload: null,
    })
  }
}

export const toggleDuplicateStepModal = visibility => dispatch => {
  dispatch({
    type: TOGGLE_DUPLICATE_STEP_MODAL,
    payload: visibility,
  })

  if (!visibility) {
    dispatch({
      type: SET_DUPLICATED_CONTENT_DATA,
      payload: null,
    })
  }
}

export const toggleDuplicateWorkflowModal = visibility => dispatch => {
  dispatch({
    type: TOGGLE_DUPLICATE_WORKFLOW_MODAL,
    payload: visibility,
  })

  if (!visibility) {
    dispatch({
      type: SET_DUPLICATED_CONTENT_DATA,
      payload: null,
    })
  }
}

export const toggleManageWorkflowModal = visibility => dispatch => {
  dispatch({
    type: TOGGLE_MANAGE_WORKFLOW_MODAL,
    payload: visibility,
  })

  if (!visibility) {
    dispatch({
      type: SET_MANAGED_CONTENT_ID,
      payload: null,
    })
  }
}

export const toggleMediaLibraryModal = visibility => dispatch => {
  dispatch({
    type: TOGGLE_MEDIA_LIBRARY_MODAL,
    payload: visibility,
  })
}

export const toggleManageCategoryModal = visibility => dispatch => {
  dispatch({
    type: TOGGLE_MANAGE_CATEGORY_MODAL,
    payload: visibility,
  })

  if (!visibility) {
    dispatch({
      type: SET_MANAGED_CONTENT_ID,
      payload: null,
    })
  }
}

export const toggleManageStepModal = visibility => dispatch => {
  dispatch({
    type: TOGGLE_MANAGE_STEP_MODAL,
    payload: visibility,
  })

  if (!visibility) {
    dispatch({
      type: SET_MANAGED_CONTENT_ID,
      payload: null,
    })
  }
}

export const setManagedContentId = id => dispatch => {
  dispatch({
    type: SET_MANAGED_CONTENT_ID,
    payload: id,
  })
}

export const setDuplicatedContentData = duplicatedContent => dispatch => {
  dispatch({
    type: SET_DUPLICATED_CONTENT_DATA,
    payload: duplicatedContent,
  })
}

export const togglePowerSearchModal = visibility => dispatch => {
  dispatch({
    type: TOGGLE_POWER_SEARCH_MODAL,
    payload: visibility,
  })
}

const initialState = {
  activeClient: null,
  activeOperatingLocation: null,
  duplicateComponentModalVisible: false,
  duplicatedContentData: null,
  duplicateStepModalVisible: false,
  duplicateWorkflowModalVisible: false,
  liveChatVisible: false,
  manageCategoryModalVisible: false,
  managedContentId: null,
  manageStepModalVisible: false,
  manageWorkflowModalVisible: false,
  powerSearchModalVisible: false,
}

export default function userSelectionsReducer(state = initialState, action) {
  switch (action.type) {
    case SET_ACTIVE_CLIENT:
      return {
        ...state,
        activeClient: action.payload,
      }
    case SET_ACTIVE_OPERATING_LOCATION:
      return {
        ...state,
        activeOperatingLocation: action.payload,
      }
    case SET_DUPLICATED_CONTENT_DATA:
      return {
        ...state,
        duplicatedContentData: action.payload,
      }
    case SET_MANAGED_CONTENT_ID:
      return {
        ...state,
        managedContentId: action.payload,
      }
    case TOGGLE_DUPLICATE_COMPONENT_MODAL:
      return {
        ...state,
        duplicateComponentModalVisible: action.payload,
      }
    case TOGGLE_DUPLICATE_STEP_MODAL:
      return {
        ...state,
        duplicateStepModalVisible: action.payload,
      }
    case TOGGLE_DUPLICATE_WORKFLOW_MODAL:
      return {
        ...state,
        duplicateWorkflowModalVisible: action.payload,
      }
    case TOGGLE_MANAGE_CATEGORY_MODAL:
      return {
        ...state,
        manageCategoryModalVisible: action.payload,
      }
    case TOGGLE_MANAGE_STEP_MODAL:
      return {
        ...state,
        manageStepModalVisible: action.payload,
      }
    case TOGGLE_MANAGE_WORKFLOW_MODAL:
      return {
        ...state,
        manageWorkflowModalVisible: action.payload,
      }
    case TOGGLE_MEDIA_LIBRARY_MODAL:
      return {
        ...state,
        mediaLibraryModalVisible: action.payload,
      }
    case TOGGLE_LIVE_CHAT:
      return {
        ...state,
        liveChatVisible: action.payload,
      }
    case TOGGLE_POWER_SEARCH_MODAL:
      return {
        ...state,
        powerSearchModalVisible: action.payload,
      }
    default:
      return state
  }
}

// SELECTORS
export const selectActiveClient = state =>
  get(state, 'userSelectionsData.activeClient', null)
export const selectActiveClientId = state =>
  get(state, 'userSelectionsData.activeClient.id', null)
export const selectActiveOperatingLocation = state =>
  get(state, 'userSelectionsData.activeOperatingLocation', null)
export const selectLiveChatVisibility = state =>
  get(state, 'userSelectionsData.liveChatVisible', null)
export const selectDuplicatedContentData = state =>
  get(state, 'userSelectionsData.duplicatedContentData', null)
export const selectDuplicatedComponentVisibility = state =>
  get(state, 'userSelectionsData.duplicateComponentModalVisible', null)
export const selectDuplicatedStepVisibility = state =>
  get(state, 'userSelectionsData.duplicateStepModalVisible', null)
export const selectDuplicatedWorkflowVisibility = state =>
  get(state, 'userSelectionsData.duplicateWorkflowModalVisible', null)
export const selectManagedContentId = state =>
  get(state, 'userSelectionsData.managedContentId', null)
export const selectManageCategoryModalVisibility = state =>
  get(state, 'userSelectionsData.manageCategoryModalVisible', false)
export const selectManageStepModalVisibility = state =>
  get(state, 'userSelectionsData.manageStepModalVisible', false)
export const selectManageWorkflowModalVisibility = state =>
  get(state, 'userSelectionsData.manageWorkflowModalVisible', false)
export const selectPowerSearchModalVisibility = state =>
  get(state, 'userSelectionsData.powerSearchModalVisible', false)

export const selectMediaLibraryModalVisibility = state =>
  get(state, 'userSelectionsData.mediaLibraryModalVisible', false)

export const selectBrandLinkColour = state =>
  get(state, 'userSelectionsData.activeClient.brandDefaultLinkColour')
