import React from 'react'
import PropTypes from 'prop-types'

function RenewIcon({ colour }, props) {
  return (
    <svg
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M19.9999 4.69977C12.0468 4.69977 5.59971 11.1469 5.59971 19.1C5.59971 19.2878 5.61532 19.4708 5.6279 19.6556L3.53628 17.564C3.18464 17.2124 2.61524 17.2124 2.26368 17.564C1.91211 17.9156 1.91211 18.485 2.26368 18.8366L5.86373 22.4367C6.03896 22.6124 6.26937 22.7001 6.49972 22.7001C6.73007 22.7001 6.96056 22.6124 7.13634 22.4367L10.7364 18.8366C11.088 18.485 11.088 17.9156 10.7364 17.564C10.3848 17.2124 9.81536 17.2124 9.46379 17.564L7.43398 19.5938C7.42561 19.4282 7.3998 19.2674 7.3998 19.1C7.3998 12.1525 13.0525 6.4998 20 6.4998C25.8285 6.4998 30.7306 10.4833 32.164 15.8659C32.5678 15.6337 33.022 15.4999 33.5002 15.4999C33.649 15.4999 33.7948 15.5191 33.9394 15.5431C32.3535 9.31389 26.723 4.69977 19.9999 4.69977Z"
        fill={colour}
      />
      <path
        d="M37.7368 21.1634L34.1368 17.5634C33.961 17.3876 33.7306 17.3 33.5002 17.3C33.2697 17.3 33.0393 17.3876 32.8635 17.5634L29.2635 21.1634C28.9119 21.515 28.9119 22.0844 29.2635 22.436C29.6151 22.7877 30.1845 22.7877 30.5361 22.436L32.5659 20.4062C32.5743 20.5718 32.6001 20.7326 32.6001 20.9C32.6001 27.8475 26.9474 33.5002 19.9999 33.5002C14.1714 33.5002 9.26932 29.5167 7.8359 24.1341C7.43209 24.3657 6.97787 24.5001 6.49966 24.5001C6.35088 24.5001 6.20505 24.4809 6.06049 24.4569C7.64634 30.6862 13.2768 35.3002 19.9999 35.3002C27.953 35.3002 34.4001 28.8531 34.4001 20.9C34.4001 20.7122 34.3845 20.5292 34.3719 20.3444L36.4635 22.436C36.8151 22.7877 37.3846 22.7877 37.7361 22.436C38.0878 22.0844 38.0879 21.515 37.7368 21.1634Z"
        fill={colour}
      />
    </svg>
  )
}

RenewIcon.defaultProps = {
  colour: '#000000',
}

RenewIcon.propTypes = {
  colour: PropTypes.string,
}

export default RenewIcon
