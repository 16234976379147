import { get } from 'lodash'
import OperatingLocationPlaceholder from '../../assets/icons/operating-locations-placeholder-icon.svg'

const getOperatingLocationIcon = (
  operatingLocations,
  activeOperatingLocation,
) => {
  let activeLocationIcon = null

  operatingLocations.forEach(location => {
    const locationIcon = get(location, 'icon.url', OperatingLocationPlaceholder)
    const locationCode = get(location, 'code', null)

    if (locationCode === activeOperatingLocation) {
      activeLocationIcon = locationIcon
    }
  })

  return activeLocationIcon
}

export default getOperatingLocationIcon
