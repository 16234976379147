import Modal from '../../global-components/elements/Modal'
import React from 'react'
import PropTypes from 'prop-types'

const PublishWorkflowStepsModal = ({ isOpen, onSubmit }) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => onSubmit(false)}
      modalClassName=" modal--sm"
    >
      <div className="modal__header">
        <h3>Publish Steps?</h3>
      </div>
      <div className="modal__body">
        <p>
          There are currently Steps in draft, would you like to publish them
          now?
        </p>
      </div>
      <div className="modal__submit">
        <button
          className="btn btn--white"
          type="button"
          onClick={() => onSubmit(false)}
        >
          Leave Steps in Draft
        </button>
        <button
          className="btn btn--dark-purple"
          type="button"
          onClick={() => onSubmit(true)}
        >
          Publish Steps
        </button>
      </div>
    </Modal>
  )
}

PublishWorkflowStepsModal.propTypes = {
  isOpen: PropTypes.bool,
  onSubmit: PropTypes.func,
}

export default PublishWorkflowStepsModal
