import PropTypes from 'prop-types'
import React, { useMemo, useState } from 'react'
import FindReplaceBreadcrumb from '../FindReplaceBreadcrumb'
import { useGlobalFilter, useSortBy, useTable } from 'react-table'
import globalSearchFilter from '../../../../utilities/table/globalSearchFilter'
import FindReplaceConfirmModal from '../FindReplaceConfirmModal'
import { ReactComponent as CheckmarkIcon } from '../../../../assets/icons/checkmark-circle-icon.svg'
import { ReactComponent as SwapIcon } from '../../../../assets/icons/coins-swap-icon.svg'
import { orderBy } from 'lodash'
import TableSortIcon from '../../../global-components/table/TableSortIcon'
/*
  Disable prop-types as it triggers on every table Cell definition
 */
/* eslint react/prop-types: 0 */
const FindReplaceEmailTable = ({
  items,
  entries,
  replaceValue,
  onReplaceClick,
}) => {
  const [disableConfirmModal, setDisableConfirmModal] = useState(false)
  const [activeReplacement, setActiveReplacement] = useState(false)
  const data = useMemo(() => {
    return orderBy(entries, 'score', 'desc')
  }, [entries])

  const find = getFind()
  const replacement = getReplacement()

  function handleReplaceClick(row) {
    if (disableConfirmModal) {
      onReplaceClick(row)
    } else {
      setActiveReplacement(row)
    }
  }

  function handleConfirmModalSubmit(shouldDisable) {
    if (shouldDisable) {
      setDisableConfirmModal(true)
    }
    onReplaceClick(activeReplacement)
    setActiveReplacement(false)
  }

  function getFind() {
    if (activeReplacement?.entryType === 'internalLink') {
      return activeReplacement?.find?.name
    }
    return activeReplacement?.find
  }

  function getReplacement() {
    if (activeReplacement) {
      if (activeReplacement?.entryType === 'internalLink') {
        return replaceValue.name
      }
      return replaceValue
    }
  }

  const columns = useMemo(
    () => [
      {
        Header: 'Workflow Name',
        accessor: 'breadcrumb.workflow.name',
        Cell: props => (
          <FindReplaceBreadcrumb
            item={items[props?.row?.original?.itemId]}
            entry={props?.row?.original || {}}
          />
        ),
      },
      {
        Header: 'Category',
        accessor: 'breadcrumb.workflow.category',
        Cell: props => <div>{props.cell.value}</div>,
      },
      {
        Header: 'Action',
        Cell: props => (
          <div>
            <button
              className="btn btn--dark-green"
              disabled={props.row.original?.replaced}
              onClick={() => handleReplaceClick(props.row.original)}
            >
              {props.row.original?.replaced ? (
                <>
                  <CheckmarkIcon className="svg__white" />
                  <span>Replaced</span>
                </>
              ) : (
                <>
                  <SwapIcon />
                  <span>Replace</span>
                </>
              )}
            </button>
          </div>
        ),
      },
    ],
    [data, disableConfirmModal],
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      globalSearchFilter,
    },
    useGlobalFilter,
    useSortBy,
  )

  return (
    <div className="table container">
      <div className="table__filters">
        <div className="table__filters-row">
          <div className="table__filters-content">
            <p className="title">Link Search</p>

            <div className="table__filters-counts">
              <p>
                <span>{entries?.length}</span> Search Results
              </p>
            </div>
          </div>

          <div className="table__filters-actions">
            <input
              type="text"
              className="filters-search"
              name="search-users"
              onChange={e => setGlobalFilter(e.currentTarget.value)}
              placeholder="Search Results"
            />
          </div>
        </div>
      </div>

      <div className="table__container">
        <table {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup, i) => (
              <tr
                key={`search-results-thead-tr-${i}`}
                {...headerGroup.getHeaderGroupProps()}
              >
                {headerGroup?.headers?.map((column, i) => (
                  <th
                    key={`find-replace-thead-th-${i}`}
                    {...column.getHeaderProps()}
                    style={{ verticalAlign: 'middle' }}
                  >
                    <div {...column.getSortByToggleProps()}>
                      {column.render('Header')}
                      <TableSortIcon column={column} />
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          <tbody {...getTableBodyProps()}>
            {rows?.map((row, i) => {
              prepareRow(row)

              return (
                <tr key={`find-replace-tbody-tr-${i}`} {...row.getRowProps()}>
                  {row.cells.map((cell, i) => {
                    return (
                      <td
                        className={cell.column.id}
                        key={`find-replace-tbody-td-${i}`}
                        {...cell.getCellProps()}
                      >
                        {cell.render('Cell')}
                      </td>
                    )
                  })}
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
      <FindReplaceConfirmModal
        isOpen={!!activeReplacement}
        onRequestClose={() => setActiveReplacement(false)}
        onSubmit={handleConfirmModalSubmit}
        location={activeReplacement?.breadcrumb?.workflow?.name}
        find={find}
        replacement={replacement}
        type="email"
      />
    </div>
  )
}

FindReplaceEmailTable.propTypes = {
  items: PropTypes.object,
  entries: PropTypes.arrayOf(PropTypes.object),
  replaceValue: PropTypes.string,
  onReplaceClick: PropTypes.func,
}

export default FindReplaceEmailTable
