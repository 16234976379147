import axios from 'axios'
import { get } from 'lodash'
import convertStringToSlug from '../../utilities/slug/convertStringToSlug'
import {
  selectActiveClientId,
  selectActiveOperatingLocation,
} from '../user/userSelections'

export const FETCH_CATEGORIES_BEGIN = 'FETCH_CATEGORIES_BEGIN'
export const FETCH_CATEGORIES_ERROR = 'FETCH_CATEGORIES_ERROR'
export const FETCH_CATEGORIES_SUCCESS = 'FETCH_CATEGORIES_SUCCESS'
export const SET_CATEGORIES = 'SET_CATEGORIES'
export const UPDATE_CATEGORY_PIN_BEGIN = 'UPDATE_CATEGORY_PIN_BEGIN'
export const UPDATE_CATEGORY_PIN_COMPLETE = 'UPDATE_CATEGORY_PIN_COMPLETE'

export const fetchCategories = token => async (dispatch, getState) => {
  const activeClient = getState().userSelectionsData.activeClient
  const activeClientId = get(activeClient, 'id', null)
  const activeOperatingLocation =
    getState().userSelectionsData.activeOperatingLocation

  dispatch({ type: FETCH_CATEGORIES_BEGIN })
  return axios({
    url: `${process.env.REACT_APP_PRODUCT_API}/api/v1/workflows/categories/${activeClientId}`,
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    data: {
      operatingLocation: activeOperatingLocation,
    },
  })
    .then(response => {
      const categories = get(response, 'data', [])
      let keyedCategories = {}

      keyedCategories[`${activeClientId}`] = categories

      dispatch({
        type: FETCH_CATEGORIES_SUCCESS,
        payload: keyedCategories,
      })
    })
    .catch(error => {
      dispatch({
        type: FETCH_CATEGORIES_ERROR,
        payload: error.message,
      })
    })
}

// REDUCER
const initialState = {
  error: null,
  loading: false,
  categories: null,
  loadingPin: {},
}

export default function categoriesReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_CATEGORIES_BEGIN:
      return { ...state, loading: true }
    case FETCH_CATEGORIES_SUCCESS:
      return {
        ...state,
        loading: false,
        categories: action.payload,
      }
    case FETCH_CATEGORIES_ERROR:
      return { ...state, loading: false, error: action.payload }
    case SET_CATEGORIES:
      return {
        ...state,
        categories: action.payload,
      }
    case UPDATE_CATEGORY_PIN_BEGIN:
      return {
        ...state,
        loadingPin: {
          ...state.loadingPin,
          [action.payload]: true,
        },
      }
    case UPDATE_CATEGORY_PIN_COMPLETE:
      return {
        ...state,
        loadingPin: {
          ...state.loadingPin,
          [action.payload]: false,
        },
      }
    default:
      return state
  }
}

// SELECTORS
export const selectCategoriesState = state => state.categoriesData
export const selectCategoriesLoading = state =>
  get(state, 'categoriesData.loading', false)
export const selectCategories = state =>
  get(state, 'categoriesData.categories', [])

export const selectCategoriesByClient = (state, clientId) =>
  get(state, ['categoriesData', 'categories', clientId], [])

export const selectCategoriesForActiveClient = state => {
  const clientId = selectActiveClientId(state)
  return get(state, ['categoriesData', 'categories', clientId], [])
}

export const selectCategoryBySlug = (state, name) => {
  const clientId = selectActiveClientId(state)
  const categories = selectCategoriesByClient(state, clientId)
  return categories?.find(
    category => convertStringToSlug(category.category) === name,
  )
}

export const selectCategoryLoadingPin = state =>
  get(state, ['categoriesData', 'loadingPin'], {})
