import Link from '@tiptap/extension-link'
import { mergeAttributes } from '@tiptap/react'

import { markPasteRule } from '@tiptap/core'
import { find } from 'linkifyjs'
const TipTapLink = Link.extend({
  addOptions() {
    return {
      ...this?.parent?.(),
      autolink: false,
      linkOnPaste: false,
    }
  },
  addAttributes() {
    return {
      ...this?.parent?.(),
      type: {
        default: null,
        rendered: false,
      },
      internalType: {
        default: null,
        rendered: false,
      },
      workflow: {
        default: null,
        rendered: false,
      },
      category: {
        default: null,
        rendered: false,
      },
      step: {
        default: null,
        rendered: false,
      },
      document: {
        default: null,
        rendered: false,
      },
      url: {
        default: null,
        rendered: false,
      },
    }
  },
  addPasteRules() {
    return [
      markPasteRule({
        find: text =>
          find(text)
            .filter(link => {
              if (this.options.validate) {
                return this.options.validate(link.value)
              }

              return true
            })
            .filter(link => link.isLink)
            .map(link => ({
              text: link.value,
              index: link.start,
              data: link,
            })),
        type: this.type,
        getAttributes: (match, pasteEvent) => {
          const html = pasteEvent?.clipboardData?.getData('text/html')
          const hrefRegex = /href="([^"]*)"/
          const existingLink = html?.match(hrefRegex)
          if (existingLink) {
            return {
              href: existingLink[1],
            }
          }

          return {
            href: 'temp',
            type: 'external',
            url: match.data?.href,
          }
        },
      }),
    ]
  },
})

export default TipTapLink
