import React from 'react'
import { Outlet } from 'react-router-dom'
import { useSelector } from 'react-redux'
import {
  selectActiveClient,
  selectActiveOperatingLocation,
} from '../redux/user/userSelections'
import Header from '../components/global-components/structure/header/Header'

function BrowseWorkflowsContainer() {
  const activeClient = useSelector(selectActiveClient)
  const activeOperatingLocation = useSelector(selectActiveOperatingLocation)

  return (
    <div className="browse-workflows-container view-container">
      <Header
        activeClient={activeClient}
        activeOperatingLocation={activeOperatingLocation}
        enableBreadCrumbs={true}
        title="Workflows"
      />
      <Outlet />
    </div>
  )
}

export default BrowseWorkflowsContainer
