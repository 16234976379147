import React from 'react'
import PropTypes from 'prop-types'

function HeartIcon({ colour }, props) {
  return (
    <svg
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M35.4769 8.10842C32.1128 4.74425 26.639 4.74425 23.2748 8.10842L20 11.383L16.7252 8.10842C13.3611 4.74425 7.8873 4.74425 4.52313 8.10842C1.15896 11.4725 1.15896 16.9464 4.52313 20.3105L17.6568 33.4449C18.2831 34.0703 19.1152 34.4147 20 34.4147C20.8853 34.4147 21.7174 34.0701 22.3432 33.4443L35.4769 20.3105C38.841 16.9464 38.841 11.4725 35.4769 8.10842ZM20.8517 31.9528C20.6243 32.1801 20.3218 32.3053 20 32.3053C19.6781 32.3053 19.3753 32.18 19.1479 31.9529L6.01467 18.819C3.47301 16.2773 3.47301 12.1416 6.01467 9.59996C8.55639 7.05824 12.6922 7.05838 15.2338 9.60003L19.2541 13.6204C19.4519 13.8182 19.7203 13.9294 20 13.9294C20.2797 13.9294 20.548 13.8182 20.7458 13.6204L24.7662 9.60003C27.3077 7.05838 31.4435 7.05824 33.9852 9.59996C36.5269 12.1416 36.5269 16.2773 33.9852 18.819L20.8517 31.9528Z"
        fill={colour}
      />
    </svg>
  )
}

HeartIcon.defaultProps = {
  colour: '#000000',
}

HeartIcon.propTypes = {
  colour: PropTypes.string,
}

export default HeartIcon
