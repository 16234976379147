import React from 'react'
import { ReactComponent as FilterIconDefault } from '../../../assets/icons/table-sort-arrows-default.svg'
import { ReactComponent as FilterIconAscending } from '../../../assets/icons/table-sort-arrows-ascending.svg'
import { ReactComponent as FilterIconDescending } from '../../../assets/icons/table-sort-arrows-descending.svg'
import PropTypes from 'prop-types'
const TableSortIcon = ({ column }) => {
  const { canSort, isSorted, isSortedDesc } = column

  if (canSort) {
    if (isSorted) {
      if (isSortedDesc) {
        return <FilterIconDescending className="ascending-icon" />
      }
      return <FilterIconAscending className="descending-icon" />
    }
    return <FilterIconDefault className="filter-icon" />
  }
  return null
}

TableSortIcon.propTypes = {
  column: PropTypes.object,
}

export default TableSortIcon
