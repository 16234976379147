import PropTypes from 'prop-types'
import EditorAccordionSubcomponent from '../accordions-component/EditorAccordionSubcomponent'
import { cloneDeep } from 'lodash'
import '../../../../styles/editor-flow/editor-components/editor-accordion-component.scss'
import {
  EDITOR_ACCORDIONS,
  EDITOR_ACCORDIONS_AND_CONTENT,
} from '../../../../configuration/editor'
import { rectSwappingStrategy, SortableContext } from '@dnd-kit/sortable'
import getId from '../../../../utilities/editor/getId'
import React from 'react'
import TipTapColorPicker from '../../base-fields/TipTap/TipTapColorPicker'
import EditorContentSubcomponent from '../content-component/EditorContentSubcomponent'
import { createEmptyAccordion } from '../accordions-component/EditorAccordionsComponent'
import { createEmptyContent } from '../content-component/EditorContentComponent'
import TipTapDropdown from '../../base-fields/TipTap/TipTapDropdown'
import clsx from 'clsx'

const headingOptions = [1, 2, 3, 4, 5, 6].map(num => ({
  id: `h${num}`,
  name: `Heading ${num}`,
  icon: `h${num}`,
}))

function EditorAccordionsAndContentComponent({
  component,
  onChange,
  dropPath,
}) {
  const { subComponents } = component
  const configurations = component?.configurations || {}
  const { title, titleColor, titleFormat = 'h2', description } = configurations

  const accordions = subComponents?.filter(
    comp => comp.subComponentType === 'accordion',
  )
  const contentIndex = subComponents.findIndex(
    comp => comp.subComponentType === 'content',
  )
  const content = subComponents?.[contentIndex]

  function handleAddAccordion() {
    onChange({
      ...component,
      subComponents: [
        ...component.subComponents,
        { ...createEmptyAccordion() },
      ],
    })
  }

  function handleConfigChange(name, value) {
    onChange({
      ...component,
      configurations: {
        ...configurations,
        [name]: value,
      },
    })
  }

  function getHeadingIcon() {
    const heading = headingOptions.find(search => search.id === titleFormat)
    return (
      <span className="editor-accordion-component__heading-icon">
        {heading.icon}
      </span>
    )
  }

  function handleSubcomponentChange(subcomponent) {
    const newComponent = cloneDeep(component)
    const subcomponentIndex = newComponent.subComponents.findIndex(
      subcomp => getId(subcomp) === getId(subcomponent),
    )
    newComponent.subComponents[subcomponentIndex] = subcomponent
    onChange(newComponent)
  }

  function handleDeleteClick(subcomponent) {
    onChange({
      ...component,
      subComponents: component.subComponents.filter(
        item => getId(item) !== getId(subcomponent),
      ),
    })
  }

  function handleDuplicateAccordion(subcomponent) {
    const newSubcomponent = cloneDeep(subcomponent)
    delete newSubcomponent._id
    newSubcomponent.id = generateId()
    newSubcomponent.layouts.forEach(layout => {
      delete layout._id
      layout.id = generateId()
      layout.fields = layout.fields.map(field => ({
        blocks: field.blocks,
        id: generateId(),
        type: field.type,
        fieldType: field.fieldType,
      }))
    })

    const subcomponentIndex =
      subComponents.findIndex(search => getId(search) === getId(subcomponent)) +
      1
    onChange({
      ...component,
      subComponents: [
        ...subComponents.slice(0, subcomponentIndex),
        newSubcomponent,
        ...subComponents.slice(subcomponentIndex, subComponents.length),
      ],
    })
  }

  function generateId() {
    return `temp-${Date.now()}-${Math.random()}`
  }

  return (
    <div className="editor-accordion-component">
      <div className="accordion-component-title">
        <input
          type="text"
          value={title}
          className="accordion-component-title-input"
          onChange={evt => handleConfigChange('title', evt.target.value)}
          style={{ ...(titleColor && { color: titleColor }) }}
          placeholder="Enter Accordion Group Header"
        />
        <div className="accordion-component-title-actions">
          <TipTapDropdown
            button={getHeadingIcon()}
            tooltip="Heading Style"
            direction="left"
          >
            {headingOptions.map(option => (
              <button
                key={option.id}
                onClick={() => {
                  handleConfigChange('titleFormat', option.id)
                }}
                className={clsx(option.id, {
                  'is-active': titleFormat === option.id,
                })}
              >
                {option.name}
              </button>
            ))}
          </TipTapDropdown>
          <TipTapColorPicker
            onChange={color => handleConfigChange('titleColor', color)}
            tooltip="Font Color"
            value={titleColor}
          />
        </div>
      </div>
      <div className="accordion-component-description">
        <textarea
          value={description}
          onChange={evt => handleConfigChange('description', evt.target.value)}
          placeholder="Enter accordion group description"
        ></textarea>
        <div className="accordion-component-description-actions"></div>
      </div>

      <div className="flex">
        <div className="flex-fill">
          <SortableContext items={accordions?.map(item => getId(item)) || []}>
            {accordions?.map((subcomponent, i) => (
              <EditorAccordionSubcomponent
                subcomponent={subcomponent}
                index={1 + i}
                key={getId(subcomponent)}
                onChange={handleSubcomponentChange}
                onDuplicateClick={handleDuplicateAccordion}
                onDeleteClick={handleDeleteClick}
                dropPath={[...dropPath, 'subComponents']}
                disableLayouts
              />
            ))}
          </SortableContext>
          <div className="accordion-component-add-button">
            <button className="btn btn--white" onClick={handleAddAccordion}>
              Add Another Accordion
            </button>
          </div>
        </div>
        <div style={{ width: '16px' }}></div>
        <div className="flex-fill">
          <EditorContentSubcomponent
            subcomponent={content}
            index={contentIndex}
            key={getId(content)}
            onChange={handleSubcomponentChange}
            onDeleteClick={handleDeleteClick}
            dropPath={[...dropPath, 'subComponents']}
            layout="layout-100"
          />
        </div>
      </div>
    </div>
  )
}

export const createAccordionAndContentComponent = id => {
  return {
    id,
    componentType: EDITOR_ACCORDIONS_AND_CONTENT,
    configurations: {},
    subComponents: [createEmptyContent(), createEmptyAccordion()],
  }
}

EditorAccordionsAndContentComponent.propTypes = {
  component: PropTypes.object,
  onChange: PropTypes.func,
  dropPath: PropTypes.array,
}

export default EditorAccordionsAndContentComponent
