import React from 'react'
import PropTypes from 'prop-types'

function ReportIcon({ colour }, props) {
  return (
    <svg
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10.5493 17.4143L16.8832 12.5091L19.5647 14.9182C19.9237 15.241 20.4616 15.2606 20.8434 14.965L26.2156 10.8046L26.0714 11.9918C26.0363 12.2809 26.2422 12.5439 26.5314 12.5791C26.8218 12.6142 27.0837 12.4072 27.1187 12.119C27.3954 9.8345 27.4178 9.68673 27.419 9.62336C27.4243 9.32169 27.1777 9.081 26.8818 9.08635H24.3547C24.0633 9.08635 23.8272 9.32246 23.8272 9.61386C23.8272 9.90526 24.0633 10.1414 24.3547 10.1414H25.3489L20.2348 14.102L17.5533 11.6928C17.1942 11.3703 16.6565 11.3506 16.2746 11.6461L9.90345 16.5802C9.6731 16.7586 9.63097 17.0899 9.80934 17.3202C9.98729 17.5502 10.3188 17.593 10.5493 17.4143Z"
        fill={colour}
      />
      <path
        d="M28.211 23.3277H27.4191V14.7956C27.4191 14.1002 26.6182 13.707 26.0681 14.1357L24.6322 15.2547C24.4451 15.4005 24.3378 15.6202 24.3378 15.8573V23.3277H22.5394V18.449C22.5394 17.8143 21.809 17.4551 21.3065 17.8459L19.753 19.0537C19.5657 19.1995 19.4582 19.4193 19.4582 19.6567V23.3277H17.6598V16.7763C17.6598 16.1485 16.9436 15.7885 16.4397 16.1634L14.8863 17.3193C14.6936 17.4628 14.5785 17.692 14.5785 17.9322V19.4627C14.5785 19.7541 14.8146 19.9902 15.106 19.9902C15.3974 19.9902 15.6336 19.7541 15.6336 19.4627V18.0784L16.6048 17.3556V23.3277H15.6336V21.9288C15.6336 21.6374 15.3974 21.4013 15.106 21.4013C14.8146 21.4013 14.5785 21.6374 14.5785 21.9288V23.3277H12.7802V20.5382C12.7802 19.9011 12.046 19.5441 11.5445 19.9371L9.99161 21.1556C9.80557 21.3014 9.69895 21.5205 9.69895 21.7569V23.3277H8.90697C8.61557 23.3277 8.37946 23.5638 8.37946 23.8552C8.37946 24.1466 8.61557 24.3827 8.90697 24.3827H28.211C28.5024 24.3827 28.7385 24.1466 28.7385 23.8552C28.7385 23.5638 28.5023 23.3277 28.211 23.3277ZM25.3928 15.9996L26.364 15.2427V23.3277H25.3928V15.9996ZM20.5131 19.7991L21.4844 19.0439V23.3278H20.5131V19.7991ZM10.7538 21.8985L11.7251 21.1364V23.3277H10.7538V21.8985Z"
        fill={colour}
      />
      <path
        d="M14.3028 27.0922H8.90697C8.61557 27.0922 8.37946 27.3283 8.37946 27.6197C8.37946 27.9111 8.61557 28.1472 8.90697 28.1472H14.3029C14.5943 28.1472 14.8304 27.9111 14.8304 27.6197C14.8304 27.3283 14.5942 27.0922 14.3028 27.0922Z"
        fill={colour}
      />
      <path
        d="M14.3028 29.5304H8.90697C8.61557 29.5304 8.37946 29.7665 8.37946 30.0579C8.37946 30.3493 8.61557 30.5855 8.90697 30.5855H14.3029C14.5943 30.5855 14.8304 30.3493 14.8304 30.0579C14.8304 29.7665 14.5942 29.5304 14.3028 29.5304Z"
        fill={colour}
      />
      <path
        d="M32.2472 5.14905H31.4135V4.04845C31.4136 2.91893 30.4946 2 29.3651 2H12.5774C12.0058 2 11.4913 2.23752 11.1289 2.59996C11.0292 2.69963 11.9344 1.79434 6.27132 7.45837C5.92126 7.82552 5.70435 8.32575 5.70435 8.87295V32.8024C5.70435 33.9319 6.62328 34.8509 7.75279 34.8509H8.58648V35.9516C8.58648 37.0811 9.50541 38 10.6349 38H32.2472C33.3767 38 34.2956 37.0811 34.2956 35.9516V7.1975C34.2956 6.06805 33.3767 5.14905 32.2472 5.14905ZM11.4199 3.80108V7.03158C11.4199 7.40864 11.1131 7.71552 10.7359 7.71552H7.50542L11.4199 3.80108ZM33.2406 35.9516C33.2406 36.4994 32.795 36.945 32.2472 36.945H10.6349C10.0871 36.945 9.64151 36.4994 9.64151 35.9516V34.8509H29.3651C30.4947 34.8509 31.4136 33.932 31.4136 32.8025V30.8489C31.4136 30.5575 31.1775 30.3214 30.8861 30.3214C30.5947 30.3214 30.3586 30.5575 30.3586 30.8489V32.8025C30.3586 33.3503 29.9129 33.7959 29.3651 33.7959H7.75286C7.20509 33.7959 6.75945 33.3503 6.75945 32.8025V8.87302C6.75945 8.83856 6.7612 8.80438 6.76465 8.77055H10.736C11.6949 8.77055 12.475 7.99046 12.475 7.03158V3.06023C12.5715 3.05032 11.7791 3.05686 29.3652 3.05503C29.913 3.05503 30.3586 3.50067 30.3586 4.04845V28.3827C30.3586 28.6741 30.5947 28.9102 30.8861 28.9102C31.1775 28.9102 31.4137 28.6741 31.4137 28.3827V6.20408H32.2473C32.7951 6.20408 33.2408 6.64973 33.2408 7.1975V35.9516H33.2406Z"
        fill={colour}
      />
    </svg>
  )
}

ReportIcon.defaultProps = {
  colour: '#000000',
}

ReportIcon.propTypes = {
  colour: PropTypes.string,
}

export default ReportIcon
