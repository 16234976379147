import React from 'react'
import { get, orderBy } from 'lodash'
import dayjs from 'dayjs'
import dateFormats from '../../configuration/supportedDateFormats'
import getIconComponent from '../icon/getIconComponent'

export default function formatWorkflowsCategoriesToTableData(clientCategories) {
  return clientCategories.map(category => {
    const id = get(category, '_id', null)
    const name = get(category, 'category', null)
    const subcategories = get(category, 'subcategories', [])
    const date = get(category, 'updatedAt', null)
    const icon = get(category, 'icon', null)
    const iconColour = get(icon, 'colour', null) || undefined
    const Icon = getIconComponent(icon)
    const sortedSubcategories = orderBy(
      subcategories,
      [subcategory => subcategory.toLowerCase()],
      ['asc'],
    )

    return {
      category: name,
      dateModified: date
        ? dayjs(date).format(dateFormats.dayMonthYearTime)
        : null,
      delete: id,
      edit: id,
      icon: Icon ? <Icon colour={iconColour} /> : null,
      subcategories: sortedSubcategories.join(', '),
    }
  })
}
