import React from 'react'
import PropTypes from 'prop-types'
import { get, isEmpty } from 'lodash'

function RelatedWorkflowsFilters({
  activeWorkflowCategory,
  relatedWorkflowsCategories,
  setActiveWorkflowCategory,
}) {
  const categoryFilterOptions = relatedWorkflowsCategories.map(category => {
    const categoryName = get(category, 'category', null)

    return categoryName ? (
      <button
        key={`related-workflow-filter-${categoryName}`}
        onClick={() => setActiveWorkflowCategory(categoryName)}
        className={`btn btn--white${
          activeWorkflowCategory === categoryName ? ' active' : ''
        }`}
      >
        {categoryName}
      </button>
    ) : null
  })

  return !isEmpty(categoryFilterOptions) ? (
    <div className="related-workflows__filters">
      <button
        onClick={() => setActiveWorkflowCategory(null)}
        className={`btn btn--white${
          activeWorkflowCategory === null ? ' active' : ''
        }`}
      >
        Show All
      </button>

      {categoryFilterOptions}
    </div>
  ) : null
}

RelatedWorkflowsFilters.propTypes = {
  activeWorkflowCategory: PropTypes.string,
  relatedWorkflowsCategories: PropTypes.array,
  setActiveWorkflowCategory: PropTypes.func.isRequired,
}

export default RelatedWorkflowsFilters
