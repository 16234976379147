import { useSelector } from 'react-redux'
import { getUserApiRequirements } from '../user/user'
import axios from 'axios'
import { fetchWorkflow } from '../workflows/workflow'
import { get } from 'lodash'
import {
  UPDATE_STEP_BEGIN,
  UPDATE_STEP_ERROR,
  UPDATE_STEP_SUCCESS,
} from './updateStep'
import {
  UPDATE_WORKFLOW_BEGIN,
  UPDATE_WORKFLOW_SUCCESS,
} from '../workflows/updateWorkflow'

export const updateSteps =
  ({ workflow, stepIds, values, token }) =>
  async (dispatch, getState) => {
    const state = getState()
    const { userId, activeClientId, userEmail, activeOperatingLocation } =
      getUserApiRequirements(state)

    dispatch({ type: UPDATE_WORKFLOW_BEGIN })

    return axios({
      url: `${process.env.REACT_APP_PRODUCT_API}/api/v1/workflow/steps/update/`,
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      data: {
        stepIds,
        values,
        authorId: userId,
        authorEmail: userEmail,
        clientId: activeClientId,
        operatingLocation: activeOperatingLocation,
        workflowId: workflow?._id,
      },
    })
      .then(() => {
        dispatch({ type: UPDATE_WORKFLOW_SUCCESS })

        dispatch(fetchWorkflow(workflow.slug, token))
      })
      .catch(error => {
        const customError = get(error, 'response.data.error', 'Error')

        dispatch({
          type: UPDATE_STEP_ERROR,
          payload: customError,
        })
      })
  }
