import { useDispatch } from 'react-redux'
import { useEffect, useState } from 'react'
import { emitSocketMessage } from '../redux/middleware/socket/socket'

const useOnResourceBecomesUnblocked = ({ name, data, isBlocked }) => {
  const dispatch = useDispatch()
  const [previousIsResourceBlocked, setPreviousIsResourceBlocked] =
    useState(false)

  useEffect(() => {
    if (!isBlocked && previousIsResourceBlocked) {
      dispatch(emitSocketMessage(name, data))
    }
    setPreviousIsResourceBlocked(isBlocked)
  }, [isBlocked])
}

export default useOnResourceBecomesUnblocked
