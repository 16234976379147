import React from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'
import { ReactComponent as AlignLeftIcon } from '../../../../assets/icons/align-left-icon.svg'
import { ReactComponent as AlignCenterIcon } from '../../../../assets/icons/align-center-icon.svg'
import { ReactComponent as AlignRightIcon } from '../../../../assets/icons/align-right-icon.svg'

function EditorAlignmentButtons({ label = 'Alignment', setAlignment }) {
  const alignmentOptions = [
    {
      icon: <AlignLeftIcon />,
      label: 'Left',
      value: 'left',
    },
    {
      icon: <AlignCenterIcon />,
      label: 'Center',
      value: 'center',
    },
    {
      icon: <AlignRightIcon />,
      label: 'Right',
      value: 'right',
    },
  ]

  return (
    <>
      <label>{label}</label>

      {alignmentOptions.map(alignment => {
        const alignmentValue = get(alignment, 'value', null)
        const alignmentIcon = get(alignment, 'icon', null)

        return alignmentValue ? (
          <button
            className="btn btn--white"
            key={`image-alignment-${alignmentValue}`}
            onClick={() => setAlignment(alignmentValue)}
          >
            {alignmentIcon ? alignmentIcon : null}
          </button>
        ) : null
      })}
    </>
  )
}

EditorAlignmentButtons.propTypes = {
  label: PropTypes.string,
  setAlignment: PropTypes.func.isRequired,
}

export default EditorAlignmentButtons
