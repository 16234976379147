import React from 'react'
import PropTypes from 'prop-types'

function EmailIcon({ colour }, props) {
  return (
    <svg
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M20.7428 22.3951C19.398 22.3889 18.0619 22.1782 16.7804 21.7702C14.7329 21.0852 13.212 19.3518 12.7992 17.2325C12.3475 15.0568 13.1707 12.7562 15.0573 10.921C15.2597 10.7238 15.47 10.535 15.6883 10.3544C17.62 8.73726 20.2242 8.17526 22.6508 8.85189C25.0001 9.60173 26.6234 11.7487 26.7046 14.2134C26.7926 15.6322 26.3302 17.0302 25.4136 18.1167C24.7066 19.0072 23.5612 19.4278 22.446 19.2064C21.9899 19.1118 21.592 18.8356 21.3438 18.4414C21.1212 18.0585 21.064 17.6014 21.1857 17.1755C21.7103 15.1922 22.2437 12.1784 22.249 12.1478C22.2863 11.9367 22.4334 11.7615 22.6348 11.6882C22.8363 11.6149 23.0616 11.6547 23.2258 11.7925C23.39 11.9303 23.4682 12.1453 23.4309 12.3564C23.4087 12.4818 22.8834 15.4505 22.3459 17.4822C22.3075 17.5947 22.3171 17.7182 22.3725 17.8234C22.4555 17.9382 22.5806 18.0153 22.7205 18.0378C23.4032 18.1518 24.0928 17.8779 24.5113 17.3266C25.2231 16.475 25.5797 15.3822 25.5072 14.2747C25.4486 12.3128 24.162 10.6 22.2942 9.99697C20.2554 9.43619 18.0711 9.91526 16.4542 11.2779C16.2614 11.4379 16.0739 11.6054 15.894 11.7806C14.9613 12.6878 13.4719 14.5704 13.9738 16.9882C14.311 18.6774 15.5174 20.0617 17.1446 20.6267C19.9584 21.5243 24.0017 21.6323 26.132 18.9835C26.3413 18.7314 26.7141 18.6939 26.9695 18.8992C27.2248 19.1044 27.2683 19.4766 27.0671 19.7352C25.4918 21.6942 23.0946 22.3951 20.7428 22.3951Z"
        fill={colour}
      />
      <path
        d="M18.689 19.2844C18.0548 19.2953 17.4363 19.0874 16.9375 18.6956C15.7859 17.774 15.7542 16.173 16.0771 15.0827C16.1858 14.7196 16.3318 14.3687 16.5127 14.0356C16.9629 13.1274 17.6827 12.3804 18.5736 11.8969C19.6832 11.3211 21.0388 11.5395 21.9114 12.4344C22.3444 12.9089 22.6705 13.4707 22.8678 14.082C22.9722 14.3922 22.8088 14.7288 22.5005 14.8387C22.1921 14.9485 21.8527 14.7909 21.7376 14.4845C21.5953 14.0329 21.3589 13.6166 21.0439 13.2631C20.5351 12.7428 19.7423 12.6245 19.1037 12.9734C18.4356 13.3502 17.8986 13.9224 17.5649 14.613C17.4246 14.8718 17.3113 15.1443 17.2268 15.4263C16.9936 16.2149 17.0449 17.2442 17.6882 17.7593C18.3915 18.3241 19.5781 18.0854 20.2456 17.5095C20.7431 17.0616 21.1706 16.5416 21.5138 15.9668C21.6249 15.7806 21.8279 15.6688 22.0446 15.6745C22.2614 15.6802 22.4582 15.8023 22.5595 15.9941C22.6607 16.1858 22.6507 16.4173 22.5331 16.5995C22.1266 17.2768 21.6206 17.889 21.032 18.4158C20.376 18.9693 19.5473 19.2765 18.689 19.2844Z"
        fill={colour}
      />
      <path
        d="M36.1988 38H3.80109C2.8075 37.9989 2.00231 37.1937 2.00122 36.2001V14.0017C2.00123 13.769 2.13574 13.5574 2.34637 13.4586C2.55699 13.3597 2.80575 13.3916 2.98467 13.5403L17.7059 25.775C19.0379 26.875 20.9633 26.8747 22.2949 25.7741L37.0147 13.5403C37.1937 13.3913 37.4426 13.3593 37.6534 13.4581C37.8642 13.557 37.9988 13.7689 37.9987 14.0017V36.2001C37.9976 37.1937 37.1924 37.9989 36.1988 38ZM3.20114 15.2804V36.2001C3.20157 36.5313 3.46992 36.7997 3.80109 36.8001H36.1988C36.53 36.7998 36.7985 36.5314 36.7988 36.2001V15.2804L23.061 26.6976C21.2847 28.1655 18.7165 28.1659 16.9398 26.6985L3.20114 15.2804Z"
        fill={colour}
      />
      <path
        d="M2.60177 14.6016C2.34044 14.6019 2.10902 14.4329 2.02963 14.184C1.95023 13.935 2.04112 13.6633 2.25433 13.5122L7.65395 9.68445C7.92429 9.49708 8.29508 9.56232 8.48524 9.8307C8.6754 10.0991 8.61401 10.4705 8.34762 10.6635L2.948 14.4912C2.84689 14.5631 2.72586 14.6017 2.60177 14.6016Z"
        fill={colour}
      />
      <path
        d="M37.3981 14.6016C37.274 14.6017 37.153 14.5631 37.0519 14.4912L31.6523 10.6635C31.3859 10.4705 31.3245 10.0991 31.5146 9.8307C31.7048 9.56232 32.0756 9.49708 32.3459 9.68445L37.7456 13.5122C37.9588 13.6633 38.0497 13.935 37.9703 14.184C37.8909 14.4329 37.6594 14.6019 37.3981 14.6016Z"
        fill={colour}
      />
      <path
        d="M25.5431 6.20224C25.4189 6.20231 25.2978 6.1637 25.1965 6.09179L22.3287 4.05793C20.9975 2.933 19.0522 2.92128 17.7075 4.03009L14.8037 6.09185C14.6288 6.21587 14.401 6.23717 14.2062 6.14772C14.0113 6.05828 13.879 5.87168 13.8591 5.65822C13.8391 5.44476 13.9345 5.23686 14.1094 5.11284L16.9772 3.07898C18.7493 1.63006 21.3 1.64159 23.0589 3.10646L25.8907 5.1126C26.1041 5.26366 26.1951 5.53551 26.1156 5.7846C26.0362 6.03369 25.8046 6.20264 25.5431 6.20224Z"
        fill={colour}
      />
      <path
        d="M2.99114 37.676C2.7383 37.6762 2.51245 37.5179 2.42644 37.2802C2.34043 37.0424 2.4127 36.7762 2.60716 36.6146L16.2982 25.2394C16.4627 25.0995 16.6897 25.0589 16.8926 25.1329C17.0954 25.207 17.2428 25.3843 17.2785 25.5973C17.3143 25.8103 17.2327 26.026 17.0651 26.1622L3.37409 37.5374C3.2666 37.627 3.13108 37.676 2.99114 37.676Z"
        fill={colour}
      />
      <path
        d="M37.008 37.676C36.8681 37.6761 36.7327 37.627 36.6254 37.5374L22.9343 26.1622C22.7667 26.0261 22.6852 25.8103 22.7209 25.5974C22.7566 25.3844 22.904 25.207 23.1069 25.133C23.3097 25.0589 23.5368 25.0996 23.7013 25.2395L37.3919 36.6147C37.5864 36.7763 37.6587 37.0425 37.5727 37.2802C37.4866 37.518 37.2608 37.6762 37.008 37.676Z"
        fill={colour}
      />
      <path
        d="M31.9991 19.0893C31.6678 19.0893 31.3992 18.8207 31.3992 18.4894V6.20691C31.3974 6.21951 31.3734 6.20253 31.3332 6.20223H8.66679C8.64027 6.20061 8.61424 6.20993 8.59479 6.22803L8.60079 18.4894C8.60079 18.8207 8.33218 19.0893 8.00084 19.0893C7.66949 19.0893 7.40088 18.8207 7.40088 18.4894V6.20223C7.42023 5.52182 7.98631 4.98525 8.66679 5.00232H31.3332C32.0136 4.98532 32.5797 5.52185 32.5991 6.20223V18.4894C32.5991 18.8207 32.3305 19.0893 31.9991 19.0893Z"
        fill={colour}
      />
    </svg>
  )
}

EmailIcon.defaultProps = {
  colour: '#000000',
}

EmailIcon.propTypes = {
  colour: PropTypes.string,
}

export default EmailIcon
