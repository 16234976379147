import '../../../../../styles/editor-flow/find-replace/find-replace-external-link.scss'
import {
  AUTO_CLOSE_WINDOW_HEIGHT,
  FindReplaceTabs,
} from '../../../../../containers/FindReplaceContainer'
import TabNavigation from '../../../../consumer-flow/structure/tab-navigation/TabNavigation'
import React, { useState } from 'react'
import axios from 'axios'
import useAuthToken from '../../../../../hooks/useAuthToken'
import { getUserApiRequirements } from '../../../../../redux/user/user'
import { useSelector } from 'react-redux'
import { cloneDeep, set } from 'lodash'
import MaterialLoader from '../../../../global-components/elements/MaterialLoader'
import { selectActiveOperatingLocation } from '../../../../../redux/user/userSelections'
import FindReplaceExternalLinkForm from './FindReplaceExternalLinkForm'
import processFindReplaceExternalLinkApiData from '../../../../../utilities/find-replace/processFindReplaceExternalLinkApiData'
import FindReplaceLinkTable from '../FindReplaceLinkTable'
import fetchFindExternalLink from '../../../../../services/find-replace/fetchFindExternalLink'
import updateReplaceExternalLink from '../../../../../services/find-replace/updateReplaceExternalLink'
import ErrorBoundary from '../../../../global-components/elements/ErrorBoundary'

const FindReplaceExternalLinkContainer = () => {
  const { token } = useAuthToken({})
  const { activeClientId } = useSelector(getUserApiRequirements)
  const operatingLocation = useSelector(selectActiveOperatingLocation)
  const [replaceValue, setReplaceValue] = useState('')
  const [replaceTarget, setReplaceTarget] = useState('')
  const [searchResultsState, setSearchResultsState] = useState({
    loading: false,
    error: false,
    data: null,
  })
  const [tabsAreOpen, setTabsAreOpen] = useState(true)
  const items = searchResultsState?.data?.items
  const entries = searchResultsState?.data?.entries

  async function handleFormSubmit({ find, replace, replaceTarget }) {
    setSearchResultsState({ ...searchResultsState, loading: true })
    setReplaceValue(replace)
    setReplaceTarget(replaceTarget)

    fetchFindExternalLink({
      activeClientId,
      token,
      find,
    })
      .then(data => {
        setSearchResultsState({
          ...searchResultsState,
          data,
          loading: false,
        })
        if (
          data?.entries?.length > 0 &&
          window.innerHeight < AUTO_CLOSE_WINDOW_HEIGHT
        ) {
          setTabsAreOpen(false)
        }
      })
      .catch(() => {
        setSearchResultsState({
          ...searchResultsState,
          loading: false,
        })
      })
  }

  function handleReplace(entry) {
    if (entry) {
      setSearchResultsState({
        ...searchResultsState,
        loading: true,
      })
      updateReplaceExternalLink({
        entry,
        items,
        replaceTarget,
        replaceValue,
        activeClientId,
        entries,
        token,
        operatingLocation,
      })
        .then(data => {
          setSearchResultsState({
            ...searchResultsState,
            loading: false,
            data,
          })
        })
        .catch(() => {
          setSearchResultsState({
            ...searchResultsState,
            loading: false,
          })
        })
    }
  }

  return (
    <div className="find-replace__container">
      <div className="find-replace__header container">
        <TabNavigation
          linkOptions={FindReplaceTabs}
          isOpen={tabsAreOpen}
          onSetOpen={setTabsAreOpen}
        >
          <FindReplaceExternalLinkForm onSubmit={handleFormSubmit} />
        </TabNavigation>
      </div>
      {entries?.length > 0 ? (
        <div className="find-replace__results">
          <FindReplaceLinkTable
            items={items}
            entries={entries}
            replaceValue={replaceValue}
            onReplaceClick={handleReplace}
          />
        </div>
      ) : null}
      {entries?.length === 0 ? (
        <ErrorBoundary
          containerClasses="find-replace__error-boundary error-boundary section--md"
          errorTitle="No Results Found"
          errorMessage="Sorry, your search query did not yield any matching or relevant results. Please try again."
        />
      ) : null}
      {searchResultsState.loading && (
        <MaterialLoader containerClasses="overlay-loader" />
      )}
    </div>
  )
}

export default FindReplaceExternalLinkContainer
