import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { get, isEmpty } from 'lodash'
import { useParams, NavLink, Link } from 'react-router-dom'
import {
  selectActiveClientId,
  selectActiveOperatingLocation,
} from '../../../../redux/user/userSelections'
import { selectWorkflowsState } from '../../../../redux/workflows/workflows'
import routes from '../../../../configuration/routes'
import getCategoriesByWorkflows from '../../../../utilities/categories/getCategoriesByWorkflows'
import convertStringToSlug from '../../../../utilities/slug/convertStringToSlug'
import getIconComponent from '../../../../utilities/icon/getIconComponent'
import { ReactComponent as BackIcon } from '../../../../assets/icons/back-arrow-icon.svg'
import { ReactComponent as FilterArrowIcon } from '../../../../assets/icons/forward-arrow-icon.svg'
import '../../../../styles/consumer-flow/browse-workflows/browse-workflows-filters.scss'
import TableListGridToggle from '../../../global-components/table/TableListGridToggle'

function BrowseWorkflowsFilters({
  activeSubcategories,
  setActiveSubcategories,
  dataView,
  onDataViewChange,
}) {
  const { category } = useParams()
  const activeClientId = useSelector(selectActiveClientId)
  const activeOperatingLocation = useSelector(selectActiveOperatingLocation)
  const workflowsState = useSelector(selectWorkflowsState)
  const workflowsByClient = get(
    workflowsState,
    `workflows.${activeOperatingLocation}-${activeClientId}`,
    [],
  )
  const categoriesByWorkflows = getCategoriesByWorkflows(workflowsByClient)
  const activeCategoryData = category
    ? categoriesByWorkflows.find(
        categoryData =>
          categoryData?.category?.replace(/\W+/g, '-').toLowerCase() ===
          category,
      )
    : null
  const subcategoriesByActiveCategory = get(
    activeCategoryData,
    'subcategories',
    [],
  )

  const handleSubcategoryEvent = (isActiveSubcategory, subcategory) => {
    if (isActiveSubcategory) {
      setActiveSubcategories([
        ...activeSubcategories.filter(subcat => subcat !== subcategory),
      ])
    } else {
      setActiveSubcategories([...activeSubcategories, subcategory])
    }
  }

  return (
    <div className="browse-workflows__filters container">
      <div className="section section--filter">
        {!category ? (
          <div className="browse-workflows__filters-heading">
            <h3>Select a Workflow Category</h3>

            <Link to={routes.dashboard} className="btn btn--white">
              <BackIcon />
              Workflows Dashboard
            </Link>
          </div>
        ) : null}

        <div
          className={`browse-workflows__filters-categories${
            category ? ' active' : ''
          }`}
        >
          {categoriesByWorkflows.map((category, i) => {
            const categoryName = get(category, 'category', null)
            const icon = get(category, 'icon', null)
            const iconColour = get(icon, 'colour', null) || undefined
            const Icon = getIconComponent(icon)

            return (
              <NavLink
                onClick={() => setActiveSubcategories([])}
                className="browse-workflows__filters-category"
                key={`workflows-category-${i}`}
                to={`${routes.browseWorkflows}/${convertStringToSlug(
                  categoryName,
                )}`}
              >
                <div className="browse-workflows__filters-category-content">
                  <p className="subtitle">Workflows</p>
                  <p className="title">{categoryName}</p>
                </div>

                {Icon ? (
                  <div className="browse-workflows__filters-category-icon">
                    <Icon colour={iconColour ? iconColour : null} />
                  </div>
                ) : null}
              </NavLink>
            )
          })}
        </div>
        <div className="browse-workflows__filters-subcategories-container">
          <div className="browse-workflows__filters-subcategories">
            {!isEmpty(subcategoriesByActiveCategory) ? (
              <>
                <FilterArrowIcon className="browse-workflows__filters-subcategories-arrow" />
                {subcategoriesByActiveCategory.map((subcategory, i) => {
                  const isActiveSubcategory = activeSubcategories.some(
                    activeSubcategory => activeSubcategory === subcategory,
                  )
                  return (
                    <button
                      key={`workflows-subcategory-${i}`}
                      onClick={() =>
                        handleSubcategoryEvent(isActiveSubcategory, subcategory)
                      }
                      className={`browse-workflows__filters-subcategory${
                        isActiveSubcategory ? ' active' : ''
                      }`}
                    >
                      {subcategory}
                    </button>
                  )
                })}
              </>
            ) : null}
          </div>
          {category ? (
            <TableListGridToggle value={dataView} onChange={onDataViewChange} />
          ) : null}
        </div>
      </div>
    </div>
  )
}

BrowseWorkflowsFilters.propTypes = {
  activeSubcategories: PropTypes.array,
  setActiveSubcategories: PropTypes.func.isRequired,
  dataView: PropTypes.string,
  onDataViewChange: PropTypes.func.isRequired,
}

export default BrowseWorkflowsFilters
