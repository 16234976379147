import React from 'react'
import PropTypes from 'prop-types'

function DeadlineIcon({ colour }, props) {
  return (
    <svg
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g mask="url(#mask0_101_31)">
        <path
          d="M32.414 18.187C32.7722 19.4818 32.9636 20.8458 32.9636 22.2545C32.9636 30.6593 26.1502 37.4726 17.7455 37.4726C9.34077 37.4726 2.52734 30.6593 2.52734 22.2545C2.52734 13.8497 9.34077 7.03638 17.7455 7.03638C18.0942 7.03638 18.4403 7.04812 18.7832 7.07125"
          stroke={colour}
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M30.523 20.0545C30.6453 20.7696 30.709 21.5046 30.709 22.2546C30.709 29.4142 24.905 35.2182 17.7453 35.2182C10.5858 35.2182 4.78174 29.4142 4.78174 22.2546C4.78174 15.095 10.5858 9.29102 17.7453 9.29102C18.5708 9.29102 19.3782 9.36815 20.1608 9.51566"
          stroke={colour}
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M27.2912 17.2676L20.2261 5.0304C19.5838 3.91799 20.3867 2.52734 21.6712 2.52734H35.8015C37.0861 2.52734 37.8889 3.91799 37.2466 5.0304L30.1815 17.2676C29.5392 18.3801 27.9335 18.3801 27.2912 17.2676Z"
          stroke={colour}
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M28.7222 14.2846V7.521"
          stroke={colour}
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M18.8727 22.2544C18.8727 21.6319 18.368 21.1272 17.7454 21.1272C17.1228 21.1272 16.6182 21.6319 16.6182 22.2544C16.6182 22.877 17.1228 23.3817 17.7454 23.3817C18.368 23.3817 18.8727 22.877 18.8727 22.2544Z"
          stroke={colour}
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M18.8728 22.2546H28.4546"
          stroke={colour}
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16.7802 23.22L10.4182 29.5819"
          stroke={colour}
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.03638 22.2546H8.16363"
          stroke={colour}
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M17.7454 32.9637V31.8364"
          stroke={colour}
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M17.7454 12.6727V11.5454"
          stroke={colour}
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.3909 12.9802L12.6727 13.4683"
          stroke={colour}
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M22.8181 31.0408L23.0999 31.5289"
          stroke={colour}
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.47119 27.609L8.9593 27.3271"
          stroke={colour}
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.6727 31.0408L12.3909 31.5289"
          stroke={colour}
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M27.0198 27.609L26.5317 27.3271"
          stroke={colour}
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.9593 17.1817L8.47119 16.8999"
          stroke={colour}
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  )
}

DeadlineIcon.defaultProps = {
  colour: '#000000',
}

DeadlineIcon.propTypes = {
  colour: PropTypes.string,
}

export default DeadlineIcon
