import React from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'
import { useNavigate, useParams } from 'react-router-dom'
import routes from '../../../../configuration/routes'
import getIconComponent from '../../../../utilities/icon/getIconComponent'
import validateUserManagementAccess from '../../../../utilities/permissions/validateUserManagementAccess'
import { ReactComponent as GearIcon } from '../../../../assets/icons/gear-icon.svg'
import { ReactComponent as DeleteIcon } from '../../../../assets/icons/delete-icon.svg'
import { ReactComponent as DuplicateIcon } from '../../../../assets/icons/duplicate-icon.svg'

function ManageWorkflowsStepsCard({
  handleDeleteEvent,
  handleDuplicateEvent,
  handleEditEvent,
  index,
  parentIndex,
  stepData,
}) {
  const isManager = validateUserManagementAccess()
  const { workflow } = useParams()
  const navigate = useNavigate()
  const workflowId = get(stepData, 'workflowId', null)
  const stepId = get(stepData, '_id', null)
  const stepName = get(stepData, 'name', null)
  const stepsStatus = get(stepData, 'status', 'draft')
  const icon = get(stepData, 'icon', null)
  const iconColour = get(icon, 'colour', null) || undefined
  const Icon = getIconComponent(icon)
  const stepSlug = get(stepData, 'slug', null)

  return (
    <div className="manage-workflow__steps-card">
      <div
        onClick={() =>
          navigate(`${routes.manageWorkflows}/${workflow}/${stepSlug}`)
        }
        className="manage-workflow__steps-card-details"
        tabIndex={0}
      >
        <div className="manage-workflow__steps-card-details-icon">
          {Icon ? <Icon colour={iconColour} /> : null}
        </div>

        <p className="manage-workflow__steps-card-details-name">{stepName}</p>
      </div>

      <div className="manage-workflow__steps-card-actions">
        <div className="manage-workflow__steps-card-actions-details">
          <div className="manage-workflow__steps-card-details-step">
            Step {Number(parentIndex) >= 0 ? `${Number(parentIndex) + 1}.` : ''}
            {Number(index) + 1}
          </div>

          {isManager ? (
            <div className={`status-flag lg ${stepsStatus}`}>{stepsStatus}</div>
          ) : null}
        </div>

        <div className="manage-workflow__steps-card-actions-toggles">
          <button
            className="btn btn--white btn--icon-small"
            onClick={() => handleDuplicateEvent(workflowId, stepId)}
            type="button"
            data-tooltip="Duplicate Step"
          >
            <DuplicateIcon />
          </button>

          <button
            className="btn btn--white btn--icon-small"
            onClick={() => handleEditEvent(stepId)}
            type="button"
            data-tooltip="Configure Step"
          >
            <GearIcon />
          </button>

          <button
            className="btn btn--red btn--icon-small"
            onClick={() => handleDeleteEvent(stepData)}
            type="button"
            data-tooltip="Delete Step"
          >
            <DeleteIcon />
          </button>
        </div>
      </div>
    </div>
  )
}

ManageWorkflowsStepsCard.propTypes = {
  handleDeleteEvent: PropTypes.func,
  handleDuplicateEvent: PropTypes.func,
  handleEditEvent: PropTypes.func,
  index: PropTypes.number,
  parentIndex: PropTypes.number,
  stepData: PropTypes.object,
}

export default ManageWorkflowsStepsCard
