import React from 'react'
import PropTypes from 'prop-types'
import { withAuthenticationRequired } from '@auth0/auth0-react'
import MaterialLoader from '../components/global-components/elements/MaterialLoader'

function AuthProtectedRoute({ component }) {
  const Component = withAuthenticationRequired(component, {
    onRedirecting: () => <MaterialLoader containerClasses="container-loader" />,
  })

  return <Component />
}

AuthProtectedRoute.propTypes = {
  component: PropTypes.func,
}

export default AuthProtectedRoute
