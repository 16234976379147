import React from 'react'
import { get } from 'lodash'
import supportedIcons from '../../configuration/supportedIcons'
function getIconComponent(iconData) {
  let IconComponent = null

  const icon = supportedIcons.find(
    supportedIcon => supportedIcon?.id === iconData?.id,
  )
  const iconComponent = get(icon, 'component', null)

  if (icon && iconComponent) {
    IconComponent = iconComponent
  }

  return IconComponent
}

export default getIconComponent
