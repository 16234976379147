import React, { useEffect } from 'react'
import { Routes, Route, useLocation } from 'react-router-dom'
import { get } from 'lodash'
import { mixpanelTrackPageView } from '../../utilities/analytics/mixpanelEvents'
import routes from '../../configuration/routes'
import validateUserRouteAccess from '../../utilities/permissions/validateUserRouteAccess'
import PermissionProtectedRoute from '../../hooks/PermissionProtectedRoute'
import usePreviousLocation from '../../hooks/usePreviousLocation'
import Footer from '../../components/global-components/structure/footer/footer'

// Parent Route Views
import BrowseWorkflowsContainer from '../BrowseWorkflowsContainer'
import ManageWorkflowContainer from '../ManageWorkflowContainer'
import PlatformDashboardContainer from '../PlatformDashboardContainer'
import ViewMyWorkflowsContainer from '../ViewMyWorkflowsContainer'
import WhatsNewContainer from '../WhatsNewContainer'

// Nested Child Route Components
import BrowseWorkflow from '../../components/consumer-flow/browse-workflow/BrowseWorkflow'
import BrowseWorkflows from '../../components/consumer-flow/browse-workflows/BrowseWorkflows'
import ManageWorkflow from '../../components/editor-flow/manage-workflow/ManageWorkflow'
import ManageWorkflows from '../../components/editor-flow/manage-workflow/ManageWorkflows'
import PlatformDashboard from '../../components/global-components/platform-dashboard/PlatformDashboard'
import ViewMyCategoriesTable from '../../components/consumer-flow/view-my-workflows/ViewMyCategoriesTable'
import ViewMyWorkflows from '../../components/consumer-flow/view-my-workflows/ViewMyWorkflows'
import ManageWorkflowStep from '../../components/editor-flow/manage-workflow/ManageWorkflowStep'
import ScrollToTop from '../../components/global-components/elements/ScrollToTop'
import FindReplaceContainer from '../FindReplaceContainer'
import FindReplaceTextContainer from '../../components/editor-flow/find-replace/text/FindReplaceTextContainer'
import FindReplaceExternalLinkContainer from '../../components/editor-flow/find-replace/links/external-link/FindReplaceExternalLinkContainer'
import FindReplaceInternalLinkContainer from '../../components/editor-flow/find-replace/links/internal-link/FindReplaceInternalLinkContainer'
import ManageContentLocksContainer from '../ManageContentLocksContainer'

function Views() {
  const location = useLocation()
  const prevLocation = usePreviousLocation(location)
  const didPathChange =
    get(location, 'pathname', '') !== get(prevLocation, 'pathname', '')

  useEffect(() => {
    mixpanelTrackPageView(
      window.location.href,
      get(location, 'pathname', ''),
      didPathChange,
      get(prevLocation, 'pathname', ''),
    )
  }, [location])

  return (
    <ScrollToTop>
      <Routes>
        <Route
          path={`${routes.dashboard}`}
          element={
            <PermissionProtectedRoute
              userRouteAccess={validateUserRouteAccess(routes.dashboard)}
            >
              <PlatformDashboardContainer />
            </PermissionProtectedRoute>
          }
        >
          <Route path={routes.dashboard} element={<PlatformDashboard />} />
        </Route>

        <Route
          path={`${routes.browseWorkflows}`}
          element={
            <PermissionProtectedRoute
              userRouteAccess={validateUserRouteAccess(routes.browseWorkflows)}
            >
              <BrowseWorkflowsContainer />
            </PermissionProtectedRoute>
          }
        >
          {[
            `${routes.browseWorkflows}`,
            `${routes.browseWorkflows}/:category`,
          ].map((path, index) => (
            <Route
              path={path}
              element={<BrowseWorkflows />}
              key={`workflows-${index}`}
            />
          ))}

          {[
            `${routes.browseWorkflows}/:category/:workflow`,
            `${routes.browseWorkflows}/:category/:workflow/:step`,
          ].map((path, index) => (
            <Route
              path={path}
              element={<BrowseWorkflow />}
              key={`workflow-${index}`}
            />
          ))}
        </Route>

        <Route
          path={`${routes.manageWorkflows}`}
          element={
            <PermissionProtectedRoute
              userRouteAccess={validateUserRouteAccess(routes.manageWorkflows)}
            >
              <ManageWorkflowContainer />
            </PermissionProtectedRoute>
          }
        >
          <Route
            index
            path={routes.manageWorkflows}
            element={<ManageWorkflows />}
          />
          <Route
            path={`${routes.manageWorkflows}/:workflow`}
            element={<ManageWorkflow />}
          />
          <Route
            path={`${routes.manageWorkflows}/:workflow/:step`}
            element={<ManageWorkflowStep />}
          />
        </Route>

        <Route
          path={`${routes.viewMyWorkflows}`}
          element={
            <PermissionProtectedRoute
              userRouteAccess={validateUserRouteAccess(routes.viewMyWorkflows)}
            >
              <ViewMyWorkflowsContainer />
            </PermissionProtectedRoute>
          }
        >
          <Route index path="*" element={<ViewMyWorkflows />} />
          <Route
            path={routes.viewMyWorkflowsCategories}
            element={<ViewMyCategoriesTable />}
          />
        </Route>

        <Route
          path={`${routes.whatsNew}`}
          element={
            <PermissionProtectedRoute
              userRouteAccess={validateUserRouteAccess(routes.whatsNew)}
            >
              <WhatsNewContainer />
            </PermissionProtectedRoute>
          }
        ></Route>

        <Route
          path={routes.findReplace}
          element={
            <PermissionProtectedRoute
              userRouteAccess={validateUserRouteAccess(routes.findReplace)}
            >
              <FindReplaceContainer />
            </PermissionProtectedRoute>
          }
        >
          <Route
            index
            path={routes.findReplace}
            element={<FindReplaceTextContainer />}
          />
          <Route
            path={`${routes.findReplace}/internal-link`}
            element={<FindReplaceInternalLinkContainer />}
          />
          <Route
            path={`${routes.findReplace}/external-link`}
            element={<FindReplaceExternalLinkContainer />}
          />
        </Route>

        <Route
          path={routes.manageContentLocks}
          element={
            <PermissionProtectedRoute
              userRouteAccess={validateUserRouteAccess(
                routes.manageContentLocks,
              )}
            >
              <ManageContentLocksContainer />
            </PermissionProtectedRoute>
          }
        />
      </Routes>
      <Footer />
    </ScrollToTop>
  )
}

Views.propTypes = {}

export default Views
