import Modal from 'react-modal'
import { ReactComponent as CloseModalIcon } from '../../../assets/icons/close-icon.svg'
import MaterialLoader from '../elements/MaterialLoader'
import React, { useEffect, useState } from 'react'
import useAuthToken from '../../../hooks/useAuthToken'
import axios from 'axios'
import { getUserApiRequirements } from '../../../redux/user/user'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
const MediaLibraryDeleteModal = ({
  items,
  isOpen,
  isLoading,
  onCancel,
  onSubmit,
}) => {
  const { token } = useAuthToken({})
  const { activeClientId } = useSelector(getUserApiRequirements)
  const [value, setValue] = useState('')
  const [deleteCheck, setDeleteCheck] = useState({
    data: null,
    loading: true,
  })
  const submitButtonDisabled = value !== 'delete'
  const itemsList = items.slice(0, 5)

  useEffect(() => {
    setValue('')
  }, [isOpen])

  useEffect(() => {
    if (token) {
      axios
        .post(
          `${process.env.REACT_APP_PRODUCT_API}/api/v1/media/deleteCheck/${activeClientId}`,
          {
            ids: items.map(item => item._id),
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        )
        .then(res => {
          setDeleteCheck({
            loading: false,
            data: res?.data?.workflows,
          })
        })
        .catch(err => {
          console.error(err)
        })
    }
  }, [token])

  function handleInputChange(evt) {
    setValue(evt.target.value)
  }
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onCancel}
      className="modal modal--sm modal--overflow-hidden"
      overlayClassName="modal-overlay"
    >
      <div className="modal__header">
        <h3 className="body-font">Delete Files</h3>

        <button className="modal__close" onClick={onCancel}>
          <CloseModalIcon />
        </button>
      </div>
      <div className="modal__body">
        {deleteCheck?.data?.length > 0 && (
          <div className="warning warning--red">
            <p className="quarter-margin">
              These files are used in the following workflows:{' '}
            </p>
            <strong>
              {deleteCheck?.data?.map(workflow => workflow.name).join(', ')}
            </strong>
          </div>
        )}
        <p className="quarter-margin">
          Are you sure you want to delete the following files?
        </p>
        <ul>
          {itemsList.map(item => (
            <li key={item.id}>{item.name}</li>
          ))}
          {items.length > 5 && <li>And {items.length - 5} other files</li>}
        </ul>

        <label>
          Please type <b>delete</b> below to continue
        </label>
        <input value={value} onChange={handleInputChange} type="text" />
      </div>
      <div className="form__submit">
        <>
          <button className="btn btn--white" type="button" onClick={onCancel}>
            Cancel
          </button>

          <button
            className="btn btn--red"
            disabled={submitButtonDisabled}
            type="submit"
            onClick={() => onSubmit(items)}
          >
            Delete
          </button>
        </>
      </div>
      {(deleteCheck?.loading || isLoading) && (
        <MaterialLoader containerClasses="overlay-loader" />
      )}
    </Modal>
  )
}

MediaLibraryDeleteModal.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({})),
  isOpen: PropTypes.bool,
  isLoading: PropTypes.bool,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
}

export default MediaLibraryDeleteModal
