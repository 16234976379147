import {
  FETCH_WORKFLOW_BEGIN,
  FETCH_WORKFLOW_ERROR,
  FETCH_WORKFLOW_SUCCESS,
} from '../workflows/workflow'
import axios from 'axios'
import { get } from 'lodash'

const createComponent =
  ({ component, workflowId, workflowSlug, stepId, token, slug }) =>
  (dispatch, getState) => {
    const activeClient = getState().userSelectionsData.activeClient
    const activeOperatingLocation =
      getState().userSelectionsData.activeOperatingLocation
    const activeClientId = get(activeClient, 'id', null)

    dispatch({ type: FETCH_WORKFLOW_BEGIN })

    return axios({
      url: `${process.env.REACT_APP_PRODUCT_API}/api/v1/workflow/component/create/${activeClientId}`,
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      data: {
        operatingLocation: activeOperatingLocation,
        workflowSlug: workflowSlug,
        workflowId,
        stepId,
        component,
      },
    })
      .then(res => {
        dispatch({
          type: FETCH_WORKFLOW_SUCCESS,
          payload: {
            [`${activeOperatingLocation}-${activeClientId}-${workflowSlug}`]:
              res?.data,
          },
        })
      })
      .catch(error => {
        const customError = get(error, 'response.data.error', 'Error')

        dispatch({
          type: FETCH_WORKFLOW_ERROR,
          payload: customError,
        })
      })
  }

export default createComponent
