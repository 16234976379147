import { EDITOR_GALLERY_FIELD } from '../../../../configuration/editor'
import { useState } from 'react'
import { cloneDeep, get, isEmpty } from 'lodash'
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable'
import EditorGalleryFieldCard from './EditorGalleryFieldCard'
import getId from '../../../../utilities/editor/getId'
import EditorAlignmentButtons from '../elements/EditorAlignmentButtons'
import MediaLibrary from '../../../global-components/media-library/MediaLibrary'
import { ReactComponent as AddMediaIcon } from '../../../../assets/icons/add-media-icon.svg'
import '../../../../styles/editor-flow/editor-fields/editor-gallery-field.scss'
import PropTypes from 'prop-types'

function EditorGalleryField({ field, onChange, dropPath }) {
  const [mediaLibraryFolder, setMediaLibraryFolder] = useState(null)
  const [mediaLibraryState, setMediaLibraryState] = useState({
    open: false,
    block: null,
    imageId: null,
  })
  const blocks = get(field, 'blocks', [])
  const configurations = get(field, 'configurations', {})
  const alignment = get(configurations, 'alignment', 'left')

  const alignments = {
    left: 'flex-start',
    center: 'center',
    right: 'flex-end',
  }

  const style = {
    justifyContent: alignments[alignment],
  }

  function handleSubmit(newImage) {
    const { block } = mediaLibraryState
    const newBlocks = cloneDeep(blocks)

    if (block) {
      const blockIndex = newBlocks.findIndex(
        item => getId(item) === getId(block),
      )

      newBlocks[blockIndex].image = newImage
    } else {
      newBlocks.push({
        id: `block-${Date.now()}`,
        type: 'image',
        image: newImage,
      })
    }

    onChange({
      ...field,
      blocks: newBlocks,
    })

    setMediaLibraryState({
      ...mediaLibraryState,
      open: false,
    })
  }

  function setAlignment(align) {
    onChange({
      ...field,
      configurations: {
        ...configurations,
        alignment: align,
      },
    })
  }

  function handleCancel() {
    setMediaLibraryState({
      ...mediaLibraryState,
      open: false,
    })
  }

  function handleAddImage() {
    setMediaLibraryState({
      block: null,
      open: true,
    })
  }

  function handleChangeImage(block) {
    setMediaLibraryState({
      block,
      open: true,
      imageId: null,
    })
  }

  function handleDeleteImage(index) {
    onChange({
      ...field,
      blocks: blocks.filter((any, i) => i !== index),
    })
  }

  function handleCardChange(newBlock) {
    onChange({
      ...field,
      blocks: blocks.map(block =>
        block.id === newBlock.id ? newBlock : block,
      ),
    })
  }

  function handleEditImage(block, image) {
    setMediaLibraryState({
      ...mediaLibraryState,
      block,
      open: true,
      imageId: image?._id,
    })
    setMediaLibraryFolder(image.folderId)
  }

  return (
    <div className="editor-gallery-field">
      <div className={`editor-gallery${!isEmpty(blocks) ? ' populated' : ''}`}>
        {!isEmpty(blocks) ? (
          <div className="editor-gallery-alignments">
            <EditorAlignmentButtons setAlignment={setAlignment} />
          </div>
        ) : null}

        {!isEmpty(blocks) ? (
          <div className="editor-gallery-cards" style={style}>
            <SortableContext
              items={blocks.map(block => getId(block))}
              strategy={verticalListSortingStrategy}
            >
              {blocks.map((block, i) => (
                <EditorGalleryFieldCard
                  key={getId(block)}
                  block={block}
                  dropPath={[...dropPath, 'blocks']}
                  index={i}
                  onChange={handleCardChange}
                  onSelectImage={handleChangeImage}
                  onEditImage={handleEditImage}
                  onDeleteClick={() => handleDeleteImage(i)}
                />
              ))}
            </SortableContext>
          </div>
        ) : null}

        {mediaLibraryState.open ? (
          <MediaLibrary
            isOpen={mediaLibraryState.open}
            onCancel={handleCancel}
            onSubmit={handleSubmit}
            itemId={mediaLibraryState.imageId}
            mode={mediaLibraryState.imageId ? 'edit' : 'select'}
            filter="image"
            folderId={mediaLibraryFolder}
            onFolderChange={setMediaLibraryFolder}
          />
        ) : null}
      </div>

      <div className="editor-gallery-actions">
        <button className="btn btn--white" onClick={handleAddImage}>
          <AddMediaIcon />
          {isEmpty(blocks) ? 'Add First Image' : 'Add Another Image'}
        </button>
      </div>
    </div>
  )
}

export const createGalleryField = id => {
  return {
    id: id,
    type: 'field',
    fieldType: EDITOR_GALLERY_FIELD,
    configurations: {},
    blocks: [],
  }
}

EditorGalleryField.propTypes = {
  field: PropTypes.shape({
    id: PropTypes.string,
    type: PropTypes.string,
    fieldType: PropTypes.string,
    configurations: PropTypes.shape({
      alignment: PropTypes.string,
    }),
    blocks: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        image: PropTypes.shape({
          id: PropTypes.string,
          url: PropTypes.string,
        }).isRequired,
      }).isRequired,
    ).isRequired,
  }).isRequired,
  onChange: PropTypes.func,
  dropPath: PropTypes.array,
}

export default EditorGalleryField
