import clsx from 'clsx'
import React, { useRef } from 'react'
import { useDraggable } from '@dnd-kit/core'
import PropTypes from 'prop-types'
import { ReactComponent as CloseModalIcon } from '../../../assets/icons/close-icon.svg'

const MediaLibraryUploadingItem = ({ item, onRemoveClick }) => {
  return (
    <div className={clsx('media-library__content-grid__item', {})}>
      <div className="media-library__content-grid__uploading-item">
        {item.status === 'error' ? (
          <div className="media-library__content-grid__errors-container">
            {item?.errors.map(error => (
              <div key={error}>{error}</div>
            ))}
            <button onClick={() => onRemoveClick(item)}>
              <CloseModalIcon />
            </button>
          </div>
        ) : (
          <div className="media-library__content-grid__progress-container">
            <div className="media-library__content-grid__progress-bar">
              <div
                className="media-library__content-grid__progress-amount"
                style={{ width: `${item.progress}%` }}
              ></div>
            </div>
          </div>
        )}

        <div className="media-library__content-grid__item-name">
          {item.name}
        </div>
      </div>
    </div>
  )
}

MediaLibraryUploadingItem.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string,
    status: PropTypes.string,
    errors: PropTypes.arrayOf(PropTypes.string),
    name: PropTypes.string,
    progress: PropTypes.number,
  }),
  isSelected: PropTypes.bool,
  onClick: PropTypes.func,
  onDoubleClick: PropTypes.func,
  onRemoveClick: PropTypes.func,
}

export default MediaLibraryUploadingItem
