import clsx from 'clsx'
import React, { useRef } from 'react'
import { useDraggable } from '@dnd-kit/core'
import PropTypes from 'prop-types'
import { ReactComponent as CSVIcon } from '../../../assets/icons/file-types/csv.svg'
import { ReactComponent as DOCIcon } from '../../../assets/icons/file-types/doc.svg'
import { ReactComponent as JSONIcon } from '../../../assets/icons/file-types/json.svg'
import { ReactComponent as PDFIcon } from '../../../assets/icons/file-types/pdf.svg'
import { ReactComponent as TXTIcon } from '../../../assets/icons/file-types/txt.svg'
import { ReactComponent as PPTIcon } from '../../../assets/icons/file-types/ppt.svg'
import { ReactComponent as XLSIcon } from '../../../assets/icons/file-types/xls.svg'

const MediaLibraryDocumentItem = ({
  item,
  isSelected,
  onClick,
  onDoubleClick,
}) => {
  const id = `media-library-item-${item._id}`
  const fileType = item?.fileType
  const { attributes, listeners, setNodeRef } = useDraggable({
    data: {
      media: item,
      type: 'media',
    },
    id,
  })

  function handleMouseDown(evt) {
    onClick(evt, item)
  }

  function handleDoubleClick() {
    onDoubleClick(item)
  }

  return (
    <div
      key={item._id}
      className={clsx('media-library__content-grid__item', {
        selected: isSelected,
      })}
      id={id}
      ref={setNodeRef}
      {...listeners}
      {...attributes}
    >
      <button
        onMouseDown={handleMouseDown}
        onClick={handleMouseDown}
        onDoubleClick={handleDoubleClick}
      >
        <MediaLibraryDocumentIcon
          type={fileType}
          containerClass="media-library__document-icon"
        />
        <div
          className="media-library__content-grid__item-name"
          data-tooltip={item.name}
        >
          {item.name}
        </div>
        <div className="media-library__content-grid__item-checkmark"></div>
      </button>
    </div>
  )
}

export const MediaLibraryDocumentIcon = ({ containerClass, type }) => {
  function getIcon() {
    switch (type) {
      case 'csv':
        return <CSVIcon />
      case 'doc':
      case 'docx':
      case 'odt':
        return <DOCIcon />
      case 'json':
        return <JSONIcon />
      case 'odp':
      case 'ppt':
      case 'pptx':
        return <PPTIcon />
      case 'ods':
      case 'xls':
      case 'xlsx':
        return <XLSIcon />
      case 'pdf':
        return <PDFIcon />
      case 'txt':
        return <TXTIcon />
      default:
        return <TXTIcon />
    }
  }
  return <div className={containerClass}>{getIcon()}</div>
}

MediaLibraryDocumentIcon.propTypes = {
  type: PropTypes.string,
  containerClass: PropTypes.string,
}

MediaLibraryDocumentItem.propTypes = {
  item: PropTypes.shape({
    _id: PropTypes.string,
    name: PropTypes.string,
    fileType: PropTypes.string,
  }),
  isSelected: PropTypes.bool,
  onClick: PropTypes.func,
  onDoubleClick: PropTypes.func,
}

export default MediaLibraryDocumentItem
