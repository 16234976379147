import { rectSwappingStrategy, SortableContext } from '@dnd-kit/sortable'
import { cloneDeep } from 'lodash'
import { EDITOR_CARDS, EDITOR_CONTENT } from '../../../../configuration/editor'
import EditorContentSubcomponent from './EditorContentSubcomponent'
import EditorLayoutSelector from '../../editor-core/EditorLayoutSelector'
import '../../../../styles/editor-flow/editor-components/editor-content-component.scss'
import getId from '../../../../utilities/editor/getId'
import PropTypes from 'prop-types'

function EditorContentComponent({ component, onChange, dropPath }) {
  const { subComponents, configurations } = component
  const { layout: layoutConfig } = configurations
  const layoutOptions = [
    '100',
    '50-50',
    '70-30',
    '30-70',
    '33-33-33',
    '25-25-25-25',
  ]

  function handleSubcomponentChange(subcomponent) {
    const newComponent = cloneDeep(component)
    const subcomponentIndex = newComponent.subComponents.findIndex(
      subcomp => getId(subcomp) === getId(subcomponent),
    )
    newComponent.subComponents[subcomponentIndex] = subcomponent
    onChange(newComponent)
  }

  function handleDeleteClick(subcomponent) {
    onChange({
      ...component,
      subComponents: component.subComponents.filter(
        item => getId(item) !== getId(subcomponent),
      ),
    })
  }

  function handleLayoutChange(value) {
    const currentLayoutCount = layoutConfig.split('-').length
    const newLayoutCount = value.split('-').length
    const newData = cloneDeep(component)
    newData.configurations.layout = value
    onChange(newData)

    if (newLayoutCount > currentLayoutCount) {
      for (let i = 0; i < newLayoutCount - currentLayoutCount; i++) {
        newData.subComponents.push({ ...createEmptyContent() })
      }
    } else if (newLayoutCount < currentLayoutCount) {
      newData.subComponents = newData.subComponents.slice(0, newLayoutCount)
    }
    onChange(newData)
  }

  return (
    <div className="editor-content-component">
      <div className="content-subcomponent-container">
        <SortableContext
          items={subComponents.map(item => getId(item)) || []}
          strategy={rectSwappingStrategy}
        >
          {subComponents?.map((subcomponent, i) => (
            <EditorContentSubcomponent
              subcomponent={subcomponent}
              index={i}
              key={getId(subcomponent)}
              onChange={handleSubcomponentChange}
              onDeleteClick={handleDeleteClick}
              dropPath={[...dropPath, 'subComponents']}
              layout={`layout-${layoutConfig}`}
            />
          ))}
        </SortableContext>
      </div>

      <EditorLayoutSelector
        label="Layout"
        value={layoutConfig}
        options={layoutOptions}
        onChange={handleLayoutChange}
      />
    </div>
  )
}

export const createEmptyContent = () => {
  const id = `temp-${Date.now()}-${Math.random()}`
  return {
    id,
    type: 'subComponent',
    subComponentType: 'content',
    fields: [],
  }
}

export const createContentComponent = id => {
  return {
    id,
    componentType: EDITOR_CONTENT,
    configurations: {
      layout: '100',
    },
    subComponents: [createEmptyContent()],
  }
}

EditorContentComponent.propTypes = {
  component: PropTypes.object,
  onChange: PropTypes.func,
  dropPath: PropTypes.array,
}

export default EditorContentComponent
