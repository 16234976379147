import React from 'react'
import PropTypes from 'prop-types'
import VideoField from '../base-fields/video/VideoField'

const Video = ({ component }) => {
  const subcomponent = component?.subComponents?.[0] || {}
  const { fields } = subcomponent || []
  const field = fields[0]

  return (
    <div className="video">
      <VideoField field={field} />
    </div>
  )
}

Video.propTypes = {
  component: PropTypes.object,
}

export default Video
