// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.material-loader {
  margin: 0 auto 0;
}
.material-loader svg {
  width: 26px;
}`, "",{"version":3,"sources":["webpack://./src/styles/global-components/elements/material-loader.scss"],"names":[],"mappings":"AAEA;EACE,gBAAA;AADF;AAGE;EACE,WAAA;AADJ","sourcesContent":["@import '../../configuration/config';\n\n.material-loader {\n  margin: 0 auto 0;\n\n  svg {\n    width: 26px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
