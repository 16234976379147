import { useDispatch } from 'react-redux'

import Modal from '../../../global-components/elements/Modal'
import React from 'react'
import { ReactComponent as SmileAlertIcon } from '../../../../assets/icons/message-smile-square-icon.svg'
import { useNavigate } from 'react-router-dom'
import routes from '../../../../configuration/routes'
import PropTypes from 'prop-types'

const WorkflowEditorStepOutOfDateModal = ({ workflow, step }) => {
  const navigate = useNavigate()
  const stepIndex = workflow?.steps?.findIndex(item => item.slug === step?.slug)

  function handleGotoPreviousStep() {
    const previousStep = workflow?.steps?.[stepIndex - 1]
    navigate(`${routes.manageWorkflows}/${workflow?.slug}/${previousStep.slug}`)
  }
  function handleGotoNextStep() {
    const previousStep = workflow?.steps?.[stepIndex + 1]
    navigate(`${routes.manageWorkflows}/${workflow?.slug}/${previousStep.slug}`)
  }

  function handleRefresh() {
    window.location.reload()
  }

  return (
    <Modal modalClassName="modal--delete-workflow modal--sm" isOpen={true}>
      <div className="modal__header">
        <h3 className="flex align-center">
          <SmileAlertIcon />
          Good News!
        </h3>
      </div>
      <div className="modal__body">
        <br />
        <p>
          This step has recently been changed, please refresh your page to get a
          fresh copy.
        </p>
      </div>
      <div className="modal__submit">
        {stepIndex > 0 ? (
          <button className="btn" onClick={handleGotoPreviousStep}>
            Previous Step
          </button>
        ) : null}
        {stepIndex < workflow?.steps?.length - 1 ? (
          <button className="btn" onClick={handleGotoNextStep}>
            Next Step
          </button>
        ) : null}
        <button className="btn btn--dark-purple" onClick={handleRefresh}>
          Refresh to Update
        </button>
      </div>
    </Modal>
  )
}

WorkflowEditorStepOutOfDateModal.propTypes = {
  workflow: PropTypes.object,
  step: PropTypes.object,
}

export default WorkflowEditorStepOutOfDateModal
