import { useSelector } from 'react-redux'
import { selectUserState } from '../../redux/user/user'
import { get } from 'lodash'

export default function validateUserManagementAccess() {
  const userState = useSelector(selectUserState)
  const userAccessLevel = get(userState, 'user.accessLevel', 'User')
  const managementRoles = ['Administrator', 'Manager', 'Super']

  return managementRoles.includes(userAccessLevel)
}
