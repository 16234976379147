import React, { useEffect, useState } from 'react'
import { ReactComponent as UpArrowIcon } from '../../../assets/icons/arrow-up-double-chevron.svg'

const ScrollTopButton = () => {
  const [offset, setOffset] = useState(null)

  const setScroll = () => {
    setOffset(window.scrollY)
  }

  useEffect(() => {
    window.addEventListener('scroll', setScroll)

    return () => {
      window.removeEventListener('scroll', setScroll)
    }
  }, [])

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }

  return offset > 80 ? (
    <button className="scroll-top" onClick={scrollToTop}>
      <UpArrowIcon />
    </button>
  ) : null
}

export default ScrollTopButton
