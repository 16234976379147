import {
  selectSocketId,
  selectSocketOutOfDateResources,
  SOCKET_SET_OUT_OF_DATE_RESOURCES,
} from '../../../redux/socket/socket'
import { useDispatch, useSelector } from 'react-redux'
import ResourceChangedToast from './ResourceChangedToast'
import { orderBy } from 'lodash'
import '../../../styles/global-components/resource-changed-toasts/resource-changed-toasts.scss'
import { selectUserEmail } from '../../../redux/user/user'
import PropTypes from 'prop-types'

const ResourceChangedToasts = ({ types, isPaused, resourceId, children }) => {
  const dispatch = useDispatch()
  const outOfDateResources = useSelector(selectSocketOutOfDateResources)
  const email = useSelector(selectUserEmail)
  const changedResources = orderBy(
    outOfDateResources.filter(resource => {
      if (resourceId) {
        return (
          resource.id === resourceId &&
          types?.includes(resource?.type) &&
          resource.email !== email
        )
      }
      return types?.includes(resource?.type) && resource.email !== email
    }),
    'receivedAt',
    'asc',
  )

  function handleDismissResource(resource) {
    const resources = outOfDateResources.map(item => {
      if (item.uuid === resource.uuid) {
        return {
          ...item,
          dismissed: true,
        }
      }
      return item
    })
    dispatch({
      type: SOCKET_SET_OUT_OF_DATE_RESOURCES,
      payload: resources,
    })
  }

  return (
    <div className="resource-changed-toasts">
      {changedResources.map(resource => (
        <ResourceChangedToast
          key={resource.uuid}
          resource={resource}
          onDismiss={handleDismissResource}
          isPaused={isPaused}
        >
          {children}
        </ResourceChangedToast>
      ))}
    </div>
  )
}
ResourceChangedToasts.propTypes = {
  types: PropTypes.arrayOf(PropTypes.string),
  resourceId: PropTypes.string,
  isPaused: PropTypes.bool,
  children: PropTypes.func,
}

export default ResourceChangedToasts
