import React from 'react'
import PropTypes from 'prop-types'

function WishlistIcon({ colour }, props) {
  return (
    <svg
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M16.7656 23.9375C16.3775 23.9375 16.0625 24.2525 16.0625 24.6406C16.0625 25.0287 16.3775 25.3438 16.7656 25.3438C17.1537 25.3438 17.4688 25.0287 17.4688 24.6406C17.4688 24.2525 17.1537 23.9375 16.7656 23.9375Z"
        fill={colour}
      />
      <path
        d="M16.7656 28.1562C16.3775 28.1562 16.0625 28.4713 16.0625 28.8594C16.0625 29.2475 16.3775 29.5625 16.7656 29.5625C17.1537 29.5625 17.4688 29.2475 17.4688 28.8594C17.4688 28.4713 17.1537 28.1562 16.7656 28.1562Z"
        fill={colour}
      />
      <path
        d="M28.8594 23.9375H19.5781C19.1898 23.9375 18.875 24.2523 18.875 24.6406C18.875 25.029 19.1898 25.3438 19.5781 25.3438H28.8594C29.2477 25.3438 29.5625 25.029 29.5625 24.6406C29.5625 24.2523 29.2477 23.9375 28.8594 23.9375Z"
        fill={colour}
      />
      <path
        d="M28.8594 28.1562H19.5781C19.1898 28.1562 18.875 28.471 18.875 28.8594C18.875 29.2477 19.1898 29.5625 19.5781 29.5625H28.8594C29.2477 29.5625 29.5625 29.2477 29.5625 28.8594C29.5625 28.471 29.2477 28.1562 28.8594 28.1562Z"
        fill={colour}
      />
      <path
        d="M4.10938 36.1249V37.2969C4.10938 37.6852 4.42413 38 4.8125 38C5.20087 38 5.51562 37.6852 5.51562 37.2969V36.1249L5.79688 35.75C6.97571 34.1784 7.625 32.2305 7.625 30.2656V10.4375H8.32812C8.71594 10.4375 9.03125 10.7528 9.03125 11.1406V17.6094C9.03125 17.9977 9.34601 18.3125 9.73438 18.3125C10.1227 18.3125 10.4375 17.9977 10.4375 17.6094V11.1406C10.4375 9.97745 9.4913 9.03125 8.32812 9.03125H7.625V7.625C7.625 6.93341 7.37643 6.28027 6.92188 5.76611V4.10938C6.92188 2.9462 5.97568 2 4.8125 2C3.64932 2 2.70312 2.9462 2.70312 4.10938V5.76666C2.25323 6.2767 2 6.93726 2 7.625V30.2656C2 32.2308 2.64929 34.1784 3.82812 35.75L4.10938 36.1249ZM6.21875 29.5625H3.40625V10.4375H6.21875V29.5625ZM3.40625 9.03125V7.625C3.40625 6.85294 4.03522 6.21875 4.8125 6.21875C5.5766 6.21875 6.21875 6.83838 6.21875 7.625V9.03125H3.40625ZM4.8125 3.40625C5.20032 3.40625 5.51562 3.72156 5.51562 4.10938V4.90259C5.28793 4.84354 5.05173 4.8125 4.8125 4.8125C4.57327 4.8125 4.33707 4.84354 4.10938 4.90231V4.10938C4.10938 3.72156 4.42468 3.40625 4.8125 3.40625ZM6.18607 30.9688C6.06192 32.3085 5.58621 33.6101 4.8125 34.7123C4.03879 33.6101 3.56308 32.3085 3.43893 30.9688H6.18607Z"
        fill={colour}
      />
      <path
        d="M38 13.3906V9.875C38 8.32428 36.7382 7.0625 35.1875 7.0625C35.0285 7.0625 15.3594 7.0625 15.3594 7.0625C13.4208 7.0625 11.8438 8.63959 11.8438 10.5781V32.375H9.73438C9.34601 32.375 9.03125 32.6898 9.03125 33.0781C9.03125 35.792 11.2392 38 13.9531 38H30.2656C32.3236 38 33.7812 36.2474 33.7812 34.3339V14.0938H37.2969C37.6852 14.0938 38 13.779 38 13.3906ZM13.9531 36.5938C12.2555 36.5938 10.8347 35.3839 10.5081 33.7812H26.75V34.4844C26.75 35.2751 27.0126 36.0057 27.4548 36.5938H13.9531ZM32.375 9.875V34.3339C32.375 35.4984 31.531 36.5938 30.2656 36.5938C29.1024 36.5938 28.1562 35.6476 28.1562 34.4844V33.0781C28.1562 32.6898 27.8415 32.375 27.4531 32.375H13.25V10.5781C13.25 9.41495 14.1962 8.46161 15.3594 8.46161H32.751C32.5101 8.87936 32.375 9.36826 32.375 9.875ZM36.5938 12.6875H33.7812V9.875C33.7812 9.08838 34.4201 8.46875 35.1875 8.46875C35.9629 8.46875 36.5938 9.09964 36.5938 9.875V12.6875Z"
        fill={colour}
      />
      <path
        d="M21.0805 19.8838C21.4812 20.2222 21.896 20.5721 22.336 20.9533C22.4681 21.0679 22.632 21.125 22.7963 21.125C22.9605 21.125 23.1248 21.0679 23.2569 20.9533C23.6966 20.5721 24.1111 20.2222 24.5121 19.8841C26.7503 17.9953 28.3672 16.6305 28.3672 14.6266C28.3672 12.7507 26.9892 11.2812 25.23 11.2812C24.0471 11.2812 23.2756 11.9154 22.7963 12.5606C22.317 11.9154 21.5452 11.2812 20.362 11.2812C18.6031 11.2812 17.2254 12.7507 17.2254 14.6266C17.2254 16.6305 18.8426 17.995 21.0805 19.8838ZM20.362 12.6875C21.6458 12.6875 22.1009 14.2193 22.1195 14.2846C22.2047 14.5862 22.4796 14.7936 22.7933 14.7952H22.7968C23.1102 14.7952 23.3863 14.5859 23.4731 14.2846C23.4917 14.2193 23.9468 12.6875 25.2298 12.6875C26.2166 12.6875 26.9609 13.5211 26.9609 14.6266C26.9609 15.9771 25.6978 17.043 23.6049 18.8094C23.3426 19.0307 23.0742 19.257 22.7963 19.4946C22.5181 19.257 22.2497 19.0307 21.9874 18.8091C19.8948 17.043 18.6317 15.9771 18.6317 14.6266C18.6317 13.5211 19.3757 12.6875 20.362 12.6875Z"
        fill={colour}
      />
    </svg>
  )
}

WishlistIcon.defaultProps = {
  colour: '#000000',
}

WishlistIcon.propTypes = {
  colour: PropTypes.string,
}

export default WishlistIcon
