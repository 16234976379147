import { getUserApiRequirements } from '../user/user'
import axios from 'axios'
import {
  selectCategories,
  SET_CATEGORIES,
  UPDATE_CATEGORY_PIN_BEGIN,
  UPDATE_CATEGORY_PIN_COMPLETE,
} from './categories'
import { cloneDeep } from 'lodash'

const updateCategoryWorkflowPin =
  ({ categoryId, workflowId, pinned, token }) =>
  async (dispatch, getState) => {
    const { activeClientId } = getUserApiRequirements(getState())
    dispatch({ type: UPDATE_CATEGORY_PIN_BEGIN, payload: workflowId })
    return axios
      .post(
        `${process.env.REACT_APP_PRODUCT_API}/api/v1/workflows/category/pin/${categoryId}`,
        {
          workflowId,
          pinned,
          clientId: activeClientId,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )
      .then(res => {
        const { pinned, categoryId, workflowId } = res?.data || {}
        const categories = cloneDeep(selectCategories(getState()))
        const category = categories[activeClientId]?.find(
          category => category._id === categoryId,
        )
        if (pinned) {
          category.pinnedWorkflows.push(workflowId)
        } else {
          category.pinnedWorkflows = category.pinnedWorkflows.filter(
            id => id !== workflowId,
          )
        }

        dispatch({ type: UPDATE_CATEGORY_PIN_COMPLETE, payload: workflowId })
        dispatch({ type: SET_CATEGORIES, payload: categories })
      })
  }

export default updateCategoryWorkflowPin
