import React from 'react'
import { NavLink } from 'react-router-dom'
import { useLocation } from 'react-router-dom'

import useBreadcrumbs from 'use-react-router-breadcrumbs'
import PropTypes from 'prop-types'

import '../../../../styles/global-components/bread-crumbs/bread-crumbs.scss'

function BreadCrumbs({ breadCrumbEvent }) {
  const location = useLocation()
  const breadcrumbs = useBreadcrumbs()

  return (
    <div className="bread-crumbs">
      {breadcrumbs.map(({ match, breadcrumb }) =>
        match.pathname !== location.pathname ? (
          <NavLink
            onClick={breadCrumbEvent ? () => breadCrumbEvent() : null}
            key={match.pathname}
            to={match.pathname}
          >
            {breadcrumb}
          </NavLink>
        ) : (
          <p className="current-route" key={match.pathname}>
            {breadcrumb}
          </p>
        ),
      )}
    </div>
  )
}

BreadCrumbs.propTypes = {
  breadCrumbEvent: PropTypes.func,
}

export default BreadCrumbs
