import React from 'react'
import PropTypes from 'prop-types'

function LikeIcon({ colour }, props) {
  return (
    <svg
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M26.5496 32.4655L23.6332 34.4246C21.2181 36.0471 18.3745 36.9135 15.465 36.9135L6.85516 36.9135"
        stroke={colour}
        strokeWidth="1.25"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.5415 19.7324L12.4131 14.87C11.8613 12.4918 13.3736 10.1251 15.7636 9.62673L18.3296 15.0437C19.1012 16.6726 20.2934 18.0662 21.7833 19.0806L26.5497 22.3261"
        stroke={colour}
        strokeWidth="1.25"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M36.8823 36.9053L28.5899 36.9053C27.4631 36.9053 26.5495 35.9918 26.5495 34.8649L26.5495 21.6578C26.5495 20.531 27.4631 19.6175 28.5899 19.6175L36.8823 19.6175L36.8823 36.9053Z"
        stroke={colour}
        strokeWidth="1.25"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.81 6.66223L21.3077 5.1645"
        stroke={colour}
        strokeWidth="1.25"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.5963 6.66223L10.0985 5.1645"
        stroke={colour}
        strokeWidth="1.25"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.7032 4.11804L15.7032 1.99999"
        stroke={colour}
        strokeWidth="1.25"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.3107 19.7692L4.14195 19.7692C2.95899 19.7692 1.99992 20.7282 1.99992 21.9113C1.99992 23.0942 2.95892 24.0533 4.14195 24.0533"
        stroke={colour}
        strokeWidth="1.25"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.14201 24.0532L4.86633 24.0532C3.68337 24.0532 2.7243 25.0122 2.7243 26.1952C2.7243 27.3782 3.6833 28.3373 4.86633 28.3373"
        stroke={colour}
        strokeWidth="1.25"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.86633 28.3372L5.66309 28.3372C4.48013 28.3372 3.52106 29.2962 3.52106 30.4792C3.52106 31.6623 4.48005 32.6213 5.66309 32.6213"
        stroke={colour}
        strokeWidth="1.25"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.66311 32.6213L6.82202 32.6213C5.63906 32.6213 4.67999 33.5803 4.67999 34.7633C4.67999 35.9463 5.63899 36.9053 6.82202 36.9053"
        stroke={colour}
        strokeWidth="1.25"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

LikeIcon.defaultProps = {
  colour: '#000000',
}

LikeIcon.propTypes = {
  colour: PropTypes.string,
}

export default LikeIcon
