// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.video-field {
  display: flex;
}
.video-field iframe {
  border: 0;
  max-width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/styles/consumer-flow/base-fields/video-field.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;AACF;AAAE;EACE,SAAA;EACA,eAAA;AAEJ","sourcesContent":[".video-field {\n  display: flex;\n  iframe {\n    border: 0;\n    max-width: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
