import React from 'react'
import PropTypes from 'prop-types'

function SlugField({
  label,
  type,
  required,
  field,
  form: { errors, touched },
  ...props
}) {
  return (
    <>
      {label && (
        <label htmlFor={field.name}>
          {label}
          {required && <sup className="required">*</sup>}
        </label>
      )}

      <input
        id={field.name}
        className={errors[field.name] ? 'error' : ''}
        type={type}
        {...field}
        {...props}
      />
      {errors[field.name] && (
        <div className="field-error">{errors[field.name]}</div>
      )}
    </>
  )
}

SlugField.defaultProps = {
  type: 'text',
}

SlugField.propTypes = {
  errors: PropTypes.object,
  field: PropTypes.object,
  form: PropTypes.object,
  label: PropTypes.string,
  required: PropTypes.bool,
  touched: PropTypes.object,
  type: PropTypes.string,
}

export default SlugField
