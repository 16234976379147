import React from 'react'
import PropTypes from 'prop-types'

function SignatureIcon({ colour }, props) {
  return (
    <svg
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.79362 12.912C8.91108 13.0295 9.06509 13.0883 9.21895 13.0883C9.37281 13.0883 9.52681 13.0295 9.64428 12.912L10.1213 12.4349L10.5984 12.912C10.7159 13.0295 10.8699 13.0883 11.0237 13.0883C11.1776 13.0883 11.3316 13.0295 11.4491 12.912C11.684 12.6771 11.684 12.2963 11.4491 12.0613L10.972 11.5843L11.4491 11.1072C11.684 10.8723 11.684 10.4915 11.4491 10.2566C11.2141 10.0216 10.8333 10.0216 10.5984 10.2566L10.1213 10.7336L9.64428 10.2566C9.40935 10.0216 9.02855 10.0216 8.79362 10.2566C8.5587 10.4915 8.5587 10.8723 8.79362 11.1072L9.27069 11.5843L8.79362 12.0613C8.5587 12.2963 8.5587 12.6771 8.79362 12.912Z"
        fill={colour}
      />
      <path
        d="M11.0237 7.07233C11.356 7.07233 11.6253 6.80298 11.6253 6.47073C11.6253 6.13848 11.356 5.86914 11.0237 5.86914C10.6915 5.86914 10.4221 6.13848 10.4221 6.47073C10.4221 6.80298 10.6915 7.07233 11.0237 7.07233Z"
        fill={colour}
      />
      <path
        d="M18.5437 8.87711C19.7046 8.87711 20.6493 7.93245 20.6493 6.77153C20.6493 5.61061 19.7046 4.66595 18.5437 4.66595C17.3828 4.66595 16.4381 5.61061 16.4381 6.77153C16.4381 7.93245 17.3829 8.87711 18.5437 8.87711ZM18.5437 5.86914C19.0414 5.86914 19.4461 6.27386 19.4461 6.77153C19.4461 7.2692 19.0414 7.67392 18.5437 7.67392C18.046 7.67392 17.6413 7.2692 17.6413 6.77153C17.6413 6.27386 18.0462 5.86914 18.5437 5.86914Z"
        fill={colour}
      />
      <path
        d="M23.2536 24.6613C22.8697 24.109 22.4394 23.4865 21.6976 23.5638C20.9086 23.6504 20.5805 24.4436 20.317 25.0807C20.284 25.1603 20.2445 25.2561 20.2042 25.3471C19.9262 24.7992 19.5985 23.6643 19.4228 23.0567C18.9919 21.565 18.8175 20.9464 18.1432 21.0365C17.4822 21.1244 17.5379 21.8162 17.5824 22.3719C17.637 23.0508 17.7285 24.1869 17.2443 24.8008C16.4785 25.7723 14.8164 25.4826 13.1484 25.0194C13.0796 24.5641 13.0035 24.0691 12.9207 23.5315C12.73 22.2928 12.5012 20.8055 12.2427 19.0177C12.1951 18.6887 11.8901 18.4649 11.5612 18.5083C11.2321 18.5558 11.0043 18.8612 11.0518 19.1898C11.311 20.9819 11.5403 22.4727 11.7316 23.7144C11.7822 24.0428 11.8297 24.3529 11.8743 24.6455C10.0282 24.095 8.31322 23.6377 7.52167 24.7762C6.78141 25.841 7.41474 27.3937 8.25426 28.4918C9.12642 29.6324 10.4517 30.6226 11.6993 30.6226C11.9468 30.6226 12.1915 30.5835 12.4287 30.499C13.6075 30.0792 13.7417 29.2928 13.3355 26.3125C15.172 26.7733 17.0804 26.9514 18.189 25.5455C18.4037 25.2734 18.5499 24.9673 18.6481 24.6486C19.0908 26.0001 19.5159 26.7865 20.2353 26.7862C20.2439 26.7862 20.2523 26.7862 20.2609 26.7859C20.9204 26.7692 21.1906 26.1162 21.4289 25.5402C21.5238 25.3111 21.6932 24.9013 21.8199 24.7786C21.9348 24.8714 22.1309 25.1537 22.2658 25.3475C22.5496 25.7559 22.9026 26.2641 23.4194 26.6614C24.752 27.6871 26.7564 27.7169 29.546 26.7533C29.8603 26.6449 30.0268 26.3024 29.9185 25.9884C29.8098 25.6737 29.4667 25.5088 29.1536 25.6158C26.8089 26.4254 25.1267 26.4569 24.1532 25.7077C23.7841 25.4247 23.5024 25.0191 23.2536 24.6613ZM12.0252 29.3662C11.3132 29.6197 10.1033 28.9297 9.21019 27.7614C8.5128 26.8494 8.21817 25.883 8.50994 25.4635C8.60574 25.3254 8.81314 25.2699 9.09153 25.2699C9.76216 25.2699 10.8437 25.5933 11.7661 25.8694C11.8665 25.8993 11.9679 25.9296 12.0703 25.9603C12.4314 28.4606 12.4444 29.217 12.0252 29.3662Z"
        fill={colour}
      />
      <path
        d="M37.7625 5.02812L35.8461 3.57257C35.7188 3.47616 35.5594 3.433 35.4007 3.45571C35.2424 3.47721 35.0997 3.56084 35.0029 3.68777L26.0362 15.4946H3.97187C2.88449 15.4946 2 16.3791 2 17.4665V34.5784C2 35.6657 2.88449 36.5502 3.97187 36.5502H35.522C36.6093 36.5502 37.4938 35.6657 37.4938 34.5784V17.4665C37.4938 16.3791 36.6093 15.4946 35.522 15.4946H30.569L37.8775 5.87125C38.0784 5.60655 38.0267 5.22905 37.7625 5.02812ZM24.4815 21.5208L23.6385 21.636L23.5233 20.793L34.288 6.61873L35.2461 7.34651L24.4815 21.5208ZM3.20304 34.5785V17.4665C3.20304 17.0469 3.54173 16.7071 3.95969 16.7002C3.85997 16.9359 3.80463 17.1949 3.80463 17.4665V34.5784C3.80463 34.85 3.85982 35.109 3.95969 35.3446C3.54173 35.3379 3.20304 34.9981 3.20304 34.5785ZM36.2906 17.4665V34.5784C36.2906 34.998 35.952 35.3377 35.534 35.3446C35.6337 35.109 35.6891 34.85 35.6891 34.5784V17.4665C35.6891 17.1949 35.6339 16.9359 35.534 16.7002C35.9521 16.7073 36.2906 17.047 36.2906 17.4665ZM33.7172 16.6978C34.141 16.6978 34.4859 17.0427 34.4859 17.4665V34.5784C34.4859 35.0022 34.141 35.3471 33.7172 35.3471H5.77665C5.35283 35.3471 5.00797 35.0022 5.00797 34.5784V17.4665C5.00797 17.0427 5.35283 16.6978 5.77665 16.6978H25.1225L22.4145 20.2636C22.3181 20.3909 22.2758 20.5509 22.2976 20.7089L22.5279 22.3948C22.5689 22.6965 22.8268 22.915 23.123 22.915C23.1504 22.915 23.1776 22.9132 23.2052 22.9094L24.8914 22.6792C25.0494 22.6577 25.1925 22.574 25.2892 22.4471L29.6554 16.6978H33.7172ZM35.9739 6.38817L35.0157 5.66039L35.5972 4.89472L36.5554 5.62234L35.9739 6.38817Z"
        fill={colour}
      />
      <path
        d="M7.41415 31.7376H6.21097C5.87874 31.7376 5.60938 32.007 5.60938 32.3392C5.60938 32.6715 5.87874 32.9408 6.21097 32.9408H7.41415C7.74638 32.9408 8.01575 32.6715 8.01575 32.3392C8.01575 32.007 7.74638 31.7376 7.41415 31.7376Z"
        fill={colour}
      />
      <path
        d="M20.8679 31.7376H18.6258C18.2935 31.7376 18.0242 32.007 18.0242 32.3392C18.0242 32.6715 18.2935 32.9408 18.6258 32.9408H20.8679C21.2001 32.9408 21.4695 32.6715 21.4695 32.3392C21.4695 32.007 21.2 31.7376 20.8679 31.7376Z"
        fill={colour}
      />
      <path
        d="M11.8987 31.7376H9.65652C9.32429 31.7376 9.05493 32.007 9.05493 32.3392C9.05493 32.6715 9.32429 32.9408 9.65652 32.9408H11.8987C12.2309 32.9408 12.5003 32.6715 12.5003 32.3392C12.5003 32.007 12.2309 31.7376 11.8987 31.7376Z"
        fill={colour}
      />
      <path
        d="M25.3525 31.7376H23.1104C22.7782 31.7376 22.5088 32.007 22.5088 32.3392C22.5088 32.6715 22.7782 32.9408 23.1104 32.9408H25.3525C25.6847 32.9408 25.9541 32.6715 25.9541 32.3392C25.9541 32.007 25.6847 31.7376 25.3525 31.7376Z"
        fill={colour}
      />
      <path
        d="M27.5949 31.7376C27.2626 31.7376 26.9933 32.007 26.9933 32.3392C26.9933 32.6715 27.2626 32.9408 27.5949 32.9408H29.837C30.1692 32.9408 30.4386 32.6715 30.4386 32.3392C30.4386 32.007 30.1692 31.7376 29.837 31.7376H27.5949Z"
        fill={colour}
      />
      <path
        d="M16.3832 31.7376H14.141C13.8088 31.7376 13.5394 32.007 13.5394 32.3392C13.5394 32.6715 13.8088 32.9408 14.141 32.9408H16.3832C16.7154 32.9408 16.9848 32.6715 16.9848 32.3392C16.9848 32.007 16.7154 31.7376 16.3832 31.7376Z"
        fill={colour}
      />
      <path
        d="M32.0795 32.9408H33.2827C33.6149 32.9408 33.8843 32.6715 33.8843 32.3392C33.8843 32.007 33.6149 31.7376 33.2827 31.7376H32.0795C31.7473 31.7376 31.4779 32.007 31.4779 32.3392C31.4779 32.6715 31.7473 32.9408 32.0795 32.9408Z"
        fill={colour}
      />
    </svg>
  )
}

SignatureIcon.defaultProps = {
  colour: '#000000',
}

SignatureIcon.propTypes = {
  colour: PropTypes.string,
}

export default SignatureIcon
