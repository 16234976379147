import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectManagedContentId,
  selectManageWorkflowModalVisibility,
  toggleManageWorkflowModal,
} from '../../../../redux/user/userSelections'
import ManageWorkflowForm from '../../../forms/ManageWorkflowForm'
import Modal from '../../../global-components/elements/Modal'
import { ReactComponent as CloseModalIcon } from '../../../../assets/icons/close-icon.svg'
import { emitSocketMessage } from '../../../../redux/middleware/socket/socket'
import { selectSocketId } from '../../../../redux/socket/socket'

function ManageWorkflowModal() {
  const dispatch = useDispatch()
  const managedContentId = useSelector(selectManagedContentId)
  const isManageWorkflowModalVisible = useSelector(
    selectManageWorkflowModalVisibility,
  )
  const socketId = useSelector(selectSocketId)

  useEffect(() => {
    if (managedContentId && socketId) {
      dispatch(
        emitSocketMessage('configureWorkflow', {
          workflowId: managedContentId,
        }),
      )
    }
  }, [managedContentId, socketId])

  function handleToggleCreateModal() {
    dispatch(
      emitSocketMessage('configureWorkflowComplete', {
        workflowId: managedContentId,
        hasChanged: false,
      }),
    )
    dispatch(toggleManageWorkflowModal(!isManageWorkflowModalVisible))
  }

  return (
    <Modal
      modalClassName="modal--delete-workflow modal--md"
      isOpen={isManageWorkflowModalVisible}
      onRequestClose={() => handleToggleCreateModal()}
    >
      <div className="modal__header">
        <h3>{managedContentId ? 'Configure' : 'Create'} Workflow</h3>
        <button
          className="modal__close"
          onClick={() => handleToggleCreateModal()}
        >
          <CloseModalIcon />
        </button>
      </div>

      <div className="modal__body">
        <ManageWorkflowForm formCancelEvent={handleToggleCreateModal} />
      </div>
    </Modal>
  )
}

export default ManageWorkflowModal
