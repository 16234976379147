import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { get, isEmpty } from 'lodash'
import {
  selectActiveClientId,
  selectActiveOperatingLocation,
} from '../../../redux/user/userSelections'
import { selectWorkflowState } from '../../../redux/workflows/workflow'
import getWorkflowsDataById from '../../../utilities/workflows/getWorkflowsDataById'
import getCategoriesByWorkflows from '../../../utilities/categories/getCategoriesByWorkflows'
import RelatedWorkflowsFilters from './elements/RelatedWorkflowsFilters'
import '../../../styles/consumer-flow/related-workflows/related-workflows.scss'
import RelatedWorkflowsEntries from './elements/RelatedWorkflowsEntries'

function RelatedWorkflows() {
  const [activeWorkflowCategory, setActiveWorkflowCategory] = useState(null)
  const { workflow } = useParams()
  const activeClientId = useSelector(selectActiveClientId)
  const activeOperatingLocation = useSelector(selectActiveOperatingLocation)
  const workflowState = useSelector(selectWorkflowState)
  const workflowOptions = get(workflowState, 'workflow', [])
  const workflowByClientSlug = get(
    workflowOptions,
    `[${activeOperatingLocation}-${activeClientId}-${workflow}]`,
    {},
  )
  const workflowId = get(workflowByClientSlug, 'workflowId', null)
  const activeWorkflow = getWorkflowsDataById(workflowId)
  const relatedWorkflows = get(activeWorkflow, 'relatedWorkflows', [])
  const relatedWorkflowsCategories = getCategoriesByWorkflows(relatedWorkflows)
  const filteredWorkflows = activeWorkflowCategory
    ? relatedWorkflows.filter(workflow => {
        const workflowCategories = get(workflow, 'categories', [])

        return workflowCategories.some(
          category => category?.category === activeWorkflowCategory,
        )
      })
    : relatedWorkflows

  return !isEmpty(filteredWorkflows) ? (
    <div className="related-workflows">
      <h3>Related Workflows</h3>

      <RelatedWorkflowsFilters
        activeWorkflowCategory={activeWorkflowCategory}
        relatedWorkflowsCategories={relatedWorkflowsCategories}
        setActiveWorkflowCategory={setActiveWorkflowCategory}
      />

      <RelatedWorkflowsEntries
        activeWorkflowCategory={activeWorkflowCategory}
        filteredWorkflows={filteredWorkflows}
      />
    </div>
  ) : null
}

export default RelatedWorkflows
