import axios from 'axios'
import { get } from 'lodash'
import { fetchCategories } from './categories'
import { fetchWorkflows } from '../workflows/workflows'

export const DELETE_CATEGORY_BEGIN = 'DELETE_CATEGORY_BEGIN'
export const DELETE_CATEGORY_ERROR = 'DELETE_CATEGORY_ERROR'
export const DELETE_CATEGORY_SUCCESS = 'DELETE_CATEGORY_SUCCESS'
export const DELETE_CATEGORY_SET_LOADING = 'DELETE_CATEGORY_SET_LOADING'

export const deleteCategory =
  ({ categoryId, token, replacementCategoryId }) =>
  async (dispatch, getState) => {
    const user = getState().userData.user
    const activeClient = getState().userSelectionsData.activeClient
    const activeOperatingLocation =
      getState().userSelectionsData.activeOperatingLocation
    const activeClientId = get(activeClient, 'id', null)

    dispatch({ type: DELETE_CATEGORY_BEGIN })
    return axios({
      url: `${process.env.REACT_APP_PRODUCT_API}/api/v1/workflow/category/delete/${categoryId}`,
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      data: {
        clientId: activeClientId,
        operatingLocation: activeOperatingLocation,
        replacementCategoryId,
      },
    })
      .then(() => {
        dispatch({
          type: DELETE_CATEGORY_SUCCESS,
        })
        dispatch(fetchCategories(token))
        dispatch(fetchWorkflows(token))
      })
      .catch(error => {
        dispatch({
          type: DELETE_CATEGORY_ERROR,
          payload: error.message,
        })
      })
  }

// REDUCER
const initialState = {
  error: null,
  loading: false,
}

export default function deleteCategoryReducer(state = initialState, action) {
  switch (action.type) {
    case DELETE_CATEGORY_BEGIN:
      return { ...state, loading: true }
    case DELETE_CATEGORY_SUCCESS:
      return {
        ...state,
        loading: false,
      }
    case DELETE_CATEGORY_ERROR:
      return { ...state, loading: false, error: action.payload }
    case DELETE_CATEGORY_SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      }
    default:
      return state
  }
}

// SELECTORS
export const selectDeleteCategoryState = state => state.deleteCategoryData
