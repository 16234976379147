import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'

const LinkWrapper = ({ link, children }) => {
  if (link?.type === 'internal') {
    return (
      <Link to={link.path} target={link.target}>
        {children}
      </Link>
    )
  }

  if (link?.type === 'external') {
    return (
      <a href={link.url} target={link.target}>
        {children}
      </a>
    )
  }

  if (link?.type === 'document') {
    return (
      <a href={link.document.url} target={link.target} download>
        {children}
      </a>
    )
  }

  return children
}

LinkWrapper.propTypes = {
  link: PropTypes.shape({
    type: PropTypes.string,
    path: PropTypes.string,
    target: PropTypes.string,
    url: PropTypes.string,
    document: PropTypes.shape({
      _id: PropTypes.string,
      fileType: PropTypes.string,
      name: PropTypes.string,
      url: PropTypes.string,
    }),
  }),
  children: PropTypes.node,
}

export default LinkWrapper
