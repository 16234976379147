import PropTypes from 'prop-types'
import GalleryField from '../base-fields/gallery/GalleryField'

const Gallery = ({ component }) => {
  const subcomponent = component?.subComponents?.[0] || {}
  const { fields } = subcomponent || []
  const field = fields[0]

  return (
    <div className="image">
      <GalleryField field={field} />
    </div>
  )
}

Gallery.propTypes = {
  component: PropTypes.object,
}

export default Gallery
