import React from 'react'
import Modal from '../../global-components/elements/Modal'
import { ReactComponent as CloseModalIcon } from '../../../assets/icons/close-icon.svg'
import { ReactComponent as MessageAlertIcon } from '../../../assets/icons/message-alert-square-icon.svg'
import PropTypes from 'prop-types'

const ManageContentLocksConfirmUnlockModal = ({
  lock = {},
  isOpen,
  onRequestClose,
  onSubmit,
}) => {
  const { user, data } = lock
  return (
    <Modal
      modalClassName="modal--delete-workflow modal--sm"
      isOpen={isOpen}
      onRequestClose={onRequestClose}
    >
      <div className="modal__header">
        <h3 className="flex align-center">
          <MessageAlertIcon />
          Unlock Content
        </h3>
        <button className="modal__close" onClick={onRequestClose}>
          <CloseModalIcon />
        </button>
      </div>
      <div className="modal__body">
        <br />
        <p>
          <b>
            {`${user?.firstName} ${user?.lastName}`} currently editing &quot;
            {data?.name}&quot;.
          </b>
        </p>
        <p>
          This page is locked right now. If you remove the lock, the user
          won&apos;t be able to save changes until they refresh, and they might
          lose any unsaved work. Are you sure you want to continue?
        </p>
      </div>
      <div className="modal__submit">
        <button className="btn" onClick={onRequestClose}>
          Cancel
        </button>
        <button className="btn btn--dark-purple" onClick={() => onSubmit(lock)}>
          Remove Content Lock
        </button>
      </div>
    </Modal>
  )
}

ManageContentLocksConfirmUnlockModal.propTypes = {
  lock: PropTypes.object,
  isOpen: PropTypes.bool,
  onRequestClose: PropTypes.func,
  onSubmit: PropTypes.func,
}

export default ManageContentLocksConfirmUnlockModal
