import React, { useEffect, useState } from 'react'
import PropTypes, { func } from 'prop-types'
import EditorComponentOptionList from './EditorComponentOptionList'
import EditorComponentList from './EditorComponentList'
import {
  DndContext,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  pointerWithin,
} from '@dnd-kit/core'
import { arrayMove, sortableKeyboardCoordinates } from '@dnd-kit/sortable'
import '../../../styles/editor-flow/editor-components/editor-components.scss'
import '../../../styles/editor-flow/editor/editor.scss'
import { cloneDeep, get, set } from 'lodash'
import { selectComponent, selectField } from '../../../configuration/editor'
import ManageWorkflowStepNavigation from '../manage-workflow/elements/ManageWorkflowStepNavigation'
import ManageWorkflowStepNavigationHeader from '../manage-workflow/elements/ManageWorkflowStepNavigationHeader'
import getId from '../../../utilities/editor/getId'
import { unstable_usePrompt } from 'react-router-dom'
import clsx from 'clsx'

const Editor = ({
  data: defaultData,
  onSaveClick,
  onOrderChange,
  onDeleteClick,
  managedWorkflow,
  workflowByClientSlugSteps,
}) => {
  const [data, setData] = useState(() => cloneDeep(defaultData))
  const [selectedComponent, setSelectedComponent] = useState(null)
  const [prevDropPath, setPrevDropPath] = useState(null)
  const [currentDropLevel, setCurrentDropLevel] = useState(null)
  const defaultComponents = get(defaultData, 'components', [])

  useEffect(() => {
    const onBeforeUnload = e => {
      if (selectedComponent) {
        e.preventDefault()
        e.returnValue = ''
      }
    }
    window.addEventListener('beforeunload', onBeforeUnload)
    return () => {
      window.removeEventListener('beforeunload', onBeforeUnload)
    }
  }, [selectedComponent])

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  )

  const { components } = data

  useEffect(() => {
    setData(defaultData)
  }, [defaultData?.updatedAt, defaultData?._id, defaultComponents.length])

  function handleDragOver(evt) {
    const { over, active } = evt
    const newData = cloneDeep(data)
    const dropPath = getDropPath(over)
    const sourcePath = getDropPath(active)
    const action = getDropAction(evt)
    if (prevDropPath && prevDropPath?.join('.') !== dropPath?.join('.')) {
      const prevDropItems = get(newData, prevDropPath)
      if (prevDropItems) {
        set(
          newData,
          prevDropPath,
          prevDropItems.filter(item => getId(item) !== 'placeholder'),
        )
      }
    }
    if (over?.data?.current?.dropLevel === active?.data?.current?.dropLevel) {
      if (dropPath) {
        const dropContainer = get(data, dropPath)
        if (dropContainer) {
          const placeholderId = 'placeholder'
          const { id: overId } = over || {}
          if (overId?.indexOf('placeholder') !== -1) {
            return
          }

          const prevItems = dropContainer.filter(
            item => getId(item).indexOf('placeholder') === -1,
          )
          const overIndex = prevItems.findIndex(item => getId(item) === overId)
          const newIndex =
            overId?.indexOf('bottom') !== -1 ? prevItems.length + 1 : overIndex
          const newItems = []
          if (newIndex !== -1) {
            newItems.push(...prevItems.slice(0, newIndex))
          }
          if (
            action === 'create' ||
            dropPath.join('.') !== sourcePath?.join('.')
          ) {
            newItems.push({ id: placeholderId })
          }

          newItems.push(
            ...prevItems.slice(Math.max(newIndex, 0), prevItems.length),
          )
          set(newData, dropPath, newItems)
        }
      }
      setPrevDropPath(dropPath)
    }
    setData(newData)
  }

  function handleDragEnd(evt) {
    const { over, active } = evt
    if (over?.data?.current?.dropLevel === active?.data?.current?.dropLevel) {
      const sourcePath = getDropPath(active)
      const dropPath = getDropPath(over)
      const newData = cloneDeep(data)
      if (dropPath) {
        const dropContainer = get(data, dropPath)
        const { active, over } = evt
        const activeData = active?.data?.current || {}
        const { type, dropLevel, action } = activeData
        if (action === 'create') {
          const newIndex = dropContainer.findIndex(
            item => getId(item) === 'placeholder',
          )
          const newItem = createItem(dropLevel, type)
          if (dropLevel === 'components') {
            setSelectedComponent(newItem)
            newItem.order = newIndex
          }
          set(newData, dropPath, [
            ...dropContainer.slice(0, newIndex),
            newItem,
            ...dropContainer
              .filter(item => getId(item) !== 'placeholder')
              .slice(newIndex, dropContainer.length),
          ])
          setData(newData)
        } else {
          if (getId(active) !== getId(over)) {
            if (dropPath.join('.') === sourcePath.join('.')) {
              // Same Container
              const oldIndex = dropContainer.findIndex(
                item => getId(item) === getId(active),
              )
              const newIndex = dropContainer.findIndex(
                item => getId(item) === getId(over),
              )
              set(
                newData,
                dropPath,
                [...arrayMove(dropContainer, oldIndex, newIndex)].filter(
                  item => getId(item) !== 'placeholder',
                ),
              )
              if (dropLevel === 'components') {
                onOrderChange(newData.components.map(comp => comp._id))
              }
            } else {
              // New Container
              const sourceContainer = get(data, sourcePath)
              const oldIndex = sourceContainer.findIndex(
                item => getId(item) === getId(active),
              )
              const newIndex = dropContainer.findIndex(
                item => getId(item) === getId(over),
              )
              const item = sourceContainer[oldIndex]

              set(
                newData,
                dropPath,
                [
                  ...dropContainer.slice(0, newIndex),
                  item,
                  ...dropContainer.slice(newIndex, dropContainer.length),
                ].filter(item => getId(item) !== 'placeholder'),
              )
              set(
                newData,
                sourcePath,
                sourceContainer.filter(
                  item =>
                    getId(item) !== getId(active) && item !== 'placeholder',
                ),
              )
            }

            setData(newData)
          }
        }
      }
    }
    setCurrentDropLevel(null)
  }

  function createItem(dropLevel, type) {
    switch (dropLevel) {
      case 'components':
        return createComponent(type)
      case 'fields':
        return createField(type)
    }
  }
  function createField(type) {
    const id = `temp-${Date.now()}`
    const field = selectField(type)
    return field.createField(id)
  }

  function createComponent(type) {
    const component = selectComponent(type)
    const id = `temp-${Date.now()}`
    return component.createComponent(id)
  }

  function handleComponentChange(component) {
    const newData = cloneDeep(data)
    const componentIndex = newData.components.findIndex(
      comp => getId(comp) === getId(component),
    )
    newData.components[componentIndex] = component

    setData(newData)
  }

  function handleComponentSave() {
    const component = data.components.find(
      comp => getId(comp) === getId(selectedComponent),
    )
    onSaveClick(component)
    setSelectedComponent(null)
  }

  function handleComponentCancel(component) {
    const newData = cloneDeep(data)
    if (component._id) {
      const componentIndex = newData.components.findIndex(
        comp => getId(comp) === getId(component),
      )
      newData.components[componentIndex] = component
    } else {
      newData.components = newData.components.filter(
        comp => getId(comp) !== getId(component),
      )
    }

    setData(newData)
    setSelectedComponent(null)
  }

  function handleComponentDelete(component) {
    if (component._id) {
      onDeleteClick(component)
    } else {
      const newData = cloneDeep(data)
      newData.components = newData.components.filter(
        comp => getId(comp) !== getId(component),
      )
      setData(newData)
    }
    setSelectedComponent(null)
  }

  function getDropPath(container) {
    return container?.data?.current?.dropPath
  }

  function getDropAction(evt) {
    return evt?.active?.data?.current?.action
  }

  function handleWorkflowNavigation() {
    setSelectedComponent(null)
  }

  function handleDragStart(evt) {
    const dropLevel = evt?.active?.data?.current?.dropLevel
    setCurrentDropLevel(dropLevel)
  }

  return (
    <div
      className={clsx('workflow-editor', {
        'dragging-fields': currentDropLevel === 'fields',
      })}
    >
      <DndContext
        sensors={sensors}
        collisionDetection={pointerWithin}
        onDragOver={handleDragOver}
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
      >
        <EditorComponentOptionList selectedComponent={selectedComponent} />
        <div className="workflow-editor-main">
          <ManageWorkflowStepNavigationHeader
            managedWorkflow={managedWorkflow}
            onNavigate={handleWorkflowNavigation}
            workflowSteps={workflowByClientSlugSteps}
            unsavedChanges={!!selectedComponent}
          />
          <EditorComponentList
            components={components}
            selectedComponent={selectedComponent}
            onEditClick={component => setSelectedComponent(component)}
            onCancelClick={handleComponentCancel}
            onChange={handleComponentChange}
            onSaveClick={handleComponentSave}
            onDeleteClick={handleComponentDelete}
          />
          <ManageWorkflowStepNavigation
            managedWorkflow={managedWorkflow}
            onNavigate={handleWorkflowNavigation}
            workflowSteps={workflowByClientSlugSteps}
            unsavedChanges={!!selectedComponent}
          />
        </div>
      </DndContext>
    </div>
  )
}

Editor.propTypes = {
  data: PropTypes.object,
  managedWorkflow: PropTypes.object,
  workflowByClientSlugSteps: PropTypes.array,
  components: PropTypes.array,
  onSaveClick: PropTypes.func,
  onDeleteClick: PropTypes.func,
  onOrderChange: PropTypes.func,
}

export default Editor
