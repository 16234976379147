import React from 'react'
import PropTypes from 'prop-types'

function HandshakeIcon({ colour }, props) {
  return (
    <svg
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g mask="url(#mask0_1_2103)">
        <path
          d="M25.8601 24.871C25.1445 24.3016 25.0289 23.2524 25.5989 22.5395C26.171 21.8247 27.2213 21.7092 27.9349 22.2787L30.5677 24.3833C31.2833 24.9527 31.3988 26.004 30.8289 26.7167C30.2588 27.4296 29.2065 27.547 28.4929 26.9756"
          stroke={colour}
          strokeWidth="1.25"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M26.4161 29.568C27.1316 30.1394 27.2472 31.1887 26.6772 31.9015C26.1072 32.6163 25.0549 32.7317 24.3413 32.1623L21.7084 30.0577C20.9929 29.4883 20.8773 28.437 21.4474 27.7242C22.0194 27.0115 23.0697 26.894 23.7833 27.4654"
          stroke={colour}
          strokeWidth="1.25"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M24.3412 32.1623C25.0547 32.7318 25.1723 33.7831 24.6004 34.4958C24.0303 35.2086 22.9799 35.3261 22.2644 34.7547L20.7915 33.5759C20.078 33.0066 19.9604 31.9572 20.5305 31.2445C21.1024 30.5297 22.1528 30.4142 22.8663 30.9836"
          stroke={colour}
          strokeWidth="1.25"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13.3892 25.4723L11.5077 26.9756C10.7942 27.547 10.6766 28.5964 11.2466 29.3091C11.8187 30.0219 12.8691 30.1394 13.5825 29.568L15.6234 27.9393"
          stroke={colour}
          strokeWidth="1.25"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15.6235 27.9393L13.5826 29.568C12.8691 30.1394 12.7515 31.1887 13.3235 31.9016C13.8935 32.6163 14.9458 32.7317 15.6594 32.1624L17.6984 30.5317"
          stroke={colour}
          strokeWidth="1.25"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M17.6984 30.5317L16.6799 31.346C15.9663 31.9174 15.8487 32.9667 16.4188 33.6796C16.9908 34.3944 18.0411 34.5098 18.7547 33.9403L20.2375 32.7557"
          stroke={colour}
          strokeWidth="1.25"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4.05955 18.9217L2.8378 21.08C2.55479 21.5818 2.73219 22.2289 3.2364 22.5077L5.11986 23.551C5.62414 23.8298 6.26595 23.6545 6.54889 23.1548L11.9781 13.5519C12.2631 13.0501 12.0857 12.405 11.5815 12.1263L9.69601 11.0829C9.1918 10.8042 8.55203 10.9775 8.26895 11.4792L7.1668 13.4265"
          stroke={colour}
          strokeWidth="1.25"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.56445 24.2757C9.12001 23.3161 8.15131 22.6052 7.03918 22.2866"
          stroke={colour}
          strokeWidth="1.25"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.5078 26.9756C10.7943 27.547 9.74194 27.4295 9.17185 26.7167C8.59992 26.004 8.71741 24.9526 9.43102 24.3832L11.7131 22.5594C12.8213 21.6754 14.0729 20.6958 15.2608 19.8436C17.4094 18.3045 18.4677 17.2811 21.1603 17.8227C23.1953 18.2329 24.8416 17.6116 25.2701 15.8495C25.5452 14.7166 24.5805 14.189 22.6691 14.0197L19.6197 13.7469C19.0178 13.6932 18.659 13.6375 17.6844 13.978L15.8448 14.621C14.6011 15.0551 14.7685 15.1705 13.475 14.7963L11.5855 14.2487"
          stroke={colour}
          strokeWidth="1.25"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M28.4192 12.1263L30.3026 11.0829C30.8068 10.8042 31.4487 10.9774 31.7317 11.4792L37.1608 21.0801C37.4459 21.5818 37.2684 22.2289 36.7643 22.5076L34.8808 23.5509C34.3765 23.8297 33.7347 23.6545 33.4517 23.1548L28.0225 13.5519C27.7376 13.0501 27.9149 12.405 28.4192 12.1263Z"
          stroke={colour}
          strokeWidth="1.25"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M25.2641 15.1507L28.4113 14.2388"
          stroke={colour}
          strokeWidth="1.25"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M30.4342 24.2757C30.8807 23.3161 31.8493 22.6052 32.9614 22.2866"
          stroke={colour}
          strokeWidth="1.25"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M20.0004 8.46466L20.0004 4.8828"
          stroke={colour}
          strokeWidth="1.25"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M24.692 9.94803L26.5656 6.97543"
          stroke={colour}
          strokeWidth="1.25"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15.3065 9.94803L13.435 6.97543"
          stroke={colour}
          strokeWidth="1.25"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M28.493 26.9756L24.7001 23.9452C23.9866 23.3758 22.9342 23.4932 22.3642 24.206C21.7941 24.9188 21.9097 25.9682 22.6252 26.5396L26.4161 29.568C27.1316 30.1394 28.182 30.0219 28.7521 29.3091C29.3241 28.5964 29.2064 27.547 28.493 26.9756Z"
          stroke={colour}
          strokeWidth="1.25"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.61609 16.1761L5.61609 16.1741"
          stroke={colour}
          strokeWidth="1.25"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  )
}

HandshakeIcon.defaultProps = {
  colour: '#000000',
}

HandshakeIcon.propTypes = {
  colour: PropTypes.string,
}

export default HandshakeIcon
