import React from 'react'
import PropTypes from 'prop-types'

function PackageIcon({ colour }, props) {
  return (
    <svg
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.32812 26.3281C7.94003 26.3281 7.625 26.6432 7.625 27.0312C7.625 27.4193 7.94003 27.7344 8.32812 27.7344C8.71622 27.7344 9.03125 27.4193 9.03125 27.0312C9.03125 26.6432 8.71622 26.3281 8.32812 26.3281Z"
        fill={colour}
      />
      <path
        d="M8.32812 30.5469C7.94003 30.5469 7.625 30.8619 7.625 31.25C7.625 31.6381 7.94003 31.9531 8.32812 31.9531C8.71622 31.9531 9.03125 31.6381 9.03125 31.25C9.03125 30.8619 8.71622 30.5469 8.32812 30.5469Z"
        fill={colour}
      />
      <path
        d="M34.4844 3.82812C33.3283 3.82812 6.75983 3.82812 5.51562 3.82812C3.57709 3.82812 2 5.40521 2 7.34375V11.5625C2 11.9509 2.31476 12.2656 2.70312 12.2656H3.40625V34.0625C3.40625 35.2257 4.35245 36.1719 5.51562 36.1719H34.4844C35.6476 36.1719 36.5938 35.2257 36.5938 34.0625V12.2656H37.2969C37.6852 12.2656 38 11.9509 38 11.5625V7.34375C38 5.40521 36.4229 3.82812 34.4844 3.82812ZM22.8125 21.3497L20.4392 19.451C20.1824 19.2455 19.8176 19.2455 19.5608 19.451L17.1875 21.3497V5.23438H22.8125V21.3497ZM3.40625 7.34375C3.40625 6.18057 4.35245 5.23438 5.51562 5.23438H15.7812V10.8594C14.7971 10.8594 3.96683 10.8594 3.40625 10.8594V7.34375ZM35.1875 34.0625C35.1875 34.4503 34.8722 34.7656 34.4844 34.7656H5.51562C5.12781 34.7656 4.8125 34.4503 4.8125 34.0625V12.2656H15.7812V22.8125C15.7812 23.0828 15.9362 23.3291 16.1798 23.4461C16.4234 23.5634 16.7126 23.5305 16.9236 23.3615L20 20.9003L23.0764 23.3615C23.2879 23.5307 23.5769 23.5631 23.8202 23.4461C24.0638 23.3291 24.2188 23.0828 24.2188 22.8125V12.2656H35.1875V34.0625ZM36.5938 10.8594H24.2188V5.23438H34.4844C35.6476 5.23438 36.5938 6.18057 36.5938 7.34375V10.8594Z"
        fill={colour}
      />
      <path
        d="M24.2188 26.3281C23.8304 26.3281 23.5156 26.6429 23.5156 27.0312V31.25C23.5156 31.6384 23.8304 31.9531 24.2188 31.9531H31.6719C32.0602 31.9531 32.375 31.6384 32.375 31.25V27.0312C32.375 26.6429 32.0602 26.3281 31.6719 26.3281H24.2188ZM30.9688 30.5469H24.9219V27.7344H30.9688V30.5469Z"
        fill={colour}
      />
      <path
        d="M15.7812 26.3281H11.1406C10.7523 26.3281 10.4375 26.6429 10.4375 27.0312C10.4375 27.4196 10.7523 27.7344 11.1406 27.7344H15.7812C16.1696 27.7344 16.4844 27.4196 16.4844 27.0312C16.4844 26.6429 16.1696 26.3281 15.7812 26.3281Z"
        fill={colour}
      />
      <path
        d="M15.7812 30.5469H11.1406C10.7523 30.5469 10.4375 30.8616 10.4375 31.25C10.4375 31.6384 10.7523 31.9531 11.1406 31.9531H15.7812C16.1696 31.9531 16.4844 31.6384 16.4844 31.25C16.4844 30.8616 16.1696 30.5469 15.7812 30.5469Z"
        fill={colour}
      />
    </svg>
  )
}

PackageIcon.defaultProps = {
  colour: '#000000',
}

PackageIcon.propTypes = {
  colour: PropTypes.string,
}

export default PackageIcon
