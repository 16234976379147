import mixpanel from 'mixpanel-browser'

export function mixpanelTrackSignIn() {
  mixpanel.track('Sign In')
}

export function mixpanelTrackClient(clientId, clientName, source) {
  mixpanel.track('Set Client', {
    client_id: clientId,
    client_name: clientName,
    data_source: source,
  })

  // Set super properties to be shown on all track calls
  mixpanel.register({
    client_id: clientId,
    client_name: clientName,
  })
}

export function mixpanelTrackOperatingLocation(operatingLocation, source) {
  mixpanel.track('Set Operating Location', {
    operating_location: operatingLocation,
    data_source: source,
  })

  // Set super properties to be shown on all track calls
  mixpanel.register({
    operating_location: operatingLocation,
  })
}

export function mixpanelTrackPageView(
  pageUrl,
  pagePath,
  didPageChange,
  prevPagePath,
) {
  if (didPageChange) {
    mixpanel.track('Page Exit', {
      page_path: prevPagePath,
    })
  }

  mixpanel.track('Page View', {
    page_url: pageUrl,
    page_path: pagePath,
  })
  mixpanel.time_event('Page Exit')
}

export function mixpanelTrackLiveChatState(state) {
  mixpanel.track(state)
}

export function mixpanelTrackUserLayoutChange({
  clientId,
  clientName,
  operatingLocation,
  pageUrl,
  pagePath,
  layoutType,
}) {
  mixpanel.track('Set User UI Layout', {
    client_id: clientId,
    client_name: clientName,
    operating_location: operatingLocation,
    page_url: pageUrl,
    page_path: pagePath,
    ui_layout_type: layoutType,
  })
}
