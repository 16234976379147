import { EditorContent, useEditor } from '@tiptap/react'
import TextAlign from '@tiptap/extension-text-align'
import { ReactComponent as PlusIcon } from '../../../../assets/icons/plus-icon.svg'
import { ReactComponent as DeleteIcon } from '../../../../assets/icons/delete-icon.svg'
import { ReactComponent as ArrowDownIcon } from '../../../../assets/icons/arrow-down-icon.svg'

import { Underline } from '@tiptap/extension-underline'
import { Color } from '@tiptap/extension-color'
import TextStyle from '@tiptap/extension-text-style'
import ListItem from '@tiptap/extension-list-item'
import StarterKit from '@tiptap/starter-kit'
import Placeholder from '@tiptap/extension-placeholder'

import { isEmpty } from 'lodash'
import PropTypes from 'prop-types'
import EditorTableCell from './EditorTableCell'
import { useContext, useEffect, useRef } from 'react'
import EditorTableContext from '../../../../contexes/EditorTableContext'
import clsx from 'clsx'
const defaultContent = [
  {
    type: 'paragraph',
    attrs: {
      textAlign: 'left',
    },
  },
]
const EditorTableHeader = ({
  readOnly,
  data,
  onChange,
  onClick,
  onAddColumnClick,
  onDeleteColumnClick,
  onMoveLeftClick,
  onMoveRightClick,
  placeholder,
  id,
}) => {
  const [tableContext, setTableContext] = useContext(EditorTableContext)
  const ref = useRef(null)
  const isActive = !!tableContext?.activeCells.find(cell => cell.id === id)

  let content = {
    type: 'doc',
    content: !isEmpty(data) ? data : defaultContent,
  }
  const editor = useEditor({
    editable: !readOnly,
    extensions: [
      TextAlign.configure({
        types: ['paragraph'],
      }),
      Underline.configure({}),
      Color.configure({ types: [TextStyle.name, ListItem.name] }),
      TextStyle.configure({}),
      StarterKit.configure({
        bulletList: false,
        orderedList: false,
        codeBlock: false,
        blockquote: false,
        horizontalRule: false,
      }),
      Placeholder.configure({
        emptyEditorClass: 'is-editor-empty',
        placeholder,
      }),
    ],
    editorProps: {
      transformPastedHTML(html) {
        return html.replace(/style=\"(.*?)\"/g, '')
      },
    },
    onUpdate: ({ editor }) => {
      onChange(editor.getJSON()?.content || [])
    },
    onFocus: ({ editor }) => {
      onClick(editor)
    },
    content,
  })

  useEffect(() => {
    if (ref.current && !tableContext.cells[id] && editor) {
      setTableContext({
        ...tableContext,
        cells: {
          ...tableContext.cells,
          [id]: {
            ref,
            editor,
          },
        },
      })
    }
  }, [ref.current, tableContext.cells, editor])

  return (
    <div
      className={clsx('editor-table-header', { highlight: isActive })}
      onClick={() => onClick(editor)}
      ref={ref}
    >
      <div className="editor-table-header__actions">
        <div className="editor-table-header__actions-menu">
          <button
            className="editor-table-header__button"
            onClick={onMoveLeftClick}
            data-tooltip="Move Column Left"
          >
            <ArrowDownIcon
              style={{ height: '12px', transform: 'rotate(90deg)' }}
            />
          </button>
          <button
            className="editor-table-header__button"
            onClick={onMoveRightClick}
            data-tooltip="Move Column Right"
          >
            <ArrowDownIcon
              style={{ height: '12px', transform: 'rotate(-90deg)' }}
            />
          </button>
          <div className="editor-table-header__divider"></div>

          <button
            data-tooltip="Insert Column Right"
            onClick={onAddColumnClick}
            className="editor-table-header__button"
          >
            <PlusIcon style={{ width: '12px' }} />
          </button>
          <button
            data-tooltip="Delete Column"
            onClick={onDeleteColumnClick}
            className="editor-table-header__button"
          >
            <DeleteIcon height="30" width="30" />
          </button>
        </div>
      </div>
      <div className="editor-table-header__content">
        <EditorContent editor={editor} />
      </div>
    </div>
  )
}

EditorTableHeader.propTypes = {
  readOnly: PropTypes.bool,
  data: PropTypes.array,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  index: PropTypes.number,
  id: PropTypes.string,
  placeholder: PropTypes.string,
  onAddColumnClick: PropTypes.func,
  onDeleteColumnClick: PropTypes.func,
  onMoveRightClick: PropTypes.func,
  onMoveLeftClick: PropTypes.func,
}
export default EditorTableHeader
