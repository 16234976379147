import React from 'react'
import PropTypes from 'prop-types'

function DeliveryTruckIcon({ colour }, props) {
  return (
    <svg
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M32.375 27.7344C31.598 27.7344 30.9688 28.3636 30.9688 29.1406C30.9688 29.9176 31.598 30.5469 32.375 30.5469C33.152 30.5469 33.7812 29.9176 33.7812 29.1406C33.7812 28.3636 33.152 27.7344 32.375 27.7344Z"
        fill={colour}
      />
      <path
        d="M31.6719 22.1094C31.2838 22.1094 30.9688 22.4244 30.9688 22.8125C30.9688 23.2006 31.2838 23.5156 31.6719 23.5156C32.06 23.5156 32.375 23.2006 32.375 22.8125C32.375 22.4244 32.06 22.1094 31.6719 22.1094Z"
        fill={colour}
      />
      <path
        d="M10.8594 27.7344C10.0824 27.7344 9.45312 28.3636 9.45312 29.1406C9.45312 29.9176 10.0824 30.5469 10.8594 30.5469C11.6364 30.5469 12.2656 29.9176 12.2656 29.1406C12.2656 28.3636 11.6364 27.7344 10.8594 27.7344Z"
        fill={colour}
      />
      <path
        d="M35.0447 19.3419L33.8323 14.4926C34.2633 14.0803 34.4844 13.5334 34.4844 12.9688C34.4844 11.8056 33.5382 10.8594 32.375 10.8594H26.75V8.75C26.75 7.58682 25.8038 6.64062 24.6406 6.64062H4.10938C2.9462 6.64062 2 7.58682 2 8.75V27.0312C2 28.1944 2.9462 29.1406 4.10938 29.1406H6.64062C6.64062 31.467 8.53302 33.3594 10.8594 33.3594C13.1857 33.3594 15.0781 31.467 15.0781 29.1406H28.1562C28.1562 31.467 30.0486 33.3594 32.375 33.3594C34.7014 33.3594 36.5938 31.467 36.5938 29.1406C36.5938 29.1005 36.5918 29.0607 36.5907 29.0206C37.4106 28.7314 38 27.9489 38 27.0312V22.8125C38 21.0646 36.7179 19.6111 35.0447 19.3419ZM32.5291 15.0781L33.5838 19.2969H27.7443L29.3565 17.6846C29.6312 17.41 29.6312 16.965 29.3565 16.6904C29.0818 16.4157 28.6369 16.4157 28.3622 16.6904L26.75 18.3023V15.0781H32.5291ZM33.0781 12.9688C33.0781 13.3568 32.7631 13.6719 32.375 13.6719H26.75V12.2656H32.375C32.7628 12.2656 33.0781 12.5809 33.0781 12.9688ZM10.8594 31.9531C9.30865 31.9531 8.04688 30.6913 8.04688 29.1406C8.04688 27.5688 9.33145 26.3281 10.8594 26.3281C12.3909 26.3281 13.6719 27.5721 13.6719 29.1406C13.6719 30.6913 12.4101 31.9531 10.8594 31.9531ZM14.8367 27.7344C14.2495 26.0752 12.66 24.9219 10.8594 24.9219C9.05789 24.9219 7.4679 26.076 6.88177 27.7344H4.10938C3.72156 27.7344 3.40625 27.4191 3.40625 27.0312V8.75C3.40625 8.36218 3.72156 8.04688 4.10938 8.04688H24.6406C25.0284 8.04688 25.3438 8.36218 25.3438 8.75V27.7344H14.8367ZM32.375 31.9531C30.8243 31.9531 29.5625 30.6913 29.5625 29.1406C29.5625 27.5688 30.8471 26.3281 32.375 26.3281C33.9065 26.3281 35.1875 27.5721 35.1875 29.1406C35.1875 30.6913 33.9257 31.9531 32.375 31.9531ZM36.3023 27.6001C35.6786 26.0131 34.1268 24.9219 32.375 24.9219C30.5735 24.9219 28.9835 26.076 28.3974 27.7344H26.75V20.7031H34.4844C35.4009 20.7031 36.1826 21.2909 36.4729 22.1094H34.4844C34.096 22.1094 33.7812 22.4241 33.7812 22.8125C33.7812 23.2009 34.096 23.5156 34.4844 23.5156H36.5938V27.0312C36.5938 27.265 36.4784 27.4721 36.3023 27.6001Z"
        fill={colour}
      />
      <path
        d="M14.375 10.8594C10.8857 10.8594 8.04688 13.6982 8.04688 17.1875C8.04688 20.6768 10.8857 23.5156 14.375 23.5156C17.8643 23.5156 20.7031 20.6768 20.7031 17.1875C20.7031 13.6982 17.8643 10.8594 14.375 10.8594ZM14.375 22.1094C11.6611 22.1094 9.45312 19.9014 9.45312 17.1875C9.45312 14.4736 11.6611 12.2656 14.375 12.2656C17.0889 12.2656 19.2969 14.4736 19.2969 17.1875C19.2969 19.9014 17.0889 22.1094 14.375 22.1094Z"
        fill={colour}
      />
      <path
        d="M17.1875 16.4844H15.0781V14.375C15.0781 13.9866 14.7634 13.6719 14.375 13.6719C13.9866 13.6719 13.6719 13.9866 13.6719 14.375V17.1875C13.6719 17.5759 13.9866 17.8906 14.375 17.8906H17.1875C17.5759 17.8906 17.8906 17.5759 17.8906 17.1875C17.8906 16.7991 17.5759 16.4844 17.1875 16.4844Z"
        fill={colour}
      />
    </svg>
  )
}

DeliveryTruckIcon.defaultProps = {
  colour: '#000000',
}

DeliveryTruckIcon.propTypes = {
  colour: PropTypes.string,
}

export default DeliveryTruckIcon
