import axios from 'axios'
import { get, isEmpty } from 'lodash'

export const DUPLICATE_STEP_BEGIN = 'DUPLICATE_STEP_BEGIN'
export const DUPLICATE_STEP_ERROR = 'DUPLICATE_STEP_ERROR'
export const DUPLICATE_STEP_SUCCESS = 'DUPLICATE_STEP_SUCCESS'

export const duplicateStep =
  (values, existingContent, successEvent, token) =>
  async (dispatch, getState) => {
    const activeClient = getState().userSelectionsData.activeClient
    const activeClientId = get(activeClient, 'id', null)
    const activeOperatingLocation =
      getState().userSelectionsData.activeOperatingLocation
    const workflows = getState().workflowsData.workflows
    const workflowsByClient = get(
      workflows,
      `${activeOperatingLocation}-${activeClientId}`,
      [],
    )
    const workflowId = get(existingContent, 'workflowId', null)
    const workflowData = workflowsByClient.find(
      clientWorkflow => clientWorkflow?._id === workflowId,
    )
    const workflowSlug = get(workflowData, 'slug', null)
    const rehydratedContent = {
      ...existingContent,
      name: values?.name,
      slug: values?.slug,
    }

    dispatch({ type: DUPLICATE_STEP_BEGIN })
    return axios({
      url: `${process.env.REACT_APP_PRODUCT_API}/api/v1/workflow/step/duplicate/${activeClientId}`,
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      data: {
        content: rehydratedContent,
        operatingLocation: activeOperatingLocation,
        workflowId: workflowId,
        workflowSlug: workflowSlug,
      },
    })
      .then(response => {
        const workflow = get(response, 'data', [])

        if (!isEmpty(workflow)) {
          dispatch({
            type: DUPLICATE_STEP_SUCCESS,
            payload: workflow,
          })
          successEvent()
        } else {
          dispatch({
            type: DUPLICATE_STEP_ERROR,
            payload:
              'There was an error duplicating this step - please wait a moment, then try again.',
          })
        }
      })
      .catch(error => {
        dispatch({
          type: DUPLICATE_STEP_ERROR,
          payload: error.message,
        })
      })
  }
