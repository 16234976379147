import React from 'react'
import { get } from 'lodash'
import { LiveChatWidget } from '@livechat/widget-react'
import { useDispatch, useSelector } from 'react-redux'
import { selectUserState } from '../../../redux/user/user'
import { selectLiveChatVisibility } from '../../../redux/user/userSelections'
import { toggleLiveChat } from '../../../redux/user/userSelections'
import { mixpanelTrackLiveChatState } from '../../../utilities/analytics/mixpanelEvents'
import '../../../styles/global-components/live-chat/live-chat.scss'

function LiveChat() {
  const dispatch = useDispatch()
  const isLiveChatVisible = useSelector(selectLiveChatVisibility)
  const user = useSelector(selectUserState)
  const userEmail = get(user, 'user.email', '')
  const userFirstName = get(user, 'user.firstName', '')
  const userLastName = get(user, 'user.lastName', '')
  const userName = `${userFirstName}${userLastName ? ' ' + userLastName : ''}`

  return (
    <div className="live-chat">
      <LiveChatWidget
        license="12702366"
        visibility={isLiveChatVisible ? 'maximized' : 'hidden'}
        customerName={userName}
        customerEmail={userEmail}
        onFormSubmitted={data => {
          if (data?.type === 'prechat') {
            mixpanelTrackLiveChatState('Initiate Live Chat')
          }
        }}
        onVisibilityChanged={widget => {
          if (widget.visibility === 'hidden' && isLiveChatVisible) {
            dispatch(toggleLiveChat(!isLiveChatVisible))
            mixpanelTrackLiveChatState('Close Live Chat')
          } else {
            mixpanelTrackLiveChatState('Open Live Chat')
          }
        }}
      />
    </div>
  )
}
LiveChat.propTypes = {}
export default LiveChat
