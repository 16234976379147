import axios from 'axios'
import processFindReplaceTextApiData from '../../utilities/find-replace/processFindReplaceTextApiData'

async function fetchFindText({ activeClientId, token, find, matchCase }) {
  return axios({
    url: `${process.env.REACT_APP_PRODUCT_API}/api/v1/find-replace/search/text/${activeClientId}`,
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    data: {
      find,
      matchCase,
    },
  }).then(res => {
    return processFindReplaceTextApiData({
      data: res?.data?.results || [],
      find,
      matchCase,
    })
  })
}

export default fetchFindText
