import { get } from 'lodash'

export default function formatStepsToNestedSteps(steps) {
  let stepsCopy = [...steps]

  return stepsCopy.map(step => {
    const stepId = get(step, '_id', null)
    const nestedSteps = get(step, 'nestedSteps', [])

    return {
      id: stepId,
      data: step,
      children: nestedSteps.map(nestedStep => {
        const nestedStepData = stepsCopy.find(step => step?._id === nestedStep)

        stepsCopy.splice(
          stepsCopy.findIndex(step => step._id === nestedStep),
          1,
        )

        return {
          id: nestedStep,
          data: nestedStepData,
          canHaveChildren: false,
        }
      }),
    }
  })
}
