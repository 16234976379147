// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.resource-changed-toasts {
  position: fixed;
  bottom: 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  left: 0;
  width: 100%;
  pointer-events: none;
}`, "",{"version":3,"sources":["webpack://./src/styles/global-components/resource-changed-toasts/resource-changed-toasts.scss"],"names":[],"mappings":"AAEA;EACE,eAAA;EACA,SAAA;EACA,aAAA;EACA,mBAAA;EACA,sBAAA;EACA,OAAA;EACA,WAAA;EACA,oBAAA;AADF","sourcesContent":["@import '../../configuration/variables';\n\n.resource-changed-toasts {\n  position: fixed;\n  bottom: 0;\n  display: flex;\n  align-items: center;\n  flex-direction: column;\n  left: 0;\n  width: 100%;\n  pointer-events: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
