import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { Formik, Form, Field } from 'formik'
import * as Yup from 'yup'
import { get, isBoolean, isEqual } from 'lodash'
import { useNavigate } from 'react-router-dom'
import { selectUserState } from '../../../../redux/user/user'
import {
  selectActiveClientId,
  selectDuplicatedContentData,
} from '../../../../redux/user/userSelections'
import useAuthToken from '../../../../hooks/useAuthToken'
import validateWorkflowSlug from '../../../../utilities/slug/validateWorkflowSlug'
import TextField from '../../../forms/elements/TextField'
import SlugField from '../../../forms/elements/SlugField'
import { selectDuplicateContentState } from '../../../../redux/duplicate/duplicateContentReducers'
import { duplicateStep } from '../../../../redux/steps/duplicateStep'

function DuplicateStepForm({ closeFormEvent }) {
  const { token } = useAuthToken({})
  const dispatch = useDispatch()
  const [lastValidatedSlug, setLastValidatedSlug] = useState('')
  const [currentSlugValidity, setCurrentSlugValidity] = useState(null)
  const userDuplicatedContentData = useSelector(selectDuplicatedContentData)
  const duplicateContentState = useSelector(selectDuplicateContentState)
  const activeClientId = useSelector(selectActiveClientId)
  const userDuplicatedStepId = get(userDuplicatedContentData, 'stepId', null)
  const duplicatedContent = get(duplicateContentState, 'content', {})
  const duplicatedSteps = get(duplicatedContent, 'steps', [])
  const duplicatedStep = duplicatedSteps.find(step => {
    const stepId = get(step, '_id', null)
    return stepId === userDuplicatedStepId
  })
  const duplicatedStepName = get(duplicatedStep, 'name', null)
  const duplicatedStepSlug = get(duplicatedStep, 'slug', null)
  const initialDuplicatedSlug = `${duplicatedStepSlug}-copy`

  useEffect(() => {
    setLastValidatedSlug(initialDuplicatedSlug)
  }, [duplicatedStepSlug])

  const duplicateWorkflowSchema = Yup.object().shape({
    name: Yup.string().required('Workflow name required'),
    slug: Yup.string()
      .required('Workflow slug required')
      .test(
        'validateSlug',
        'The selected URL path is already in use for this client - please enter a unique path value',
        async value => {
          // Re-fire event only when the slug field had changed OR slug remains invalid
          if (
            (duplicatedStepSlug && lastValidatedSlug !== duplicatedStepSlug) ||
            (value !== '' && value !== lastValidatedSlug) ||
            (isBoolean(currentSlugValidity) && currentSlugValidity === false)
          ) {
            setLastValidatedSlug(value)

            const slugExists = await validateWorkflowSlug(
              activeClientId,
              value,
              token,
            )
            setCurrentSlugValidity(slugExists)
            return slugExists
          }

          return true
        },
      ),
  })

  async function handleDuplicateStep(values) {
    const handleCreateWorkflowSuccess = () => {
      closeFormEvent()
    }

    dispatch(
      duplicateStep(values, duplicatedStep, handleCreateWorkflowSuccess, token),
    )
  }

  const initialFormValues = {
    name: duplicatedStepName ? `${duplicatedStepName} Copy` : '',
    slug: initialDuplicatedSlug ? initialDuplicatedSlug : '',
  }

  return (
    <Formik
      initialValues={initialFormValues}
      validationSchema={duplicateWorkflowSchema}
      onSubmit={values => handleDuplicateStep(values)}
    >
      {({ isValid, setFieldValue }) => {
        const submitButtonDisabled = !isValid

        return (
          <Form className="form form--modal">
            <div className="form__field full">
              <div className="form__field half">
                <Field
                  component={TextField}
                  label="Name"
                  name="name"
                  placeholder="Enter Workflow Name"
                  required={true}
                  type="text"
                />
              </div>

              <div className="form__field half">
                <Field
                  component={SlugField}
                  label="Path"
                  name="slug"
                  placeholder="Enter Workflow URL Path"
                  required={true}
                  type="text"
                  onChange={e =>
                    setFieldValue(
                      'slug',
                      e.target.value
                        .replace(/\W+/g, '-')
                        .replace(/[^a-zA-Z0-9-]/g, '')
                        .toLowerCase(),
                    )
                  }
                />
              </div>
            </div>

            <div className="form__submit">
              <>
                <button
                  className="btn btn--white"
                  type="button"
                  onClick={() => closeFormEvent()}
                >
                  Cancel
                </button>

                <button
                  className="btn btn--dark-purple"
                  disabled={submitButtonDisabled}
                  type="submit"
                >
                  {submitButtonDisabled
                    ? 'Please ensure all field values are valid'
                    : 'Duplicate Step'}
                </button>
              </>
            </div>
          </Form>
        )
      }}
    </Formik>
  )
}

DuplicateStepForm.propTypes = {
  closeFormEvent: PropTypes.func,
}

export default DuplicateStepForm
