import { FETCH_USER_SUCCESS, selectUserId } from '../../user/user'
import { io } from 'socket.io-client'
import { selectAuthToken } from '../../auth/auth'
import onConnect from './events/onConnect'
import onSetSocketId from './events/onSetSocketId'
import onBlockedResources from './events/onBlockedResources'
import onResourceHasChanged from './events/onResourceHasChanged'
import onSessionExpired from './events/onSessionExpired'

const events = {
  connect: onConnect,
  setSocketId: onSetSocketId,
  blockedResources: onBlockedResources,
  resourceHasChanged: onResourceHasChanged,
  sessionExpired: onSessionExpired,
}

export const SEND_SOCKET_MESSAGE = 'SEND_SOCKET_MESSAGE'

const webSocketMiddleware = store => {
  let socket

  return next => action => {
    switch (action.type) {
      case FETCH_USER_SUCCESS: {
        const state = store.getState()
        const userId = action?.payload?.id
        const token = selectAuthToken(state)
        socket = io(
          `${process.env.REACT_APP_PRODUCT_API}/?token=${token}&userId=${userId}`,
          {
            transports: ['websocket'],
          },
        )

        // Bind Socket Events
        Object.entries(events).forEach(([name, callback]) =>
          socket.on(name, args => {
            callback({
              socket,
              args,
              state: store.getState(),
              dispatch: store.dispatch,
            })
          }),
        )

        break
      }

      case SEND_SOCKET_MESSAGE: {
        const { name, data } = action.payload
        socket.emit(name, data)
        return
      }
    }

    return next(action)
  }
}

const emitSocketMessage = (name, data) => dispatch => {
  dispatch({
    type: SEND_SOCKET_MESSAGE,
    payload: {
      name,
      data,
    },
  })
}

export { webSocketMiddleware, emitSocketMessage }
