import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { get, isEmpty } from 'lodash'
import { useLocation, useParams } from 'react-router-dom'
import { Link } from 'react-router-dom'
import routes from '../../../configuration/routes'
import {
  fetchWorkflows,
  selectWorkflowsState,
} from '../../../redux/workflows/workflows'
import {
  selectActiveClient,
  selectActiveClientId,
  selectActiveOperatingLocation,
} from '../../../redux/user/userSelections'
import MaterialLoader from '../../global-components/elements/MaterialLoader'
import BrowseWorkflowsFilters from './elements/BrowseWorkflowsFilters'
import filterWorkflowsByCategory from '../../../utilities/workflows/filterWorkflowsByCategory'
import BrowseWorkflowsEntriesGrid from './elements/BrowseWorkflowsEntriesGrid'
import useAuthToken from '../../../hooks/useAuthToken'
import validateUserManagementAccess from '../../../utilities/permissions/validateUserManagementAccess'
import BrowseWorkflowsEntriesTable from './elements/BrowseWorkflowsEntriesTable'
import clsx from 'clsx'
import { mixpanelTrackUserLayoutChange } from '../../../utilities/analytics/mixpanelEvents'
import updateCategoryWorkflowPin from '../../../redux/categories/updateCategoryWorkflowPin'
import {
  selectCategoryBySlug,
  selectCategoryLoadingPin,
} from '../../../redux/categories/categories'
import ResourceChangedToasts from '../../global-components/resource-changed-toasts/ResourceChangedToasts'

function BrowseWorkflows() {
  const dispatch = useDispatch()
  const { category: categorySlug } = useParams()
  const location = useLocation()
  const { token } = useAuthToken({})
  const isManager = validateUserManagementAccess()
  const [activeSubcategories, setActiveSubcategories] = useState([])
  const [dataView, setDataView] = useState(
    window.localStorage.getItem('BrowseWorkflowDataView') || 'grid',
  )
  const activeClient = useSelector(selectActiveClient)
  const activeClientId = useSelector(selectActiveClientId)
  const activeOperatingLocation = useSelector(selectActiveOperatingLocation)
  const category = useSelector(state =>
    selectCategoryBySlug(state, categorySlug),
  )

  const workflowsState = useSelector(selectWorkflowsState)
  const workflows = get(workflowsState, 'workflows', [])
  const workflowsByClient = get(
    workflows,
    `${activeOperatingLocation}-${activeClientId}`,
    [],
  )

  const filteredWorkflows = filterWorkflowsByCategory(
    categorySlug,
    activeSubcategories,
    workflowsByClient,
  ).sort((a, b) => {
    return (
      category?.pinnedWorkflows?.includes(b._id) -
      category?.pinnedWorkflows?.includes(a._id)
    )
  })

  const filteredPinnedWorkflows = category?.pinnedWorkflows?.filter(id =>
    filteredWorkflows.some(workflow => workflow._id === id),
  )

  const WorkflowsView =
    dataView === 'table'
      ? BrowseWorkflowsEntriesTable
      : BrowseWorkflowsEntriesGrid

  useEffect(() => {
    if (
      token &&
      !workflowsState.loading &&
      !workflowsState.error &&
      isEmpty(workflowsByClient)
    ) {
      dispatch(fetchWorkflows(token))
    }
  }, [activeClientId, activeOperatingLocation, token])

  function handleDataViewChange(value) {
    setDataView(value)
    window.localStorage.setItem('BrowseWorkflowDataView', value)
    mixpanelTrackUserLayoutChange({
      clientId: activeClientId,
      clientName: activeClient.name,
      operatingLocation: activeOperatingLocation,
      pageUrl: window.location.href,
      pathPath: location.pathname,
      layoutType: value === 'table' ? 'List View' : 'Card View',
    })
  }

  function handlePin(workflow, pinned) {
    dispatch(
      updateCategoryWorkflowPin({
        pinned,
        token,
        workflowId: workflow._id,
        categoryId: category._id,
      }),
    )
  }

  return workflowsState.loading ? (
    <MaterialLoader containerClasses="container-loader" />
  ) : (
    <div
      className={`browse-workflows${
        dataView === 'table' ? ' table__scroll-container' : ''
      }`}
    >
      <div className="table__scroll-container-header">
        <BrowseWorkflowsFilters
          activeSubcategories={activeSubcategories}
          setActiveSubcategories={setActiveSubcategories}
          dataView={dataView}
          onDataViewChange={handleDataViewChange}
        />
      </div>

      <div
        className={clsx({
          'table__scroll-container-table': dataView === 'table',
        })}
      >
        {categorySlug ? (
          <WorkflowsView
            categorySlug={categorySlug}
            title={
              !isEmpty(activeSubcategories)
                ? activeSubcategories.join(', ')
                : 'Select a Workflow'
            }
            workflows={filteredWorkflows}
            pinnedWorkflows={filteredPinnedWorkflows}
            onPinToggle={handlePin}
          />
        ) : null}
      </div>

      {isEmpty(workflowsByClient) ? (
        <div className="container no-workflows">
          <p>
            There are currently no workflows or categories registered under this
            client
          </p>

          {isManager ? (
            <Link
              className="btn btn--white"
              to={routes.viewMyWorkflowsCategories}
            >
              Manage Categories
            </Link>
          ) : null}
        </div>
      ) : null}

      <ResourceChangedToasts types={['publishWorkflow']}>
        {resource => (
          <>
            {resource?.firstName} has published a new workflow - &quot;
            {resource?.name}&quot; in &quot;{resource?.category}&quot;
          </>
        )}
      </ResourceChangedToasts>
    </div>
  )
}

export default BrowseWorkflows
