// noinspection JSAnnotator

import React from 'react'
import PropTypes from 'prop-types'
import { isEmpty, get } from 'lodash'
import Select, { ActionMeta } from 'react-select'
import supportedIcons from '../../../configuration/supportedIcons'
import { ReactComponent as RemoveIcon } from '../../../assets/icons/close-circle.svg'

function IconField({
  field,
  form: { touched, errors, setFieldValue, values },
  label,
  placeholder = 'Select an Icon',
  required,
  setFieldTouched,
}) {
  function onChange(option, actionMeta) {
    const value = get(option, 'value', '')
    const colourFieldName = 'iconColour'

    switch (actionMeta.action) {
      case 'select-option':
        if (value !== '') {
          setFieldValue(colourFieldName, '#000000')
          setFieldValue(field.name, value)
        } else {
          setFieldValue(colourFieldName, '')
          setFieldValue(field.name, value)
        }
        break
      case 'clear':
        setFieldValue(field.name, '')
        setFieldValue(colourFieldName, '')
        break
    }
  }

  const getValue = () => {
    return !isEmpty(values?.icon)
      ? supportedIcons.find(option => option.id === values?.icon)
      : ''
  }

  const renderIconOptions = () => {
    const iconOptions = [
      {
        label: 'Remove Icon',
        value: '',
        component: RemoveIcon,
      },
    ]

    supportedIcons.forEach(icon => {
      const value = get(icon, 'id', null)
      const Component = get(icon, 'component', null)
      const label = get(icon, 'label', null)
      const synonyms = get(icon, 'synonyms', [])
      if (label && value) {
        iconOptions.push({
          label,
          value,
          synonyms,
          component: Component,
        })
      }
    })

    return iconOptions
  }

  function handleFilterIcons(option, filter) {
    const search = filter?.toLowerCase() || ''
    const synonyms = get(option, 'data.synonyms', []).map(value =>
      value?.toLowerCase(),
    )
    const label = get(option, 'data.label', '').toLowerCase()
    const searchOptions = [label, ...synonyms]
    return searchOptions.reduce((acc, cur) => {
      return acc || cur.includes(search)
    }, false)
  }

  return (
    <>
      {label && (
        <label htmlFor={field.name}>
          {label}
          {required && <sup className="required">*</sup>}
        </label>
      )}

      <Select
        className={`custom-select custom-select__menu-half${
          touched[field.name] && errors[field.name] ? ' error' : ''
        }`}
        classNamePrefix="custom-select"
        id={field.name}
        value={getValue()}
        isClearable={true}
        isMulti={false}
        maxMenuHeight={200}
        menuPlacement="top"
        minMenuHeight={200}
        name={field.name}
        onBlur={() => setFieldTouched(field.name, true)}
        onChange={onChange}
        filterOption={handleFilterIcons}
        options={renderIconOptions()}
        getOptionLabel={option => {
          const Component = get(option, 'component', null)
          const label = get(option, 'label', null)

          return (
            <div className="custom-select__option-content">
              {Component ? <Component /> : null}
              <span className="custom-select__option-content-label">
                {label}
              </span>
            </div>
          )
        }}
        placeholder={placeholder}
        required={required}
      />

      {touched[field.name] && errors[field.name] && (
        <div className="field-error">{errors[field.name]}</div>
      )}
    </>
  )
}

IconField.propTypes = {
  errors: PropTypes.object,
  field: PropTypes.object,
  form: PropTypes.object,
  isFixed: PropTypes.bool,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  setFieldTouched: PropTypes.func.isRequired,
  touched: PropTypes.object,
}

export default IconField
