import React from 'react'
import PropTypes from 'prop-types'

function LightBulbIcon({ colour }, props) {
  return (
    <svg
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M24.9882 31.1929C24.7153 31.1929 24.4941 30.9718 24.4941 30.6988V28.4471C24.4941 26.96 25.1106 25.5341 26.1835 24.5365C28.0211 22.8259 29.0776 20.4024 29.0776 17.8918C29.0776 12.8965 25.0141 8.82353 20.0188 8.81412C20.0141 8.81412 20.007 8.81412 20.0023 8.81412C17.5906 8.81412 15.3153 9.75765 13.5976 11.4706C11.8753 13.1882 10.927 15.4659 10.9247 17.8847C10.9223 20.3976 11.9764 22.8212 13.8164 24.5341C14.8917 25.5341 15.5058 26.96 15.5058 28.4471V30.6988C15.5058 30.9718 15.2847 31.1929 15.0117 31.1929C14.7388 31.1929 14.5176 30.9718 14.5176 30.6988V28.4471C14.5176 27.2329 14.0164 26.0706 13.1411 25.2565C12.1388 24.3224 11.3506 23.2188 10.7976 21.9718C10.2235 20.6824 9.93408 19.3059 9.93408 17.8824C9.93408 16.5271 10.2023 15.2094 10.7317 13.9694C11.2423 12.7718 11.9694 11.6941 12.8964 10.7694C13.8235 9.84471 14.9011 9.11765 16.1011 8.61177C17.3435 8.08706 18.6635 7.82118 20.0164 7.82353C21.3741 7.82589 22.6894 8.09412 23.927 8.62118C25.1247 9.12941 26.1976 9.85412 27.12 10.7788C28.0423 11.7035 28.767 12.7788 29.2729 13.9765C29.7976 15.2165 30.0635 16.5341 30.0635 17.8894C30.0635 19.3106 29.7717 20.6871 29.2 21.9765C28.647 23.2212 27.8588 24.3271 26.8564 25.2588C25.9835 26.0729 25.4823 27.2353 25.4823 28.4471V30.6988C25.4823 30.9718 25.2611 31.1929 24.9882 31.1929Z"
        fill={colour}
      />
      <path
        d="M20.7788 35.7012H19.2188C16.6258 35.7012 14.5176 33.5906 14.5176 31V30.6988C14.5176 30.4259 14.7388 30.2047 15.0117 30.2047H24.9882C25.2611 30.2047 25.4823 30.4259 25.4823 30.6988V31C25.4823 33.5906 23.3717 35.7012 20.7788 35.7012ZM15.5105 31.1929C15.6117 33.1506 17.2376 34.7129 19.2188 34.7129H20.7788C22.7623 34.7129 24.3858 33.1506 24.487 31.1929H15.5105Z"
        fill={colour}
      />
      <path
        d="M19.9999 38C18.1717 38 16.6823 36.5129 16.6799 34.6847C16.6799 34.4118 16.9011 34.1906 17.174 34.1906C17.447 34.1906 17.6682 34.4118 17.6682 34.6847C17.6705 35.9694 18.7152 37.0141 19.9999 37.0141C21.2846 37.0141 22.3293 35.9694 22.3317 34.6847C22.3317 34.4118 22.5529 34.1906 22.8258 34.1906C23.0988 34.1906 23.3199 34.4118 23.3199 34.6847C23.3152 36.5129 21.8282 38 19.9999 38Z"
        fill={colour}
      />
      <path
        d="M24.3834 33.4471H15.614C15.341 33.4471 15.1199 33.2259 15.1199 32.9529C15.1199 32.68 15.341 32.4588 15.614 32.4588H24.3834C24.6563 32.4588 24.8775 32.68 24.8775 32.9529C24.8775 33.2259 24.6563 33.4471 24.3834 33.4471Z"
        fill={colour}
      />
      <path
        d="M20 6.16471C19.727 6.16471 19.5059 5.94353 19.5059 5.67059V2.49412C19.5059 2.22118 19.727 2 20 2C20.2729 2 20.4941 2.22118 20.4941 2.49412V5.67059C20.4941 5.94353 20.2729 6.16471 20 6.16471Z"
        fill={colour}
      />
      <path
        d="M11.3646 9.74352C11.2376 9.74352 11.1129 9.69411 11.0164 9.59764L8.76934 7.35058C8.5764 7.15764 8.5764 6.8447 8.76934 6.65176C8.96228 6.45881 9.27522 6.45881 9.46816 6.65176L11.7152 8.89881C11.9082 9.09176 11.9082 9.4047 11.7152 9.59764C11.6164 9.69411 11.4893 9.74352 11.3646 9.74352Z"
        fill={colour}
      />
      <path
        d="M7.78582 18.3788H4.60935C4.33641 18.3788 4.11523 18.1577 4.11523 17.8847C4.11523 17.6118 4.33641 17.3906 4.60935 17.3906H7.78582C8.05876 17.3906 8.27994 17.6118 8.27994 17.8847C8.27994 18.1577 8.05876 18.3788 7.78582 18.3788Z"
        fill={colour}
      />
      <path
        d="M9.11757 29.2612C8.99052 29.2612 8.86581 29.2141 8.76934 29.1153C8.5764 28.9224 8.5764 28.6094 8.76934 28.4165L11.0164 26.1694C11.2093 25.9765 11.5223 25.9765 11.7152 26.1694C11.9082 26.3624 11.9082 26.6753 11.7152 26.8683L9.46816 29.1153C9.37169 29.2118 9.24463 29.2612 9.11757 29.2612Z"
        fill={colour}
      />
      <path
        d="M30.8823 29.2588C30.7553 29.2588 30.6306 29.2118 30.5341 29.1129L28.287 26.8659C28.0941 26.6729 28.0941 26.36 28.287 26.167C28.48 25.9741 28.7929 25.9741 28.9859 26.167L31.2329 28.4141C31.4259 28.607 31.4259 28.92 31.2329 29.1129C31.1365 29.2118 31.0094 29.2588 30.8823 29.2588Z"
        fill={colour}
      />
      <path
        d="M35.3906 18.3765H32.2141C31.9411 18.3765 31.72 18.1553 31.72 17.8824C31.72 17.6094 31.9411 17.3882 32.2141 17.3882H35.3906C35.6635 17.3882 35.8847 17.6094 35.8847 17.8824C35.8847 18.1553 35.6635 18.3765 35.3906 18.3765Z"
        fill={colour}
      />
      <path
        d="M28.6353 9.74117C28.5082 9.74117 28.3835 9.69176 28.287 9.59529C28.0941 9.40235 28.0941 9.08941 28.287 8.89646L30.5341 6.64941C30.727 6.45646 31.04 6.45646 31.2329 6.64941C31.4259 6.84235 31.4259 7.15529 31.2329 7.34823L28.9859 9.59529C28.8894 9.69411 28.7623 9.74117 28.6353 9.74117Z"
        fill={colour}
      />
      <path
        d="M20 22.3671C17.4776 22.3671 15.4282 20.3153 15.4282 17.7953C15.4282 15.273 17.48 13.2235 20 13.2235C22.52 13.2235 24.5718 15.2753 24.5718 17.7953C24.5718 20.3177 22.52 22.3671 20 22.3671ZM20 14.2118C18.0235 14.2118 16.4165 15.8188 16.4165 17.7953C16.4165 19.7718 18.0235 21.3788 20 21.3788C21.9765 21.3788 23.5835 19.7718 23.5835 17.7953C23.5835 15.8188 21.9765 14.2118 20 14.2118Z"
        fill={colour}
      />
      <path
        d="M19.287 19.5223C19.1599 19.5223 19.0352 19.4729 18.9388 19.3765L17.8964 18.3341C17.7035 18.1412 17.7035 17.8282 17.8964 17.6353C18.0894 17.4423 18.4023 17.4423 18.5952 17.6353L19.2894 18.3294L21.407 16.2118C21.5999 16.0188 21.9129 16.0188 22.1058 16.2118C22.2988 16.4047 22.2988 16.7176 22.1058 16.9106L19.6352 19.3788C19.5388 19.4753 19.4141 19.5223 19.287 19.5223Z"
        fill={colour}
      />
    </svg>
  )
}

LightBulbIcon.defaultProps = {
  colour: '#000000',
}

LightBulbIcon.propTypes = {
  colour: PropTypes.string,
}

export default LightBulbIcon
