import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { isEmpty, get } from 'lodash'
import { Link } from 'react-router-dom'
import routes from '../../../../configuration/routes'
import validateUserManagementAccess from '../../../../utilities/permissions/validateUserManagementAccess'
import getIconComponent from '../../../../utilities/icon/getIconComponent'
import { ReactComponent as BackArrowIcon } from '../../../../assets/icons/back-arrow-icon.svg'
import { ReactComponent as ArrowRightIcon } from '../../../../assets/icons/arrow-right-icon.svg'
import { ReactComponent as PinIcon } from '../../../../assets/icons/pin-icon.svg'
import { ReactComponent as FilledPinIcon } from '../../../../assets/icons/pin-filled-icon.svg'
import '../../../../styles/consumer-flow/browse-workflows/browse-workflows-entries.scss'
import { useGlobalFilter, useSortBy, useTable } from 'react-table'
import globalSearchFilter from '../../../../utilities/table/globalSearchFilter'
import dayjs from 'dayjs'
import dateFormats from '../../../../configuration/supportedDateFormats'
import '../../../../styles/consumer-flow/browse-workflows/browse-workflows-entries-table.scss'
import TableSortIcon from '../../../global-components/table/TableSortIcon'
import { useSelector } from 'react-redux'
import { selectCategoryLoadingPin } from '../../../../redux/categories/categories'
import clsx from 'clsx'
import MaterialLoader from '../../../global-components/elements/MaterialLoader'
/*
  Disable prop-types as it triggers on every table Cell definition
 */
/* eslint react/prop-types: 0 */
function BrowseWorkflowsEntriesTable({
  categorySlug,
  title,
  workflows,
  pinnedWorkflows,
  onPinToggle,
}) {
  const workflowsLoading = useSelector(selectCategoryLoadingPin)
  const isManager = validateUserManagementAccess()
  const data = useMemo(() => {
    return workflows.map(workflow => ({
      ...workflow,
      updatedAt: new Date(workflow.updatedAt),
    }))
  }, [workflows])
  const columns = useMemo(
    () => [
      {
        accessor: 'icon',
        disableSortBy: true,
        Cell: ({ cell }) => {
          const icon = cell.value
          const { colour } = icon
          const Icon = getIconComponent(icon)
          return (
            <div className="workflow-entries-table__icon">
              {' '}
              <Icon colour={colour} />{' '}
            </div>
          )
        },
      },
      {
        Header: 'Workflow Name',
        accessor: 'name',
        Cell: ({ cell }) => {
          return (
            <div>
              <div className="workflow-entries-table__name">{cell.value}</div>
            </div>
          )
        },
      },
      {
        Header: 'Status',
        accessor: 'status',
        show: isManager,
        Cell: ({ cell }) => (
          <div>
            <span className={`status-icon status-icon--${cell.value}`}>
              <span className="status-icon__indicator"></span>
              {cell.value}
            </span>
          </div>
        ),
      },
      {
        Header: 'Category',
        accessor: 'categories[0].category',
        id: 'category',
      },
      {
        Header: 'Subcategory',
        id: 'subcategory',
        accessor: row => {
          const category = row?.categories?.find(
            category =>
              category?.category.replace(/\W+/g, '-').toLowerCase() ===
              categorySlug,
          )
          const subcategories = category?.subcategories || []

          return subcategories?.join(', ')
        },
      },
      {
        Header: 'Last Modified',
        accessor: 'updatedAt',
        sortType: 'datetime',
        Cell: ({ cell }) =>
          dayjs(cell.value).format(dateFormats.dayMonthYearTime),
      },

      {
        Header: isManager ? 'Actions' : '',
        accessor: 'slug',
        disableSortBy: true,
        Cell: ({ cell, row }) => {
          const workflow = row?.original
          const isPinned = pinnedWorkflows?.some(
            workflowId => workflowId === workflow?._id,
          )
          const isLoading = workflowsLoading?.[workflow?._id]
          return (
            <div>
              <div>
                {isManager ? (
                  <button
                    className="btn--transparent btn--no-border"
                    data-tooltip={isPinned ? 'Unpin Workflow' : 'Pin Workflow'}
                    onClick={() => onPinToggle(workflow, !isPinned)}
                  >
                    {isPinned ? <FilledPinIcon /> : <PinIcon />}
                  </button>
                ) : null}
              </div>
              <Link
                to={`${routes.browseWorkflows}/${categorySlug}/${cell.value}`}
                className="btn btn--light-green btn--icon-text-right"
              >
                <span>View</span>
                <ArrowRightIcon />
              </Link>
              {isLoading ? (
                <MaterialLoader containerClasses="overlay-loader" />
              ) : null}
            </div>
          )
        },
      },
    ],
    [workflows, pinnedWorkflows, workflowsLoading],
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      globalSearchFilter,
      initialState: {
        hiddenColumns: [
          ...(isManager ? [] : ['status']),
          ...(!categorySlug ? ['subcategory'] : []),
        ],
      },
    },
    useGlobalFilter,
    useSortBy,
  )

  return (
    <div className="workflow-entries-table container">
      <div className="table__filters">
        <div className="table__filters-row">
          <div className="table__filters-content">
            <p className="title">{title}</p>

            <div className="table__filters-counts">
              <p>
                <span>{workflows?.length}</span> Workflows
              </p>
            </div>
          </div>

          <div className="table__filters-actions">
            <input
              type="text"
              className="filters-search"
              name="search-users"
              onChange={e => setGlobalFilter(e.currentTarget.value)}
              placeholder="Search Results"
            />
          </div>
        </div>
      </div>
      <div className="table">
        <div className="table__container">
          <table {...getTableProps()}>
            <thead>
              {headerGroups.map((headerGroup, i) => (
                <tr
                  key={`search-results-thead-tr-${i}`}
                  {...headerGroup.getHeaderGroupProps()}
                >
                  {headerGroup?.headers?.map((column, i) => (
                    <th
                      key={`find-replace-thead-th-${i}`}
                      {...column.getHeaderProps()}
                      style={{ verticalAlign: 'middle' }}
                    >
                      <div {...column.getSortByToggleProps()}>
                        {column.render('Header')}
                        <TableSortIcon column={column} />
                      </div>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>

            <tbody {...getTableBodyProps()}>
              {pinnedWorkflows?.length > 0 ? (
                <tr>
                  <td
                    colSpan="100%"
                    className="workflow-entries-table__pinned-header"
                  >
                    <div className="flex align-center">
                      <FilledPinIcon /> Pinned Workflows
                    </div>
                  </td>
                </tr>
              ) : null}
              {rows?.map((row, i) => {
                prepareRow(row)
                const isPinned = pinnedWorkflows?.some(
                  workflowId => workflowId === row?.original?._id,
                )

                return (
                  <tr
                    key={`find-replace-tbody-tr-${i}`}
                    {...row.getRowProps()}
                    className={clsx({ pinned: isPinned })}
                  >
                    {row.cells.map((cell, i) => {
                      return (
                        <td
                          className={cell.column.id}
                          key={`find-replace-tbody-td-${i}`}
                          {...cell.getCellProps()}
                        >
                          {cell.render('Cell')}
                        </td>
                      )
                    })}
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

BrowseWorkflowsEntriesTable.propTypes = {
  categorySlug: PropTypes.string,
  title: PropTypes.string,
  workflows: PropTypes.array,
}

export default BrowseWorkflowsEntriesTable
