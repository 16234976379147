// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.editor-link-form__document-icon {
  width: 80px;
  height: 80px;
  margin-right: 16px;
}
.editor-link-form__document-icon svg {
  width: 100%;
  height: 100%;
  display: inline-block;
}
.editor-link-form__document-field {
  margin-bottom: 16px;
}
.editor-link-form textarea {
  min-height: 100px;
}`, "",{"version":3,"sources":["webpack://./src/styles/editor-flow/editor/editor-link-form.scss"],"names":[],"mappings":"AACE;EACE,WAAA;EACA,YAAA;EACA,kBAAA;AAAJ;AACI;EACE,WAAA;EACA,YAAA;EACA,qBAAA;AACN;AAEE;EACE,mBAAA;AAAJ;AAGE;EACE,iBAAA;AADJ","sourcesContent":[".editor-link-form {\n  &__document-icon {\n    width: 80px;\n    height: 80px;\n    margin-right: 16px;\n    svg {\n      width: 100%;\n      height: 100%;\n      display: inline-block;\n    }\n  }\n  &__document-field {\n    margin-bottom: 16px;\n  }\n\n  textarea {\n    min-height: 100px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
