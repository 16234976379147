import { Field, Form, Formik } from 'formik'
import React, { useState } from 'react'
import * as Yup from 'yup'
import PropTypes from 'prop-types'
import CustomSelect from '../../../../forms/elements/CustomSelect'
import { useSelector } from 'react-redux'
import { get, orderBy } from 'lodash'
import {
  selectActiveClientId,
  selectActiveOperatingLocation,
} from '../../../../../redux/user/userSelections'
import { selectWorkflowsState } from '../../../../../redux/workflows/workflows'
import { selectCategories } from '../../../../../redux/categories/categories'
import { ReactComponent as DropdownChevronIcon } from '../../../../../assets/icons/dropdown-chevron-icon.svg'
import clsx from 'clsx'

const FindReplaceInternalLinkForm = ({ onSubmit = values => {} }) => {
  const validationSchema = Yup.object().shape({
    find: Yup.object().shape({
      workflow: Yup.string()
        .optional()
        .when(['internalType'], {
          is: internalType => internalType === 'workflow',
          then: schema => schema.required('Required'),
          otherwise: schema => schema.notRequired(),
        }),
      category: Yup.string()
        .optional()
        .when(['internalType'], {
          is: internalType => internalType === 'category',
          then: schema => schema.required('Required'),
          otherwise: schema => schema.notRequired(),
        }),
    }),
    replace: Yup.object().shape({
      workflow: Yup.string()
        .optional()
        .when(['internalType'], {
          is: internalType => internalType === 'workflow',
          then: schema => schema.required('Required'),
          otherwise: schema => schema.notRequired(),
        }),
      category: Yup.string()
        .optional()
        .when(['internalType'], {
          is: internalType => internalType === 'category',
          then: schema => schema.required('Required'),
          otherwise: schema => schema.notRequired(),
        }),
    }),
  })

  const targetOptions = [
    {
      label: 'Open the link in the same tab',
      value: '_self',
    },
    {
      label: 'Open the link in a new tab',
      value: '_blank',
    },
  ]

  const internalLinkOptions = [
    {
      label: 'Workflow',
      value: 'workflow',
    },
    {
      label: 'Category',
      value: 'category',
    },
  ]

  const activeClientId = useSelector(selectActiveClientId)
  const activeOperatingLocation = useSelector(selectActiveOperatingLocation)
  const workflowsState = useSelector(selectWorkflowsState)
  const categories = useSelector(selectCategories)?.[activeClientId] || []
  const workflowsByClient = orderBy(
    get(
      workflowsState?.workflows,
      `${activeOperatingLocation}-${activeClientId}`,
      [],
    ),
    'name',
    'asc',
  )
  const workflowOptions = workflowsByClient.map(workflow => ({
    value: workflow._id,
    label: workflow.name,
  }))

  const categoryOptions = categories.map(category => ({
    value: category._id,
    label: category.category,
  }))

  const initialValues = {
    find: {
      type: 'internal',
      target: '_self',
      workflow: '',
      url: '',
      document: null,
      internalType: 'workflow',
      name: '',
    },
    replace: {
      type: 'internal',
      target: '_self',
      workflow: '',
      url: '',
      document: null,
      internalType: 'workflow',
      name: '',
    },
  }

  const handleSubmit = values => {
    onSubmit({
      find: {
        ...values.find,
        name: getName(values.find),
      },
      replace: {
        ...values.replace,
        name: getName(values.replace),
      },
    })
  }

  function getStepOptions(workflowId) {
    const workflow = workflowsByClient.find(item => item._id === workflowId)
    return [
      {
        label: 'Step Overview',
        value: null,
      },
      ...(workflow?.steps?.map(step => ({
        label: step.name,
        value: step._id,
      })) || []),
    ]
  }

  function getCategoryOptions(workflowId) {
    const workflow = workflowsByClient.find(item => item._id === workflowId)
    return [
      ...(workflow?.categories?.map(category => ({
        label: category.category,
        value: category._id,
      })) || []),
    ]
  }

  function getName(values) {
    if (values?.internalType === 'workflow') {
      const workflow = workflowsByClient.find(
        item => item._id === values?.workflow,
      )
      if (values?.step) {
        const step = workflow?.steps?.find(step => step?._id === values?.step)
        return step?.name
      }
      return workflow?.name
    }
    const category = categories.find(
      category => category?._id === values?.category,
    )
    return category?.category || ''
  }

  const customSelectFilter = (option, searchText) => {
    return (
      option?.data?.label?.toLowerCase()?.includes(searchText?.toLowerCase()) ||
      option?.data?.value?.toLowerCase()?.includes(searchText?.toLowerCase())
    )
  }

  return (
    <div className="find-replace-internal-link-form">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ isValid, setFieldTouched, values, setFieldValue, errors }) => {
          async function onWorkflowChange(value, type) {
            const workflow = workflowsByClient.find(item => item._id === value)
            await setFieldValue(
              `${type}.category`,
              workflow.categories?.[0]?._id,
            )
            await setFieldValue(`${type}.step`, null)
          }

          async function onInternalTypeChange(type) {
            await setFieldValue(`${type}.category`, null)
            await setFieldValue(`${type}.step`, null)
            await setFieldValue(`${type}.workflow`, null)
          }

          return (
            <div>
              <Form className="form">
                <div className="find-replace-internal-link-form__find-fields">
                  <h3>Choose Link</h3>

                  <div className="field-group">
                    <div className="form__field ">
                      <Field
                        component={CustomSelect}
                        filterOption={customSelectFilter}
                        label="Internal Link Type"
                        name="find.internalType"
                        options={internalLinkOptions}
                        required
                        placeholder="Internal Link Type"
                        setFieldTouched={setFieldTouched}
                        onChange={() => onInternalTypeChange('find')}
                        isClearable={false}
                      />
                    </div>
                  </div>

                  {values.find.internalType === 'workflow' && (
                    <>
                      <div className="field-group">
                        <div className="form__field half">
                          <Field
                            component={CustomSelect}
                            filterOption={customSelectFilter}
                            label="Workflow"
                            name="find.workflow"
                            options={workflowOptions}
                            required
                            placeholder="Workflow"
                            setFieldTouched={setFieldTouched}
                            onChange={value => onWorkflowChange(value, 'find')}
                            isClearable={false}
                          />
                        </div>
                        {values.find.workflow && (
                          <div className="form__field half">
                            <Field
                              component={CustomSelect}
                              filterOption={customSelectFilter}
                              label="Category"
                              name="find.category"
                              options={getCategoryOptions(values.find.workflow)}
                              required
                              placeholder="Category"
                              setFieldTouched={setFieldTouched}
                              isClearable={false}
                            />
                          </div>
                        )}
                      </div>
                      {values.find.workflow && (
                        <div className="field-group">
                          <div className="form__field half">
                            <Field
                              component={CustomSelect}
                              filterOption={customSelectFilter}
                              label="Step"
                              name="find.step"
                              disabled={!values.workflow}
                              options={getStepOptions(values.find.workflow)}
                              placeholder="Step"
                              setFieldTouched={setFieldTouched}
                              isClearable={false}
                            />
                          </div>
                        </div>
                      )}
                    </>
                  )}
                  {values.find.internalType === 'category' && (
                    <div className="field-group">
                      <div className="form__field half">
                        <Field
                          component={CustomSelect}
                          filterOption={customSelectFilter}
                          label="Category"
                          name="find.category"
                          options={categoryOptions}
                          required
                          placeholder="Category"
                          setFieldTouched={setFieldTouched}
                          isClearable={false}
                        />
                      </div>
                    </div>
                  )}
                </div>

                <div className="find-replace-internal-link-form__replace-fields">
                  <h3>New Link</h3>
                  <div className="field-group">
                    <div className="form__field half">
                      <Field
                        component={CustomSelect}
                        filterOption={customSelectFilter}
                        label="Internal Link Type"
                        name="replace.internalType"
                        options={internalLinkOptions}
                        required
                        placeholder="Internal Link Type"
                        setFieldTouched={setFieldTouched}
                        onChange={() => onInternalTypeChange('replace')}
                        isClearable={false}
                      />
                    </div>
                    <div className="form__field half">
                      <Field
                        component={CustomSelect}
                        label="Target"
                        name="replace.target"
                        menuPlacement="bottom"
                        options={targetOptions}
                        required={true}
                        setFieldTouched={setFieldTouched}
                        isClearable={false}
                      />
                    </div>
                  </div>

                  {values.replace.internalType === 'workflow' && (
                    <>
                      <div className="field-group">
                        <div className="form__field half">
                          <Field
                            component={CustomSelect}
                            filterOption={customSelectFilter}
                            label="Workflow"
                            name="replace.workflow"
                            options={workflowOptions}
                            required
                            placeholder="Workflow"
                            setFieldTouched={setFieldTouched}
                            onChange={value =>
                              onWorkflowChange(value, 'replace')
                            }
                            isClearable={false}
                          />
                        </div>
                        {values.replace.workflow && (
                          <div className="form__field half">
                            <Field
                              component={CustomSelect}
                              filterOption={customSelectFilter}
                              label="Category"
                              name="replace.category"
                              options={getCategoryOptions(
                                values.replace.workflow,
                              )}
                              required
                              placeholder="Category"
                              setFieldTouched={setFieldTouched}
                              isClearable={false}
                            />
                          </div>
                        )}
                      </div>
                      {values.replace.workflow && (
                        <div className="field-group">
                          <div className="form__field half">
                            <Field
                              component={CustomSelect}
                              filterOption={customSelectFilter}
                              label="Step"
                              name="replace.step"
                              disabled={!values.workflow}
                              options={getStepOptions(values.replace.workflow)}
                              placeholder="Step"
                              setFieldTouched={setFieldTouched}
                              isClearable={false}
                            />
                          </div>
                        </div>
                      )}
                    </>
                  )}
                  {values.replace.internalType === 'category' && (
                    <div className="field-group">
                      <div className="form__field half">
                        <Field
                          component={CustomSelect}
                          filterOption={customSelectFilter}
                          label="Category"
                          name="replace.category"
                          options={categoryOptions}
                          required
                          placeholder="Category"
                          setFieldTouched={setFieldTouched}
                          isClearable={false}
                        />
                      </div>
                    </div>
                  )}
                  <div className="find-replace-internal-link-form__submit">
                    <button
                      type="submit"
                      className="btn btn--dark-green"
                      disabled={!isValid}
                    >
                      Search
                    </button>
                  </div>
                </div>
              </Form>
            </div>
          )
        }}
      </Formik>
    </div>
  )
}

FindReplaceInternalLinkForm.propTypes = {
  onSubmit: PropTypes.func,
}
export default FindReplaceInternalLinkForm
