import React from 'react'
import PropTypes from 'prop-types'

function VolumeOnIcon({ colour }, props) {
  return (
    <svg
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M18.1442 6.59914C17.7501 6.43595 17.2964 6.52616 16.9948 6.8278L9.39603 14.4268H3.05469C2.47222 14.4268 2 14.899 2 15.4814V24.5189C2 25.1014 2.47222 25.5736 3.05469 25.5736H9.39603L16.9948 33.1725C17.3185 33.4962 17.7793 33.5523 18.1442 33.4011C18.5383 33.2379 18.7953 32.8533 18.7953 32.4268V7.57361C18.7953 7.14702 18.5383 6.76241 18.1442 6.59914ZM16.6859 29.8805L10.5786 23.7731C10.3808 23.5753 10.1126 23.4642 9.83288 23.4642H4.10938V16.5361H9.83281C10.1126 16.5361 10.3808 16.4249 10.5785 16.2271L16.6858 10.1198L16.6859 29.8805Z"
        fill={colour}
      />
      <path
        d="M33.059 8.07164C33.0546 8.06721 33.0501 8.06279 33.0456 8.05843C31.7525 6.77902 29.6597 6.78317 28.3714 8.07101C27.0773 9.36511 27.0751 11.4616 28.3714 12.758C28.3758 12.7623 28.3801 12.7666 28.3845 12.7708C30.3109 14.704 31.3718 17.2708 31.3718 20.0002C31.3718 22.7293 30.3114 25.2957 28.3852 27.2289C28.3735 27.2402 28.362 27.2518 28.3508 27.2637C27.0793 28.5566 27.0859 30.6432 28.3705 31.9286C28.375 31.9331 28.3795 31.9376 28.3841 31.942C29.6692 33.2134 31.7528 33.2211 33.0456 31.942C33.05 31.9376 33.0546 31.9332 33.059 31.9288C36.4736 28.5141 38 24.1073 38 20.0002C38 15.8924 36.4729 11.4855 33.059 8.07164ZM31.5778 30.4269C31.574 30.4305 31.5705 30.4341 31.5668 30.4377C31.0971 30.9074 30.3327 30.9074 29.863 30.4377C29.8602 30.435 29.8574 30.4321 29.8547 30.4295C29.3932 29.9593 29.3958 29.2016 29.8622 28.7352C29.8659 28.7316 29.8695 28.7279 29.873 28.7243C32.2 26.3922 33.4812 23.2945 33.4812 20.0002C33.4812 16.7024 32.1973 13.6017 29.8657 11.2689C29.8617 11.2648 29.8577 11.2609 29.8536 11.2569C29.3929 10.7863 29.3946 10.031 29.8629 9.56269C30.3328 9.093 31.097 9.093 31.5667 9.56269C31.5704 9.56635 31.574 9.56993 31.5777 9.57345C34.5589 12.5606 35.8906 16.4119 35.8906 20.0002C35.8906 23.5888 34.5587 27.44 31.5778 30.4269Z"
        fill={colour}
      />
      <path
        d="M26.6762 14.4698C26.6732 14.4667 26.6702 14.4636 26.6671 14.4606L26.667 14.4605C25.3753 13.1692 23.2735 13.1691 21.9819 14.4605C21.976 14.4665 21.9701 14.4725 21.9642 14.4787C20.6893 15.7718 20.6947 17.8615 21.981 19.1486C22.2086 19.3761 22.3339 19.6786 22.3339 20.0002C22.3339 20.3218 22.2086 20.6242 21.981 20.8517C21.9744 20.8583 21.9679 20.865 21.9615 20.8718C21.3477 21.4953 21.01 22.3194 21.01 23.1955C21.01 24.0805 21.3546 24.9125 21.9804 25.5382C21.9852 25.5431 21.9902 25.548 21.9952 25.5527C22.6395 26.1895 23.482 26.5078 24.3245 26.5078C25.166 26.5078 26.0076 26.1901 26.6516 25.5549C26.6574 25.5494 26.6631 25.5438 26.6687 25.5382C28.1479 24.059 28.9626 22.0922 28.9626 20.0002C28.9626 17.9117 28.1506 15.9481 26.6762 14.4698ZM25.1898 24.0338C25.1853 24.0382 25.1808 24.0426 25.1764 24.0471C24.7067 24.5168 23.9423 24.5168 23.4727 24.0471C23.4695 24.044 23.4664 24.0409 23.4633 24.0378C23.2414 23.8116 23.1195 23.513 23.1195 23.1955C23.1195 22.874 23.2446 22.5718 23.4719 22.3445C23.4785 22.338 23.485 22.3313 23.4913 22.3246C24.1054 21.7008 24.4434 20.8764 24.4434 20.0002C24.4434 19.1151 24.0986 18.2829 23.4728 17.6572C23.0031 17.1872 23.003 16.4228 23.4726 15.9533C23.4764 15.9495 23.4802 15.9457 23.4839 15.9418C23.9503 15.4867 24.6977 15.4864 25.1642 15.9409C25.1723 15.9494 25.1805 15.9576 25.189 15.9657C26.2623 17.0451 26.8532 18.4773 26.8532 20.0002C26.8532 21.5227 26.2626 22.9546 25.1898 24.0338Z"
        fill={colour}
      />
    </svg>
  )
}

VolumeOnIcon.defaultProps = {
  colour: '#000000',
}

VolumeOnIcon.propTypes = {
  colour: PropTypes.string,
}

export default VolumeOnIcon
