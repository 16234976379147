import React from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'
import { useNavigate, useParams } from 'react-router-dom'
import routes from '../../../../configuration/routes'
import getIconComponent from '../../../../utilities/icon/getIconComponent'
import validateUserManagementAccess from '../../../../utilities/permissions/validateUserManagementAccess'
import { ReactComponent as GearIcon } from '../../../../assets/icons/gear-icon.svg'
import { ReactComponent as DeleteIcon } from '../../../../assets/icons/delete-icon.svg'
import { ReactComponent as DuplicateIcon } from '../../../../assets/icons/duplicate-icon.svg'
import { ReactComponent as ReorderIcon } from '../../../../assets/icons/reorder-icon.svg'
import { ReactComponent as EditIcon } from '../../../../assets/icons/edit-icon.svg'
import clsx from 'clsx'

function ManageWorkflowsStepsCard({
  onClick,
  onDeleteClick,
  onDuplicateClick,
  onEditClick,
  onConfigureClick,
  index,
  parentIndex,
  stepData,
  isSelected,
  handleProps = {},
}) {
  const isManager = validateUserManagementAccess()
  const { workflow } = useParams()
  const navigate = useNavigate()

  const workflowId = get(stepData, 'workflowId', null)
  const stepId = get(stepData, '_id', null)
  const stepName = get(stepData, 'name', null)
  const stepsStatus = get(stepData, 'status', 'draft')
  const icon = get(stepData, 'icon', null)
  const iconColour = get(icon, 'colour', null) || undefined
  const Icon = getIconComponent(icon)
  const stepSlug = get(stepData, 'slug', null)

  function handleEditClick(evt) {
    evt.stopPropagation()
    onEditClick(stepData)
  }

  function handleDeleteClick(evt) {
    evt.stopPropagation()
    onDeleteClick(stepData)
  }

  function handleConfigureClick(evt) {
    evt.stopPropagation()
    onConfigureClick(stepData)
  }

  function handleDuplicateClick(evt) {
    evt.stopPropagation()
    onDuplicateClick(workflowId, stepId)
  }

  function handleClick() {
    navigate(`${routes.manageWorkflows}/${workflow}/${stepSlug}`)
  }

  function handleCheckboxClick(evt) {
    evt.stopPropagation()
    onClick(evt, stepData)
  }

  return (
    <tr
      className={clsx('manage-workflow__steps-card', { selected: isSelected })}
      {...handleProps}
      onClick={handleClick}
    >
      <td className="manage-workflow__steps-card-reorder reorder">
        <ReorderIcon />
      </td>

      <td className="manage-workflow__steps-card-checkbox select">
        <label>
          <input
            type="checkbox"
            checked={isSelected}
            onClick={handleCheckboxClick}
          />
        </label>
      </td>

      <td className="manage-workflow__steps-card-details details" tabIndex={0}>
        <div className="manage-workflow__steps-card-details-icon">
          {Icon ? <Icon colour={iconColour} /> : null}
        </div>

        <p className="manage-workflow__steps-card-details-name">{stepName}</p>
      </td>

      {isManager ? (
        <td className="stage">
          <div className={`status-flag lg ${stepsStatus}`}>
            <span className={'status-icon__indicator'}></span>
            {stepsStatus}
          </div>
        </td>
      ) : null}

      <td className="manage-workflow__steps-card-actions actions">
        <div className="manage-workflow__steps-card-actions-details">
          <div className="manage-workflow__steps-card-actions-toggles">
            <button
              className="manage-workflow__steps-card-actions-icon"
              onClick={handleEditClick}
              data-tooltip="Edit Step"
            >
              <EditIcon />
            </button>

            <button
              className="manage-workflow__steps-card-actions-icon"
              onClick={handleDuplicateClick}
              data-tooltip="Duplicate Step"
            >
              <DuplicateIcon />
            </button>

            <button
              className="manage-workflow__steps-card-actions-icon"
              onClick={handleConfigureClick}
              data-tooltip="Configure Step"
            >
              <GearIcon />
            </button>

            <button
              className="manage-workflow__steps-card-actions-icon red"
              onClick={handleDeleteClick}
              data-tooltip="Delete Step"
            >
              <DeleteIcon />
            </button>
          </div>
        </div>
      </td>
    </tr>
  )
}

ManageWorkflowsStepsCard.propTypes = {
  onClick: PropTypes.func,
  onDeleteClick: PropTypes.func,
  onDuplicateClick: PropTypes.func,
  onEditClick: PropTypes.func,
  onConfigureClick: PropTypes.func,
  index: PropTypes.number,
  parentIndex: PropTypes.number,
  stepData: PropTypes.object,
  isSelected: PropTypes.bool,
  handleProps: PropTypes.object,
}

export default ManageWorkflowsStepsCard
