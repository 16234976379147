import React from 'react'
import PropTypes from 'prop-types'

function CautionIcon({ colour }, props) {
  return (
    <svg
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g mask="url(#mask0_101_481)">
        <path
          d="M23.2361 6.2408L36.9665 30.0225C38.4047 32.5136 36.6069 35.6276 33.7304 35.6276L6.26964 35.6276C3.39315 35.6276 1.59533 32.5136 3.03357 30.0225L16.7639 6.2408C18.2022 3.74963 21.7978 3.74963 23.2361 6.2408Z"
          stroke={colour}
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M32.5326 27.968L20.8002 7.64695C20.5682 7.24476 20.1991 7.18499 20.0001 7.18499C19.8011 7.18499 19.432 7.24476 19.1999 7.64695L5.4693 31.4287C5.23727 31.8309 5.36945 32.1804 5.4693 32.3534C5.56914 32.5256 5.80539 32.8153 6.26945 32.8153L33.7307 32.8153C34.1948 32.8153 34.431 32.5256 34.5309 32.3534C34.6307 32.1804 34.7629 31.8309 34.5309 31.4287L33.5873 29.7947"
          stroke={colour}
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M18.2233 17.9342L18.2233 23.0783C18.2233 24.0599 19.0185 24.8551 20.0001 24.8551C20.9816 24.8551 21.7769 24.0599 21.7769 23.0783L21.7769 14.9417C21.7769 13.9602 20.9816 13.1642 20.0001 13.1642C19.5093 13.1642 19.0649 13.3632 18.7436 13.6853C18.4222 14.0066 18.2233 14.451 18.2233 14.9417L18.2233 15.8249"
          stroke={colour}
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M18.2231 28.542C18.2231 27.5605 19.0187 26.765 20.0001 26.765C20.9815 26.765 21.7771 27.5605 21.7771 28.542C21.7771 29.5234 20.9815 30.319 20.0001 30.319C19.0187 30.319 18.2231 29.5234 18.2231 28.542Z"
          stroke={colour}
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  )
}

CautionIcon.defaultProps = {
  colour: '#000000',
}

CautionIcon.propTypes = {
  colour: PropTypes.string,
}

export default CautionIcon
