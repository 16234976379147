import React, { Fragment, useRef, useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import algoliasearch from 'algoliasearch/lite'
import { useSelector } from 'react-redux'
import { selectUserProducts, selectUserState } from '../../../redux/user/user'
import { createAutocomplete } from '@algolia/autocomplete-core'
import { getAlgoliaResults } from '@algolia/autocomplete-preset-algolia'
import { chain, get, isEmpty, uniqBy } from 'lodash'
import {
  selectCheckClientIndexes,
  selectCheckClientIndexLoading,
} from '../../../redux/global-search/checkClientIndexes'
import Modal from '../elements/Modal'
import validateWorkflowClient from '../../../utilities/permissions/validateWorkflowClient'
import validateComparisonClient from '../../../utilities/permissions/validateComparisonClient'
import PowerSearchResultsList from './elements/PowerSearchResultsList'
import getResultRouteDetails from '../../../utilities/algolia/getResultRouteDetails'
// import PowerSearchResultPreview from './elements/PowerSearchResultPreview'
import PowerSearchFooter from './elements/PowerSearchFooter'
import MaterialLoader from '../elements/MaterialLoader'
import { ReactComponent as SearchIcon } from '../../../assets/icons/power-search-input-icon.svg'
import '../../../styles/global-components/power-search/power-search.scss'

const searchClient = algoliasearch(
  process.env.REACT_APP_ALGOLIA_APP_ID,
  process.env.REACT_APP_ALGOLIA_API_KEY,
)

function PowerSearch({
  activeClientId,
  activeOperatingLocation,
  handlePowerSearchModalEvent,
  isPowerSearchModalVisible,
  handleNavigationEvent,
}) {
  const [autocompleteState, setAutocompleteState] = useState({})
  const [activeResultsFilter, setActiveResultsFilter] = useState('All')
  const indexesExistLoading = useSelector(selectCheckClientIndexLoading)
  const indexesExistData = useSelector(selectCheckClientIndexes)
  const inputRef = useRef(null)
  const userState = useSelector(selectUserState)
  const userProducts = useSelector(selectUserProducts)
  const isWorkflowsClient = validateWorkflowClient(userProducts)
  const isComparisonClient = validateComparisonClient(userProducts)
  // const activeItemId = get(autocompleteState, 'activeItemId', 0)
  const userInsuranceProductLines = get(
    userState,
    'user.insuranceProductLines',
    [],
  )
  const userFallbackInsuranceProductLine = get(
    userInsuranceProductLines,
    0,
    'Personal_Retail',
  )
  const userDefaultInsuranceProductLine =
    get(
      userState,
      'user.defaultInsuranceProductLine',
      userFallbackInsuranceProductLine,
    ) || userFallbackInsuranceProductLine
  const activeInsuranceProductLine =
    window.localStorage.getItem('qf_insurance_product_line') ||
    userDefaultInsuranceProductLine
  const baseAlgoliaIndexKey = `${activeClientId}_${activeOperatingLocation.toLowerCase()}`

  function handleTogglePowerSearchModal() {
    setAutocompleteState({ query: '' })
    handlePowerSearchModalEvent()
  }

  // const debouncedSearchHook = debounce(function (query, search) {
  //   search(query)
  // }, 300)
  const autoCompleteQuery = get(autocompleteState, 'query', null)
  const autoCompleteStatus = get(autocompleteState, 'status', 'idle')
  const autoCompleteCollections = get(autocompleteState, 'collections', [])
  const autoCompleteCollectionItems = get(
    autocompleteState,
    'collections[0].items',
    [],
  )
  const isAutoCompleteOpen = get(autocompleteState, 'isOpen', false)
  const isAutoCompleteLoading = autoCompleteStatus !== 'idle'
  const noAutoCompleteResults =
    autoCompleteQuery !== '' &&
    autoCompleteQuery?.length > 2 &&
    !isAutoCompleteLoading &&
    !isAutoCompleteOpen &&
    isEmpty(autoCompleteCollectionItems)

  const autocomplete = useMemo(
    () =>
      createAutocomplete({
        initialState: autocompleteState,
        defaultActiveItemId: 0,
        autoFocus: true,
        onStateChange({ state }) {
          setAutocompleteState(state)
        },
        onReset() {
          setAutocompleteState({ query: '' })
        },
        onSubmit({ state }) {
          setAutocompleteState({ query: state.query })
        },
        getSources({ query }) {
          let queriedIndexes = []
          const comparisonsIndexExists = get(
            indexesExistData,
            'comparisonsIndexExists',
            false,
          )
          const pagesIndexExists = get(
            indexesExistData,
            'pagesIndexExists',
            false,
          )
          const workflowsIndexExists = get(
            indexesExistData,
            'workflowsIndexExists',
            false,
          )

          if (isComparisonClient) {
            if (comparisonsIndexExists) {
              queriedIndexes.push({
                indexName: `${baseAlgoliaIndexKey}_comparisons`,
                query,
                params: {
                  exactOnSingleWordQuery: 'word',
                  minWordSizefor1Typo: 5,
                  hitsPerPage: 999,
                  page: 0,
                  highlightPreTag: '<mark class="power-search__highlight">',
                  highlightPostTag: '</mark>',
                },
              })
            }

            if (pagesIndexExists) {
              queriedIndexes.push({
                indexName: `${baseAlgoliaIndexKey}_pages`,
                query,
                params: {
                  exactOnSingleWordQuery: 'word',
                  minWordSizefor1Typo: 5,
                  hitsPerPage: 999,
                  page: 0,
                  highlightPreTag: '<mark class="power-search__highlight">',
                  highlightPostTag: '</mark>',
                },
              })
            }
          }

          if (isWorkflowsClient && workflowsIndexExists) {
            queriedIndexes.push({
              indexName: `${baseAlgoliaIndexKey}_workflows`,
              query,
              params: {
                exactOnSingleWordQuery: 'word',
                minWordSizefor1Typo: 5,
                hitsPerPage: 999,
                page: 0,
                highlightPreTag: '<mark class="power-search__highlight">',
                highlightPostTag: '</mark>',
              },
            })
          }

          return query.length > 2
            ? [
                {
                  sourceId: 'comparisons',
                  getItems({ query }) {
                    return getAlgoliaResults({
                      searchClient,
                      transformResponse({ hits }) {
                        return !isEmpty(Object(hits))
                          ? Object(hits).map(hit => {
                              const firstResult = hit[0]
                              const resultType = get(firstResult, 'type', null)
                              const isWorkflowResult = resultType === 'workflow'
                              const searchResults = isWorkflowResult
                                ? uniqBy(hit, '_id')
                                : uniqBy(hit, 'id')

                              return isWorkflowResult
                                ? searchResults
                                : searchResults.filter(result => {
                                    const insuranceProductLines = get(
                                      result,
                                      'insuranceProductLines',
                                      [],
                                    )

                                    return (
                                      !isEmpty(insuranceProductLines) &&
                                      insuranceProductLines.includes(
                                        activeInsuranceProductLine,
                                      )
                                    )
                                  })
                            })
                          : []
                      },
                      queries: queriedIndexes,
                    })
                  },
                  onSelect({ item, setQuery, setIsOpen }) {
                    const routeDetails = getResultRouteDetails(
                      item,
                      activeOperatingLocation,
                    )
                    const link = get(routeDetails, 'link', null)
                    const isExternal = get(routeDetails, 'external', false)

                    // todo: tracking
                    setQuery('')
                    setIsOpen(false)
                    handleTogglePowerSearchModal()

                    if (isExternal) {
                      window.location.assign(link)
                    } else {
                      handleNavigationEvent(link)
                    }
                  },
                },
              ]
            : []
        },
      }),
    [],
  )

  return indexesExistLoading ? (
    <MaterialLoader color={'#000'} />
  ) : (
    <Modal
      modalClassName="modal--power-search modal--lg modal--slim"
      isOpen={isPowerSearchModalVisible}
      onRequestClose={() => handleTogglePowerSearchModal()}
    >
      <div className="power-search" {...autocomplete.getRootProps({})}>
        <div className="power-search__input">
          <div className="power-search__input-icon">
            {isAutoCompleteLoading ? (
              <MaterialLoader color={'#000'} />
            ) : (
              <SearchIcon className="power-search__input-icon-svg" />
            )}
          </div>

          <input
            className="power-search__input-field"
            type="text"
            ref={inputRef}
            {...autocomplete.getInputProps({ inputElement: inputRef.current })}
            placeholder="Search for workflows, comparisons & pages"
          />

          <button
            className="power-search__input-cancel"
            onClick={() => handleTogglePowerSearchModal()}
          >
            Cancel
          </button>
        </div>

        {isAutoCompleteOpen ? (
          <>
            <div
              className="power-search__results"
              {...autocomplete.getPanelProps({})}
            >
              {autoCompleteCollections.map((collection, index) => {
                const { source, items } = collection
                // const activeResultData = get(items, activeItemId, {})
                const groupedItems = chain(items)
                  .groupBy(item => {
                    const eventType =
                      get(item, '__typename', null) || get(item, 'type', null)

                    return eventType
                  })
                  .map(function (value, key) {
                    return {
                      type: `${key}s`,
                      items: value,
                    }
                  })
                  .value()
                const filteredItems =
                  activeResultsFilter === 'All'
                    ? groupedItems
                    : groupedItems.filter(
                        groupItem => groupItem?.type === activeResultsFilter,
                      )

                return !isEmpty(items) ? (
                  <Fragment key={`power-search-results-list-${index}`}>
                    <PowerSearchResultsList
                      activeResultsFilter={activeResultsFilter}
                      autocomplete={autocomplete}
                      filteredItems={filteredItems}
                      groupedItems={groupedItems}
                      resultGroup={index}
                      setActiveResultsFilter={setActiveResultsFilter}
                      source={source}
                    />

                    {/*<PowerSearchResultPreview activeResult={activeResultData} />*/}
                  </Fragment>
                ) : null
              })}
            </div>

            <PowerSearchFooter />
          </>
        ) : noAutoCompleteResults ? (
          <div className="power-search__results no-results">
            <p>
              <strong>0</strong> results found containing the search term{' '}
              <strong>{autoCompleteQuery}</strong> - please alter your search
              query and try again.
            </p>
          </div>
        ) : null}
      </div>
    </Modal>
  )
}

PowerSearch.propTypes = {
  activeClientId: PropTypes.string.isRequired,
  activeOperatingLocation: PropTypes.string.isRequired,
  handlePowerSearchModalEvent: PropTypes.func.isRequired,
  isPowerSearchModalVisible: PropTypes.bool,
  handleNavigationEvent: PropTypes.func.isRequired,
}

export default PowerSearch
