import React from 'react'
import PropTypes from 'prop-types'
import { Field, Form, Formik } from 'formik'
import TextField from '../../forms/elements/TextField'
import * as Yup from 'yup'
import { ReactComponent as CloseModalIcon } from '../../../assets/icons/close-icon.svg'
import { ReactComponent as DeleteIcon } from '../../../assets/icons/delete-icon.svg'
import MaterialLoader from '../elements/MaterialLoader'
import clsx from 'clsx'

const MediaLibraryEditPanel = ({
  item,
  error,
  showAltRequiredMessage,
  onCancel,
  onSubmit,
  onDelete,
  isLoading,
}) => {
  const isImage = item.type === 'image'
  const isDocument = item.type === 'document'
  const typeLabel = isImage ? 'Image' : isDocument ? 'Document' : 'Asset'
  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Required'),
    alt: isImage ? Yup.string().required('Required') : Yup.string(),
    caption: Yup.string(),
  })

  return (
    <div className="media-library__edit-panel-container">
      <button
        className="media-library__edit-panel-close-area"
        onClick={onCancel}
      ></button>
      <div
        className={clsx('media-library__edit-panel', {
          'document-panel': isDocument,
        })}
      >
        <div className="media-library__edit-panel-title">
          {item.name}
          <button className="modal__close" onClick={onCancel}>
            <CloseModalIcon />
          </button>
        </div>
        <div className="flex">
          {isImage && (
            <div className="image">
              <div>
                <img src={item.url} className="" />
                <div>
                  <button className="btn btn--secondary-red" onClick={onDelete}>
                    <DeleteIcon /> Delete This {typeLabel}
                  </button>
                </div>
              </div>
            </div>
          )}

          <Formik
            enableReinitialize
            initialValues={{
              name: item?.name,
              alt: item?.alt ? item.alt : '',
              caption: item?.caption ? item.caption : '',
              file: null,
            }}
            validationSchema={validationSchema}
            onSubmit={values => {
              onSubmit(item._id, values)
            }}
          >
            {({ isValid, setFieldTouched, setFieldValue, values }) => {
              const submitButtonDisabled = !isValid

              return (
                <Form className="form">
                  {item && (
                    <>
                      {showAltRequiredMessage && (
                        <div className="warning warning--red">
                          An Alt (description) of the item is required.
                        </div>
                      )}
                      {error && (
                        <div className="warning warning--red">{error}</div>
                      )}
                      <div className="">
                        <div className="field-container">
                          <Field
                            component={TextField}
                            label="Name"
                            name="name"
                            placeholder="Enter Image Name"
                            required={true}
                            type="text"
                          />
                        </div>
                        {isImage && (
                          <>
                            <div className="field-container">
                              <Field
                                component={TextField}
                                label="Alt"
                                name="alt"
                                placeholder="Enter Image Alt"
                                required={true}
                                type="text"
                                tooltip="Alt is a brief description of the image (for screen readers). If the image doesn't load, the alt will be displayed instead."
                                forceDisplayError={showAltRequiredMessage}
                              />
                            </div>
                            <div className="field-container">
                              <Field
                                component={TextField}
                                label="Caption"
                                name="caption"
                                placeholder="Enter Image Caption"
                                required={false}
                                type="text"
                              />
                            </div>
                          </>
                        )}
                        <div className="field-container">
                          <label>Replace {typeLabel}</label>
                          <div className="flex align-center">
                            <div>
                              <label
                                htmlFor="file"
                                className="btn btn--bright-purple"
                              >
                                Choose File
                              </label>
                            </div>
                            <div>
                              <input
                                type="file"
                                name="file"
                                id="file"
                                onChange={event => {
                                  setFieldValue(
                                    'file',
                                    event.currentTarget.files[0],
                                  )
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="media-library__edit-panel-actions flex ">
                        <button
                          type="button"
                          className="btn btn--secondary"
                          onClick={onCancel}
                        >
                          Cancel
                        </button>
                        <button
                          type="submit"
                          className="btn btn--bright-purple"
                          disabled={submitButtonDisabled}
                        >
                          Save
                        </button>
                      </div>
                    </>
                  )}
                </Form>
              )
            }}
          </Formik>
        </div>
        {isLoading && <MaterialLoader containerClasses="overlay-loader" />}
      </div>
    </div>
  )
}

MediaLibraryEditPanel.propTypes = {
  item: PropTypes.object,
  error: PropTypes.string,
  showAltRequiredMessage: PropTypes.bool,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  onDelete: PropTypes.func,
  isLoading: PropTypes.bool,
}

export default MediaLibraryEditPanel
