import PropTypes from 'prop-types'
import '../../../../styles/consumer-flow/base-fields/divider-field.scss'
const DividerField = ({ field }) => {
  return <div className="divider-field"></div>
}

DividerField.propTypes = {
  field: PropTypes.object,
}

export default DividerField
