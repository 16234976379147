import React from 'react'
import PropTypes from 'prop-types'

function QuestionIcon({ colour }, props) {
  return (
    <svg
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g mask="url(#mask0_101_139)">
        <path
          d="M29.7999 5.74354C23.0615 1.1001 13.7629 1.77538 7.769 7.76931C1.6322 13.906 1.07124 23.5064 6.08474 30.2772L4.75316 35.2468L9.72285 33.9152C16.4936 38.9288 26.0939 38.3678 32.2307 32.231L32.2307 32.231C38.2247 26.237 38.8999 16.9382 34.2562 10.1997"
          stroke={colour}
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15.3069 15.4755C14.2809 15.4858 13.53 14.4886 13.8484 13.5132C14.1685 12.5328 14.7285 11.6406 15.4994 10.9043C16.7648 9.6958 18.4269 9.07473 20.1788 9.15531C21.7091 9.22569 23.1837 9.89626 24.3308 11.0434C25.4779 12.1906 26.1484 13.6651 26.2189 15.1954C26.3339 17.6962 24.9647 20.0388 22.7304 21.1633C21.9047 21.5788 21.3917 22.398 21.3917 23.3011C21.3917 24.1338 20.7166 24.8088 19.884 24.8088L19.8781 24.8088C19.0454 24.8088 18.3703 24.1338 18.3703 23.3011C18.3703 21.2496 19.5206 19.3963 21.3721 18.4645C22.5433 17.875 23.2611 16.6464 23.2007 15.3343C23.1267 13.7245 21.6496 12.2474 20.0399 12.1733C19.1197 12.1308 18.2488 12.4563 17.5861 13.0893C17.1911 13.4665 16.9018 13.9219 16.7324 14.4221C16.523 15.0405 15.9597 15.4689 15.3069 15.4755Z"
          stroke={colour}
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M21.3917 29.3409C21.3917 30.1752 20.7154 30.8516 19.881 30.8516C19.0467 30.8516 18.3704 30.1752 18.3704 29.3409C18.3704 28.5066 19.0467 27.8302 19.881 27.8302C20.7154 27.8302 21.3917 28.5066 21.3917 29.3409Z"
          stroke={colour}
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  )
}

QuestionIcon.defaultProps = {
  colour: '#000000',
}

QuestionIcon.propTypes = {
  colour: PropTypes.string,
}

export default QuestionIcon
