import React, { useEffect, useRef } from 'react'

export default function usePreviousLocation(location) {
  const prevLocRef = useRef(location)

  useEffect(() => {
    prevLocRef.current = location
  }, [location])

  return prevLocRef.current
}
