import { useDispatch, useSelector } from 'react-redux'
import {
  selectManagedContentId,
  selectManageWorkflowModalVisibility,
  toggleManageWorkflowModal,
} from '../../../../redux/user/userSelections'
import {
  selectSocketResourceBlockedBy,
  selectSocketResourceOutOfDate,
  selectSocketSessionExpired,
} from '../../../../redux/socket/socket'
import React from 'react'
import ManageWorkflowModalBlocked from './ManageWorkflowModalBlocked'
import ManageWorkflowModalOutOfDate from './ManageWorkflowModalOutOfDate'
import ManageWorkflowModal from './ManageWorkflowModal'
import useOnResourceBecomesUnblocked from '../../../../hooks/useOnResourceBecomesUnblocked'

const ManageWorkflowModalContainer = () => {
  const managedContentId = useSelector(selectManagedContentId)
  const resourceBlockedBy = useSelector(state =>
    selectSocketResourceBlockedBy({
      state,
      type: 'configureWorkflow',
      id: managedContentId,
    }),
  )

  const isResourceOutOfDate = useSelector(state =>
    selectSocketResourceOutOfDate({
      state,
      type: 'configureWorkflow',
      id: managedContentId,
    }),
  )
  const isSocketSessionExpired = useSelector(selectSocketSessionExpired)

  useOnResourceBecomesUnblocked({
    name: 'configureWorkflow',
    data: {
      workflowId: managedContentId,
    },
    isBlocked: !!resourceBlockedBy,
  })

  if (isSocketSessionExpired) {
    return null
  }

  if (resourceBlockedBy) {
    return <ManageWorkflowModalBlocked />
  }

  if (isResourceOutOfDate) {
    return <ManageWorkflowModalOutOfDate />
  }

  return <ManageWorkflowModal />
}

export default ManageWorkflowModalContainer
