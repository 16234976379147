import React from 'react'
import PropTypes from 'prop-types'

function EventIcon({ colour }, props) {
  return (
    <svg
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.2773 14.3301H4.64765C4.35501 14.3301 4.11792 14.5672 4.11792 14.8598V33.3104C4.11792 33.603 4.35508 33.8402 4.64765 33.8402H35.3524C35.6451 33.8402 35.8822 33.603 35.8822 33.3104V14.8598C35.8822 14.5672 35.645 14.3301 35.3524 14.3301H14.3964C14.1038 14.3301 13.8667 14.5672 13.8667 14.8598C13.8667 15.1524 14.1038 15.3895 14.3964 15.3895H34.8227V32.7806H5.17739V15.3895H12.2773C12.57 15.3895 12.8071 15.1524 12.8071 14.8598C12.8071 14.5672 12.57 14.3301 12.2773 14.3301Z"
        fill={colour}
      />
      <path
        d="M36.0575 12.2173H33.7254L22.1675 6.50862C22.1937 6.37756 22.2077 6.24214 22.2077 6.10348C22.2077 4.96934 21.2851 4.04663 20.151 4.04663C19.0168 4.04663 18.0941 4.96934 18.0941 6.10348C18.0941 6.24214 18.1081 6.37763 18.1344 6.50862L6.5765 12.2173H3.94245C2.87138 12.2173 2 13.0887 2 14.1597V34.0107C2 35.0818 2.87138 35.9532 3.94245 35.9532H26.5014C26.794 35.9532 27.0312 35.716 27.0312 35.4234C27.0312 35.1309 26.794 34.8937 26.5014 34.8937H3.94245C3.45561 34.8937 3.05954 34.4976 3.05954 34.0108V14.1597C3.05954 13.6729 3.45561 13.2768 3.94245 13.2768H24.1737C24.4664 13.2768 24.7035 13.0397 24.7035 12.7471C24.7035 12.4545 24.4663 12.2174 24.1737 12.2174H8.96909L18.605 7.45798C18.9822 7.88808 19.5352 8.1604 20.151 8.1604C20.7667 8.1604 21.3197 7.88808 21.6969 7.45798L31.3328 12.2174H26.2928C26.0003 12.2174 25.7631 12.4545 25.7631 12.7471C25.7631 13.0397 26.0003 13.2768 26.2928 13.2768H36.0575C36.5444 13.2768 36.9405 13.6729 36.9405 14.1597V34.0107C36.9405 34.4976 36.5444 34.8936 36.0575 34.8936H28.6205C28.3278 34.8936 28.0907 35.1308 28.0907 35.4234C28.0907 35.7159 28.3279 35.9531 28.6205 35.9531H36.0575C37.1286 35.9531 38 35.0817 38 34.0107V14.1597C38 13.0887 37.1287 12.2173 36.0575 12.2173ZM20.151 7.10086C19.601 7.10086 19.1536 6.65347 19.1536 6.10355C19.1536 5.55364 19.601 5.10624 20.151 5.10624C20.7009 5.10624 21.1483 5.55364 21.1483 6.10355C21.1482 6.65347 20.7008 7.10086 20.151 7.10086Z"
        fill={colour}
      />
      <path
        d="M11.023 21.3947C11.3156 21.3947 11.5528 21.1575 11.5528 20.8649C11.5528 20.5724 11.3156 20.3352 11.023 20.3352H8.748C8.45536 20.3352 8.21826 20.5724 8.21826 20.8649V26.5291C8.21826 26.5297 8.21833 26.5302 8.21833 26.5307C8.21833 26.5313 8.21826 26.5318 8.21826 26.5323C8.21826 26.8249 8.45543 27.0621 8.748 27.0621H11.023C11.3156 27.0621 11.5528 26.8249 11.5528 26.5323C11.5528 26.2398 11.3156 26.0026 11.023 26.0026H9.27773V24.2284H11.023C11.3156 24.2284 11.5528 23.9912 11.5528 23.6987C11.5528 23.4061 11.3156 23.1689 11.023 23.1689H9.27773V21.3948H11.023V21.3947Z"
        fill={colour}
      />
      <path
        d="M18.4614 27.0622H20.7365C21.029 27.0622 21.2662 26.825 21.2662 26.5324C21.2662 26.2399 21.029 26.0027 20.7365 26.0027H18.9911V24.2285H20.7365C21.029 24.2285 21.2662 23.9913 21.2662 23.6988C21.2662 23.4062 21.029 23.169 20.7365 23.169H18.9911V21.3949H20.7365C21.029 21.3949 21.2662 21.1578 21.2662 20.8652C21.2662 20.5726 21.029 20.3354 20.7365 20.3354H18.4614C18.1688 20.3354 17.9316 20.5726 17.9316 20.8652V26.5293C17.9316 26.5299 17.9317 26.5304 17.9317 26.531C17.9317 26.5315 17.9316 26.532 17.9316 26.5326C17.9316 26.825 18.1687 27.0622 18.4614 27.0622Z"
        fill={colour}
      />
      <path
        d="M12.2259 21.0073L13.7109 26.3364C13.8318 26.7705 14.2159 27.0622 14.6666 27.0622C15.1173 27.0622 15.5014 26.7705 15.6223 26.3364L17.1073 21.0073C17.1859 20.7255 17.021 20.4333 16.7392 20.3548C16.4571 20.2762 16.1652 20.4411 16.0867 20.7229L14.6666 25.8191L13.2464 20.7229C13.1679 20.4411 12.8761 20.2763 12.5938 20.3548C12.3122 20.4333 12.1473 20.7255 12.2259 21.0073Z"
        fill={colour}
      />
      <path
        d="M23.432 26.5292V22.8886L25.6262 26.792C25.7501 27.0123 26.0211 27.1155 26.2603 27.0333C26.4718 26.9607 26.6177 26.7559 26.6177 26.5323V20.865C26.6177 20.5724 26.3806 20.3353 26.088 20.3353C25.7954 20.3353 25.5583 20.5724 25.5583 20.865V24.5088L23.3641 20.6054C23.246 20.3954 23.001 20.2915 22.7681 20.3525C22.5351 20.4135 22.3726 20.6241 22.3726 20.865V26.5292C22.3726 26.8217 22.6097 27.0589 22.9023 27.0589C23.1949 27.059 23.432 26.8217 23.432 26.5292Z"
        fill={colour}
      />
      <path
        d="M29.725 27.0622C30.0177 27.0622 30.2548 26.825 30.2548 26.5325V21.3981H31.5538C31.8465 21.3981 32.0836 21.1609 32.0836 20.8684C32.0836 20.5758 31.8464 20.3386 31.5538 20.3386H27.8962C27.6035 20.3386 27.3665 20.5758 27.3665 20.8684C27.3665 21.1609 27.6036 21.3981 27.8962 21.3981H29.1953V26.5325C29.1953 26.825 29.4324 27.0622 29.725 27.0622Z"
        fill={colour}
      />
    </svg>
  )
}

EventIcon.defaultProps = {
  colour: '#000000',
}

EventIcon.propTypes = {
  colour: PropTypes.string,
}

export default EventIcon
