import { useSelector } from 'react-redux'
import { get, isEmpty } from 'lodash'
import { routeData } from '../../configuration/routes'
import { selectUserState } from '../../redux/user/user'

export default function validateUserRouteAccess(currentRoute) {
  const userState = useSelector(selectUserState)
  const userAccessLevel = get(userState, 'user.accessLevel', 'User')
  const userProducts = get(userState, 'user.products', [])
  const currentRouteData = routeData.find(route => {
    return route.path === currentRoute
  })
  const routeAccessPermissions = get(currentRouteData, 'accessPermissions', [])
  const userHasRouteAccess = routeAccessPermissions.some(
    permission => permission === userAccessLevel,
  )
  const routeProductLicenses = get(currentRouteData, 'productLicenses', [])
  const userHasProductAccess = isEmpty(routeProductLicenses)
    ? true
    : routeProductLicenses.some(product => userProducts.includes(product))

  return userHasRouteAccess && userHasProductAccess
}
