const processFindReplaceEmailApiData = ({ data, find }) => {
  const items = {}
  const entries = []

  data?.forEach(searchResult => {
    items[searchResult._id] = searchResult
    switch (searchResult.type) {
      case 'component':
        entries.push(...processComponent(searchResult, find))
        break
      case 'field':
        entries.push(...processField(searchResult, find))
        break
    }
  })

  let i = 0

  return {
    entries: entries.map(entry => ({
      ...entry,
      id: i++,
      score: entry.score - entry.highlightIndex / 10000,
      entryType: 'email',
    })),
    items,
  }
}

function processComponent(component, find) {
  const results = []
  const subComponents = component?.subComponents || []

  if (subComponents.length > 0) {
    subComponents.forEach((subComponent, index) => {
      if (subComponent?.configurations?.link?.email === find) {
        results.push({
          link: subComponent?.configurations?.link,
          path: ['subComponents', index, 'configurations', 'link'],
          find,
          index,
          type: 'component',
          subType: 'card',
          itemId: component._id,
          score: component.score,
          breadcrumb: component.breadcrumb,
          replaced: false,
        })
      }
    })
  }

  let highlightIndex = 0

  return results.map(result => ({
    ...result,
    highlightIndex: highlightIndex++,
  }))
}
function processField(field, find) {
  switch (field.fieldType) {
    case 'Button':
      return processButton(field, find)
    case 'RichText':
      return processText(field, find)
    case 'Table':
      return processTable(field, find)
    case 'Image':
      return processImage(field, find)
      return
  }

  return []
}

function processButton(field, find) {
  const results = []
  const link = field?.blocks?.[0]?.configurations?.link
  if (link) {
    results.push({
      find,
      link,
      path: ['blocks', 0, 'configurations', 'link'],
      type: 'field',
      subType: 'button',
      itemId: field._id,
      score: field.score,
      breadcrumb: field.breadcrumb,
      replaced: false,
      highlightIndex: 0,
    })
  }
  return results
}

function processImage(field, find) {
  const results = []
  const link = field?.blocks?.[0]?.configurations?.link
  if (link) {
    results.push({
      find,
      link,
      path: ['blocks', 0, 'configurations', 'link'],
      type: 'field',
      subType: 'image',
      itemId: field._id,
      score: field.score,
      breadcrumb: field.breadcrumb,
      replaced: false,
      highlightIndex: 0,
    })
  }
  return results
}

function processText(field, find) {
  const results = []
  let highlightIndex = 0
  field.blocks.forEach((block, i) => {
    const items = processTipTapData(block, find, ['blocks', i])
    const processedItems = items.map(item => ({
      ...item,
      itemId: field._id,
      score: field.score,
      type: 'field',
      subType: 'text',
      breadcrumb: field.breadcrumb,
      highlightIndex: highlightIndex++,
    }))
    results.push(...processedItems)
  })
  return results.toReversed()
}

function processTable(field, find) {
  const results = []
  const { body, header } = field?.blocks[0]?.table || { body: [], header: [] }
  body.forEach((row, rowIndex) => {
    row.forEach((cell, cellIndex) => {
      const items = processTipTapData(cell, find, [
        'blocks',
        0,
        'table',
        'body',
        rowIndex,
        cellIndex,
      ])
      let highlightIndex = 0
      results.push(
        ...items.map(item => ({
          ...item,
          itemId: field._id,
          score: field.score,
          type: 'field',
          subType: 'table-cell',
          breadcrumb: field.breadcrumb,
          rowIndex,
          cellIndex,
          highlightIndex: highlightIndex++,
        })),
      )
    })
  })

  header.forEach((header, headerIndex) => {
    const items = processTipTapData(header, find, [
      'blocks',
      0,
      'table',
      'header',
      headerIndex,
    ])
    let highlightIndex = 0
    results.push(
      ...items.map(item => ({
        ...item,
        itemId: field._id,
        score: field.score,
        type: 'field',
        subType: 'table-header',
        breadcrumb: field.breadcrumb,
        headerIndex,
        highlightIndex: highlightIndex++,
      })),
    )
  })

  return results
}

function processTipTapData(block, find, path, fieldIndex) {
  const results = []
  const marks = block?.marks || []
  marks.forEach((mark, i) => {
    if (mark?.type === 'link') {
      const link = mark?.attrs || {}
      if (link?.email === find) {
        let textPath = null
        if (block.text.trim() === find.trim()) {
          textPath = [...path, 'text']
        }
        results.push({
          find,
          link,
          textPath,
          text: block?.text,
          path: [...path, 'marks', i, 'attrs'],
          replaced: false,
        })
      }
    }
  })

  if (block?.content?.length > 0) {
    block.content.forEach((content, index) => {
      const items = processTipTapData(
        content,
        find,
        [...path, 'content', index],
        fieldIndex,
      )
      results.push(...items)
    })
  }
  return results
}

export default processFindReplaceEmailApiData
