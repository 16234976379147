// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.view-my-categories__table {
  display: flex;
  flex-wrap: wrap;
  margin: 20px 0 0 0;
  width: 100%;
}
.view-my-categories__table .table__container {
  max-height: calc(100vh - 340px);
}
.view-my-categories__table .icon {
  width: 5%;
}
.view-my-categories__table .category {
  width: 25%;
}
.view-my-categories__table .subcategories {
  width: 30%;
}
.view-my-categories__table .dateModified {
  width: 38%;
}
.view-my-categories__table .edit,
.view-my-categories__table .delete {
  text-align: right;
}
.view-my-categories__table .edit button,
.view-my-categories__table .delete button {
  display: inline-block;
}
.view-my-categories__table .view {
  text-align: right;
  width: 12%;
}
.view-my-categories__table .view .btn {
  display: inline-block;
}`, "",{"version":3,"sources":["webpack://./src/styles/consumer-flow/view-my-workflows/view-my-categories-table.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,eAAA;EACA,kBAAA;EACA,WAAA;AADF;AAGE;EACE,+BAAA;AADJ;AAIE;EACE,SAAA;AAFJ;AAKE;EACE,UAAA;AAHJ;AAME;EACE,UAAA;AAJJ;AAOE;EACE,UAAA;AALJ;AAQE;;EAEE,iBAAA;AANJ;AAQI;;EACE,qBAAA;AALN;AASE;EACE,iBAAA;EACA,UAAA;AAPJ;AASI;EACE,qBAAA;AAPN","sourcesContent":["@import '../../configuration/variables';\n\n.view-my-categories__table {\n  display: flex;\n  flex-wrap: wrap;\n  margin: 20px 0 0 0;\n  width: 100%;\n\n  .table__container {\n    max-height: calc(100vh - 340px);\n  }\n\n  .icon {\n    width: 5%;\n  }\n\n  .category {\n    width: 25%;\n  }\n\n  .subcategories {\n    width: 30%;\n  }\n\n  .dateModified {\n    width: 38%;\n  }\n\n  .edit,\n  .delete {\n    text-align: right;\n\n    button {\n      display: inline-block;\n    }\n  }\n\n  .view {\n    text-align: right;\n    width: 12%;\n\n    .btn {\n      display: inline-block;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
