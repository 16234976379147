import axios from 'axios'
import { get } from 'lodash'

export default function validateStepSlug(clientId, workflowId, slug, token) {
  return new Promise((resolve, reject) => {
    axios({
      url: `${process.env.REACT_APP_PRODUCT_API}/api/v1/workflow/step/validate-slug/${slug}`,
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      data: {
        clientId: clientId,
        workflowId: workflowId,
      },
    })
      .then(slug => {
        const validatedSlug = get(slug, 'data.valid', null)
        resolve(validatedSlug)
      })
      .catch(error => {
        reject(error)
      })
  })
}
