import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { ReactComponent as ArrowDownIcon } from '../../../assets/icons/arrow-down-icon.svg'

function AccordionFieldGroup({
  currentValues,
  label = 'Options',
  defaultOpen = false,
  children,
  totalOptions,
}) {
  const [isGroupToggled, toggleGroup] = useState(defaultOpen)

  return (
    <div className={`accordion-field-group${isGroupToggled ? ' open' : ''}`}>
      <button
        type="button"
        className="btn btn--white"
        onClick={() => toggleGroup(!isGroupToggled)}
      >
        {!isGroupToggled ? `Edit ${label}` : `Hide ${label}`}

        <div className="accordion-field-group__details">
          <span className="accordion-field-group__count">
            {currentValues}/{totalOptions} Selected
          </span>
          <ArrowDownIcon />
        </div>
      </button>

      {isGroupToggled ? (
        <div className="accordion-field-group__content">{children}</div>
      ) : null}
    </div>
  )
}

AccordionFieldGroup.propTypes = {
  currentValues: PropTypes.number,
  label: PropTypes.string,
  defaultOpen: PropTypes.bool,
  children: PropTypes.node,
  totalOptions: PropTypes.number,
}

export default AccordionFieldGroup
