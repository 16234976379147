import { useEffect, useRef, useState } from 'react'
import clsx from 'clsx'
import { ReactComponent as CloseIcon } from '../../../assets/icons/close-icon.svg'
import { ReactComponent as SmileIcon } from '../../../assets/icons/message-smile-square-icon.svg'
import '../../../styles/global-components/resource-changed-toasts/resource-changed-toast.scss'
import PropTypes from 'prop-types'

const TIMEOUT_LENGTH = 30000

const ResourceChangedToast = ({ resource, onDismiss, isPaused, children }) => {
  const [element, setElement] = useState(null)
  const [isDismissed, setIsDismissed] = useState(resource?.dismissed)
  const [animateDismissal, setAnimateDismissal] = useState(false)
  const [timeoutProgress, setTimeoutProgress] = useState(0)

  useEffect(() => {
    if (resource?.dismissed && !isDismissed) {
      setAnimateDismissal(true)
      setIsDismissed(true)
      setTimeout(() => {
        setAnimateDismissal(false)
      }, 350)
    }
  }, [resource])

  useEffect(() => {
    const interval = setInterval(() => {
      if (!isPaused) {
        setTimeoutProgress(progress => progress + 250)
      }
    }, 250)
    return () => {
      clearInterval(interval)
    }
  }, [isPaused])

  useEffect(() => {
    if (timeoutProgress > TIMEOUT_LENGTH) {
      handleDismiss()
    }
  }, [timeoutProgress, element])

  if (isDismissed && !animateDismissal) {
    return null
  }

  function handleDismiss() {
    if (element) {
      element.style.height = `${element.clientHeight}px`
      onDismiss(resource)
    }
  }

  function handleRefresh() {
    window.location.reload()
  }

  return (
    <div
      className={clsx('resource-changed-toast', {
        dismissing: animateDismissal,
      })}
      ref={setElement}
    >
      <div className="resource-changed-toast__header">
        <SmileIcon />
        Good News!
        <button className="modal__close" type="button" onClick={handleDismiss}>
          <CloseIcon />
        </button>
      </div>
      <div className="resource-changed-toast__body">
        <p>{children(resource)}</p>
        <div>
          <button
            className="btn--underline-link btn--underline-link-white"
            onClick={handleRefresh}
          >
            Refresh To Update
          </button>
        </div>
      </div>
      <div
        className="resource-changed-toast__progress"
        style={{
          width: `${Math.min((timeoutProgress * 100) / TIMEOUT_LENGTH, 100)}%`,
        }}
      ></div>
    </div>
  )
}

ResourceChangedToast.propTypes = {
  resource: PropTypes.object,
  onDismiss: PropTypes.func,
  isPaused: PropTypes.bool,
  children: PropTypes.func,
}

export default ResourceChangedToast
