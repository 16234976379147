import React from 'react'
import PropTypes from 'prop-types'

function DislikeIcon({ colour }, props) {
  return (
    <svg
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M13.4426 7.53446L16.359 5.57539C18.7741 3.95292 21.6177 3.08649 24.5272 3.08649H33.137"
        stroke={colour}
        strokeWidth="1.25"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M26.4508 20.2676L27.5792 25.13C28.131 27.5082 26.6187 29.8749 24.2287 30.3733L21.6627 24.9563C20.8911 23.3274 19.6989 21.9338 18.209 20.9194L13.4426 17.6739"
        stroke={colour}
        strokeWidth="1.25"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.10986 3.09473H11.4023C12.5291 3.09473 13.4426 4.00824 13.4426 5.13506V18.3422C13.4426 19.469 12.5291 20.3825 11.4023 20.3825H3.10986V3.09473Z"
        stroke={colour}
        strokeWidth="1.25"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.1822 33.3378L18.6844 34.8355"
        stroke={colour}
        strokeWidth="1.25"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M28.3959 33.3378L29.8937 34.8355"
        stroke={colour}
        strokeWidth="1.25"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24.2889 35.882V38"
        stroke={colour}
        strokeWidth="1.25"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23.6815 20.2308H35.8502C37.0332 20.2308 37.9923 19.2718 37.9923 18.0887C37.9923 16.9058 37.0333 15.9467 35.8502 15.9467"
        stroke={colour}
        strokeWidth="1.25"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M35.8502 15.9468H35.1259C36.3088 15.9468 37.2679 14.9878 37.2679 13.8048C37.2679 12.6218 36.3089 11.6627 35.1259 11.6627"
        stroke={colour}
        strokeWidth="1.25"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M35.1259 11.6628H34.3291C35.5121 11.6628 36.4711 10.7038 36.4711 9.52075C36.4711 8.33772 35.5121 7.37872 34.3291 7.37872"
        stroke={colour}
        strokeWidth="1.25"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M34.3291 7.37873H33.1702C34.3531 7.37873 35.3122 6.41973 35.3122 5.2367C35.3122 4.05367 34.3532 3.09467 33.1702 3.09467"
        stroke={colour}
        strokeWidth="1.25"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

DislikeIcon.defaultProps = {
  colour: '#000000',
}

DislikeIcon.propTypes = {
  colour: PropTypes.string,
}

export default DislikeIcon
