import React, { useCallback } from 'react'

function globalSearchFilter() {
  return useCallback((rows, ids, query) => {
    const searchTerm = String(query).toLowerCase()

    return rows.filter(row => {
      const matches = ids.filter(id => {
        const rowValue = row.values[id]

        return rowValue !== undefined
          ? String(rowValue).toLowerCase().includes(searchTerm)
          : false
      })

      return matches.length > 0
    })
  }, [])
}

export default globalSearchFilter
