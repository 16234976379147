import React, { useEffect, useRef } from 'react'
import { get } from 'lodash'
import getIconComponent from '../../../../utilities/icon/getIconComponent'
import PropTypes from 'prop-types'
import highlightTextInHTML from '../../../../utilities/find-replace/highlightTextInHTML'

const FindReplaceStepPreview = ({ item, highlight }) => {
  const ref = useRef()
  const icon = item?.icon
  const Icon = getIconComponent(icon)
  const hasInnerText = !!ref?.current?.innerText

  useEffect(
    function highlightResultsOnSearch() {
      if (highlight && hasInnerText) {
        highlightTextInHTML({ ref, highlight })
      }
    },
    [highlight, hasInnerText],
  )

  return (
    <div className="find-replace__workflow-preview" ref={ref}>
      <div className="browse-workflow__step-heading">
        {Icon ? (
          <div className="browse-workflow__step-icon">
            <Icon colour={icon?.colour} />
          </div>
        ) : null}

        <h2>{item?.name}</h2>
      </div>
    </div>
  )
}

FindReplaceStepPreview.propTypes = {
  item: PropTypes.object,
  highlight: PropTypes.object,
}
export default FindReplaceStepPreview
