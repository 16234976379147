import React from 'react'
import PropTypes from 'prop-types'

function EnvelopeIcon({ colour }, props) {
  return (
    <svg
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M38 9.8317C38 8.00497 36.5138 6.51877 34.6871 6.51877H5.31293C3.48655 6.51884 2.00063 8.00434 2 9.83226V9.83282V30.1672C2 32.0168 3.50132 33.4813 5.31405 33.4813H34.6859C36.5354 33.4813 37.9999 31.98 37.9999 30.1672V9.83282C37.9999 9.83268 37.9999 9.83254 37.9999 9.8324C38 9.83219 38 9.83198 38 9.8317ZM5.31293 8.62822H34.6871C35.3507 8.62822 35.8906 9.16815 35.8906 9.83345C35.8906 10.187 35.7149 10.5151 35.4203 10.7114L20.6683 20.5465C20.2624 20.8172 19.7377 20.8172 19.3317 20.5465C19.3317 20.5465 4.57942 10.7112 4.57971 10.7114C4.57978 10.7115 4.57949 10.7113 4.57942 10.7112C4.28509 10.5151 4.10938 10.187 4.10938 9.8317C4.10938 9.16808 4.64932 8.62822 5.31293 8.62822ZM34.6859 31.3719H5.31405C4.6574 31.3719 4.10938 30.8409 4.10938 30.1672V12.9331L18.1616 22.3015C18.72 22.6738 19.36 22.86 20 22.86C20.6401 22.86 21.2801 22.6738 21.8385 22.3015L35.8906 12.9331V30.1673C35.8906 30.8239 35.3597 31.3719 34.6859 31.3719Z"
        fill={colour}
      />
    </svg>
  )
}

EnvelopeIcon.defaultProps = {
  colour: '#000000',
}

EnvelopeIcon.propTypes = {
  colour: PropTypes.string,
}

export default EnvelopeIcon
