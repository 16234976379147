import PropTypes from 'prop-types'
import EditorJSField from '../EditorJSField'
import { EDITOR_CONTENT_FIELD } from '../../../../configuration/editor'
import TipTapEditor from '../TipTap/TipTapEditor'

function EditorTextField({ field, onChange }) {
  function handleChange(data) {
    onChange({ blocks: data || [] })
  }

  return (
    <div>
      <TipTapEditor data={field?.blocks} onChange={handleChange} />
    </div>
  )
}

export const createContentField = id => {
  return {
    id: id,
    type: 'field',
    fieldType: EDITOR_CONTENT_FIELD,
    blocks: [],
  }
}

EditorTextField.propTypes = {
  field: PropTypes.object,
  onChange: PropTypes.func,
}

export default EditorTextField
