import { cloneDeep, get, set } from 'lodash'
import axios from 'axios'

async function updateReplaceEmail({
  entry,
  items,
  replaceValue,
  activeClientId,
  userId,
  userEmail,
  entries,
  token,
  operatingLocation,
}) {
  if (entry) {
    const item = cloneDeep(items[entry.itemId])
    set(item, [...entry.path, 'email'], replaceValue)
    if (entry.textPath) {
      const originalText = get(item, entry.textPath)
      set(item, entry.textPath, originalText.replace(entry.find, replaceValue))
    }

    return axios({
      url: `${process.env.REACT_APP_PRODUCT_API}/api/v1/find-replace/update/external-link/${activeClientId}`,
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      data: {
        authorId: userId,
        authorEmail: userEmail,
        searchTerm: entry.find,
        replacementTerm: replaceValue,
        totalResults: entries.length,
        type: entry.type,
        item,
        operatingLocation,
      },
    }).then(res => {
      const newItems = cloneDeep(items)
      const newItem = res?.data?.item
      newItems[entry.itemId] = {
        ...newItem,
        breadcrumb: item.breadcrumb,
      }
      return {
        items: newItems,
        entries: entries.map(currentEntry => {
          if (currentEntry.id === entry.id) {
            return {
              ...currentEntry,
              replaced: true,
            }
          }
          return currentEntry
        }),
      }
    })
  }
}

export default updateReplaceEmail
