import React from 'react'
import dayjs from 'dayjs'
import dateFormats from '../../../../configuration/supportedDateFormats'
import '../../../../styles/global-components/footer/footer.scss'

function Footer() {
  const currentYear = dayjs().format(dateFormats.year)

  return (
    <footer className="footer">
      <div className="footer__container container">
        <p>Copyright {currentYear}&copy; QuickFacts Inc. All rights reserved</p>
      </div>
    </footer>
  )
}

export default Footer
