import React from 'react'
import PropTypes from 'prop-types'
import { get, isEmpty } from 'lodash'
import renderConsumerField from '../../../utilities/fields/renderConsumerFields'
import '../../../styles/consumer-flow/base-components/cards.scss'
import LinkWrapper from '../base-fields/LinkWrapper'
import useEntryHighlight from '../../../hooks/useEntryHighlight'
import clsx from 'clsx'

export default function Cards({ component }) {
  const cardsLayout = get(component, 'configurations.layout', '100')
  const cards = get(component, 'subComponents', [])
  const highlight = useEntryHighlight()

  return (
    <div className={`cards cards--${cardsLayout}`}>
      {!isEmpty(cards)
        ? cards.map((card, index) => {
            const cardId = get(card, '_id', null) || card?.id
            const cardFields = get(card, 'fields', [])
            const link = card.configurations?.link
            let highlightClass = ''
            if (
              highlight?.itemId === component?._id &&
              highlight?.highlightIndex === index
            ) {
              highlightClass = 'find-replace__outline'
            }
            return !isEmpty(cardFields) ? (
              <div key={cardId} className={clsx('cards__card', highlightClass)}>
                <LinkWrapper link={link}>
                  {cardFields.map(field => {
                    const fieldId = get(field, '_id', null)
                    const fieldType = get(field, 'fieldType', null)
                    const fieldHighlight =
                      highlight?.itemId === fieldId ? highlight : null
                    const FieldComponent = renderConsumerField(fieldType)
                    return FieldComponent ? (
                      <FieldComponent
                        key={fieldId}
                        field={field}
                        highlight={fieldHighlight}
                      />
                    ) : null
                  })}
                </LinkWrapper>
              </div>
            ) : null
          })
        : null}
    </div>
  )
}

Cards.propTypes = {
  component: PropTypes.object,
}
