import clsx from 'clsx'
import React, { useRef } from 'react'
import { useDraggable } from '@dnd-kit/core'
import PropTypes from 'prop-types'

const MediaLibraryImageItem = ({
  item,
  isSelected,
  onClick,
  onDoubleClick,
}) => {
  const id = `media-library-item-${item._id}`
  const { attributes, listeners, setNodeRef, isDragging } = useDraggable({
    data: {
      media: item,
      type: 'media',
    },
    id,
  })

  function handleMouseDown(evt) {
    onClick(evt, item)
  }

  function handleDoubleClick() {
    onDoubleClick(item)
  }

  return (
    <div
      key={item._id}
      className={clsx('media-library__content-grid__item', {
        selected: isSelected,
      })}
      id={id}
      ref={setNodeRef}
      {...listeners}
      {...attributes}
    >
      <button
        onMouseDown={handleMouseDown}
        onClick={handleMouseDown}
        onDoubleClick={handleDoubleClick}
      >
        <img src={item?.thumbnail?.url} draggable={false} />
        <div
          className="media-library__content-grid__item-name"
          data-tooltip={item.name}
        >
          {item.name}
        </div>
        <div className="media-library__content-grid__item-checkmark"></div>
      </button>
    </div>
  )
}

MediaLibraryImageItem.propTypes = {
  item: PropTypes.shape({
    _id: PropTypes.string,
    name: PropTypes.string,
    thumbnail: PropTypes.shape({
      _id: PropTypes.string,
      name: PropTypes.string,
      url: PropTypes.string,
    }),
  }),
  isSelected: PropTypes.bool,
  onClick: PropTypes.func,
  onDoubleClick: PropTypes.func,
}

export default MediaLibraryImageItem
