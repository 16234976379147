import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'
import { useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectManageStepModalVisibility,
  toggleManageStepModal,
} from '../../../../redux/user/userSelections'
import routes from '../../../../configuration/routes'
import { ReactComponent as PreviousIcon } from '../../../../assets/icons/double-chevron-left.svg'
import { ReactComponent as NextIcon } from '../../../../assets/icons/double-chevron-right.svg'

function ManageWorkflowStepNavigation({
  editWorkflowEvent,
  managedWorkflow,
  workflowSteps,
  onNavigate,
  unsavedChanges,
}) {
  const dispatch = useDispatch()
  const isManageStepModalVisible = useSelector(selectManageStepModalVisibility)
  const { workflow, step } = useParams()
  const navigate = useNavigate()
  const [navigatePath, setNavigatePath] = useState('')
  const activeStepIndex = workflowSteps.findIndex(
    activeStep => activeStep?.slug === step,
  )
  const basePath = `${routes.manageWorkflows}/${workflow}`
  const previousStepContent = workflowSteps[activeStepIndex - 1]
  const previousStepSlug = get(previousStepContent, 'slug', null)
  const nextStepContent = workflowSteps[activeStepIndex + 1]
  const nextStepSlug = get(nextStepContent, 'slug', null)
  const [currentState, setCurrentState] = useState({
    numberOfSteps: workflowSteps.length,
    nextStepSlug,
  })

  /**
   * Detects if a new step has been created if we're at the end of the step list
   */
  useEffect(() => {
    if (
      currentState.numberOfSteps !== workflowSteps.length &&
      currentState.nextStepSlug === null
    ) {
      handleNavigation(`${basePath}/${nextStepSlug}`)
    }
    setCurrentState({
      numberOfSteps: workflowSteps.length,
      nextStepSlug,
    })
  }, [workflowSteps.length, nextStepSlug])

  function handleNavigation(path) {
    if (unsavedChanges) {
      return setNavigatePath(path)
    }
    navigateTo(path)
  }

  function navigateTo(path) {
    if (onNavigate) {
      onNavigate(path)
    }
    navigate(path)
    setNavigatePath('')
  }

  return (
    <div className="manage-workflow__step-bottom__wrapper">
      {activeStepIndex >= 0 && workflowSteps.length > 0 ? (
        <div className="manage-workflow__step-bottom-navigation">
          <div className="flex">
            {previousStepSlug ? (
              <button
                disabled={!previousStepSlug}
                onClick={() =>
                  handleNavigation(`${basePath}/${previousStepSlug}`)
                }
                className="btn btn--white previous svg-left"
              >
                <PreviousIcon />
                Go To Prev Step
              </button>
            ) : null}
          </div>

          <div className="flex">
            {!nextStepSlug ? (
              <button
                onClick={() =>
                  dispatch(toggleManageStepModal(!isManageStepModalVisible))
                }
                className="btn btn--white next svg-right"
              >
                Create Step
                <NextIcon />
              </button>
            ) : (
              <button
                disabled={!nextStepSlug}
                onClick={() => handleNavigation(`${basePath}/${nextStepSlug}`)}
                className="btn btn--white next svg-right"
              >
                Go To Next Step
                <NextIcon />
              </button>
            )}
          </div>
        </div>
      ) : null}
    </div>
  )
}

ManageWorkflowStepNavigation.propTypes = {
  editWorkflowEvent: PropTypes.func,
  managedWorkflow: PropTypes.object,
  workflowSteps: PropTypes.array,
  onNavigate: PropTypes.func,
  unsavedChanges: PropTypes.bool,
}

export default ManageWorkflowStepNavigation
