import React, { useState } from 'react'
import * as Yup from 'yup'
import PropTypes from 'prop-types'
import { Field, Form, Formik } from 'formik'
import CustomSelect from '../../../forms/elements/CustomSelect'
import { useSelector } from 'react-redux'
import { get } from 'lodash'
import {
  selectActiveClientId,
  selectActiveOperatingLocation,
} from '../../../../redux/user/userSelections'
import { selectWorkflowsState } from '../../../../redux/workflows/workflows'
import { MediaLibraryDocumentIcon } from '../../../global-components/media-library/MediaLibraryDocumentItem'
import { selectCategories } from '../../../../redux/categories/categories'
import TextField from '../../../forms/elements/TextField'
import MediaLibrary from '../../../global-components/media-library/MediaLibrary'
import '../../../../styles/editor-flow/editor/editor-link-form.scss'
import TextAreaField from '../../../forms/elements/TextAreaField'

const validationSchema = Yup.object().shape({
  type: Yup.string().required('Required'),
  workflow: Yup.string()
    .optional()
    .when(['type', 'internalType'], {
      is: (type, internalType) =>
        type === 'internal' && internalType === 'workflow',
      then: schema => schema.required('Required'),
      otherwise: schema => schema.notRequired(),
    }),
  category: Yup.string()
    .optional()
    .when('internalType', {
      is: val => val === 'category',
      then: schema => schema.required('Required'),
      otherwise: schema => schema.notRequired(),
    }),
  url: Yup.string().when('type', {
    is: val => val === 'external',
    then: schema => schema.required('Required'),
    otherwise: schema => schema.notRequired(),
  }),
})

const typeOptions = [
  {
    label: 'Internal',
    value: 'internal',
  },
  {
    label: 'External',
    value: 'external',
  },
  {
    label: 'Document',
    value: 'document',
  },
  {
    label: 'Email',
    value: 'email',
  },
]

const targetOptions = [
  {
    label: 'Open the link in the same tab',
    value: '_self',
  },
  {
    label: 'Open the link in a new tab',
    value: '_blank',
  },
]

const internalLinkOptions = [
  {
    label: 'Workflow',
    value: 'workflow',
  },
  {
    label: 'Category',
    value: 'category',
  },
]

const EditorLinkForm = ({ values, onSubmit, onCancel, onDelete }) => {
  const [selectDocumentIsOpen, setSelectDocumentIsOpen] = useState(false)
  const activeClientId = useSelector(selectActiveClientId)
  const activeOperatingLocation = useSelector(selectActiveOperatingLocation)
  const workflowsState = useSelector(selectWorkflowsState)
  const categories = useSelector(selectCategories)?.[activeClientId] || []
  const workflowsByClient = get(
    workflowsState?.workflows,
    `${activeOperatingLocation}-${activeClientId}`,
    [],
  ).sort(sortByName)
  const workflowOptions = workflowsByClient.map(workflow => ({
    value: workflow._id,
    label: workflow.name,
  }))

  const categoryOptions = categories.map(category => ({
    value: category._id,
    label: category.category,
  }))

  const initialValues = {
    type: 'internal',
    target: '_self',
    workflow: '',
    url: '',
    document: null,
    body: '',
    subject: '',
    ...values,
    internalType: values?.internalType || 'workflow',
  }

  const handleSubmit = values => {
    !!onSubmit && onSubmit(values)
  }

  function getStepOptions(workflowId) {
    const workflow = workflowsByClient.find(item => item._id === workflowId)
    return [
      {
        label: 'Step Overview',
        value: null,
      },
      ...(workflow?.steps?.map(step => ({
        label: step.name,
        value: step._id,
      })) || []),
    ]
  }

  function getCategoryOptions(workflowId) {
    const workflow = workflowsByClient.find(item => item._id === workflowId)
    return [
      ...(workflow?.categories?.map(category => ({
        label: category.category,
        value: category._id,
      })) || []),
    ]
  }

  const customSelectFilter = (option, searchText) => {
    if (
      option.data.label?.toLowerCase()?.includes(searchText.toLowerCase()) ||
      option.data.value?.toLowerCase()?.includes(searchText.toLowerCase())
    ) {
      return true
    } else {
      return false
    }
  }

  return (
    <div className="editor-link-form">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ isValid, setFieldTouched, values, setFieldValue, errors }) => {
          const submitButtonDisabled = !isValid

          function handleSelectDocument(document) {
            const documentValue = {
              _id: document._id,
              name: document.name,
              fileType: document.fileType,
              url: document.url,
            }
            setFieldValue('document', documentValue)
            setSelectDocumentIsOpen(false)
          }

          async function onWorkflowChange(value) {
            const workflow = workflowsByClient.find(item => item._id === value)
            await setFieldValue('category', workflow.categories?.[0]?._id)
            await setFieldValue('step', null)
          }

          async function onInternalTypeChange(value) {
            await setFieldValue('category', null)
            await setFieldValue('step', null)
            await setFieldValue('workflow', null)
          }

          async function onTypeChange(value) {
            if (value === 'internal') {
              await setFieldValue('url', null)
              await setFieldValue('target', '_self')
              await setFieldTouched('workflow', false)
              await setFieldValue('document', null)
              await setFieldValue('internalType', 'workflow')
            } else if (value === 'document') {
              await setFieldValue('url', null)
              await setFieldValue('target', '_blank')
              await setFieldValue('internalType', null)
            } else if (value === 'email') {
              await setFieldValue('url', null)
              await setFieldValue('target', '_self')
              await setFieldValue('internalType', null)
              await setFieldTouched('url', false)
            } else {
              await setFieldValue('workflow', null)
              await setFieldValue('category', null)
              await setFieldValue('step', null)
              await setFieldValue('target', '_blank')
              await setFieldTouched('url', false)
              await setFieldValue('document', null)
              await setFieldValue('internalType', null)
            }
          }

          return (
            <>
              <Form className="form form--modal">
                <div className="form__field half">
                  <Field
                    component={CustomSelect}
                    label="Link Type"
                    name="type"
                    menuPlacement="bottom"
                    options={typeOptions}
                    required={true}
                    onChange={onTypeChange}
                    setFieldTouched={setFieldTouched}
                    isClearable={false}
                  />
                </div>
                <div className="form__field half">
                  {values.type !== 'email' ? (
                    <Field
                      component={CustomSelect}
                      label="Target"
                      name="target"
                      menuPlacement="bottom"
                      options={targetOptions}
                      required={true}
                      setFieldTouched={setFieldTouched}
                      isClearable={false}
                    />
                  ) : (
                    <Field
                      component={TextField}
                      label="Email"
                      name="email"
                      placeholder="email@example.com"
                      required={values.type === 'email'}
                      type="text"
                    />
                  )}
                </div>
                {values.type === 'internal' && (
                  <>
                    <div className="form__field full">
                      <Field
                        component={CustomSelect}
                        filterOption={customSelectFilter}
                        label="Internal Link Type"
                        name="internalType"
                        options={internalLinkOptions}
                        required={values.type === 'internal'}
                        placeholder="Internal Link Type"
                        setFieldTouched={setFieldTouched}
                        onChange={onInternalTypeChange}
                        isClearable={false}
                      />
                    </div>
                    {values.internalType === 'workflow' && (
                      <>
                        <div className="form__field full">
                          <Field
                            component={CustomSelect}
                            filterOption={customSelectFilter}
                            label="Workflow"
                            name="workflow"
                            options={workflowOptions}
                            required={values.type === 'internal'}
                            placeholder="Workflow"
                            setFieldTouched={setFieldTouched}
                            onChange={onWorkflowChange}
                            isClearable={false}
                          />
                        </div>
                        {values.workflow && (
                          <>
                            <div className="form__field half">
                              <Field
                                component={CustomSelect}
                                filterOption={customSelectFilter}
                                label="Category"
                                name="category"
                                options={getCategoryOptions(values.workflow)}
                                required={values.type === 'internal'}
                                placeholder="Category"
                                setFieldTouched={setFieldTouched}
                                isClearable={false}
                              />
                            </div>
                            <div className="form__field half">
                              <Field
                                component={CustomSelect}
                                filterOption={customSelectFilter}
                                label="Step"
                                name="step"
                                disabled={!values.workflow}
                                options={getStepOptions(values.workflow)}
                                placeholder="Step"
                                setFieldTouched={setFieldTouched}
                                isClearable={false}
                              />
                            </div>
                          </>
                        )}
                      </>
                    )}
                    {values.internalType === 'category' && (
                      <>
                        <div className="form__field">
                          <Field
                            component={CustomSelect}
                            filterOption={customSelectFilter}
                            label="Category"
                            name="category"
                            options={categoryOptions}
                            required={values.type === 'internal'}
                            placeholder="Category"
                            setFieldTouched={setFieldTouched}
                            isClearable={false}
                          />
                        </div>
                      </>
                    )}
                  </>
                )}

                {values.type === 'external' && (
                  <>
                    <div className="form__field full">
                      <Field
                        component={TextField}
                        label="URL"
                        name="url"
                        placeholder="Enter Link URL"
                        required={values.type === 'external'}
                        type="text"
                      />
                    </div>
                  </>
                )}

                {values.type === 'document' && (
                  <>
                    <div className="form__field full editor-link-form__document-field">
                      <label>Document</label>

                      <div className="flex">
                        <div>
                          {values.document ? (
                            <MediaLibraryDocumentIcon
                              type={values.document.fileType}
                              containerClass="editor-link-form__document-icon"
                            />
                          ) : null}
                        </div>
                        <div className="flex flex-fill col">
                          <div className="form__sub-label">
                            {values.document
                              ? values.document.name
                              : 'No Document Chosen'}
                          </div>
                          <div className="">
                            <button
                              className="btn btn--secondary"
                              onClick={() => setSelectDocumentIsOpen(true)}
                              type="button"
                            >
                              Choose Document
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}

                {values.type === 'email' ? (
                  <>
                    <div className="form__field full">
                      <Field
                        component={TextField}
                        label="Subject"
                        name="subject"
                        placeholder="Enter Subject Line"
                        type="text"
                      />
                    </div>
                    <div className="form__field full">
                      <Field
                        component={TextAreaField}
                        label="Body"
                        name="body"
                        placeholder="Enter Email Body"
                        type="text"
                      />
                    </div>
                  </>
                ) : null}

                <div className="modal__submit">
                  {initialValues.type && (
                    <button
                      type="button"
                      className="btn btn--red"
                      onClick={onDelete}
                    >
                      Remove Link
                    </button>
                  )}
                  <div className="flex-fill"></div>

                  <button
                    type="button"
                    className="btn btn--white"
                    onClick={onCancel}
                  >
                    Cancel
                  </button>

                  <button
                    type="submit"
                    className="btn btn--dark-purple"
                    disabled={submitButtonDisabled}
                  >
                    Save
                  </button>
                </div>
              </Form>
              <MediaLibrary
                isOpen={selectDocumentIsOpen}
                onCancel={() => setSelectDocumentIsOpen(false)}
                onSubmit={handleSelectDocument}
                mode="select"
                filter="document"
              />
            </>
          )
        }}
      </Formik>
    </div>
  )
}

function sortByName(a, b) {
  if (a.name < b.name) {
    return -1
  }
  if (a.name > b.name) {
    return 1
  }
  return 0
}

EditorLinkForm.propTypes = {
  values: PropTypes.shape({
    type: PropTypes.string,
    workflow: PropTypes.string,
    internalType: PropTypes.string,
    document: PropTypes.shape({
      _id: PropTypes.string,
      fileType: PropTypes.string,
      name: PropTypes.string,
      url: PropTypes.string,
    }),
  }),
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  onDelete: PropTypes.func,
}

export default EditorLinkForm
