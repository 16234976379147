import React from 'react'
import PropTypes from 'prop-types'

function CreditCardIcon({ colour }, props) {
  return (
    <svg
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5.83262 27.064L7.9927 29.2241V34.9999C7.99365 35.7953 8.31004 36.5578 8.87246 37.1202C9.43488 37.6827 10.1974 37.999 10.9928 38H34.999C35.7944 37.999 36.5569 37.6827 37.1194 37.1202C37.6818 36.5578 37.9982 35.7953 37.9991 34.9999V19.9994C37.9968 19.2421 37.7077 18.5138 37.1901 17.961C36.6724 17.4083 35.9646 17.0722 35.209 17.0203C35.5116 16.4544 35.6251 15.8066 35.533 15.1715C35.4409 14.5365 35.148 13.9476 34.6972 13.491L27.0566 5.84549L27.053 5.83889H27.0476L24.0889 2.87659C23.5264 2.31526 22.7642 2 21.9696 2C21.1749 2 20.4128 2.31526 19.8503 2.87659L2.87452 19.859C2.31489 20.4244 2.00098 21.1878 2.00098 21.9833C2.00098 22.7789 2.31489 23.5423 2.87452 24.1077L5.83262 27.064ZM24.0847 4.56925L25.7797 6.26551L15.0519 16.9993H11.6582L24.0847 4.56925ZM7.9981 24.0531L6.25804 25.7932L4.56238 24.0975L7.9981 20.6594V24.0531ZM7.10647 26.6416L7.9981 25.75V27.5332L7.10647 26.6416ZM36.7991 19.9994V34.9999C36.7991 35.4773 36.6094 35.9352 36.2719 36.2727C35.9343 36.6103 35.4764 36.8 34.999 36.8H10.9982C10.5208 36.8 10.0629 36.6103 9.72536 36.2727C9.38779 35.9352 9.19814 35.4773 9.19814 34.9999V19.9994C9.19814 19.522 9.38779 19.0641 9.72536 18.7265C10.0629 18.389 10.5208 18.1993 10.9982 18.1993H34.999C35.4764 18.1993 35.9343 18.389 36.2719 18.7265C36.6094 19.0641 36.7991 19.522 36.7991 19.9994ZM33.8488 16.8871L33.739 16.9993H16.7482L26.6281 7.11454L33.847 14.3376C34.1836 14.6763 34.3727 15.1344 34.3731 15.612C34.3734 16.0895 34.1849 16.5479 33.8488 16.8871ZM3.72415 20.7056L20.6987 3.72502C21.0406 3.39832 21.4951 3.2157 21.968 3.21503C22.4408 3.21436 22.8958 3.39569 23.2386 3.72142L9.60375 17.3593C9.07333 17.6403 8.63942 18.074 8.35811 18.6043L3.71515 23.2479C3.38356 22.9073 3.19877 22.4503 3.20045 21.9749C3.20213 21.4996 3.39016 21.0438 3.72415 20.7056Z"
        fill={colour}
      />
      <path
        d="M11.5982 24.7994H15.1984C15.5166 24.7994 15.8219 24.673 16.0469 24.4479C16.272 24.2229 16.3984 23.9177 16.3984 23.5994V21.1993C16.3984 20.881 16.272 20.5758 16.0469 20.3508C15.8219 20.1257 15.5166 19.9993 15.1984 19.9993H11.5982C11.28 19.9993 10.9747 20.1257 10.7497 20.3508C10.5246 20.5758 10.3982 20.881 10.3982 21.1993V23.5994C10.3982 23.9177 10.5246 24.2229 10.7497 24.4479C10.9747 24.673 11.28 24.7994 11.5982 24.7994ZM11.5982 21.1993H15.1984V23.5994H11.5982V21.1993Z"
        fill={colour}
      />
      <path
        d="M10.9982 28.3996C11.1573 28.3996 11.31 28.3364 11.4225 28.2239C11.535 28.1113 11.5982 27.9587 11.5982 27.7996V26.5995C11.5982 26.4404 11.535 26.2878 11.4225 26.1753C11.31 26.0627 11.1573 25.9995 10.9982 25.9995C10.8391 25.9995 10.6865 26.0627 10.5739 26.1753C10.4614 26.2878 10.3982 26.4404 10.3982 26.5995V27.7996C10.3982 27.9587 10.4614 28.1113 10.5739 28.2239C10.6865 28.3364 10.8391 28.3996 10.9982 28.3996Z"
        fill={colour}
      />
      <path
        d="M13.3984 25.9995C13.2392 25.9995 13.0866 26.0627 12.9741 26.1753C12.8616 26.2878 12.7983 26.4404 12.7983 26.5995V27.7996C12.7983 27.9587 12.8616 28.1113 12.9741 28.2239C13.0866 28.3364 13.2392 28.3996 13.3984 28.3996C13.5575 28.3996 13.7101 28.3364 13.8226 28.2239C13.9352 28.1113 13.9984 27.9587 13.9984 27.7996V26.5995C13.9984 26.4404 13.9352 26.2878 13.8226 26.1753C13.7101 26.0627 13.5575 25.9995 13.3984 25.9995Z"
        fill={colour}
      />
      <path
        d="M15.1982 26.5995V27.7996C15.1982 27.9587 15.2615 28.1113 15.374 28.2239C15.4865 28.3364 15.6391 28.3996 15.7983 28.3996C15.9574 28.3996 16.11 28.3364 16.2225 28.2239C16.3351 28.1113 16.3983 27.9587 16.3983 27.7996V26.5995C16.3983 26.4404 16.3351 26.2878 16.2225 26.1753C16.11 26.0627 15.9574 25.9995 15.7983 25.9995C15.6391 25.9995 15.4865 26.0627 15.374 26.1753C15.2615 26.2878 15.1982 26.4404 15.1982 26.5995Z"
        fill={colour}
      />
      <path
        d="M18.1984 25.9995C18.0393 25.9995 17.8867 26.0627 17.7741 26.1753C17.6616 26.2878 17.5984 26.4404 17.5984 26.5995V27.7996C17.5984 27.9587 17.6616 28.1113 17.7741 28.2239C17.8867 28.3364 18.0393 28.3996 18.1984 28.3996C18.3575 28.3996 18.5102 28.3364 18.6227 28.2239C18.7352 28.1113 18.7984 27.9587 18.7984 27.7996V26.5995C18.7984 26.4404 18.7352 26.2878 18.6227 26.1753C18.5102 26.0627 18.3575 25.9995 18.1984 25.9995Z"
        fill={colour}
      />
      <path
        d="M20.5986 28.3996C20.7577 28.3996 20.9103 28.3364 21.0228 28.2239C21.1354 28.1113 21.1986 27.9587 21.1986 27.7996V26.5995C21.1986 26.4404 21.1354 26.2878 21.0228 26.1753C20.9103 26.0627 20.7577 25.9995 20.5986 25.9995C20.4394 25.9995 20.2868 26.0627 20.1743 26.1753C20.0618 26.2878 19.9985 26.4404 19.9985 26.5995V27.7996C19.9985 27.9587 20.0618 28.1113 20.1743 28.2239C20.2868 28.3364 20.4394 28.3996 20.5986 28.3996Z"
        fill={colour}
      />
      <path
        d="M25.3986 25.9995C25.2395 25.9995 25.0869 26.0627 24.9743 26.1753C24.8618 26.2878 24.7986 26.4404 24.7986 26.5995V27.7996C24.7986 27.9587 24.8618 28.1113 24.9743 28.2239C25.0869 28.3364 25.2395 28.3996 25.3986 28.3996C25.5577 28.3996 25.7104 28.3364 25.8229 28.2239C25.9354 28.1113 25.9986 27.9587 25.9986 27.7996V26.5995C25.9986 26.4404 25.9354 26.2878 25.8229 26.1753C25.7104 26.0627 25.5577 25.9995 25.3986 25.9995Z"
        fill={colour}
      />
      <path
        d="M27.7988 25.9995C27.6396 25.9995 27.487 26.0627 27.3745 26.1753C27.2619 26.2878 27.1987 26.4404 27.1987 26.5995V27.7996C27.1987 27.9587 27.2619 28.1113 27.3745 28.2239C27.487 28.3364 27.6396 28.3996 27.7988 28.3996C27.9579 28.3996 28.1105 28.3364 28.223 28.2239C28.3356 28.1113 28.3988 27.9587 28.3988 27.7996V26.5995C28.3988 26.4404 28.3356 26.2878 28.223 26.1753C28.1105 26.0627 27.9579 25.9995 27.7988 25.9995Z"
        fill={colour}
      />
      <path
        d="M30.1989 25.9995C30.0398 25.9995 29.8871 26.0627 29.7746 26.1753C29.6621 26.2878 29.5989 26.4404 29.5989 26.5995V27.7996C29.5989 27.9587 29.6621 28.1113 29.7746 28.2239C29.8871 28.3364 30.0398 28.3996 30.1989 28.3996C30.358 28.3996 30.5107 28.3364 30.6232 28.2239C30.7357 28.1113 30.7989 27.9587 30.7989 27.7996V26.5995C30.7989 26.4404 30.7357 26.2878 30.6232 26.1753C30.5107 26.0627 30.358 25.9995 30.1989 25.9995Z"
        fill={colour}
      />
      <path
        d="M32.599 25.9995C32.4399 25.9995 32.2873 26.0627 32.1748 26.1753C32.0622 26.2878 31.999 26.4404 31.999 26.5995V27.7996C31.999 27.9587 32.0622 28.1113 32.1748 28.2239C32.2873 28.3364 32.4399 28.3996 32.599 28.3996C32.7582 28.3996 32.9108 28.3364 33.0233 28.2239C33.1358 28.1113 33.1991 27.9587 33.1991 27.7996V26.5995C33.1991 26.4404 33.1358 26.2878 33.0233 26.1753C32.9108 26.0627 32.7582 25.9995 32.599 25.9995Z"
        fill={colour}
      />
      <path
        d="M13.3983 29.5996H10.9982C10.8391 29.5996 10.6865 29.6628 10.5739 29.7754C10.4614 29.8879 10.3982 30.0405 10.3982 30.1996C10.3982 30.3588 10.4614 30.5114 10.5739 30.6239C10.6865 30.7364 10.8391 30.7997 10.9982 30.7997H13.3983C13.5574 30.7997 13.7101 30.7364 13.8226 30.6239C13.9351 30.5114 13.9983 30.3588 13.9983 30.1996C13.9983 30.0405 13.9351 29.8879 13.8226 29.7754C13.7101 29.6628 13.5574 29.5996 13.3983 29.5996Z"
        fill={colour}
      />
      <path
        d="M18.1983 29.5996H15.7983C15.6391 29.5996 15.4865 29.6628 15.374 29.7754C15.2615 29.8879 15.1982 30.0405 15.1982 30.1996C15.1982 30.3588 15.2615 30.5114 15.374 30.6239C15.4865 30.7364 15.6391 30.7997 15.7983 30.7997H18.1983C18.3575 30.7997 18.5101 30.7364 18.6226 30.6239C18.7352 30.5114 18.7984 30.3588 18.7984 30.1996C18.7984 30.0405 18.7352 29.8879 18.6226 29.7754C18.5101 29.6628 18.3575 29.5996 18.1983 29.5996Z"
        fill={colour}
      />
      <path
        d="M13.3983 31.9998H10.9982C10.8391 31.9998 10.6865 32.063 10.5739 32.1755C10.4614 32.288 10.3982 32.4406 10.3982 32.5998C10.3982 32.7589 10.4614 32.9115 10.5739 33.0241C10.6865 33.1366 10.8391 33.1998 10.9982 33.1998H13.3983C13.5574 33.1998 13.7101 33.1366 13.8226 33.0241C13.9351 32.9115 13.9983 32.7589 13.9983 32.5998C13.9983 32.4406 13.9351 32.288 13.8226 32.1755C13.7101 32.063 13.5574 31.9998 13.3983 31.9998Z"
        fill={colour}
      />
      <path
        d="M18.1983 31.9998H15.7983C15.6391 31.9998 15.4865 32.063 15.374 32.1755C15.2615 32.288 15.1982 32.4406 15.1982 32.5998C15.1982 32.7589 15.2615 32.9115 15.374 33.0241C15.4865 33.1366 15.6391 33.1998 15.7983 33.1998H18.1983C18.3575 33.1998 18.5101 33.1366 18.6226 33.0241C18.7352 32.9115 18.7984 32.7589 18.7984 32.5998C18.7984 32.4406 18.7352 32.288 18.6226 32.1755C18.5101 32.063 18.3575 31.9998 18.1983 31.9998Z"
        fill={colour}
      />
      <path
        d="M22.9986 29.5996H20.5986C20.4394 29.5996 20.2868 29.6628 20.1743 29.7754C20.0618 29.8879 19.9985 30.0405 19.9985 30.1996C19.9985 30.3588 20.0618 30.5114 20.1743 30.6239C20.2868 30.7364 20.4394 30.7997 20.5986 30.7997H22.9986C23.1578 30.7997 23.3104 30.7364 23.4229 30.6239C23.5354 30.5114 23.5987 30.3588 23.5987 30.1996C23.5987 30.0405 23.5354 29.8879 23.4229 29.7754C23.3104 29.6628 23.1578 29.5996 22.9986 29.5996Z"
        fill={colour}
      />
    </svg>
  )
}

CreditCardIcon.defaultProps = {
  colour: '#000000',
}

CreditCardIcon.propTypes = {
  colour: PropTypes.string,
}

export default CreditCardIcon
