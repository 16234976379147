import Modal from '../../../global-components/elements/Modal'
import React from 'react'
import { ReactComponent as CloseModalIcon } from '../../../../assets/icons/close-icon.svg'
import MaterialLoader from '../../../global-components/elements/MaterialLoader'
import PropTypes from 'prop-types'

const ManageWorkflowStepsPublishModal = ({
  isOpen,
  onRequestClose,
  onSubmit,
  steps,
  type = 'publish',
}) => {
  const title = type === 'publish' ? 'Publish Steps' : 'Unpublish Steps'
  return (
    <Modal
      modalClassName="modal--delete-workflow modal--sm"
      isOpen={isOpen}
      onRequestClose={onRequestClose}
    >
      <div className="modal__header">
        <h3>{title}</h3>

        <button className="modal__close" onClick={onRequestClose}>
          <CloseModalIcon />
        </button>
      </div>
      <div className="modal__body">
        <p>
          Are you sure you want to{' '}
          {type === 'publish' ? 'publish' : 'unpublish'} the following steps?
        </p>
        <ul>
          {steps.map(step => (
            <li key={step._id}>{step.name}</li>
          ))}
        </ul>
        <br />
      </div>
      <div className="modal__submit">
        <button className="btn btn--white" onClick={onRequestClose}>
          Cancel
        </button>

        <button
          className="btn btn--bright-purple"
          onClick={() => onSubmit(steps)}
        >
          {title}
        </button>
      </div>
    </Modal>
  )
}

ManageWorkflowStepsPublishModal.propTypes = {
  isOpen: PropTypes.bool,
  onRequestClose: PropTypes.func,
  onSubmit: PropTypes.func,
  steps: PropTypes.arrayOf(PropTypes.object),
  type: PropTypes.string,
}

export default ManageWorkflowStepsPublishModal
