import React from 'react'
import { useSelector } from 'react-redux'
import { get, isEmpty, orderBy } from 'lodash'
import { selectWorkflowsState } from '../../../redux/workflows/workflows'
import {
  selectActiveClientId,
  selectActiveOperatingLocation,
} from '../../../redux/user/userSelections'
import { Link } from 'react-router-dom'
import dayjs from 'dayjs'
import dateFormats from '../../../configuration/supportedDateFormats'
import routes from '../../../configuration/routes'
import MaterialLoader from '../../global-components/elements/MaterialLoader'
import convertStringToSlug from '../../../utilities/slug/convertStringToSlug'
import { ReactComponent as BackIcon } from '../../../assets/icons/back-arrow-icon.svg'
import '../../../styles/consumer-flow/whats-new/whats-new.scss'
import PropTypes from 'prop-types'

function WhatsNewCards({ workflows }) {
  return (
    <div className="whats-new__cards">
      {!isEmpty(workflows)
        ? workflows.map(workflow => {
            const name = get(workflow, 'name', null)
            const slug = get(workflow, 'slug', null)
            const description = get(workflow, 'content.description', null)
            const category = get(workflow, 'categories[0].category', null)
            const lastUpdated = get(workflow, 'updatedAt', null)

            return name && slug ? (
              <Link
                to={`${routes.browseWorkflows}/${convertStringToSlug(
                  category,
                )}/${slug}`}
                className="whats-new__card"
                key={`whats-new-card-${slug}`}
              >
                <div className="whats-new__card-details">
                  {category ? (
                    <div className="whats-new__card-category">{category}</div>
                  ) : null}

                  {lastUpdated ? (
                    <div className="whats-new__card-date">
                      {dayjs(lastUpdated).format(dateFormats.monthDayYearShort)}
                    </div>
                  ) : null}
                </div>

                <div className="whats-new__card-content">
                  <h4>{name}</h4>
                  {description ? <p>{description}</p> : null}
                </div>
              </Link>
            ) : null
          })
        : null}
    </div>
  )
}

WhatsNewCards.propTypes = {
  workflows: PropTypes.arrayOf(PropTypes.object),
}

export default WhatsNewCards
