import React from 'react'
import PropTypes from 'prop-types'

function ModernHouseTallIcon({ colour }, props) {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g mask="url(#mask0_1_2220)">
        <path
          d="M3.65234 33.7109L36.3477 33.7109C36.9689 33.7109 37.4727 33.2072 37.4727 32.5859C37.4727 31.9647 36.9689 31.4609 36.3477 31.4609L3.65234 31.4609C3.03106 31.4609 2.52734 31.9647 2.52734 32.5859C2.52734 33.2072 3.03106 33.7109 3.65234 33.7109Z"
          stroke={colour}
          strokeWidth="1.25"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.48437 8.63258L7.48437 24.1484L32.5156 24.1484L32.5156 13.9777"
          stroke={colour}
          strokeWidth="1.25"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M30.4062 22.0391L30.4063 15.4297L9.59375 10.9317L9.59375 22.0391L30.4062 22.0391Z"
          stroke={colour}
          strokeWidth="1.25"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4.74219 30.125L4.74219 31.4609L35.2578 31.4609L35.2578 24.1484L4.74219 24.1484L4.74219 27.8804"
          stroke={colour}
          strokeWidth="1.25"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M29.009 26.5391L19.1914 26.5391L19.1914 31.4609L32.5508 31.4609L32.5508 26.5391L31.2445 26.5391"
          stroke={colour}
          strokeWidth="1.25"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M25.625 14.3963L25.625 22.039"
          stroke={colour}
          strokeWidth="1.25"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M20 13.3041L20 22.0391"
          stroke={colour}
          strokeWidth="1.25"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14.375 12.0781L14.375 22.0391"
          stroke={colour}
          strokeWidth="1.25"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.9324 7.63899L33.72 12.0778C34.2897 12.1995 34.6529 12.7599 34.5312 13.3295C34.4254 13.8249 33.9878 14.1641 33.5009 14.1641C33.4279 14.1641 33.3538 14.1564 33.2795 14.1406L6.27993 8.3753C5.71033 8.25366 5.34717 7.69327 5.46881 7.12367C5.59045 6.554 6.15098 6.1909 6.72044 6.31247L10.705 7.16333"
          stroke={colour}
          strokeWidth="1.25"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.32812 31.4609L15.6406 31.4609L15.6406 24.1484L8.32813 24.1484L8.32812 31.4609Z"
          stroke={colour}
          strokeWidth="1.25"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15.6406 26.0425L8.32812 26.0425"
          stroke={colour}
          strokeWidth="1.25"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15.6406 27.8047L8.32812 27.8047"
          stroke={colour}
          strokeWidth="1.25"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15.6406 29.5669L8.32812 29.5669"
          stroke={colour}
          strokeWidth="1.25"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M35.2578 21.4531L32.5156 21.4531"
          stroke={colour}
          strokeWidth="1.25"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M34.4393 21.4531L34.4393 24.1484"
          stroke={colour}
          strokeWidth="1.25"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4.74219 21.4531L7.48438 21.4531"
          stroke={colour}
          strokeWidth="1.25"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.56067 21.4531L5.56067 24.1484"
          stroke={colour}
          strokeWidth="1.25"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  )
}

ModernHouseTallIcon.defaultProps = {
  colour: '#000000',
}

ModernHouseTallIcon.propTypes = {
  colour: PropTypes.string,
}

export default ModernHouseTallIcon
