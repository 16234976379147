import React from 'react'
import PropTypes from 'prop-types'

function ManualSettingsIcon({ colour }, props) {
  return (
    <svg
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M34.6871 16.686H31.256C30.2396 15.3162 28.611 14.4266 26.7781 14.4266C24.9453 14.4266 23.3166 15.3162 22.3002 16.686H5.31291C3.4862 16.686 2 18.1721 2 20.0011C2 21.8278 3.4862 23.314 5.31291 23.314H22.3002C23.3166 24.6838 24.9453 25.5734 26.7781 25.5734C28.611 25.5734 30.2396 24.6838 31.256 23.314H34.6871C36.5138 23.314 38 21.8278 38 19.9989C38 18.1721 36.5138 16.686 34.6871 16.686ZM5.31291 21.2047C4.6493 21.2047 4.10938 20.6647 4.10938 19.9989C4.10938 19.3353 4.6493 18.7953 5.31291 18.7953H21.3373C21.2514 19.1835 21.2047 19.5863 21.2047 20C21.2047 20.4136 21.2514 20.8165 21.3373 21.2047H5.31291ZM26.7781 23.4641C24.868 23.4641 23.314 21.9101 23.314 20C23.314 18.0899 24.868 16.5359 26.7781 16.5359C28.6882 16.5359 30.2422 18.0899 30.2422 20C30.2422 21.9101 28.6882 23.4641 26.7781 23.4641ZM34.6871 21.2047H32.219C32.3049 20.8165 32.3516 20.4136 32.3516 20C32.3516 19.5863 32.3049 19.1835 32.219 18.7953H34.6871C35.3507 18.7953 35.8906 19.3353 35.8906 20.0011C35.8906 20.6648 35.3507 21.2047 34.6871 21.2047Z"
        fill={colour}
      />
      <path
        d="M34.6871 29.1125H17.6997C16.6833 27.7427 15.0546 26.8531 13.2218 26.8531C11.389 26.8531 9.76032 27.7427 8.74388 29.1125H5.31291C3.4862 29.1125 2 30.5987 2 32.4277C2 34.2545 3.4862 35.7406 5.31291 35.7406H8.74395C9.76039 37.1104 11.389 38 13.2219 38C15.0548 38 16.6834 37.1104 17.6998 35.7406H34.6871C36.5138 35.7406 38 34.2545 38 32.4255C38 30.5987 36.5138 29.1125 34.6871 29.1125ZM5.31291 33.6313C4.6493 33.6313 4.10938 33.0913 4.10938 32.4255C4.10938 31.7619 4.6493 31.2219 5.31291 31.2219H7.78102C7.6951 31.6101 7.64841 32.013 7.64841 32.4266C7.64841 32.8403 7.6951 33.2431 7.78102 33.6313H5.31291ZM13.2219 35.8906C11.3118 35.8906 9.75779 34.3366 9.75779 32.4265C9.75779 30.5164 11.3118 28.9625 13.2219 28.9625C15.132 28.9625 16.686 30.5164 16.686 32.4265C16.686 34.3366 15.132 35.8906 13.2219 35.8906ZM34.6871 33.6313H18.6627C18.7486 33.2431 18.7953 32.8403 18.7953 32.4266C18.7953 32.013 18.7486 31.6101 18.6627 31.2219H34.6871C35.3507 31.2219 35.8906 31.7619 35.8906 32.4277C35.8906 33.0913 35.3507 33.6313 34.6871 33.6313Z"
        fill={colour}
      />
      <path
        d="M5.31291 10.8875H13.2627C14.2791 12.2573 15.9077 13.1469 17.7406 13.1469C19.5734 13.1469 21.2021 12.2573 22.2186 10.8875H34.6871C36.5138 10.8875 38 9.4013 38 7.57234C38 5.74562 36.5138 4.25942 34.6871 4.25942H22.2186C21.2021 2.88959 19.5734 2 17.7406 2C15.9077 2 14.2791 2.88959 13.2627 4.25935H5.31291C3.4862 4.25935 2 5.74555 2 7.57459C2 9.4013 3.4862 10.8875 5.31291 10.8875ZM34.6871 6.36873C35.3507 6.36873 35.8906 6.90866 35.8906 7.57452C35.8906 8.23812 35.3507 8.77805 34.6871 8.77805H23.1815C23.2674 8.38986 23.3141 7.98704 23.3141 7.57339C23.3141 7.15974 23.2674 6.75692 23.1815 6.36873H34.6871ZM17.7406 4.10938C19.6507 4.10938 21.2047 5.66335 21.2047 7.57346C21.2047 9.48357 19.6507 11.0375 17.7406 11.0375C15.8305 11.0375 14.2766 9.4835 14.2766 7.57346C14.2766 5.66342 15.8305 4.10938 17.7406 4.10938ZM5.31291 6.36873H12.2997C12.2138 6.75692 12.1671 7.15974 12.1671 7.57339C12.1671 7.98704 12.2138 8.38986 12.2997 8.77805H5.31291C4.6493 8.77813 4.10938 8.2382 4.10938 7.57227C4.10938 6.90866 4.6493 6.36873 5.31291 6.36873Z"
        fill={colour}
      />
    </svg>
  )
}

ManualSettingsIcon.defaultProps = {
  colour: '#000000',
}

ManualSettingsIcon.propTypes = {
  colour: PropTypes.string,
}

export default ManualSettingsIcon
