import renderConsumerField from '../../../../utilities/fields/renderConsumerFields'
import React from 'react'
import PropTypes from 'prop-types'

const FindReplaceCardPreview = ({ item, index }) => {
  const card = item?.subComponents?.[index]
  const fields = card?.fields || []
  return (
    <div className="cards__card">
      {fields.map((field, i) => {
        const FieldComponent = renderConsumerField(field?.fieldType)
        return FieldComponent ? <FieldComponent key={i} field={field} /> : null
      })}
    </div>
  )
}

FindReplaceCardPreview.propTypes = {
  item: PropTypes.object,
  index: PropTypes.number,
}

export default FindReplaceCardPreview
