import React from 'react'
import PropTypes from 'prop-types'

function FirstAidIcon({ colour }, props) {
  return (
    <svg
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M23.5156 25.2734H22.1094V23.8672C22.1094 23.4789 21.7945 23.1641 21.4062 23.1641H18.5938C18.2054 23.1641 17.8906 23.4789 17.8906 23.8672V25.2734H16.4844C16.096 25.2734 15.7812 25.5882 15.7812 25.9766V28.7891C15.7812 29.1774 16.096 29.4922 16.4844 29.4922H17.8906V30.8984C17.8906 31.2868 18.2054 31.6016 18.5938 31.6016H21.4062C21.7945 31.6016 22.1094 31.2868 22.1094 30.8984V29.4922H23.5156C23.9039 29.4922 24.2188 29.1774 24.2188 28.7891V25.9766C24.2188 25.5882 23.9039 25.2734 23.5156 25.2734ZM22.8125 28.0859H21.4062C21.018 28.0859 20.7031 28.4007 20.7031 28.7891V30.1953H19.2969V28.7891C19.2969 28.4007 18.9821 28.0859 18.5938 28.0859H17.1875V26.6797H18.5938C18.9821 26.6797 19.2969 26.3649 19.2969 25.9766V24.5703H20.7031V25.9766C20.7031 26.3649 21.018 26.6797 21.4062 26.6797H22.8125V28.0859Z"
        fill={colour}
      />
      <path
        d="M20 20C15.9291 20 12.6172 23.3119 12.6172 27.3828C12.6172 31.4537 15.9291 34.7656 20 34.7656C24.0709 34.7656 27.3828 31.4537 27.3828 27.3828C27.3828 23.3119 24.0709 20 20 20ZM20 33.3594C16.7045 33.3594 14.0234 30.6783 14.0234 27.3828C14.0234 24.0873 16.7045 21.4062 20 21.4062C23.2955 21.4062 25.9766 24.0873 25.9766 27.3828C25.9766 30.6783 23.2955 33.3594 20 33.3594Z"
        fill={colour}
      />
      <path
        d="M36.5938 12.5469V9.03125C36.5938 8.25584 35.9629 7.625 35.1875 7.625H29.8438V3.40625C29.8438 2.63084 29.2129 2 28.4375 2H11.5625C10.7871 2 10.1562 2.63084 10.1562 3.40625V7.625H4.8125C4.03709 7.625 3.40625 8.25584 3.40625 9.03125V12.5469C2.63084 12.5469 2 13.1777 2 13.9531V16.7656C2 17.541 2.63084 18.1719 3.40625 18.1719V36.5783C3.40625 37.3623 4.04398 38 4.82797 38H35.172C35.9559 38 36.5938 37.3623 36.5938 36.5783V30.1954C36.5938 29.807 36.2789 29.4923 35.8906 29.4923C35.5024 29.4923 35.1875 29.807 35.1875 30.1954V36.5783C35.1875 36.5868 35.1805 36.5938 35.172 36.5938H4.82797C4.81946 36.5938 4.8125 36.5868 4.8125 36.5783V18.1719H6.92188C6.92188 18.9473 7.55272 19.5781 8.32812 19.5781H11.8438C12.6192 19.5781 13.25 18.9473 13.25 18.1719H26.75C26.75 18.9473 27.3808 19.5781 28.1562 19.5781H31.6719C32.4473 19.5781 33.0781 18.9473 33.0781 18.1719H35.1875V24.5702C35.1875 24.9586 35.5024 25.2734 35.8906 25.2734C36.2789 25.2734 36.5938 24.9586 36.5938 24.5702V18.1719C37.3692 18.1719 38 17.541 38 16.7656V13.9531C38 13.1777 37.3692 12.5469 36.5938 12.5469ZM11.5625 3.40625H28.4375V7.625H27.0312V5.51562C27.0312 5.12729 26.7164 4.8125 26.3281 4.8125H13.6719C13.2835 4.8125 12.9688 5.12729 12.9688 5.51562V7.625H11.5625V3.40625ZM25.625 7.625H14.375V6.21875H25.625V7.625ZM4.8125 9.03125H35.1875V12.5469H4.8125V9.03125ZM11.8438 18.1719H8.32812V16.7656H11.8438V18.1719ZM31.6719 18.1719H28.1562V16.7656H31.6719L31.6728 18.1718C31.6728 18.1718 31.6725 18.1719 31.6719 18.1719ZM36.5938 16.7656H33.0781C33.0781 15.9902 32.4473 15.3594 31.6719 15.3594H28.1562C27.3808 15.3594 26.75 15.9902 26.75 16.7656H13.25C13.25 15.9902 12.6192 15.3594 11.8438 15.3594H8.32812C7.55272 15.3594 6.92188 15.9902 6.92188 16.7656H3.40625V13.9531H36.5938V16.7656Z"
        fill={colour}
      />
      <path
        d="M35.8906 28.0859C36.279 28.0859 36.5938 27.7711 36.5938 27.3828C36.5938 26.9945 36.279 26.6797 35.8906 26.6797C35.5023 26.6797 35.1875 26.9945 35.1875 27.3828C35.1875 27.7711 35.5023 28.0859 35.8906 28.0859Z"
        fill={colour}
      />
    </svg>
  )
}

FirstAidIcon.defaultProps = {
  colour: '#000000',
}

FirstAidIcon.propTypes = {
  colour: PropTypes.string,
}

export default FirstAidIcon
