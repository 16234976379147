import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useEditor, EditorContent } from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit'
import TipTapMenuBar from '../TipTap/TipTapMenuBar'
import { Color } from '@tiptap/extension-color'
import ListItem from '@tiptap/extension-list-item'
import TextStyle from '@tiptap/extension-text-style'
import TextAlign from '@tiptap/extension-text-align'
import Placeholder from '@tiptap/extension-placeholder'
import edjsHTML from 'editorjs-html'
import clsx from 'clsx'
import '../../../../styles/editor-flow/tip-tap/tiptap-editor.scss'
import { isEmpty } from 'lodash'
import { Underline } from '@tiptap/extension-underline'
import {
  TipTapAccordion,
  TipTapAccordionContent,
  TipTapAccordionHeader,
} from './TipTapAccordion/TipTapAccordion'
import TipTapLink from './TipTapLink/TipTapLink'
import { useNavigate } from 'react-router-dom'
import { bindRouterLinks } from '../../../../utilities/editor/bindRouterLinks'

const defaultContent = [
  {
    type: 'paragraph',
    attrs: {
      textAlign: 'left',
    },
  },
]
const TipTapEditor = ({ data, onChange, readOnly, onCreated }) => {
  const navigate = useNavigate()
  let content = {
    type: 'doc',
    content: !isEmpty(data) ? data : defaultContent,
  }

  if (data?.[0]?.id) {
    const edjsParser = edjsHTML({
      button: block => {
        return ''
      },
    })
    try {
      const HTML = edjsParser.parse({ blocks: data })?.join(' ')
      content = HTML || ''
    } catch (e) {
      console.log(e)
      content = ''
    }
  }

  const editor = useEditor({
    editable: !readOnly,
    extensions: [
      TipTapLink.configure({
        openOnClick: false,
      }),
      TextAlign.configure({
        types: ['heading', 'paragraph', 'image'],
      }),
      TipTapAccordion.configure({}),
      TipTapAccordionContent.configure({}),
      TipTapAccordionHeader.configure({}),
      Underline.configure({}),
      Color.configure({ types: [TextStyle.name, ListItem.name] }),
      TextStyle.configure({ types: [ListItem.name] }),
      StarterKit.configure({
        bulletList: {
          keepMarks: true,
          keepAttributes: false,
        },
        orderedList: {
          keepMarks: true,
          keepAttributes: false,
        },
      }),
      Placeholder.configure({
        emptyEditorClass: 'is-editor-empty',
        placeholder: 'Enter paragraph content',
      }),
    ],
    editorProps: {
      transformPastedHTML(html) {
        return html.replace(/style=\"(.*?)\"/g, '')
      },
    },
    onUpdate: ({ editor }) => {
      onChange(editor.getJSON()?.content || [])
    },
    onCreate: () => {
      if (!!onCreated) {
        onCreated()
      }
    },
    content,
  })

  useEffect(() => {
    if (editor && !editor.isEditable) {
      bindRouterLinks(editor, navigate)
    }
  }, [editor])

  return (
    <div className={clsx('tiptap-editor', { readOnly })}>
      {!readOnly && <TipTapMenuBar editors={[editor]} />}
      <EditorContent editor={editor} />
    </div>
  )
}

TipTapEditor.propTypes = {
  data: PropTypes.array,
  onChange: PropTypes.func,
  onCreated: PropTypes.func,
  readOnly: PropTypes.bool,
}

export default TipTapEditor
