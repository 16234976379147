import React from 'react'
import PropTypes from 'prop-types'

function BackIcon({ colour }, props) {
  return (
    <svg
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M28.4668 14.022C25.0221 11.869 21.6 11.269 19.925 11.1019V5.31408C19.925 4.919 19.7043 4.55703 19.353 4.37625C19.0017 4.19548 18.5788 4.22621 18.2574 4.45585L2.44163 15.7527C2.16446 15.9507 2 16.2703 2 16.6109C2 16.9515 2.16446 17.2712 2.44163 17.4691L18.2572 28.766C18.5787 28.9956 19.0015 29.0263 19.3529 28.8456C19.7042 28.6649 19.925 28.3029 19.925 27.9078V22.206C22.9065 22.3298 25.5611 22.9843 27.8281 24.1565C34.3199 27.5129 35.7816 34.1389 35.905 34.8593C35.9903 35.3713 36.4337 35.7406 36.9444 35.7406C36.9735 35.7406 37.0028 35.7394 37.0323 35.737C37.5792 35.6917 38 35.2346 38 34.6859C38 26.3081 35.5149 18.4272 28.4668 14.022ZM28.8546 22.3128C26.0086 20.8279 22.6494 20.075 18.8703 20.075C18.2878 20.075 17.8156 20.5472 17.8156 21.1297V25.8584L4.86924 16.6109L17.8156 7.36355V12.0922C17.8156 12.372 17.9268 12.6402 18.1246 12.8379C18.3223 13.0357 18.5906 13.1469 18.8703 13.1469C18.9125 13.1469 23.1353 13.1774 27.3488 15.8108C30.1121 17.5379 33.8354 20.9662 35.2801 28.1209C33.0758 24.8079 30.4312 23.1353 28.8546 22.3128Z"
        fill={colour}
      />
    </svg>
  )
}

BackIcon.defaultProps = {
  colour: '#000000',
}

BackIcon.propTypes = {
  colour: PropTypes.string,
}

export default BackIcon
