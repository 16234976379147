import PropTypes from 'prop-types'
import DividerField from '../base-fields/divider/DividerField'

const Divider = ({ component }) => {
  const subcomponent = component?.subComponents?.[0] || {}
  const { fields } = subcomponent || []
  const field = fields[0]

  return <DividerField field={field} />
}

Divider.propTypes = {
  component: PropTypes.object,
}

export default Divider
