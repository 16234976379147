import { get, isEmpty, filter, intersection } from 'lodash'

export default function filterWorkflowsByCategory(
  activeCategory,
  activeSubcategories,
  workflows,
) {
  return filter(workflows, function (workflow) {
    const workflowCategories = get(workflow, 'categories', [])
    const activeCategoryData = workflowCategories.find(
      category =>
        category?.category.replace(/\W+/g, '-').toLowerCase() ===
        activeCategory,
    )
    const workflowSubcategories = get(activeCategoryData, 'subcategories', [])
    const isCategorySearch = activeCategory && isEmpty(activeSubcategories)

    let formattedCategories = workflowCategories.map(category =>
      category?.category.replace(/\W+/g, '-').toLowerCase(),
    )

    return isCategorySearch
      ? formattedCategories.includes(activeCategory)
      : formattedCategories.includes(activeCategory) &&
          intersection(activeSubcategories, workflowSubcategories).length > 0
  })
}
