import React from 'react'
import { EDITOR_SPACER_FIELD } from '../../../../configuration/editor'
import { ReactComponent as DragIcon } from '../../../../assets/icons/suble-drag.svg'
import PropTypes from 'prop-types'
import '../../../../styles/editor-flow/editor-fields/editor-spacer-field.scss'
const MIN_HEIGHT = 24
function EditorSpacerField({ field, onChange }) {
  const block = field?.blocks?.[0] || {}
  const configurations = block?.configurations || {}

  const style = {
    height: '24px',
  }
  if (configurations?.height) {
    style.height = configurations.height + 'px'
  }

  const handler = mouseDownEvent => {
    const startSize = {
      height: configurations?.height || MIN_HEIGHT,
    }

    const startPosition = { x: mouseDownEvent.pageX, y: mouseDownEvent.pageY }

    function onMouseMove(mouseMoveEvent) {
      onChange({
        ...field,
        blocks: [
          {
            ...block,
            configurations: {
              ...configurations,
              height: Math.max(
                MIN_HEIGHT,
                startSize.height - startPosition.y + mouseMoveEvent.pageY,
              ),
            },
          },
        ],
      })
    }
    function onMouseUp() {
      document.body.removeEventListener('mousemove', onMouseMove)
    }

    document.body.addEventListener('mousemove', onMouseMove)
    document.body.addEventListener('mouseup', onMouseUp, { once: true })
  }

  return (
    <div className="editor-spacer-field" style={style}>
      <div className="editor-spacer-field-spacer">
        <div className="editor-spacer-field-options-overlay">
          <button
            type="button"
            className="editor-spacer-field-resize-se"
            onMouseDown={handler}
          >
            <DragIcon />
          </button>
          <div className="editor-spacer-value">{style.height}</div>
        </div>
      </div>
    </div>
  )
}

export const createSpacerField = id => {
  return {
    id,
    type: 'field',
    fieldType: EDITOR_SPACER_FIELD,
    blocks: [],
  }
}

EditorSpacerField.propTypes = {
  field: PropTypes.object,
  onChange: PropTypes.func,
}

export default EditorSpacerField
