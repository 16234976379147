import axios from 'axios'
import { cloneDeep, get, isEmpty } from 'lodash'

export const DUPLICATE_WORKFLOW_CROSS_CLIENT_BEGIN =
  'DUPLICATE_WORKFLOW_CROSS_CLIENT_BEGIN'
export const DUPLICATE_WORKFLOW_CROSS_CLIENT_ERROR =
  'DUPLICATE_WORKFLOW_CROSS_CLIENT_ERROR'
export const DUPLICATE_WORKFLOW_CROSS_CLIENT_SUCCESS =
  'DUPLICATE_WORKFLOW_CROSS_CLIENT_SUCCESS'

export const duplicateCrossClientWorkflow =
  (values, existingContent, successEvent, token) =>
  async (dispatch, getState) => {
    const newClientId = get(values, 'clientId', null)
    const existingWorkflow = get(existingContent, 'workflow', {})
    const existingSteps = get(existingContent, 'steps', [])
    const existingWorkflowClients = get(existingWorkflow, 'clientId', [])
    const steps = stripStepLinks(existingSteps)
    const rehydratedContent = {
      steps: steps.map(step => {
        const components = get(step, 'components', [])

        return {
          ...step,
          clientId: [newClientId],
          components: components.map(component => {
            const fields = get(component, 'fields', [])
            const subComponents = get(component, 'subComponents', [])

            return {
              ...component,
              clientId: [newClientId],
              fields: fields.map(field => {
                return {
                  ...field,
                  clientId: [newClientId],
                }
              }),
              subComponents: subComponents.map(subComponent => {
                const subComponentFields = get(subComponent, 'fields', [])
                const subComponentLayouts = get(subComponent, 'layouts', [])

                return {
                  ...subComponent,
                  fields: subComponentFields.map(field => {
                    return {
                      ...field,
                      clientId: [newClientId],
                    }
                  }),
                  layouts: subComponentLayouts.map(subComponentLayout => {
                    const subComponentLayoutFields = get(
                      subComponentLayout,
                      'fields',
                      [],
                    )

                    return {
                      ...subComponentLayout,
                      fields: subComponentLayoutFields.map(
                        subComponentLayoutField => {
                          return {
                            ...subComponentLayoutField,
                            clientId: [newClientId],
                          }
                        },
                      ),
                    }
                  }),
                }
              }),
            }
          }),
          status: 'draft',
        }
      }),
      workflow: {
        ...existingWorkflow,
        clientId: [newClientId],
        categories: [],
        name: values?.name,
        relatedWorkflows: [],
        slug: values?.slug,
        status: 'draft',
        operatingLocations: values.operatingLocations,
      },
    }

    dispatch({ type: DUPLICATE_WORKFLOW_CROSS_CLIENT_BEGIN })
    return axios({
      url: `${process.env.REACT_APP_PRODUCT_API}/api/v1/workflow/cross-client/duplicate/${newClientId}`,
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      data: {
        content: rehydratedContent,
        originalClients: existingWorkflowClients,
      },
    })
      .then(response => {
        const workflow = get(response, 'data', [])

        if (!isEmpty(workflow)) {
          dispatch({
            type: DUPLICATE_WORKFLOW_CROSS_CLIENT_SUCCESS,
            payload: workflow,
          })
          successEvent(response)
        } else {
          dispatch({
            type: DUPLICATE_WORKFLOW_CROSS_CLIENT_ERROR,
            payload:
              'There was an error duplicating this workflow - please wait a moment, then try again.',
          })
        }
      })
      .catch(error => {
        dispatch({
          type: DUPLICATE_WORKFLOW_CROSS_CLIENT_ERROR,
          payload: error.message,
        })
      })
  }

function stripStepLinks(oldSteps) {
  const steps = cloneDeep(oldSteps)
  steps.forEach(step => {
    const components = step?.components || []
    components.forEach(component => {
      const subComponents = component?.subComponents || []
      subComponents.forEach(subComponent => {
        const layouts = subComponent?.layouts || []
        const fields = subComponent?.fields || []
        if (subComponent?.configurations?.link) {
          delete subComponent.configurations.link
        }
        fields.forEach(stripFieldLinks)
        layouts.forEach(layout => {
          const fields = layout?.fields || []
          fields.forEach(stripFieldLinks)
        })
      })
    })
  })
  return steps
}

/**
 * This function mutates data
 */
function stripFieldLinks(field) {
  if (field.fieldType === 'RichText') {
    field?.blocks?.forEach(parseBlock)
  } else if (field.fieldType === 'Table') {
    const table = field?.blocks?.[0]?.table
    if (table) {
      const { body } = table
      body.forEach(row => row.forEach(parseBlock))
    }
  } else {
    field?.blocks?.forEach(block => {
      if (block?.configurations?.link) {
        delete block.configurations.link
      }
    })
  }
}

function parseBlock(block) {
  if (block?.marks?.length > 0) {
    block.marks = block.marks.filter(mark => mark.type !== 'link')
  }
  if (block?.content?.length > 0) {
    block?.content?.forEach(parseBlock)
  }
}
