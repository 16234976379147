import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectManagedContentId,
  selectManageWorkflowModalVisibility,
  toggleManageWorkflowModal,
} from '../../../../../redux/user/userSelections'
import { selectSocketResourceBlockedBy } from '../../../../../redux/socket/socket'
import Modal from '../../../../global-components/elements/Modal'
import { ReactComponent as CloseModalIcon } from '../../../../../assets/icons/close-icon.svg'
import { ReactComponent as MessageIcon } from '../../../../../assets/icons/message-alert-square-icon.svg'

const ManageWorkflowStepModalBlocked = () => {
  const dispatch = useDispatch()
  const managedContentId = useSelector(selectManagedContentId)
  const isManageWorkflowModalVisible = useSelector(
    selectManageWorkflowModalVisibility,
  )
  const resourceBlockedBy = useSelector(state =>
    selectSocketResourceBlockedBy({
      state,
      type: 'configureStep',
      id: managedContentId,
    }),
  )

  function handleToggleCreateModal() {
    dispatch(toggleManageWorkflowModal(!isManageWorkflowModalVisible))
  }

  return (
    <Modal
      modalClassName="modal--delete-workflow modal--sm"
      isOpen={true}
      onRequestClose={() => handleToggleCreateModal()}
    >
      <div className="modal__header">
        <h3 className="flex align-center">
          <MessageIcon />
          This step is already being configured
        </h3>
        <button
          className="modal__close"
          onClick={() => handleToggleCreateModal()}
        >
          <CloseModalIcon />
        </button>
      </div>
      <div className="modal__body">
        <br />
        <p>
          <b>
            Oops! It seems{' '}
            {`${resourceBlockedBy?.firstName} ${resourceBlockedBy?.lastName}`}{' '}
            is already editing this section.
          </b>
        </p>
        <p>
          To avoid any accidental overlaps, this section is currently locked.
          You can choose to wait, or feel free to reach out to{' '}
          {resourceBlockedBy?.firstName} and see when they might be done.
        </p>
        <p>
          Collaboration is great, but let&apos;s avoid stepping on each
          other&apos;s toes.
        </p>
      </div>
      <div className="modal__submit">
        <button className="btn" onClick={() => handleToggleCreateModal()}>
          Close
        </button>
      </div>
    </Modal>
  )
}

export default ManageWorkflowStepModalBlocked
