// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.icon-field {
  display: flex;
  width: 100%;
  margin: 16px 0;
}
.icon-field:first-child {
  margin-top: 0;
}
.icon-field:last-child {
  margin-bottom: 0;
}`, "",{"version":3,"sources":["webpack://./src/styles/consumer-flow/base-fields/icon-field.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,WAAA;EACA,cAAA;AACF;AAAE;EACE,aAAA;AAEJ;AAAE;EACE,gBAAA;AAEJ","sourcesContent":[".icon-field {\n  display: flex;\n  width: 100%;\n  margin: 16px 0;\n  &:first-child {\n    margin-top: 0;\n  }\n  &:last-child {\n    margin-bottom: 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
