import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { get, isEmpty, orderBy } from 'lodash'
import { useSelector } from 'react-redux'
import { selectCategoriesState } from '../../../redux/categories/categories'
import { selectActiveClientId } from '../../../redux/user/userSelections'
import '../../../styles/global-components/forms/categories-field.scss'
import ManageCategoryModal from '../../editor-flow/create-workflow/ManageCategoryModal'

function CategoriesField({ arrayHelpers }) {
  const [categoryModalIsOpen, setCategoryModalIsOpen] = useState(false)
  const [categoryToEdit, setCategoryToEdit] = useState(null)
  const setFieldValue = get(arrayHelpers, 'form.setFieldValue', null)
  const activeCategories = get(arrayHelpers, 'form.values.categories', [])
  const activeClientId = useSelector(selectActiveClientId)
  const categoriesState = useSelector(selectCategoriesState)
  const categories = get(categoriesState, `categories.${activeClientId}`, [])
  const globalCategoryPayload = isEmpty(activeCategories)
    ? categories.map(category => {
        const categoryId = get(category, '_id', null)
        const categorySubcategories = get(category, 'subcategories', null)

        return {
          category: categoryId,
          subcategories: categorySubcategories,
        }
      })
    : []

  function handleToggleAddSubcategory(categoryId) {
    const category = categories.find(search => search._id === categoryId)
    setCategoryToEdit(category)
    setCategoryModalIsOpen(true)
  }
  return (
    <div className="categories-field">
      <div className="categories-field__heading">
        <p className="categories-field__title">
          Workflow Categories *
          <span className="categories-field__heading-count">
            {activeCategories.length} Selected
          </span>
        </p>

        <div className="categories-field__heading-actions">
          <button
            onClick={() => setCategoryModalIsOpen(true)}
            className="btn btn--white"
            type="button"
          >
            Add Category
          </button>

          <button
            type="button"
            className="btn btn--white"
            onClick={() => setFieldValue('categories', globalCategoryPayload)}
          >
            {isEmpty(activeCategories) ? 'Select All' : 'Remove All'}
          </button>
        </div>
      </div>
      <div className="categories-field__categories">
        {!isEmpty(categories) ? (
          <>
            {categories.map((category, i) => {
              const categoryId = get(category, '_id', null)
              const title = get(category, 'category', null)
              const subcategories = get(category, 'subcategories', [])
              const sortedSubcategories = orderBy(
                subcategories,
                [subcategory => subcategory.toLowerCase()],
                ['asc'],
              )
              const isCategoryChecked = activeCategories.some(
                activeCategory => activeCategory?.category === categoryId,
              )

              const categoryPayload = {
                category: categoryId,
                subcategories: [],
              }
              const activeCategoryIndex = activeCategories
                .map(e => e?.category)
                .indexOf(categoryId)

              return (
                <div
                  key={`workflow-category-${i}`}
                  className="categories-field__category"
                >
                  <label>
                    <input
                      type="checkbox"
                      checked={isCategoryChecked}
                      onChange={e => {
                        if (e.target.checked) {
                          arrayHelpers.push(categoryPayload)
                        } else {
                          arrayHelpers.remove(activeCategoryIndex)
                        }
                      }}
                    />
                    <span className="label__content">{title}</span>
                  </label>

                  {isCategoryChecked ? (
                    <div className="categories-field__subcategories">
                      {!isEmpty(sortedSubcategories)
                        ? sortedSubcategories.map((subcategory, i) => {
                            const activeCategory = activeCategories.find(
                              activeCategory =>
                                activeCategory?.category === categoryId,
                            )
                            const activeCategorySubcategories = get(
                              activeCategory,
                              'subcategories',
                              [],
                            )
                            const isSubcategoryChecked = activeCategories.some(
                              activeCategory =>
                                activeCategory?.category === categoryId &&
                                activeCategory?.subcategories.includes(
                                  subcategory,
                                ),
                            )

                            function handleSubcategoryEvent(type) {
                              const subcategoriesPayload =
                                type === 'add'
                                  ? [
                                      ...activeCategorySubcategories,
                                      subcategory,
                                    ]
                                  : activeCategorySubcategories.filter(
                                      function (item) {
                                        return item !== subcategory
                                      },
                                    )

                              return arrayHelpers.replace(activeCategoryIndex, {
                                category: categoryId,
                                subcategories: subcategoriesPayload,
                              })
                            }

                            return (
                              <div
                                key={`workflow-subcategory-${subcategory}-${i}`}
                                className="categories-field__subcategory"
                              >
                                <label>
                                  <input
                                    type="checkbox"
                                    checked={isSubcategoryChecked}
                                    onChange={e => {
                                      if (e.target.checked) {
                                        handleSubcategoryEvent('add')
                                      } else {
                                        handleSubcategoryEvent('remove')
                                      }
                                    }}
                                  />

                                  <span className="label__content">
                                    {subcategory}
                                  </span>
                                </label>
                              </div>
                            )
                          })
                        : null}

                      <button
                        className="btn-text btn-text--base"
                        onClick={() => handleToggleAddSubcategory(categoryId)}
                        type="button"
                      >
                        Add New Subcategory +
                      </button>
                    </div>
                  ) : null}
                </div>
              )
            })}
          </>
        ) : (
          <div className="form__message form__message--new">
            <h4>Add Your First Category</h4>
            <p>
              There are currently no categories assigned to this client - click
              the button below and follow the instructions to add your first
              category.
            </p>
            <button
              type="button"
              onClick={() => setCategoryModalIsOpen(true)}
              className="btn btn--white"
            >
              Add New Category
            </button>
          </div>
        )}
      </div>
      <ManageCategoryModal
        isOpen={categoryModalIsOpen}
        category={categoryToEdit}
        onRequestClose={() => setCategoryModalIsOpen(false)}
        onSubmit={() => setCategoryModalIsOpen(false)}
      />
    </div>
  )
}

CategoriesField.propTypes = {
  arrayHelpers: PropTypes.object.isRequired,
}

export default CategoriesField
