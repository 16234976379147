import React from 'react'
import PropTypes from 'prop-types'

function SearchMagnifyingGlassIcon({ colour }, props) {
  return (
    <svg
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M23.7969 15.3594C23.4088 15.3594 23.0938 15.6744 23.0938 16.0625C23.0938 16.4506 23.4088 16.7656 23.7969 16.7656C24.185 16.7656 24.5 16.4506 24.5 16.0625C24.5 15.6744 24.185 15.3594 23.7969 15.3594Z"
        fill={colour}
      />
      <path
        d="M37.176 33.199L27.7907 23.8137C29.3196 21.5107 30.125 18.8437 30.125 16.0625C30.125 8.30844 23.8166 2 16.0625 2C8.30844 2 2 8.30844 2 16.0625C2 23.8166 8.30844 30.125 16.0625 30.125C18.8438 30.125 21.5107 29.3196 23.8137 27.7908L26.4079 30.3849C26.4082 30.3853 26.4086 30.3856 26.409 30.3861L33.1989 37.176C33.7303 37.7074 34.4365 38 35.1875 38C35.9385 38 36.6447 37.7074 37.1758 37.1762C37.7073 36.6451 38 35.9389 38 35.1875C38 34.4361 37.7073 33.7299 37.176 33.199ZM23.483 26.3089L23.4829 26.3091C21.3125 27.8855 18.7467 28.7188 16.0625 28.7188C9.08384 28.7188 3.40625 23.0412 3.40625 16.0625C3.40625 9.08384 9.08384 3.40625 16.0625 3.40625C23.0412 3.40625 28.7188 9.08384 28.7188 16.0625C28.7188 18.7467 27.8855 21.3125 26.309 23.4829C25.5203 24.5695 24.5695 25.5203 23.483 26.3089ZM24.9582 26.9464C25.6859 26.351 26.3511 25.6858 26.9464 24.9582L28.9412 26.953C28.3346 27.669 27.669 28.3346 26.953 28.9412L24.9582 26.9464ZM36.1816 36.1816C35.9159 36.4474 35.5628 36.5938 35.1875 36.5938C34.8122 36.5938 34.4591 36.4474 34.1934 36.1816L27.95 29.9382C28.662 29.3272 29.3272 28.6621 29.9382 27.95L36.1818 34.1936C36.4474 34.459 36.5938 34.8119 36.5938 35.1875C36.5938 35.5631 36.4474 35.916 36.1816 36.1816Z"
        fill={colour}
      />
      <path
        d="M16.0625 4.8125C9.85918 4.8125 4.8125 9.85918 4.8125 16.0625C4.8125 22.2658 9.85918 27.3125 16.0625 27.3125C22.2658 27.3125 27.3125 22.2658 27.3125 16.0625C27.3125 9.85918 22.2658 4.8125 16.0625 4.8125ZM16.0625 25.9062C10.6347 25.9062 6.21875 21.4903 6.21875 16.0625C6.21875 10.6347 10.6347 6.21875 16.0625 6.21875C21.4903 6.21875 25.9062 10.6347 25.9062 16.0625C25.9062 21.4903 21.4903 25.9062 16.0625 25.9062Z"
        fill={colour}
      />
      <path
        d="M23.9421 13.0442C23.3366 11.4684 22.2824 10.121 20.8933 9.14797C19.4712 8.15164 17.8007 7.625 16.0625 7.625C15.6742 7.625 15.3594 7.93986 15.3594 8.32812C15.3594 8.71639 15.6742 9.03125 16.0625 9.03125C18.9523 9.03125 21.5912 10.8466 22.6294 13.5486C22.7368 13.8282 23.0033 13.9997 23.2859 13.9997C23.3697 13.9997 23.455 13.9846 23.5379 13.9528C23.9004 13.8135 24.0814 13.4067 23.9421 13.0442Z"
        fill={colour}
      />
    </svg>
  )
}

SearchMagnifyingGlassIcon.defaultProps = {
  colour: '#000000',
}

SearchMagnifyingGlassIcon.propTypes = {
  colour: PropTypes.string,
}

export default SearchMagnifyingGlassIcon
