import React from 'react'
import PropTypes from 'prop-types'

function PaperIcon({ colour }, props) {
  return (
    <svg
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10.1201 14.7492C10.1201 15.0405 10.3563 15.2766 10.6476 15.2766H27.3127C27.604 15.2766 27.8401 15.0405 27.8401 14.7492C27.8401 14.4579 27.604 14.2217 27.3127 14.2217H10.6476C10.3562 14.2216 10.1201 14.4578 10.1201 14.7492Z"
        fill={colour}
      />
      <path
        d="M10.6476 19.4615H27.3127C27.604 19.4615 27.8401 19.2254 27.8401 18.9341C27.8401 18.6428 27.604 18.4066 27.3127 18.4066H10.6476C10.3562 18.4066 10.1201 18.6428 10.1201 18.9341C10.1201 19.2254 10.3562 19.4615 10.6476 19.4615Z"
        fill={colour}
      />
      <path
        d="M10.6476 23.6466H27.3127C27.604 23.6466 27.8401 23.4104 27.8401 23.1191C27.8401 22.8278 27.604 22.5916 27.3127 22.5916H10.6476C10.3562 22.5916 10.1201 22.8278 10.1201 23.1191C10.1201 23.4104 10.3562 23.6466 10.6476 23.6466Z"
        fill={colour}
      />
      <path
        d="M10.6476 27.8315H18.9801C19.2715 27.8315 19.5076 27.5953 19.5076 27.304C19.5076 27.0127 19.2714 26.7765 18.9801 26.7765H10.6476C10.3562 26.7765 10.1201 27.0127 10.1201 27.304C10.1201 27.5953 10.3562 27.8315 10.6476 27.8315Z"
        fill={colour}
      />
      <path
        d="M27.6512 9.63236C28.1562 9.63236 28.5671 9.22153 28.5671 8.71647V5.90664C28.5671 5.40159 28.1562 4.99075 27.6512 4.99075H20.0709C19.5659 4.99075 19.155 5.40159 19.155 5.90664V8.71647C19.155 9.22153 19.5659 9.63236 20.0709 9.63236H27.6512ZM20.2101 6.04579H27.512V8.57732H20.2101V6.04579Z"
        fill={colour}
      />
      <path
        d="M32.2472 5.14909H31.4135V4.04841C31.4135 2.91891 30.4946 2 29.3651 2H12.5774C12.0302 2 11.5157 2.21305 11.129 2.59998L6.30439 7.42454C5.91739 7.8114 5.70435 8.32587 5.70435 8.87298V13.5187C5.70435 13.81 5.94053 14.0462 6.23183 14.0462C6.52313 14.0462 6.75931 13.81 6.75931 13.5187V8.87298C6.75931 8.83852 6.76121 8.80435 6.76466 8.77053H10.7358C11.6947 8.77053 12.4748 7.99041 12.4748 7.03156V3.06038C12.5086 3.05694 12.5428 3.05504 12.5773 3.05504H29.3651C29.9129 3.05504 30.3585 3.50068 30.3585 4.04848V26.0704C30.3585 26.3617 30.5947 26.5978 30.886 26.5978C31.1773 26.5978 31.4135 26.3617 31.4135 26.0704V6.20412H32.2471C32.7949 6.20412 33.2405 6.64977 33.2405 7.19757V35.9516C33.2405 36.4994 32.7949 36.945 32.2471 36.945H10.6349C10.0871 36.945 9.64142 36.4994 9.64142 35.9516V34.8509H29.3651C30.4946 34.8509 31.4135 33.932 31.4135 32.8025V28.537C31.4135 28.2457 31.1773 28.0095 30.886 28.0095C30.5947 28.0095 30.3585 28.2457 30.3585 28.537V32.8025C30.3585 33.3503 29.9129 33.7959 29.3651 33.7959H7.75283C7.20503 33.7959 6.75939 33.3503 6.75939 32.8025V15.9849C6.75939 15.6936 6.52321 15.4574 6.2319 15.4574C5.9406 15.4574 5.70442 15.6936 5.70442 15.9849V32.8025C5.70442 33.932 6.6234 34.8509 7.75283 34.8509H8.58646V35.9516C8.58646 37.0811 9.50544 38 10.6349 38H32.2472C33.3767 38 34.2956 37.0811 34.2956 35.9516V7.1975C34.2957 6.068 33.3767 5.14909 32.2472 5.14909ZM10.7359 7.71549H7.50547L11.4198 3.80105V7.03156C11.4198 7.40865 11.113 7.71549 10.7359 7.71549Z"
        fill={colour}
      />
    </svg>
  )
}

PaperIcon.defaultProps = {
  colour: '#000000',
}

PaperIcon.propTypes = {
  colour: PropTypes.string,
}

export default PaperIcon
