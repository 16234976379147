function highlightTextInHTML({ ref, highlight }) {
  const element = ref?.current
  const innerHTML = element?.innerHTML
  const hasInnerText = !!element?.innerText
  const hasReplaced = !!element?.querySelector('.find-replace__highlight')
  let i = 0
  if (highlight && hasInnerText && !hasReplaced) {
    element.innerHTML = innerHTML?.replaceAll(highlight.find, find => {
      if (i++ === highlight?.highlightIndex) {
        return `<span class="find-replace__highlight">${find}</span>`
      }
      return find
    })
  }
}

export default highlightTextInHTML
