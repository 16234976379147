import React from 'react'
import PropTypes from 'prop-types'

function ScheduleIcon({ colour }, props) {
  return (
    <svg
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9.91121 17.8729H6.01001C5.69922 17.8729 5.44751 17.6212 5.44751 17.3104V13.4092C5.44751 13.0984 5.69922 12.8467 6.01001 12.8467H9.91121C10.222 12.8467 10.4737 13.0984 10.4737 13.4092V17.3104C10.4737 17.6209 10.2218 17.8729 9.91121 17.8729ZM6.57252 16.7479H9.34871V13.9717H6.57252V16.7479Z"
        fill={colour}
      />
      <path
        d="M16.5775 17.8729H12.6763C12.3655 17.8729 12.1138 17.6212 12.1138 17.3104V13.4092C12.1138 13.0984 12.3655 12.8467 12.6763 12.8467H16.5775C16.8883 12.8467 17.14 13.0984 17.14 13.4092V17.3104C17.1399 17.6209 16.888 17.8729 16.5775 17.8729ZM13.2388 16.7479H16.015V13.9717H13.2388V16.7479Z"
        fill={colour}
      />
      <path
        d="M23.2435 17.8729H19.3423C19.0315 17.8729 18.7798 17.6212 18.7798 17.3104V13.4092C18.7798 13.0984 19.0315 12.8467 19.3423 12.8467H23.2435C23.5543 12.8467 23.806 13.0984 23.806 13.4092V17.3104C23.806 17.6209 23.5542 17.8729 23.2435 17.8729ZM19.9048 16.7479H22.681V13.9717H19.9048V16.7479Z"
        fill={colour}
      />
      <path
        d="M29.9097 17.8729H26.0085C25.6978 17.8729 25.446 17.6212 25.446 17.3104V13.4092C25.446 13.0984 25.6978 12.8467 26.0085 12.8467H29.9097C30.2205 12.8467 30.4722 13.0984 30.4722 13.4092V17.3104C30.4722 17.6209 30.2203 17.8729 29.9097 17.8729ZM26.5711 16.7479H29.3472V13.9717H26.5711V16.7479Z"
        fill={colour}
      />
      <path
        d="M9.91121 23.8902H6.01001C5.69922 23.8902 5.44751 23.6385 5.44751 23.3277V19.4265C5.44751 19.1157 5.69922 18.864 6.01001 18.864H9.91121C10.222 18.864 10.4737 19.1157 10.4737 19.4265V23.3277C10.4737 23.6383 10.2218 23.8902 9.91121 23.8902ZM6.57252 22.7652H9.34871V19.989H6.57252V22.7652Z"
        fill={colour}
      />
      <path
        d="M16.5775 23.8902H12.6763C12.3655 23.8902 12.1138 23.6385 12.1138 23.3277V19.4265C12.1138 19.1157 12.3655 18.864 12.6763 18.864H16.5775C16.8883 18.864 17.14 19.1157 17.14 19.4265V23.3277C17.1399 23.6383 16.888 23.8902 16.5775 23.8902ZM13.2388 22.7652H16.015V19.989H13.2388V22.7652Z"
        fill={colour}
      />
      <path
        d="M9.91121 29.9075H6.01001C5.69922 29.9075 5.44751 29.6558 5.44751 29.345V25.4439C5.44751 25.1331 5.69922 24.8813 6.01001 24.8813H9.91121C10.222 24.8813 10.4737 25.1331 10.4737 25.4439V29.345C10.4737 29.6556 10.2218 29.9075 9.91121 29.9075ZM6.57252 28.7825H9.34871V26.0063H6.57252V28.7825Z"
        fill={colour}
      />
      <path
        d="M16.5775 29.9075H12.6763C12.3655 29.9075 12.1138 29.6558 12.1138 29.345V25.4439C12.1138 25.1331 12.3655 24.8813 12.6763 24.8813H16.5775C16.8883 24.8813 17.14 25.1331 17.14 25.4439V29.345C17.1399 29.6556 16.888 29.9075 16.5775 29.9075ZM13.2388 28.7825H16.015V26.0063H13.2388V28.7825Z"
        fill={colour}
      />
      <path
        d="M19.9819 32.6936H4.41614C3.44842 32.6936 2.66113 31.9064 2.66113 30.9389V10.6226C2.66113 10.3118 2.91284 10.0601 3.22364 10.0601H32.6961C33.0069 10.0601 33.2586 10.3118 33.2586 10.6226V21.288C33.2586 21.5988 33.0069 21.8505 32.6961 21.8505C32.3853 21.8505 32.1336 21.5988 32.1336 21.288V11.1851H3.78614V30.9389C3.78614 31.286 4.06878 31.5686 4.41614 31.5686H19.9819C20.2925 31.5686 20.5445 31.8203 20.5445 32.1311C20.5445 32.4419 20.2924 32.6936 19.9819 32.6936Z"
        fill={colour}
      />
      <path
        d="M32.6962 11.1851H3.22364C2.91284 11.1851 2.66113 10.9333 2.66113 10.6225V6.62975C2.66113 5.61045 3.49034 4.78125 4.50963 4.78125H7.49325C7.80405 4.78125 8.05576 5.03296 8.05576 5.34375C8.05576 5.65455 7.80405 5.90626 7.49325 5.90626H4.50963C4.1107 5.90626 3.78614 6.23087 3.78614 6.62975V10.06H32.1338V6.62998C32.1338 6.23105 31.8092 5.90649 31.4103 5.90649H28.4267C28.1159 5.90649 27.8642 5.65479 27.8642 5.34399C27.8642 5.03319 28.1159 4.78149 28.4267 4.78149H31.4103C32.4296 4.78149 33.2588 5.61069 33.2588 6.62998V10.6225C33.2588 10.9333 33.0069 11.1851 32.6962 11.1851Z"
        fill={colour}
      />
      <path
        d="M26.0957 5.90626H9.82422C9.51343 5.90626 9.26172 5.65455 9.26172 5.34375C9.26172 5.03296 9.51343 4.78125 9.82422 4.78125H26.0957C26.4065 4.78125 26.6582 5.03296 26.6582 5.34375C26.6582 5.65455 26.4062 5.90626 26.0957 5.90626Z"
        fill={colour}
      />
      <path
        d="M8.65844 8.68791C7.70571 8.68791 6.93042 7.91262 6.93042 6.95988V3.72802C6.93042 2.77529 7.70577 2 8.65844 2C9.61112 2 10.3865 2.77529 10.3865 3.72802V6.95982C10.3865 7.91256 9.61142 8.68791 8.65844 8.68791ZM8.65844 3.12501C8.32589 3.12501 8.05537 3.39547 8.05537 3.72808V6.95988C8.05537 7.29243 8.32583 7.56296 8.65844 7.56296C8.991 7.56296 9.26152 7.29249 9.26152 6.95988V3.72802C9.26152 3.39547 8.99106 3.12501 8.65844 3.12501Z"
        fill={colour}
      />
      <path
        d="M27.2612 8.68791C26.3083 8.68791 25.5332 7.91262 25.5332 6.95988V3.72802C25.5332 2.77529 26.3083 2 27.2612 2C28.214 2 28.9893 2.77529 28.9893 3.72802V6.95982C28.9893 7.91256 28.2139 8.68791 27.2612 8.68791ZM27.2612 3.12501C26.9287 3.12501 26.6582 3.39547 26.6582 3.72808V6.95988C26.6582 7.29243 26.9286 7.56296 27.2612 7.56296C27.5938 7.56296 27.8643 7.29249 27.8643 6.95988V3.72802C27.8642 3.39547 27.5938 3.12501 27.2612 3.12501Z"
        fill={colour}
      />
      <path
        d="M28.0273 38C26.7706 38 25.551 37.7536 24.4027 37.268C23.2939 36.7991 22.298 36.1278 21.443 35.2728C20.588 34.4178 19.9168 33.4222 19.4478 32.3131C18.9622 31.1649 18.7158 29.9452 18.7158 28.6885C18.7158 27.4318 18.9622 26.2121 19.4478 25.0639C19.9167 23.955 20.588 22.9592 21.443 22.1042C22.298 21.2491 23.2936 20.5779 24.4027 20.1089C25.5509 19.6233 26.7706 19.377 28.0273 19.377C29.2841 19.377 30.5037 19.6233 31.652 20.1089C32.7608 20.5779 33.7566 21.2491 34.6116 22.1042C35.4667 22.9592 36.1379 23.9548 36.6069 25.0639C37.0925 26.212 37.3389 27.4318 37.3389 28.6885C37.3389 29.9452 37.0925 31.1649 36.6069 32.3131C36.1379 33.4219 35.4667 34.4178 34.6116 35.2728C33.7566 36.1278 32.761 36.799 31.652 37.268C30.5037 37.7537 29.2841 38 28.0273 38ZM28.0273 20.502C23.5132 20.502 19.8408 24.1744 19.8408 28.6885C19.8408 33.2026 23.5132 36.875 28.0273 36.875C32.5414 36.875 36.2138 33.2026 36.2138 28.6885C36.2138 24.1744 32.5413 20.502 28.0273 20.502Z"
        fill={colour}
      />
      <path
        d="M28.0274 30.339C27.1174 30.339 26.377 29.5987 26.377 28.6886C26.377 27.7785 27.1173 27.0381 28.0274 27.0381C28.9375 27.0381 29.6779 27.7785 29.6779 28.6886C29.6779 29.5987 28.9375 30.339 28.0274 30.339ZM28.0274 28.1631C27.7377 28.1631 27.502 28.3989 27.502 28.6886C27.502 28.9782 27.7377 29.214 28.0274 29.214C28.3171 29.214 28.5529 28.9782 28.5529 28.6886C28.5529 28.3989 28.3171 28.1631 28.0274 28.1631Z"
        fill={colour}
      />
      <path
        d="M32.0665 29.2507H29.115C28.8042 29.2507 28.5525 28.999 28.5525 28.6882C28.5525 28.3774 28.8042 28.1257 29.115 28.1257H32.0665C32.3773 28.1257 32.629 28.3774 32.629 28.6882C32.629 28.999 32.377 29.2507 32.0665 29.2507Z"
        fill={colour}
      />
      <path
        d="M28.0273 28.163C27.7166 28.163 27.4648 27.9113 27.4648 27.6005V24.0352C27.4648 23.7244 27.7166 23.4727 28.0273 23.4727C28.3381 23.4727 28.5899 23.7244 28.5899 24.0352V27.6005C28.5899 27.9112 28.338 28.163 28.0273 28.163Z"
        fill={colour}
      />
      <path
        d="M28.0273 22.028C27.7166 22.028 27.4648 21.7762 27.4648 21.4654V19.9392C27.4648 19.6284 27.7166 19.3767 28.0273 19.3767C28.3381 19.3767 28.5899 19.6284 28.5899 19.9392V21.4654C28.5899 21.7762 28.338 22.028 28.0273 22.028Z"
        fill={colour}
      />
      <path
        d="M22.9201 24.1437C22.7762 24.1437 22.6323 24.0888 22.5223 23.9789L21.443 22.8996C21.2234 22.68 21.2234 22.3238 21.443 22.1042C21.6626 21.8846 22.0189 21.8846 22.2385 22.1042L23.3178 23.1835C23.5374 23.4031 23.5374 23.7593 23.3178 23.9789C23.2079 24.0888 23.064 24.1437 22.9201 24.1437Z"
        fill={colour}
      />
      <path
        d="M20.8046 29.251H19.2783C18.9675 29.251 18.7158 28.9993 18.7158 28.6885C18.7158 28.3777 18.9675 28.126 19.2783 28.126H20.8046C21.1154 28.126 21.3671 28.3777 21.3671 28.6885C21.3671 28.9993 21.1151 29.251 20.8046 29.251Z"
        fill={colour}
      />
      <path
        d="M21.8408 35.4374C21.6968 35.4374 21.5527 35.3825 21.443 35.2726C21.2234 35.053 21.2234 34.6968 21.443 34.4772L22.5223 33.3981C22.742 33.1785 23.0982 33.1785 23.3178 33.3981C23.5374 33.6177 23.5374 33.974 23.3178 34.1936L22.2385 35.2726C22.1286 35.3825 21.9847 35.4374 21.8408 35.4374Z"
        fill={colour}
      />
      <path
        d="M28.0273 37.9999C27.7166 37.9999 27.4648 37.7482 27.4648 37.4374V35.9111C27.4648 35.6003 27.7166 35.3486 28.0273 35.3486C28.3381 35.3486 28.5899 35.6003 28.5899 35.9111V37.4374C28.5899 37.7482 28.338 37.9999 28.0273 37.9999Z"
        fill={colour}
      />
      <path
        d="M34.2139 35.4374C34.07 35.4374 33.9261 35.3825 33.8161 35.2726L32.7368 34.1936C32.5172 33.974 32.517 33.6177 32.7368 33.3981C32.9564 33.1785 33.3127 33.1785 33.5323 33.3981L34.6116 34.4772C34.8312 34.6968 34.8314 35.053 34.6116 35.2726C34.5019 35.3825 34.3577 35.4374 34.2139 35.4374Z"
        fill={colour}
      />
      <path
        d="M36.7762 29.251H35.25C34.9392 29.251 34.6875 28.9993 34.6875 28.6885C34.6875 28.3777 34.9392 28.126 35.25 28.126H36.7762C37.087 28.126 37.3387 28.3777 37.3387 28.6885C37.3387 28.9993 37.087 29.251 36.7762 29.251Z"
        fill={colour}
      />
      <path
        d="M33.1345 24.1437C32.9905 24.1437 32.8467 24.0888 32.7367 23.9789C32.5171 23.7593 32.5171 23.4031 32.7367 23.1835L33.816 22.1042C34.0357 21.8846 34.3919 21.8846 34.6115 22.1042C34.8311 22.3238 34.8311 22.68 34.6115 22.8996L33.5322 23.9789C33.4222 24.0888 33.2784 24.1437 33.1345 24.1437Z"
        fill={colour}
      />
    </svg>
  )
}

ScheduleIcon.defaultProps = {
  colour: '#000000',
}

ScheduleIcon.propTypes = {
  colour: PropTypes.string,
}

export default ScheduleIcon
