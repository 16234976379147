import Modal from '../../global-components/elements/Modal'
import { ReactComponent as CloseModalIcon } from '../../../assets/icons/close-icon.svg'
import { ReactComponent as WarningIcon } from '../../../assets/icons/alert-triangle-icon.svg'
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

const FindReplaceConfirmModal = ({
  isOpen,
  onRequestClose,
  onSubmit,
  find,
  replacement,
  location,
  type = 'text',
}) => {
  const [checked, setChecked] = useState(false)
  useEffect(() => {
    setChecked(false)
  }, [isOpen])

  return (
    <Modal
      modalClassName="modal--sm"
      isOpen={isOpen}
      onRequestClose={() => onRequestClose()}
    >
      <div className="modal__header">
        <h3 className="flex align-center">
          <WarningIcon />
          This Action Cannot Be Undone
        </h3>

        <button className="modal__close" onClick={() => onRequestClose()}>
          <CloseModalIcon />
        </button>
      </div>
      <div className="modal__body">
        {type === 'internalLink' ? (
          <p>
            You are replacing a link to {find} in {location} with a link to{' '}
            {replacement}.
            <br />
            <br />
            This change cannot be undone, however, you can complete a subsequent
            find and replace action to reverse your change.
          </p>
        ) : (
          <p>
            You are replacing {find} in {location} with {replacement}.
            <br />
            <br />
            This change cannot be undone, however, you can complete a subsequent
            find and replace action to reverse your change.
          </p>
        )}
      </div>
      <div className="modal__submit">
        <div className="flex flex-fill align-center">
          <label className="checkbox">
            <input
              type="checkbox"
              checked={checked}
              onChange={e => {
                setChecked(e.target.checked)
              }}
            />
            <span className="label__content">
              Don&apos;t show this warning again
            </span>
          </label>
        </div>
        <div>
          <button
            className="btn btn--transparent"
            onClick={() => onRequestClose()}
          >
            Cancel
          </button>
          <button
            className="btn btn--dark-green"
            onClick={() => onSubmit(checked)}
          >
            Replace
          </button>
        </div>
      </div>
    </Modal>
  )
}

FindReplaceConfirmModal.propTypes = {
  isOpen: PropTypes.bool,
  onRequestClose: PropTypes.func,
  onSubmit: PropTypes.func,
  find: PropTypes.string,
  replacement: PropTypes.string,
  location: PropTypes.string,
  type: PropTypes.string,
}

export default FindReplaceConfirmModal
