import axios from 'axios'
import { get, isEmpty } from 'lodash'
import { fetchWorkflow } from '../workflows/workflow'

export const UPDATE_STEP_BEGIN = 'UPDATE_STEP_BEGIN'
export const UPDATE_STEP_ERROR = 'UPDATE_STEP_ERROR'
export const UPDATE_STEP_SUCCESS = 'UPDATE_STEP_SUCCESS'

export const updateStep =
  (workflowData, stepData, updatedStep, successEvent, redirectEvent, token) =>
  async (dispatch, getState) => {
    const activeClient = getState().userSelectionsData.activeClient
    const activeClientId = get(activeClient, 'id', null)
    const activeOperatingLocation =
      getState().userSelectionsData.activeOperatingLocation
    const stepId = get(stepData, '_id', null)
    const updatedStepSlug = get(updatedStep, 'slug', null)
    const workflowId = get(stepData, 'workflowId', null)
    const workflowSlug = get(workflowData, 'slug', null)

    dispatch({ type: UPDATE_STEP_BEGIN })

    return axios({
      url: `${process.env.REACT_APP_PRODUCT_API}/api/v1/workflow/step/update/${stepId}`,
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      data: {
        clientId: activeClientId,
        operatingLocation: activeOperatingLocation,
        step: updatedStep,
        workflowId: workflowId,
        workflowSlug: workflowSlug,
      },
    })
      .then(() => {
        dispatch({
          type: UPDATE_STEP_SUCCESS,
        })

        dispatch(fetchWorkflow(workflowSlug, token))
        successEvent()
        redirectEvent(updatedStepSlug)
      })
      .catch(error => {
        const customError = get(error, 'response.data.error', 'Error')

        dispatch({
          type: UPDATE_STEP_ERROR,
          payload: customError,
        })
      })
  }
