import { cloneDeep, get, set } from 'lodash'
import axios from 'axios'

async function updateReplaceText({
  entry,
  items,
  entries,
  activeClientId,
  token,
  replacementTerm,
  operatingLocation,
}) {
  if (entry) {
    const item = cloneDeep(items[entry.itemId])
    const otherEntries = entries.filter(
      search =>
        search.index !== entry.index &&
        search.itemId === entry.itemId &&
        search.path.join('.') === entry.path.join('.'),
    )
    const currentText = get(item, entry.path)
    let i = 0
    set(
      item,
      entry.path,
      currentText.replaceAll(entry.find, find => {
        if (i++ === entry.index) {
          return replacementTerm
        }
        return find
      }),
    )
    return axios({
      url: `${process.env.REACT_APP_PRODUCT_API}/api/v1/find-replace/update/text/${activeClientId}`,
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      data: {
        item,
        operatingLocation,
        replacementTerm,
        searchTerm: entry.find.source,
        totalResults: entries.length,
        type: entry.type,
      },
    }).then(res => {
      const newItems = cloneDeep(items)
      const newItem = res?.data?.item
      newItems[entry.itemId] = {
        ...newItem,
        breadcrumb: item.breadcrumb,
      }
      return {
        items: newItems,

        entries: entries.map(currentEntry => {
          if (currentEntry.id === entry.id) {
            return {
              ...currentEntry,
              replaced: true,
            }
          } else if (
            !!otherEntries.find(search => search.id === currentEntry.id)
          ) {
            if (
              currentEntry.index > entry.index &&
              [...replacementTerm.matchAll(entry.find)].length === 0
            ) {
              return {
                ...currentEntry,
                index: Math.max(0, currentEntry.index - 1),
                highlightIndex: Math.max(0, currentEntry.highlightIndex - 1),
              }
            }
          } else if (
            entry.itemId === currentEntry.itemId &&
            currentEntry.highlightIndex > entry.highlightIndex
          ) {
            return {
              ...currentEntry,
              highlightIndex: Math.max(0, currentEntry.highlightIndex - 1),
            }
          }
          return currentEntry
        }),
      }
    })
  }
}

export default updateReplaceText
