import React, { useEffect } from 'react'
import { get } from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import {
  toggleDuplicateWorkflowModal,
  setDuplicatedContentData,
  selectDuplicatedWorkflowVisibility,
  selectDuplicatedContentData,
  selectActiveClientId,
  selectActiveOperatingLocation,
  setManagedContentId,
  toggleManageWorkflowModal,
  selectManageWorkflowModalVisibility,
} from '../../../../redux/user/userSelections'
import {
  resetDuplicateContent,
  selectDuplicateContentState,
} from '../../../../redux/duplicate/duplicateContentReducers'
import {
  fetchWorkflows,
  selectWorkflowsState,
} from '../../../../redux/workflows/workflows'
import { fetchSanitizedWorkflow } from '../../../../redux/duplicate/fetchSanitizedWorkflow'
import Modal from '../../../global-components/elements/Modal'
import MaterialLoader from '../../../global-components/elements/MaterialLoader'
import useAuthToken from '../../../../hooks/useAuthToken'
import DuplicateWorkflowForm from './DuplicateWorkflowForm'
import { ReactComponent as CloseModalIcon } from '../../../../assets/icons/close-icon.svg'

function DuplicateWorkflowModal() {
  const { token } = useAuthToken({})
  const dispatch = useDispatch()
  const activeClientId = useSelector(selectActiveClientId)
  const activeOperatingLocation = useSelector(selectActiveOperatingLocation)
  const workflowsState = useSelector(selectWorkflowsState)
  const isDuplicateModalOpen = useSelector(selectDuplicatedWorkflowVisibility)
  const userDuplicatedContentData = useSelector(selectDuplicatedContentData)
  const duplicateContentState = useSelector(selectDuplicateContentState)
  const isManageWorkflowModalVisible = useSelector(
    selectManageWorkflowModalVisibility,
  )
  const duplicatedWorkflowLoading = get(duplicateContentState, 'loading', false)
  const workflows = get(workflowsState, 'workflows', [])
  const workflowsByClient = get(
    workflows,
    `${activeOperatingLocation}-${activeClientId}`,
    [],
  )
  const userDuplicatedWorkflowId = get(
    userDuplicatedContentData,
    'workflowId',
    null,
  )
  const userDuplicatedWorkflowData = workflowsByClient.find(workflow => {
    const workflowId = get(workflow, '_id', null)
    return workflowId === userDuplicatedWorkflowId
  })
  const userDuplicatedWorkflowSlug = get(
    userDuplicatedWorkflowData,
    'slug',
    null,
  )
  const duplicatedWorkflow = get(
    duplicateContentState,
    'content.workflow',
    null,
  )
  const duplicatedWorkflowName = get(duplicatedWorkflow, 'name', null)

  const handleCloseDuplicateModal = () => {
    dispatch(resetDuplicateContent())
    dispatch(toggleDuplicateWorkflowModal(!isDuplicateModalOpen))

    setDuplicatedContentData(null)
  }

  const handleDuplicateModalSuccess = () => {
    handleCloseDuplicateModal()
  }

  useEffect(() => {
    if (token) {
      dispatch(fetchSanitizedWorkflow(userDuplicatedWorkflowSlug, token))
    }
  }, [userDuplicatedWorkflowSlug, token])

  return (
    <Modal
      modalClassName="modal--duplicate-workflow modal--sm"
      isOpen={isDuplicateModalOpen}
      onRequestClose={() => handleCloseDuplicateModal()}
    >
      <div className="modal__header">
        <h3>Duplicate Workflow</h3>

        <button
          className="modal__close"
          onClick={() =>
            dispatch(toggleDuplicateWorkflowModal(!isDuplicateModalOpen))
          }
        >
          <CloseModalIcon />
        </button>
      </div>

      {(!duplicatedWorkflowName && duplicatedWorkflowLoading) ||
      duplicatedWorkflowLoading ? (
        <MaterialLoader containerClasses="inline-loader" />
      ) : duplicatedWorkflowName ? (
        <>
          <div className="modal__body">
            <p className="warning warning--blue">
              This action will duplicate the{' '}
              <strong>{duplicatedWorkflowName}</strong> workflow and all of
              it&apos;s existing content
            </p>

            <div className="modal__body-group">
              <DuplicateWorkflowForm
                closeFormEvent={handleDuplicateModalSuccess}
              />
            </div>
          </div>
        </>
      ) : (
        'Error fetching workflow - please wait a moment and try again'
      )}
    </Modal>
  )
}

export default DuplicateWorkflowModal
