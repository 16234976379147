import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import routes from '../../../../configuration/routes'
import {
  TOGGLE_MANAGE_WORKFLOW_MODAL,
  TOGGLE_MEDIA_LIBRARY_MODAL,
} from '../../../../redux/user/userSelections'
import { ReactComponent as HomeIcon } from '../../../../assets/icons/home-icon.svg'
import { ReactComponent as DataflowIcon } from '../../../../assets/icons/dataflow-icon.svg'
import { ReactComponent as MagicWandIcon } from '../../../../assets/icons/magic-wand-icon.svg'
import { ReactComponent as SettingsIcon } from '../../../../assets/icons/settings-icon.svg'
import { ReactComponent as AnnouncementIcon } from '../../../../assets/icons/announcement-icon.svg'
import { ReactComponent as ImageIcon } from '../../../../assets/icons/image-icon-no-border.svg'
import { ReactComponent as RepeatIcon } from '../../../../assets/icons/repeat-icon.svg'
import { ReactComponent as ArrowRight } from '../../../../assets/icons/arrow-right-double-chevron.svg'
import { ReactComponent as UnlockIcon } from '../../../../assets/icons/unlock-icon.svg'
import '../../../../styles/consumer-flow/platform-navigation/platform-navigation.scss'
import validateUserManagementAccess from '../../../../utilities/permissions/validateUserManagementAccess'
import { selectUserState } from '../../../../redux/user/user'
import { get } from 'lodash'

function PlatformNavigation({ isNavigationOpen, onToggleClick }) {
  const dispatch = useDispatch()
  const userState = useSelector(selectUserState)
  const userAccessLevel = get(userState, 'user.accessLevel', 'User')
  const managementRoles = ['Administrator', 'Manager', 'Super', 'Edit']
  const canEdit = managementRoles.includes(userAccessLevel)

  function onMediaLibraryClick() {
    dispatch({ type: TOGGLE_MEDIA_LIBRARY_MODAL, payload: true })
  }

  function onCreateNewClick() {
    dispatch({ type: TOGGLE_MANAGE_WORKFLOW_MODAL, payload: true })
  }

  return (
    <div className={`platform-navigation ${isNavigationOpen ? 'open' : ''}`}>
      <button
        className="platform-navigation__indicator"
        onClick={onToggleClick}
      >
        <ArrowRight />
      </button>

      <div className="platform-navigation__content">
        <h4>Workflows </h4>

        <div className="platform-navigation__links">
          <Link className="platform-navigation__link" to={routes.dashboard}>
            <HomeIcon />
            Home
          </Link>
          <Link
            className="platform-navigation__link"
            to={routes.browseWorkflows}
          >
            <DataflowIcon />
            Browse
          </Link>
          {canEdit ? (
            <button
              className="platform-navigation__link"
              onClick={onCreateNewClick}
            >
              <MagicWandIcon className="platform-navigation__link-wand-icon" />
              Create New
            </button>
          ) : null}

          {canEdit ? (
            <Link
              className="platform-navigation__link"
              to={routes.viewMyWorkflows}
            >
              <SettingsIcon />
              Manage Workflows
            </Link>
          ) : null}

          {canEdit ? (
            <Link className="platform-navigation__link" to={routes.findReplace}>
              <RepeatIcon />
              Find & Replace
            </Link>
          ) : null}

          {canEdit ? (
            <button
              className="platform-navigation__link"
              onClick={onMediaLibraryClick}
            >
              <ImageIcon />
              Media Library
            </button>
          ) : null}

          {canEdit ? (
            <Link
              className="platform-navigation__link"
              to={routes.manageContentLocks}
            >
              <UnlockIcon />
              Content Locks
            </Link>
          ) : null}

          <Link className="platform-navigation__link" to={routes.whatsNew}>
            <AnnouncementIcon />
            What&apos;s New
          </Link>
        </div>
      </div>
    </div>
  )
}

PlatformNavigation.propTypes = {
  isNavigationOpen: PropTypes.bool,
  onToggleClick: PropTypes.func,
}

export default PlatformNavigation
