import React from 'react'
import { get } from 'lodash'
import { useNavigate, useParams } from 'react-router-dom'
import routes from '../../../../configuration/routes'
import { ReactComponent as PreviousIcon } from '../../../../assets/icons/double-chevron-left.svg'
import { ReactComponent as NextIcon } from '../../../../assets/icons/double-chevron-right.svg'
import PropTypes from 'prop-types'

function BrowseWorkflowStepNavigation({ workflowSteps }) {
  const { category, workflow, step } = useParams()
  const navigate = useNavigate()
  const activeStepIndex = workflowSteps.findIndex(
    activeStep => activeStep?.slug === step,
  )
  const basePath = `${routes.browseWorkflows}/${category}/${workflow}`
  const previousStepContent = workflowSteps[activeStepIndex - 1]
  const previousStepSlug = get(previousStepContent, 'slug', null)
  const nextStepContent = workflowSteps[activeStepIndex + 1]
  const nextStepSlug = get(nextStepContent, 'slug', null)

  return (
    <div className="browse-workflow__step-navigation">
      <button
        disabled={!previousStepSlug}
        onClick={() => navigate(`${basePath}/${previousStepSlug}`)}
        className="btn btn--white previous"
      >
        <PreviousIcon />
        Go To Prev Step
      </button>

      <button
        disabled={!nextStepSlug}
        onClick={() => navigate(`${basePath}/${nextStepSlug}`)}
        className="btn btn--white next"
      >
        Go to Next Step <NextIcon />
      </button>
    </div>
  )
}

BrowseWorkflowStepNavigation.propTypes = {
  workflowSteps: PropTypes.array,
}

export default BrowseWorkflowStepNavigation
