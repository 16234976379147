import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectLiveChatVisibility,
  toggleLiveChat,
} from '../../../redux/user/userSelections'

function UserLicenseError() {
  const dispatch = useDispatch()
  const isLiveChatVisible = useSelector(selectLiveChatVisibility)
  const toggleLiveChatEvent = () => {
    dispatch(toggleLiveChat(!isLiveChatVisible))
  }

  return (
    <div className="section">
      <div className="container">
        <h3>User License Error</h3>
        <p>
          This account is not a licensed to use the{' '}
          <strong>QuickFacts Workflows Platform</strong> - for further
          assistance please contact your management team.
        </p>

        <button
          className="btn btn--inline btn--white"
          onClick={() =>
            window.location.replace('https://app.quickfactsinc.com')
          }
        >
          Return to Dashboard
        </button>

        <button
          className="btn btn--white"
          onClick={() => toggleLiveChatEvent()}
        >
          Contact QuickFacts Support
        </button>
      </div>
    </div>
  )
}

UserLicenseError.propTypes = {}

export default UserLicenseError
