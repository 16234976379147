import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { Formik, Form, Field } from 'formik'
import * as Yup from 'yup'
import { get, isBoolean, orderBy } from 'lodash'
import { useNavigate } from 'react-router-dom'
import { selectUserClients, selectUserState } from '../../../../redux/user/user'
import {
  selectActiveClientId,
  selectActiveOperatingLocation,
  selectManageWorkflowModalVisibility,
  setActiveClient,
  setManagedContentId,
  toggleDuplicateWorkflowModal,
  toggleManageWorkflowModal,
} from '../../../../redux/user/userSelections'
import { fetchWorkflows } from '../../../../redux/workflows/workflows'
import { fetchCategories } from '../../../../redux/categories/categories'
import { duplicateWorkflow } from '../../../../redux/workflows/duplicateWorkflow'
import { duplicateCrossClientWorkflow } from '../../../../redux/workflows/duplicateCrossClientWorkflow'
import { selectDuplicateContentState } from '../../../../redux/duplicate/duplicateContentReducers'
import routes from '../../../../configuration/routes'
import useAuthToken from '../../../../hooks/useAuthToken'
import validateWorkflowSlug from '../../../../utilities/slug/validateWorkflowSlug'
import TextField from '../../../forms/elements/TextField'
import SlugField from '../../../forms/elements/SlugField'
import CustomSelect from '../../../forms/elements/CustomSelect'

function DuplicateWorkflowForm({ closeFormEvent }) {
  const { token } = useAuthToken({})
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [lastValidatedSlug, setLastValidatedSlug] = useState('')
  const [currentSlugValidity, setCurrentSlugValidity] = useState(null)
  const userClients = useSelector(selectUserClients)
  const duplicateContentState = useSelector(selectDuplicateContentState)
  const activeClientId = useSelector(selectActiveClientId)
  const activeOperatingLocation = useSelector(selectActiveOperatingLocation)
  const userState = useSelector(selectUserState)
  const isManageWorkflowModalVisible = useSelector(
    selectManageWorkflowModalVisibility,
  )

  const user = get(userState, 'user', {})
  const duplicatedContent = get(duplicateContentState, 'content', {})
  const duplicatedWorkflow = get(duplicatedContent, 'workflow', null)
  const duplicatedWorkflowName = get(duplicatedWorkflow, 'name', null)
  const duplicatedWorkflowSlug = get(duplicatedWorkflow, 'slug', null)
  const duplicateWorkflowClientId = get(
    duplicatedWorkflow,
    'clientId[0]',
    activeClientId,
  )
  const initialDuplicatedSlug = `${duplicatedWorkflowSlug}-copy`

  useEffect(() => {
    setLastValidatedSlug(initialDuplicatedSlug)
  }, [duplicatedWorkflowSlug])

  const duplicateWorkflowSchema = Yup.object().shape({
    name: Yup.string().required('Workflow name required'),
    slug: Yup.string()
      .required('Workflow slug required')
      .test(
        'validateSlug',
        'The selected URL path is already in use for this client - please enter a unique path value',
        async value => {
          // Re-fire event only when the slug field had changed OR slug remains invalid
          if (
            (duplicatedWorkflowSlug &&
              lastValidatedSlug !== duplicatedWorkflowSlug) ||
            (value !== '' && value !== lastValidatedSlug) ||
            (isBoolean(currentSlugValidity) && currentSlugValidity === false)
          ) {
            setLastValidatedSlug(value)

            const slugExists = await validateWorkflowSlug(
              activeClientId,
              value,
              token,
            )
            setCurrentSlugValidity(slugExists)
            return slugExists
          }

          return true
        },
      ),
    clientId: Yup.string().required('Client required'),
    operatingLocations: Yup.array()
      .of(Yup.string())
      .min(1, 'Operating Location required'),
  })

  async function handleDuplicateWorkflow(values) {
    const slug = get(values, 'slug', null)
    const clientId = get(values, 'clientId', null)
    const isCrossClientDuplication = clientId !== duplicateWorkflowClientId

    const handleDuplicateWorkflowSuccess = async response => {
      const workflow = response?.data?.workflow
      if (isCrossClientDuplication) {
        const newActiveClient = userClients.find(
          client => client.id === clientId,
        )
        dispatch(toggleDuplicateWorkflowModal(false))
        dispatch(setActiveClient(newActiveClient))
        await dispatch(fetchCategories(token))
        await dispatch(fetchWorkflows(token))
        if (workflow?.operatingLocations?.includes(activeOperatingLocation)) {
          dispatch(setManagedContentId(workflow?._id))
          dispatch(toggleManageWorkflowModal(!isManageWorkflowModalVisible))
        }

        navigate(`${routes.viewMyWorkflows}`)
      } else {
        dispatch(fetchWorkflows(token)).then(() => {
          dispatch(setManagedContentId(workflow?._id))
          dispatch(toggleManageWorkflowModal(!isManageWorkflowModalVisible))
        })
        navigate(`${routes.manageWorkflows}/${slug}`)
      }

      closeFormEvent()
    }

    // Handle unique cross-client duplicate functionality & logic
    if (isCrossClientDuplication) {
      dispatch(
        duplicateCrossClientWorkflow(
          values,
          duplicatedContent,
          handleDuplicateWorkflowSuccess,
          token,
        ),
      )
    } else {
      dispatch(
        duplicateWorkflow(
          values,
          duplicatedContent,
          handleDuplicateWorkflowSuccess,
          token,
        ),
      )
    }
  }

  const initialFormValues = {
    name: duplicatedWorkflowName ? `${duplicatedWorkflowName} Copy` : '',
    slug: initialDuplicatedSlug ? initialDuplicatedSlug : '',
    clientId: activeClientId,
    operatingLocations: getOperatingLocations(activeClientId)?.map(
      op => op?.code,
    ),
  }

  function getOperatingLocations(clientId) {
    return (
      userClients.find(client => client.id === clientId)?.operatingLocations ||
      []
    )
  }

  return (
    <Formik
      initialValues={initialFormValues}
      validationSchema={duplicateWorkflowSchema}
      onSubmit={values => handleDuplicateWorkflow(values)}
    >
      {({ isValid, setFieldValue, setFieldTouched, values, errors }) => {
        const submitButtonDisabled = !isValid
        const orderedUserClients = orderBy(userClients, ['name'], ['asc'])
        const operatingLocations = getOperatingLocations(values.clientId)

        function onClientChange(value) {
          const availableOperatingLocations = getOperatingLocations(value)
          setFieldValue(
            'operatingLocations',
            availableOperatingLocations.map(op => op?.code),
          )
        }

        return (
          <Form className="form form--modal">
            <div className="form__field full">
              <Field
                component={TextField}
                label="Name"
                name="name"
                placeholder="Enter Workflow Name"
                required={true}
                type="text"
              />
            </div>

            <div className="form__field full">
              <Field
                component={SlugField}
                label="Path"
                name="slug"
                placeholder="Enter Workflow URL Path"
                required={true}
                type="text"
                onChange={e =>
                  setFieldValue(
                    'slug',
                    e.target.value
                      .replace(/\W+/g, '-')
                      .replace(/[^a-zA-Z0-9-]/g, '')
                      .toLowerCase(),
                  )
                }
              />
            </div>

            {userClients.length > 1 && (
              <div className="form__field full">
                <Field
                  component={CustomSelect}
                  label="Client"
                  name="clientId"
                  options={orderedUserClients.map(client => ({
                    label: client.name,
                    value: client.id,
                  }))}
                  required={true}
                  placeholder="Select Workflow Client"
                  setFieldTouched={setFieldTouched}
                  isClearable={false}
                  onChange={onClientChange}
                  defaultMenuIsOpen={true}
                />
              </div>
            )}

            {values.clientId !== activeClientId &&
              operatingLocations.length > 1 && (
                <div className="form__field full">
                  <Field
                    component={CustomSelect}
                    label="Operating Locations"
                    name="operatingLocations"
                    options={operatingLocations.map(location => ({
                      label: location.code,
                      value: location.code,
                    }))}
                    required={true}
                    placeholder="Select Operating Locations"
                    setFieldTouched={setFieldTouched}
                    isClearable={false}
                    isMulti={true}
                    defaultMenuIsOpen={true}
                  />
                </div>
              )}

            <div className="form__submit">
              <>
                <button
                  className="btn btn--white"
                  type="button"
                  onClick={() => closeFormEvent()}
                >
                  Cancel
                </button>

                <button
                  className="btn btn--dark-purple"
                  disabled={submitButtonDisabled}
                  type="submit"
                >
                  {submitButtonDisabled
                    ? 'Please ensure all field values are valid'
                    : 'Duplicate Workflow'}
                </button>
              </>
            </div>
          </Form>
        )
      }}
    </Formik>
  )
}

DuplicateWorkflowForm.propTypes = {
  closeFormEvent: PropTypes.func,
}

export default DuplicateWorkflowForm
