import React from 'react'
import PropTypes from 'prop-types'

function BookmarkIcon({ colour }, props) {
  return (
    <svg
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M33.5562 2H6.44373C5.86126 2 5.38904 2.47222 5.38904 3.05469V36.9453C5.38904 37.3719 5.64603 37.7565 6.04013 37.9197C6.43423 38.0829 6.88789 37.9927 7.18953 37.6911L20 24.8806L32.8104 37.6911C33.1121 37.9927 33.5657 38.0829 33.9598 37.9197C34.3539 37.7565 34.6109 37.3719 34.6109 36.9453V3.05469C34.6109 2.47222 34.1387 2 33.5562 2ZM32.5015 34.3991L20.7458 22.6433C20.5398 22.4374 20.2699 22.3344 20.0001 22.3344C19.7302 22.3344 19.4602 22.4374 19.2543 22.6433L7.49841 34.3991V4.10938H32.5015V34.3991Z"
        fill={colour}
      />
    </svg>
  )
}

BookmarkIcon.defaultProps = {
  colour: '#000000',
}

BookmarkIcon.propTypes = {
  colour: PropTypes.string,
}

export default BookmarkIcon
