import { Node } from '@tiptap/core'
import { findParentNode, mergeAttributes } from '@tiptap/react'
import { Plugin, PluginKey, TextSelection } from '@tiptap/pm/state'

import '../../../../../styles/editor-flow/tip-tap/tiptap-accordion.scss'
const arrowDownSvg = `
  <svg width="10" height="6" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M1 1L7 7L13 1" stroke="#000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
`
const TipTapAccordion = Node.create({
  name: 'accordion',
  group: 'block',
  defining: true,
  isolating: true,
  allowGapCursor: false,
  selectable: false,

  content: 'accordionHeader accordionContent',
  addNodeView() {
    return ({ HTMLAttributes }) => {
      const div = document.createElement('div')
      div.classList.add('tiptap-accordion')
      div.dataset.type = 'tiptap-accordion'
      return {
        dom: div,
        contentDOM: div,
      }
    }
  },

  parseHTML() {
    return [
      {
        tag: 'div[data-type="tiptap-accordion"]',
      },
    ]
  },

  renderHTML({ HTMLAttributes }) {
    return [
      'div',
      mergeAttributes(HTMLAttributes, { 'data-type': 'tiptap-accordion' }),
    ]
  },

  addCommands() {
    return {
      setAccordion:
        () =>
        ({ state, chain }) => {
          const { selection } = state
          const { $from, $to } = selection
          const range = $from.blockRange($to)
          const selectedContent = state.doc.slice(range.start, range.end)
          const contentJson = selectedContent.toJSON()
          const content = contentJson?.content || []
          return chain()
            .insertContentAt(
              {
                from: range.start,
                to: range.end,
              },
              {
                type: this.name,
                content: [
                  {
                    type: 'accordionHeader',
                  },
                  {
                    type: 'accordionContent',
                    content,
                  },
                ],
              },
            )
            .setTextSelection(range.start + 2)
            .run()
        },
    }
  },
})

const TipTapAccordionHeader = Node.create({
  name: 'accordionHeader',
  content: 'text*',
  defining: true,
  selectable: false,
  isolating: true,

  addStorage() {
    return {
      open: false,
    }
  },

  onUpdate({ editor }) {
    const { state } = editor
    const { selection } = state
    const node = findParentNode(node => node.type === this.type)(selection)
    if (node) {
      const domNode = editor.view.nodeDOM(node.pos)
      if (node?.node?.textContent === '') {
        domNode.classList.add('empty')
      } else {
        domNode.classList.remove('empty')
      }
    }
  },

  addNodeView() {
    return ({ node, editor }) => {
      const { open } = this.storage
      const container = document.createElement('div')
      container.dataset.type = 'accordion-header'
      container.classList.add('tiptap-accordion__header')
      if (open) {
        container.classList.add('open')
      }

      const clickHandler = () => {
        const newState = !container.classList.contains('open')
        this.storage.open = newState
        if (newState) {
          container.classList.add('open')
        } else {
          container.classList.remove('open')
        }
      }

      const button = document.createElement('button')
      button.type = 'button'
      button.classList.add('tiptap-accordion__button')
      button.innerHTML = arrowDownSvg
      button.addEventListener('mouseup', clickHandler)
      container.appendChild(button)

      const header = document.createElement('div')
      header.classList.add('tiptap-accordion__header-content')
      if (!editor.isEditable) {
        header.addEventListener('mouseup', clickHandler)
      }
      container.appendChild(header)

      if (!node.textContent) {
        header.classList.add('empty')
      }

      return {
        dom: container,
        contentDOM: header,
        ignoreMutation(e) {
          return e.type === 'selection'
            ? false
            : !header.contains(e.target) || header === e.target
        },
        update(e) {
          return e.type === this.type
        },
      }
    }
  },

  addKeyboardShortcuts() {
    return {
      ...this.parent?.(),
      Backspace: ({ editor }) => {
        const { state, view } = editor
        const { selection, schema } = state
        const { $anchor, empty } = selection
        const node = findParentNode(node => node.type === this.type)(selection)
        const accordionNode = findParentNode(
          node => node.type === schema.nodes.accordion,
        )(selection)
        const isAtStart = $anchor.parentOffset === 0
        if (!empty || !node) {
          return false
        }

        if (isAtStart && accordionNode) {
          const { pos, node } = accordionNode
          let tr = view.state.tr
          tr.delete(pos, pos + node.nodeSize)
          view.dispatch(tr)
        }
      },

      Enter: ({ editor }) => {
        const { state } = editor
        const { selection, doc } = state
        const node = findParentNode(node => node.type === this.type)(selection)
        if (node) {
          const accordionPosition = node.pos - 1
          const accordion = doc.nodeAt(accordionPosition)
          const newParagraphPosition =
            accordionPosition + accordion.nodeSize - 1
          return editor
            .chain()
            .insertContentAt(newParagraphPosition, {
              type: 'paragraph',
              content: [],
            })
            .setTextSelection(newParagraphPosition + 1)
            .run()
        }
      },
    }
  },

  parseHTML() {
    return [
      {
        tag: 'div[data-type="accordion-header"]',
      },
    ]
  },

  renderHTML({ HTMLAttributes }) {
    return [0]
    /* If you want to enable copying of the entire header, use the below instead of [0]
    [
      'div',
      mergeAttributes(HTMLAttributes, { 'data-type': 'accordion-header' }),
    ]*/
  },

  addProseMirrorPlugins() {
    return [
      new Plugin({
        key: new PluginKey('accordionHeaderSelection'),
        appendTransaction: (transactions, oldState, newState) => {
          const selection = transactions?.[0]?.curSelection || {}
          const { $anchor, $head } = selection
          const node = findParentNode(node => node.type === this.type)(
            selection,
          )

          if (
            node &&
            $head?.depth !== node.depth &&
            $head.pos - $anchor.pos < 5
          ) {
            const textSelection = new TextSelection($anchor)
            return newState.tr.setSelection(textSelection)
          }
        },
      }),
    ]
  },
})

const TipTapAccordionContent = Node.create({
  name: 'accordionContent',
  content: 'block+',
  defining: true,
  selectable: false,
  isolating: true,

  onUpdate({ editor }) {
    const { state } = editor
    const { selection } = state
    const node = findParentNode(node => node.type === this.type)(selection)
    if (node) {
      const domNode = editor.view.nodeDOM(node.pos)
      if (node?.node?.textContent === '') {
        domNode.classList.add('empty')
      } else {
        domNode.classList.remove('empty')
      }
    }
  },

  addNodeView() {
    return ({ node }) => {
      const div = document.createElement('div')
      div.dataset.type = 'accordion-content'
      div.classList.add('tiptap-accordion__content')
      if (!node.textContent) {
        div.classList.add('empty')
      }

      return {
        dom: div,
        contentDOM: div,
        ignoreMutation(e) {
          return e.type === 'selection'
            ? false
            : !div.contains(e.target) || div === e.target
        },
        update(e) {
          return e.type === this.type
        },
      }
    }
  },

  parseHTML() {
    return [
      {
        tag: 'div[data-type="accordion-content"]',
      },
    ]
  },

  renderHTML({ HTMLAttributes }) {
    return [0]
    /* If you want to enable copying of the entire accordion, use the below instead of [0]
    [
      'div',
      mergeAttributes(HTMLAttributes, { 'data-type': 'accordion-content' }),
      0,
    ]*/
  },
  addKeyboardShortcuts() {
    return {
      ...this.parent?.(),
      Enter: ({ editor }) => {
        let offset = 1
        const { state } = editor
        const { selection, doc } = state
        const { $from, empty } = selection
        const node = findParentNode(node => node.type === this.type)(selection)
        const isAtEnd = $from.parentOffset === $from.parent.nodeSize - 2
        const previousEmpty = $from.parent.textContent === ''
        const previousIsListItem =
          doc.nodeAt($from.pos - 2)?.type?.name === 'listItem'

        if (empty && node && isAtEnd && previousEmpty) {
          if (previousIsListItem) {
            return editor
              .chain()
              .joinBackward()
              .joinBackward()
              .insertContentAt($from.pos + offset, {
                type: 'paragraph',
                content: [],
              })
              .setTextSelection($from.pos + (offset + 1))
              .run()
          }
          return editor
            .chain()
            .joinBackward()
            .insertContentAt($from.pos + offset, {
              type: 'paragraph',
              content: [],
            })
            .setTextSelection($from.pos + (offset + 1))
            .run()
        }
      },
    }
  },
  addProseMirrorPlugins() {
    return [
      new Plugin({
        key: new PluginKey('accordionContentSelection'),
        appendTransaction: (transactions, oldState, newState) => {
          const selection = transactions?.[0]?.curSelection || {}
          const { $anchor, $head } = selection
          const node = findParentNode(node => node.type === this.type)(
            selection,
          )

          if (node && $head?.depth < node.depth) {
            const textSelection = new TextSelection($anchor)
            return newState.tr.setSelection(textSelection)
          }
        },
      }),
    ]
  },
})

export { TipTapAccordion, TipTapAccordionContent, TipTapAccordionHeader }
