import React from 'react'
import { get, isEmpty, uniq } from 'lodash'
import routes from '../../configuration/routes'
import convertStringToSlug from '../slug/convertStringToSlug'
import getIconComponent from '../icon/getIconComponent'

export default function formatWorkflowsToTableData(
  clientWorkflows,
  activeClientId,
) {
  return clientWorkflows.map(workflow => {
    const id = get(workflow, '_id', null)
    const name = get(workflow, 'name', null)
    const status = get(workflow, 'status', null)
    const rawCategories = get(workflow, 'categories', [])
    const categories = rawCategories.map(category => category?.category)
    const date = get(workflow, 'updatedAt', null)
    const slug = get(workflow, 'slug', null)
    const browsePath = !isEmpty(categories)
      ? `${routes.browseWorkflows}/${convertStringToSlug(
          categories[0],
        )}/${slug}`
      : ''
    const editPath = `${routes.manageWorkflows}/${slug}`
    const icon = get(workflow, 'icon', null)
    const iconColour = get(icon, 'colour', null) || undefined
    const Icon = getIconComponent(icon)
    let subCategories = []

    rawCategories.forEach(category => {
      const categorySubcategories = get(category, 'subcategories', [])

      if (!isEmpty(categorySubcategories)) {
        categorySubcategories.forEach(subCategory => {
          subCategories.push(subCategory)
        })
      }
    })

    return {
      name: name,
      status: status,
      categories: categories.join(', '),
      subcategories: uniq(subCategories).join(', '),
      dateModified: new Date(date),
      originalDateModified: date,
      configure: id,
      delete: id,
      duplicate: id,
      edit: editPath,
      view: browsePath,
      icon: Icon ? <Icon colour={iconColour} /> : null,
      canEdit: workflow?.clientId?.[0] === activeClientId,
    }
  })
}
