import React from 'react'
import { chain, get, isEmpty, uniq } from 'lodash'

const getCategoriesByWorkflows = workflows => {
  let workflowCategories = []

  if (!isEmpty(workflows)) {
    for (let i = 0; i < workflows.length; i++) {
      const workflow = workflows[i]

      const categories = get(workflow, 'categories', [])

      if (!isEmpty(categories)) {
        workflowCategories.push(...categories)
      }
    }
  }

  const uniqueCategories = chain(workflowCategories)
    .groupBy(category => {
      const categoryId = get(category, 'category', null)

      return categoryId
    })
    .map(function (value, key) {
      const iconData = get(value, '[0].icon', null)
      let subcategories = []

      value.forEach(categoryData => {
        const categorySubcategories = get(categoryData, 'subcategories', [])
        return subcategories.push(...categorySubcategories)
      })

      return {
        category: key,
        icon: iconData,
        subcategories: uniq(subcategories).sort(),
      }
    })
    .sortBy('category')
    .value()

  return uniq(uniqueCategories)
}

export default getCategoriesByWorkflows
