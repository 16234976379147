import React from 'react'
import PropTypes from 'prop-types'
import { isEmpty, get } from 'lodash'
import { Link } from 'react-router-dom'
import routes from '../../../../configuration/routes'
import validateUserManagementAccess from '../../../../utilities/permissions/validateUserManagementAccess'
import getIconComponent from '../../../../utilities/icon/getIconComponent'
import { ReactComponent as BackArrowIcon } from '../../../../assets/icons/back-arrow-icon.svg'
import { ReactComponent as ArrowRightIcon } from '../../../../assets/icons/arrow-right-icon.svg'
import { ReactComponent as PinIcon } from '../../../../assets/icons/pin-icon.svg'
import { ReactComponent as FilledPinIcon } from '../../../../assets/icons/pin-filled-icon.svg'
import '../../../../styles/consumer-flow/browse-workflows/browse-workflows-entries.scss'
import clsx from 'clsx'
import { useSelector } from 'react-redux'
import { selectCategoryLoadingPin } from '../../../../redux/categories/categories'
import MaterialLoader from '../../../global-components/elements/MaterialLoader'

function BrowseWorkflowsEntriesGrid({
  categorySlug,
  title,
  workflows,
  pinnedWorkflows,
  onPinToggle,
}) {
  const workflowsLoading = useSelector(selectCategoryLoadingPin)
  const isManager = validateUserManagementAccess()

  return (
    <div className="browse-workflows__entries">
      <div className="container">
        <h3>{title}</h3>

        {!isEmpty(workflows) ? (
          <div className="browse-workflows__entries-cards">
            {workflows.map((workflow, i) => {
              const name = get(workflow, 'name', null)
              const categories = get(workflow, 'categories', [])
              const activeCategoryData = categories.find(
                category =>
                  category?.category.replace(/\W+/g, '-').toLowerCase() ===
                  categorySlug,
              )
              const subcategories = get(activeCategoryData, 'subcategories', [])
              const slug = get(workflow, 'slug', null)
              const status = get(workflow, 'status', null)
              const icon = get(workflow, 'icon', null)
              const iconColour = get(icon, 'colour', null) || undefined
              const hasRequiredContent = slug && name
              const Icon = getIconComponent(icon)
              const isPinned = pinnedWorkflows?.some(
                workflowId => workflowId === workflow._id,
              )
              const isLoading = workflowsLoading[workflow._id]

              return (hasRequiredContent && isManager) ||
                (hasRequiredContent && !isManager && status === 'published') ? (
                <div
                  key={`workflows-card-${i}`}
                  className={clsx(
                    'browse-workflows__entries-card',
                    { tags: isManager },
                    { pinned: isPinned },
                  )}
                >
                  <div className="browse-workflows__entries-card-header">
                    <div className="browse-workflows__entries-card-content">
                      {!isEmpty(subcategories) ? (
                        <p className="browse-workflows__entries-card-subcategories">
                          {subcategories.join(', ')}
                        </p>
                      ) : null}

                      {name ? (
                        <p className="browse-workflows__entries-card-name">
                          {name}
                        </p>
                      ) : null}
                    </div>

                    <div className="browse-workflows__entries-card-status-container">
                      {isManager && status ? (
                        <div>
                          <div className={`status-flag lg ${status}`}>
                            {status}
                          </div>
                        </div>
                      ) : null}
                      {isManager ? (
                        <div>
                          <button
                            className={clsx(
                              'browse-workflows__entries-card-pin-button',
                              { active: isPinned },
                            )}
                            data-tooltip={
                              isPinned ? 'Unpin Workflow' : 'Pin Workflow'
                            }
                            onClick={() => onPinToggle(workflow, !isPinned)}
                          >
                            {isPinned ? <FilledPinIcon /> : <PinIcon />}
                          </button>
                        </div>
                      ) : null}
                      {!isManager && isPinned ? (
                        <div>
                          <FilledPinIcon />
                        </div>
                      ) : null}
                    </div>
                  </div>

                  {Icon ? (
                    <div className="browse-workflows__entries-card-icon">
                      <Icon colour={iconColour ? iconColour : null} />
                    </div>
                  ) : null}

                  <Link
                    className="btn btn--light-green"
                    to={`${routes.browseWorkflows}/${categorySlug}/${slug}`}
                  >
                    View <ArrowRightIcon />
                  </Link>
                  {isLoading ? (
                    <MaterialLoader containerClasses="overlay-loader" />
                  ) : null}
                </div>
              ) : null
            })}
          </div>
        ) : (
          <div className="browse-workflows__error">
            <p>
              0 results found for <strong>{categorySlug}</strong> - please alter
              your search criteria and try again.
            </p>

            <Link to={routes.dashboard} className="btn btn--white">
              <BackArrowIcon /> Workflows Dashboard
            </Link>
          </div>
        )}
      </div>
    </div>
  )
}

BrowseWorkflowsEntriesGrid.propTypes = {
  categorySlug: PropTypes.string,
  title: PropTypes.string,
  workflows: PropTypes.array,
  pinnedWorkflows: PropTypes.array,
  onPinToggle: PropTypes.func,
}

export default BrowseWorkflowsEntriesGrid
