// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.editor-content-component {
  position: relative;
}
.editor-content-component .content-subcomponent-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  gap: 20px;
}
.editor-content-component .content-subcomponent-container .content-subcomponent {
  min-height: 50px;
}`, "",{"version":3,"sources":["webpack://./src/styles/editor-flow/editor-components/editor-content-component.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;AACF;AACE;EACE,aAAA;EACA,eAAA;EACA,mBAAA;EACA,SAAA;AACJ;AACI;EACE,gBAAA;AACN","sourcesContent":[".editor-content-component {\n  position: relative;\n\n  .content-subcomponent-container {\n    display: flex;\n    flex-wrap: wrap;\n    flex-direction: row;\n    gap: 20px;\n\n    .content-subcomponent {\n      min-height: 50px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
