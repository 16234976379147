import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { get } from 'lodash'
import {
  selectActiveClientId,
  selectActiveOperatingLocation,
  selectManagedContentId,
  selectManageStepModalVisibility,
  toggleManageStepModal,
} from '../../../../../redux/user/userSelections'
import { selectWorkflowState } from '../../../../../redux/workflows/workflow'
import Modal from '../../../../global-components/elements/Modal'
import ManageWorkflowStepForm from '../../../../forms/ManageWorkflowStepForm'
import { ReactComponent as CloseModalIcon } from '../../../../../assets/icons/close-icon.svg'
import { emitSocketMessage } from '../../../../../redux/middleware/socket/socket'
import { selectSocketId } from '../../../../../redux/socket/socket'

function ManageWorkflowStepManageModal() {
  const { workflow } = useParams()
  const dispatch = useDispatch()
  const activeClientId = useSelector(selectActiveClientId)
  const activeOperatingLocation = useSelector(selectActiveOperatingLocation)
  const isManageStepModalVisible = useSelector(selectManageStepModalVisibility)
  const managedContentId = useSelector(selectManagedContentId)
  const workflowState = useSelector(selectWorkflowState)
  const socketId = useSelector(selectSocketId)
  const workflowOptions = get(workflowState, 'workflow', [])
  const workflowByClientSlug = get(
    workflowOptions,
    `[${activeOperatingLocation}-${activeClientId}-${workflow}]`,
    {},
  )
  const workflowByClientSlugSteps = get(workflowByClientSlug, 'steps', [])
  const managedStep = workflowByClientSlugSteps.find(workflowStep => {
    const workflowStepId = get(workflowStep, '_id', null)
    return workflowStepId === managedContentId
  })

  useEffect(() => {
    if (managedContentId && socketId) {
      dispatch(
        emitSocketMessage('configureStep', {
          stepId: managedContentId,
        }),
      )
    }
  }, [managedContentId, socketId])

  function handleToggleManageStepModal() {
    dispatch(
      emitSocketMessage('configureStepComplete', {
        stepId: managedContentId,
      }),
    )
    dispatch(toggleManageStepModal(!isManageStepModalVisible))
  }

  return (
    <Modal
      modalClassName="modal--manage-workflow modal--md"
      isOpen={isManageStepModalVisible}
      onRequestClose={() => handleToggleManageStepModal()}
    >
      <div className="modal__header">
        <h3>{managedStep ? 'Configure' : 'Create'} Step</h3>

        <button
          className="modal__close"
          onClick={() => handleToggleManageStepModal()}
        >
          <CloseModalIcon />
        </button>
      </div>

      <div className="modal__body">
        <ManageWorkflowStepForm
          formCancelEvent={handleToggleManageStepModal}
          managedStep={managedStep}
        />
      </div>
    </Modal>
  )
}

export default ManageWorkflowStepManageModal
