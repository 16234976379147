import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import TipTapEditor from '../../editor-flow/base-fields/TipTap/TipTapEditor'
import { cloneDeep, set } from 'lodash'
import openAccordions from '../../../utilities/find-replace/openAccordions'

function RichText({ field, highlight }) {
  const ref = useRef()
  const mutableField = cloneDeep(field)
  if (highlight?.type === 'link') {
    set(mutableField, [...highlight.path, 'class'], 'find-replace__highlight')
  }

  useEffect(() => {
    handleOnCreated()
  }, [highlight, ref.current?.innerText])

  function handleOnCreated() {
    const element = ref?.current
    const hasReplaced = !!element?.querySelector('.find-replace__highlight')
    if (element) {
      if (highlight?.type === 'text' && element?.innerText && !hasReplaced) {
        let i = 0
        element.innerHTML = element.innerHTML.replaceAll(
          highlight.find,
          find => {
            if (i++ === highlight.highlightIndex) {
              return `<span class="find-replace__highlight">${find}</span>`
            }
            return find
          },
        )

        const highlightedText = [
          ...element.querySelectorAll('.find-replace__highlight'),
        ]?.[0]
        if (highlightedText) {
          const accordion = highlightedText.closest('.tiptap-accordion')
          openAccordions({ accordion })
        }
      }
      if (highlight?.type === 'link') {
        const highlightedLink = [
          ...element.querySelectorAll('.find-replace__highlight'),
        ]?.[0]
        if (highlightedLink) {
          const accordion = highlightedLink.closest('.tiptap-accordion')
          openAccordions({ accordion })
        }
      }
    }
  }

  return (
    <div className="rich-text" ref={ref}>
      <TipTapEditor
        data={mutableField?.blocks}
        readOnly
        onCreated={handleOnCreated}
      />
    </div>
  )
}

RichText.propTypes = {
  field: PropTypes.object,
  highlight: PropTypes.object,
}

export default RichText
