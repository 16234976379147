import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'

function useClickAwayListener(ref, toggleEvent) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        toggleEvent()
      }
    }

    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [ref, toggleEvent])
}

export default function ClickAwayListener({ children, toggleEvent }) {
  const wrapperRef = useRef(null)
  useClickAwayListener(wrapperRef, toggleEvent)

  return (
    <div ref={wrapperRef} className="u-click-away-listener">
      {children}
    </div>
  )
}

ClickAwayListener.propTypes = {
  children: PropTypes.node,
  toggleEvent: PropTypes.func.isRequired,
}
