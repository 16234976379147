//./components/Editor
import React, { memo, useEffect, useRef } from 'react'
import EditorJS from '@editorjs/editorjs'
import Undo from 'editorjs-undo'
import DragDrop from 'editorjs-drag-drop'
import PropTypes from 'prop-types'

const EditorJSField = ({
  data,
  onChange = () => {},
  onReady = () => {},
  tools,
  placeholder,
  defaultBlock = 'paragraph',
  zIndex,
}) => {
  const ref = useRef()
  const containerRef = useRef()
  const changeRef = useRef(onChange)
  const idRef = useRef(crypto.randomUUID())

  useEffect(() => {
    if (!ref.current) {
      const editor = new EditorJS({
        holder: idRef.current,
        tools,
        data,
        placeholder,
        minHeight: 0,
        defaultBlock,
        async onChange(api, event) {
          const data = await api.saver.save()
          changeRef.current(data)
        },
        onReady: () => {
          new Undo({ editor })
          new DragDrop(editor)
          onReady(editor)
          const element = containerRef.current.querySelector('.codex-editor')
          element.style.zIndex = zIndex || 1
        },
      })

      ref.current = editor
    }

    return () => {
      if (ref?.current?.destroy) {
        ref.current.destroy()
        ref.current = null
      }
    }
  }, [])

  useEffect(() => {
    changeRef.current = onChange
  }, [onChange])

  /**
   * Manage active-focus without re-renders
   */
  useEffect(() => {
    const onDocumentClick = evt => {
      const closestContainer = evt.target.closest('.editor-js-container')
      if (closestContainer === containerRef.current) {
        containerRef.current.classList.add('active-focus')
      } else {
        containerRef.current.classList.remove('active-focus')
      }
    }
    document.addEventListener('click', onDocumentClick)
    return () => {
      document.removeEventListener('click', onDocumentClick)
    }
  }, [])

  return (
    <div
      id={idRef.current}
      ref={containerRef}
      className="editor-js-container"
    />
  )
}

EditorJSField.propTypes = {
  data: PropTypes.shape({}),
  onChange: PropTypes.func,
  onReady: PropTypes.func,
  tools: PropTypes.shape({}),
  placeholder: PropTypes.string,
  defaultBlock: PropTypes.string,
  zIndex: PropTypes.number,
}

export default EditorJSField
