import { get } from 'lodash'
import { selectUserEmail } from '../user/user'
import axios from 'axios'

export const SOCKET_SET_SOCKET_ID = 'SOCKET_SET_SOCKET_ID'
export const SOCKET_SET_BLOCKED_RESOURCES = 'SOCKET_SET_BLOCKED_RESOURCES'
export const SOCKET_SET_OUT_OF_DATE_RESOURCES =
  'SOCKET_SET_OUT_OF_DATE_RESOURCES'
export const SOCKET_SET_SESSION_EXPIRED = 'SOCKET_SET_SESSION_EXPIRED'

const initialState = {
  socketId: null,
  blockedResources: [],
  outOfDateResources: [],
  sessionExpired: null,
}

export default function socketReducer(state = initialState, action) {
  switch (action.type) {
    case SOCKET_SET_SOCKET_ID:
      return { ...state, socketId: action.payload }
    case SOCKET_SET_BLOCKED_RESOURCES:
      return { ...state, blockedResources: action.payload }
    case SOCKET_SET_OUT_OF_DATE_RESOURCES:
      return { ...state, outOfDateResources: action.payload }
    case SOCKET_SET_SESSION_EXPIRED:
      return { ...state, sessionExpired: action.payload }
    default:
      return state
  }
}

export const selectSocketId = state => get(state, 'socketData.socketId')
export const selectSocketBlockedResources = state =>
  get(state, 'socketData.blockedResources', [])

export const selectSocketOutOfDateResources = state =>
  get(state, 'socketData.outOfDateResources', [])
export const selectSocketSessionExpired = state =>
  get(state, 'socketData.sessionExpired')

export const selectSocketResourceBlockedBy = ({ state, type, id }) => {
  const blockedResources = selectSocketBlockedResources(state)
  const email = selectUserEmail(state)
  return blockedResources.find(
    data => data.id === id && data.type === type && data.email !== email,
  )
}

export const selectSocketResourceOutOfDate = ({ state, type, id }) => {
  const outOfDateResources = selectSocketOutOfDateResources(state)
  const socketId = selectSocketId(state)
  return outOfDateResources.find(
    data => data.id === id && data.type === type && data.socketId !== socketId,
  )
}

export const fetchLockedContent = ({ token, clientId }) => {
  return axios({
    url: `${process.env.REACT_APP_PRODUCT_API}/api/v1/sockets`,
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    data: {
      clientId,
    },
  })
}

export const unlockContentLock = ({ token, clientId, lock }) => {
  return axios({
    url: `${process.env.REACT_APP_PRODUCT_API}/api/v1/sockets/unlock/${lock?.socketId}`,
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    data: {
      clientId,
      lockId: lock?.id,
    },
  })
}
