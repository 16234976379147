// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.view-my-workflows {
  height: calc(100vh - 40px);
}

.view-my-workflows__table {
  display: flex;
  flex-wrap: wrap;
  margin: 20px 0 0 0;
  width: 100%;
}
.view-my-workflows__table .table__container {
  max-height: calc(100vh - 340px);
}
.view-my-workflows__table .icon {
  width: 5%;
  width: 6%;
  padding-right: clamp(4px, 1vw, 8px);
}
.view-my-workflows__table .icon svg {
  width: 45px;
  height: 45px;
}
@media screen and (min-width: 1480px) {
  .view-my-workflows__table .icon {
    width: 3%;
    padding-right: 8px;
  }
  .view-my-workflows__table .icon svg {
    width: 50px;
    height: 50px;
  }
}
.view-my-workflows__table .name {
  width: 20%;
}
.view-my-workflows__table .status {
  width: 8%;
}
.view-my-workflows__table .categories {
  width: 18%;
}
.view-my-workflows__table .subcategories {
  width: 18%;
}
.view-my-workflows__table .dateModified {
  width: 17%;
}
.view-my-workflows__table .edit,
.view-my-workflows__table .delete {
  text-align: right;
}
.view-my-workflows__table .edit button,
.view-my-workflows__table .delete button {
  display: inline-block;
}
.view-my-workflows__table .configure svg,
.view-my-workflows__table .duplicate svg,
.view-my-workflows__table .edit svg,
.view-my-workflows__table .delete svg {
  width: 18px;
  max-height: 18px;
}

.table__filters-content-filters .flex {
  display: flex;
}
.table__filters-content-filters .flex .custom-select {
  width: 150px;
}
@media screen and (min-width: 1920px) {
  .table__filters-content-filters .flex .custom-select {
    width: 200px;
  }
}`, "",{"version":3,"sources":["webpack://./src/styles/consumer-flow/view-my-workflows/view-my-workflows-table.scss"],"names":[],"mappings":"AAEA;EACE,0BAAA;AADF;;AAIA;EACE,aAAA;EACA,eAAA;EACA,kBAAA;EACA,WAAA;AADF;AAGE;EACE,+BAAA;AADJ;AAIE;EACE,SAAA;EACA,SAAA;EACA,mCAAA;AAFJ;AAII;EACE,WAAA;EACA,YAAA;AAFN;AAKI;EAVF;IAWI,SAAA;IACA,kBAAA;EAFJ;EAII;IACE,WAAA;IACA,YAAA;EAFN;AACF;AAME;EACE,UAAA;AAJJ;AAOE;EACE,SAAA;AALJ;AAQE;EACE,UAAA;AANJ;AASE;EACE,UAAA;AAPJ;AAUE;EACE,UAAA;AARJ;AAWE;;EAEE,iBAAA;AATJ;AAWI;;EACE,qBAAA;AARN;AAgBI;;;;EACE,WAAA;EACA,gBAAA;AAXN;;AAiBE;EACE,aAAA;AAdJ;AAgBI;EACE,YAAA;AAdN;AAgBM;EAHF;IAII,YAAA;EAbN;AACF","sourcesContent":["@import '../../configuration/variables';\n\n.view-my-workflows {\n  height: calc(100vh - 40px);\n}\n\n.view-my-workflows__table {\n  display: flex;\n  flex-wrap: wrap;\n  margin: 20px 0 0 0;\n  width: 100%;\n\n  .table__container {\n    max-height: calc(100vh - 340px);\n  }\n\n  .icon {\n    width: 5%;\n    width: clamp(5%, 6%, 8%);\n    padding-right: clamp(4px, 1vw, 8px);\n\n    svg {\n      width: 45px;\n      height: 45px;\n    }\n\n    @media screen and ($bp-min-xlr) {\n      width: clamp(2%, 3%, 4%);\n      padding-right: 8px;\n\n      svg {\n        width: 50px;\n        height: 50px;\n      }\n    }\n  }\n\n  .name {\n    width: 20%;\n  }\n\n  .status {\n    width: 8%;\n  }\n\n  .categories {\n    width: 18%;\n  }\n\n  .subcategories {\n    width: 18%;\n  }\n\n  .dateModified {\n    width: 17%;\n  }\n\n  .edit,\n  .delete {\n    text-align: right;\n\n    button {\n      display: inline-block;\n    }\n  }\n\n  .configure,\n  .duplicate,\n  .edit,\n  .delete {\n    svg {\n      width: 18px;\n      max-height: 18px;\n    }\n  }\n}\n\n.table__filters-content-filters {\n  .flex {\n    display: flex;\n\n    .custom-select {\n      width: 150px;\n\n      @media screen and ($bp-min-xxl) {\n        width: 200px;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
