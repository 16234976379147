import React from 'react'
import PropTypes from 'prop-types'

function AccountingIcon({ colour }, props) {
  return (
    <svg
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M15.52 20.0317H8.43048C8.13918 20.0317 7.90314 20.2678 7.90314 20.559C7.90314 20.8503 8.13918 21.0864 8.43048 21.0864H15.52C15.8113 21.0864 16.0474 20.8503 16.0474 20.559C16.0474 20.2678 15.8113 20.0317 15.52 20.0317Z"
        fill={colour}
      />
      <path
        d="M15.52 24.6311H8.43048C8.13918 24.6311 7.90314 24.8672 7.90314 25.1585C7.90314 25.4497 8.13918 25.6858 8.43048 25.6858H15.52C15.8113 25.6858 16.0474 25.4497 16.0474 25.1585C16.0474 24.8672 15.8113 24.6311 15.52 24.6311Z"
        fill={colour}
      />
      <path
        d="M15.52 29.2306H8.43048C8.13918 29.2306 7.90314 29.4667 7.90314 29.758C7.90314 30.0492 8.13918 30.2853 8.43048 30.2853H15.52C15.8113 30.2853 16.0474 30.0492 16.0474 29.758C16.0474 29.4667 15.8113 29.2306 15.52 29.2306Z"
        fill={colour}
      />
      <path
        d="M21.4341 23.9742H22.5533C22.8446 23.9742 23.0807 23.7381 23.0807 23.4469C23.0807 23.1556 22.8446 22.9195 22.5533 22.9195H21.4341C21.1428 22.9195 20.9068 23.1556 20.9068 23.4469C20.9068 23.7381 21.1429 23.9742 21.4341 23.9742Z"
        fill={colour}
      />
      <path
        d="M25.9534 22.9195H24.8341C24.5428 22.9195 24.3068 23.1556 24.3068 23.4469C24.3068 23.7381 24.5428 23.9742 24.8341 23.9742H25.9534C26.2447 23.9742 26.4807 23.7381 26.4807 23.4469C26.4807 23.1556 26.2446 22.9195 25.9534 22.9195Z"
        fill={colour}
      />
      <path
        d="M29.3533 22.9195H28.2341C27.9428 22.9195 27.7068 23.1556 27.7068 23.4469C27.7068 23.7381 27.9428 23.9742 28.2341 23.9742H29.3533C29.6446 23.9742 29.8807 23.7381 29.8807 23.4469C29.8807 23.1556 29.6446 22.9195 29.3533 22.9195Z"
        fill={colour}
      />
      <path
        d="M32.7534 22.9195H31.6342C31.3429 22.9195 31.1069 23.1556 31.1069 23.4469C31.1069 23.7381 31.3429 23.9742 31.6342 23.9742H32.7534C33.0447 23.9742 33.2807 23.7381 33.2807 23.4469C33.2807 23.1556 33.0446 22.9195 32.7534 22.9195Z"
        fill={colour}
      />
      <path
        d="M21.4341 26.7164H22.5533C22.8446 26.7164 23.0807 26.4803 23.0807 26.1891C23.0807 25.8978 22.8446 25.6617 22.5533 25.6617H21.4341C21.1428 25.6617 20.9068 25.8978 20.9068 26.1891C20.9068 26.4803 21.1429 26.7164 21.4341 26.7164Z"
        fill={colour}
      />
      <path
        d="M25.9534 25.6617H24.8341C24.5428 25.6617 24.3068 25.8978 24.3068 26.1891C24.3068 26.4803 24.5428 26.7164 24.8341 26.7164H25.9534C26.2447 26.7164 26.4807 26.4803 26.4807 26.1891C26.4807 25.8978 26.2446 25.6617 25.9534 25.6617Z"
        fill={colour}
      />
      <path
        d="M29.3533 25.6617H28.2341C27.9428 25.6617 27.7068 25.8978 27.7068 26.1891C27.7068 26.4803 27.9428 26.7164 28.2341 26.7164H29.3533C29.6446 26.7164 29.8807 26.4803 29.8807 26.1891C29.8807 25.8978 29.6446 25.6617 29.3533 25.6617Z"
        fill={colour}
      />
      <path
        d="M32.7534 25.6617H31.6342C31.3429 25.6617 31.1069 25.8978 31.1069 26.1891C31.1069 26.4803 31.3429 26.7164 31.6342 26.7164H32.7534C33.0447 26.7164 33.2807 26.4803 33.2807 26.1891C33.2807 25.8978 33.0446 25.6617 32.7534 25.6617Z"
        fill={colour}
      />
      <path
        d="M21.4341 29.4586H22.5533C22.8446 29.4586 23.0807 29.2225 23.0807 28.9312C23.0807 28.64 22.8446 28.4039 22.5533 28.4039H21.4341C21.1428 28.4039 20.9068 28.64 20.9068 28.9312C20.9068 29.2225 21.1429 29.4586 21.4341 29.4586Z"
        fill={colour}
      />
      <path
        d="M25.9534 28.4039H24.8341C24.5428 28.4039 24.3068 28.64 24.3068 28.9312C24.3068 29.2225 24.5428 29.4586 24.8341 29.4586H25.9534C26.2447 29.4586 26.4807 29.2225 26.4807 28.9312C26.4807 28.64 26.2446 28.4039 25.9534 28.4039Z"
        fill={colour}
      />
      <path
        d="M29.3533 28.4039H28.2341C27.9428 28.4039 27.7068 28.64 27.7068 28.9312C27.7068 29.2225 27.9428 29.4586 28.2341 29.4586H29.3533C29.6446 29.4586 29.8807 29.2225 29.8807 28.9312C29.8807 28.64 29.6446 28.4039 29.3533 28.4039Z"
        fill={colour}
      />
      <path
        d="M32.7534 28.4039H31.6342C31.3429 28.4039 31.1069 28.64 31.1069 28.9312C31.1069 29.2225 31.3429 29.4586 31.6342 29.4586H32.7534C33.0447 29.4586 33.2807 29.2225 33.2807 28.9312C33.2807 28.64 33.0446 28.4039 32.7534 28.4039Z"
        fill={colour}
      />
      <path
        d="M9.59366 14.7802C9.3501 14.6207 9.02314 14.6891 8.86367 14.9327C8.7042 15.1764 8.77255 15.5033 9.01632 15.6627C9.78821 16.1677 10.3094 16.3073 11.0011 16.3391V17.0489C11.0011 17.3402 11.2371 17.5763 11.5285 17.5763C11.8198 17.5763 12.0558 17.3402 12.0558 17.0489V16.2996C13.3943 16.0629 14.1759 14.9711 14.3473 13.9518C14.5641 12.6625 13.8891 11.5413 12.6279 11.0955C12.422 11.0227 12.2321 10.953 12.0558 10.8856V7.93649C12.569 8.03689 12.8763 8.29965 12.901 8.32124C13.1151 8.51572 13.4464 8.50138 13.643 8.28833C13.8405 8.07423 13.827 7.7406 13.6129 7.54316C13.5792 7.51201 13.0007 6.99177 12.0558 6.86858V6.23345C12.0558 5.94221 11.8198 5.7061 11.5285 5.7061C11.2371 5.7061 11.0011 5.94221 11.0011 6.23345V6.90915C10.8973 6.93031 10.7911 6.95696 10.6825 6.98966C9.8548 7.23899 9.23619 7.94809 9.068 8.84029C8.91514 9.65155 9.17347 10.4327 9.74237 10.8789C10.0581 11.1264 10.4536 11.3531 11.0011 11.5942V15.2842C10.4883 15.2563 10.1572 15.1488 9.59366 14.7802ZM12.0558 12.011C12.1276 12.037 12.2009 12.0633 12.2764 12.0899C13.4277 12.4969 13.3564 13.4841 13.3072 13.777C13.2076 14.3697 12.7818 15.0013 12.0558 15.216V12.011ZM10.3932 10.0489C10.1353 9.84674 10.0247 9.4584 10.1045 9.03554C10.1789 8.64074 10.4497 8.16121 10.9867 7.99942C10.9915 7.99794 10.9963 7.99682 11.0011 7.99541V10.4272C10.7527 10.2991 10.5541 10.1751 10.3932 10.0489Z"
        fill={colour}
      />
      <path
        d="M31.5995 34.8968C31.3082 34.8968 31.0721 35.1329 31.0721 35.4241V35.8012C31.0721 36.4321 30.5589 36.9454 29.9279 36.9454H6.17478C5.54379 36.9454 5.03058 36.4321 5.03058 35.8012V4.19889C5.03058 3.56797 5.54379 3.05469 6.17478 3.05469H24.6254C24.6811 3.05469 24.7361 3.0601 24.7905 3.06805V7.47757C24.7905 8.50251 25.6243 9.33635 26.6493 9.33635H31.0588C31.0667 9.39077 31.0721 9.44562 31.0721 9.50137V12.0188C31.0721 12.3101 31.3082 12.5462 31.5995 12.5462C31.8908 12.5462 32.1268 12.3101 32.1268 12.0188V9.50137C32.1268 8.89858 31.8382 8.30036 31.4939 7.95822C31.2775 7.74173 26.186 2.64969 26.1803 2.64406L26.1682 2.63218C26.1678 2.63176 26.1673 2.63127 26.1668 2.63085L26.1666 2.63056C26.1652 2.62916 26.1637 2.62782 26.1622 2.62635C25.7659 2.2392 25.2194 2 24.6254 2H6.17478C4.96231 2 3.97589 2.98642 3.97589 4.19889V35.8011C3.97589 37.0136 4.96231 38 6.17478 38H29.9279C31.1403 38 32.1268 37.0136 32.1268 35.8011V35.424C32.1268 35.1329 31.8907 34.8968 31.5995 34.8968ZM25.8452 3.80057C26.238 4.1934 29.7958 7.75108 30.3263 8.28166H26.6492C26.2058 8.28166 25.8451 7.92096 25.8451 7.47757V3.80057H25.8452Z"
        fill={colour}
      />
      <path
        d="M34.1934 13.9657H19.9941C18.9847 13.9657 18.1634 14.7869 18.1634 15.7964V17.5704C18.1634 17.8617 18.3994 18.0978 18.6907 18.0978C18.982 18.0978 19.2181 17.8617 19.2181 17.5704V15.7964C19.2181 15.3685 19.5662 15.0203 19.9941 15.0203H34.1934C34.6213 15.0203 34.9694 15.3685 34.9694 15.7964V31.6584C34.9694 32.0863 34.6213 32.4345 34.1934 32.4345H19.9941C19.5662 32.4345 19.2181 32.0863 19.2181 31.6584V20.0358C19.2181 19.7446 18.982 19.5085 18.6907 19.5085C18.3994 19.5085 18.1634 19.7446 18.1634 20.0358V31.6584C18.1634 32.6678 18.9847 33.4892 19.9941 33.4892H34.1934C35.2029 33.4892 36.0241 32.6679 36.0241 31.6584V15.7963C36.0241 14.7869 35.2029 13.9657 34.1934 13.9657Z"
        fill={colour}
      />
      <path
        d="M33.2807 17.4546C33.2807 16.9402 32.8622 16.5217 32.3478 16.5217H21.8397C21.3253 16.5217 20.9068 16.9402 20.9068 17.4546V20.1535C20.9068 20.6679 21.3253 21.0864 21.8397 21.0864H32.3478C32.8622 21.0864 33.2807 20.6679 33.2807 20.1535V17.4546ZM32.226 20.0317H21.9614V17.5764H32.226V20.0317H32.226Z"
        fill={colour}
      />
    </svg>
  )
}

AccountingIcon.defaultProps = {
  colour: '#000000',
}

AccountingIcon.propTypes = {
  colour: PropTypes.string,
}

export default AccountingIcon
