import React from 'react'
import PropTypes from 'prop-types'

function ComputerIcon({ colour }, props) {
  return (
    <svg
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M37.4727 8.66914C37.764 8.66914 38 8.43303 38 8.1418V5.71243C38 4.466 36.986 3.45203 35.7396 3.45203H4.26041C3.01405 3.45203 2 4.46607 2 5.71243V26.2951C2 27.5415 3.01405 28.5555 4.26041 28.5555H16.0113L14.8507 32.6982H12.7433C11.682 32.6982 10.8185 33.5617 10.8185 34.6231C10.8185 35.6845 11.682 36.548 12.7433 36.548H27.2565C28.3179 36.548 29.1814 35.6845 29.1814 34.6231C29.1814 33.5617 28.3179 32.6982 27.2565 32.6982H25.1491L23.9885 28.5555H35.7395C36.9859 28.5555 37.9999 27.5415 37.9999 26.2951V10.6072C37.9999 10.3159 37.7638 10.0798 37.4725 10.0798C37.1812 10.0798 36.9452 10.3159 36.9452 10.6072V23.7105H8.81152C8.52022 23.7105 8.28418 23.9466 8.28418 24.2379C8.28418 24.5291 8.52022 24.7652 8.81152 24.7652H36.9453V26.2952C36.9453 26.96 36.4045 27.5009 35.7396 27.5009H4.26041C3.59553 27.5009 3.05469 26.96 3.05469 26.2952V24.7652H6.34981C6.64112 24.7652 6.87716 24.5291 6.87716 24.2379C6.87716 23.9466 6.64112 23.7105 6.34981 23.7105H3.05469V5.71243C3.05469 5.04756 3.59553 4.50671 4.26041 4.50671H35.7395C36.4044 4.50671 36.9452 5.04763 36.9452 5.71243V8.1418C36.9453 8.43303 37.1814 8.66914 37.4727 8.66914ZM28.1268 34.6231C28.1268 35.1029 27.7364 35.4933 27.2566 35.4933H12.7434C12.2636 35.4933 11.8732 35.1029 11.8732 34.6231C11.8732 34.1433 12.2636 33.7529 12.7434 33.7529H27.2566C27.7364 33.7529 28.1268 34.1433 28.1268 34.6231ZM24.0539 32.6982H15.9461L17.1066 28.5555H22.8934L24.0539 32.6982Z"
        fill={colour}
      />
      <path
        d="M32.7148 8.84824H24.0767C23.7854 8.84824 23.5493 9.08435 23.5493 9.37558C23.5493 9.66681 23.7854 9.90292 24.0767 9.90292H32.7148C33.0061 9.90292 33.2422 9.66681 33.2422 9.37558C33.2422 9.08435 33.0061 8.84824 32.7148 8.84824Z"
        fill={colour}
      />
      <path
        d="M32.7148 12.0628H24.0767C23.7854 12.0628 23.5493 12.2989 23.5493 12.5902C23.5493 12.8814 23.7854 13.1175 24.0767 13.1175H32.7148C33.0061 13.1175 33.2422 12.8814 33.2422 12.5902C33.2422 12.2989 33.0061 12.0628 32.7148 12.0628Z"
        fill={colour}
      />
      <path
        d="M33.2421 15.8048C33.2421 15.5135 33.0061 15.2774 32.7148 15.2774H24.0767C23.7854 15.2774 23.5493 15.5135 23.5493 15.8048C23.5493 16.096 23.7854 16.3321 24.0767 16.3321H32.7148C33.0061 16.3321 33.2421 16.096 33.2421 15.8048Z"
        fill={colour}
      />
      <path
        d="M24.0767 18.4921C23.7854 18.4921 23.5493 18.7282 23.5493 19.0194C23.5493 19.3107 23.7854 19.5468 24.0767 19.5468H28.3957C28.6871 19.5468 28.9231 19.3107 28.9231 19.0194C28.9231 18.7282 28.6871 18.4921 28.3957 18.4921H24.0767Z"
        fill={colour}
      />
      <path
        d="M6.75793 14.1975C6.75793 18.2417 10.0481 21.5318 14.0923 21.5318C17.8931 21.5318 21.1248 18.6072 21.4065 14.7366C21.4065 14.7365 21.4065 14.7365 21.4065 14.7365C21.7166 10.4554 18.3189 6.86314 14.0923 6.86314C10.0481 6.86314 6.75793 10.1533 6.75793 14.1975ZM7.81262 14.1975C7.81262 10.9124 10.3481 8.20843 13.565 7.93977V14.1975C13.565 14.3205 13.6095 14.443 13.6898 14.5378C13.6913 14.5396 13.6926 14.5416 13.6942 14.5434L17.7983 19.2672C16.7271 20.0507 15.4299 20.4771 14.0923 20.4771C10.6296 20.4771 7.81262 17.66 7.81262 14.1975ZM18.5946 18.5755L15.3296 14.8176L20.2946 15.1839C20.0934 16.4668 19.5038 17.6431 18.5946 18.5755ZM20.3704 14.1319L14.6196 13.7076V7.93977C17.8149 8.2066 20.3367 10.8767 20.3704 14.1319Z"
        fill={colour}
      />
    </svg>
  )
}

ComputerIcon.defaultProps = {
  colour: '#000000',
}

ComputerIcon.propTypes = {
  colour: PropTypes.string,
}

export default ComputerIcon
