import React from 'react'
import PropTypes from 'prop-types'

function WifiIcon({ colour }, props) {
  return (
    <svg
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M19.9999 26.9905C18.1509 26.9905 16.6466 28.4949 16.6466 30.3439C16.6466 32.1929 18.1509 33.6972 19.9999 33.6972C21.849 33.6972 23.3533 32.1929 23.3533 30.3439C23.3533 28.4949 21.849 26.9905 19.9999 26.9905ZM19.9999 31.5878C19.3141 31.5878 18.756 31.0298 18.756 30.3439C18.756 29.658 19.3141 29.1 19.9999 29.1C20.6858 29.1 21.2439 29.658 21.2439 30.3439C21.2439 31.0298 20.6858 31.5878 19.9999 31.5878Z"
        fill={colour}
      />
      <path
        d="M20 16.6463C16.6657 16.6463 13.088 17.8859 10.3156 20.6576C8.96305 22.0102 8.98998 24.0901 10.3291 25.4152C11.6588 26.7358 13.7276 26.7302 15.057 25.4009C16.3773 24.0807 18.1327 23.3536 19.9999 23.3536C21.8671 23.3536 23.6226 24.0807 24.9429 25.4009C26.2722 26.7302 28.3411 26.7358 29.6707 25.4152C31.0099 24.0901 31.0368 22.0102 29.6842 20.6576C26.912 17.886 23.3343 16.6463 20 16.6463ZM28.1872 23.9157C27.5068 24.5915 26.744 24.2189 26.4345 23.9094C24.7158 22.1907 22.4306 21.2442 20 21.2442C17.5694 21.2442 15.2842 22.1907 13.5656 23.9093C13.0183 24.4565 12.2804 24.3801 11.8157 23.9186C11.3367 23.4447 11.25 22.7065 11.8073 22.1493C13.9961 19.961 16.9057 18.7557 20.0001 18.7557C23.0945 18.7557 26.0041 19.961 28.193 22.1495C28.7491 22.7056 28.6629 23.445 28.1872 23.9157Z"
        fill={colour}
      />
      <path
        d="M36.9991 13.3445C32.1666 8.51205 25.952 6.30276 20 6.30276C14.048 6.30276 7.83341 8.51205 3.0009 13.3445C1.66538 14.6816 1.6715 16.752 2.98845 18.0745C4.63306 19.7374 6.78631 19.0755 7.75051 18.079C11.0238 14.81 15.3737 13.0097 20 13.0097C24.6263 13.0097 28.9762 14.81 32.2495 18.079C33.2137 19.0755 35.3669 19.7374 37.0115 18.0745C38.3285 16.752 38.3346 14.6816 36.9991 13.3445ZM35.5168 16.586C34.6456 17.463 33.7612 16.6079 33.74 16.5864C30.0685 12.9197 25.1889 10.9004 20 10.9004C14.8111 10.9004 9.93153 12.9197 6.25988 16.5864C6.23872 16.6079 5.35433 17.463 4.48309 16.586C3.98014 16.0809 3.98443 15.3446 4.49244 14.836C8.63497 10.6936 14.1423 8.41214 20 8.41214C25.8577 8.41214 31.365 10.6935 35.5075 14.836C36.0155 15.3446 36.0198 16.081 35.5168 16.586Z"
        fill={colour}
      />
    </svg>
  )
}

WifiIcon.defaultProps = {
  colour: '#000000',
}

WifiIcon.propTypes = {
  colour: PropTypes.string,
}

export default WifiIcon
