import PropTypes from 'prop-types'
import '../../../../styles/consumer-flow/base-fields/image-field.scss'
import Modal from '../../../global-components/elements/Modal'
import { ReactComponent as CloseIcon } from '../../../../assets/icons/close-icon.svg'

import { useEffect, useRef, useState } from 'react'
import LinkWrapper from '../LinkWrapper'
import clsx from 'clsx'
import highlightTextInHTML from '../../../../utilities/find-replace/highlightTextInHTML'
const ButtonField = ({ field, highlight }) => {
  const ref = useRef()
  const block = field?.blocks?.[0] || {}
  const { configurations, button } = block
  const alignment = configurations?.alignment || 'left'

  const alignments = {
    left: 'flex-start',
    center: 'center',
    right: 'flex-end',
  }
  const style = {
    justifyContent: alignments[alignment],
  }

  const buttonStyle = {
    color: button?.color || '#FFF',
    background: button?.background || '#000',
    width: `calc(${button.label?.length}ch + 48px)`,
    textAlign: 'center',
  }
  const hasInnerText = !!ref?.current?.innerText

  useEffect(
    function highlightResultsOnSearch() {
      if (highlight?.type === 'text' && hasInnerText) {
        highlightTextInHTML({ ref, highlight })
      }
    },
    [highlight, hasInnerText],
  )

  return (
    <div className="button-field" style={style} ref={ref}>
      <LinkWrapper link={configurations?.link}>
        <div
          className={clsx('btn', {
            'find-replace__outline':
              highlight?.itemId === field._id && highlight.type !== 'text',
          })}
          style={buttonStyle}
        >
          {button.label}
        </div>
      </LinkWrapper>
    </div>
  )
}

ButtonField.propTypes = {
  field: PropTypes.object,
  highlight: PropTypes.object,
}

export default ButtonField
