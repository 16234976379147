import renderHTML from 'html-react-parser'

export default function parseValidHtmlString(string) {
  const isValidHTML = RegExp.prototype.test.bind(/(<([^>]+)>)/i)

  // check string for valid markup and render elements OR
  // strip tags and render string.
  if (isValidHTML(string)) {
    return renderHTML(string)
  } else if (string) {
    return string.replace(/<[^>]*>?/gm, '')
  }
}
