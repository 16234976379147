import React, { useState, useEffect } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchUserProfile } from '../../redux/user/user'
import { useWidgetState } from '@livechat/widget-react'
import { isEmpty } from 'lodash'
import { selectUserState } from '../../redux/user/user'
import {
  selectActiveClient,
  selectManageWorkflowModalVisibility,
  selectLiveChatVisibility,
  selectPowerSearchModalVisibility,
  toggleLiveChat,
  selectActiveClientId,
  selectActiveOperatingLocation,
  togglePowerSearchModal,
  selectDuplicatedWorkflowVisibility,
  selectDuplicatedStepVisibility,
  selectDuplicatedComponentVisibility,
  selectMediaLibraryModalVisibility,
  toggleMediaLibraryModal,
} from '../../redux/user/userSelections'
import { useLocation, useNavigate } from 'react-router-dom'
import { BrandNavigation } from '@quickfacts-inc/qf-brand-navigation'
import routes from '../../configuration/routes'
import Views from './Views'
import ErrorBoundary from '../../components/global-components/elements/ErrorBoundary'
import ClientsField from '../../components/global-components/filters/ClientsField'
import MaterialLoader from '../../components/global-components/elements/MaterialLoader'
import LiveChat from '../../components/global-components/live-chat/LiveChat'
import PlatformNavigation from '../../components/consumer-flow/structure/platform-navigation/PlatformNavigation'
import ManageWorkflowModal from '../../components/editor-flow/create-workflow/manage-workflow-modal/ManageWorkflowModal'
import useAuthToken from '../../hooks/useAuthToken'
import PowerSearch from '../../components/global-components/power-search/PowerSearch'
import DuplicateWorkflowModal from '../../components/editor-flow/duplicate/workflow/DuplicateWorkflowModal'
import DuplicateStepModal from '../../components/editor-flow/duplicate/step/DuplicateStepModal'
import DuplicateComponentModal from '../../components/editor-flow/duplicate/component/DuplicateComponentModal'
import ScrollTopButton from '../../components/global-components/elements/ScrollTopButton'
import Tooltip from '../../components/global-components/elements/Tooltip'
import MediaLibrary from '../../components/global-components/media-library/MediaLibrary'
import BrandingStyles from '../../components/global-components/branding/BrandingStyles'
import ManageWorkflowModalContainer from '../../components/editor-flow/create-workflow/manage-workflow-modal/ManageWorkflowModalContainer'
import { selectSocketSessionExpired } from '../../redux/socket/socket'
import SocketSessionExpiredModal from '../../components/editor-flow/manage-content-locks/SocketSessionExpiredModal'

function App() {
  const { isLoading, user } = useAuth0()
  const { token } = useAuthToken({})
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const widgetState = useWidgetState()
  const [isNavigationOpen, toggleNavigation] = useState(false)
  const dispatch = useDispatch()
  const isLiveChatVisible = useSelector(selectLiveChatVisibility)
  const userState = useSelector(selectUserState)
  const activeClient = useSelector(selectActiveClient)
  const activeClientId = useSelector(selectActiveClientId)
  const isDuplicateComponentModalVisible = useSelector(
    selectDuplicatedComponentVisibility,
  )
  const activeOperatingLocation = useSelector(selectActiveOperatingLocation)
  const isDuplicateStepModalVisible = useSelector(
    selectDuplicatedStepVisibility,
  )
  const isDuplicateWorkflowModalVisible = useSelector(
    selectDuplicatedWorkflowVisibility,
  )
  const isManageWorkflowModalVisible = useSelector(
    selectManageWorkflowModalVisibility,
  )

  const isPowerSearchModalVisible = useSelector(
    selectPowerSearchModalVisibility,
  )
  const isMediaLibraryModalVisible = useSelector(
    selectMediaLibraryModalVisibility,
  )
  const isSocketSessionExpired = useSelector(selectSocketSessionExpired)
  const isGlobalLoading = isLoading || userState.loading || !activeClient
  const isEditorMode = pathname.includes(routes.manageWorkflows)

  const handleFetchUserProfile = async () => {
    await dispatch(fetchUserProfile(token))
  }

  useEffect(() => {
    if (token && !userState.user && !userState.loading && !userState.error) {
      handleFetchUserProfile()
    }
  }, [user, !isGlobalLoading, token])

  const toggleLiveChatEvent = () => {
    dispatch(toggleLiveChat(!isLiveChatVisible))
  }

  const handlePowerSearchModalEvent = () => {
    dispatch(togglePowerSearchModal(!isPowerSearchModalVisible))
  }

  const handleCloseMediaLibrary = () => {
    dispatch(toggleMediaLibraryModal(false))
  }

  useEffect(() => {
    const handleGlobalSearchHotKey = event => {
      if ((event.metaKey || event.ctrlKey) && event.code === 'KeyK') {
        handlePowerSearchModalEvent()
      }
    }

    window.addEventListener('keydown', handleGlobalSearchHotKey)

    return () => {
      window.removeEventListener('keydown', handleGlobalSearchHotKey)
    }
  }, [])

  return isGlobalLoading ? (
    <MaterialLoader containerClasses="container-loader" />
  ) : !isGlobalLoading && !isEmpty(userState.user) ? (
    <div
      key={'app'}
      className={`main${isNavigationOpen ? ' nav-open' : ''}${
        isEditorMode ? ' editor' : ''
      }`}
    >
      <div className="navigation__group">
        <BrandNavigation
          activeClient={activeClient}
          clientFieldComponent={ClientsField}
          loaderComponent={MaterialLoader}
          userPlatform="Workflows"
          userState={userState}
          toggleLiveChatEvent={toggleLiveChatEvent}
          widgetState={widgetState}
        />

        <PlatformNavigation
          isNavigationOpen={isNavigationOpen}
          onToggleClick={() => toggleNavigation(!isNavigationOpen)}
        />
      </div>

      <Views />
      <LiveChat />
      <ScrollTopButton />
      <Tooltip />
      <BrandingStyles />

      {isPowerSearchModalVisible ? (
        <PowerSearch
          activeClientId={activeClientId}
          activeOperatingLocation={activeOperatingLocation}
          isPowerSearchModalVisible={isPowerSearchModalVisible}
          handlePowerSearchModalEvent={handlePowerSearchModalEvent}
          handleNavigationEvent={navigate}
        />
      ) : null}

      {isDuplicateComponentModalVisible ? <DuplicateComponentModal /> : null}
      {isDuplicateStepModalVisible ? <DuplicateStepModal /> : null}
      {isDuplicateWorkflowModalVisible ? <DuplicateWorkflowModal /> : null}
      {isManageWorkflowModalVisible ? <ManageWorkflowModalContainer /> : null}
      {isMediaLibraryModalVisible ? (
        <MediaLibrary
          isOpen={true}
          onCancel={handleCloseMediaLibrary}
          mode="management"
        />
      ) : null}
      {isSocketSessionExpired ? <SocketSessionExpiredModal /> : null}
    </div>
  ) : userState.error ? (
    <ErrorBoundary
      errorTitle="Error Fetching User"
      errorMessage={userState.error}
    />
  ) : null
}

export default App
