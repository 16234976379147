import '../../../../../styles/editor-flow/find-replace/find-replace-internal-link.scss'
import {
  AUTO_CLOSE_WINDOW_HEIGHT,
  FindReplaceTabs,
} from '../../../../../containers/FindReplaceContainer'
import TabNavigation from '../../../../consumer-flow/structure/tab-navigation/TabNavigation'
import React, { useMemo, useState } from 'react'
import axios from 'axios'
import useAuthToken from '../../../../../hooks/useAuthToken'
import { getUserApiRequirements } from '../../../../../redux/user/user'
import { useSelector } from 'react-redux'
import { cloneDeep, get, set } from 'lodash'
import MaterialLoader from '../../../../global-components/elements/MaterialLoader'
import { selectActiveOperatingLocation } from '../../../../../redux/user/userSelections'
import FindReplaceInternalLinkForm from './FindReplaceInternalLinkForm'
import processFindReplaceInternalLinkApiData from '../../../../../utilities/find-replace/processFindReplaceInternalLinkApiData'
import FindReplaceLinkTable from '../FindReplaceLinkTable'
import fetchFindInternalLink from '../../../../../services/find-replace/fetchFindInternalLink'
import updateReplaceInternalLink from '../../../../../services/find-replace/updateReplaceInternalLink'
import ErrorBoundary from '../../../../global-components/elements/ErrorBoundary'

const FindReplaceInternalLinkContainer = () => {
  const { token } = useAuthToken({})
  const { activeClientId } = useSelector(getUserApiRequirements)
  const operatingLocation = useSelector(selectActiveOperatingLocation)
  const [replaceValue, setReplaceValue] = useState(null)
  const [searchResultsState, setSearchResultsState] = useState({
    loading: false,
    error: false,
    data: null,
  })
  const [tabsAreOpen, setTabsAreOpen] = useState(true)

  const items = searchResultsState?.data?.items
  const entries = searchResultsState?.data?.entries

  async function handleFormSubmit(values) {
    setSearchResultsState({ ...searchResultsState, loading: true })
    setReplaceValue(values.replace)
    fetchFindInternalLink({
      find: values.find,
      activeClientId,
      token,
    })
      .then(data => {
        setSearchResultsState({
          ...searchResultsState,
          loading: false,
          data,
        })

        if (
          data?.entries?.length > 0 &&
          window.innerHeight < AUTO_CLOSE_WINDOW_HEIGHT
        ) {
          setTabsAreOpen(false)
        }
      })
      .catch(() => {
        setSearchResultsState({
          ...searchResultsState,
          loading: false,
        })
      })
  }

  function handleReplace(entry) {
    if (entry) {
      const item = cloneDeep(items[entry.itemId])
      const currentLink = get(item, entry.path)
      set(item, [...entry.path], { ...currentLink, ...replaceValue })

      setSearchResultsState({
        ...searchResultsState,
        loading: true,
      })

      updateReplaceInternalLink({
        entry,
        items,
        replaceValue,
        token,
        activeClientId,
        operatingLocation,
        entries,
      })
        .then(data => {
          setSearchResultsState({
            ...searchResultsState,
            loading: false,
            data,
          })
        })
        .catch(() => {
          setSearchResultsState({
            ...searchResultsState,
            loading: false,
          })
        })
    }
  }

  return (
    <div className="find-replace__container">
      <div className="find-replace__header container">
        <TabNavigation
          linkOptions={FindReplaceTabs}
          isOpen={tabsAreOpen}
          onSetOpen={setTabsAreOpen}
        >
          <FindReplaceInternalLinkForm onSubmit={handleFormSubmit} />
        </TabNavigation>
      </div>
      {entries?.length > 0 ? (
        <div className="find-replace__results">
          <FindReplaceLinkTable
            items={items}
            entries={entries}
            onReplaceClick={handleReplace}
            replaceValue={replaceValue}
          />
        </div>
      ) : null}
      {entries?.length === 0 ? (
        <ErrorBoundary
          containerClasses="find-replace__error-boundary error-boundary section--md"
          errorTitle="No Results Found"
          errorMessage="Sorry, your search query did not yield any matching or relevant results. Please try again."
        />
      ) : null}
      {searchResultsState.loading && (
        <MaterialLoader containerClasses="overlay-loader" />
      )}
    </div>
  )
}

export default FindReplaceInternalLinkContainer
