import React from 'react'
import { ReactComponent as ReturnIcon } from '../../../../assets/icons/return-icon.svg'
import { ReactComponent as UpArrowIcon } from '../../../../assets/icons/up-key-icon.svg'
import { ReactComponent as DownArrowIcon } from '../../../../assets/icons/down-key-icon.svg'

function PowerSearchFooter() {
  return (
    <div className="power-search__footer">
      <div className="power-search__helpers">
        <div className="power-search__helper">
          <span className="power-search__helper-icon return">
            <ReturnIcon />
          </span>
          <span className="power-search__helper-label">to select</span>
        </div>

        <div className="power-search__helper">
          <span className="power-search__helper-icon up">
            <UpArrowIcon />
          </span>
          <span className="power-search__helper-icon down">
            <DownArrowIcon />
          </span>
          <span className="power-search__helper-label">to navigate</span>
        </div>

        <div className="power-search__helper">
          <span className="power-search__helper-icon esc">esc</span>
          <span className="power-search__helper-label">to close</span>
        </div>
      </div>
    </div>
  )
}

export default PowerSearchFooter
