import { get } from 'lodash'

export const SET_AUTH_TOKEN = 'SET_AUTH_TOKEN'

const initialState = {
  token: null,
}

export default function authReducer(state = initialState, action) {
  switch (action.type) {
    case SET_AUTH_TOKEN:
      return { ...state, token: action.payload }
    default:
      return state
  }
}

export const selectAuthToken = state => get(state, 'authData.token', null)
