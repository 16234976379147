import React from 'react'
import PropTypes from 'prop-types'

function PinkCardIcon({ colour }, props) {
  return (
    <svg
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6.20037 27.7999H23.0018C23.161 27.7999 23.3136 27.7367 23.4261 27.6241C23.5387 27.5116 23.6019 27.359 23.6019 27.1998C23.6019 27.0407 23.5387 26.8881 23.4261 26.7755C23.3136 26.663 23.161 26.5998 23.0018 26.5998H6.20037C6.04122 26.5998 5.8886 26.663 5.77607 26.7755C5.66353 26.8881 5.60032 27.0407 5.60032 27.1998C5.60032 27.359 5.66353 27.5116 5.77607 27.6241C5.8886 27.7367 6.04122 27.7999 6.20037 27.7999Z"
        fill={colour}
      />
      <path
        d="M6.20037 30.8002H23.0018C23.161 30.8002 23.3136 30.737 23.4261 30.6244C23.5387 30.5119 23.6019 30.3593 23.6019 30.2001C23.6019 30.041 23.5387 29.8883 23.4261 29.7758C23.3136 29.6633 23.161 29.6001 23.0018 29.6001H6.20037C6.04122 29.6001 5.8886 29.6633 5.77607 29.7758C5.66353 29.8883 5.60032 30.041 5.60032 30.2001C5.60032 30.3593 5.66353 30.5119 5.77607 30.6244C5.8886 30.737 6.04122 30.8002 6.20037 30.8002Z"
        fill={colour}
      />
      <path
        d="M6.20037 21.1993H20.6016C20.7608 21.1993 20.9134 21.1361 21.0259 21.0236C21.1385 20.911 21.2017 20.7584 21.2017 20.5993V16.9989C21.2017 16.8398 21.1385 16.6872 21.0259 16.5746C20.9134 16.4621 20.7608 16.3989 20.6016 16.3989H6.20037C6.04122 16.3989 5.8886 16.4621 5.77607 16.5746C5.66353 16.6872 5.60032 16.8398 5.60032 16.9989V20.5993C5.60032 20.7584 5.66353 20.911 5.77607 21.0236C5.8886 21.1361 6.04122 21.1993 6.20037 21.1993ZM6.80042 17.599H20.0016V19.9992H6.80042V17.599Z"
        fill={colour}
      />
      <path
        d="M6.20037 24.7996H11.0008C11.1599 24.7996 11.3126 24.7364 11.4251 24.6239C11.5376 24.5113 11.6008 24.3587 11.6008 24.1996C11.6008 24.0404 11.5376 23.8878 11.4251 23.7753C11.3126 23.6627 11.1599 23.5995 11.0008 23.5995H6.20037C6.04122 23.5995 5.8886 23.6627 5.77607 23.7753C5.66353 23.8878 5.60032 24.0404 5.60032 24.1996C5.60032 24.3587 5.66353 24.5113 5.77607 24.6239C5.8886 24.7364 6.04122 24.7996 6.20037 24.7996Z"
        fill={colour}
      />
      <path
        d="M13.401 23.5995C13.2419 23.5995 13.0892 23.6627 12.9767 23.7753C12.8642 23.8878 12.8009 24.0404 12.8009 24.1996C12.8009 24.3587 12.8642 24.5113 12.9767 24.6239C13.0892 24.7364 13.2419 24.7996 13.401 24.7996H17.0013C17.1605 24.7996 17.3131 24.7364 17.4256 24.6239C17.5381 24.5113 17.6014 24.3587 17.6014 24.1996C17.6014 24.0404 17.5381 23.8878 17.4256 23.7753C17.3131 23.6627 17.1605 23.5995 17.0013 23.5995H13.401Z"
        fill={colour}
      />
      <path
        d="M28.4023 30.8002C28.877 30.8002 29.3411 30.6594 29.7358 30.3957C30.1305 30.1319 30.4381 29.7571 30.6198 29.3185C30.8015 28.8799 30.849 28.3973 30.7564 27.9317C30.6638 27.4661 30.4352 27.0384 30.0995 26.7028C29.7638 26.3671 29.3362 26.1385 28.8706 26.0459C28.405 25.9533 27.9224 26.0008 27.4838 26.1825C27.0452 26.3641 26.6703 26.6718 26.4066 27.0665C26.1429 27.4612 26.0021 27.9253 26.0021 28.4C26.0028 29.0363 26.2559 29.6464 26.7059 30.0964C27.1559 30.5464 27.766 30.7995 28.4023 30.8002ZM28.4023 27.1999C28.6397 27.1999 28.8717 27.2702 29.0691 27.4021C29.2664 27.534 29.4202 27.7214 29.5111 27.9407C29.6019 28.16 29.6257 28.4013 29.5794 28.6341C29.533 28.8669 29.4187 29.0807 29.2509 29.2486C29.0831 29.4164 28.8692 29.5307 28.6364 29.577C28.4036 29.6233 28.1623 29.5996 27.943 29.5087C27.7238 29.4179 27.5363 29.2641 27.4045 29.0667C27.2726 28.8694 27.2022 28.6373 27.2022 28.4C27.2026 28.0818 27.3291 27.7768 27.5541 27.5518C27.7791 27.3268 28.0841 27.2002 28.4023 27.1999Z"
        fill={colour}
      />
      <path
        d="M26.7703 19.1383C27.5262 19.8636 28.3877 20.4702 29.3253 20.9374C29.408 20.9783 29.4989 20.9996 29.5911 20.9996C29.6833 20.9997 29.7742 20.9786 29.8569 20.9378C30.7986 20.4707 31.6641 19.8636 32.4238 19.1371C34.2389 17.3962 35.2883 15.1139 35.5423 12.3538C35.5525 12.2425 35.5315 12.1305 35.4815 12.0305C35.4315 11.9305 35.3546 11.8465 35.2594 11.7879C33.9667 10.9917 31.8097 9.66667 29.9151 8.50392C29.8206 8.44589 29.7118 8.4152 29.6008 8.41528C29.4899 8.41536 29.3811 8.4462 29.2866 8.50437L23.9457 11.7924C23.8505 11.851 23.7736 11.9351 23.7236 12.0351C23.6736 12.1351 23.6525 12.2471 23.6628 12.3584C23.9169 15.117 24.9621 17.3981 26.7703 19.1383ZM29.6017 9.7197C31.2638 10.7398 33.0763 11.8533 34.3078 12.6113C34.0258 14.9101 33.1135 16.8128 31.5932 18.2709C30.9946 18.8432 30.3215 19.3321 29.5921 19.7244C28.8667 19.3324 28.1975 18.8444 27.6025 18.2735C26.088 16.816 25.1788 14.9141 24.8973 12.6159L29.6017 9.7197Z"
        fill={colour}
      />
      <path
        d="M29.0024 16.9989C29.0812 16.999 29.1592 16.9836 29.2321 16.9534C29.3049 16.9233 29.371 16.879 29.4267 16.8232L32.4269 13.8229C32.5395 13.7104 32.6027 13.5578 32.6027 13.3986C32.6027 13.2395 32.5395 13.0868 32.4269 12.9743C32.3144 12.8618 32.1618 12.7986 32.0026 12.7986C31.8435 12.7986 31.6908 12.8618 31.5783 12.9743L29.0024 15.5503L28.2266 14.7745C28.114 14.6619 27.9614 14.5987 27.8023 14.5987C27.6431 14.5987 27.4905 14.6619 27.3779 14.7745C27.2654 14.887 27.2022 15.0396 27.2022 15.1988C27.2022 15.3579 27.2654 15.5106 27.3779 15.6231L28.578 16.8232C28.6337 16.879 28.6998 16.9233 28.7727 16.9534C28.8455 16.9836 28.9235 16.999 29.0024 16.9989Z"
        fill={colour}
      />
      <path
        d="M5.00026 34.4005H31.4026C32.198 34.3996 32.9606 34.0832 33.5231 33.5207C34.0856 32.9583 34.4019 32.1957 34.4028 31.4002V20.5601C36.145 18.7573 38 15.7275 38 10.9983C38 10.8958 37.9738 10.7951 37.9238 10.7056C37.8738 10.6162 37.8018 10.541 37.7145 10.4873L29.917 5.68691C29.8214 5.62974 29.7122 5.59955 29.6008 5.59955C29.4895 5.59955 29.3802 5.62974 29.2847 5.68691L21.4872 10.4873C21.3999 10.5411 21.3278 10.6162 21.2779 10.7057C21.2279 10.7952 21.2017 10.8959 21.2017 10.9984C21.2011 11.5997 21.2327 12.2006 21.2963 12.7986H5.00026C4.20482 12.7995 3.44221 13.1159 2.87974 13.6783C2.31728 14.2408 2.00089 15.0034 2 15.7988V31.4002C2.00089 32.1957 2.31728 32.9583 2.87974 33.5207C3.44221 34.0832 4.20482 34.3996 5.00026 34.4005ZM29.6008 6.90149L36.7967 11.3315C36.7262 14.9651 35.4961 17.9211 33.1375 20.1223C32.1101 21.0866 30.9099 21.8482 29.6 22.3674C28.3063 21.8567 27.1201 21.1074 26.1032 20.1585C23.7192 17.9544 22.4759 14.9859 22.405 11.3315L29.6008 6.90149ZM3.2001 15.7988C3.20066 15.3216 3.3905 14.864 3.72797 14.5266C4.06545 14.1891 4.523 13.9992 5.00026 13.9987H21.4735C22.1216 17.4321 23.8162 19.6785 25.2884 21.0397C27.3125 22.911 29.3283 23.5466 29.4132 23.5728C29.4705 23.5905 29.5301 23.5995 29.5901 23.5995H29.6116C29.6716 23.5995 29.7312 23.5905 29.7885 23.5728C29.8635 23.5497 31.4419 23.0515 33.2027 21.6491V31.4002C33.2022 31.8775 33.0123 32.335 32.6749 32.6725C32.3374 33.01 31.8798 33.1998 31.4026 33.2004H5.00026C4.523 33.1998 4.06545 33.01 3.72797 32.6725C3.3905 32.335 3.20066 31.8775 3.2001 31.4002V15.7988Z"
        fill={colour}
      />
    </svg>
  )
}

PinkCardIcon.defaultProps = {
  colour: '#000000',
}

PinkCardIcon.propTypes = {
  colour: PropTypes.string,
}

export default PinkCardIcon
