import React, { useContext, useEffect, useState } from 'react'
import { useDraggable, useDroppable } from '@dnd-kit/core'
import clsx from 'clsx'
import { ReactComponent as FolderIcon } from '../../../../assets/icons/folder-icon.svg'
import { ReactComponent as DotsIcon } from '../../../../assets/icons/dots-vertical-icon.svg'
import { ReactComponent as DeleteIcon } from '../../../../assets/icons/delete-icon.svg'
import { ReactComponent as EditIcon } from '../../../../assets/icons/edit-icon.svg'
import { alphaFolderSort, TreeContext } from './MediaLibraryFolderTree'
import useClickAway from '../../../../hooks/useClickAway'
import PropTypes from 'prop-types'

const MediaLibraryFolderTreeItem = ({ folder, onEditClick, onDeleteClick }) => {
  const treeCtx = useContext(TreeContext)
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const { setNodeRef, isOver } = useDroppable({
    id: folder._id,
    data: {
      type: 'folder',
    },
  })

  const id = `media-library-item-${folder._id}`
  const {
    attributes,
    listeners,
    setNodeRef: setDragNodeRef,
    isDragging,
  } = useDraggable({
    data: {
      folder,
      type: 'folder',
    },
    id,
  })
  const ref = useClickAway(() => setDropdownOpen(false))

  const { folders, selectedFolder } = treeCtx
  const childFolders = folder?.children?.sort(alphaFolderSort) || []
  const isOpen = treeCtx.openFolders.find(item => item === folder._id)

  function handleEditClick() {
    setDropdownOpen(false)
    onEditClick(folder)
  }

  function handleDeleteClick() {
    setDropdownOpen(false)
    onDeleteClick(folder)
  }

  return (
    <div className="media-library-folder-tree__item">
      <div
        className={clsx('media-library-folder-tree__item-content', {
          'is-over': isOver,
        })}
        ref={setNodeRef}
      >
        <button
          className={clsx('media-library-folder-tree__item-dropdown', {
            empty: childFolders.length === 0,
          })}
          onClick={() => treeCtx.onOpen(folder)}
        >
          {isOpen ? '-' : '+'}
        </button>
        <button
          onClick={() => treeCtx.onClick(folder)}
          className={clsx('media-library-folder-tree__item-name', {
            selected: selectedFolder === folder._id,
          })}
          ref={setDragNodeRef}
          {...listeners}
          {...attributes}
        >
          <FolderIcon />
          <span>{folder.name}</span>
        </button>
        <div className="media-library-folder-tree__item-options" ref={ref}>
          <button
            className="media-library-folder-tree__item-options-button"
            onClick={() => setDropdownOpen(true)}
          >
            <DotsIcon />
          </button>
          {dropdownOpen && (
            <div className="media-library-folder-tree__item-options-menu">
              <button onClick={handleEditClick}>
                <EditIcon /> Rename
              </button>
              <button onClick={handleDeleteClick}>
                <DeleteIcon /> Delete
              </button>
            </div>
          )}
        </div>
      </div>

      {childFolders.length > 0 && isOpen && (
        <div className="media-library-folder-tree__children">
          {childFolders.map(childFolder => (
            <MediaLibraryFolderTreeItem
              folder={folders[childFolder._id]}
              key={childFolder._id}
              onDeleteClick={onDeleteClick}
              onEditClick={onEditClick}
            />
          ))}
        </div>
      )}
    </div>
  )
}

MediaLibraryFolderTreeItem.propTypes = {
  folder: PropTypes.shape({
    _id: PropTypes.string,
    name: PropTypes.string,
    children: PropTypes.array,
  }),
  onEditClick: PropTypes.func,
  onDeleteClick: PropTypes.func,
}

export default MediaLibraryFolderTreeItem
