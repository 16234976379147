import PropTypes from 'prop-types'
import { EDITOR_IMAGE } from '../../../../configuration/editor'
import EditorImageField, {
  createImageField,
} from '../../base-fields/image-field/EditorImageField'

const EditorImageComponent = ({ component, onChange, dropPath }) => {
  const subcomponent = component?.subComponents?.[0] || {}
  const { fields } = subcomponent || []
  const field = fields[0]
  function handleFieldChange(field) {
    onChange({
      ...component,
      subComponents: [
        {
          ...subcomponent,
          fields: [field],
        },
      ],
    })
  }

  return (
    <div>
      <div>
        <EditorImageField field={field} onChange={handleFieldChange} />
      </div>
    </div>
  )
}

export const createEmptyImage = () => {
  const id = `temp-${Date.now()}-sc`
  const fieldId = `temp-${Date.now()}-field`
  return {
    id,
    type: 'subComponent',
    subComponentType: 'image',
    configurations: {},
    fields: [createImageField(fieldId)],
  }
}

export const createImageComponent = id => {
  return {
    id,
    componentType: EDITOR_IMAGE,
    configurations: {},
    subComponents: [createEmptyImage()],
  }
}

EditorImageComponent.propTypes = {
  component: PropTypes.object,
  onChange: PropTypes.func,
  dropPath: PropTypes.string,
}

export default EditorImageComponent
