import React from 'react'
import { get, isEmpty, uniq } from 'lodash'
import { useSelector } from 'react-redux'
import { selectActiveClient } from '../../redux/user/userSelections'
import defaultBrandColours from '../../configuration/defaultBrandColours'

const getActiveBrandHexColours = () => {
  const activeClient = useSelector(selectActiveClient)
  const clientBrandColours = get(activeClient, 'brandColours', [])

  let brandColours = defaultBrandColours.map(colour => {
    return colour?.hex
  })

  if (!isEmpty(clientBrandColours)) {
    clientBrandColours.map(colour => {
      return brandColours.push(colour?.hex)
    })
  }

  return uniq(brandColours)
}

export default getActiveBrandHexColours
