import React, { useEffect } from 'react'
import { get } from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import {
  setDuplicatedContentData,
  selectDuplicatedContentData,
  selectActiveClientId,
  selectActiveOperatingLocation,
  selectDuplicatedComponentVisibility,
  toggleDuplicateComponentModal,
} from '../../../../redux/user/userSelections'
import {
  resetDuplicateContent,
  selectDuplicateContentState,
} from '../../../../redux/duplicate/duplicateContentReducers'
import {
  fetchWorkflows,
  selectWorkflowsState,
} from '../../../../redux/workflows/workflows'
import { fetchSanitizedWorkflow } from '../../../../redux/duplicate/fetchSanitizedWorkflow'
import { fetchWorkflow } from '../../../../redux/workflows/workflow'
import { duplicateComponent } from '../../../../redux/components/duplicateComponent'
import useAuthToken from '../../../../hooks/useAuthToken'
import Modal from '../../../global-components/elements/Modal'
import MaterialLoader from '../../../global-components/elements/MaterialLoader'
import { ReactComponent as CloseModalIcon } from '../../../../assets/icons/close-icon.svg'

function DuplicateComponentModal() {
  const { token } = useAuthToken({})
  const dispatch = useDispatch()
  const activeClientId = useSelector(selectActiveClientId)
  const activeOperatingLocation = useSelector(selectActiveOperatingLocation)
  const workflowsState = useSelector(selectWorkflowsState)
  const isDuplicateModalOpen = useSelector(selectDuplicatedComponentVisibility)
  const userDuplicatedContentData = useSelector(selectDuplicatedContentData)
  const duplicateContentState = useSelector(selectDuplicateContentState)
  const duplicateComponentLoading = get(duplicateContentState, 'loading', false)
  const workflows = get(workflowsState, 'workflows', [])
  const workflowsByClient = get(
    workflows,
    `${activeOperatingLocation}-${activeClientId}`,
    [],
  )
  const userDuplicatedWorkflowId = get(
    userDuplicatedContentData,
    'workflowId',
    null,
  )
  const userDuplicatedWorkflowData = workflowsByClient.find(workflow => {
    const workflowId = get(workflow, '_id', null)
    return workflowId === userDuplicatedWorkflowId
  })
  const userDuplicatedWorkflowSlug = get(
    userDuplicatedWorkflowData,
    'slug',
    null,
  )
  const userDuplicatedStepId = get(userDuplicatedContentData, 'stepId', null)
  const duplicatedSteps = get(duplicateContentState, 'content.steps', [])
  const duplicatedStep = duplicatedSteps.find(step => {
    const stepId = get(step, '_id', null)
    return stepId === userDuplicatedStepId
  })
  const duplicatedStepName = get(duplicatedStep, 'name', null)
  const userDuplicatedComponentId = get(
    userDuplicatedContentData,
    'componentId',
    null,
  )
  const duplicatedComponents = get(duplicatedStep, 'components', [])
  const duplicatedComponent = duplicatedComponents.find(component => {
    const componentId = get(component, '_id', null)
    return componentId === userDuplicatedComponentId
  })
  const duplicatedComponentType = get(
    duplicatedComponent,
    'componentType',
    null,
  )

  const handleCloseDuplicateModal = () => {
    dispatch(resetDuplicateContent())
    dispatch(toggleDuplicateComponentModal(!isDuplicateModalOpen))

    setDuplicatedContentData(null)
  }

  const handleDuplicateComponentSuccess = () => {
    dispatch(fetchWorkflow(userDuplicatedWorkflowSlug, token))
    handleCloseDuplicateModal()
  }

  async function handleDuplicateComponent() {
    dispatch(
      duplicateComponent(
        duplicatedComponent,
        handleDuplicateComponentSuccess,
        token,
      ),
    )
  }

  useEffect(() => {
    if (token) {
      dispatch(fetchSanitizedWorkflow(userDuplicatedWorkflowSlug, token))
    }
  }, [userDuplicatedWorkflowSlug, token])

  return (
    <Modal
      modalClassName="modal--duplicate-workflow modal--sm"
      isOpen={isDuplicateModalOpen}
      onRequestClose={() => handleCloseDuplicateModal()}
    >
      <div className="modal__header">
        <h3>Duplicate Component</h3>

        <button
          className="modal__close"
          onClick={() => handleCloseDuplicateModal()}
        >
          <CloseModalIcon />
        </button>
      </div>

      {(!duplicatedComponentType && duplicateComponentLoading) ||
      duplicateComponentLoading ? (
        <MaterialLoader containerClasses="inline-loader" />
      ) : duplicatedComponentType ? (
        <>
          <div className="modal__body">
            <p className="warning warning--blue">
              This action will duplicate the{' '}
              <strong>
                {duplicatedStepName} -{' '}
                <span className="text--capitalize">
                  {duplicatedComponentType}
                </span>
              </strong>{' '}
              component and all of it&apos;s existing fields
            </p>
          </div>
        </>
      ) : (
        'Error fetching workflow content - please wait a moment and try again'
      )}

      <div className="modal__submit">
        <button
          className="btn btn--white"
          onClick={() => dispatch(handleCloseDuplicateModal())}
        >
          Close
        </button>

        {duplicatedComponent ? (
          <button
            className="btn btn--dark-purple"
            onClick={() => handleDuplicateComponent()}
          >
            Duplicate Component
          </button>
        ) : null}
      </div>
    </Modal>
  )
}

export default DuplicateComponentModal
