import React from 'react'
import PropTypes from 'prop-types'
import Select, { components, ActionMeta, OnChangeValue } from 'react-select'
import { get, isBoolean, isEmpty } from 'lodash'

function CustomSelect({
  field,
  filterOption = null,
  form: { touched, errors, setFieldValue },
  getOptionLabel,
  isClearable = true,
  isFixed = false,
  isMulti = false,
  label,
  menuPlacement = 'top',
  options,
  placeholder = 'Select',
  required,
  setFieldTouched,
  onChange,
}) {
  const MultiValueRemove = props => {
    if (props.data.isFixed) {
      return null
    }

    return <components.MultiValueRemove {...props} />
  }

  function handleFieldChange(newValue) {
    setFieldValue(field.name, newValue)
    !!onChange && onChange(newValue)
  }

  function handleChange(
    option,
    actionMeta: ActionMeta,
    newValue: OnChangeValue,
  ) {
    switch (actionMeta.action) {
      case 'remove-value':
      case 'pop-value':
        if (actionMeta.removedValue.isFixed) {
          return
        }
        break
      case 'clear':
        newValue = options.filter(v => v.isFixed)
        break
    }

    if (isMulti) {
      if (isFixed && !isEmpty(newValue)) {
        handleFieldChange(newValue.map(item => item.value))
      } else {
        handleFieldChange(option ? option.map(item => item.value) : [])
      }
    } else {
      handleFieldChange(option?.value)
    }
  }

  const getValue = () => {
    return !isEmpty(options) && isMulti
      ? options.filter(option => field.value.indexOf(option.value) >= 0)
      : !isEmpty(options) && !isMulti
      ? options.find(option => option.value === field.value)
      : isEmpty(options) && isMulti
      ? []
      : ''
  }

  return (
    <>
      {label && (
        <label htmlFor={field.name}>
          {label}
          {required && <sup className="required">*</sup>}
        </label>
      )}

      <Select
        className={`custom-select${
          touched[field.name] && errors[field.name] ? ' error' : ''
        }`}
        classNamePrefix="custom-select"
        id={field.name}
        value={getValue()}
        isClearable={
          isFixed
            ? getValue().some(v => !v.isFixed)
            : isBoolean(isClearable)
            ? isClearable
            : true
        }
        components={isFixed ? { MultiValueRemove } : {}}
        filterOption={filterOption}
        isMulti={isMulti}
        maxMenuHeight={150}
        menuPlacement={menuPlacement}
        minMenuHeight={150}
        name={field.name}
        onBlur={() => setFieldTouched(field.name, true)}
        onChange={handleChange}
        options={options}
        placeholder={placeholder}
        required={required}
        getOptionLabel={getOptionLabel}
      />

      {touched[field.name] && errors[field.name] && (
        <div className="field-error">{errors[field.name]}</div>
      )}
    </>
  )
}

CustomSelect.propTypes = {
  errors: PropTypes.object,
  field: PropTypes.object,
  filterOption: PropTypes.func,
  form: PropTypes.object,
  getOptionLabel: PropTypes.func,
  isClearable: PropTypes.bool,
  isFixed: PropTypes.bool,
  isMulti: PropTypes.bool,
  label: PropTypes.string,
  options: PropTypes.array.isRequired,
  placeholder: PropTypes.string,
  menuPlacement: PropTypes.string,
  required: PropTypes.bool,
  onChange: PropTypes.func,
  setFieldTouched: PropTypes.func.isRequired,
  touched: PropTypes.object,
  data: PropTypes.object,
}

export default CustomSelect
