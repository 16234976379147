import '../../../../styles/consumer-flow/browse-workflow/browse-workflow-step-side-navigation.scss'
import { ReactComponent as ChevronIcon } from '../../../../assets/icons/arrow-down-icon.svg'
import { ReactComponent as HomeIcon } from '../../../../assets/icons/home-smile-icon.svg'
import { Link } from 'react-router-dom'
import routes from '../../../../configuration/routes'
import BrowseWorkflowStepSideNavigationLink from './BrowseWorkflowStepSideNavigationLink'
import { useState } from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'

const BrowseWorkflowStepSideNavigation = ({ steps }) => {
  const [isOpen, setIsOpen] = useState(
    window.localStorage.getItem('workflowSideNav') === 'open',
  )

  const childrenStepIds = steps.reduce((acc, cur) => {
    if (cur?.nestedSteps?.length > 0) {
      acc.push(...cur.nestedSteps)
    }
    return acc
  }, [])

  const parentSteps = steps
    .filter(step => !childrenStepIds.includes(step._id))
    .reduce((acc, cur) => {
      if (cur?.nestedSteps?.length > 0) {
        return [
          ...acc,
          {
            ...cur,
            nestedSteps: cur.nestedSteps.map(nestedStepId =>
              steps.find(step => step._id === nestedStepId),
            ),
          },
        ]
      }
      return [...acc, cur]
    }, [])

  function toggleOpen() {
    const newValue = !isOpen
    window.localStorage.setItem('workflowSideNav', newValue ? 'open' : 'closed')
    setIsOpen(newValue)
  }

  return (
    <div className={clsx('step-side-nav', { open: isOpen })}>
      <button className="step-side-nav__title" onClick={toggleOpen}>
        <div className="step-side-nav__title-content">
          Workflow Steps <ChevronIcon width="10" height="10" />
        </div>
      </button>
      <div className="step-side-nav__menu">
        <Link
          to={routes.browseWorkflows}
          className="step-side-nav__home-button"
        >
          <HomeIcon />
          Workflow Home
        </Link>
        <div>
          {parentSteps.map((step, i) => (
            <BrowseWorkflowStepSideNavigationLink
              step={step}
              key={step._id}
              index={i + 1}
            />
          ))}
        </div>
      </div>
    </div>
  )
}

BrowseWorkflowStepSideNavigation.propTypes = {
  steps: PropTypes.arrayOf(PropTypes.object),
}

export default BrowseWorkflowStepSideNavigation
