import React from 'react'
import PropTypes from 'prop-types'

function BasicUserIcon({ colour }, props) {
  return (
    <svg
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M20 2C10.0748 2 2 10.0748 2 20C2 29.9252 10.0748 38 20 38C29.9252 38 38 29.9252 38 20C38 10.0748 29.9252 2 20 2ZM20 35.8906C15.9469 35.8906 12.2441 34.3646 9.43393 31.8579C12.3475 29.2545 16.0597 27.8328 20 27.8328C23.9403 27.8328 27.6526 29.2545 30.566 31.8578C27.7559 34.3646 24.0531 35.8906 20 35.8906ZM32.0452 30.353C27.3299 26.0947 22.095 25.7234 20 25.7234C17.8988 25.7234 12.6689 26.0967 7.95477 30.353C5.55943 27.5701 4.10938 23.9513 4.10938 20C4.10938 11.2379 11.2379 4.10938 20 4.10938C28.7621 4.10938 35.8906 11.2379 35.8906 20C35.8906 23.9514 34.4406 27.5701 32.0452 30.353Z"
        fill={colour}
      />
      <path
        d="M20 7.64841C16.2985 7.64841 13.2969 10.6434 13.2969 14.3516V16.6109C13.2969 20.3125 16.2919 23.3141 20 23.3141C23.7015 23.3141 26.7031 20.3191 26.7031 16.6109V14.3516C26.7031 10.6494 23.7077 7.64841 20 7.64841ZM24.5937 16.6109C24.5937 19.1481 22.5409 21.2047 20 21.2047C17.5043 21.2047 15.4063 19.1879 15.4063 16.6109V14.3516C15.4063 11.7746 17.5045 9.75778 20 9.75778C22.5368 9.75778 24.5937 11.8103 24.5937 14.3516V16.6109Z"
        fill={colour}
      />
    </svg>
  )
}

BasicUserIcon.defaultProps = {
  colour: '#000000',
}

BasicUserIcon.propTypes = {
  colour: PropTypes.string,
}

export default BasicUserIcon
