import React from 'react'
import PropTypes from 'prop-types'
import { useDndContext, useDroppable } from '@dnd-kit/core'
import EditorField from './EditorField'
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable'
import getId from '../../../utilities/editor/getId'
import { ReactComponent as PlusIcon } from '../../../assets/icons/plus-icon.svg'

const EditorFieldList = ({
  id,
  fields,
  dropPath,
  onFieldChange,
  onFieldDelete,
}) => {
  const dropLevel = 'fields'
  const dndContext = useDndContext()
  const disableDropZones =
    dndContext?.active &&
    dndContext?.active?.data?.current?.dropLevel !== dropLevel
  const { setNodeRef } = useDroppable({
    id: `${id}-editor-field-list`,
    data: {
      dropPath,
      dropLevel,
    },
    disabled: disableDropZones,
  })

  const { setNodeRef: setBottomNodeRef } = useDroppable({
    id: `${id}-editor-field-list-bottom`,
    data: {
      dropPath,
      dropLevel,
      disabled: disableDropZones,
    },
  })

  return (
    <div
      ref={setNodeRef}
      id={`${id}-editor-field-list`}
      className="editor-field-list"
      data-drop-path={dropPath}
    >
      <SortableContext
        items={fields.map(field => getId(field))}
        strategy={verticalListSortingStrategy}
      >
        {fields?.length > 0 &&
          fields?.map((field, index) => (
            <EditorField
              field={field}
              key={getId(field)}
              index={index}
              zIndex={fields.length - index}
              dropPath={dropPath}
              onChange={onFieldChange}
              onDeleteClick={onFieldDelete}
            />
          ))}

        <div
          ref={setBottomNodeRef}
          id={`${id}-editor-field-list-bottom`}
          className="editor-field-list-actions"
        >
          <div className="btn btn--light-grey">
            <PlusIcon />
            Drag & Drop Any Field Here
          </div>
        </div>
      </SortableContext>
    </div>
  )
}

EditorFieldList.propTypes = {
  id: PropTypes.string,
  fields: PropTypes.array,
  dropPath: PropTypes.array,
  onFieldChange: PropTypes.func,
  onFieldDelete: PropTypes.func,
}

export default EditorFieldList
