import axios from 'axios'
import { get, isEmpty } from 'lodash'

export const DUPLICATE_WORKFLOW_BEGIN = 'DUPLICATE_WORKFLOW_BEGIN'
export const DUPLICATE_WORKFLOW_ERROR = 'DUPLICATE_WORKFLOW_ERROR'
export const DUPLICATE_WORKFLOW_SUCCESS = 'DUPLICATE_WORKFLOW_SUCCESS'

export const duplicateWorkflow =
  (values, existingContent, successEvent, token) =>
  async (dispatch, getState) => {
    const activeClient = getState().userSelectionsData.activeClient
    const activeOperatingLocation =
      getState().userSelectionsData.activeOperatingLocation
    const activeClientId = get(activeClient, 'id', null)
    const existingWorkflow = get(existingContent, 'workflow', {})
    const existingSteps = get(existingContent, 'steps', [])

    const rehydratedContent = {
      steps: existingSteps.map(step => {
        return {
          ...step,
          status: 'draft',
        }
      }),
      workflow: {
        ...existingWorkflow,
        name: values?.name,
        slug: values?.slug,
        status: 'draft',
      },
    }

    dispatch({ type: DUPLICATE_WORKFLOW_BEGIN })
    return axios({
      url: `${process.env.REACT_APP_PRODUCT_API}/api/v1/workflow/duplicate/${activeClientId}`,
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      data: {
        operatingLocation: activeOperatingLocation,
        content: rehydratedContent,
      },
    })
      .then(response => {
        const workflow = get(response, 'data', [])

        if (!isEmpty(workflow)) {
          dispatch({
            type: DUPLICATE_WORKFLOW_SUCCESS,
            payload: workflow,
          })
          successEvent(response)
        } else {
          dispatch({
            type: DUPLICATE_WORKFLOW_ERROR,
            payload:
              'There was an error duplicating this workflow - please wait a moment, then try again.',
          })
        }
      })
      .catch(error => {
        dispatch({
          type: DUPLICATE_WORKFLOW_ERROR,
          payload: error.message,
        })
      })
  }
