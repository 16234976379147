import {
  FETCH_DUPLICATE_WORKFLOW_BEGIN,
  FETCH_DUPLICATE_WORKFLOW_SUCCESS,
  FETCH_DUPLICATE_WORKFLOW_ERROR,
} from './fetchSanitizedWorkflow'

import {
  DUPLICATE_COMPONENT_BEGIN,
  DUPLICATE_COMPONENT_ERROR,
  DUPLICATE_COMPONENT_SUCCESS,
} from '../components/duplicateComponent'

import {
  DUPLICATE_STEP_BEGIN,
  DUPLICATE_STEP_ERROR,
  DUPLICATE_STEP_SUCCESS,
} from '../steps/duplicateStep'

import {
  DUPLICATE_WORKFLOW_BEGIN,
  DUPLICATE_WORKFLOW_ERROR,
  DUPLICATE_WORKFLOW_SUCCESS,
} from '../workflows/duplicateWorkflow'

import {
  DUPLICATE_WORKFLOW_CROSS_CLIENT_BEGIN,
  DUPLICATE_WORKFLOW_CROSS_CLIENT_ERROR,
  DUPLICATE_WORKFLOW_CROSS_CLIENT_SUCCESS,
} from '../workflows/duplicateCrossClientWorkflow'

export const RESET_DUPLICATE_CONTENT = 'RESET_DUPLICATE_CONTENT'

export const resetDuplicateContent = () => async dispatch => {
  dispatch({ type: RESET_DUPLICATE_CONTENT })
}

// REDUCER
const initialState = {
  error: null,
  loading: false,
  content: null,
}

export default function duplicateWorkflowReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_DUPLICATE_WORKFLOW_BEGIN:
    case DUPLICATE_COMPONENT_BEGIN:
    case DUPLICATE_STEP_BEGIN:
    case DUPLICATE_WORKFLOW_BEGIN:
    case DUPLICATE_WORKFLOW_CROSS_CLIENT_BEGIN:
      return { ...state, loading: true }
    case FETCH_DUPLICATE_WORKFLOW_SUCCESS:
      return {
        ...state,
        loading: false,
        content: action.payload,
      }
    case DUPLICATE_COMPONENT_SUCCESS:
    case DUPLICATE_STEP_SUCCESS:
    case DUPLICATE_WORKFLOW_SUCCESS:
    case DUPLICATE_WORKFLOW_CROSS_CLIENT_SUCCESS:
      return {
        ...state,
        loading: false,
      }
    case RESET_DUPLICATE_CONTENT: {
      return {
        ...state,
        content: null,
      }
    }
    case FETCH_DUPLICATE_WORKFLOW_ERROR:
    case DUPLICATE_COMPONENT_ERROR:
    case DUPLICATE_STEP_ERROR:
    case DUPLICATE_WORKFLOW_ERROR:
    case DUPLICATE_WORKFLOW_CROSS_CLIENT_ERROR:
      return { ...state, loading: false, error: action.payload }
    default:
      return state
  }
}

// SELECTORS
export const selectDuplicateContentState = state => state.duplicateContentData
