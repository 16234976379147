import React from 'react'
import PropTypes from 'prop-types'
import { get, isEmpty } from 'lodash'
import { Link, useLocation } from 'react-router-dom'
import '../../../../styles/consumer-flow/tab-navigation/tab-navigation.scss'
import clsx from 'clsx'
import { ReactComponent as DownChevronIcon } from '../../../../assets/icons/arrow-down-icon.svg'

export default function TabNavigation({
  classNames,
  linkOptions,
  linkChildren,
  children,
  isOpen,
  onSetOpen,
}) {
  const location = useLocation()
  let renderLinks = []

  for (let i = 0; i < linkOptions.length; i++) {
    const link = linkOptions[i]
    const linkRoute = get(link, 'link', null)
    const linkLabel = get(link, 'label', null)

    if (linkRoute && linkLabel) {
      renderLinks.push(
        <Link
          key={`tab-link-${i}`}
          className={location.pathname === linkRoute ? 'active' : ''}
          to={linkRoute}
        >
          {linkLabel}
        </Link>,
      )
    }
  }

  return !isEmpty(renderLinks) ? (
    <div
      className={`tab-navigation section section--xs${
        classNames ? ' ' + classNames : ''
      }`}
    >
      <div className="tab-navigation__link-row">
        <div className="tab-navigation__links">
          {renderLinks}
          {linkChildren && linkChildren}
        </div>
      </div>

      <div
        className={clsx('tab-navigation__content', {
          'tab-navigation__content-visible': isOpen,
        })}
      >
        {children}
      </div>
      {!!children ? (
        <div className="tab-navigation__toggle-container">
          <button
            className="tab-navigation__toggle btn btn--transparent btn--no-border"
            onClick={() => onSetOpen(!isOpen)}
          >
            {isOpen ? (
              <>
                Collapse{' '}
                <DownChevronIcon className="tab-navigation__open-icon rotate-180" />
              </>
            ) : (
              <>
                Expand <DownChevronIcon className="tab-navigation__open-icon" />
              </>
            )}
          </button>
        </div>
      ) : null}
    </div>
  ) : null
}

TabNavigation.propTypes = {
  children: PropTypes.node,
  classNames: PropTypes.string,
  isOpen: PropTypes.bool,
  onSetOpen: PropTypes.func,
  linkChildren: PropTypes.node,
  linkOptions: PropTypes.array.isRequired,
}
