import React from 'react'
import PropTypes from 'prop-types'

function ComputerListIcon({ colour }, props) {
  return (
    <svg
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M36.0523 2.71009H3.94766C2.8737 2.71009 2 3.58372 2 4.65774V27.387C2 28.4609 2.8737 29.3346 3.94766 29.3346H7.0625C7.3538 29.3346 7.58984 29.0986 7.58984 28.8073C7.58984 28.516 7.3538 28.2799 7.0625 28.2799H3.94766C3.45526 28.2799 3.05469 27.8794 3.05469 27.387V4.65774C3.05469 4.16534 3.45526 3.76477 3.94766 3.76477H36.0523C36.5447 3.76477 36.9453 4.16534 36.9453 4.65774V27.387C36.9453 27.8794 36.5447 28.2799 36.0523 28.2799H9.52344C9.23213 28.2799 8.99609 28.516 8.99609 28.8073C8.99609 29.0986 9.23213 29.3346 9.52344 29.3346H15.1794C15.079 30.2608 14.6648 32.2671 12.9672 33.962H11.1927C10.6669 33.962 10.2391 34.3897 10.2391 34.9154V36.3364C10.2391 36.8622 10.6669 37.2899 11.1927 37.2899H24.3095C24.6008 37.2899 24.8368 37.0539 24.8368 36.7626C24.8368 36.4713 24.6008 36.2352 24.3095 36.2352H11.2939V35.0167H28.7061V36.2352H26.7704C26.4791 36.2352 26.243 36.4713 26.243 36.7626C26.243 37.0539 26.4791 37.2899 26.7704 37.2899H28.8073C29.3331 37.2899 29.7609 36.8622 29.7609 36.3364V34.9154C29.7609 34.3897 29.3331 33.962 28.8073 33.962H27.0329C25.3447 32.2754 24.926 30.263 24.8225 29.3346H36.0523C37.1263 29.3346 38 28.4609 38 27.387V4.65774C38 3.58372 37.1263 2.71009 36.0523 2.71009ZM25.6205 33.9619H14.3795C15.8105 32.1614 16.1577 30.2463 16.2405 29.3345H23.7595C23.8423 30.2464 24.1895 32.1614 25.6205 33.9619Z"
        fill={colour}
      />
      <path
        d="M31.6923 24.9309H35.1994C35.4907 24.9309 35.7268 24.6949 35.7268 24.4036V5.51063C35.7268 5.21932 35.4907 4.98329 35.1994 4.98329H4.80054C4.50923 4.98329 4.27319 5.21932 4.27319 5.51063V24.4036C4.27319 24.6949 4.50923 24.9309 4.80054 24.9309H29.2313C29.5226 24.9309 29.7587 24.6949 29.7587 24.4036C29.7587 24.1123 29.5226 23.8763 29.2313 23.8763H5.32788V6.03797H34.6721V23.8763H31.6923C31.401 23.8763 31.1649 24.1123 31.1649 24.4036C31.1649 24.6949 31.401 24.9309 31.6923 24.9309Z"
        fill={colour}
      />
      <path
        d="M10.8676 8.42256C10.8676 8.13125 10.6316 7.89521 10.3403 7.89521H7.073C6.78169 7.89521 6.54565 8.13125 6.54565 8.42256V11.2636C6.54565 11.5549 6.78169 11.7909 7.073 11.7909H10.3403C10.6316 11.7909 10.8676 11.5549 10.8676 11.2636V8.42256ZM9.81294 10.7363H7.60034V8.9499H9.81294V10.7363Z"
        fill={colour}
      />
      <path
        d="M13.1814 11.7909H21.9887C22.28 11.7909 22.5161 11.5549 22.5161 11.2636C22.5161 10.9723 22.28 10.7363 21.9887 10.7363H13.1814C12.8901 10.7363 12.6541 10.9723 12.6541 11.2636C12.6541 11.5549 12.8902 11.7909 13.1814 11.7909Z"
        fill={colour}
      />
      <path
        d="M24.5457 7.89521H17.301C17.0097 7.89521 16.7737 8.13125 16.7737 8.42256C16.7737 8.71386 17.0097 8.9499 17.301 8.9499H24.5457C24.837 8.9499 25.0731 8.71386 25.0731 8.42256C25.0731 8.13125 24.837 7.89521 24.5457 7.89521Z"
        fill={colour}
      />
      <path
        d="M13.1814 8.9499H14.815C15.1063 8.9499 15.3423 8.71386 15.3423 8.42256C15.3423 8.13125 15.1063 7.89521 14.815 7.89521H13.1814C12.8901 7.89521 12.6541 8.13125 12.6541 8.42256C12.6541 8.71386 12.8902 8.9499 13.1814 8.9499Z"
        fill={colour}
      />
      <path
        d="M10.8676 13.5365C10.8676 13.2451 10.6316 13.0091 10.3403 13.0091H7.073C6.78169 13.0091 6.54565 13.2451 6.54565 13.5365V16.3775C6.54565 16.6688 6.78169 16.9048 7.073 16.9048H10.3403C10.6316 16.9048 10.8676 16.6688 10.8676 16.3775V13.5365ZM9.81294 15.8502H7.60034V14.0638H9.81294V15.8502Z"
        fill={colour}
      />
      <path
        d="M13.1814 16.9048H21.9887C22.28 16.9048 22.5161 16.6688 22.5161 16.3775C22.5161 16.0862 22.28 15.8502 21.9887 15.8502H13.1814C12.8901 15.8502 12.6541 16.0862 12.6541 16.3775C12.6541 16.6688 12.8902 16.9048 13.1814 16.9048Z"
        fill={colour}
      />
      <path
        d="M24.5457 13.0091H17.301C17.0097 13.0091 16.7737 13.2451 16.7737 13.5365C16.7737 13.8278 17.0097 14.0638 17.301 14.0638H24.5457C24.837 14.0638 25.0731 13.8278 25.0731 13.5365C25.0731 13.2451 24.837 13.0091 24.5457 13.0091Z"
        fill={colour}
      />
      <path
        d="M13.1814 14.0638H14.815C15.1063 14.0638 15.3423 13.8278 15.3423 13.5365C15.3423 13.2451 15.1063 13.0091 14.815 13.0091H13.1814C12.8901 13.0091 12.6541 13.2451 12.6541 13.5365C12.6541 13.8278 12.8902 14.0638 13.1814 14.0638Z"
        fill={colour}
      />
      <path
        d="M10.3403 18.1231H7.073C6.78169 18.1231 6.54565 18.3591 6.54565 18.6504V21.4915C6.54565 21.7828 6.78169 22.0189 7.073 22.0189H10.3403C10.6316 22.0189 10.8676 21.7828 10.8676 21.4915V18.6504C10.8676 18.3591 10.6316 18.1231 10.3403 18.1231ZM9.81294 20.9642H7.60034V19.1778H9.81294V20.9642Z"
        fill={colour}
      />
      <path
        d="M13.1814 22.0189H21.9887C22.28 22.0189 22.5161 21.7828 22.5161 21.4915C22.5161 21.2002 22.28 20.9642 21.9887 20.9642H13.1814C12.8901 20.9642 12.6541 21.2002 12.6541 21.4915C12.6541 21.7828 12.8902 22.0189 13.1814 22.0189Z"
        fill={colour}
      />
      <path
        d="M25.0731 18.6504C25.0731 18.3591 24.837 18.1231 24.5457 18.1231H17.301C17.0097 18.1231 16.7737 18.3591 16.7737 18.6504C16.7737 18.9417 17.0097 19.1778 17.301 19.1778H24.5457C24.837 19.1778 25.0731 18.9417 25.0731 18.6504Z"
        fill={colour}
      />
      <path
        d="M13.1814 19.1778H14.815C15.1063 19.1778 15.3423 18.9417 15.3423 18.6504C15.3423 18.3591 15.1063 18.1231 14.815 18.1231H13.1814C12.8901 18.1231 12.6541 18.3591 12.6541 18.6504C12.6541 18.9417 12.8902 19.1778 13.1814 19.1778Z"
        fill={colour}
      />
      <path
        d="M33.4537 17.1921C33.4438 16.9681 33.2933 16.7749 33.0785 16.7105L26.9702 14.878C26.7842 14.8222 26.583 14.8731 26.4457 15.0102C26.3086 15.1474 26.2578 15.3488 26.3135 15.5347L28.146 21.643C28.2104 21.8578 28.4036 22.0083 28.6276 22.0182C28.6355 22.0187 28.6435 22.0188 28.6513 22.0188C28.866 22.0188 29.0604 21.8882 29.1408 21.6873L30.2784 18.8429L33.1228 17.7052C33.331 17.622 33.4637 17.4162 33.4537 17.1921ZM29.6769 17.9476C29.5429 18.0013 29.4367 18.1074 29.3831 18.2415L28.7223 19.8935L27.6052 16.1697L31.329 17.2868L29.6769 17.9476Z"
        fill={colour}
      />
    </svg>
  )
}

ComputerListIcon.defaultProps = {
  colour: '#000000',
}

ComputerListIcon.propTypes = {
  colour: PropTypes.string,
}

export default ComputerListIcon
