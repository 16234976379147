import MediaLibraryImageItem from './MediaLibraryImageItem'
import React from 'react'
import '../../../styles/global-components/media-library/media-library-content-grid.scss'
import PropTypes from 'prop-types'
import MediaLibraryDocumentItem from './MediaLibraryDocumentItem'
import MediaLibraryUploadingItem from './MediaLibraryUploadingItem'

const MediaLibraryContentGrid = ({
  items,
  onItemClick,
  onItemDoubleClick,
  selectedItems,
  onRemoveUploadClick,
  isLoading,
}) => {
  const extraItems = items.length % 4 > 0 ? 4 - (items.length % 4) : 0
  return (
    <div className="media-library__content-grid">
      {items.length > 0 && (
        <>
          {items
            .filter(item => !item?.type)
            .map(item => (
              <MediaLibraryUploadingItem
                key={item.id}
                item={item}
                onRemoveClick={onRemoveUploadClick}
              />
            ))}
          {items
            ?.filter(item => item?.type)
            .map(item =>
              item.type === 'image' ? (
                <MediaLibraryImageItem
                  key={item._id}
                  item={item}
                  isSelected={
                    !!selectedItems.find(search => search === item._id)
                  }
                  onClick={onItemClick}
                  onDoubleClick={onItemDoubleClick}
                />
              ) : (
                <MediaLibraryDocumentItem
                  key={item._id}
                  item={item}
                  isSelected={
                    !!selectedItems.find(search => search === item._id)
                  }
                  onClick={onItemClick}
                  onDoubleClick={onItemDoubleClick}
                />
              ),
            )}
          {Array(extraItems)
            .fill(1)
            .map((unused, i) => (
              <div
                key={i}
                className="media-library__content-grid__empty-item"
              ></div>
            ))}
        </>
      )}
      {items.length === 0 && !isLoading && (
        <div className="media-library__content-grid__no-items">
          <div>
            <h3>No files found</h3>
            <p>
              There are no files in this folder. Drop files from your computer
              here to upload or select Upload File above.
            </p>
          </div>
        </div>
      )}
    </div>
  )
}

MediaLibraryContentGrid.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string,
    }),
  ),
  isLoading: PropTypes.bool,
  onItemClick: PropTypes.func,
  onItemDoubleClick: PropTypes.func,
  onRemoveUploadClick: PropTypes.func,
  selectedItems: PropTypes.arrayOf(PropTypes.string),
}

export default MediaLibraryContentGrid
