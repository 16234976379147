import React, { useState } from 'react'
import Modal from '../../global-components/elements/Modal'
import { ReactComponent as EditIcon } from '../../../assets/icons/pencil-icon.svg'
import { ReactComponent as DeleteIcon } from '../../../assets/icons/delete-icon.svg'
import { ReactComponent as CloseModalIcon } from '../../../assets/icons/close-icon.svg'
import '../../../styles/global-components/forms/subcategories-field.scss'
import { sortBy } from 'lodash'
import PropTypes from 'prop-types'

function SubcategoriesField({ value, onChange }) {
  const [newSubcategoryModalIsOpen, setNewSubcategoryModalIsOpen] =
    useState(false)
  const [renameSubcategoryModalIsOpen, setRenameSubcategoryModalIsOpen] =
    useState(false)
  const [newSubcategory, setNewSubcategory] = useState('')
  const [renameSubcategory, setRenameSubcategory] = useState({})

  function formatSubcategory(subcategory) {
    return subcategory.replace(/(^\w|\s\w)/g, m => m.toUpperCase()).trim()
  }

  function handleNewSubcategoryClick() {
    setNewSubcategoryModalIsOpen(true)
    setNewSubcategory('')
  }

  function handleRenameSubcategoryClick(subcategory) {
    setRenameSubcategoryModalIsOpen(true)
    setRenameSubcategory({ ...subcategory })
  }

  function handleDeleteSubcategoryClick(subcategory) {
    onChange(value.filter(search => search.value !== subcategory.value))
  }

  function submitNewSubcategory() {
    const subcategory = formatSubcategory(newSubcategory)
    if (!value.find(search => search.label === subcategory)) {
      onChange(
        sortBy(
          [
            ...value,
            {
              value: subcategory,
              label: subcategory,
              isNew: true,
            },
          ],
          'label',
        ),
      )
    }

    setNewSubcategoryModalIsOpen(false)
  }

  function submitRenameSubcategory() {
    const subcategory = formatSubcategory(renameSubcategory.label)
    if (!value.find(search => search.label === subcategory)) {
      onChange(
        sortBy(
          value.map(item => {
            if (item.value === renameSubcategory.value) {
              return {
                ...item,
                label: subcategory,
              }
            }
            return item
          }),
          'label',
        ),
      )
    }
    setRenameSubcategoryModalIsOpen(false)
  }

  function closeNewSubcategoryModal() {
    setNewSubcategoryModalIsOpen(false)
  }

  function closeRenameSubcategoryModal() {
    setRenameSubcategoryModalIsOpen(false)
  }

  return (
    <div className="subcategories-field">
      <div className="subcategories-field__label-container">
        <div className="subcategories-field__label">Subcategories</div>
        <div>
          <button
            className="btn btn--white"
            type="button"
            onClick={handleNewSubcategoryClick}
          >
            Add Subcategory
          </button>
        </div>
      </div>

      <div className="subcategories-field__subcategories">
        {value?.length > 0
          ? value.map(subcategory => (
              <div
                className="subcategories-field__subcategory"
                key={subcategory.label}
              >
                <div className="subcategories-field__subcategory-label">
                  {subcategory.label}
                </div>
                <div className="subcategories-field__subcategory-actions">
                  <button
                    className="btn--transparent btn--no-border"
                    data-tooltip="Rename Subcategory"
                    type="button"
                    onClick={() => handleRenameSubcategoryClick(subcategory)}
                  >
                    <EditIcon />
                  </button>
                  <button
                    className="btn--transparent btn--no-border"
                    data-tooltip="Remove Subcategory"
                    type="button"
                    onClick={() => handleDeleteSubcategoryClick(subcategory)}
                  >
                    <DeleteIcon />
                  </button>
                </div>
              </div>
            ))
          : null}
      </div>

      <Modal
        isOpen={newSubcategoryModalIsOpen}
        onRequestClose={closeNewSubcategoryModal}
        modalClassName="modal--sm"
      >
        <div className="modal__header">
          <h3>New Subcategory</h3>
          <button className="modal__close" onClick={closeNewSubcategoryModal}>
            <CloseModalIcon />
          </button>
        </div>
        <div className="modal__body">
          <div className="field">
            <label>Name *</label>
            <input
              type="text"
              value={newSubcategory}
              onChange={evt => setNewSubcategory(evt.target.value)}
            />
          </div>
        </div>
        <div className="modal__submit">
          <button
            className="btn btn--white"
            type="button"
            onClick={closeNewSubcategoryModal}
          >
            Cancel
          </button>

          <button
            className="btn btn--dark-purple"
            disabled={!newSubcategory}
            onClick={submitNewSubcategory}
            type="submit"
          >
            Add Subcategory
          </button>
        </div>
      </Modal>

      <Modal
        isOpen={renameSubcategoryModalIsOpen}
        onRequestClose={closeRenameSubcategoryModal}
        modalClassName="modal--sm"
      >
        <div className="modal__header">
          <h3>Rename Subcategory</h3>
          <button
            className="modal__close"
            onClick={closeRenameSubcategoryModal}
          >
            <CloseModalIcon />
          </button>
        </div>
        <div className="modal__body">
          <div className="field">
            <label>Name *</label>
            <input
              type="text"
              value={renameSubcategory.label}
              onChange={evt =>
                setRenameSubcategory({
                  ...renameSubcategory,
                  label: evt.target.value,
                })
              }
            />
          </div>
        </div>
        <div className="modal__submit">
          <button
            className="btn btn--white"
            type="button"
            onClick={closeRenameSubcategoryModal}
          >
            Cancel
          </button>

          <button
            className="btn btn--dark-purple"
            disabled={!renameSubcategory.label}
            onClick={submitRenameSubcategory}
            type="submit"
          >
            Save
          </button>
        </div>
      </Modal>
    </div>
  )
}

SubcategoriesField.propTypes = {
  value: PropTypes.array,
  onChange: PropTypes.func,
}

export default SubcategoriesField
