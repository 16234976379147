import PropTypes from 'prop-types'
import { EDITOR_TABLE } from '../../../../configuration/editor'
import EditorTableField, {
  createTableField,
} from '../../base-fields/table-field/EditorTableField'

const EditorTableComponent = ({ component, onChange, dropPath }) => {
  const subcomponent = component?.subComponents?.[0] || {}
  const { fields } = subcomponent || []
  const field = fields[0]
  function handleFieldChange(changeSet) {
    onChange({
      ...component,
      subComponents: [
        {
          ...subcomponent,
          fields: [{ ...field, blocks: changeSet.blocks }],
        },
      ],
    })
  }
  return (
    <div>
      <div>
        <EditorTableField
          field={field}
          onChange={handleFieldChange}
          readOnly={false}
        />
      </div>
    </div>
  )
}

export const createEmptyTable = () => {
  const id = `temp-${Date.now()}-sc`
  const fieldId = `temp-${Date.now()}-field`
  return {
    id,
    type: 'subComponent',
    subComponentType: 'table',
    configurations: {},
    fields: [createTableField(fieldId)],
  }
}

export const createTableComponent = id => {
  return {
    id,
    componentType: EDITOR_TABLE,
    configurations: {},
    subComponents: [createEmptyTable()],
  }
}

EditorTableComponent.propTypes = {
  component: PropTypes.object,
  onChange: PropTypes.func,
  dropPath: PropTypes.array,
}

export default EditorTableComponent
