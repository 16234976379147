import React from 'react'
import PropTypes from 'prop-types'

function LocationIcon({ colour }, props) {
  return (
    <svg
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M19.9999 2C12.5663 2 6.51868 8.04765 6.51868 15.4812C6.51868 21.7546 10.1806 27.5116 12.9562 31.0569C16.097 35.0688 19.1972 37.6501 19.3276 37.7579C19.5227 37.9194 19.7613 38 20 38C20.2386 38 20.4773 37.9194 20.6723 37.7579C20.8027 37.6501 23.9029 35.0688 27.0437 31.0569C29.8193 27.5116 33.4813 21.7547 33.4813 15.4812C33.4811 8.04765 27.4335 2 19.9999 2ZM25.4094 29.7225C23.2515 32.4845 21.0684 34.5755 20.0005 35.542C17.4651 33.2431 8.62805 24.5759 8.62805 15.4812C8.62805 9.21083 13.7294 4.10938 19.9999 4.10938C26.2704 4.10938 31.3718 9.21083 31.3718 15.4812C31.3718 20.9442 28.1294 26.241 25.4094 29.7225Z"
        fill={colour}
      />
      <path
        d="M20 6.51877C15.0581 6.51877 11.0375 10.5394 11.0375 15.4813C11.0375 20.4232 15.0581 24.4437 20 24.4437C24.9419 24.4437 28.9625 20.4231 28.9625 15.4812C28.9625 10.5393 24.9419 6.51877 20 6.51877ZM20 22.3344C16.2212 22.3344 13.1469 19.26 13.1469 15.4812C13.1469 11.7024 16.2212 8.62814 20 8.62814C23.7788 8.62814 26.8531 11.7025 26.8531 15.4813C26.8531 19.2601 23.7788 22.3344 20 22.3344Z"
        fill={colour}
      />
    </svg>
  )
}

LocationIcon.defaultProps = {
  colour: '#000000',
}

LocationIcon.propTypes = {
  colour: PropTypes.string,
}

export default LocationIcon
