import axios from 'axios'
import { get } from 'lodash'
import { fetchWorkflow } from '../workflows/workflow'

export const DELETE_STEP_BEGIN = 'DELETE_STEP_BEGIN'
export const DELETE_STEP_ERROR = 'DELETE_STEP_ERROR'
export const DELETE_STEP_SUCCESS = 'DELETE_STEP_SUCCESS'

export const deleteStep =
  (stepId, workflowId, workflowSlug, successEvent, token) =>
  async (dispatch, getState) => {
    const activeClient = getState().userSelectionsData.activeClient
    const activeClientId = get(activeClient, 'id', null)
    const activeOperatingLocation =
      getState().userSelectionsData.activeOperatingLocation

    dispatch({ type: DELETE_STEP_BEGIN })
    return axios({
      url: `${process.env.REACT_APP_PRODUCT_API}/api/v1/workflow/step/delete/${workflowId}`,
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      data: {
        clientId: activeClientId,
        operatingLocation: activeOperatingLocation,
        stepId: stepId,
        workflowSlug: workflowSlug,
      },
    })
      .then(() => {
        dispatch({
          type: DELETE_STEP_SUCCESS,
        })
        dispatch(fetchWorkflow(workflowSlug, token))
        successEvent()
      })
      .catch(error => {
        dispatch({
          type: DELETE_STEP_ERROR,
          payload: error.message,
        })
      })
  }

// REDUCER
const initialState = {
  error: null,
  loading: false,
}

export default function deleteStepsReducer(state = initialState, action) {
  switch (action.type) {
    case DELETE_STEP_BEGIN:
      return { ...state, loading: true }
    case DELETE_STEP_SUCCESS:
      return {
        ...state,
        loading: false,
      }
    case DELETE_STEP_ERROR:
      return { ...state, loading: false, error: action.payload }
    default:
      return state
  }
}

// SELECTORS
export const selectDeleteStepsState = state => state.deleteStepsData
