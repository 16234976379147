import Modal from 'react-modal'
import React from 'react'
import { ReactComponent as CloseModalIcon } from '../../../../assets/icons/close-icon.svg'
import { Field, Form, Formik } from 'formik'
import * as Yup from 'yup'
import TextField from '../../../forms/elements/TextField'
import convertStringToSlug from '../../../../utilities/slug/convertStringToSlug'
import MaterialLoader from '../../elements/MaterialLoader'
import PropTypes from 'prop-types'

const MediaLibraryCreateFolderModal = ({
  values,
  isOpen,
  onCancel,
  onSubmit,
  isLoading,
}) => {
  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Required'),
  })
  const submitLabel = values?.name ? 'Save' : 'Create'
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onCancel}
      className="modal modal--sm modal--overflow-hidden"
      overlayClassName="modal-overlay"
    >
      <div className="modal__header">
        <h3>{values?.name ? 'Edit' : 'Create'} Folder</h3>

        <button className="modal__close" onClick={onCancel}>
          <CloseModalIcon />
        </button>
      </div>

      <div className="modal__body">
        <Formik
          initialValues={values}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
        >
          {({ isValid, setFieldValue, values }) => {
            const submitButtonDisabled = !isValid

            return (
              <Form className="form form--modal">
                <div className="form__field full">
                  <Field
                    component={TextField}
                    label="Name"
                    name="name"
                    placeholder="Enter Folder Name"
                    required={true}
                    type="text"
                  />
                </div>
                <div className="form__submit">
                  <>
                    <button
                      className="btn btn--white"
                      type="button"
                      onClick={onCancel}
                    >
                      Cancel
                    </button>

                    <button
                      className="btn btn--dark-purple"
                      disabled={submitButtonDisabled}
                      type="submit"
                    >
                      {submitButtonDisabled
                        ? 'Please ensure all field values are valid'
                        : submitLabel}
                    </button>
                  </>
                </div>
              </Form>
            )
          }}
        </Formik>
      </div>
      {isLoading && <MaterialLoader containerClasses="overlay-loader" />}
    </Modal>
  )
}

MediaLibraryCreateFolderModal.propTypes = {
  values: PropTypes.shape({
    name: PropTypes.string,
  }),
  isOpen: PropTypes.bool,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  isLoading: PropTypes.bool,
}

export default MediaLibraryCreateFolderModal
