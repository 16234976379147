import { SOCKET_SET_SOCKET_ID } from '../../../socket/socket'

const onSetSocketId = ({ dispatch, args }) => {
  const { socketId } = args
  dispatch({
    type: SOCKET_SET_SOCKET_ID,
    payload: socketId,
  })
}

export default onSetSocketId
