import React from 'react'
import PropTypes from 'prop-types'

function FoldersIcon({ colour }, props) {
  return (
    <svg
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M13.5191 19.6808C12.8429 19.6808 12.2928 20.231 12.2928 20.9072V23.3679C12.2928 24.0442 12.8429 24.5943 13.5191 24.5943H26.4808C27.1571 24.5943 27.7072 24.0442 27.7072 23.3679V20.9072C27.7072 20.231 27.1571 19.6808 26.4808 19.6808H13.5191ZM26.6525 20.9072V23.3679C26.6525 23.4626 26.5755 23.5396 26.4808 23.5396H13.5191C13.4244 23.5396 13.3474 23.4626 13.3474 23.3679V20.9072C13.3474 20.8125 13.4244 20.7355 13.5191 20.7355H26.4808C26.5755 20.7355 26.6525 20.8125 26.6525 20.9072Z"
        fill={colour}
      />
      <path
        d="M37.4727 21.4425C37.7639 21.4425 38 21.2064 38 20.9151V17.0781C38 16.2321 37.3943 15.5251 36.5938 15.3674C36.5938 15.0405 36.5938 7.17039 36.5938 6.80659C36.5938 5.6875 35.6833 4.77702 34.5642 4.77702H15.0814C14.6449 4.77702 14.2288 4.58922 13.94 4.26177L13.1471 3.36262C12.6582 2.8082 11.9539 2.49025 11.2147 2.49025H5.20013C4.08104 2.49025 3.17056 3.40066 3.17056 4.51975V15.4315C2.48987 15.6692 2 16.3173 2 17.078V35.7661C2 36.7276 2.78223 37.5097 3.74368 37.5097H36.2563C37.2178 37.5097 38 36.7275 38 35.7661V23.37C38 23.0788 37.7639 22.8427 37.4727 22.8427C37.1814 22.8427 36.9453 23.0788 36.9453 23.37V35.7661C36.9453 36.146 36.6362 36.4551 36.2563 36.4551H3.74368C3.36378 36.4551 3.05469 36.146 3.05469 35.7661V17.078C3.05469 16.7133 3.33959 16.4148 3.69847 16.3913C3.70459 16.3913 3.7107 16.3912 3.71682 16.391C3.72744 16.3905 3.73827 16.39 3.7524 16.389H6.66404C6.95527 16.389 7.19138 16.1529 7.19138 15.8616C7.19138 15.5704 6.95527 15.3343 6.66404 15.3343H4.22518V9.8976C4.22518 9.36006 4.66252 8.92279 5.20006 8.92279H11.2146C11.6512 8.92279 12.0672 9.11059 12.356 9.43804L13.149 10.3372C13.6379 10.8915 14.3422 11.2095 15.0814 11.2095H34.5642C35.1017 11.2095 35.539 11.6468 35.539 12.1843V15.3343H9.12941C8.83817 15.3343 8.60206 15.5704 8.60206 15.8616C8.60206 16.1529 8.83817 16.389 9.12941 16.389H36.2563C36.6362 16.389 36.9453 16.6981 36.9453 17.078V20.9151C36.9453 21.2064 37.1814 21.4425 37.4727 21.4425ZM35.5391 10.4048C35.2496 10.2456 34.9174 10.1548 34.5642 10.1548H15.0814C14.6449 10.1548 14.2288 9.967 13.94 9.63955L13.1471 8.74047C12.6582 8.18605 11.9539 7.8681 11.2147 7.8681H5.20013C4.84702 7.8681 4.5148 7.95894 4.22525 8.11813V4.51982C4.22525 3.98228 4.66259 3.54501 5.20013 3.54501H11.2147C11.6512 3.54501 12.0673 3.73281 12.356 4.06026L13.1491 4.95941C13.6381 5.51376 14.3424 5.83171 15.0815 5.83171H34.5643C35.1019 5.83171 35.5391 6.26905 35.5391 6.80659V10.4048H35.5391Z"
        fill={colour}
      />
    </svg>
  )
}

FoldersIcon.defaultProps = {
  colour: '#000000',
}

FoldersIcon.propTypes = {
  colour: PropTypes.string,
}

export default FoldersIcon
