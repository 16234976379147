import {
  selectActiveClientId,
  selectActiveOperatingLocation,
} from '../../../user/userSelections'
import { SOCKET_SET_OUT_OF_DATE_RESOURCES } from '../../../socket/socket'

const onConnect = ({ state, dispatch, socket }) => {
  const clientId = selectActiveClientId(state)
  socket.emit('setClient', {
    clientId,
  })

  dispatch({
    type: SOCKET_SET_OUT_OF_DATE_RESOURCES,
    payload: [],
  })
}

export default onConnect
