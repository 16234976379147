import axios from 'axios'
import processFindReplaceInternalLinkApiData from '../../utilities/find-replace/processFindReplaceInternalLinkApiData'

async function fetchFindInternalLink({ activeClientId, token, find }) {
  return axios({
    url: `${process.env.REACT_APP_PRODUCT_API}/api/v1/find-replace/search/internal-link/${activeClientId}`,
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    data: {
      category: find?.category,
      internalType: find?.internalType,
      workflow: find?.workflow,
      step: find?.step,
    },
  }).then(res =>
    processFindReplaceInternalLinkApiData({
      data: res?.data?.results || [],
      find,
    }),
  )
}

export default fetchFindInternalLink
