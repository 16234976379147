import axios from 'axios'
import { getUserApiRequirements } from '../user/user'
import { SET_ACTIVE_CLIENT } from '../user/userSelections'

export const FETCH_MEDIA_LIBRARY_TREE_BEGIN = 'FETCH_MEDIA_LIBRARY_TREE_BEGIN'
export const FETCH_MEDIA_LIBRARY_TREE_ERROR = 'FETCH_MEDIA_LIBRARY_TREE_ERROR'
export const FETCH_MEDIA_LIBRARY_TREE_SUCCESS =
  'FETCH_MEDIA_LIBRARY_TREE_SUCCESS'

export const fetchMediaLibraryTree = token => (dispatch, getState) => {
  const activeClient = getState().userSelectionsData.activeClient
  const activeClientId = activeClient?.id
  dispatch({ type: FETCH_MEDIA_LIBRARY_TREE_BEGIN })
  return axios
    .post(
      `${process.env.REACT_APP_PRODUCT_API}/api/v1/mediaFolder/${activeClientId}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
    .then(res => {
      const data = reduceTreeData(res?.data?.data)
      dispatch({
        type: FETCH_MEDIA_LIBRARY_TREE_SUCCESS,
        payload: data,
      })
    })
}

export const createMediaLibraryFolder =
  (token, name, parentFolderId) => (dispatch, getState) => {
    const { activeClientId } = getUserApiRequirements(getState())
    dispatch({ type: FETCH_MEDIA_LIBRARY_TREE_BEGIN })
    return axios
      .post(
        `${process.env.REACT_APP_PRODUCT_API}/api/v1/mediaFolder/create/${activeClientId}`,
        {
          name,
          folderId: parentFolderId,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )
      .then(() => dispatch(fetchMediaLibraryTree(token)))
      .catch(err => {
        dispatch({ type: FETCH_MEDIA_LIBRARY_TREE_ERROR, payload: err })
        return Promise.reject(err)
      })
  }

export const editMediaLibraryFolder =
  (token, id, values) => (dispatch, getState) => {
    const { activeClientId } = getUserApiRequirements(getState())
    dispatch({ type: FETCH_MEDIA_LIBRARY_TREE_BEGIN })
    return axios
      .post(
        `${process.env.REACT_APP_PRODUCT_API}/api/v1/mediaFolder/update/${activeClientId}`,
        {
          id,
          ...values,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )
      .then(() => dispatch(fetchMediaLibraryTree(token)))
      .catch(err => {
        dispatch({ type: FETCH_MEDIA_LIBRARY_TREE_ERROR, payload: err })
        return Promise.reject(err)
      })
  }

export const deleteMediaLibraryFolder = (token, id) => (dispatch, getState) => {
  const { activeClientId } = getUserApiRequirements(getState())
  dispatch({ type: FETCH_MEDIA_LIBRARY_TREE_BEGIN })
  return axios
    .post(
      `${process.env.REACT_APP_PRODUCT_API}/api/v1/mediaFolder/delete/${activeClientId}`,
      {
        id,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
    .then(() => dispatch(fetchMediaLibraryTree(token)))
    .catch(err => {
      dispatch({ type: FETCH_MEDIA_LIBRARY_TREE_ERROR, payload: err })
      return Promise.reject(err)
    })
}

function reduceTreeData(data) {
  const formatted = {
    null: {
      name: 'Home',
      type: 'folder',
      path: [],
      children: getChildren({ _id: null }),
    },
    searchResults: {
      _id: 'searchResults',
      name: 'Search Results',
      type: 'folder',
      path: [],
      children: [],
    },
  }
  data.forEach(folder => {
    formatted[folder._id] = {
      ...folder,
      path: getPath(folder),
      children: getChildren(folder),
    }
  })

  return formatted

  function getPath(folder) {
    const path = []
    let parentId = folder.parent
    let i = 0
    while (parentId !== null) {
      const parentFolder = data.find(item => item._id === parentId)
      path.unshift(parentFolder)
      parentId = parentFolder.parent
      if (i++ > 10) {
        break
      }
    }

    return path
  }

  function getChildren(folder) {
    return data
      .filter(item => item.parent === folder._id)
      .map(item => ({
        ...item,
        type: 'folder',
      }))
  }
}

// REDUCER
const initialState = {
  error: null,
  loading: false,
  data: null,
}

export default function mediaLibraryReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_MEDIA_LIBRARY_TREE_BEGIN:
      return { ...state, loading: true }
    case FETCH_MEDIA_LIBRARY_TREE_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      }
    case FETCH_MEDIA_LIBRARY_TREE_ERROR:
      return { ...state, loading: false, error: action.payload }
    case SET_ACTIVE_CLIENT:
      return initialState
    default:
      return state
  }
}

// SELECTORS
export const selectMediaLibraryTreeState = state => state.mediaLibraryTreeData
