import axios from 'axios'

export const updateStaffDefaultClient = (clientId, token) => {
  return axios({
    url: `${process.env.REACT_APP_USERS_API}/api/v1/staff/default-client/${clientId}`,
    method: 'POST',
    headers: {
      authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  })
    .then(() => {
      // No action required
    })
    .catch(err => {
      console.log(err)
    })
}
