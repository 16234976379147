const defaultBrandColours = [
  {
    css: 'rgb(0,0,0)',
    hex: '#000000',
  },
  {
    css: 'rgb(26, 29, 31)',
    hex: '#1a1d1f',
  },
  {
    css: 'rgb(71, 84, 103)',
    hex: '#475467',
  },
  {
    css: 'rgb(102, 112, 133)',
    hex: '#667085',
  },
  {
    css: 'rgba(152, 162, 179)',
    hex: '#98A2B3',
  },
  {
    css: 'rgba(255, 255, 255)',
    hex: '#FFFFFF',
  },
  {
    css: 'rgb(82, 14, 129)',
    hex: '#520E81',
  },
  {
    css: 'rgb(105, 18, 166)',
    hex: '#6912A6',
  },
  {
    css: 'rgb(159, 53, 233)',
    hex: '#9F35E9',
  },
  {
    css: 'rgba(97, 114, 243)',
    hex: '#6172F3',
  },
  {
    css: 'rgb(128, 152, 249)',
    hex: '#8098F9',
  },
  {
    css: 'rgb(253, 162, 155)',
    hex: '#FDA29B',
  },
  {
    css: 'rgb(253, 162, 155)',
    hex: '#FDA29B',
  },
  {
    css: 'rgb(249, 112, 102)',
    hex: '#F97066',
  },
  {
    css: 'rgb(147,65,71)',
    hex: '#934147',
  },
  {
    css: 'rgb(255, 38, 21)',
    hex: '#FF2615',
  },
  {
    css: 'rgb(196, 32, 44)',
    hex: '#C4202C',
  },
  {
    css: 'rgb(16, 138, 144)',
    hex: '#108A90',
  },
  {
    css: 'rgb(19, 163, 170)',
    hex: '#13A3AA',
  },
  {
    css: 'rgba(158, 165, 209, 1)',
    hex: '#9EA5D1',
  },
  {
    css: 'rgb(113, 123, 188)',
    hex: '#717BBC',
  },
  {
    css: 'rgb(62, 71, 132)',
    hex: '#3E4784',
  },
  {
    css: 'rgb(21, 112, 239)',
    hex: '#1570EF',
  },
  {
    css: 'rgb(83, 177, 253)',
    hex: '#53B1FD',
  },
  {
    css: 'rgb(132, 202, 255)',
    hex: '#84CAFF',
  },
  {
    css: 'rgb(21, 112, 239)',
    hex: '#1570EF',
  },
  {
    css: 'rgb(253, 133, 58)',
    hex: '#FD853A',
  },
  {
    css: 'rgb(254, 178, 115)',
    hex: '#FEB273',
  },
]
export default defaultBrandColours
