import React, { useState } from 'react'
import HeaderSearchToggle from '../structure/header/elements/HeaderSearchToggle'
import OperatingLocationsField from './OperatingLocationsField'
import { ReactComponent as NavigationIcon } from '../../../assets/icons/navigation-icon.svg'
import '../../../styles/global-components/search-filters/search-filters.scss'

function SearchFilters() {
  const [isSearchOpen, toggleSearch] = useState(false)

  return (
    <div className={`search-filters${isSearchOpen ? ' open' : ''}`}>
      <button
        className="search-filters__toggle"
        onClick={() => toggleSearch(!isSearchOpen)}
      >
        <NavigationIcon />
      </button>
      <OperatingLocationsField isSearchOpen={isSearchOpen} />
      <HeaderSearchToggle isSearchOpen={isSearchOpen} />
    </div>
  )
}

SearchFilters.propTypes = {}

export default SearchFilters
