import PropTypes from 'prop-types'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { get } from 'lodash'
import { useDndContext } from '@dnd-kit/core'
import fixTransform from '../../../../utilities/workflows/fixTransform'
import getId from '../../../../utilities/editor/getId'
import { ReactComponent as EditIcon } from '../../../../assets/icons/pencil-icon.svg'
import { ReactComponent as DeleteIcon } from '../../../../assets/icons/delete-icon.svg'
import { ReactComponent as ReorderIcon } from '../../../../assets/icons/reorder-icon.svg'
import { ReactComponent as ImageIcon } from '../../../../assets/icons/image-icon-no-border.svg'
import { ReactComponent as ImageNameIcon } from '../../../../assets/icons/image-component-icon.svg'
import { ReactComponent as ImageDimensionsIcon } from '../../../../assets/icons/image-dimensions-icon.svg'

const EditorGalleryFieldCard = ({
  block,
  index,
  onChange,
  onSelectImage,
  onEditImage,
  onDeleteClick,
  dropPath,
}) => {
  const dropLevel = 'blocks'
  const dndContext = useDndContext()
  const disableDropZones =
    dndContext?.active &&
    dndContext?.active?.data?.current?.dropLevel !== dropLevel
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({
      id: getId(block),
      data: {
        dropPath,
        dropLevel,
      },
    })

  const style = {
    transform: CSS.Transform.toString(fixTransform(transform)),
    transition,
  }

  const dragBindings = disableDropZones
    ? {}
    : {
        style,
        ref: setNodeRef,
        ...attributes,
      }

  const dragListeners = disableDropZones ? {} : { ...listeners }
  const image = get(block, 'image', null)
  const imageName = get(image, 'name', null)
  const imageHeight = get(image, 'height', null)
  const imageWidth = get(image, 'width', null)
  const imageThumbnail = get(image, 'thumbnail.url', null)
  const cardTitle = block?.configurations?.title

  function handleTitleChange(evt) {
    onChange({
      ...block,
      configurations: {
        ...block.configurations,
        title: evt.target.value,
      },
    })
  }

  return (
    <div className="editor-gallery-card" id={getId(block)} {...dragBindings}>
      <div className="editor-gallery-card-drag-handle" {...dragListeners}>
        <ReorderIcon /> Gallery Entry {index + 1}
      </div>

      <button
        className="editor-gallery-card-swap btn btn--light-grey"
        onClick={() => onSelectImage(block)}
        data-tooltip="Change Image"
      >
        <ImageIcon />
      </button>

      <button
        className="editor-gallery-card-edit btn btn--light-blue"
        onClick={() => onEditImage(block, image)}
        data-tooltip="Edit Image"
      >
        <EditIcon />
      </button>
      <button
        className="editor-gallery-card-delete btn btn--red"
        onClick={() => onDeleteClick(block)}
        data-tooltip="Remove Image"
      >
        <DeleteIcon />
      </button>

      <div className="editor-gallery-card-data">
        <div className="editor-gallery-card-image">
          <img src={imageThumbnail} alt={image?.alt} />
        </div>

        <div className="editor-gallery-card-content">
          <div>
            <input
              type="text"
              placeholder="Title"
              onChange={handleTitleChange}
              value={cardTitle}
            />
          </div>
          <div>
            {imageName ? (
              <p className="editor-gallery-card-image-title">
                <ImageNameIcon /> {imageName}
              </p>
            ) : null}

            {imageHeight ? (
              <p className="editor-gallery-card-image-dimensions">
                <ImageDimensionsIcon /> {imageHeight}
                {imageWidth ? 'x' + imageWidth : null}
              </p>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  )
}

EditorGalleryFieldCard.propTypes = {
  block: PropTypes.object,
  index: PropTypes.number,
  onChange: PropTypes.func,
  onSelectImage: PropTypes.func,
  onDeleteClick: PropTypes.func,
  onEditImage: PropTypes.func,
  dropPath: PropTypes.array,
}

export default EditorGalleryFieldCard
