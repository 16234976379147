// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.browse-workflow {
  display: flex;
  flex-wrap: wrap;
  margin: 20px 0 0;
  width: 100%;
  padding-bottom: 48px;
}
.browse-workflow__heading {
  margin: 0 0 30px 0;
}
.browse-workflow__heading h3 {
  margin: 0 0 15px 0;
}
.browse-workflow__heading p {
  margin: 0;
}

.browse-workflows .no-workflows {
  padding-bottom: 48px;
}`, "",{"version":3,"sources":["webpack://./src/styles/consumer-flow/browse-workflow/browse-workflow.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,eAAA;EACA,gBAAA;EACA,WAAA;EACA,oBAAA;AADF;AAGE;EACE,kBAAA;AADJ;AAGI;EACE,kBAAA;AADN;AAII;EACE,SAAA;AAFN;;AAQE;EACE,oBAAA;AALJ","sourcesContent":["@import '../../configuration/variables';\n\n.browse-workflow {\n  display: flex;\n  flex-wrap: wrap;\n  margin: 20px 0 0;\n  width: 100%;\n  padding-bottom: 48px;\n\n  &__heading {\n    margin: 0 0 30px 0;\n\n    h3 {\n      margin: 0 0 15px 0;\n    }\n\n    p {\n      margin: 0;\n    }\n  }\n}\n\n.browse-workflows {\n  .no-workflows {\n    padding-bottom: 48px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
