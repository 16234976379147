import RichText from '../../consumer-flow/base-fields/RichText'
import FindReplaceWorkflowPreview from './previews/FindReplaceWorkflowPreview'
import FindReplaceStepPreview from './previews/FindReplaceStepPreview'
import FindReplaceTableHeaderPreview from './previews/FindReplaceTableHeaderPreview'
import ButtonField from '../../consumer-flow/base-fields/button/ButtonField'
import Accordions from '../../consumer-flow/base-components/accordions/Accordions'
import { cloneDeep } from 'lodash'
import '../../../styles/editor-flow/find-replace/find-replace-preview.scss'
import PropTypes from 'prop-types'
import FindReplaceCardPreview from './previews/FindReplaceCardPreview'
import ImageField from '../../consumer-flow/base-fields/image/ImageField'
import FindReplaceAccordionPreview from './previews/FindReplaceAccordionPreview'
import { useEffect, useRef } from 'react'

const FindReplacePreview = ({ item, entry }) => {
  const ref = useRef()
  const contentRef = useRef()
  const innerHTML = ref?.current?.innerHTML
  let preview = <div></div>
  const highlight = entry.replaced
    ? null
    : {
        index: entry.index,
        highlightIndex: entry.highlightIndex,
        match: entry.match,
        find: entry.find,
        path: entry.path,
        type: entry.entryType === 'text' ? 'text' : 'link',
      }
  let type = ''
  switch (item.type) {
    case 'workflow':
      type = 'Workflow'
      preview = <FindReplaceWorkflowPreview item={item} highlight={highlight} />
      break
    case 'step':
      type = 'Step'
      preview = <FindReplaceStepPreview item={item} highlight={highlight} />
      break
    case 'component':
      switch (item.componentType) {
        case 'accordionsAndContent':
          const itemWithoutContent = cloneDeep(item)
          itemWithoutContent.subComponents =
            itemWithoutContent.subComponents.filter(
              subComponent => subComponent.subComponentType === 'accordion',
            )
          type = 'Accordion'
          preview = (
            <FindReplaceAccordionPreview
              item={itemWithoutContent}
              highlight={highlight}
            />
          )

          break
        case 'accordions':
          type = 'Accordion'
          preview = (
            <FindReplaceAccordionPreview item={item} highlight={highlight} />
          )
          break
        case 'cards':
          type = 'Card'
          preview = <FindReplaceCardPreview item={item} index={entry?.index} />
          break
      }
      break
    case 'field':
      switch (item.fieldType) {
        case 'Table':
          const table = item?.blocks?.[0]?.table
          switch (entry.subType) {
            case 'table-cell': {
              const { cellIndex, rowIndex } = entry
              const field = {
                blocks: table?.body?.[rowIndex]?.[cellIndex]?.content,
              }

              let cellHighlight = null
              if (highlight) {
                const pathWithoutTableBlocks = [
                  'blocks',
                  ...highlight.path.slice(7),
                ]

                cellHighlight = {
                  ...highlight,
                  path: pathWithoutTableBlocks,
                }
              }

              type = 'Table Cell'
              preview = <RichText field={field} highlight={cellHighlight} />
              break
            }
            case 'table-header': {
              const { headerIndex } = entry
              const field = {
                blocks: table?.header?.[headerIndex]?.content,
              }
              type = 'Table Header'
              preview = (
                <FindReplaceTableHeaderPreview
                  item={field}
                  highlight={highlight}
                  headerIndex={headerIndex}
                />
              )
              break
            }
          }
          break
        case 'RichText':
          type = 'Text Content'
          preview = <RichText field={item} highlight={highlight} />
          break
        case 'Button':
          type = 'Button'
          preview = (
            <div className="flex justify-center">
              <ButtonField field={item} highlight={highlight} />
            </div>
          )
          break
        case 'Image':
          type = 'Image'
          preview = (
            <div className="flex justify-center">
              <ImageField field={item} highlight={highlight} />
            </div>
          )
          break
      }
    default:
      break
  }

  useEffect(
    function detectHighlightComplete() {
      if (ref.current && contentRef?.current?.style?.top === '') {
        const element = ref.current?.querySelector(
          '.find-replace__highlight, .find-replace__outline',
        )
        const parentRect = ref.current.getBoundingClientRect()
        const heightBounds = 400
        if (element && parentRect.height > heightBounds) {
          const elementRect = element.getBoundingClientRect()
          const errorMargin = 100

          if (elementRect.bottom + errorMargin > parentRect.bottom) {
            contentRef.current.style.top = `-${
              elementRect.bottom - parentRect.bottom + errorMargin
            }px`
          }
        }
      }
    },
    [innerHTML],
  )

  return (
    <div className="find-replace-preview" ref={ref}>
      <div className="find-replace-preview__type">{type}</div>
      <div className="find-replace-preview__content-container">
        <div className="find-replace-preview__content" ref={contentRef}>
          {preview}
        </div>
      </div>
    </div>
  )
}
FindReplacePreview.propTypes = {
  item: PropTypes.object,
  entry: PropTypes.object,
}
export default FindReplacePreview
